import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {EMPTY, Observable, Subject, throwError} from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {LoginFormComponent} from "../components/login-form/login-form.component";
import {Context} from "../services/config/context.service";
import { AuthService } from '../services/api/auth.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { switchMap, catchError, filter, take, finalize } from 'rxjs/operators';


@Injectable()
export class AddTokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private authService: AuthService
  constructor(private router: Router, public dialog: MatDialog,private context: Context, private injector: Injector) {
    this.authService = injector.get(AuthService)
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(request.url.includes('/authenticate') || request.url.includes('/login')) {
      const newRequest  = request.clone({ setHeaders: {'Client-Id': this.context.clientId } })
      return next.handle(newRequest)
    }

    const refreshToken = this.authService.getRefreshToken();
    if(!refreshToken) {
      return EMPTY
    }

    const isActiveToken = this.authService.isValidToken(refreshToken)
    if (!isActiveToken) {
      if(this.context.authPopup) {
        return this.refreshTokenSubject.pipe(
          filter((result) => result),
          take(1),
          switchMap(() => next.handle(this.addHeaders(request)))
        );
      }

      this.context.authPopup = true;
      this.refreshTokenSubject.next(null)
      setTimeout(() => {
        this.dialog
          .open(LoginFormComponent, {panelClass: 'custom-dialog-container'})
          .afterClosed()
          .subscribe(data => {
            if(data) this.refreshTokenSubject.next("token")
            
            this.context.authPopup = false;
          });
      })

      return EMPTY;
    }

    return this.sendRequestWithFallback(request, next)
  }

  private addHeaders(request: HttpRequest<any>) {
    const token = this.authService.getToken();
    const accountId = this.authService.getAccountId();

    const headers: {[key: string]: string} = {'Account-Id': `${accountId}`||'', 'Client-Id': this.context.clientId}
    
    if(token) {
      headers.Authorization = `Bearer ${token}`
    }

    const clone = request.clone({ setHeaders: { ...headers  } });
    return clone;
  }

  private sendRequestWithFallback(
      request: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {

      return next.handle(this.addHeaders(request)).pipe(
        catchError((requestError: HttpErrorResponse) => {
          console.log(requestError)
          if (requestError && (requestError.error.errorMessage === 'Signature has expired.' || requestError.status === 401)) {
            if (this.isRefreshing) {
              return this.refreshTokenSubject.pipe(
                filter((result) => result),
                take(1),
                switchMap(() => next.handle(this.addHeaders(request)))
              );
            } else {
              this.isRefreshing = true;
              this.refreshTokenSubject.next(null);
              const refreshToken = this.authService.getRefreshToken()
              return this.authService.refreshToken(refreshToken || '').pipe(
                switchMap((token: any) => {
                  const access_token = token.payload.access_token
                  const refresh_token = token.payload.refresh_token
                  
                  this.authService.saveToken(access_token);
                  this.authService.saveRefreshToken(refresh_token);

                  this.isRefreshing = false;
                  this.refreshTokenSubject.next(refresh_token);

                  return next.handle(this.addHeaders(request));
                }),
                finalize(() => (this.isRefreshing = false))
              );
            }
          } else {
            return throwError(() => new Error(requestError.message));
          }
        })
      );
    }  
  

}

import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormBuilder, FormGroup, FormControl, FormArray, AbstractControl} from '@angular/forms';
import { PipelineService } from '../../services/api/pipeline.service';
import { DashboardStoreService } from '../../services/store/dasboard-store.service';
import { pairs } from 'rxjs';
import { controller } from 'ngx-bootstrap-icons';

@Component({
  selector: 'app-pipeline-constant',
  templateUrl: './pipeline-constant.component.html',
  styleUrls: ['./pipeline-constant.component.css']
})
export class PipelineConstantComponent implements OnInit {


  user = new FormGroup({
    key: new FormControl(''),
    value: new FormControl(''),
    pairs: new FormArray([])
  });

  pairs = this.user.get('pairs') as FormArray;

  variables: Array<{key: string, value: string}> = []

  ngOnInit(): void {
    this.loadKeyValues()
    
  }
  
  constructor(private dialogRef: NbDialogRef<PipelineConstantComponent>, private fb: FormBuilder, private pipeline: PipelineService, private dashboard: DashboardStoreService) 
  {
 }

pair:any

  async loadKeyValues() {
    let response = await this.pipeline.getPipelineVariable(this.dashboard.getCurrentPipeline()?.id as number).toPromise()
      let pairs = response
      let _pairs = pairs.map((x: { key: any; value: any; id:number }) => new FormGroup({
        id: new FormControl(x.id),
        key: new FormControl(x.key),
        value: new FormControl(x.value)

      }))
      for(let pair of _pairs) {
        this.pairs.push(pair)
      }
      console.log(_pairs)
    }
  
  addPair(controller: any) {
  console.log(controller.controls.key.value, controller.controls.value.value)

    let newPair = {
      key: controller.controls.key.value,
      value: controller.controls.value.value
    }

    controller.controls.key.reset()
    controller.controls.value.reset()
    // this.user.controls['key'].reset()
    // this.user.controls['value'].reset()

    // Push in Array
    this.pairs.push(new FormGroup({
        key: new FormControl(newPair.key),
        value: new FormControl(newPair.value)
    }
    ));
    
    let pipelineId = this.dashboard.getCurrentPipeline()?.id
    //(this.user.get('pairs') as FormArray).push(this.user.controls["pairs"]);
    this.pipeline.createVariable({
    "pipelineId" : pipelineId,
    "key" : newPair.key,
    "value" : newPair.value
    }).subscribe((res: any) => {
      console.log(res)
    })
  }


  updatePair(controller:any) {

    console.log(controller.controls.key.value, controller.controls.value.value)

    let newPair = {
      key: controller.controls.key.value,
      value: controller.controls.value.value
    }

    // controller.controls.key.reset()
    // controller.controls.value.reset()
    // // this.user.controls['key'].reset()
    // // this.user.controls['value'].reset()

    // // Push in Array
    // this.pairs.push(new FormGroup({
    //     key: new FormControl(newPair.key),
    //     value: new FormControl(newPair.value)
    // }
    // ));
    //(this.user.get('pairs') as FormArray).push(this.user.controls["pairs"]);
    this.pipeline.updateVariable({
    "pipelinevariableId" : controller.value.id,
    "key" : newPair.key,
    "value" : newPair.value
    }).subscribe((res: any) => {
      console.log(res)
    })
}
  removePair(controller:any) {
    let pipelinevariableId = controller.value.id
    console.log(pipelinevariableId)
    this.pipeline.deleteVariable(pipelinevariableId as number)
    .subscribe((res: any) => {
      console.log(res)

      this.pairs.controls = this.pairs.controls.filter((x: any) => x.value.id !==pipelinevariableId)
    })
   }

   close(){
    this.dialogRef.close();
  }


}




import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {ConfigKeyService, ConfigServiceResponse} from "../../../../services/api/configKey.service";
import {ConfigModel} from "../../../../models/config.model";
import {ConsoleLoggerService} from "../../../../services/logger/console-logger.service";
import {PipelineAPIResponse, PipelineService} from "../../../../services/api/pipeline.service";
import {MatDialog} from "@angular/material/dialog";
import {NodeServiceResponse} from "../../../../services/api/node.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ConfigureApiService} from "../../../../services/api/configure-api.service";
import {FileService} from "../../../../services/api/file.service";
import {NbDialogService} from "@nebular/theme";
import {
  ButtonType,
  CloseButton,
  CreateButton,
  LoadDefaultConfigurations,
  OutputEventEnum,
  addInputField,
  checkDropdown,
  enableButtons,
  getInputFromConfigKey,
  getInputHandlers,
  getSingleDropdownSelector,
  removeInputField,
  transformData_v1,
  inputsVisitor,
  formatSql,
} from "../helper-functions";
import {UpdateTaskComponent} from "../update-task/update-task.component";
import { DashboardStoreService } from 'src/app/services/store/dasboard-store.service';
import {TaskService} from "../../../../services/api/task.service";
import { CopyTaskComponent } from '../copy-task/copy-task.component';
import { UIService } from 'src/app/services/config/ui.service';
import { schema_sheet } from 'src/app/models/schema.model';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

export type DropdownOptionType = {
  id: number|string|null
  isSelected: boolean
  value: string | number | boolean | null
  [key: string]: unknown
}


export type OutputEventType = keyof typeof OutputEventEnum

export const INPUT_FIELD_KEY = 'id'
export const INPUT_FIELD_CONFIG_KEY = 'id'

export type InputFieldType = {
  [INPUT_FIELD_KEY]: string|number,
  type: 'text' | 'dropdown' | 'dropdown_multi' | 'file' | 'date' | 'number'|'button'|'task_specific' | 'textarea_code' | 'sql_query' |  'input-separator'
  hidden: boolean
  display_text: string
  placeholder: string

  multiple?: boolean
  options: Array<DropdownOptionType>
  buttons?: Array<ButtonType>
  getSelected?: () => Array<DropdownOptionType>|DropdownOptionType // getSelected returns selected object/objects

  _value: unknown
  isValid(): boolean
  getValue(): Promise<any> // getValue is called to generate payload

  // eslint-disable-next-line no-use-before-define
  eventHandlers?: Array<(state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, event: {type: string, args: Array<unknown>}) => void>

  [key: string]: unknown
}

@Component({
  selector: 'app-addtask',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.css'],
  providers: [MatSnackBar]
})
export class AddTaskComponent implements OnInit {
  INPUT_FIELD_KEY = INPUT_FIELD_KEY
  INPUT_FIELD_CONFIG_KEY = INPUT_FIELD_CONFIG_KEY

  @Input() type!: ConfigModel;
  @Input() pipelineId!: string | number;
  @Input() processId!: string | number;
  @Input() parentNodeIds: number[] = [];
  @Output() output = new EventEmitter<{type: OutputEventType|string, data: any}>()
  @Input() isReadonly: boolean = false
  @Input() schemaProperties: {
    hasSchema:boolean,
    schema:Array<schema_sheet>
  } = {
    hasSchema : false,
    schema : []
  }

  // Metadata
  configKeyResponse!: ConfigServiceResponse['payload']

  // Data
  pipeline!: PipelineAPIResponse['payload']
  configData!: NodeServiceResponse['payload']
  cacheData: Map<string, any> = new Map<string, any>()

  // State
  loadingError: string = ''
  actionButtons: Array<ButtonType> = []
  inputActionButtons: Array<ButtonType> = []
  inputHandlers!:  ReturnType<typeof getInputHandlers>

  formInputs: Array<InputFieldType> = []
  keyInputMap: {[key: string]: InputFieldType, [key: number]: InputFieldType} = {}

  // sideEffect functions will be called after every change in configKeyValues
  // !!should not change configKeyValues or call handleInputChange function!!
  sideEffects: Array<(state: AddTaskComponent, event: any) => void> = []
  
  @ViewChildren(CodemirrorComponent) codemirrorComponents!: QueryList<CodemirrorComponent>;

  constructor(
    private logger: ConsoleLoggerService,
    private configKeyService: ConfigKeyService,
    private dashboardStoreService : DashboardStoreService,
    public taskService: TaskService,
    public pipelineService: PipelineService, // TODO: make service private
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public fileService: FileService,
    public dialogService: NbDialogService,
    public configureAPIService: ConfigureApiService,
    public uiService: UIService,
  ) {

    this.inputHandlers = getInputHandlers(this)

    this.sideEffects.push(enableButtons)
    this.sideEffects.push(inputsVisitor)
    this.sideEffects.push(checkDropdown)

    this.output.subscribe(logger.log)
  }

  formatCode(id: string, input: InputFieldType) {
    let editor = this.codemirrorComponents.find((el: any) => el._options.id === id)
    if(editor) {
      let value = formatSql(editor.value)
      input._value = value
      // editor.writeValue(value)
    }
  }

  async ngOnInit() {
    try {
      await this.loadData()
      this.output.next({type: OutputEventEnum.ON_LOAD, data: {component: this.constructor.name}})
    } catch (error) {
      this.output.next({type: OutputEventEnum.LOADING_ERROR, data: {component: this.constructor.name, error}})
    }
  }

  private async loadData() {
    // Load pipeline
    this.pipeline = this.dashboardStoreService.getCurrentPipeline()!
    if(!this.pipeline) {
      this.pipeline = await this.pipelineService.getPipeline(this.pipelineId as number).toPromise()
    }

    // Load configuration
    this.configKeyResponse = (await this.configKeyService.getConfigKey(this.type.id, this.parentNodeIds).toPromise()).payload

    // Load default input fields
    LoadDefaultConfigurations(this)

    // Load input fields from configuration
    this.configKeyResponse.configs.forEach(key => addInputField(this, getInputFromConfigKey(this, key)))

    // load sideEEffects
    const dependentDropdowns: Array<InputFieldType> = []
    if(this.keyInputMap['dependent'])dependentDropdowns.push(this.keyInputMap['dependent'])
    if(this.keyInputMap['data_dependent'])dependentDropdowns.push(this.keyInputMap['data_dependent'])
    if(this.keyInputMap['union'])dependentDropdowns.push(this.keyInputMap['union'])
    this.sideEffects.push(getSingleDropdownSelector(this, dependentDropdowns))

    // Add Save button
    this.actionButtons.push(CreateButton)
    enableButtons(this, {})
  }

  public addInputField(input: InputFieldType) {
    addInputField(this, input)
  }

  public removeInputField(input: InputFieldType) {
    removeInputField(this, input)
  }

  // handle final input change.
  handleInputChange(event: { configKeyName: string|number, value: string | number | unknown }): void {
    this.sideEffects.forEach(visitor => visitor(this, event))
  }


  // validate input after update
  // TODO: use input validator
  validateInput(configKeyId: number, value: string | number | unknown): { valid: boolean, error?: string } {
    return {valid: true, error: ''}
  }

  async save() {

    // transform data to payload
    let payload = await transformData_v1(this)

    // Create TaskNode
    const response = await this.dashboardStoreService.createTask(payload)

    // emit save event
    this.output.next({type: OutputEventEnum.SAVED_AND_EXIT, data: {payload, response}})

  }

  close() {
    this.output.next({type: OutputEventEnum.CANCEL, data: {}})
  }

  open_schema(){
    this.output.next({type: OutputEventEnum.OPEN_SCHEMA, data: {}})
  }

  edit_schema(){
    this.output.next({type: OutputEventEnum.EDIT_SCHEMA, data: this.schemaProperties})
  }

}

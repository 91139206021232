<!-- <div class="container-fluid" style="padding-top: .5rem"> -->

  <!-- <div style="display: flex; justify-content: space-between;margin: 1rem 0">
    <h4 style="color:rgba(0,0,0,.54);align-self: center">
      History
    </h4>
   <mat-form-field appearance="fill">
     <mat-label>Enter a date range</mat-label>
     <mat-date-range-input [rangePicker]="picker">
       <input matStartDate placeholder="Start date">
       <input matEndDate placeholder="End date">
     </mat-date-range-input>
     <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
     <mat-date-range-picker #picker></mat-date-range-picker>
   </mat-form-field>
  </div> -->


  <div style="padding: 1rem;">
    <table mat-table [dataSource]="dataSource" style="width: -webkit-fill-available">

      <!-- Index -->
      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef> #</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Pipeline Name</th>
        <td mat-cell *matCellDef="let element"> {{pipelineId}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="weight">
        <th mat-header-cell *matHeaderCellDef> ProcessId</th>
        <td mat-cell *matCellDef="let element"> {{element.process_id}} </td>
      </ng-container>

      <!-- Last Updated -->
      <ng-container matColumnDef="updated_time">
        <th mat-header-cell *matHeaderCellDef> Last Updated</th>
        <td mat-cell *matCellDef="let element"> {{element.updated_time}} </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="symbol">
        <th mat-header-cell *matHeaderCellDef> Status</th>
        <!--        <td mat-cell *matCellDef="let element"> {{element.status}} </td>-->
        <td mat-cell *matCellDef="let element" style="width: 15rem">
          <div class="status-bar" style="align-items: center">
            <div nbTooltip="Open">{{element.node_status.open}}</div>
            <div nbTooltip="In Progress">{{element.node_status.in_progress}}</div>
            <div nbTooltip="Success">{{element.node_status.success}}</div>
            <div nbTooltip="Failed" class="Warn">{{element.node_status.failed}}</div>
<!--            <a (click)="openProcessDetails($event,element)" href="#" style="margin-left: 1rem">Process info</a>-->
            <button nbButton shape="round" size="small" status="success" style="margin-left: 1rem" (click)="openProcessDetails($event,element)">
              Process info
            </button>

          </div>
          <!--          <nb-actions [size]="'tiny'">-->
          <!--            <nb-action icon="person-outline" [badgeText]="element.node_status.open" badgeStatus="info"></nb-action>-->
          <!--            <nb-action icon="home-outline" [badgeText]="element.node_status.in_progress" badgeStatus="success"></nb-action>-->
          <!--            <nb-action icon="home-outline" [badgeText]="element.node_status.success||'0'" badgeStatus="success"></nb-action>-->
          <!--            <nb-action icon="power-outline" [badgeText]="element.node_status.failed" badgeStatus="danger"></nb-action>-->
          <!--            <nb-action (click)="openProcessDetails(element)"><a>Process info</a></nb-action>-->
          <!--          </nb-actions>-->
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                   showFirstLastButtons="false"
                   aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>


<!-- </div> -->

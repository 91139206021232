import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {TaskHistoryStoreService} from "../../services/store/task-history-store.service";
import {TaskProcess} from "../../services/api/process-history.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {Observable, Subject} from "rxjs";
import {UIService} from "../../services/config/ui.service";

class TaskHistorySource implements DataSource<TaskProcess> {
  subject = new Subject<TaskProcess[]>()
  private _paginator: MatPaginator | null = null
  private defaultPageSize = 5

  constructor(private taskHistory: TaskHistoryStoreService,public uiService: UIService) {}
  connect(collectionViewer: CollectionViewer): Observable<TaskProcess[]> {
    this.uiService.isContentLoading = true
    this.taskHistory.getTaskHistory({
      from: 1,
      pipelineId: this.taskHistory.pipelineId,
      size: this.defaultPageSize
    }).subscribe(data => {
      if(this._paginator){
        this._paginator.length = data.payload.process_count
      }
      this.uiService.isContentLoading = false
      this.subject.next(data.payload.process_list.map((value, index, array) => ({...value, id: index+1})))
    })

    return this.subject.asObservable()
  }
  disconnect(collectionViewer: CollectionViewer): void {}
  set paginator(paginator: MatPaginator) {
    this._paginator = paginator
    paginator.page.subscribe(config => {
      let start_index = config.pageIndex * config.pageSize+1
      this.taskHistory.getTaskHistory({
        from: start_index,
        pipelineId: this.taskHistory.pipelineId,
        size: config.pageSize
      }).subscribe(data => {
        if(this._paginator){
          this._paginator.length = data.payload.process_count
        }
        this.subject.next(data.payload.process_list.map((value, index, array) => ({...value, id: index+start_index})))
      })
    })
  }
}

@Component({
  selector: 'app-task-history',
  templateUrl: './task-history.component.html',
  styleUrls: ['./task-history.component.scss']
})
export class TaskHistoryComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator | any = {}
  displayedColumns: string[] = ['index','name', 'weight', 'updated_time', 'symbol']
  dataSource: TaskHistorySource = new TaskHistorySource(this.taskHistory, this.uiService)

  constructor(private uiService: UIService, private taskHistory: TaskHistoryStoreService, private router: Router,private route: ActivatedRoute) {
    
    this.route.paramMap.subscribe((route) => {
      this.taskHistory.pipelineId = parseInt(route.get('id') || '-2')
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
  }

  ngOnInit(): void {
  }

  @Input()
  get pipelineId() {
    return this.taskHistory.pipelineId
  }

  set pipelineId(pipelineId: number) {
    this.taskHistory.pipelineId = pipelineId
  }

  openProcessDetails(event: MouseEvent,element: TaskProcess) {
    // console.log(element)
    event.preventDefault()
    let config = {edit: false, processId: element.process_id}
    this.router.navigate(['/dashboard', this.taskHistory.pipelineId], {queryParams: {config: btoa(JSON.stringify(config))}})
  }

}

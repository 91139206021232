// Load data require for key
// ex: dropdown data, mapping data, convert input type to file for file-upload
import {ConfigKeyModel, ConfigNodeTypes} from "../../../models/config.model";
import {
  AddTaskComponent,
  DropdownOptionType,
  INPUT_FIELD_CONFIG_KEY,
  INPUT_FIELD_KEY,
  InputFieldType
} from "./addtask/add-task.component";
import {JsonFormComponent} from "../../../components/json-form/json-form.component";
import {ApiPopupComponent} from "../../api-popup/api-popup.component";
import {DataConnectionComponent} from "../../data-connection/data-connection.component";
import {UpdateTaskComponent} from "./update-task/update-task.component";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { EventTypes } from "src/app/components/multi-select-dropdown/union.component";
import { CopyTaskComponent } from "./copy-task/copy-task.component";
import 'codemirror/mode/sql/sql';
import { format } from 'sql-formatter';

export enum OutputEventEnum {
  CANCEL = 'CANCEL',
  SAVED_AND_EXIT = 'SAVED_AND_EXIT',
  ON_LOAD = 'ON_LOAD',
  OPEN_CODE_EDITOR = 'OPEN_CODE_EDITOR',
  OPEN_PREVIEW = 'OPEN_PREVIEW',
  LOADING_ERROR = 'LOADING_ERROR',
  OPEN_SCHEMA = 'OPEN_SCHEMA',
  EDIT_SCHEMA = "EDIT_SCHEMA"
}

export type ButtonType = {
  name: string,
  disable: boolean,
  hidden: boolean,
  icon?: string,
  status: 'primary' | 'basic',
  // click is to perform some action, can update the state
  click: (state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, self: ButtonType, event: MouseEvent) => void,

  // visitor is called on any change in input form _value (ie. configKeyValues)
  // visitor is not allowed to change state but can update `self`
  visitor?: (state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, self: ButtonType) => void

  // button data
  [key: string]: any
}

interface IInput {
  type: 'text' | 'dropdown'
  value: string
  data: any
  placeholder?: string
}

interface IKey extends IInput {}

interface IValue extends IInput {}

export interface IKeyValue {
  id: string | number
  key: IKey
  value: IValue
}

export function boolThreeState(value: true|false|null) {
  if(value === true) return 0
  if(value === false) return 2
  return 1
}

export function threeStateToBool(value: 0|1|2) {
  if(value === 0) return true
  if(value === 2) return false
  return null
}

// Input object factory
export function getInputFromConfigKey(state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, key: ConfigKeyModel): InputFieldType {

  // Create task specific
  if(key.is_node_specific) {

    const APPLY_TASK_SPACIFIC_TO = 'union'
    const addTaskSpecificData = (input: InputFieldType, value: Array<{taskId: number, value: string}>, key: ConfigKeyModel|null=null) => {

      const valueMap = value.reduce((prev, curr) => {
        prev[curr.taskId] = curr.value
        return prev
      }, {} as {[key: number]: string})

      input.options.forEach(el => {
        el.showDetailsIcon = true;
        if(!el.taskSpecific) {
          el.taskSpecific = {title: el.value,keyValues: []}
        }

        // update the value is confix exist
        let configIndex = ((el.taskSpecific as any).keyValues as Array<IKeyValue>).findIndex(x => x.id == key?.id);
        if(configIndex > -1) {
          //@ts-ignore
          el.taskSpecific.keyValues[configIndex].value.value = valueMap[el.id as number]
        } else {

          (el.taskSpecific as any).keyValues.push({
            id: key?.id,
            key: {
              data: {},
              type: 'text',
              multiple: key?.is_multi_select,
              value: key?.display_text,
              placeholder: key?.display_text
            },
            value: {
              data: {options:[]},
              type:'text',
              multiple: key?.is_multi_select,
              value: valueMap[el.id as number],
              placeholder: 'value'
            }
          })
        }
      })
    }

    const taskSpecific:InputFieldType = {
      [INPUT_FIELD_KEY]: key[INPUT_FIELD_CONFIG_KEY],
      type: "task_specific",
      hidden: true,
      display_text: key.display_text,
      placeholder: "",
      options: [],
      set _value(value: Array<{taskId: number, value: string}>) {
        if(state.keyInputMap[APPLY_TASK_SPACIFIC_TO]) {
          const input = state.keyInputMap[APPLY_TASK_SPACIFIC_TO];
          addTaskSpecificData(input, value, key)
        }
      },
      get _value() {
        if(state.keyInputMap[APPLY_TASK_SPACIFIC_TO]) {
          const input = state.keyInputMap[APPLY_TASK_SPACIFIC_TO];
          return input.options.filter(x => x.isSelected).map(x => ({taskId: x.id as number, value: ''}))
        }
        return []
      },
      isValid: function (): boolean {
        return true
      },
      getValue: function (): Promise<any> {
        if(state.keyInputMap[APPLY_TASK_SPACIFIC_TO]) {
          const unionInput = state.keyInputMap[APPLY_TASK_SPACIFIC_TO];

          const values = unionInput.options.filter(x => x.isSelected).map(x => {
            let thisValue = ((x.taskSpecific as any).keyValues as IKeyValue[]).find(x => `${x.id}` == `${key.id}`)
            return {taskId: x.id, value: thisValue?.value.value, configValueId: null}
          });
          return Promise.resolve(values.map(x => ({configKeyId: key.id, configValueId: x.configValueId, taskId:x.taskId, value: x.value})))
        }
        return Promise.resolve([])
      },

      // taskSpecific contains the data related to specific node
      // taskNodeId => nodeSchema
      // {[key: number]: {value: <string|number>, columns: Array<{}>}}
      taskSpecific: {

      },
      eventHandlers: []
    }

    const eventHandler = async (state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, event: {type: string, args: Array<unknown>}) => {

      if(event.type === EventTypes.ON_SELECT) {
        let item:any = event.args[0]
        if(key.key_value_input_box_type !== 'drop_down') return;
        console.log(item.taskSpecific.keyValues)

        // find self
        const taskSpecificConfigInput = item.taskSpecific.keyValues.find((x: any) => x.id === key.id)
        console.log(taskSpecificConfigInput)

        const requestCacheId = `nodeSchema:${state.pipelineId}:${state.pipeline.process.id}:${item.id}`;

        if(!state.cacheData.has(requestCacheId)) {
          try {
            const result = await state.configureAPIService.getNodeSchema({pipelineId: state.pipelineId, processId: state.pipeline.process.id,taskId: `${item.id}`}).toPromise();
            state.cacheData.set(requestCacheId, result);
          } catch {
            console.error('Failed to load node schema');
          }
        }

        if(taskSpecificConfigInput && state.cacheData.has(requestCacheId)) {
          taskSpecificConfigInput.value.data.options = state.cacheData.get(requestCacheId).payload.columns.map((x: any) => ({id: x.key_name, value: x.key_name}))
          taskSpecificConfigInput.value.type = 'dropdown'
        }
      }

      if(event.type === EventTypes.ON_SHOW_DETAILS) {

      }
    }

    if(state.keyInputMap[APPLY_TASK_SPACIFIC_TO]) {
      const unionInput = state.keyInputMap[APPLY_TASK_SPACIFIC_TO]

      // adding task specific data
      addTaskSpecificData(unionInput, [], key)

      // refill dropdown for pre-selected values
      unionInput.options.filter(x => x.isSelected).forEach(option => {
        eventHandler(state, {type: EventTypes.ON_SELECT, args:[option]})
      })

      // subscribe union input events
      if(!unionInput.eventHandlers) unionInput.eventHandlers = []

      unionInput.eventHandlers.push(eventHandler)
    }

    taskSpecific.eventHandlers?.push(eventHandler)
    return taskSpecific
  }

  // Create sql query field
  if (key.key_value_input_box_type === 'sql_query' && key.key_value_type === 'open_text') {
    const sqlTextInputField: InputFieldType = {
      hidden: false,
      [INPUT_FIELD_KEY]: key[INPUT_FIELD_CONFIG_KEY],
      type: 'sql_query',
      multiple: key.is_multi_select,
      _value: key.is_multi_select? [] : '',
      display_text: key.display_text,
      placeholder: key.key_value_type,
      options: [],
      editorOptions: {
        id: `editor-id-${key[INPUT_FIELD_CONFIG_KEY]}`,
        lineNumbers: true,
        theme: 'default',
        mode: 'sql'
      },
      isValid() {
        return true
      },
      async getValue() {
        return Promise.resolve({
          configKeyId: key.id,
          configValueId: null,
          // remove white space, new line
          // eslint-disable-next-line no-use-before-define
          value: formatSql(sqlTextInputField._value as string || '')
        })
      }
    }
    return sqlTextInputField
  }

  // Create text field
  if (key.key_value_input_box_type === 'text' && key.key_value_type === 'open_text') {
    const textInputField: InputFieldType = {
      hidden: false,
      [INPUT_FIELD_KEY]: key[INPUT_FIELD_CONFIG_KEY],
      type: 'text',
      multiple: key.is_multi_select,
      _value: key.is_multi_select? [] : '',
      display_text: key.display_text,
      placeholder: key.key_value_type,
      options: [],
      isValid() {
        return true
      },
      async getValue() {
        return Promise.resolve({
          configKeyId: key.id,
          configValueId: null,
          value: textInputField._value
        })
      }
    }
    return textInputField
  }

  // Create file field
  if (key.key_value_input_box_type === 'text' && key.key_value_type === 'file') {
    const fileInputField: InputFieldType = {
      [INPUT_FIELD_KEY]: key[INPUT_FIELD_CONFIG_KEY],
      hidden: false,
      type: 'file',
      display_text: key.display_text,
      placeholder: key.key_value_type,
      options: [],
      buttons: [],
      _url: null,
      _value: '',
      isValid() {
        return true
      },
      async getValue() {

        if(fileInputField._value === '') {
          console.warn(`File [${key.key_name}] not selected!`)
          return Promise.resolve({
            configKeyId: key.id,
            configValueId: null,
            value: fileInputField._url
          })
        }

        // Upload file and return url
        const formData = new FormData()
        formData.append('pipeline_id', state.pipelineId.toString())
        formData.append('process_id', state.processId.toString())
        formData.append('config_id', key.config_id.toString())
        formData.append('file', fileInputField._value as Blob)
        let response = await state.fileService.uploadFile(formData).toPromise()
        fileInputField._url = response.payload.url

        return Promise.resolve({
          configKeyId: key.id,
          configValueId: null,
          value: fileInputField._url
        })
      }
    }

    if(key.key_name === 'validation_file') {
      const openViewButton: ButtonType = {
        name: 'View',
        disable: false,
        hidden: false,
        status: "basic",
        // icon:'done',
        class: 'button',
        marginLeft: 'auto',
        click: function (state: AddTaskComponent | UpdateTaskComponent |CopyTaskComponent, self: ButtonType, event: MouseEvent): void {
          console.log('Reading file content...') // TODO:

          state.output.emit({type: OutputEventEnum.OPEN_CODE_EDITOR, data: {
            value: fileInputField._value,
            readOnly: true,
            event,
            input: self,
            callback: (value: string) => {
              fileInputField._value = value
              state.inputHandlers.textareaInputEventHandler(fileInputField, event)
            }
          }})
        },
        visitor: (state, button) => {
          button.disable = state instanceof AddTaskComponent
        }
      }

      const openEditButton: ButtonType = {
        name: 'Edit',
        disable: false,
        hidden: false,
        status: "basic",
        icon:'edit',
        class: 'button button-basic',
        click: function (state: AddTaskComponent | UpdateTaskComponent | CopyTaskComponent, self: ButtonType, event: MouseEvent): void {
          console.log('Reading file content...') // TODO:
          openEditButton.disable = true
          state.output.emit({type: OutputEventEnum.OPEN_CODE_EDITOR, data: {
            value: fileInputField._value,
            readOnly: false,
            event,
            input: self,
            callback: (value: string) => {
              openEditButton.disable = false
              fileInputField._value = value
              state.inputHandlers.textareaInputEventHandler(fileInputField, event)
            }
          }})
        }
      }

      fileInputField.buttons = fileInputField.buttons || [];
      fileInputField.buttons.push(openEditButton)
      fileInputField.buttons.push(openViewButton)
    }
    return fileInputField
  }

  // Create mapping field
  if (key.key_value_input_box_type === 'map') {
    let mapInputField: InputFieldType = {
      [INPUT_FIELD_KEY]: key[INPUT_FIELD_CONFIG_KEY],
      hidden: false,
      type: 'button',
      display_text: key.display_text,
      placeholder: key.key_value_type,
      options: [],
      _value: [],
      isValid() {
        return true
      },
      async getValue() {
        return Promise.resolve({
          configKeyId: key.id,
          configValueId: null,
          value: {
            mapping: mapInputField._value
          }
        })
      }
    }
    const mappingButton: ButtonType = {
      name: key.display_text,
      disable: false,
      hidden: false,
      status: "primary",
      marginLeft: 'auto',
      class: 'button button-basic',
      click: async (state, self, event) => {
        // Get client_api keyConfig
        const clientApiKey = state.configKeyResponse.configs.find(x => x.key_name === 'client_api')
        if(!clientApiKey) {
          console.error('client_api key_name not found!')
          return
        }

        const selectedApiId = (state.keyInputMap[clientApiKey[INPUT_FIELD_CONFIG_KEY]]._selected as DropdownOptionType).id
        let form = await state.pipelineService.getSinkDetailsV2({api_id: selectedApiId as string}).toPromise()


        // loading node schema
        let dropdownData = []
        let mapping: Array<any> = []
        try {
          let taskId: any
          if(state instanceof AddTaskComponent) {
            taskId = state.parentNodeIds[0]
            console.log("parent nodes",state.parentNodeIds[0]);
          }

          if(state instanceof CopyTaskComponent) {
            taskId = state.parentNodeIds[0]
            console.log("parent nodes",state.parentNodeIds[0]);
          }

          /* FIXME: V2 remove commented code, V2 API does not require incoming_task_with_weights
          if(state instanceof UpdateTaskComponent) {
            // find data dependent node
            let dataDependentNode = (state.currentNode.incoming_task_with_weights || []).find(x => x.type === 'data');
            if(dataDependentNode) {
              taskId = dataDependentNode.taskId
            }

            // pass all incoming nodes if data node not found
            else {
              taskId = state.currentNode.incomingTaskIds.join(',')
            }
          }
          // */

          if(state.type.type === 'source') {
            taskId = 'null'
          }

          const sourceData = await state.configureAPIService.getNodeSchema({
            pipelineId: state.pipelineId,
            processId: state.processId,
            taskId: taskId,
          }).toPromise()
          dropdownData = sourceData.payload.columns.map((x: {id:number, key_name: string, data_type:string}) => ({key: x.key_name, value: x.key_name, data_type: x.data_type}))
        } catch {
          state.snackBar.open('Failed to load nodeSchema', 'close', {duration: 2000})
        }

        // Load mappings
        if(state instanceof UpdateTaskComponent) {
          const keyData = state.taskConfigDetails.payload.configs.find(x => x.id == key.id)
          mapping = (keyData?.userValue?.mapping as Array<any>) || mapping
        }

        const schema = {
          type: 'object',
          children: form.payload.requst_payload
        }

        const variables = {
          type: 'object',
          children: form.payload.variables
        }

        const headers = {
          type: 'object',
          children: form.payload.headers
        }

        let dialogRef = state.dialog.open(JsonFormComponent, {
          panelClass: 'custom-dialog-container', hasBackdrop: true, data: {
            state: mapInputField.$prev_state || null,
            schema: schema, variables, headers, defaultValue: {mapping: mapping},
            dropdownData: dropdownData,
            subscriber: (event: any, data: any) => {
              data.value = event.target.value
              // console.log(data, event.target.value)
              console.log(data, event.target.value)
            }
          }
        })

        dialogRef.afterClosed().subscribe(event => {
          if(event && event.response) {
            mapInputField._value = event.response
            mapInputField.$prev_state = event.state
          }
        })
      },
      visitor: (state, button) => {
        // Get client_api keyConfig
        const clientApiKey = state.configKeyResponse.configs.find(x => x.key_name === 'client_api')
        if(!clientApiKey) {
          console.error('client_api key_name not found!')
          return
        }
        let current_value = (state.keyInputMap[clientApiKey[INPUT_FIELD_CONFIG_KEY]]._selected as DropdownOptionType).id
        button.disable = !current_value

        // remove saved state on API change
        if(mapInputField.$last_value !== current_value) {
          mapInputField.$prev_state = null
        }

        mapInputField.$last_value = current_value

      }
    }

    const addAPIButton: ButtonType = {
      name: 'Add',
      disable: false,
      hidden: false,
      status: "primary",
      click: async (state, self, event) => {
        let data:Array<any> = [];
        data.push({title: 'headers', fields: []})
        data.push({title: 'variables', fields: []})
        data.push({title: 'schema', fields: []})
        let settings = {}
        let dialogRef = state.dialog.open(ApiPopupComponent, {
          panelClass: 'custom-dialog-container',
          data: {
            data,
            settings,
            create: true
          }
        })
      },
      visitor: (state, button) => {
      }
    }

    const editAPIButton: ButtonType = {
      name: 'Edit',
      disable: false,
      hidden: false,
      status: "primary",
      click: async (state, self, event) => {
        let data:Array<any> = [];
        let settings = {}

        const clientApiKey = state.configKeyResponse.configs.find(x => x.key_name === 'client_api')
        if(!clientApiKey) {
          console.error('client_api key_name not found!')
          return
        }
        const selectedApiId = (state.keyInputMap[clientApiKey[INPUT_FIELD_CONFIG_KEY]]._selected as DropdownOptionType).id

        try {
          let response = await state.pipelineService.getApiDetails({api_id: selectedApiId as string}).toPromise()
          data.push({title: 'headers', fields: response.payload.headers})
          data.push({title: 'variables', fields: response.payload.variables})
          data.push({title: 'schema', fields: response.payload.schema})
          settings = response.payload || {}
          let dialogRef = state.dialog.open(ApiPopupComponent, { panelClass: 'custom-dialog-container',data: {data,settings}})

          dialogRef.afterClosed().subscribe(event => {
            console.log('dialog closed', event)
          })

        } catch {
          let delay = 1500
          state.snackBar.open('Failed to load api details', 'close', {duration: delay})
        }

      },
      visitor: (state, button) => {
        const clientApiKey = state.configKeyResponse.configs.find(x => x.key_name === 'client_api')
        if(!clientApiKey) {
          console.error('client_api key_name not found!')
          return
        }
        button.disable = !(state.keyInputMap[clientApiKey[INPUT_FIELD_CONFIG_KEY]]._selected as DropdownOptionType).id
      }
    }

    // eslint-disable-next-line no-use-before-define
    mapInputField.buttons = [addAPIButton, editAPIButton, mappingButton, PreviewButton]
    return mapInputField
  }

  // Boolean dropdown
  if (['drop_down', 'dropdown'].includes(key.key_value_input_box_type) && key.key_value_type === 'boolean') {
    let options: Array<DropdownOptionType> = [
      {
        id: 'true',
        value: 'true',
        isSelected: false
      },
      {
        id: 'false',
        value: 'false',
        isSelected: true
      }
    ];
    const dropdownInputField: InputFieldType = {
      [INPUT_FIELD_KEY]: key[INPUT_FIELD_CONFIG_KEY],
      hidden: false,
      type: 'dropdown',
      multiple: false,
      display_text: key.display_text,
      placeholder: key.key_value_type,
      options: options,
      events: new BehaviorSubject<{type:string, args: Array<unknown>}>({type: 'init', args: []}),
      __value: 'false',
      get _value() {
        return dropdownInputField.__value as string
      },
      set _value(value: string) {
        dropdownInputField.__value =  value
        dropdownInputField.options.map(x => x.isSelected = `${value}` === `${x.id}`)

      },
      get _selected() {
        return dropdownInputField.options.find(x=>x.isSelected)||{}
      },
      isValid() {
        return true
      },
      async getValue() {
        return Promise.resolve({
          configKeyId: key.id,
          configValueId: null,
          value: dropdownInputField._value
        })

      }
    }
    return dropdownInputField
  }

  // Create dropdown
  if (['drop_down', 'dropdown'].includes(key.key_value_input_box_type)) {
    let options: Array<DropdownOptionType> = [];

    // set options
    if(key.options) {
      options = (key.options as Array<DropdownOptionType>).filter(x => x.value)

    } else {
      key.valueConfigs.forEach(x => options.push({
        $data: x,
        id: x.value,
        value: x.display_text,
        isSelected: false
      }))
    }


    if(!key.is_multi_select) {
      // Add empty option
      options.unshift({id: null, value: null, isSelected: false, $data:{}})
    }

    const dropdownInputField: InputFieldType = {
      [INPUT_FIELD_KEY]: key[INPUT_FIELD_CONFIG_KEY],
      hidden: false,
      type: key.is_multi_select?'dropdown_multi':'dropdown',
      multiple: key.is_multi_select,
      display_text: key.display_text,
      placeholder: key.key_value_type,
      options: options,
      events: new BehaviorSubject<{type:string, args: Array<unknown>}>({type: 'init', args: []}),
      __value: key.is_multi_select ? [] : null,
      get _value() {
        return dropdownInputField.__value as Array<any>|any
      },
      set _value(value: Array<any>|any) {
        if(!value) {
          dropdownInputField.__value = dropdownInputField.multiple? [] : null
        } else {
          dropdownInputField.__value =  value
        }

        if(Array.isArray(value)) {
          dropdownInputField.options.map(x => x.isSelected = value.includes(x.id))
        } else {
          dropdownInputField.options.map(x => x.isSelected = `${value}` === `${x.id}`)
        }
      },
      get _selected() {
        if(dropdownInputField.multiple) {
          return dropdownInputField.options.filter(x=>x.isSelected)
        }
        return dropdownInputField.options.find(x=>x.isSelected)||{}
      },
      isValid() {
        return true
      },
      async getValue() {

        // return multi-select value as an array of {configValueId,value}
        if(dropdownInputField.multiple) {
          let order: Array<number> = dropdownInputField.__value as Array<number> || []
          let orderIndexMap = order.reduce((prev, curr, i) => { prev[curr]=i; return prev }, {} as {[key: number]: number})
          let selectedOptions = dropdownInputField.options.filter(x => x.isSelected)

          // re-arrange options in user selected order
          let value = selectedOptions.reduce((prev, curr) => {
            let index = orderIndexMap[curr.id as number]
            prev[index] = {
              configValueId: curr.id, 
              value: curr.value
            }
            return prev
          }, [] as Array<{configValueId: any, value: any}>)

          // const value = selectedOptions.map(option => {
          //   let selectedOption:any = option.$data || {}
          //   return {
          //     configValueId: selectedOption.id,
          //     value: selectedOption.value
          //   }
          // })
          return Promise.resolve({
            configKeyId: key.id,
            configValueId: null,
            values: value
          })

        // return single-select value as {configValueId,value}
        } else {
          const selectedOption = dropdownInputField.options.find(x => x.isSelected)
          let value = {
            configValueId: null,
            value: null
          }

          if(selectedOption) {
            let data:any = (selectedOption.$data||{})
            value.configValueId = data.id
            value.value = data.value
          }

          return Promise.resolve({
            configKeyId: key.id,
            configValueId: value.configValueId,
            value: value.value
          })
        }


      }
    }

    if(key.key_value_type === "connection") {
      const addConnectionButton: ButtonType = {
        name: 'Add',
        disable: false,
        hidden: false,
        status: "primary",
        click: async (state, self, event) => {
          state.dialog.open(DataConnectionComponent, {panelClass: 'custom-dialog-container', data: {}}).beforeClosed().subscribe((res: any) => {
            if(res) {
              dropdownInputField.options.push({id: res.connectionId, value: res.identifier, isSelected: true, $data:res})
              dropdownInputField._value = res.connectionId
            }
          })
        },
        visitor: (state, button) => {}
      }
      const editConnectionButton: ButtonType = {
        name: 'Edit',
        disable: false,
        hidden: false,
        status: "primary",
        marginLeft: 'auto',
        click: async (state, self, event) => {
          // TODO:
          let _connection = dropdownInputField._selected as {id: number}
          let connections:Array<any> = [];

          // Loading connections list
          try {
            connections = (await state.configureAPIService.getConnection().toPromise()).payload;
          } catch {
            console.warn('Failed to load connections!');
          }

          let connection = connections.find(x => x.id == _connection.id) || {}

          state.dialog.open(DataConnectionComponent, {panelClass: 'custom-dialog-container', data: {
            connection
          }}).beforeClosed().subscribe((res: any) => {
            if(res) {
              // dropdownInputField.options.push({id: res.connectionId, value: res.identifier, isSelected: true, $data:res})
              if(res.connectionId) {
                dropdownInputField._value = res.connectionId
              }
            }
          })
        },
        visitor: (state, button) => {}
      }
      dropdownInputField.buttons = [addConnectionButton, editConnectionButton]
    }
    return dropdownInputField
  }

  // Create textarea_code input field
  if(key.key_value_input_box_type === 'textarea_code') {
    const textareaInputField: InputFieldType = {
      hidden: false,
      [INPUT_FIELD_KEY]: key[INPUT_FIELD_CONFIG_KEY],
      type: key.key_value_input_box_type,
      multiple: key.is_multi_select,
      _value: '',
      display_text: key.display_text,
      placeholder: key.key_value_type,
      eventHandlers: [],
      options: [],
      isValid() {
        return true
      },
      async getValue() {
        return Promise.resolve({
          configKeyId: key.id,
          configValueId: null,
          value: textareaInputField._value
        })
      }
    }

    const openViewButton: ButtonType = {
      name: 'View',
      disable: false,
      hidden: false,
      status: "basic",
      // icon:'done',
      class: 'button',
      marginLeft: 'auto',
      click: function (state: AddTaskComponent | UpdateTaskComponent | CopyTaskComponent, self: ButtonType, event: MouseEvent): void {

        // get custom config Input Key
        const customConfigs = state.configKeyResponse.configs.filter(x => x.key_value_input_box_type === 'custom_inputs');
        const customInputs = customConfigs.map(input => state.keyInputMap[input[INPUT_FIELD_KEY]])

        state.output.emit({type: OutputEventEnum.OPEN_CODE_EDITOR, data: {
          customInputs,
          value: textareaInputField._value,
          title: textareaInputField.display_text,
          readOnly: true,
          event,
          input: self,
          callback: (value: string) => {
            textareaInputField._value = value
            state.inputHandlers.textareaInputEventHandler(textareaInputField, event)
          }
        }})
      },
      visitor: (state, button) => {
        button.disable = state instanceof AddTaskComponent
      }
    }

    const openEditButton: ButtonType = {
      name: 'Edit',
      disable: false,
      hidden: false,
      status: "basic",
      icon:'edit',
      class: 'button button-basic',
      click: function (state: AddTaskComponent | UpdateTaskComponent | CopyTaskComponent, self: ButtonType, event: MouseEvent): void {
        openEditButton.disable = true
        // get custom config Input Key
        const customConfigs = state.configKeyResponse.configs.filter(x => x.key_value_input_box_type === 'custom_inputs');
        const customInputs = customConfigs.map(input => state.keyInputMap[input[INPUT_FIELD_KEY]])
        state.output.emit({type: OutputEventEnum.OPEN_CODE_EDITOR, data: {
          customInputs,
          value: textareaInputField._value,
          title: textareaInputField.display_text,
          readOnly: false,
          event,
          input: self,
          callback: (value: string) => {
            openEditButton.disable = false
            textareaInputField._value = value
            state.inputHandlers.textareaInputEventHandler(textareaInputField, event)
          }
        }})
      }
    }

    textareaInputField.buttons = [openEditButton, openViewButton]

    return textareaInputField
  }

  // Create custom_inputs
  if(key.key_value_input_box_type === 'custom_inputs') {
    const textInputField: InputFieldType = {
      hidden: true,
      disable: true,
      [INPUT_FIELD_KEY]: key[INPUT_FIELD_CONFIG_KEY],
      type: 'text',
      multiple: key.is_multi_select,
      _value: key.is_multi_select? [] : '',
      display_text: key.display_text,
      placeholder: key.key_value_type,
      options: [],
      isValid() {
        return true
      },
      visitor: (state: AddTaskComponent | UpdateTaskComponent, self: InputFieldType) => {
        // eslint-disable-next-line no-use-before-define
        const inputs = state.formInputs.filter(x => x[INPUT_FIELD_CONFIG_KEY] === (key.display_text || -100));

        const inputObj: { value: {[key: string]: string}, inputs: Array<Partial<ConfigKeyModel>  & {options: Array<DropdownOptionType>}> } = {
          value: {},
          // TODO: convert x.type to key_value_input_box_type
          inputs: inputs.map(x => ({id: x.id as number, key_name: x.display_text, display_text: x.display_text, key_value_input_box_type: x.type as any, options: x.options}))
        };

        for(let input of inputs) {
          inputObj.value[input.display_text] = input._value as any
        }

        self._value = JSON.stringify(inputObj.value)
        self.__value = inputObj

      },
      async getValue() {
        return Promise.resolve({
          configKeyId: key.id,
          configValueId: null,
          value: JSON.stringify(textInputField.__value||{value: {}, inputs:[]})
        })
      },
    }
    return textInputField;
  }

  console.warn(`unrecognized key type! [${key.key_name}]. key.id: ${key.id}`)

  // Default/Null object
  const textInputField: InputFieldType = {
    hidden: false,
    disable: false,
    [INPUT_FIELD_KEY]: key[INPUT_FIELD_CONFIG_KEY],
    type: 'text',
    multiple: key.is_multi_select,
    _value: key.is_multi_select? [] : '',
    display_text: key.display_text,
    placeholder: 'text',
    options: [],
    isValid() {
      return true
    },
    async getValue() {
      return Promise.resolve({
        configKeyId: key.id,
        configValueId: null,
        value: textInputField._value
      })
    }
  }
  return textInputField;
}

// Adding input field helper function. add input form field, set default value to configKeyValues, register dropdown, register input buttons
export function addInputField(state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, input: InputFieldType) {
  if (!input) return
  state.formInputs.push(input)
  state.keyInputMap[input[INPUT_FIELD_KEY]] = input

  if(input.buttons) {
    (input.buttons as Array<ButtonType>).forEach((button: ButtonType) => {
      state.inputActionButtons.push(button)
      if(button.visitor) button.visitor(state, button)
    })
  }

  state.handleInputChange({configKeyName: input.display_text, value: input._value})
}

export function disableInputField(input: InputFieldType) {
  input.disable = true
  return input
}


export function removeInputField(state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, input: InputFieldType) {
  if (!input) return
  state.formInputs = state.formInputs.filter(i => i[INPUT_FIELD_KEY] !== input[INPUT_FIELD_KEY])
  delete state.keyInputMap[input[INPUT_FIELD_KEY]]

  if(input.buttons) {

    (input.buttons as Array<ButtonType>).forEach((button: ButtonType) => {
      state.inputActionButtons = state.inputActionButtons.filter(b => b !== button)
    })
  }

  state.handleInputChange({configKeyName: input.display_text, value: input._value})
}


// Calling all button visitors. helper function
export function enableButtons(state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, event: any) {
  state.inputActionButtons.forEach(button => {
    if (button.visitor) button.visitor(state, button)
  })

  state.actionButtons.forEach(button => {
    if (button.visitor) button.visitor(state, button)
  })
}

export function inputsVisitor(state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, event: any) {
  state.formInputs.forEach(input => {
    if (input.visitor) (input.visitor as Function)(state, input);
  })
}

// Check dropdown sideEffect function
export function checkDropdown(state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, event: any) {
  if (event.configKeyName && state.keyInputMap[event.configKeyName]) {
    // console.table(state.dropdownsData[event.configKeyName].filter(option => option.isSelected))
  }
}

// Close button
export const CloseButton: ButtonType = {
  name: 'Cancel',
  disable: false,
  hidden: false,
  status: "basic",
  click: (state, button, event) => {
    state.close()
  }
}

// Create button
export const CreateButton: ButtonType = {
  name: 'Add node',
  disable: false,
  hidden: false,
  status: "primary",
  // icon: 'add',
  class: 'button button-success',
  click: async (state, self, event) => {
    self.disable = true
    self.loading = true
    try {
      await state.save()
    } finally {
      self.disable = false
      self.loading = false
    }
  },
  visitor: (state, button) => {
    let isValid = state.formInputs.reduce((isValid, input) => isValid && input.isValid(), true)

    button.disable = !isValid
  }
}

// Update button
export const UpdateButton: ButtonType = {
  name: 'Update node',
  disable: false,
  hidden: false,
  status: "primary",
  icon:'done',
  class: 'button button-success',
  click: async (state, self, event) => {
    self.disable = true
    self.loading = true
    try {
      await state.save()
    } finally {
      self.disable = false
      self.loading = false
    }
  },
  visitor: (state, button) => {
    let isValid = state.formInputs.reduce((isValid, input) => isValid && input.isValid(), true)

    button.disable = !isValid
  }
}

// Update button
export const PreviewButton: ButtonType = {
  name: 'Preview',
  disable: false,
  hidden: false,
  status: "primary",
  click: async (state, self, event) => {
    self.disable = true
    self.loading = true
    try {
      state.output.emit({type: OutputEventEnum.OPEN_PREVIEW, data: {event}})
    } finally {
      self.disable = false
      self.loading = false
    }
  },
  visitor: (state, button) => {

    if(state instanceof AddTaskComponent) {
      button.hidden = true
    }

    if(state instanceof UpdateTaskComponent) {
      // button.disable = state.currentNode.taskConfigDetails.name !== 'sink_api'
      // button.hidden = state.currentNode.taskConfigDetails.name !== 'sink_api'
    }
  }
}

// Run Task button
export const RunTaskButton: ButtonType = {
  name: 'Run',
  disable: false,
  hidden: false,
  status: "primary",
  marginLeft: 'auto',
  class: 'button button-basic',
  click: async (state, self, event) => {

    if(state instanceof UpdateTaskComponent) {
      self.disable = true
      self.loading = true
      try {
        const response = await state.taskService.runNode(state.pipelineId, state.processId, state.currentNode.id, false).toPromise()
        if(response.status === 'success') {
          state.uiService.showInfo('Run request submitted!', 'success')
        }
        else {
          state.uiService.showError('Failed to run task!')
        }
      } finally {
        self.disable = false
        self.loading = false
      }
    }

    if(state instanceof AddTaskComponent) {
      console.log('Run Task API not implemented')
    }

  },
  visitor: (state, button) => {

  }
}


export const RetryTaskButton: ButtonType = {
  name: 'Retry',
  disable: false,
  hidden: true,
  status: "primary",
  class: 'button button-basic',
  click: async (state, self, event) => {

    if(state instanceof UpdateTaskComponent) {
      self.disable = true
      self.loading = true
      try {
        const response = await state.taskService.runNode(state.pipelineId, state.processId, state.currentNode.id, true ).toPromise()
      } finally {
        self.disable = false
        self.loading = false
      }
    }

    if(state instanceof AddTaskComponent) {
      console.log('Run Task API not implemented')
    }

  },
  visitor: (state, button) => {
    if(state instanceof UpdateTaskComponent)
    {
      if(state.configKeyResponse.name.toLocaleLowerCase() === 'sink_api')
      {
        let nodeStatus=state.nodeStatusService.nodeStatus$.getValue().find(x=>x.task_node_id===state.currentNodeId)
        button.hidden = nodeStatus?.status !== 'success'
      }

      else
      {
        button.hidden=true
      }

    }

  }
}
// Task name input field
export function getNameInputField(): InputFieldType {
  const input: InputFieldType = {
    [INPUT_FIELD_KEY]: 'name',
    hidden: false,
    type: 'text',
    multiple: false,
    _value: '',
    display_text: 'Name',
    placeholder: 'name',
    options: [],
    isValid() {
      return ((input._value as string) || '').length > 0
    },
    async getValue() {
      return Promise.resolve(input._value)
    }
  }
  return input
}


// Task reference input field
// TODO: V2 Reference node type need to discuss
// export function getReferenceInputField(state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent): InputFieldType {
//   let referenceNodes:Array<number> = []

//   // Add Dependent input
//   let options:Array<DropdownOptionType> = Object.keys(state.pipeline.taskNodes)
//     .filter(x => state.pipeline.taskNodes[x].config_id !== state.configKeyResponse.id)
//     .map(key => ({
//       id:state.pipeline.taskNodes[key].id,
//       value: state.pipeline.taskNodes[key].name+'_'+state.pipeline.taskNodes[key].id,
//       isSelected: referenceNodes.includes(state.pipeline.taskNodes[key].id),
//       isBoolean: state.pipeline.taskNodes[key].taskConfigDetails.type === 'conditional',
//       booleanValue: 1,
//     }))

//   if(state instanceof UpdateTaskComponent) {
//     options = options.filter(x => x.id !== state.currentNode.id)
//   }

//   const dependentNodeInputField: InputFieldType = {
//     [INPUT_FIELD_KEY]: 'reference',
//     hidden: false,
//     type: 'dropdown',
//     display_text: 'Reference node',
//     placeholder: '',
//     multiple: false,
//     options: options,
//     events: new BehaviorSubject<{type:string, args: Array<unknown>}>({type: 'init', args: []}),
//     __value: null,
//     get _value() {
//       return dependentNodeInputField.__value as string | null
//     },
//     set _value(value: string | null) {
//       dependentNodeInputField.__value = value
//       dependentNodeInputField.options.map(x => x.isSelected = `${x.id}`=== `${value}`)
//     },
//     get _selected() {
//       return dependentNodeInputField.options.filter(x=>x.isSelected)
//     },
//     isValid() {
//       return !!dependentNodeInputField.__value
//     },
//     async getValue() {
//       const {id:selectedNodeId} = dependentNodeInputField.options.find(x => x.isSelected) || {isSelected: true, id: null, value: null}
//       return Promise.resolve(selectedNodeId)
//     },
//     eventHandlers: [],
//   }


//   const eventHandler = async (state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, event: {type:string, args: Array<unknown>}) => {
//   }
//   dependentNodeInputField.eventHandlers?.push(eventHandler)
//   return dependentNodeInputField
// }

// Dependent dropdown
// FIXME: remove commented code. V2 api handels dependency by edge connection
// export function getDependentInputField(state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent): InputFieldType {
//   let dependentNodes:Array<number> = []
//   if(state instanceof AddTaskComponent && ["management"].includes(state.configKeyResponse.type)) {
//     dependentNodes = state.parentNodeIds
//   }

//   // Add Dependent input
//   let options:Array<DropdownOptionType> = Object.keys(state.pipeline.taskNodes)
//     .map(key => ({
//       id:state.pipeline.taskNodes[key].id,
//       value: state.pipeline.taskNodes[key].name+'_'+state.pipeline.taskNodes[key].id,
//       isSelected: dependentNodes.includes(state.pipeline.taskNodes[key].id),
//       isBoolean: state.pipeline.taskNodes[key].taskConfigDetails.type === 'conditional',
//       booleanValue: 1,
//     }))

//   if(state instanceof UpdateTaskComponent) {
//     options = options.filter(x => x.id !== state.currentNode.id)
//   }

//   const dependentNodeInputField: InputFieldType = {
//     [INPUT_FIELD_KEY]: 'dependent',
//     hidden: false,
//     type: 'dropdown_multi',
//     display_text: 'Dependent',
//     placeholder: '',
//     multiple: true,
//     options: options,
//     events: new BehaviorSubject<{type:string, args: Array<unknown>}>({type: 'init', args: []}),
//     __value: [],
//     get _value() {
//       return dependentNodeInputField.__value as Array<string|number|null>
//     },
//     set _value(value: Array<string|number|null>) {
//       dependentNodeInputField.__value = value
//       dependentNodeInputField.options.map(x => x.isSelected = value.includes(x.id))
//     },
//     get _selected() {
//       return dependentNodeInputField.options.filter(x=>x.isSelected)
//     },
//     isValid() {
//       return true
//     },
//     async getValue() {
//       let order: Array<number> = dependentNodeInputField.__value as Array<number> || []
//       let orderIndexMap = order.reduce((prev, curr, i) => { prev[curr]=i; return prev }, {} as {[key: number]: number})
          
//       return Promise.resolve(
//         dependentNodeInputField.options
//           .filter(x=>x.isSelected)
//           .map(x => ({
//             taskId: x.id,
//             type: threeStateToBool(x.booleanValue as 0|1|2) === null ? "run_dependent" : "conditional",
//             weight: threeStateToBool(x.booleanValue as 0|1|2)
//           }))
//           // re-arrange options in user selected order
//           .reduce((prev, curr) => {
//             prev[orderIndexMap[curr.taskId as number]] = curr
//             return prev
//           }, [] as Array<any>)
//       )
//     },
//     eventHandlers: [],
//   }


//   const eventHandler = async (state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, event: {type:string, args: Array<unknown>}) => {
//   }
//   dependentNodeInputField.eventHandlers?.push(eventHandler)
//   return dependentNodeInputField
// }

// DataDependent dropdown
// FIXME: remove commented code. V2 api handels dependency by edge connection
// export function getDataDependentInputField(state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent): InputFieldType {

//   let dataDependentNodes:Array<number> = []
//   if(state instanceof AddTaskComponent) {
//     dataDependentNodes = state.parentNodeIds
//   }

//   // Add Dependent input
//   let options:Array<DropdownOptionType> = Object.keys(state.pipeline.taskNodes)
//     .map(key => ({
//       id:state.pipeline.taskNodes[key].id,
//       value: state.pipeline.taskNodes[key].name+'_'+state.pipeline.taskNodes[key].id,
//       isSelected: dataDependentNodes.includes(state.pipeline.taskNodes[key].id),
//       isBoolean: state.pipeline.taskNodes[key].taskConfigDetails.type === 'conditional',
//       booleanValue: 1
//     }))

//   if(state instanceof UpdateTaskComponent) {
//     options = options.filter(x => x.id !== state.currentNode.id)
//   }

//   const dataDependentNodeInputField: InputFieldType = {
//     [INPUT_FIELD_KEY]: 'data_dependent',
//     hidden: false,
//     type: 'dropdown_multi',
//     display_text: 'Data Dependent',
//     placeholder: '',
//     multiple: true,
//     options: options,
//     events: new BehaviorSubject<{type:string, args: Array<unknown>}>({type: 'init', args: []}),
//     __value: [],
//     get _value() {
//       return dataDependentNodeInputField.__value as Array<string|number|null>
//     },
//     set _value(value: Array<string|number|null>) {
//       dataDependentNodeInputField.__value = value
//       dataDependentNodeInputField.options.map(x => x.isSelected = value.includes(x.id))
//     },
//     get _selected() {
//       return dataDependentNodeInputField.options.filter(x=>x.isSelected)
//     },
//     isValid() {
//       return options.filter(x => x.isSelected).length > 0
//     },
//     async getValue() {
//       let order: Array<number> = dataDependentNodeInputField.__value as Array<number> || []
//       let orderIndexMap = order.reduce((prev, curr, i) => { prev[curr]=i; return prev }, {} as {[key: number]: number})
          
//       return Promise.resolve(
//         dataDependentNodeInputField.options
//           .filter(x=>x.isSelected)
//           .map(x => ({
//             taskId: x.id,
//             type: threeStateToBool(x.booleanValue as 0|1|2) === null? "data" : "conditional_data",
//             weight: threeStateToBool(x.booleanValue as 0|1|2)
//           }))
//           // re-arrange options in user selected order
//           .reduce((prev, curr) => {
//             prev[orderIndexMap[curr.taskId as number]] = curr
//             return prev
//           }, [] as Array<any>)
//       )
//     }
//   }
//   return dataDependentNodeInputField
// }


// Union dropdown
// FIXME: remove commented code, V2 API does not require Union dropdown. (dynamic form can handel it from backend)
// export function getUnionInputField(state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent): InputFieldType {
//   let unionDependentNodes:Array<number> = []
//   if(state instanceof AddTaskComponent) {
//     unionDependentNodes = state.parentNodeIds
//   }

//   // Add Dependent input
//   let options:Array<DropdownOptionType> = Object.keys(state.pipeline.taskNodes)
//     .map(key => ({
//       id:state.pipeline.taskNodes[key].id,
//       value: state.pipeline.taskNodes[key].name+'_'+state.pipeline.taskNodes[key].id,
//       isSelected: unionDependentNodes.includes(state.pipeline.taskNodes[key].id),
//       isBoolean: false,
//       booleanValue: 1,

//     }))
//   if(state instanceof UpdateTaskComponent) {
//     options = options.filter(x => x.id !== state.currentNode.id)
//   }

//   const unionNodeInputField: InputFieldType = {
//     [INPUT_FIELD_KEY]: 'union',
//     hidden: false,
//     type: 'dropdown_multi',
//     display_text: 'Union',
//     placeholder: '',
//     multiple: true,
//     options: options,
//     events: new BehaviorSubject<{type:string, args: Array<unknown>}>({type: 'init', args: []}),
//     get _value() {
//       return unionNodeInputField.__value as Array<string|number|null>
//     },
//     set _value(value: Array<string|number|null>) {
//       unionNodeInputField.__value = value
//       unionNodeInputField.options.map(x => x.isSelected = value.includes(x.id))
//     },
//     get _selected() {
//       return unionNodeInputField.options.filter(x=>x.isSelected)
//     },
//     isValid() {
//       return true
//     },
//     async getValue() {
//       let order: Array<number> = unionNodeInputField.__value as Array<number> || []
//       let orderIndexMap = order.reduce((prev, curr, i) => { prev[curr]=i; return prev }, {} as {[key: number]: number})
          
//       return Promise.resolve(
//         unionNodeInputField.options
//           .filter(x=>x.isSelected)
//           .map(x => ({
//             taskId: x.id,
//             type: 'data',
//           }))
//           // re-arrange options in user selected order
//           .reduce((prev, curr) => {
//             prev[orderIndexMap[curr.taskId as number]] = curr
//             return prev
//           }, [] as Array<any>)
//       )
//     },

//     eventHandlers:[]
//   }

//   const eventHandler = async (state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, event: {type:string, args: Array<unknown>}) => {
//     return Promise.resolve()
//   }

//   unionNodeInputField.eventHandlers?.push(eventHandler)
//   return unionNodeInputField
// }

// transform state to payload
export async function transformData_v1(state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent) {
  const payload:any = {
    pipelineId: state.pipelineId,
    processId: state.processId,
    name: await state.keyInputMap.name.getValue(),
    configId: state.type.id,
    configs: [],
    dependent_nodes: []
  }

  // transform configs
  for(let key of state.configKeyResponse.configs) {
    if(!key.is_node_specific && state.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]) {
      payload.configs.push(await state.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]].getValue())
    }

    if(key.is_node_specific) {
      let valueArray = await state.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]].getValue()
      payload.configs = payload.configs.concat(valueArray)
    }
  }

  // transform dependent_nodes
  for(const dropdownId of ["dependent", "data_dependent", "union", "management"]) {
    if(!state.keyInputMap[dropdownId]) continue;

    (await state.keyInputMap[dropdownId].getValue() as Array<unknown>).forEach(el => {
      payload.dependent_nodes.push(el)
    })
  }

  // // manual transform
  // // if(["source", "transformation", "sink", "validation", "union", "conditional"].includes(state.configKeyResponse.type)) {
  // //   (await state.keyInputMap['dependent'].getValue() as Array<unknown>).forEach(el => {
  // //     payload.dependent_nodes.push(el)
  // //   });
  // // }
  // //
  // // if(["source", "transformation", "sink", "validation", "union", "conditional"].includes(state.configKeyResponse.type)) {
  // //   (await state.keyInputMap['data_dependent'].getValue() as Array<unknown>).forEach(el => {
  // //     payload.dependent_nodes.push(el)
  // //   });
  // // }
  // //
  // // if(["union"].includes(state.configKeyResponse.type)) {
  // //   (await state.keyInputMap['union'].getValue() as Array<unknown>).forEach(el => {
  // //     payload.dependent_nodes.push(el)
  // //   });
  // // }


  //schema configs
  // FIXME: remove fallback: || (state.schemaProperties as any).schemaConfigs
  if(state.schemaProperties && (state.schemaProperties.schema || state.schemaProperties as any)) {
    payload["schemaConfigs"] = {
       schemaDetails: state.schemaProperties?.schema
    }
    //state.schemaProperties?.schema?.["schemaConfigs"] || (state.schemaProperties as any).schemaConfigs.schemaDetails
  }

  // Reference node data
  if(['management'].includes(state.configKeyResponse.type) && ['reference_node'].includes(state.configKeyResponse.name)) {
    payload.parent_id = await state.keyInputMap.reference.getValue()
    payload.is_reference = true
    payload.configs = []
  }


  return payload
}

/**
 * get userDefineConfigValues
 * @param state
 * @returns
 */
export async function userDefineConfigValues(state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, userInputId: number|string) {
  const inputs = state.formInputs.filter(x => x[INPUT_FIELD_CONFIG_KEY] === userInputId);
  if(inputs.length === 0) return [];

  return await Promise.all(inputs.map(x => x.getValue()))
}

// create unique element selector sideEffect from dropdown array
export function getSingleDropdownSelector(state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, dropdowns: Array<InputFieldType>) {

  const invalidInputs = dropdowns.filter(x => !(['dropdown_multi', 'dropdown'].includes(x.type))).map(x => x.type)
  if(invalidInputs.length > 0) {
    console.error(`Invalid arguments found.\nExpected dropdown found:${JSON.stringify(invalidInputs)}`)
    return () => {}
  }


  const dropdownIds = dropdowns.map(x => x[INPUT_FIELD_KEY])

  // save all dropdowns and mark as unselected to use it later
  let dropdownOptions:{[key: number]: Array<DropdownOptionType>, [key: string]: Array<DropdownOptionType>} = {}
  for(let dropdownId of dropdownIds) {
    dropdownOptions[dropdownId] = state.keyInputMap[dropdownId].options.map(x => ({...x, isSelected: false}))
  }

  return function (state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent, event: any) {

    // ignore other events
    if(event.type !== 'INIT' && !dropdownIds.includes(event.configKeyName)) return

    // get selected values from all dropdowns
    let selectedValueMap: {[key: number]: {dropdownId:string|number, data: DropdownOptionType}} = {}

    for(let dropdownId of dropdownIds) {
      if(!Array.isArray(state.keyInputMap[dropdownId].options)) {
        console.warn(`dropdownId [${dropdownId}] not found!`)
        continue
      }
      selectedValueMap = state.keyInputMap[dropdownId].options.filter(x => x.isSelected).reduce((prev, current) => {
        prev[current.id as number] = {dropdownId, data: current}
        return prev
      }, selectedValueMap)
    }

    for(let dropdownId of dropdownIds) {
      if(!Array.isArray(dropdownOptions[dropdownId])) {
        console.warn(`dropdownId [${dropdownId}] not found!`)
        continue
      }
      let latestValue = dropdownOptions[dropdownId].filter(x => selectedValueMap[x.id as number] === undefined || selectedValueMap[x.id as number].dropdownId === dropdownId).map(x => {
        if(selectedValueMap[x.id as number]) {
          return selectedValueMap[x.id as number].data
        }
        return {...x}
      })

      state.keyInputMap[dropdownId].options.splice(0, state.keyInputMap[dropdownId].options.length)
      latestValue.forEach(option => state.keyInputMap[dropdownId].options.push(option))
    }

  }
}

// return event handlers for each input type
export const getInputHandlers = (state: AddTaskComponent|UpdateTaskComponent|CopyTaskComponent) => {
  return {

    textInputEvent(input: InputFieldType, event: any) {
      state.handleInputChange({configKeyName: input.id, value: input._value})
    },

    fileInputEvent(input: InputFieldType, event: any) {
      input._url = null
      if (event.target.files?.length) {
        input._value = event.target.files[0]
        state.handleInputChange({configKeyName: input.id, value: input._value})
      } else {
        input._value = null
        state.handleInputChange({configKeyName: input.id, value: input._value})
      }
    },

    dropdownInputEvent(input: InputFieldType, event: any) {
      state.handleInputChange({configKeyName: input.id, value: input._selected})
    },

    async dropdownMultiInputEvent(input: InputFieldType, event: any) {
      console.log(event)
      for(let handler of input.eventHandlers||[]) {
        await handler(state, event)
      }
      if(event.type === 'on-incoming-node-change' || event.type === 'on-index-change') {
        input._value = event.args[0]
        state.handleInputChange({configKeyName: input.id, value: input._selected})
      }
      if(event.type === 'show-details') {
        let item = event.args[0]
        console.log(item)
      }
    },

    buttonInputEventHandler(button: ButtonType, event: MouseEvent) {
      button.click(state, button, event)
    },

    textareaInputEventHandler(input: InputFieldType, event: MouseEvent) {
      state.handleInputChange({configKeyName: input.id, value: input._value})
    },

    codeMirrorInputEventHandler(input: InputFieldType, event: any) {
      console.log(event)
      // state.handleInputChange({configKeyName: input.id, value: input._value})
    }
  }
}

export const getDefaultFieldsName = (type: ConfigNodeTypes | string) => {
  const fieldNames = [] as Array<'dependent'|'data-dependent'|'union'|'reference_node'>

  if(["source", "transformation", "sink", "validation", "union", "conditional", "management"].includes(type)) {
    fieldNames.push('dependent')
  }

  // Add Data Dependent input - required for all except [source|union] node
  if(!['source', 'union', "management"].includes(type)) {
    fieldNames.push('data-dependent')
  }

  // Add Union input
  if(["union"].includes(type)) {
    fieldNames.push('union')
  }

  // Reference node
  if(['management'].includes(type)) {
    fieldNames.push('reference_node')
  }

  return fieldNames
}

export const LoadDefaultConfigurations = (state:AddTaskComponent|UpdateTaskComponent|CopyTaskComponent) =>{

  // Add name input
  addInputField(state, getNameInputField())


  /* FIXME: V2 API changes
  // Add Dependent input -  required for all [source|transformation|sink|validation|union|conditional]
  if(["source", "transformation", "sink", "validation", "union", "conditional", "management"].includes(state.configKeyResponse.type)) {
    addInputField(state, getDependentInputField(state))
  }

  // Add Data Dependent input - required for all except [source|union] node
  if(!['source', 'union', "management"].includes(state.configKeyResponse.type)) {
    addInputField(state, getDataDependentInputField(state))
  }

  // Add Union input
  if(["union"].includes(state.configKeyResponse.type)) {
    addInputField(state, getUnionInputField(state))
  }

  // Reference node
  if(['management'].includes(state.configKeyResponse.type) && ['reference_node'].includes(state.configKeyResponse.name)) {
    addInputField(state, getReferenceInputField(state))
  }
  // */
}

export function formatSql(sql: string) {
  return format(sql, {
    language: 'mysql',  // (sql | mariadb | mysql | postgresql | db2 | plsql | n1ql | redshift | spark | tsq)
    indent: ' ',     // Defaults to two spaces
    uppercase: true,    // Defaults to false
    linesBetweenQueries: 2, // Defaults to 1
  })
}

<div style="padding: 2rem;" [nbSpinner]="uiLoading" nbSpinnerStatus="info" nbSpinnerSize="large">
<header class="page-header">
    <div style="display:flex;">
        <div style="margin-right:1rem"><img style="height:3rem;width:3rem;border-radius: 20%;" [src]="nodeData.image" alt=""></div>
        <div class="node-name">{{nodeData.name}}
            <!-- <span>
                <nb-icon style="font-size:x-large;" icon="edit-outline"></nb-icon>
            </span> -->
            <span style="font-size:x-small;font-weight:100;">Editing Version {{versionNum}}</span>
        </div>
    </div>
    <div>
        <button (click)="saveConnector()" nbTooltip="Save Connector Version" status="success" style="margin-right:1rem" nbButton>Save</button>
        <button (click)="releaseConnector()" nbTooltip="Release latest version" outline status="success" style="margin-right:1rem" nbButton>Release latest version</button>
        <button nbTooltip="Delete Node" class="danger-btn" nbButton><nb-icon icon="trash-outline"></nb-icon></button>
    </div>
</header>
<hr>
  
      <nb-tabset>
        <nb-tab tabTitle="Custom Node Code">
                  <div style="display: flex;height: 80vh;">
                    <div style="flex:1;display: flex;flex-direction: column;align-items: center;background-color: #dedee5;">
                      <div (click)="sidenavPageSelected='editor'" class="side-icons" [class.active]="sidenavPageSelected==='editor'" nbTooltip="Editor" nbTooltipPlacement="right" ><nb-icon style="font-size: xx-large;font-weight: bold;" icon="code-outline"></nb-icon></div>
                      <div (click)="sidenavPageSelected='debugger'" class="side-icons" [class.active]="sidenavPageSelected==='debugger'" nbTooltip="Debugger" nbTooltipPlacement="right" ><nb-icon style="font-size: xx-large;font-weight: bold;" icon="layout-outline"></nb-icon></div>
                    </div>
                    <div style="flex:25">
                      <app-file-explorer [nbSpinner]="editorLoading"
                      nbSpinnerStatus="info"
                      nbSpinnerSize="large"
                      nbSpinnerMessage="Providing resources for Connector SDK" [files]="files" [class.hidden]="sidenavPageSelected==='debugger'"></app-file-explorer>
                      <ng-component *ngTemplateOutlet="debuggerPage"></ng-component>
                      <!-- <ng-container [ngSwitch]="sidenavPageSelected">
                        <ng-container *ngSwitchCase="'editor'">
                            <app-file-explorer></app-file-explorer>
                        </ng-container>
                        <ng-container *ngSwitchCase="'debugger'">
                            <ng-component *ngTemplateOutlet="debuggerPage"></ng-component>
                        </ng-container>
                    </ng-container> -->
                    </div>
                  </div>

        </nb-tab>
        <nb-tab tabTitle="Versions">
          <mat-paginator
              [length]="totalCount"
              [pageSize]="pageSize"
              [pageIndex]="pageIndex"
              [pageSizeOptions]="[5, 10, 20, 50, 100]"
              [showFirstLastButtons]="true"
              aria-label="Select page"
              (page)="pageChange($event)">
          </mat-paginator>
              <table style="width:100%" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef> Version Id </th>
                  <td mat-cell *matCellDef="let element"> {{element.versionNum}} </td>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="updatedBy">
                  <th mat-header-cell *matHeaderCellDef> Updated By </th>
                  <td mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
                </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="updatedOn">
                  <th mat-header-cell *matHeaderCellDef> Updated On </th>
                  <td mat-cell *matCellDef="let element"> {{element.createdTime*1000 | date:'yyyy-MM-dd HH:mm:ss'}} </td>
                </ng-container>
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="comments">
                  <th mat-header-cell *matHeaderCellDef> Comments </th>
                  <td mat-cell *matCellDef="let element"> {{element.comments}} </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row (click)="openNodeVersion(row,dialog)" *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
        </nb-tab>
        <nb-tab tabTitle="More" disabled>
          <p>List of <strong>transactions</strong>.</p>
        </nb-tab>
      </nb-tabset>

</div>


<ng-template #editorPage>
    <!-- <header style="display: flex;height: 5rem;justify-content: space-between;">
        <div></div>
        <div>
            <nb-icon icon="play-outline"></nb-icon>
        </div>
    </header> -->
    <section style="display: flex;">
        <div style="flex:1;">
            <nb-card size="medium" style="height: 80vh;">
                <nb-card-header style="display:flex;justify-content: space-between;">
                  <div>Files</div>
                  <nb-icon style="cursor:pointer;" nbTooltip="Add file" icon="plus-outline"></nb-icon>
                </nb-card-header>
                <nb-list>
                  <nb-list-item *ngFor="let file of files"class="list-item" >
                    <div>{{file}}</div>
                    <nb-icon nbTooltip="Delete File" class="hover-icon" icon="trash-outline"></nb-icon>
                  </nb-list-item>
                </nb-list>
              </nb-card>
        </div>
        <div style="flex:4;height: 80vh;overflow-y: scroll;">
            <app-code-editor-options></app-code-editor-options>
        </div>
    </section>
</ng-template>

<ng-template #debuggerPage>
    <div style="display: flex;height:100%" [class.hidden]="sidenavPageSelected==='editor'">
        <nb-card style="margin-bottom: 0 !important;width:100%">
            <nb-card-header>
                <div style="display: flex;">
                    <span style="margin-right:0.25rem">Debugger</span> <span class="material-symbols-outlined">adb</span>
                </div>
                
            </nb-card-header>
            <nb-card-body>
              <nb-select placeholder="Node Connection fields" fullWidth="true">
                <nb-option value="">Option empty</nb-option>
                <nb-option value="0">Option 0</nb-option>
                <nb-option value="1">Option 1</nb-option>
                <nb-option value="2">Option 2</nb-option>
                <nb-option value="3">Option 3</nb-option>
                <nb-option value="4">Option 4</nb-option>
                <nb-option value="0">Option 0</nb-option>
                <nb-option value="1">Option 1</nb-option>
                <nb-option value="2">Option 2</nb-option>
                <nb-option value="3">Option 3</nb-option>
                <nb-option value="4">Option 4</nb-option>
              </nb-select>
              <br>
              <br>
              <nb-select placeholder="Node Services" fullWidth="true">
                <nb-option value="">Option empty</nb-option>
                <nb-option value="0">Option 0</nb-option>
                <nb-option value="1">Option 1</nb-option>
                <nb-option value="2">Option 2</nb-option>
                <nb-option value="3">Option 3</nb-option>
                <nb-option value="4">Option 4</nb-option>
                <nb-option value="0">Option 0</nb-option>
                <nb-option value="1">Option 1</nb-option>
                <nb-option value="2">Option 2</nb-option>
                <nb-option value="3">Option 3</nb-option>
                <nb-option value="4">Option 4</nb-option>
              </nb-select>
            </nb-card-body>
            <nb-card-footer>
              <nb-icon style="color:rgb(255 221 0) !important" icon="bulb-outline"></nb-icon>Connections and Services
            </nb-card-footer>
          </nb-card>

          <nb-card style="margin-bottom:0 !important;width: 100%;">
            <nb-card-header>
                <div style="display: flex;">
                    <span style="margin-right:0.25rem">Console</span> <span class="material-symbols-outlined">terminal</span>
                </div>
            </nb-card-header>
            <nb-card-body>
            </nb-card-body>
            <nb-card-footer>
              <nb-icon style="color:red !important;margin-right:0.25rem" icon="alert-circle-outline"></nb-icon>Test the connection to see some output !!
            </nb-card-footer>
          </nb-card>
    </div>
</ng-template>

<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card style="height:100vh;width:100vw">
    <nb-card-header style="display:flex;justify-content: space-between;align-items: center;">
      <h6>Connector Version {{data.row.id}} </h6>
      <div>
        <button nbButton status="success" (click)="ref.close('restore')">Restore</button>
        <button nbButton style="margin-left:0.5rem;" status="danger" (click)="ref.close()"><nb-icon icon="close-outline" ></nb-icon></button>
      </div>
    </nb-card-header>
  
    <nb-card-body>
      <app-file-explorer #fileExplorer [files]="restoreFiles" [readOnly]="true"></app-file-explorer>
    </nb-card-body>
  </nb-card>
</ng-template>
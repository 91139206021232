import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ExcelExportParams, RowSelectedEvent } from 'ag-grid-community';
import { Pipelinev2Service } from 'src/app/services/api/v2/pipelinev2.service';
@Component({
  selector: 'app-show-output',
  templateUrl: './show-output.component.html',
  styleUrls: ['./show-output.component.scss']
})
export class ShowOutputComponent implements OnInit {

  @Input() nodeOutput:any
  @Input() nodeOutputError:any
  @Input() downloadFileUrl:string = ''
  selectedRowIndex: number | null = null;
  selectedColumn: any
  selectedColumnIndex: string | null = null
  selectedCell: any
  public gridApi: any;
  public gridColumnApi: any;
  isFormulaBarFocused = false;
  columnDefs: Array<any> = []
  pipelineName: string = ''

  @ViewChild('formulaInput', { static: false })
  public rowSelection: 'single' | 'multiple' = 'multiple';

  formulaInput!: ElementRef;
  columns = [
    {
      "column_name": "trace_id",
      "data_type": "string",
      "order": 1,
      "column_id": 1
    },
    {
      "column_name": "id",
      "data_type": "number",
      "order": 2,
      "column_id": 2
    },
    {
      "column_name": "akshit",
      "data_type": "string",
      "order": 3,
      "column_id": 3  
    },
  ]
  //we need to get first object by default so that we can get column name in begining 
    rowData = [
      {
        "trace_id": "trace_id",
        "id": "id",
        "akshit":"ds"
      },
    ]


  selectedCellValue: string = ''
  @ViewChild('agGrid') agGrid: AgGridAngular | undefined;

  lastSelectedCellData = ''
  lastSelectedRowData = {}
  @HostListener('window:keydown',['$event'])
  onKeyPress($event: KeyboardEvent) {
    if(($event.ctrlKey || $event.metaKey) && $event.altKey && $event.keyCode == 67) {
      this.copyLastSelectedRow()
    }
    if(($event.ctrlKey || $event.metaKey) && !$event.altKey && $event.keyCode == 67) {
      this.copyLastSelectedCell()
    }
    // if(($event.ctrlKey || $event.metaKey) && $event.keyCode == 86) {
    // }
  }

  copyLastSelectedCell() {
    // this.contextMenu.nativeElement.classList.remove("visible")
    if(this.lastSelectedCellData) {
      navigator.clipboard.writeText(this.lastSelectedCellData)
    }
  }

  copyLastSelectedRow() {
    // this.contextMenu.nativeElement.classList.remove("visible")
    let data = '';
    let selectedRowIndexes = Object.keys(this.gridApi.selectionController.selectedNodes).filter(i => this.gridApi.selectionController.selectedNodes[i] !== undefined)
    if (selectedRowIndexes.length > 0) {
      let firstNode = this.gridApi.selectionController.selectedNodes[selectedRowIndexes[0]]
      data = Object.keys(firstNode.data).join('\t') + '\t\n'

      for (let i of selectedRowIndexes) {
        data += Object.values(this.gridApi.selectionController.selectedNodes[i].data).join('\t') + '\t\n'
      }
      navigator.clipboard.writeText(data)
    }
  }

  constructor(private route:ActivatedRoute, private http: HttpClient, public ElementRef: ElementRef, private pipelineService:Pipelinev2Service) {

  }
  public tooltipShowDelay = 0;
  public tooltipHideDelay = 1000;
  // public rowData: Array<any> = [];
  // public columns:Array<any> = []

  ngOnInit(): void {
    const pipelineId = this.route.snapshot.queryParams['pipelineId']
    this.loadpipelineDetails(pipelineId)
    //get data from API nodeOuput
    this.rowData = this.nodeOutput.data
    const slCol = {
      headerName: ' ',
      field: 'sl',
      width: 50,
      pinned: true,
      lockPosition: true,
      cellClass: 'locked-col',
      suppressNavigable: true,
      suppressMenu: true,
      resizable: true,
      valueGetter: function (params: any) {
        return params.node.rowIndex + 1;
      },
    };

    this.columnDefs.push(slCol as any);

    const dynamicColumns = this.nodeOutput.columns.map((x:any) => ({
        headerName: this.getExcelColumnLabel(parseInt(x.order)),
        field: x.column_name,
        editable: false,
        enableCellTextSelection:true,
        filter:true,
        // tooltipField: String.fromCharCode('A'.charCodeAt(0) +  parseInt(x.order)-1),
        tooltipComponent: ((args: any) => { console.log(args) }) as any,//CustomCellRendererComponent as any,
        width: 150,
        cellStyle: (params: any) => {
          const isEffected = this.nodeOutputError.some((error:any) => error.effected_cell === (params.colDef.headerName + (params.node.rowIndex+1).toString()));
           if (isEffected) {
            return { backgroundColor: 'rgb(255 128 171 / 15%)' };
          } else {
            if (params.node.rowIndex === 0) {
              return { fontWeight: 'bold' };
            } else {
              return null;
            }
          }
        },
        order:x.order
      })).sort((a:any, b:any) => parseInt(a.order) - parseInt(b.order));

    this.columnDefs = [slCol, ...dynamicColumns];

  }

  getExcelColumnLabel(number: number): string {
    let label = '';
    while (number > 0) {
        const remainder = (number - 1) % 26;
        label = String.fromCharCode(65 + remainder) + label;
        number = Math.floor((number - 1) / 26);
    }
    return label;
}

async loadpipelineDetails(id: number) {
  const data = await this.pipelineService.getPipelineById(id).toPromise()
  const pipelineName = data.payload.label
  this.pipelineName = pipelineName

}

  async createUrl( uri:string , Filename:string = "Data_load"){
    console.log(uri)
    let url = await this.pipelineService.getDownloadPublicUrl(uri, Filename).toPromise()
    console.log(url)
    let a = document.createElement("a")
    a.download = Filename
    a.href = url.URL
    a.click()  
  }


  onCellClicked(params: any) {
    this.lastSelectedCellData = params.value
    this.selectedCell = params
    console.log(params)
    this.selectedRowIndex = params.rowIndex;
    this.selectedColumnIndex = params.colDef.headerName
    this.selectedColumn = params.colDef.field;
    this.selectedCellValue = params.data[this.selectedColumn];
    this.formulaInput = this.selectedCellValue as any
    
  }


  onRowSelected(event: RowSelectedEvent) {
    this.lastSelectedRowData = event.data
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onCellValueChanged(params: any) {
    // const newValue = params.newValue;
    // const focusedCell = this.gridApi.getFocusedCell();
    // if (focusedCell && focusedCell.rowIndex === params.rowIndex && focusedCell.column.colId === params.column.colId) {
    //   this.selectedCellValue = newValue;
    //   this.formulaInput.nativeElement.value = newValue;
    // }
  }


  onCellEditingStarted(event: any) {
    const selectedCell = this.gridApi.getFocusedCell();
    if (selectedCell && selectedCell.node) {
      const selectedCellValue = selectedCell.node.data[event.column.colId];
      this.selectedCellValue = selectedCellValue;
    }
    const cellEditor = this.gridApi.getCellEditorInstances()[0];
    if (cellEditor) {
      const inputElement = cellEditor.getGui().querySelector('input');
      inputElement.addEventListener('input', (e: any) => {
        this.formulaInput = e.target.value;
      });
    }
  }

  onCellEditingStopped(event: any) {
    const cellEditor = this.gridApi.getCellEditorInstances()[0];
    if (cellEditor) {
      const inputElement = cellEditor.getGui().querySelector('input');
      inputElement.removeEventListener('input', () => { });
    }

  }

    onColumnSelectionChange(selectedValue: any) {
      if (selectedValue) {
        this.gridColumnApi.setColumnVisible(selectedValue, true);
        this.columnDefs.forEach((column) => {
          if (column.field !== selectedValue) {
            this.gridColumnApi.setColumnVisible(column.field, false);
          }
        });
      } else {
        this.columnDefs.forEach((column) => {
          this.gridColumnApi.setColumnVisible(column.field, true);
        });
      }
    }

  exportToExcel() {
    const params: ExcelExportParams = {
      allColumns: true,
      fileName: 'my-grid-data.xlsx',
    };
    this.gridApi.exportDataAsExcel(params);
  }


  saveData() {
    const rowData: any[] = [];
    this.gridApi.forEachNode((node: { data: any; }) => {
      rowData.push(node.data);
    });
    this.rowData = rowData
    // update the data using API (PUT)
    console.log(rowData)
  }

  gridOptions = {
    defaultColDef: {
      filter: true,
      resizable: true,
      animateRows: true,
      enableCellTextSelection:true,
      // tooltipComponent: CustomCellRendererComponent,
      tooltipComponentParams: { color: '#ffff' },
    },
    columnDefs: this.columnDefs,
    rowData: this.rowData,


    // pagination: true,
    // paginationPageSize: 10
  };

  @Output() onClose = new EventEmitter<void>();

  ontableClose() {
        this.onClose.emit()
      }


}

import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Context} from "../services/config/context.service";
import {ConsoleLoggerService} from "../services/logger/console-logger.service";
import {tap} from "rxjs/operators";

@Injectable()
export class RequestLogInterceptor implements HttpInterceptor {


  constructor(private context: Context, private logger: ConsoleLoggerService) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = new URL(httpRequest.url)
    this.context.emit(`REQUEST.${httpRequest.method}`, {url: url, method: httpRequest.method, state: 'INIT'})

    return next.handle(httpRequest).pipe(
      tap((response) => {
        if (response instanceof HttpResponse) {
          this.context.emit(`REQUEST.${httpRequest.method}`, {
            url: url,
            method: httpRequest.method,
            state: 'END',
            ok: response.ok,
            status: response.status,
            body: response.body
          })
        }
      }, error => {
        this.context.emit(`REQUEST.${httpRequest.method}`, {
          url: url,
          method: httpRequest.method,
          state: 'END',
          status: error.status,
          ok: error.ok,
          statusText: error.statusText,
          error: error.error
        })
      })
    )
  }
}

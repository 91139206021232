import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FileService  } from '../../services/api/file.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {

  constructor(private router: Router, private fileService: FileService) { }

  uploadFile: FormGroup = new FormGroup({
  	files : new FormControl()
  });

  ngOnInit(): void {
  }

  sendFile(){
  	console.log(this.uploadFile.value);
  	this.uploadFile.reset({});

  	this.fileService.uploadFile(this.uploadFile.value);

  	//when nodes are done in dashboard navigate url can be changed
  	this.router.navigateByUrl('/dashboard');

  }

}

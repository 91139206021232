<div class="task-config" style="height: 100%;width: 100%;position: absolute;background-color: white;display: flex;flex-direction: column;border-left: 1px solid #D6D6D6;">
    <header>
    <div style="display: inline-block;font-weight: 600;">
      <span>Create: {{configKeyResponse?.displayName}}</span>
    </div>
  
      <button class="button" style="background-color: unset;" (click)="close()">
        <span class="material-icons-outlined" style="font-size: 16px;">close</span>
      </button>
     
    </header>
  
    <main style="overflow: overlay;">
      <div style="display: flex;flex-direction: column;padding: 0 .8rem">
        <ng-container *ngFor="let key of formInputs">
          <div [hidden]="key.hidden" class="item-container">
            <div mat-line *ngIf="key.type!=='button'">
              <label [attr.for]="key[INPUT_FIELD_KEY]">{{key.display_text|titlecase}} </label>
            </div>
            <div mat-line [ngSwitch]="key.type">
  
              <!-- Text Input -->
              <ng-container *ngSwitchCase="'text'">
                <ng-component *ngTemplateOutlet="text;context:{key:key}"></ng-component>
              </ng-container>
  
              <!-- File Input -->
              <ng-container *ngSwitchCase="'file'">
                <ng-component *ngTemplateOutlet="file;context:{key:key}"></ng-component>
              </ng-container>
  
              <!-- Dropdown Input -->
              <ng-container *ngSwitchCase="'dropdown'">
                <ng-component *ngTemplateOutlet="dropdown;context:{key:key}"></ng-component>
              </ng-container>
  
              <!-- Dropdown_multi Input -->
              <ng-container *ngSwitchCase="'dropdown_multi'">
                <ng-component *ngTemplateOutlet="dropdown_multi;context:{key:key}"></ng-component>
              </ng-container>
  
  
              <!-- task_specific Input -->
              <ng-container *ngSwitchCase="'task_specific'">
                <ng-component *ngTemplateOutlet="task_specific;context:{key:key}"></ng-component>
              </ng-container>
  
            </div>
  
            <!-- Button Input -->
            <ng-container *ngIf="key.buttons">
              <ng-component *ngTemplateOutlet="button;context:{key:key}"></ng-component>
            </ng-container>
  
          </div>
        </ng-container>
      </div>
  
    </main>
  
    <footer>
      <button
        shape="round" size="small"
        nbSpinnerSize="tiny" nbSpinnerStatus="primary"
        [nbSpinner]="button?.loading||false"
        *ngFor="let button of actionButtons"
        [disabled]="isReadonly||button.disable"
        [class]="button.class||'button'"
        (click)="inputHandlers.buttonInputEventHandler(button, $event)"
        [style.margin-left]="button.marginLeft||'unset'"
        [hidden]="button.hidden">
        <span class="material-icons-outlined" style="font-size: 20px;" *ngIf="button.icon">{{button.icon}}</span> 
        {{button.name}}
      </button>
    </footer>
  
  </div>
  
  
  
  <ng-template #text let-key="key">
    <app-drag *ngIf="key.multiple" [items]="key._value" (onchange)="inputHandlers.textInputEvent(key, $event)"></app-drag>
  
    <input
      *ngIf="!key.multiple"
      type="text"
      [attr.id]="key[INPUT_FIELD_KEY]"
      [placeholder]="key.placeholder"
      (keyup)="inputHandlers.textInputEvent(key, $event)"
      [(ngModel)]="key._value"
      [disabled]="key.disable"
      [hidden]="key.hidden"
    >
  </ng-template>
  
  
  
  <ng-template #file let-key="key">
    <input
      style="
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        "
      [title]="key._url"
      type="text"
      [ngModel]="key._url"
      [disabled]="true"
      [hidden]="!key._url"
    >
  
    <input
      type="file"
      [attr.id]="key[INPUT_FIELD_KEY]"
      [placeholder]="key.placeholder"
      class="custom-file-input"
      (change)="inputHandlers.fileInputEvent(key, $event)"
      [disabled]="key.disable"
      [hidden]="key.hidden"
    >
  </ng-template>
  
  <ng-template #dropdown let-key="key">
    <select [attr.id]="key[INPUT_FIELD_KEY]" (change)="inputHandlers.dropdownInputEvent(key, $event)" [(ngModel)]="key._value" [disabled]="key.disable" [hidden]="key.hidden">
      <option *ngFor="let option of key.options" [value]="option.id">{{option.value}}</option>
    </select>
  </ng-template>
  
  <ng-template #dropdown_multi let-key="key">
    <!-- <div style="height: .5rem;width: 100%;"></div> -->
    <app-union [dropdownOptions]="key.options" (callbackEvents)="inputHandlers.dropdownMultiInputEvent(key, $event)" [hidden]="key.hidden" [events]="key.events"></app-union>
  </ng-template>
  
  <ng-template #button let-key="key">
    <div style="display: flex;gap: .5rem;margin-top: 5px;">
      <button
        nbSpinnerSize="tiny" nbSpinnerStatus="primary"
        [nbSpinner]="button?.loading||false"
        *ngFor="let button of key.buttons"
        [hidden]="button.hidden"
        [disabled]="isReadonly||button.disable"
        [class]="button.class||'button'"
        [style.margin-left]="button.marginLeft||'unset'"
        (click)="inputHandlers.buttonInputEventHandler(button, $event)">
        <span class="material-icons-outlined" style="font-size: 20px;" *ngIf="button.icon">{{button.icon}}</span> 
        {{button.name}}
      </button>
    </div>
  </ng-template>
  
  <ng-template #task_specific let-key="key">
    <div>
      {{key.display_text}}
    </div>
  </ng-template>
  
  
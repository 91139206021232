import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { pluck } from 'rxjs/operators'
import { Pipelinev2Service } from 'src/app/services/api/v2/pipelinev2.service';
import { CdkPortal, TemplatePortal } from '@angular/cdk/portal';
import { SideNavService } from 'src/app/services/core/sidenav.service';
import { UIService } from 'src/app/services/config/ui.service';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/api/auth.service';
import { Accountv2Service } from 'src/app/services/api/v2/accountv2.service';

@Component({
  selector: 'app-pipeline-details',
  templateUrl: './pipeline-details.component.html',
  styleUrls: ['./pipeline-details.component.scss']
})
export class PipelineDetailsComponent implements OnInit {
  hasAdminRole = false
  @ViewChild('detailstabs') detailsTabs:any
  //version pagination
  versionTotalCount=0
  versionPageIndex=0
  versionItemsPerPage=10
  versionPageSizeOptions=[10,20,25,30]

  //history pagination
  historyTotalCount=0
  historyPageIndex=0
  historyItemsPerPage=10
  historyPageSizeOptions=[10,20,25,30]
  
  isLoading = true

  @ViewChild('portalContent', { static: true })
  portalContent: any;
  pipelineVersionDetails: any;
  pipelineHistoryDetails: any;
  runPipelineSection ={
    "name": "run_Pipeline",
    "label": "Run Pipeline",
    "order": 2 || 3,
    "description": "run pipeline",
    "input_fields": {}
} // for temp purpose till API get ready
  activeTab = 0;
  

  pipelineId = -1;
  headerDetails = {
    name: '',
    folder: '',
    status: ''
  }
  pipelineDetails = {
    runBy: '',
    progress: 'w-50',//percentage
    remainingTime: '',
    buildType: '',
    triggerName: '',
    description: ''
  }
  runDetails = {
    inProgress: '-',
    success: '-',
    failed: '-'
  }

  connections: Array<{ icon: string, name: string, status: 'Connected' | 'Disconnected' }> = []
  attributes: Array<{ key: string, value: string }> = []
  tags: Array<string> = []

  constructor(private viewContainerRef: ViewContainerRef, public portalBridge: SideNavService, private router: Router, private activatedRoute: ActivatedRoute, private pipelineService: Pipelinev2Service, private route:ActivatedRoute, private uiService:UIService, private _location:Location,private authService:AuthService,private accountsService:Accountv2Service) { }

  ngOnInit(): void {
    this.authService.accountId$.subscribe(accountId=>{
      if(accountId){
        this.accountsService.getUserInfoV2().subscribe((_data:any)=>{
          let role = _data.payload.accounts[accountId]?.role?.role || ""
          this.hasAdminRole = role === "rrole:admin" 
        })
      }
      
    })
    this.activatedRoute.params.subscribe(params => {
      this.pipelineId = params.id
      this.loadData(params.id)
    }
    );
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(data=>{
      if(data.edit){
        this.openEditDetails();
      }
      if(data.show){
        if(data.show==='history'){
          this.activeTab = 2;
        }
      }
    })
  }


  async loadData(pipelineId:any){
    // console.log('loadpipeline data',pipelineId)
    let pipelineData = (await (this.pipelineService.getPipelineById(pipelineId).toPromise())).payload;
    this.isLoading = false
    let attrArr:any = []
    let attrs = pipelineData.attributes
      for(let [key,value] of Object.entries(attrs)){
        attrArr.push({key,value})
    }
    this.pipelineBasicDetails = {
      name: pipelineData.label,
      description: pipelineData.description,
      tags: [...pipelineData.tags],
      attributes: [...attrArr]
    }
    this.headerDetails.name = pipelineData.label;
    this.headerDetails.folder = pipelineData.group.label;
    this.headerDetails.status = pipelineData.status.charAt(0).toUpperCase() + pipelineData.status.slice(1);
    this.pipelineDetails.description = pipelineData.description;
    this.pipelineDetails.buildType = pipelineData.buildType ?? 'Custom Pipeline';
    this.pipelineDetails.triggerName = pipelineData.triggerName ?? 'Custom';
    this.runDetails = pipelineData?.process ?? {
      inProgress: `${(pipelineData.id)%50}`,
      success: `${(pipelineData.id+5)%50}`,
      failed: `${(pipelineData.id+1)%50}`
    }
    this.tags = pipelineData.tags;
    this.attributes = attrArr

    //versionTable
    let versionPayload = (await (this.pipelineService.getPipelineVersions(pipelineId,{itemsPerPage:this.versionItemsPerPage,pageNum:this.versionPageIndex+1}).toPromise())).payload;
    this.versionTotalCount = versionPayload.totalCount;
      this.pipelineVersionDetails = this.pipelineVersionDataFormatter(versionPayload.data);
    //historyTable
    // interface HistoryData {
    //   id: number;
    //   createdTime: number;
    //   //other
    //   status: string;
    //   errorMessage:string;
    // }
    let historyPayload = (await (this.pipelineService. getPipelineProcesses(pipelineId,{itemsPerPage:this.historyItemsPerPage,pageNum:this.historyPageIndex+1}).toPromise())).payload;
    this.historyTotalCount=historyPayload.totalCount;
      this.pipelineHistoryDetails = this.pipelineHistoryDataFormatter(historyPayload.data); 

  }
  formatTimestamp(timestamp:any) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(timestamp* 1000);
    const day = date.getDate();
    const month = date.toLocaleDateString(undefined, { month: 'long' });
    const year = date.getFullYear();
    // Add 'st', 'nd', 'rd', or 'th' to the day based on its value
    let daySuffix = 'th';
    if (day >= 1 && day <= 3) {
      daySuffix = ['st', 'nd', 'rd'][day - 1];
    }
    return `${day}${daySuffix} ${month} ${year}`;
  }
  
  async eventLogger(e:any){
    console.dir(e.detail)
    if (e.detail.eventName === 'back') {
      // this.router.navigate(["", { outlets: { view: ['pipeline-list'], action: null } }])
      this._location.back();

    }
    else if (e.detail.eventName === 'edit') {
      let config = { edit: true, name: this.headerDetails.name }
      const response = await this.pipelineService.editInitPipeline(this.pipelineId).toPromise()

      const pipelineVersionId = response.payload.version.id
      console.log(response.payload)

      
      this.router.navigate(['/dashboard', this.pipelineId, pipelineVersionId], { queryParams: { config: btoa(JSON.stringify(config)), name: this.headerDetails.name } })


    }
    else if (e.detail.eventName === 'edit-pipeline-details') {
      this.openEditDetails();
    }
    else if (e.detail.eventName === 'run') {
      this.triggerPipeline(this.pipelineId)
    }
  }

  openEditDetails(){
    this.portalBridge.show = true;
    const portal = new TemplatePortal(this.portalContent, this.viewContainerRef)
    this.portalBridge.setRightNavTemplate(portal)
  }

  triggerPipeline(pipelineId: number){
    this.pipelineService.triggerPipeline(pipelineId).subscribe((response: any) => {
      const processId = response?.payload?.id;
      if (processId) {
        localStorage.removeItem('activeSection')
        this.router.navigate([{ outlets: { view: ['upload'] } }], {
          queryParams: { pipelineId: pipelineId, processId: processId }
        }); } else {
        throw new Error('Invalid response from trigger API');
      }
    });
  }

  closeEditDetails(){
    this.portalBridge.show = false;
    // remove edit query param
    // this.router.navigate([], { queryParams: { edit: null }, queryParamsHandling: 'merge' });
  }

  pipelineBasicDetails = {}
  updatePipelineDetails(event: any) {
    // console.log(event.detail)
    this.pipelineService.updatePipelineDetails(this.pipelineId.toString(),event.detail).subscribe(data=>{
      this.uiService.showInfo("Pipeline updated successfully",'success')
      this.loadData(this.pipelineId)
    }
    );
    this.portalBridge.show = false;
  }

   processRedirect(event: any) {
    this.activatedRoute.params.subscribe(params => {
      const pipelineId = params.id;
      try {
        // if (event.detail.status === 'success') {
          this.router.navigate([{ outlets: { view: ['upload'] } }], {
            queryParams: { pipelineId: pipelineId, processId: event.detail.process }
          });
          localStorage.setItem('activeSection', JSON.stringify(this.runPipelineSection));
        // } 
        // else {
        //   this.uiService.showError('Process is not successful, Please check successful processes from the list');
        // }
      } catch (error) {
        // Handle the error here
        console.error(error);
        // You can also show a user-friendly error message if needed
      }
    });
  }
  


  ngOnDestroy() {
    this.portalBridge.removeRightNavTemplate()
    this.portalBridge.show = false;
  }
  onPaginationEvent(e:any){
    // console.log(e.detail.itemsPerPage)
    this.isLoading = true;
    this.loadPipelinesVersion(e.detail.itemsPerPage,e.detail.index);
   
  }
  onPaginationEventHistory(e:any){
    this.isLoading = true;
    this.loadPipelinesHistory(e.detail.itemsPerPage,e.detail.index);

  }
  loadPipelinesVersion(itemsPerPage: number, pageIndex: number) {
    this.pipelineService.getPipelineVersions(this.pipelineId,{itemsPerPage,pageNum:pageIndex+1}).subscribe((data:any) => {
      this.pipelineVersionDetails=this.pipelineVersionDataFormatter(data.payload.data); 
      this.isLoading = false
      this.versionTotalCount = data.payload.totalCount;
      this.versionPageIndex = data.payload.currentPage-1;
    });
  }
  pipelineVersionDataFormatter(pipelineVersionDetails: Array<any> = []){
    let arr = pipelineVersionDetails.map((data) => {
      return {
        versionId: data.id,
        createdOn: new Date(data.createdTime*1000).toLocaleString(),
        createdBy: 'Bridge Admin',
        publishedOn: '--',
        publishedBy: '--',
        comment: data.comment, 
      }
    })
    return arr;

  }
  loadPipelinesHistory(itemsPerPage:number,pageIndex:number){
    this.pipelineService.getPipelineProcesses(this.pipelineId,{itemsPerPage,pageNum:pageIndex+1}).subscribe((data:any)=>{
      this.pipelineHistoryDetails=this.pipelineHistoryDataFormatter(data.payload.data); 
      this.isLoading = false
      this.historyTotalCount = data.payload.totalCount;
      this.historyPageIndex = data.payload.currentPage-1;
    })
  }
  pipelineHistoryDataFormatter(pipelineHistoryDetails:Array<any>=[]){
    let arr=pipelineHistoryDetails.map((data)=>{
      return{
      process:data.id,
      initiatedOn: new Date(data.createdTime*1000).toLocaleString(),
      initiatedBy: data.triggerByName || 'NA',
      status:data.status,
      information:data.status === 'failed'? data.errorMessage : data?.moreInfo?.dispStr
      }
    })
    return arr

  }

  customEventsHandler(event:any){
    if(event.detail.name === 'view-history'){
      this.detailsTabs.nativeElement.changeActiveTab(2)
    }
  }

}
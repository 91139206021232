import { Node as GraphNode, NodeDimension, NodePosition } from "@swimlane/ngx-graph";
import {Node as TaskNode, NodeField} from "../../models/tasknode.model";

export class Workflow {
  constructor(
    public id = null,
    public clientId = '5d0fc0086b7f9e3fd5006049',
    public name = 'Workflow 1',
    public entityType = 'DEAL',
    public startNodeId = null
  ) {

  }
}


export type NodeInputField = NodeField & {
  id: string;
  type: 'input' | 'output' | 'config' | 'add';
  isError: boolean;
  meta: any;
  name: string;
  label: string;
  description: string;
  required: boolean;
  dataType: string; // TODO: add type
  formatType: string; // TODO: add type
  default: unknown;
  schema: unknown;

  source?: {
    sourceNodeId: number;
    sourceFieldId: string;
  }
};

export class Node implements GraphNode{
  // {
  // id:element.id,
  // label:element.taskConfigDetails[0].displayName,
  // icon:'',
  // nodeClass: '',
  // }
    public id = ''
    public name = ''
    public label = ''
    public icon = ''
    public nodeClass = ''
    public type = ''
    public boolean_weight: boolean|null = null
    public conditional_status: boolean|null = null
    public metaData: Partial<TaskNode> = {}

    public fields: Array<NodeInputField> = []
    position!: NodePosition;
    dimension!: NodeDimension;

    constructor(config: Partial<{[T in keyof Node]:Node[T] }>) {
      Object.assign(this, config)
    }
}

export class Link {
  //   {
  //   id: key+target_id,
  //   source:key,
  //   target:target_id,
  //   label: '' true | false if conditional
  // }

  dimension: {width: number, height: number} = {
    width: 100,
    height: 100
  }

  constructor(
    public id = '',
    public source = '',
    public target = '',
    public label = '',
    public type = '',
    public config = '',
    public points = [{x: 0, y: 0}],
    public connector = {sourceFieldId: '', targetFieldId: ''},
    public weight: boolean | null | undefined = null
  ) {



  }
}

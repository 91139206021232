import {jsonix} from '../../libs/jsonix';
import {Xml2Json, getTreeView, XmlSchemaParser} from '../../libs/xml2json'

function getJSONString(data:string){
  try{
    return(JSON.stringify(JSON.parse(data),null,4))
    } catch(e){
        return('Cannot parse JSON data')
    }
}

function getSchema(schema:any,type:'json'|'xml'){
  try{
    if(type==='json') return getItems(schema)
    else {
      let xmlSchemaParser = new XmlSchemaParser()
      const schemaStruct = xmlSchemaParser.parseString(schema)
      const ls = getTreeView(schemaStruct)
      return ls;
    }
  }catch{
    return([])
  }
}

function JSONParse(data:any){
  try{
    return JSON.parse(data)
  }catch{
    return {}
  }
}

export function buildTableDataAllOutput(outputData:{[key:string]:{schema:any,dataType:string,formatType:string,data:string}},fieldName:string){
  let files_data:any = []
  for(let key in outputData){
    let output = outputData[key];
    if(output.formatType==='dataframe'){
      let columns:any = [];
      (output.schema.typeOf.properties as Array<any>).forEach((data,i)=>{
        columns.push({
          headerName:data.name,
          field:data.name,
          type:'json',
          id:i+1
      })
      })
      files_data.push(
        {
          sheet_name:key,
          fileType:'json',
          dataString:getJSONString(output.data),
          schema:getSchema(output.schema,'json'),
          data:JSONParse(output.data),
          cols:columns,
          outputTypes:['table','data','schema']
        }
      )
    }
    else if(output.formatType==='xml'){
      files_data.push(
        {
          sheet_name:key,
          fileType:output.formatType,
          dataString:output.data,
          schema:getSchema(output.schema,'xml'),
          cols:[],
          outputTypes:['data','schema']
        }
      )
    }
    else if(output.formatType==='json'){
      
      files_data.push(
        {
          sheet_name:key,
          fileType:'json',
          dataString:getJSONString(output.data),
          schema:getSchema(output.schema,'json'),
          cols:[],
          outputTypes:['data','schema'],
          
        }
      )
    }
    else{
      files_data.push(
        {
          sheet_name:key,
          fileType:'json',
          dataString:getJSONString(output.data),
          schema:getSchema(output.schema,'json'),
          cols:[],
          outputTypes:['data']
        }
      )
    }
    }
  return files_data;
}



type SchemaType = {
  name: string
  type: 'string' | 'number' | 'date'
} | {
  name?:string
  type: 'object'
  properties: Array<SchemaType>
} | {
  name:string
  type: 'array'
  typeOf: SchemaType
}

const getItems = (obj: SchemaType, indent: number = 0, result: Array<{text: string, type: string, indent: number}>=[]) => { 
  if(!obj.type){
    return result;
  }
  if(obj.type === 'array'){
    result.push({
      text: obj.name, type: obj.type, indent: indent
    })
    getItems(obj.typeOf,indent+1,result)
  }
  else if(obj.type === 'object'){
    if(obj.name){
      result.push({
      text: obj.name, type: obj.type, indent: indent
      })
      obj.properties.forEach((x:SchemaType)=>{
        getItems(x,indent+1,result)
      })
    }
    else{
      obj.properties.forEach((x:SchemaType)=>{
        getItems(x,indent,result)
      })
    }
  }
  else{
    result.push({
      text: obj.name, type: obj.type, indent: indent
    })
  }
  return result
}


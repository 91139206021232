import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  Output
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

//routing
import { AppRoutingModule } from './app-routing.module';

import { ActivatedRoute } from '@angular/router';

// Components
import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { CreatePipelineComponent } from './pages/create-pipeline/create-pipeline.component';
import { ViewPipelineComponent } from './pages/view-pipeline/view-pipeline.component';
import { UploadFileComponent } from './pages/upload-file/upload-file.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { OutputComponent } from './pages/output/output.component';
import { ExcelPreviewComponent } from './pages/excel-preview/excel-preview.component';
import { AddTaskComponent as AddTaskPopup } from './components/add-task/add-task.component';
import { SinkSetMappingComponent } from './pages/sink-set-mapping/sink-set-mapping.component';
import { ConfigPreviewComponent } from './pages/config-preview/config-preview.component';
import { EdgeconnectorComponent } from './components/edgeconnector/edgeconnector.component';
import { SimplifyHeaderComponent } from './components/simplify-header/simplify-header.component';
import { DataConnectionComponent } from './pages/data-connection/data-connection.component';
import { UnionComponent } from './components/multi-select-dropdown/union.component';
import { OutputTableComponent } from './pages/output-table/output-table.component';
import { TableHeaderComponent } from './pages/output-table/table-header/table-header.component';
import { ApiConfigurationComponent } from './pages/api-configuration/api-configuration.component';
import { JsonFormComponent } from './components/json-form/json-form.component';
import { TaskHistoryComponent } from './pages/task-history/task-history.component';
import { PipelineConstantComponent } from './pages/pipeline-constant/pipeline-constant.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { CreateGroupComponent } from './pages/create-group/create-group.component';
import { ViewGroupComponent } from './pages/view-group/view-group.component';
import { CreateConfigComponent } from './pages/create-config/create-config.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { PrintJsonComponent } from './components/print-json/print-json.component';
import { NotificationRecipientComponent } from './components/notification-recipient/notification-recipient.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginComponent } from './pages/login/login.component';
import { MultiProgressBarComponent } from './components/multi-progress-bar/multi-progress-bar.component';
import { EditApiComponent } from './components/edit-api/edit-api.component';
import { ApiPopupComponent } from './pages/api-popup/api-popup.component';
import { AddTaskComponent } from './pages/dashboard/tasks/addtask/add-task.component';
import { EditorComponent } from './components/codemirror/codemirror.component';
import { UpdateTaskComponent } from './pages/dashboard/tasks/update-task/update-task.component';
import { DagComponent } from './components/dag/dag.component';
import { TopicsComponent } from './pages/topics/topics.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAlertModule,
  NbButtonGroupModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbPopoverModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTabsetModule,
  NbThemeModule,
  NbTimepickerModule,
  NbToastrModule,
  NbToggleModule,
  NbTooltipModule,
  NbTreeGridModule,
  NbUserModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { DataTablesModule } from 'angular-datatables';
import { AgGridModule} from 'ag-grid-angular';
import { MatTableModule } from '@angular/material/table';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatChipsModule } from '@angular/material/chips';
import { PortalModule } from '@angular/cdk/portal';
import { ViewJsonComponent } from './components/view-json/view-json.component';
import { ExitConfirmGuard } from './guards/exit-confirm.guard';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { AddTokenInterceptor } from './interceptors/add-token.interceptor';
import { TaskDetailsDirective } from './pages/dashboard/task-details.directive';
import { RequestLogInterceptor } from './interceptors/request-log.interceptor';
import { AddSchemaComponent } from './components/add-schema/add-schema.component';
import { MultipleSheetSchemaComponent } from './components/multiple-sheet-schema/multiple-sheet-schema.component';
import { DragComponent } from './components/drag/drag.component';
import { MatMenuModule } from '@angular/material/menu';
import { DownloadFormComponent } from './pages/download-form/download-form.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CustomNodeEditorComponent } from './pages/dashboard/custom-node-editor/custom-node-editor.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CopyTaskComponent } from './pages/dashboard/tasks/copy-task/copy-task.component';
import { PipelineTimelineComponent } from './components/pipeline-timeline/pipeline-timeline.component';
import { PipelineHistoryComponent } from './pages/pipeline-history/pipeline-history.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { NodesOutputComponent } from './pages/output/nodes-output/nodes-output.component';
import { DataMapperComponent } from './components/data-mapper/data-mapper.component';
import { CodeEditorComponent } from './components/code-editor/code-editor.component';
import { CodeEditorOptionsComponent } from './components/code-editor-options/code-editor-options.component';
import { AddNodeComponent } from './pages/dashboard/add-node/add-node.component';
import { SelectnodeComponent } from './components/selectnode/selectnode.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { NodeSettingsComponent } from './pages/dashboard/node-settings/node-settings.component';
import { ConnectionsComponent } from './pages/connections/connections.component';
import { DetailCellRendererComponent } from './components/detail-cell-renderer/detail-cell-renderer.component';
import { EditCellComponent } from '../../src/app/components/upload-utility-basic-config/edit-cell/edit-cell.component';
import { UploadUtilityBasicConfigComponent } from './components/upload-utility-basic-config/upload-utility-basic-config.component';

import { CustomNodeComponent } from './pages/custom-node/custom-node.component';
import { NodeEditorComponent } from './pages/node-editor/node-editor.component';
import { FileExplorerComponent } from './components/file-explorer/file-explorer.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { WebComponentComponent } from './web-component/web-component.component';
import { PipelineDetailsComponent } from './components/pipeline-details/pipeline-details.component';
import { TestComponentComponent } from './test-component/test-component.component';
import { SidebarPipelinesComponent } from './pages/sidebar-pipelines/sidebar-pipelines.component';
import { PipelineListingComponent } from './components/pipeline-listing/pipeline-listing.component';
import { ActionAreaComponent } from './action-area/action-area.component';
import { GroupListComponent } from './pages/group-list/group-list.component';
import { GroupDetailComponent } from './pages/group-detail/group-detail.component';
import { GroupFolderDetailComponent } from './pages/group-folder-detail/group-folder-detail.component'
import { ReteDagComponent } from './rete-dag/rete-dag.component'
import { ReteModule } from "rete-angular-plugin/12";
import { CustomSocketComponent } from './rete-dag/custom-socket/custom-socket.component';
import { CustomConnectionComponent } from './rete-dag/custom-connection/custom-connection.component';
import { CustomControlComponent } from './rete-dag/custom-controller/custom-control.component';
import { ButtonComponent } from './rete-dag/custom-controller/custom-button.component';
import { NodeSettingsIconComponent } from './rete-dag/custom-controller/settings-icon.component';
import { CustomReteNodeComponent } from './rete-dag/custom-nodes/default-node/custom-node.component';
import { ReteIfNodeComponent } from './rete-dag/custom-nodes/if-node/if-node.component';
import { ReteLoopNodeComponent } from './rete-dag/custom-nodes/loop-node/loop-node.component';

import { ShowOutputComponent } from './components/upload-utility-basic-config/show-output/show-output.component'
import { AccountDetailsComponent } from './pages/account-details/account-details.component';
import { AccountListComponent } from './pages/account-list/account-list.component'
// import {defineCustomElements, applyPolyfills} from '@sm-bridge/simplify-ui/loader'
// import '@sm-bridge/simplify-ui/dist/simplify-ui/simplify-ui.esm';
import { TemplatesComponent } from './pages/templates/templates.component';
import { SidebarTemplatesComponent } from './pages/sidebar-templates/sidebar-templates.component';
import { TemplateConfigurationsComponent } from './pages/template-configurations/template-configurations.component';
import { MainDashboardComponent } from './pages/main-dashboard/main-dashboard.component';
import { ConnectionPageComponent } from './pages/connection-page/connection-page.component';
import { TopicDetailComponent } from './pages/topic-detail/topic-detail.component';
const pkg = require('../../package.json')

const loadStencilFactory = (callback: () => unknown) => {
  const STENCIL_COMPONENTS_OVERRIDE = '__STENCIL_COMPONENTS_OVERRIDE__'
  const stencilOverrideUrl = localStorage.getItem(STENCIL_COMPONENTS_OVERRIDE)
  if(stencilOverrideUrl) {
    const script = document.createElement('script')
    script.type = 'module'
    script.onload = callback
    script.src = stencilOverrideUrl
    console.log(`loading stencil from ${stencilOverrideUrl} ...`)
    document.head.appendChild(script)
  }
  else {
    // const {defineCustomElements} = require('@sm-bridge/simplify-ui/loader')
    // defineCustomElements();
    require('@sm-bridge/simplify-ui/dist/simplify-ui/simplify-ui.esm')
    callback()
  }
}

@NgModule({
  declarations: [
    NodeSettingsIconComponent,
    ButtonComponent,
    CustomControlComponent,
    CustomSocketComponent,
    CustomReteNodeComponent,
    ReteIfNodeComponent,
    ReteLoopNodeComponent,
    CustomConnectionComponent,
    NodesOutputComponent,
    EditorComponent,
    AppComponent,
    MainComponent,
    CreatePipelineComponent,
    ViewPipelineComponent,
    UploadFileComponent,
    DashboardComponent,
    DropdownComponent,
    OutputComponent,
    ExcelPreviewComponent,
    AddTaskComponent,
    SinkSetMappingComponent,
    ConfigPreviewComponent,
    EdgeconnectorComponent,
    SimplifyHeaderComponent,
    DataConnectionComponent,
    UnionComponent,
    OutputTableComponent,
    TableHeaderComponent,
    ApiConfigurationComponent,
    JsonFormComponent,
    TaskHistoryComponent,
    PipelineConstantComponent,
    ScheduleComponent,
    DataTableComponent,
    PrintJsonComponent,
    CreateGroupComponent,
    ViewGroupComponent,
    CreateConfigComponent,
    SearchFilterPipe,
    NotificationRecipientComponent,
    LoginFormComponent,
    AddTaskPopup,
    AppComponent,
    MainComponent,
    CreatePipelineComponent,
    ViewPipelineComponent,
    UploadFileComponent,
    DashboardComponent,
    DropdownComponent,
    DagComponent,
    DragComponent,
    OutputComponent,
    AddTaskComponent,
    SinkSetMappingComponent,
    ConfigPreviewComponent,
    EdgeconnectorComponent,
    SimplifyHeaderComponent,
    DataConnectionComponent,
    UnionComponent,
    OutputTableComponent,
    TableHeaderComponent,
    ApiConfigurationComponent,
    JsonFormComponent,
    TaskHistoryComponent,
    PipelineConstantComponent,
    ScheduleComponent,
    DataTableComponent,
    PrintJsonComponent,
    LoginFormComponent,
    LoginComponent,
    MultiProgressBarComponent,
    AddTaskComponent,
    TaskDetailsDirective,
    ApiPopupComponent,
    AddSchemaComponent,
    MultipleSheetSchemaComponent,
    EditApiComponent,
    UpdateTaskComponent,
    ViewJsonComponent,
    DragComponent,
    DownloadFormComponent,
    CustomNodeEditorComponent,
    CopyTaskComponent,
    PipelineTimelineComponent,
    PipelineHistoryComponent,
    ConfirmationPopupComponent,
    DataMapperComponent,
    CodeEditorComponent,
    CodeEditorOptionsComponent,
    AddNodeComponent,
    SelectnodeComponent,
    DynamicFormComponent,
    NodeSettingsComponent,
    DetailCellRendererComponent,
    ConnectionsComponent,
    EditCellComponent,
    UploadUtilityBasicConfigComponent,
    CustomNodeComponent,
    NodeEditorComponent,
    FileExplorerComponent,
    ImageCropperComponent,
    WebComponentComponent,
    PipelineDetailsComponent,
    TestComponentComponent,
    SidebarPipelinesComponent,
    PipelineListingComponent,
    ActionAreaComponent,
    GroupListComponent,
    GroupDetailComponent,
    GroupFolderDetailComponent,
    ReteDagComponent,
    WebComponentComponent,
    ShowOutputComponent,
    AccountDetailsComponent,
    AccountListComponent,
    TopicsComponent,
    TemplatesComponent,
    SidebarTemplatesComponent,
    TemplateConfigurationsComponent,
    MainDashboardComponent,
    TopicDetailComponent,
    ConnectionPageComponent
  ],
  imports: [
    ReteModule,
    NbStepperModule,
    MatSnackBarModule,
    CodemirrorModule,
    MatMenuModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatNativeDateModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CdkTableModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    DragDropModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCardModule,
    MatDialogModule,
    NgxGraphModule,
    NbLayoutModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbToastrModule.forRoot(),
    NbPopoverModule,
    NbButtonModule,
    NbEvaIconsModule,
    NbIconModule,
    NbCardModule,
    NbSelectModule,
    NgxBootstrapIconsModule.pick(allIcons),
    NbThemeModule.forRoot({ name: 'default' }),
    NbListModule,
    DataTablesModule,
    AgGridModule.withComponents([OutputComponent, DagComponent]),
    NbActionsModule,
    NbSearchModule,
    NbContextMenuModule,
    NbTooltipModule,
    NbTreeGridModule,
    NbButtonGroupModule,
    NbInputModule,
    MatTabsModule,
    NbUserModule,
    NbFormFieldModule,
    NbAccordionModule,
    NbSpinnerModule,
    NbTabsetModule,
    MatTableModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatTooltipModule,
    NbTimepickerModule.forRoot(),
    NbToggleModule,
    MatSlideToggleModule,
    ClipboardModule,
    NbCheckboxModule,
    MatChipsModule,
    NbAlertModule,
    PortalModule,
    MatMenuModule,
    MatChipsModule,
    MatCheckboxModule,
    ImageCropperModule
  ],
  providers: [
    ExitConfirmGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AddTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestLogInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        console.log(`%cApp version:${pkg.version}`, 'color: green')
        console.log(`%cStencils version:${pkg.dependencies?.['@sm-bridge/simplify-ui']}`, 'color: green')
        return () => new Promise<void>(resolve => loadStencilFactory(resolve))
      },
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}

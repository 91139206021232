<simplify-header></simplify-header>

<simplify-horizontal-stack>


  <simplify-sidemenu>

    <simplify-sidemenu-item active>
      <i slot='icon' class="ri-flow-chart"></i>
      Pipelines
      <span slot='badge' class="badge badge-default ms-auto">3</span>
    </simplify-sidemenu-item>

    <simplify-sidemenu-item href='#'>
      <i slot='icon' class="ri-folders-line"></i>
      All Groups
      <simplify-badge slot="badge" class="ms-auto">5</simplify-badge>
      <!-- <span slot='badge' class="badge badge-default ms-auto">5</span> -->
    </simplify-sidemenu-item>

    <simplify-sidemenu-item href='#'>
      Recents
    </simplify-sidemenu-item>

    <simplify-sidemenu-item href='#'>
      <i slot='icon' class="ri-folder-fill text-warning"></i>
      Simplify Bulk Upload
      <span slot='badge' class="badge badge-default ms-auto">7</span>
    </simplify-sidemenu-item>

    <simplify-sidemenu-item href='#'>
      <i slot='icon' class="ri-folder-fill text-success"></i>
      Inbound Integration
      <span slot='badge' class="badge badge-default ms-auto">9</span>
    </simplify-sidemenu-item>

    <simplify-sidemenu-item href='#'>
      <i slot='icon' class="ri-folder-fill text-danger"></i>
      Workday Integration
      <span slot='badge' class="badge badge-default ms-auto">2</span>
    </simplify-sidemenu-item>

    <simplify-sidemenu-item href='#'>
      <i slot='icon' class="ri-folder-fill text-blue"></i>
      Invoice
      <span slot='badge' class="badge badge-default ms-auto">11</span>
    </simplify-sidemenu-item>

    <simplify-sidemenu-item href='#'>
      <i slot='icon' class="ri-folder-fill text-warning"></i>

      Default

      <span slot='badge' class="badge badge-default ms-auto">6</span>
    </simplify-sidemenu-item>


  </simplify-sidemenu>


  <simplify-vertical-stack>
    <simplify-content-area>
      <simplify-table>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Pipeline Name</th>
              <th scope="col">Group</th>
              <th scope="col">Trigger</th>
              <th scope="col">Build type</th>
              <th scope="col">Status</th>
              <th scope="col">Run Status</th>
              <th scope="col">Last Updated</th>
              <th></th>
            </tr>
          </thead>
          <tbody>



            <tr *ngFor="let pipeline of pipelines">
              <th>
                <a class="btn-link text-decoration-none" (click)="viewPipeline(pipeline.id)" [href]="'/dashboard/'+pipeline.id">
                  {{pipeline.name}}
                  <i class="ri-information-line text-secondary"></i>
                </a>
              </th>
              <td>
                <i class="ri-folder-fill text-danger"></i>
                {{this.groups[pipeline.group_id]?.name}}
              </td>
              <td>
                <a class="text-error text-decoration-none" href="#">
                  <i class="ri-time-line  text-secondary"></i> Schedule
                </a>
              </td>
              <td>
                <i class="ri-shape-line"></i> Template Pipeline
              </td>
              <td>
                <span class="badge badge-secondary">Active</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="mx-2 text-danger">8</div>
                  <div class="mx-2 text-success">8</div>
                  <div class="mx-2 text-danger">8</div>
                </div>
              </td>
              <td>By Shubham 6 hour ago </td>
              <td>
                <div class="dropdown">
                  <a class="mx-1 dropdown-icon" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-line"></i>
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li>
                      <a class="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>



            <!-- <tr>
              <th>
                <a class="btn-link text-decoration-none" href="#">
                  Generic Job Template <i class="ri-information-line text-secondary"></i>
                </a>
              </th>
              <td>
                <i class="ri-folder-fill text-danger"></i> Evergy
              </td>
              <td>
                <a class="text-error text-decoration-none" href="#">
                  <i class="ri-time-line  text-secondary"></i> Schedule
                </a>
              </td>
              <td>
                <i class="ri-shape-line"></i> Template Pipeline
              </td>
              <td>
                <span class="badge badge-secondary">Active</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="mx-2 text-danger">8</div>
                  <div class="mx-2 text-success">8</div>
                  <div class="mx-2 text-danger">8</div>
                </div>
              </td>
              <td>By Shubham 6 hour ago </td>
              <td>
                <div class="dropdown">
                  <a class="mx-1 dropdown-icon" role="button" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-line"></i>
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li>
                      <a class="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <a class="btn-link text-decoration-none" href="#">
                  Generic Job Template <i class="ri-information-line text-secondary"></i>
                </a>
              </th>
              <td>
                <i class="ri-folder-fill text-danger"></i> Evergy
              </td>
              <td>
                <a class="text-error text-decoration-none" href="#">
                  <i class="ri-time-line  text-secondary"></i> Schedule
                </a>
              </td>
              <td>
                <i class="ri-shape-line"></i> Template Pipeline
              </td>
              <td>
                <span class="badge badge-secondary">Active</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="mx-2 text-danger">8</div>
                  <div class="mx-2 text-success">8</div>
                  <div class="mx-2 text-danger">8</div>
                </div>
              </td>
              <td>By Shubham 6 hour ago </td>
              <td>
                <div class="dropdown">
                  <a class="mx-1 dropdown-icon" role="button" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-line"></i>
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li>
                      <a class="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <a class="btn-link text-decoration-none" href="#">
                  Generic Job Template <i class="ri-information-line text-secondary"></i>
                </a>
              </th>
              <td>
                <i class="ri-folder-fill text-danger"></i> Evergy
              </td>
              <td>
                <a class="text-error text-decoration-none" href="#">
                  <i class="ri-time-line  text-secondary"></i> Schedule
                </a>
              </td>
              <td>
                <i class="ri-shape-line"></i> Template Pipeline
              </td>
              <td>
                <span class="badge badge-secondary">Active</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="mx-2 text-danger">8</div>
                  <div class="mx-2 text-success">8</div>
                  <div class="mx-2 text-danger">8</div>
                </div>
              </td>
              <td>By Shubham 6 hour ago </td>
              <td>
                <div class="dropdown">
                  <a class="mx-1 dropdown-icon" role="button" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-line"></i>
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li>
                      <a class="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <a class="btn-link text-decoration-none" href="#">
                  Generic Job Template <i class="ri-information-line text-secondary"></i>
                </a>
              </th>
              <td>
                <i class="ri-folder-fill text-danger"></i> Evergy
              </td>
              <td>
                <a class="text-error text-decoration-none" href="#">
                  <i class="ri-time-line  text-secondary"></i> Schedule
                </a>
              </td>
              <td>
                <i class="ri-shape-line"></i> Template Pipeline
              </td>
              <td>
                <span class="badge badge-secondary">Active</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="mx-2 text-danger">8</div>
                  <div class="mx-2 text-success">8</div>
                  <div class="mx-2 text-danger">8</div>
                </div>
              </td>
              <td>By Shubham 6 hour ago </td>
              <td>
                <div class="dropdown">
                  <a class="mx-1 dropdown-icon" role="button" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-line"></i>
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li>
                      <a class="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <a class="btn-link text-decoration-none" href="#">
                  Generic Job Template <i class="ri-information-line text-secondary"></i>
                </a>
              </th>
              <td>
                <i class="ri-folder-fill text-danger"></i> Evergy
              </td>
              <td>
                <a class="text-error text-decoration-none" href="#">
                  <i class="ri-time-line  text-secondary"></i> Schedule
                </a>
              </td>
              <td>
                <i class="ri-shape-line"></i> Template Pipeline
              </td>
              <td>
                <span class="badge badge-secondary">Active</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="mx-2 text-danger">8</div>
                  <div class="mx-2 text-success">8</div>
                  <div class="mx-2 text-danger">8</div>
                </div>
              </td>
              <td>By Shubham 6 hour ago </td>
              <td>
                <div class="dropdown">
                  <a class="mx-1 dropdown-icon" role="button" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-line"></i>
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li>
                      <a class="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <a class="btn-link text-decoration-none" href="#">
                  Generic Job Template <i class="ri-information-line text-secondary"></i>
                </a>
              </th>
              <td>
                <i class="ri-folder-fill text-danger"></i> Evergy
              </td>
              <td>
                <a class="text-error text-decoration-none" href="#">
                  <i class="ri-time-line  text-secondary"></i> Schedule
                </a>
              </td>
              <td>
                <i class="ri-shape-line"></i> Template Pipeline
              </td>
              <td>
                <span class="badge badge-secondary">Active</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="mx-2 text-danger">8</div>
                  <div class="mx-2 text-success">8</div>
                  <div class="mx-2 text-danger">8</div>
                </div>
              </td>
              <td>By Shubham 6 hour ago </td>
              <td>
                <div class="dropdown">
                  <a class="mx-1 dropdown-icon" role="button" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-line"></i>
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li>
                      <a class="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <a class="btn-link text-decoration-none" href="#">
                  Generic Job Template <i class="ri-information-line text-secondary"></i>
                </a>
              </th>
              <td>
                <i class="ri-folder-fill text-danger"></i> Evergy
              </td>
              <td>
                <a class="text-error text-decoration-none" href="#">
                  <i class="ri-time-line  text-secondary"></i> Schedule
                </a>
              </td>
              <td>
                <i class="ri-shape-line"></i> Template Pipeline
              </td>
              <td>
                <span class="badge badge-secondary">Active</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="mx-2 text-danger">8</div>
                  <div class="mx-2 text-success">8</div>
                  <div class="mx-2 text-danger">8</div>
                </div>
              </td>
              <td>By Shubham 6 hour ago </td>
              <td>
                <div class="dropdown">
                  <a class="mx-1 dropdown-icon" role="button" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-line"></i>
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li>
                      <a class="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <a class="btn-link text-decoration-none" href="#">
                  Generic Job Template <i class="ri-information-line text-secondary"></i>
                </a>
              </th>
              <td>
                <i class="ri-folder-fill text-danger"></i> Evergy
              </td>
              <td>
                <a class="text-error text-decoration-none" href="#">
                  <i class="ri-time-line  text-secondary"></i> Schedule
                </a>
              </td>
              <td>
                <i class="ri-shape-line"></i> Template Pipeline
              </td>
              <td>
                <span class="badge badge-secondary">Active</span>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="mx-2 text-danger">8</div>
                  <div class="mx-2 text-success">8</div>
                  <div class="mx-2 text-danger">8</div>
                </div>
              </td>
              <td>By Shubham 6 hour ago </td>
              <td>
                <div class="dropdown">
                  <a class="mx-1 dropdown-icon" role="button" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-line"></i>
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li>
                      <a class="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr> -->
          </tbody>
        </table>
      </simplify-table>
    </simplify-content-area>
  </simplify-vertical-stack>
</simplify-horizontal-stack>
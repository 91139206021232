<simplify-dashboard>
    <simplify-dashbord-header slot="header" [dashboardTitle]="dashboardTitle"></simplify-dashbord-header>
    <simplify-dashboard-pipelinestats slot="pipelinecards" [cardsData]="cardsData"></simplify-dashboard-pipelinestats>
    <simplify-dashboard-pipeline-runstatus slot="runstatus" [pipelines]="pipelines">
        <div class="chart-canvas" slot="chart-canvas">
            <canvas  id="MyChart" >{{ chart }}</canvas>
        </div>
        <simplify-dashboard-pipeline-runstatus-filter slot="filter" (filterChanged)="filterApplied($event)"></simplify-dashboard-pipeline-runstatus-filter>
    </simplify-dashboard-pipeline-runstatus>
    <simplify-dashboard-scheduledtasks slot="tasksAndProcesses" [tableData]="tableData"></simplify-dashboard-scheduledtasks>
</simplify-dashboard>
import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigModel} from "../../models/config.model";
import {Observable, Subject, of} from "rxjs";
import {DataService, ServiceResponse} from "../core/data.service";
import {filter, map} from "rxjs/operators";
import apiConfig from './api.config'
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../api/auth.service';

export type Event = { type: string, payload: Array<unknown> }

// Context service is to provide global state, constants, communication channel b/w components
@Injectable({providedIn: 'root'})
export class Context implements  OnDestroy {

  // Global event bus
  private subject = new Subject<Event>()

  // Principle defines the user-object/roles/user-access
  // TODO: change principle to Subject, add 'user' subject
  readonly principle: {[key: string]: {[kay: string]: any}|undefined} = {}
  readonly userGroups = new BehaviorSubject<Array<{id: string, name: string}>>(this.getContextFromStorage('principle.userGroups', []))
  readonly currentUserGroup = new BehaviorSubject<{id: string, name: string}|null>(null)


  // Auth-popup state
  authPopup: boolean = false // TODO: Move the auth popup from interceptor to context
  clientId = 'bridge-app' // FIXME: move to .env

  readonly environment: typeof environment

  constructor(private router: Router) {
    this.environment = environment
    let user = this.getContextFromStorage('principle.user')
    let userGroup: any = this.getContextFromStorage('principle.userGroup')
    this.setCurrentUser(user as any)
    this.setUserGroup(userGroup, false)
    this.currentUserGroup.next(userGroup)

    this.userGroups.subscribe(
      userGroups => {
        this.saveContext('principle.userGroups', userGroups)
      }
    )
  }

  setCurrentUser(user: {apiKey: string, email: string, [key: string]: any}|undefined) {
    this.principle.user = user
    this.saveContext('principle.user', this.principle.user)
  }

  setUserGroup(value: {[key: string]: any}, reload=true) {
    this.principle.userGroup = value
    this.saveContext('principle.userGroup', this.principle.userGroup)
    this.subject.next({type: 'principle.userGroup', payload: [value]})
    this.currentUserGroup.next(value as any)
    if(reload) this.reload()
  }

  // It is responsibility of contextService to load APS configuration
  // from different resources and provide to the calling function
  getApiConfig(): ReturnType<typeof apiConfig> {
    const apiUrl = localStorage.getItem('__API_URL__')
    return {...apiConfig(apiUrl || environment.apiUrl)}
  }

  /// Example:
  /// context.on('', (eventType, ...args)=>{})
  /// context.on('ui*', (eventType, ...args)=>{})
  /// context.on('ui.OPEN_SIDE_NAV', (...args)=>{})
  on(event: string, callback: Function) {
    if (event.length === 0) return this.subject.asObservable().subscribe(event => callback(event.type, ...event.payload))
    return this.subject.asObservable().pipe(filter((x: Event) => (new RegExp(event)).test(x.type))).subscribe(_event => _event.type === event ? callback(..._event.payload) : callback(_event.type, ..._event.payload))
  }

  emit(event: string, ...payload: Event['payload']) {
    this.subject.next({type: event, payload})
  }

  public getContextFromStorage(key: string, defaultValue?: any):JSON|any {
    let data = localStorage.getItem(key)
    if(!data && defaultValue)return defaultValue;

    try {
      return JSON.parse(data as string) as any
    } catch {
      return JSON.parse('{}')
    }
  }

  public saveContext(key: string, data?: Object) {
    if(data) {
      localStorage.setItem(key, JSON.stringify(data))
    } else {
      localStorage.removeItem(key)
    }
  }

  reload() {
    const defaultStratergy = this.router.routeReuseStrategy.shouldReuseRoute
    this.router.routeReuseStrategy.shouldReuseRoute = () => false

    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
      setTimeout(() => this.router.routeReuseStrategy.shouldReuseRoute = defaultStratergy)
    });
  }

  ngOnDestroy() {
    this.saveContext('principle.user', this.principle.user)
  }
}


<!-- TODO: move custom css to css file -->
<div style="height: 100%;display: flex;align-items: center;" [nbSpinner]="loader" nbSpinnerStatus="danger">
  <div style="width: 35%;height: 100%; background-color: #4c7ff0;display: flex;flex-direction: column;align-items: center;gap: 1rem;justify-content: center;">
    <h1 style="color: white;margin-top: -4rem;">Quick Start</h1>

    <div style="display: grid;grid-template-columns: 1fr 1fr;gap:1rem">
      <div class="quick-start-item" title="Filter data">
        <img src="/assets/basic_pipeline.png" style="width: 10rem;height: 6rem;">
      </div>
  
      <div class="quick-start-item" title="Push API">
        <img src="/assets/s3-as-bridge.png" style="width: 10rem;height: 6rem;">
      </div>
  
      <div class="quick-start-item">
  
      </div>
  
      <div class="quick-start-item">
  
      </div>
    </div>

  </div>
  <form 
    [formGroup]="pipeline" (ngSubmit)="onSubmit()"
    style="flex-grow: 1;height: 100%; background-color: whitesmoke;display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 1rem;">
    <h1 style="color: rgba(0, 0, 0, 0.68);margin-top: -4rem;">Create Pipeline</h1>
  
    <div style="width: max(20rem, 40%);">
      <input #pipelineName nbInput fullWidth placeholder="pipeline name" formControlName="name">
    </div>

    <div style="width: max(20rem, 40%);">
      <input nbInput fullWidth placeholder="created by" formControlName="email">
    </div>
    

    <hr style="width: max(20rem, 40%);margin: 0;">

    <div style="width: max(20rem, 40%);display: flex;gap:.5rem">
      <!-- <input nbInput fullWidth placeholder="group" formControlName="groupid"> -->
      <label 
        style="background-color: powderblue;
        border-radius: .5rem;border: 1px solid rgba(22, 22, 22, 0.1);
        padding: 0 1rem;"
        
        class="input-label">
        <span>
          <nb-icon icon="attach-2-outline"></nb-icon>
          Import 
        </span>
        <input type="file" style="display: none;" formControlName="selectedFile" (change)="onFileSelect($event)">
      </label>
      <nb-select formControlName="groupid" required placeholder="group" style="flex-grow: 1;">
        <nb-option *ngFor="let values of groupIds"
          [value]="values.id">{{values.name}}</nb-option>
        </nb-select>
    </div>

    
    <div style="display: none;width: max(20rem, 40%);height: 2.5rem;border-radius: .5rem;border: 1px solid rgba(22, 22, 22, 0.1);background-color: powderblue;font-size: medium;">
      <label class="input-label">
        <span>
          <nb-icon icon="attach-2-outline"></nb-icon>
          Import pipeline
        </span>
        <input type="file" style="display: none;" formControlName="selectedFile" (change)="onFileSelect($event)">
      </label>
    </div>

    <div style="width: max(20rem, 40%);height: 3rem;display: flex;justify-content: space-between;">
      <div style="height: 3rem;border-radius: .5rem;">
        <input nbButton type="button" value="Reset" type="reset" />
      </div>
      <div style="height: 3rem;border-radius: .5rem;">
        <button nbButton hero status="info" [disabled]="pipeline.invalid" type="submit">Submit</button>
      </div>
    </div>

  </form>
</div>




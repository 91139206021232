<div [formGroup]="schemaDetails">
    <div>
        <div class="matching-type">
            <label>Matching Type</label>
            <nb-select name="matchingType" formControlName="matchingType">
                <nb-option *ngFor="let option of matchingTypeOptions" [value]="option.value">
                    {{option.key}}
                </nb-option>
            </nb-select>
        </div>
            <button nbButton status="danger" nbTooltip="delete sheet" (click)="delete_sheet()" style="float: right;"><nb-icon icon="trash-2-outline"></nb-icon></button>
    </div>
        <table>
            <thead>
                <th></th>
                <th>Column Name</th>
                <th>Data Type</th>
                <th>mandatory</th>
                <th></th>
            </thead>
            <tbody formArrayName="schema" cdkDropList (cdkDropListDropped)="onDrop($event)">
                <ng-container *ngFor="let col of SchemaKeys.controls;let i = index">
                    <tr [formGroupName]="i" cdkDrag>
                        <td cdkDragHandle class="handle">
                            <nb-icon icon="move-outline" status="primary" nbTooltip="move"></nb-icon>
                        </td>
                        <td>
                            <input nbInput type="text" formControlName="columnName" [class]="['column',duplicate_columns.get(i)?'invalid':'valid']" (input)="checkDuplicates($event,i)" class="" required>
                        </td>
                        <td>
                            <nb-select formControlName="dType" required>
                                <nb-option *ngFor="let dt of data_type | keyvalue" [value]="dt.value">{{
                                    dt.key
                                    }}</nb-option>
                            </nb-select>
                        </td>
                        <td>
                            <input type="checkbox" formControlName="isMandatory">
                        </td>
                        <td>
                            <button nbButton status="danger" size="tiny" shape="round" (click)="removeColumn(i)"><nb-icon icon="minus-outline"></nb-icon></button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <button nbButton status="success" (click)="addSchema()" class="add-schema">+ Add Column</button>
    </div>

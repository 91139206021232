import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PipelineResponse, PipelineService } from '../../services/api/pipeline.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DataService } from 'src/app/services/api/data.service';
import { NavbarService } from 'src/app/services/core/navbar.service';
import { NB_WINDOW, NbMenuService } from '@nebular/theme';
import { Subject } from 'rxjs'
import { filter, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  NbComponentStatus,
  NbGetters,
  NbGlobalPhysicalPosition,
  NbSearchService,
  NbToastrService,
  NbTreeGridDataSource,
  NbTreeGridDataSourceBuilder
} from "@nebular/theme";
import { UIService } from "../../services/config/ui.service";
import { GroupService, group } from 'src/app/services/api/group.service';
import { DashboardStoreService } from 'src/app/services/store/dasboard-store.service';
import { ConfirmationPopupComponent } from 'src/app/components/confirmation-popup/confirmation-popup.component';
import { ConfirmationPopupModel } from 'src/app/components/confirmation-popup/confirmation-popup';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs/internal/Subscription';
import { CellEditorComponent } from 'ag-grid-community/dist/lib/components/framework/componentTypes';
import { UploadUtilityBasicConfigComponent } from 'src/app/components/upload-utility-basic-config/upload-utility-basic-config.component';
export const columns = [
  "Name",
  "Date Created",
  "Actions"
];
interface FSEntry {
  id: string;
  name: string;
  email: string;
  created_time: string;
  action?: string;
  status: string;
}
@Component({
  selector: 'app-view-pipeline',
  templateUrl: './view-pipeline.component.html',
  styleUrls: ['./view-pipeline.component.css'],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        maxWidth: '100rem', // Set the maximum width to 90vw
        maxHeight: '100rem', // Set the maximum height to 90vh
      },
    },
  ],
})
export class ViewPipelineComponent implements OnInit, OnDestroy {
  filterTextChanged: Subject<string> = new Subject<string>();
  customColumn = 'index';
  defaultColumns = ['index', 'name', 'group', 'created_time', 'last_runtime', 'pipeline_run', 'action'];
  defaultNames: { [key: string]: string } = {
    index: 'Status',
    name: "Pipe Name",
    group: "Group Name",
    created_time: 'Date',
    action: 'Action',
    last_runtime: 'Last Run',
    pipeline_run: 'Pipeline Run'
  };
  allColumns = [/*this.customColumn, */...this.defaultColumns];
  dataSource: NbTreeGridDataSource<FSEntry>;
  subscriptions: Array<Subscription> = [];
  data: PipelineResponse['data'] = [];
  menu_items = [
    { title: "Copy" }
  ]
  getters: NbGetters<FSEntry, FSEntry> = {
    dataGetter: (node: FSEntry) => node,
    childrenGetter: (node: FSEntry) => undefined,
    expandedGetter: (node: FSEntry) => false,
  };
  //Page variables for mat-paginator
  pageIndex: number = 0;
  pageSize: number = 20;
  totalCount: number = 0;

  //Default page-variables
  PAGE_NUM: number = 1;
  PAGE_SIZE: number = 20;

  pageQuery: string = '';

  //Pipeline response
  pipelineResponse: any;
  //get group details
  groupView: boolean = false;
  group_id: number | undefined = undefined;
  group_name: string = '';
  grouplist: Array<{
    id:number,
    name:string,
    icon:any,
    comment:string,
    created_time:Date,
    updated_time:Date
}> = []
  isProgress: Map<number, boolean> = new Map
  constructor(private pipelineService: PipelineService,
    private router: Router,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    private data_service: DataService,
    private navbarService: NavbarService,
    private dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>,
    private toastrService: NbToastrService,
    private nbMenuService: NbMenuService,
    private group_service: GroupService,
    public uiService: UIService,
    public dashboardService: DashboardStoreService,
    public dialog: MatDialog) {
    this.dataSource = dataSourceBuilder.create([], this.getters);
  }
  async ngOnInit() {
    let response = await this.group_service.listGroups().toPromise()
    this.grouplist = Object.values(response);
    console.log(this.grouplist)
    this.navbarService.create = true;
    this.navbarService.view = true;
    this.navbarService.back = false;
    await this.registerEvents();
  }
  async registerEvents() {
    let subscription = this.route.paramMap.subscribe(async (response: any) => {
      this.group_id = response.get('group_id') ? parseInt(response.get('group_id')) : undefined
      this.groupView = !!this.group_id
      if (this.group_id) {
        let groups = await this.group_service.getGroupById(this.group_id).toPromise()
        this.group_name = groups[0].name
      }
      await this.loadPipelineList(this.PAGE_NUM, this.PAGE_SIZE, this.pageQuery)
    });
    this.subscriptions.push(subscription)


    subscription = this.filterTextChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(filterQuery => {
        this.loadPipelineList(this.PAGE_NUM, this.PAGE_SIZE, filterQuery);
      });

    this.subscriptions.push(subscription)


  }

  async loadPipelineList(index: number, size: number, query: string) {
    this.uiService.isContentLoading = true
    let pipelineResponse = await this.pipelineService.getList({ params: { group_id: this.group_id, pageNum: index, itemsPerPage: size, search: query } }).toPromise();

    this.totalCount = pipelineResponse.payload.totalCount
    this.pageIndex = pipelineResponse.payload.currentPage - 1

    this.data = pipelineResponse.payload.data.filter(x => x.status === 'active')

    this.data = this.data.map(obj => ({ ...obj, group:this.grouplist.find(x => x.id === obj.group_id) }))

    if (this.group_name) {
      const group = this.grouplist.find(x => x.name === this.group_name)
      const filteredData = this.data.filter((x: any) => x?.group_id === group?.id)
      this.dataSource = this.dataSourceBuilder.create(filteredData, this.getters);
    }
    else {
      this.dataSource = this.dataSourceBuilder.create(this.data, this.getters);
    }
    console.log(this.data)
    this.uiService.isContentLoading = false
  }
  delete(id: any) {
    this.pipelineService.deletePipeline(id).subscribe((result: any) => {
      this.toastrService.show("Warning", `Pipeline deleted`, { status, position: NbGlobalPhysicalPosition.BOTTOM_RIGHT });
      this.loadPipelineList(this.pageIndex + 1, this.pageSize, this.pageQuery)
    });
  }
  viewPipeline(id: number) {
    let config = { edit: false }
    this.router.navigate(['/dashboard', id], { queryParams: { config: btoa(JSON.stringify(config)) } });
  }
  editPipeline(id: number) {
    let config = { edit: true }
    this.router.navigate(['/dashboard', id], { queryParams: { config: btoa(JSON.stringify(config)) } });
  }
  viewPipelineHistory(id: any) {
    this.router.navigate(['view-pipeline', id, 'task-history']);
  }
  moreOptionsMenu(pipelineId: number) {
    localStorage.setItem('pipelineId', JSON.stringify(pipelineId));
  }
  runPipeline(id: number) {
    const dialogData = new ConfirmationPopupModel('RUN PIPELINE', 'Do you want to Run the Pipeline?');
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '80rem',
      maxWidth: '100rem',
      height:'42rem',
      closeOnNavigation: true,
      data: dialogData
    })
    dialogRef.afterClosed().subscribe(async dialogResult => {
      if (dialogResult) {
        this.isProgress.set(id, true)
        try {
          const response = await this.pipelineService.triggerPipeline(id).toPromise()
          this.isProgress.set(id, false)
          if (response.status === 'success') {
            this.uiService.showInfo('Run request submitted!', 'success')
          }
        }
        catch {
          this.uiService.showError('Failed to run pipeline!')
          this.isProgress.set(id, false)
        }
      }
    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
  async filterGroupBased() {
    this.uiService.isContentLoading = true

    const group = this.grouplist.find(x => x.name === this.group_name)
    this.group_id = group?.id
    await this.loadPipelineList(this.PAGE_NUM, this.PAGE_SIZE, this.pageQuery)
    this.uiService.isContentLoading = false
  }
  pageChange(event: any) {
    // for page size change pageIndex
    let page_index = this.pageSize !== event.pageSize ? this.PAGE_NUM : event.pageIndex + 1
    this.pageSize=event.pageSize
    this.loadPipelineList(page_index, event.pageSize, this.pageQuery)

  }
  onFilterTextChanged(event: any) {
    this.filterTextChanged.next(event.target.value);
  }
}
<div style="display:flex;height: 100%;width: 100%;position: absolute;" [nbSpinner]="isLoading" nbSpinnerStatus="basic">
  <div style="min-width: 12rem;display: flex;flex-direction: column;background-color: #F2F4FC;border: 1px solid #DEE2E6;transition: .5s;" [hidden]="hideMenu">
    <header *ngIf="title">
      {{title}}
    </header>

    <ul style="list-style: none;margin: 0;padding: 0;" class="vertical-menu">
      <li class="menu-item" *ngFor="let item of toolbar.options" [class.active]="toolbar.selectedTabName===item.name" (click)="toolbar.selectedTabName=item.name">{{item.name|titlecase}}</li>
    </ul>

    <label for="menu-toggle-1" *ngIf="!hideMenu" title="Hide menu">
      <input id="menu-toggle-1" alt="Show menu" type="checkbox" hidden (change)="hideMenu=!hideMenu">
    </label>
  </div>

  <div style="flex-grow: 1;position: relative;overflow: hidden;margin: .5rem;" *ngIf="data">

    <div *ngIf="!hideMenu&&data[toolbar.selectedTabName][STRING_KEY_VALUE_TYPE]" style="padding: 1rem;">
      <div *ngFor="let item of data[toolbar.selectedTabName]|keyvalue">
        <span style="color: brown;">{{item.key}}: </span> <span style="color: olive;">{{item.value}}</span> 
      </div>
    </div>

    <ng-container *ngIf="(!data[toolbar.selectedTabName][STRING_KEY_VALUE_TYPE])||hideMenu">
      <app-print-json [data]="hideMenu?data:data[toolbar.selectedTabName]" *ngIf="!error&&!isLoading&&data"></app-print-json>
    </ng-container>

    <div style="height:100%;display: flex;align-items: center;justify-content: center;" *ngIf="error">
      <code>Error: </code>&nbsp; {{error}}
    </div>
    <label for="menu-toggle-2" *ngIf="hideMenu" title="Show menu">
      <input id="menu-toggle-2" alt="Show menu" type="checkbox" hidden (change)="hideMenu=!hideMenu">
    </label>
  </div>
</div>

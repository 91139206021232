
<section class="map-background">
    {{apiForm.value | json}}
    <header mat-dialog-title >
        <h4 class="map-header">API:&nbsp;{{api_keys_data.apiName}}</h4>
    </header>
    <div class="map-body">
        <form [formGroup]='apiForm' (ngSubmit)="show_data();">
            <table class="table map-table table-striped">
                <thead>
                    <th i=1>#</th>
                    <th>Output Data [API]</th>
                    <th>Is Mapping Mandatory</th>
                    <th>Input Data </th>
                </thead>
                <tbody>
                    <tr *ngFor="let api_key of apiKeys; let i = index;">
                        <td>{{i}}</td>
                        <td><label for="{{api_key.keyId}}">{{api_key.key}}({{api_key.dataType}})</label></td>
                        <td><label for="{{api_key.keyId}}">{{api_key.isMandatory}}</label></td>
                        <td>
                            <select name="" id="{{api_key.keyId}}" class="custom-select" [formControlName]="api_key.keyId">
                                <option [disabled]="true">(Select)</option>
                                <option name="" *ngFor="let col of schema" value = "{{col.id}}">{{col.key_name}} ({{col.data_type}})</option>
                            </select>
                        </td>
                    </tr>
                    <tr >

                    </tr>
                </tbody>
            </table>
            <div class="save-mapping">
                <button class="btn btn-success" type="submit" style="right: 10px;">Save the Mapping</button>
        </div>
        </form>
    </div>
</section>

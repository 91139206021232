import { Component, HostListener, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/api/data.service';
import { NavbarService } from 'src/app/services/core/navbar.service';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, OnChanges {

  nav = {
  	create:true,
  	view:true,
  	back:false
  }


  constructor(private router: Router,public data_service:DataService, public navbarService: NavbarService) {

  	console.log(this.navbarService.create,this.navbarService.view,this.navbarService.back);
  }

  ngOnInit(): void {
  	console.log("URL:",this.router.url);
  }

  ngOnChanges(){
  	console.log("URL:",this.router.url);
  }

  gotoView(){
  	this.router.navigateByUrl('/view-pipeline');
  }

  gotoCreate(){
  	this.router.navigateByUrl('/create-pipeline');
  }


}

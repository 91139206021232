import { ComponentPortal, DomPortal, Portal, TemplatePortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs'

export type MyPortal = TemplatePortal | ComponentPortal<any> | DomPortal;

@Injectable({
  providedIn: 'root'
})
export class SideNavService {
  //all related to folder birectionality
  public selectedFolder = new Subject<any>();
  public expandedFolder = new Subject<string>();
  public templateCategory = new BehaviorSubject<string>("");


  public rightNavtemplatePortal:  TemplatePortal<any> | ComponentPortal<any> | null = null
  show = false

  // readonly portal$ = this.activePortal.asObservable();

  constructor() {}

  // setPortal(portal: TemplatePortal) {
  //   console.log(portal)
  //   this.activePortal.next(portal);
  // }
  setRightNavTemplate(templatePortal:TemplatePortal<any> | ComponentPortal<any>){
    this.rightNavtemplatePortal?.detach()
    this.rightNavtemplatePortal = templatePortal
  }

  removeRightNavTemplate()
  {
    this.rightNavtemplatePortal?.detach()
    this.rightNavtemplatePortal = null
  }
}

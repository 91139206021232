import {environment} from '../../../environments/environment'

export default (apiUrl: string = environment.apiUrl || 'http://127.0.0.1:8000') => ({
  apiUrl,
  AUTH_APIS: {
    GET: {
      accounts: `${apiUrl}/auth/userInfo`
    },
    POST: {
      login: `${apiUrl}/auth/login`,
      register: `${apiUrl}/user/create`,
      refresh: `${apiUrl}/auth/refersh`
    }
  },
  CONFIG_APIS: {
    GET: {
      // ConfigService
      nodeType: `${apiUrl}/pipeline/v1/configuration`,
      // ConfigKeyService
      nodeParams: `${apiUrl}/pipeline/v1/configDetails`,
    },
    POST: {
      nodeType: `${apiUrl}/pipeline/v1/configurationValue`
    }
  },

  PIPELINE_APIS: {
    GET: {
      pipeline: `${apiUrl}/pipeline/v1/pipeline`,
      pipelineDetails: `${apiUrl}/pipeline/v1/pipelineDetails`,
      taskNode: `${apiUrl}/pipeline/v1/tasknode`,
      pipelinevariables: `${apiUrl}/pipeline/v1/add/pipelinevariables`,
      schedule: `${apiUrl}/pipeline/v1/schedule`,
      config: `${apiUrl}/pipeline/v1/bulkConfigs`,
      export: `${apiUrl}/pipeline/v1/export`,
      public: `${apiUrl}/pipeline/v1/public_url`,

      apiDetails: `${apiUrl}/pipeline/v1/apiSink/apiDetailsV4`,

      taskConfigDetails: `${apiUrl}/pipeline/v1/taskconfigDetails`,

      // NodeStatus
      nodeStatus: `${apiUrl}/pipeline/v2/nodeStatus`,

      // ProcessHistory
      processHistory: `${apiUrl}/pipeline/v1/processHistory`,

      // API Details
      apiSinkDetailsV2: `${apiUrl}/pipeline/v1/apiSink/apiDetailsV2`,
      apiSinkDetailsV3: `${apiUrl}/pipeline/v1/apiSink/apiDetailsV3`

    },
    POST: {
      taskNode: `${apiUrl}/pipeline/v1/tasknode`,
      runTaskNode: `${apiUrl}/pipeline/v1/runtasknode`,
      stopNode: `${apiUrl}/pipeline/v1/stopNode`,

      /**
       * @deprecated since version 2.0
       */
      // runPipeline: `${apiUrl}/pipeline/v1/sequentialRun`,

      stopPipeline: `${apiUrl}/pipeline/v1/stopSequentialRun`,

      
      
      sinkPreview: `${apiUrl}/pipeline/v1/sinkPreview`, // TODO: this api should be a get api
      import: `${apiUrl}/pipeline/v1/import`,

      testScript: `${apiUrl}/pipeline/v1/validateCode`,
    },
    PUT: {
      updateDetails: `${apiUrl}/pipeline/v1/apiSink/newApi`,
      updatePipelineName: `${apiUrl}/pipeline/v1/pipeline`
    },
    DELETE:{
      deleteTaskNode: `${apiUrl}/pipeline/v1/tasknode`
    }
  },

  DATA_APIS: {
    GET: {
      taskOutput: `${apiUrl}/pipeline/v1/taskOutput`,
      upload: `${apiUrl}/pipeline/v1/upload`,
      getPublicUrl: `${apiUrl}/pipeline/v1/public_url`,
      getFileContent: `${apiUrl}/pipeline/v1/fetchBridgeFileContent`,
    },
    POST: {
      changeDataType: `${apiUrl}/pipeline/v1/datatype/change`
    }
  },

  NOTIFICATION_APIS:{
    GET: {
      get_notifications_by_id: `${apiUrl}/pipeline/v1/pipelineNotifications?pipelineId=`
    },
    POST: {
      add_recipient: `${apiUrl}/pipeline/v1/pipelineNotifications`
    },
    PUT: {
      update_recipient:  `${apiUrl}/pipeline/v1/pipelineNotifications?subscriber_id=`
    },
    DELETE: {
      delete_recipient:  `${apiUrl}/pipeline/v1/pipelineNotifications?subscriber_id=`
    }
  },

  GROUP_APIS: {
    GET:{
      listGroups: `${apiUrl}/pipeline/v1/group`,
      groupById: `${apiUrl}/pipeline/v1/group?groupId=`
    },
    POST:{
      newGroup: `${apiUrl}/pipeline/v1/group`
    },
    PUT:{
      addToGroup: `${apiUrl}/pipeline/v1/addToGroup`,
      editGroup:`${apiUrl}/pipeline/v1/group`//to add pipeline to group
    }
  },

  TASK_APIS: {
    GET:{
      getTaskPosition: `${apiUrl}/pipeline/v1/taskposition`,
      getAllTaskPositions: `${apiUrl}/pipeline/v1/fetchallTaskPosition`
    },
    POST:{
      taskposition: `${apiUrl}/pipeline/v1/taskposition`
    },
    PUT:{}
  },

  //v2 apis
  NODE_APIS:{
    GET:{
      nodes: `${apiUrl}/core/v2/connectors`,
      //node details like connection isEnabled or not, services
      NodeDetails:(pipelineId:string,nodeName:string)=> `${apiUrl}/pipeline/${pipelineId}/v2/nodeDetails/${nodeName}`,
      //all the nodeInstance details like connectionInstanceId
      nodeInstance:(pipelineId:string,nodeInstanceId:string) => `${apiUrl}/pipeline/${pipelineId}/v2/${nodeInstanceId}`,
      nodeOutput:(pipelineId:string,nodeInstanceId:string) => ` ${apiUrl}/pipeline/${pipelineId}/v2/nodeInstance/${nodeInstanceId}/output`
    },
    POST:{
      nodeInstance: (pipelineId:string)=> `${apiUrl}/pipeline/${pipelineId}/v2/nodeInstance`,
      nodeSetConnection: (pipelineId:string,versionId:string,nodeId:string) => `${apiUrl}/core/v2/pipeline/${pipelineId}/${versionId}/node/${nodeId}/setConnection`
    },
    PUT:{
      nodeInstance:(pipelineId:string,nodeInstanceId:string) => `${apiUrl}/pipeline/${pipelineId}/v2/nodeInstance/${nodeInstanceId}`
    }
  },

  TOPICS_APIS:{
    GET:{
      topicDetails: `${apiUrl}/core/v2/pubSub/topics`
    },
    POST:{
      topicInstance: `${apiUrl}/core/v2/pubSub/topic`,
      updateAccessInstance:(topicId:string) => `${apiUrl}/core/v2/pubSub/topic/${topicId}/changeAccessLevel`
    },
    PUT:{
      topicUpdateInstance:(topicId:string) => `${apiUrl}/core/v2/pubSub/topic/${topicId}`
    }
  },

  CONNECTION_APIS:{
    GET:{
      connectionDetails:(pipelineId:string,nodeInstanceId:string)=>`${apiUrl}/pipeline/${pipelineId}/v2/nodeInstance/${nodeInstanceId}/connectionDetails`,
      connectionInstances:(nodeName:string)=>`${apiUrl}/pipeline/v2/connectionInstance?nodeName=${nodeName}`,
      connectionInstance:(connectionInstanceId:string)=>`${apiUrl}/pipeline/v2/connectionInstance/${connectionInstanceId}`,
      connectionAllInstances:`${apiUrl}/pipeline/v2/connectionInstance`
    },
    POST:{
      connectionRemap:(pipelineId:string,nodeInstanceId:string)=>`${apiUrl}/pipeline/${pipelineId}/v2/nodeInstance/${nodeInstanceId}/connectionRemap`,
      connectionTest:(pipelineId:string,nodeInstanceId:string) => `${apiUrl}/pipeline/${pipelineId}/v2/nodeInstance/${nodeInstanceId}/connectionTest`,
      connectionInstance: `${apiUrl}/pipeline/v2/connectionInstance`,
    },
    PUT:{
      connectionInstance:(connectionInstanceId:string)=>`${apiUrl}/pipeline/v2/connectionInstance/${connectionInstanceId}`
    }
  },

  SERVICE_APIS:{
    GET:{
      serviceDetails:(connectorId:string)=>`${apiUrl}/core/v2/connector/${connectorId}/serviceDetails`
    },
    POST:{
      serviceFormRemap:(connectorId:string)=>`${apiUrl}/core/v2/connector/${connectorId}/serviceRemap`,
      saveService:(pipelineId:string,pipelineVersion:string,nodeId:string)=>`${apiUrl}/core/v2/pipeline/${pipelineId}/${pipelineVersion}/node/${nodeId}/service`
    }
    
  },

  PIPELINE_APIS_V2:{
    GET:{
      // pipelineDetails:(pipelineId:string)=>`${apiUrl}/pipeline/${pipelineId}/v2/pipelineDetails`,
      pipelineDetails:(pipelineId:string)=>`${apiUrl}/core/v2/pipeline/${pipelineId}`,
      getNodesConfig: () => `${apiUrl}/pipeline/v2/node`,
      getNodeOutput:(pipelineId:string,nodeInstanceId:string,processId:string,field:string) => `${apiUrl}/pipeline/${pipelineId}/v2/nodeInstance/${nodeInstanceId}/output?processId=${processId}&field=${field}`,
      //new ones
      pipelines:()=>`${apiUrl}/core/v2/pipelines`,
      pipeline:(pipelineId:string)=>`${apiUrl}/core/v2/pipeline/${pipelineId}`,
      pipelineVersions:(pipelineId:string)=>`${apiUrl}/core/v2/pipeline/${pipelineId}/versions`,
      process:(pipelineId:number, processId:number)=>`${apiUrl}/core/v2/pipeline/${pipelineId}/process/${processId}`,
      nodeOutput:(pipelineId:number, processId:number) => `${apiUrl}/core/v2/pipeline/${pipelineId}/manualTrigger/nodeOutput?process_id=${processId}`,
      pipelineProcesses:(pipelineId:string)=>`${apiUrl}/core/v2/pipeline/${pipelineId}/processes`

    },
    POST: {
      addDynamicField: (pipelineId: number, nodeInstanceId: number|string) => `${apiUrl}/pipeline/${pipelineId}/v2/nodeInstance/${nodeInstanceId}/edge`,
      runNodeInstance: (pipelineId: number, nodeInstanceId: number|string) => `${apiUrl}/pipeline/${pipelineId}/v2/nodeInstance/${nodeInstanceId}/run`,
      trigger: (pipelineId: number) =>`${apiUrl}/core/v2/pipeline/${pipelineId}/trigger`,
      pipeline:`${apiUrl}/core/v2/pipeline`,
      manualtriggerInputRemap:(pipelineId:number, pipelineVersionId:number)=>`${apiUrl}/core/v2/pipeline/${pipelineId}/${pipelineVersionId}/manualTrigger/inputRemap`,

      runPipeline: (pipelineId: number, version?: number) => `${apiUrl}/core/v2/pipeline/${pipelineId}/trigger`,

      createPipeline: () => `${apiUrl}/core/v2/pipeline`,
      refreshToken: () => `${apiUrl}/auth/refresh`,
      editInitPipelint: (pipeline_id: number) => `${apiUrl}/core/v2/pipeline/${pipeline_id}/editInit`
    },
    DELETE: {
      removeDynamicField: (pipelineId: number, nodeInstanceId: number, fieldId: number) => `${apiUrl}/pipeline/${pipelineId}/v2/nodeInstance/${nodeInstanceId}/edge/${fieldId}`
    },
    PUT:{
      pipeline:(pipelineId:string)=>`${apiUrl}/core/v2/pipeline/${pipelineId}`,
    }
  },

  DATA_API_V2:{
    GET:{
      public:`${apiUrl}/core/v2/publicUrl`,

    },

    POST:{
      upload:(pipeline_id:string | null, pipelineVersionId:number)=>`${apiUrl}/core/v2/pipeline/${pipeline_id}/${pipelineVersionId}/fileUpload`
    }
  },

  CUSTOM_CONNECTOR_API:{
    POST:{
      createConnector: `${apiUrl}/core/v2/connectorSDK/connector`,
      saveAssets: `${apiUrl}/core/v2/assets`,
      releaseVersion:(connectorId:string)=>`${apiUrl}/core/v2/connectorSDK/connector/${connectorId}/release`,
      restoreVersion:(connectorId:string)=>`${apiUrl}/core/v2/connectorSDK/connector/${connectorId}/restore`,
      boilerPlate:(connectorId:string)=>`${apiUrl}/core/v2/connectorSDK/connector/${connectorId}/init`,
      editInit:(connectorId:string)=>`${apiUrl}/core/v2/connectorSDK/connector/${connectorId}/editInit`,

      autoComplete:(connectorId:string)=>`${apiUrl}/core/v2/connectorSDK/connector/${connectorId}/autoComplete`,
      lint:(connectorId:string)=>`${apiUrl}/core/v2/connectorSDK/connector/${connectorId}/lint`,
      saveFile:(connectorId:string)=>`${apiUrl}/core/v2/connectorSDK/connector/${connectorId}/saveFile`
    },
    GET:{
      connectors:`${apiUrl}/core/v2/connectorSDK/connectors`,
      connectorDetails:(connectorId:string)=> `${apiUrl}/core/v2/connectorSDK/connector/${connectorId}`,
      versionDetails:(connectorId:string,versionNum:string)=>`${apiUrl}/core/v2/connectorSDK/connector/${connectorId}/version/${versionNum}`,
      versions:(connectorId:string)=>`${apiUrl}/core/v2/connectorSDK/connector/${connectorId}/versions`,
      checkDeployerService:(connectorId:string,versionId:string)=>`${apiUrl}/core/v2/connectorSDK/connector/${connectorId}/deploymentStatus?versionId=${versionId}`
    }
  },
  CONNECTOR_API:{
    GET:{
      connectors:`${apiUrl}/core/v2/connectors`,
      connectorDetail:(connectorId:string)=>`${apiUrl}/core/v2/connector/${connectorId}`,
      connections:(connId:string)=>`${apiUrl}/core/v2/connections?connectorId=${connId}`,
      connectionDetailsById:(connectionid:string)=>`${apiUrl}/core/v2/connection/${connectionid}`,
      connectionFormDetails:(connectorId:string)=>`${apiUrl}/core/v2/connector/${connectorId}/connectionDetails`
    },
    POST:{
      createNode:(pipelineId:string,pipelineVersion:string)=>`${apiUrl}/core/v2/pipeline/${pipelineId}/${pipelineVersion}/createNodeInit`,
      connectionRemap:(connectorId:string)=>`${apiUrl}/core/v2/connector/${connectorId}/connectionRemap`,
      testConnection:(connectorId:string)=>`${apiUrl}/core/v2/connector/${connectorId}/connectionTest`,
      createConnection:`${apiUrl}/core/v2/connection`
    }
  }
  ,
  GROUP_APIS_V2:{
    GET:{
      groups:`${apiUrl}/core/v2/groups`,
      group:(groupId:string)=>`${apiUrl}/core/v2/group/${groupId}`,
      allDirectories:`${apiUrl}/core/v2/groupNavTree`
    },
    POST:{
      group:`${apiUrl}/core/v2/group`
    },
    PUT:{
      group:(groupId:string)=>`${apiUrl}/core/v2/group/${groupId}`
    }
  },

  DIRECTORY_APIS_V2:{
    GET:{
      directories:`${apiUrl}/core/v2/directories`, //?group_id=1
      directory:(directoryId:string)=>`${apiUrl}/core/v2/directory/${directoryId}`
    },
    POST:{
      directory:`${apiUrl}/core/v2/directory`
    },
    PUT:{
      directory:(directoryId:string)=>`${apiUrl}/core/v2/directory/${directoryId}`
    }
  },

  ACCOUNTS_APIS_V2:{
    GET:{
      accounts:`${apiUrl}/auth/accounts`,
      account:(accountId:string)=>`${apiUrl}/auth/account/${accountId}`,
      userInfoV2:`${apiUrl}/auth/userInfoV2`
    }
  },

  DASHBOARD_APIS_V2:{
    GET:{
      pipelineStats:`${apiUrl}/core/v2/dashboard/pipelineStats`,
      recentProcesses:`${apiUrl}/core/v2/dashboard/recentProcesses`,
      processesRunData:`${apiUrl}/core/v2/dashboard/processes`,
    },
    POST:{
    },
    PUT:{
    }
  },

})
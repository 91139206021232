import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import {
  ProcessHistoryResponse,
  ProcessHistoryService,
} from '../api/process-history.service';
import { ConsoleLoggerService } from '../logger/console-logger.service';
import { ActivatedRoute } from '@angular/router';

export type TaskHistoryConfig = {};

type ProcessStatusType = {
  processId: string,
  processStatus: 'success' | 'failed' | 'in_progress',
  executionTime: string,
  scheduledTime: string,
  nodes: {
    [key: string]: {
      status: 'success' | 'failed' | 'in_progress',
      taskHistoryId: string,
      executionTime: string,
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class TaskHistoryStoreService {
  private readonly _configSource = new BehaviorSubject<TaskHistoryConfig>({
    pipelineId: -1,
  });
  readonly config$ = this._configSource.asObservable();

  pipelineId: number = -1;
  private delay = 1000 * 2;

  constructor(
    private processHistory: ProcessHistoryService,
    private route: ActivatedRoute,
    private logger: ConsoleLoggerService
  ) {}

  public getTaskHistory(params: {
    pipelineId: number;
    from: number;
    size: number;
  }): Observable<ProcessHistoryResponse> {
    let observer = new Subject<ProcessHistoryResponse>();
    this.processHistory
      .get(params)
      .toPromise()
      .then((response) => {
        observer.next(response);
      })
      .catch((error) => {
        this.logger.error(error);
      });
    return observer.asObservable();
  }

  public getConfig() {
    return this._configSource.getValue();
  }

  public getPipelineHistory(nodes:string[]) {
    return new Promise<{ payload: {processRun: Array<ProcessStatusType>}}>((resolve) => {
      const processes:ProcessStatusType[] = []
      for(let i=0; i < 26; i++) {
        processes.push({
          processId: `${i + 1}`,
          processStatus: 'success',
          executionTime: 'Fab 3, 06:15',
          scheduledTime: 'Fab 3, 06:15',
          nodes: {}
        })

        for(let task of nodes) {
          processes[i].nodes[task] = {
            status: Math.floor((Math.random()*11)%2) ? 'success' : 'failed',
            taskHistoryId: '1',
            executionTime: '10',
          }
        }

      }
      resolve({ payload: {processRun: processes} });
    });
  }
}


// const historyPayload: {processRun: Array<ProcessStatusType>} = {
//   processRun: [
//     {
//       processId: '1',
//       processStatus: 'success',
//       executionTime: 'Fab 3, 06:15',
//       scheduledTime: 'Fab 3, 06:15',
//       nodes: {
//         '3184': {
//           status: 'success',
//           taskHistoryId: '1',
//           executionTime: '10',
//         },
//         '3246': {
//           status: 'failed',
//           taskHistoryId: '2',
//           executionTime: '21',
//         },
//       },
//     },
//     {
//       processId: '2',
//       processStatus: 'failed',
//       executionTime: 'Fab 3, 06:15',
//       scheduledTime: 'Fab 3, 06:15',
//       nodes: {
//         '3184': {
//           status: 'success',
//           taskHistoryId: '3',
//           executionTime: '12',
//         },
//         '3246': {
//           status: 'failed',
//           taskHistoryId: '5',
//           executionTime: '4',
//         },
//         '3214': {
//           status: 'in_progress',
//           taskHistoryId: '5',
//           executionTime: '4',
//         },
//       },
//     },
//   ],
// };

import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit,Output,EventEmitter } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent {
    @Input() imageChangedEvent: any = '';
    @Input() imageUrl: any = '';
    @Output('croppedImage') cropDone = new EventEmitter<any>();
    roundCropper:boolean= false
    croppedImage: any = '';
        obj:any = {
        rotate:0,
        scale:1
    }

    // fileChangeEvent(event: any): void {
    //     console.log(event)
    //     this.imageChangedEvent = event;
    // }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.cropDone.emit(this.croppedImage)
    }
    // imageLoaded(image: LoadedImage) {
    imageLoaded() {
        // show cropper
        console.log('imageLoaded')
    }
    cropperReady() {
        // cropper ready
        console.log('cropperReady()')
    }
    loadImageFailed() {
        // show message
        console.log('loadImageFailed()')
    }
    rotate(){
        this.obj = {
            rotate:this.obj.rotate+90
        }
    }
    zoom(i:'+'|'-'){
        this.obj = {
            ...this.obj,
            scale:i==='+'?3:1
        }
    }
    

}

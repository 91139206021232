import { Nodev2Service } from 'src/app/services/api/nodev2.service';

import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { PipelineResponse, PipelineService } from '../../services/api/pipeline.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DataService } from 'src/app/services/api/data.service';
import { NavbarService } from 'src/app/services/core/navbar.service';
import { NB_WINDOW, NbMenuService } from '@nebular/theme';
import { Subject } from 'rxjs'
import { filter, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  NbComponentStatus,
  NbGetters,
  NbGlobalPhysicalPosition,
  NbSearchService,
  NbToastrService,
  NbTreeGridDataSource,
  NbTreeGridDataSourceBuilder
} from "@nebular/theme";
import { UIService } from "../../services/config/ui.service";
import { GroupService, group } from 'src/app/services/api/group.service';
import { DashboardStoreService } from 'src/app/services/store/dasboard-store.service';
import { ConfirmationPopupComponent } from 'src/app/components/confirmation-popup/confirmation-popup.component';
import { ConfirmationPopupModel } from 'src/app/components/confirmation-popup/confirmation-popup';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs/internal/Subscription';
import { ConnectionFormRemap, Form, FormUpdateHandlerOnBlur } from 'src/app/components/dynamic-form/dynamic-form.component';
export const columns = [
  "Name",
  "Date Created",
  "Actions"
];
interface FSEntry {
  id: string;
  name: string;
  email: string;
  created_time: string;
  action?: string;
  status: string;
}
@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.css']
})
export class ConnectionsComponent  {
  // dialogRef : any;

  // defaultColumns = ['name', 'nodeName', 'usedInNodes', 'createdTime', 'status', 'options'];

  // defaultNames: any = {name: 'Name', nodeName: 'Node Name', usedInNodes:'Used Nodes', createdTime: 'Created Date', status: 'Status', options: 'Options'}
  // allColumns = [...this.defaultColumns];
  // getters: NbGetters<FSEntry, FSEntry> = {
  //   dataGetter: (node: FSEntry) => node,
  //   childrenGetter: (node: FSEntry) => undefined,
  //   expandedGetter: (node: FSEntry) => false,
  // };
  
  // public dataSource: NbTreeGridDataSource<FSEntry>;

  // @ViewChild('connectionEdit') connectionEdit!: TemplateRef<HTMLDivElement>;
  // connectionInstanceId: any;
  // constructor(private nodeService:Nodev2Service, private dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>, private dialog : MatDialog) { 
  //   this.dataSource = dataSourceBuilder.create([], this.getters);

  // }
  // connectionInstancs:Array<any>=[]
  // connectionFormRemap : any
  // formUpdateHandler : any

  // newConnectionForm = new Form();
  
  // ngOnInit(): void {
    
  //   this.nodeService.getAllConnectionInstances().subscribe(data=>{
  //     // console.log(data.payload)
  //     const tableData = data.payload
  //     this.dataSource = this.dataSourceBuilder.create(tableData, this.getters);
  //   })
    
  //   this.connectionFormRemap = new ConnectionFormRemap("1", "4", this.nodeService);
  //   this.formUpdateHandler = new FormUpdateHandlerOnBlur(this.newConnectionForm,this.nodeService,this.connectionFormRemap);
  //   this.formUpdateHandler.subscribe()

  // }
  // newConnectionName : string = "";

  // async editConnection(connectionInstanceId : number, showButton: boolean){

  //   this.dialogRef = this.dialog.open(this.connectionEdit,{data:{connectionInstanceId, showButton }})
  //   let connid = connectionInstanceId.toString()
  //   let connectionInstance:any = await this.nodeService.getConnectionInstance(connid).toPromise();
  //   this.newConnectionName= connectionInstance.payload.label;
  //   // let fieldValues = connectionInstance.payload.inputConfigs;
  //   let inputFields = await this.nodeService.getConnectionDetails("1","4").toPromise();
  //   // inputFields = inputFields.map((x:any)=>{
  //   //   return {...x,value:fieldValues[x.name]?.value}
  //   // })
  //   console.log(inputFields);
  //   this.newConnectionForm.build(inputFields);
    
  // }
  
  // async saveEditConnection(connectionInstanceId : number){
    
  //   let conn = connectionInstanceId.toString();
  //   const data = this.newConnectionForm.getValue()
  //   const newForm = data
  //   let newConnectionReqest:any = {
  //     nodeName:"mysql",
  //     name:this.newConnectionName,
  //     label:this.newConnectionName,
  //     inputConfigs:{

  //     }
  //   }
  //   newForm.forEach(d=>{
  //     newConnectionReqest.inputConfigs[d.name]={"value":d.value}
  //   })

  //   await this.nodeService.updateConnectionInstance(conn,newConnectionReqest).toPromise();
  //   // this.isEditConnection = false;
  //   this.dialogRef.close()
  //   window.location.reload()
  // }
}

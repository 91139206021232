<div style="margin-top: .5rem;">
  <div *ngFor="let subForm of form; let i = index">
    <!-- <div *ngIf="showTitle" style="display: flex;justify-content: space-between;position: sticky;top:-1px; background-color: white;z-index: 100;align-items: center"> -->
      <!-- <h6>{{subForm.title|titlecase}}</h6> -->
      <!-- <nb-icon style="cursor: pointer" icon="plus-circle-outline" status="primary"
               (click)="addNewField(fc, subForm.title)"></nb-icon> -->
    <!-- </div> -->
    <div *ngFor="let item of getFormAsArray(fc, subForm.title).controls" style="display: flex">
      <ng-component
        *ngTemplateOutlet="field;context: { item: item, parent: fc.controls[subForm.title] }"></ng-component>
      <div style="padding-top: .5rem;">
        <nb-icon icon="trash-2-outline" status="danger" style="cursor: pointer"
                 (click)="removeFormItem(getFormAsArray(fc, subForm.title), item, $event)"></nb-icon>
      </div>
    </div>
    <div>
      <button nbButton shape="round" size="tiny" (click)="addNewField(fc, subForm.title)" style="margin: .5rem;">      
        <nb-icon icon="plus-circle-outline"></nb-icon> 
        Add Field
      </button>
    </div>
  </div>
</div>


<ng-template #field let-item='item' let-parent='parent'>
  <table style="border-bottom: 1px solid #eee;margin-top: .5rem;">
    <tr *ngFor="let input of objOrder">

      <td>
        <div style="display: flex;justify-content: space-between"
             *ngIf="input!==keysOfObject.fields || (item.controls[keysOfObject.data_type].value===keysOfDataTypes.object||item.controls[keysOfObject.sub_type].value===keysOfDataTypes.object)">
          
             <span class="key-text"
             *ngIf="input!==keysOfObject.sub_type || item.controls[keysOfObject.data_type].value===keysOfDataTypes.list">{{nameAlias[input]}}</span>
           
             <!-- <code style="white-space: nowrap;"
            *ngIf="input!==keysOfObject.sub_type || item.controls[keysOfObject.data_type].value===keysOfDataTypes.list">{{nameAlias[input]}}</code> -->
          <!-- <nb-icon style="cursor: pointer" icon="plus-circle-outline" status="primary"
                   *ngIf="input===keysOfObject.fields"
                   (click)="addNewField(item, keysOfObject.fields)"></nb-icon> -->
        </div>
      </td>
      <td [ngSwitch]="input">
        <!-- Name -->
        <div *ngSwitchCase="keysOfObject.name">
          <input type="text" [formControl]="item.controls[input]" nbInput fieldSize="tiny"/>
        </div>


        <!-- Data Type -->
        <div *ngSwitchCase="keysOfObject.data_type">
          <nb-select size="tiny" [formControl]="item.controls[input]" [selected]="item.controls[input]">
            <nb-option *ngFor="let dt of dataTypes" [value]="dt" >{{dt}}</nb-option>
          </nb-select>
        </div>

        <!-- Sub Data Type -->
        <div *ngSwitchCase="keysOfObject.sub_type">
          <ng-container *ngIf="item.controls[keysOfObject.data_type].value===keysOfDataTypes.list">
            <nb-select size="tiny" [formControl]="item.controls[input]" [selected]="item.controls[input]">
              <nb-option *ngIf="item.controls[keysOfObject.data_type]!==keysOfDataTypes.list"></nb-option>
              <nb-option *ngFor="let dt of dataTypes" [value]="dt" >{{dt}}</nb-option>
            </nb-select>
          </ng-container>
        </div>

        <!-- Default value -->
        <div *ngSwitchCase="keysOfObject.default_value">
          <input type="text" [formControl]="item.controls[input]" nbInput fieldSize="tiny">
        </div>

        <!-- Is mandatory -->
        <div *ngSwitchCase="keysOfObject.is_mandatory">
          <nb-checkbox [formControl]="item.controls[input]"></nb-checkbox>
        </div>

        <!-- Is null allowed -->
        <div *ngSwitchCase="keysOfObject.is_null_allowed">
          <nb-checkbox [formControl]="item.controls[input]"></nb-checkbox>
        </div>

        <!-- Is blank allowed -->
        <div *ngSwitchCase="keysOfObject.is_blank_allowed">
          <nb-checkbox [formControl]="item.controls[input]"></nb-checkbox>
        </div>

        <!-- Skip Field -->
        <div *ngSwitchCase="keysOfObject.skip_field">
          <nb-checkbox [formControl]="item.controls[input]"></nb-checkbox>
        </div>

        <!-- child fields -->
        <div *ngSwitchCase="keysOfObject.fields">
          <ng-container
            *ngIf="input!==keysOfObject.fields || (item.controls[keysOfObject.data_type].value===keysOfDataTypes.object||item.controls[keysOfObject.sub_type].value===keysOfDataTypes.object)">
            <div>
              <div *ngFor="let itemChild of item.controls[keysOfObject.fields]?.controls" style="display: flex;">
                <ng-component
                  *ngTemplateOutlet="field;context: { item: itemChild, parent: item.controls[keysOfObject.fields] }"></ng-component>
  
                <div style="padding-top: .5rem;">
                  <nb-icon icon="trash-2-outline" status="danger" style="cursor: pointer"
                           (click)="removeFormItem(item.controls[keysOfObject.fields], itemChild, $event)"></nb-icon>
                </div>
              </div>
            </div>
            <button nbButton shape="round" size="tiny" (click)="addNewField(item, keysOfObject.fields)" style="width: auto;margin: .5rem;">      
              <nb-icon icon="plus-circle-outline"></nb-icon> 
              Add Field
            </button>
          </ng-container>
        </div>

      </td>
    </tr>
  </table>
</ng-template>

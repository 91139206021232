import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

enum NodeStatus {
  SUCCESS = 'success',
  FAILED = 'failed'
}

type TimelineItem = {
  column: number, 
  text: string
}

type Node = {
  id: number,
  name: string, 
  currentStatus: NodeStatus, 
  timeline: Array<{date: string, status: NodeStatus}>
  children: Array<number>
}

type APIResponse = {
  nodes: Array<Node>,
  timeline: Array<TimelineItem>
}

type Graph = {
  [key: number]: {row: number, column: number, children: number[]}
}

@Component({
  selector: 'app-pipeline-timeline',
  templateUrl: './pipeline-timeline.component.html',
  styleUrls: ['./pipeline-timeline.component.scss']
})
export class PipelineTimelineComponent implements OnInit, AfterViewInit {

  @ViewChild('container') container!: ElementRef

  @Input() width: number = 1000
  @Input() height: number = 450

  showRect: boolean = true

  statusNodeRadius: number = 6
  statusCount: number = 25
  fontSize: number = 16
  lineHeight: number = 20

  statusGap: number = 3
  graphNodeGap: number = 6

  dateTimeline: {x: number, y:number} = {x: 0, y: 5}

  graphOffset: {x: number, y:number} = {x: 50, y: 100}
  statusOffset: {x: number, y:number} = {x: 0, y: 30}

  timelineSlots: Array<TimelineItem> = [
    {
      column: 0,
      text: 'Fab 1, 01:30'
    },
    {
      column: 5,
      text: 'Fab 1, 01:55'
    },
    {
      column: 10,
      text: 'Fab 2, 00:30'
    },
    {
      column: 15,
      text: 'Fab 3, 06:15'
    },
    {
      column: 20,
      text: 'Fab 4, 01:30'
    },
    {
      column: 25,
      text: 'Fab 4, 03:19'
    },
  ]

  edgeMap: {[key: number]: {row: number, column: number, children: Array<number>}} = {}

  graphEdges: Array<Node> = []

  constructor() { }

  ngAfterViewInit(): void {
    const container = this.container.nativeElement as HTMLDivElement
    const containerRect = container.getBoundingClientRect()
    setTimeout(() => {
      this.width = containerRect.width

      // width - statusArray - parentOffset - rightPadding
      this.statusOffset.x = this.width - ((this.statusCount+0) * (this.statusNodeRadius*2 + this.statusGap)) - this.graphOffset.x - 50

      this.init()
    })
  }

  ngOnInit(): void {
  }

  init() {
    const nodes:Array<Node> = [
      {
        id: 1,
        children: [2, 5],
        name: 'node_1',
        currentStatus: NodeStatus.SUCCESS,
        timeline: [
          {
            date: '3/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '2/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '1/2/2022',
            status: NodeStatus.SUCCESS
          }
        ]
      },
      {
        id: 2,
        children: [],
        name: 'node_2',
        currentStatus: NodeStatus.SUCCESS,
        timeline: [
          {
            date: '3/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '2/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '1/2/2022',
            status: NodeStatus.SUCCESS
          }
        ]
      },
      {
        id: 0,
        children: [1, 3],
        name: 'node_0',
        currentStatus: NodeStatus.SUCCESS,
        timeline: [
          {
            date: '3/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '2/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '1/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '3/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '2/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '1/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '3/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '2/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '1/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '3/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '2/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '1/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '3/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '2/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '1/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '3/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '2/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '1/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '3/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '2/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '1/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '3/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '2/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '1/2/2022',
            status: NodeStatus.SUCCESS
          }
          ,
          {
            date: '1/2/2022',
            status: NodeStatus.SUCCESS
          }
        ]
      },
      {
        id: 3,
        children: [4],
        name: 'node_3',
        currentStatus: NodeStatus.SUCCESS,
        timeline: [
          {
            date: '3/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '2/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '1/2/2022',
            status: NodeStatus.SUCCESS
          }
        ]
      },
      {
        id: 4,
        children: [],
        name: 'node_4',
        currentStatus: NodeStatus.FAILED,
        timeline: [
          {
            date: '3/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '2/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '1/2/2022',
            status: NodeStatus.FAILED
          }
        ]
      },
      {
        id: 5,
        children: [],
        name: 'node_5',
        currentStatus: NodeStatus.SUCCESS,
        timeline: [
          {
            date: '3/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '2/2/2022',
            status: NodeStatus.SUCCESS
          },
          {
            date: '1/2/2022',
            status: NodeStatus.SUCCESS
          }
        ]
      }
    ]
    const {nodes: sortedNodes, graph} = this.builsGraph(nodes)
    this.graphEdges = sortedNodes
    this.edgeMap = graph
  }

  private builsGraph(nodes:Array<Node>): {nodes: Array<Node>, graph: Graph} {
    let graph: Graph= {}

    let sortedNodes: Array<Node> = []
    
    // Sort nodes before building graph
    nodes = nodes.sort(function(a, b) {
      let nameA = a.name.toUpperCase()
      let nameB = b.name.toUpperCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    })


    let rootNodesMap: {[key: number]: boolean} = {}
    let nodesMap:{[key: number]: Node} = {}

    nodes.forEach(node => {
      if(!graph[node.id]) graph[node.id] = {row: 0, column: 0, children: node.children}

      if(rootNodesMap[node.id] === undefined) rootNodesMap[node.id] = true
      node.children.forEach(child => {rootNodesMap[child] = false})

      nodesMap[node.id] = node

    })

    let rootNodes = Object.entries(rootNodesMap).filter(([id, isRoot]) => isRoot).map(x => x[0]);
    // console.log(rootNodes)

    let visited: any = {}

    const build = (row: number, column: number, nodeId: number): number/**return last row */ => {
      let node = graph[nodeId];

      if(visited[nodeId]) return 0
      visited[nodeId] = true

      // console.log(nodeId, row, column)
      node.row = row
      node.column = column
      sortedNodes.push(nodesMap[nodeId])

      node.children.forEach(child => {
        row = build(row+1, column+1, child)
      })

      return row
    }

    let row = 0
    rootNodes.forEach(rootId => {
      row += build(row, 0, parseInt(rootId))
    })


    return {nodes: sortedNodes, graph}
  }

  getPath(parentId: string|number, childId: number): string {

    let parent = this.edgeMap[parentId as number]
    let child = this.edgeMap[childId]

    let x = (this.statusNodeRadius * 2 + this.graphNodeGap) * parent.column
    let y = this.lineHeight * parent.row
    let x_ = (this.statusNodeRadius * 2 + this.graphNodeGap) * child.column
    let y_ = this.lineHeight * child.row

    let dx = x_ - x
    let dy = y_ - y

    return `M ${x} ${y} q ${dx/3} ${dy} ${dx} ${dy}`
  }


}

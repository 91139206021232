<div >
  <simplify-templates-sidemenu (category)="onCategoryChange($event)" [sidemenuDetails]="sidemenuDetails" [sidemenuGlobal]="sidemenuGlobal"></simplify-templates-sidemenu>
</div>

<!-- <simplify-accordian>
    <simplify-accordian-item class="mb-2" slot="item">
      <simplify-accordian-header slot="header">
        <div class="template-accordian-header d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <simplify-icon name="layout-4-line" lib='remix' size='large' ></simplify-icon>
            <h3 class="mb-0 ms-2">Simplify's Template</h3>
          </div>
          <simplify-icon class="arrow-down-s" name="arrow-down-s-line" lib="remix" size="large" ></simplify-icon>
        </div>
      </simplify-accordian-header>
      <simplify-accordian-body slot="body" class="py-1">
        <div class="template-accordian-body p-2 ps-4 pe-0 pb-0">
          <div class="template-accordian-item d-flex align-items-center justify-content-between p-2 pe-1 mb-2">
            <p class="mb-0">HCM</p>
            <simplify-badge status="outline-sm" >5</simplify-badge>
          </div>
          <div class="template-accordian-item d-flex align-items-center justify-content-between p-2 pe-1 mb-2">
            <p class="mb-0">HCM</p>
            <simplify-badge status="outline-sm" >5</simplify-badge>
          </div>
          <div class="template-accordian-item d-flex align-items-center justify-content-between p-2 pe-1 mb-2">
            <p class="mb-0">HCM</p>
            <simplify-badge status="outline-sm" >5</simplify-badge>
          </div>
          <div class="template-accordian-item d-flex align-items-center justify-content-between p-2 pe-1 mb-2">
            <p class="mb-0">HCM</p>
            <simplify-badge status="outline-sm" >5</simplify-badge>
          </div>
          <div class="template-accordian-item d-flex align-items-center justify-content-between p-2 pe-1 mb-2">
            <p class="mb-0">HCM</p>
            <simplify-badge status="outline-sm" >5</simplify-badge>
          </div>
        </div> 
      </simplify-accordian-body>
  </simplify-accordian-item>
  <simplify-accordian-item class="mb-2" slot="item">
      <simplify-accordian-header slot="header">
        <div class="template-accordian-header d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <simplify-icon name="user-line" lib='remix' size='large' ></simplify-icon>
            <h3 class="mb-0 ms-2">My Templates</h3>
          </div>
          <simplify-icon class="arrow-down-s" name="arrow-down-s-line" lib="remix" size="large" ></simplify-icon>
        </div>
      </simplify-accordian-header>
      <simplify-accordian-body slot="body" class="py-2">
        <div class="template-accordian-body p-2 ps-4 pe-0">
          <div class="template-accordian-item d-flex align-items-center justify-content-between p-2 pe-1 mb-2">
            <p class="mb-0">HCM</p>
            <simplify-badge status="outline-sm" >5</simplify-badge>
          </div>
          <div class="template-accordian-item d-flex align-items-center justify-content-between p-2 pe-1 mb-2">
            <p class="mb-0">HCM</p>
            <simplify-badge status="outline-sm" >5</simplify-badge>
          </div>
          <div class="template-accordian-item d-flex align-items-center justify-content-between p-2 pe-1 mb-2">
            <p class="mb-0">HCM</p>
            <simplify-badge status="outline-sm" >5</simplify-badge>
          </div>
          <div class="template-accordian-item d-flex align-items-center justify-content-between p-2 pe-1 mb-2">
            <p class="mb-0">HCM</p>
            <simplify-badge status="outline-sm" >5</simplify-badge>
          </div>
          <div class="template-accordian-item d-flex align-items-center justify-content-between p-2 pe-1 mb-2">
            <p class="mb-0">HCM</p>
            <simplify-badge status="outline-sm" >5</simplify-badge>
          </div>
        </div> 
      </simplify-accordian-body>
  </simplify-accordian-item>
  <simplify-accordian-item class="mb-2" slot="item">
      <simplify-accordian-header slot="header">
        <div class="template-accordian-header d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <simplify-icon name="global-line" lib='remix' size='large' ></simplify-icon>
            <h3 class="mb-0 ms-2">Global</h3>
          </div>
          <simplify-icon class="arrow-down-s" name="arrow-down-s-line" lib="remix" size="large" ></simplify-icon>
        </div>
      </simplify-accordian-header>
      <simplify-accordian-body slot="body" class="py-2">
        <div class="template-accordian-body p-2 ps-4 pe-0">
          <div class="template-accordian-item d-flex align-items-center justify-content-between p-2 pe-1 mb-2">
            <p class="mb-0">HCM</p>
            <simplify-badge status="outline-sm" >5</simplify-badge>
          </div>
          <div class="template-accordian-item d-flex align-items-center justify-content-between p-2 pe-1 mb-2">
            <p class="mb-0">HCM</p>
            <simplify-badge status="outline-sm" >5</simplify-badge>
          </div>
          <div class="template-accordian-item d-flex align-items-center justify-content-between p-2 pe-1 mb-2">
            <p class="mb-0">HCM</p>
            <simplify-badge status="outline-sm" >5</simplify-badge>
          </div>
          <div class="template-accordian-item d-flex align-items-center justify-content-between p-2 pe-1 mb-2">
            <p class="mb-0">HCM</p>
            <simplify-badge status="outline-sm" >5</simplify-badge>
          </div>
          <div class="template-accordian-item d-flex align-items-center justify-content-between p-2 pe-1 mb-2">
            <p class="mb-0">HCM</p>
            <simplify-badge status="outline-sm" >5</simplify-badge>
          </div>
        </div> 
      </simplify-accordian-body>
  </simplify-accordian-item>  
</simplify-accordian> -->
<h6 mat-dialog-title>
    {{title}}
  </h6> 
  
  <div mat-dialog-content>
    <p>{{message}}</p>
  </div>
  
  <div mat-dialog-actions>
    <button style="background-color: #ff708d" mat-raised-button cdkFocusInitial (click)="onDismiss()">No</button>
    <button style="background-color:  #00d68f;" mat-raised-button (click)="onConfirm()">Yes</button>
  </div>
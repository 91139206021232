// // @ts-nocheck
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import * as CodeMirror from 'codemirror';
import 'codemirror/mode/xml/xml'
import {ConfigModel} from "../../models/config.model";
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/fold/xml-fold';
import { DashboardStoreService } from 'src/app/services/store/dasboard-store.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import 'codemirror/addon/scroll/simplescrollbars'
import 'codemirror/addon/display/autorefresh'



@Component({
  selector: 'app-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.css']
})
export class CodeEditorComponent implements OnInit {

  @ViewChild('textarea', { static: false }) textarea!: ElementRef;
  @Input() content: string = ''
  @Input() mode:'javascript'|'python'|'json' | 'xml'  = 'javascript'
  @Input() theme: string = 'default'
  @Input('width') editorWidth:string = '100%'
  @Input('height') editorHeight:string = '100%'
  @Input() editorConfig: CodeMirror.EditorConfiguration = {}

  codemirrorConfigs: CodeMirror.EditorConfiguration  = {
    lineNumbers: true, 
    theme: this.theme, 
    mode: this.mode, 
    autoRefresh:true
  }

  editor!: ReturnType<typeof CodeMirror>

  ngAfterViewInit() {
    if(this.mode==='json'){
      let jsonConfigs:CodeMirror.EditorConfiguration = {
        mode: {name: "javascript", json: true},
        lineNumbers: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        foldOptions: {
          widget: (from, to) => {
            var count = undefined;
    
            // Get open / close token
            var startToken = '{', endToken = '}';        
            var prevLine = this.editor.getLine(from.line);
            if (prevLine.lastIndexOf('[') > prevLine.lastIndexOf('{')) {
              startToken = '[', endToken = ']';
            }
    
            // Get json content
            var internal = this.editor.getRange(from, to);
            var toParse = startToken + internal + endToken;
    
            // Get key count
            try {
              var parsed = JSON.parse(toParse);
              count = Object.keys(parsed).length;
            } catch(e) { }        
    
            return count ? `\u21A4${count}\u21A6` : '\u2194';
          }
        }
      }
      this.editor = CodeMirror.fromTextArea(this.textarea.nativeElement, {...jsonConfigs})
    }
    else if(this.mode==='xml'){
      let xmlConfigs:CodeMirror.EditorConfiguration = {
        mode: "xml",
        lineNumbers: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
      }
      this.editor = CodeMirror.fromTextArea(this.textarea.nativeElement, {...xmlConfigs})
    }
    else if(this.mode==='python'){
      let pythonConfigs: CodeMirror.EditorConfiguration = {
        mode:'python',
        lineNumbers:true,
        theme:'default',
        gutters:["CodeMirror-lint-markers","CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        extraKeys: {
          "Ctrl-F": "findPersistent",
          //"Ctrl-Space": snippet
        },
        foldGutter: true,
        indentUnit:4
      }
      this.editor = CodeMirror.fromTextArea(this.textarea.nativeElement, {...pythonConfigs})
      this.editor.setOption("extraKeys", {
        Tab: function(cm) {
          var spaces = Array((cm.getOption("indentUnit")||4) + 1).join(" ");
          cm.replaceSelection(spaces);
        }
      });
    }
    else{
      this.editor = CodeMirror.fromTextArea(this.textarea.nativeElement, {...this.codemirrorConfigs, ...this.editorConfig})
    }
    this.editor.setValue(this.content || "")
    this.editor.setSize(this.editorWidth, this.editorHeight);
    const editor = this.editor

  }

  constructor() {
  }

  ngOnInit(): void {
  }

  


  public setValue(sourceCode: string) {
    if(this.editor) this.editor.setValue(sourceCode);
    this.content = sourceCode
  }


  public setEditorCss(cssStyle: Partial<CSSStyleDeclaration>) {
    if(!this.editor)return;
    const editorElement = this.editor.getWrapperElement();

    // set custom style
    Object.assign(editorElement.style, cssStyle);
  }

}

import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConfigService } from '../../services/api/config.service';


@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit {

  config = new FormControl();

  @Input()
  data:any;

  @Input()
  multiSelect:boolean=false;


  select_value:any;
  constructor(private configService: ConfigService) {


  	//fetch taskconfigurations
  	//getConfigurations();

  }

  ngOnInit(): void {

  }


}


 
<mat-tab-group [selectedIndex]="currentIndex" (selectedTabChange)="selectedTabChange($event)">

    <mat-tab  class="tabs" style="flex-grow:auto;display: flex;justify-content: space-between; background-color: rgb(223 223 223)" *ngFor="let node of nodes; let i=index">
        <ng-template mat-tab-label>
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <div class="col" style="text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 89px;
                padding: 18px;
                overflow: hidden;" nbTooltip="{{node.name}}" >{{node.name}}
                </div>
                <span class="close" style="font-size: x-large; padding: 1.5rem;" (click)="closeTab(i, $event)">&times;</span>
            </div>
            
        </ng-template>

            <ng-container *ngIf="selectedNode">
                <app-output
                    (filteredData)="filteredDataEvent($event)"
                    [defaultFilters]="defaultFilters[selectedNode.id] || {}"
                    [status]="selectedNode.status"
                    [taskData]="selectedNode.data"
                    [validation]="selectedNode.validation"
                    [api]="selectedNode.api_upload"
                    [taskId]="selectedNode.id"
                    [Conditional_upload]="selectedNode.isConditionalUpload||null"
                    [name]="selectedNode.name"
                    [events]="field_output$.asObservable()"
                    [field]="selectedField">
                    >
                </app-output>
            </ng-container>            
        
    </mat-tab>
</mat-tab-group>

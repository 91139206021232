import { Layout, Graph, Edge, Node as GraphNode, NodePosition } from "@swimlane/ngx-graph";
import { curveLinear } from "d3-shape";
import * as dagre from 'dagre';
import { Observable } from "rxjs";
import * as shape from 'd3-shape';
import { DagreSettings } from "@swimlane/ngx-graph";
import { DagEvents, DagState } from "./dag.component";
import { Link } from "./dag_type";
import { Point } from "src/app/services/api/node-position.service";
import {Node} from './dag_type'

export enum Orientation {
    LEFT_TO_RIGHT = 'LR',
    RIGHT_TO_LEFT = 'RL',
    TOP_TO_BOTTOM = 'TB',
    BOTTOM_TO_TOM = 'BT'
  }
export enum Alignment {
    CENTER = 'C',
    UP_LEFT = 'UL',
    UP_RIGHT = 'UR',
    DOWN_LEFT = 'DL',
    DOWN_RIGHT = 'DR'
}

export const getEdgePoints = (start: Point, end: Point): Array<Point> => {
  const path: Array<Point> = []

  const [left, right] = start.x <= end.x ? [start, end] : [end, start];
  start = left;
  end = right;

  const diff_x = 10


  // const midLeft: Point = {x: + diff_x + (left.x), y: left.y}
  // const midRight: Point = {x: - diff_x + (right.x), y: right.y}

  const midLeft: Point = {x: -10 + (left.x + right.x) / 2, y: left.y}
  const midRight: Point = {x: +10 + (left.x + right.x) / 2, y: right.y}

  path.push(midLeft)
  path.push(midRight)

  return [start, ...path, end]
}
  
export interface customSettings extends DagreSettings {
    curveDistance?: number;
    curve?: string | shape.CurveFactory;
  }
  
export class customDagreLayout1 implements Layout {
    defaultSettings: customSettings = {
      orientation: Orientation.LEFT_TO_RIGHT,
      marginX: 20,
      marginY: 20,
      edgePadding: 100,
      rankPadding: 100,
      nodePadding: 100,
      multigraph: true,
      compound: true,
      curveDistance: 20
    };
    settings: DagreSettings = {};
  
    dagreGraph: any;
    dagreNodes: any;
    dagreEdges: any;

    constructor(private nodePositionMap: {[nodeId: string]: {x: number, y: number}}, private dagState: DagState) {}
  
    run(graph: Graph): Graph {
      this.createDagreGraph(graph);
      dagre.layout(this.dagreGraph);
  
      graph.edgeLabels = this.dagreGraph._edgeLabels;
  
      for (const dagreNodeId in this.dagreGraph._nodes) {
        const dagreNode = this.dagreGraph._nodes[dagreNodeId];
        const node = graph.nodes.find(n => n.id === dagreNode.id);
        if(node != undefined){
          node.position = {
            x: dagreNode.x,
            y: dagreNode.y
          };

          if(this.nodePositionMap[node.id]) {
            node.position = {...this.nodePositionMap[node.id]}
          }

          this.nodePositionMap[node.id] = {...node.position}
        
          // if(localStorage.getItem(node.id)!=undefined){
          //     node.position = {
          //         x : JSON.parse(localStorage.getItem(node.id) || '{}').x,
          //         y : JSON.parse(localStorage.getItem(node.id) || '{}').y
          //     }
          //     //console.log(node);
          // }
      
          node.dimension = {
            width: dagreNode.width,
            height: dagreNode.height
          };
        }
      }

      //   console.log(this.dagreEdges);
      this.dagreEdges.forEach((edge:Edge) => {
        this.updateEdge(graph,edge);
      });

      //   this.createDagreGraph(graph);
  
      
      return graph;
    }
  
    updateEdge(graph: Graph, edge: Edge): Graph {
      const sourceNode = graph.nodes.find(n => n.id === edge.source)! as Node;
      const targetNode = graph.nodes.find(n => n.id === edge.target)! as Node;
      const weight = (edge as Link).weight


      const boxMargin = 5 // px
      const topOffset = 30 // px
      const bottomOffset = 30 // px
      const nodeWidth = 250 - 2 * boxMargin // px
      const connectorFieldHeight = 16 // px
      const connectorFieldGap = 4 // px


      const {sourceFieldId, targetFieldId} = (edge as Link).connector

      const sIdx = sourceNode.fields.findIndex(n => n.id === sourceFieldId)
      const tIdx = targetNode.fields.findIndex(n => n.id === targetFieldId)
      const sourceConnector = sourceNode.fields.find(n => n.id === sourceFieldId)!
      const targetConnector = targetNode.fields.find(n => n.id === targetFieldId)!

      const [sourceIndex, targetIndex] = [sIdx===-1 ? 0 : sIdx, tIdx === -1 ? 0 : tIdx]

  
      // determine new arrow position
      const dir = sourceNode?.position?.y as number <= (targetNode?.position?.y as number) ? -1 : 1;
      const dir_x = sourceNode?.position?.x as number <= (targetNode?.position?.x as number) ? -1 : 1;
      const equal_y = sourceNode?.position?.y == targetNode?.position?.y; 

      const startingPoint = {
        x: (sourceNode.position?.x || 0) - (sourceNode.dimension?.width || 0) / 2,
        y: (sourceNode.position?.y || 0) - (sourceNode.dimension?.height || 0) / 2,
      }

      startingPoint.y += boxMargin + topOffset + (connectorFieldHeight * (sourceIndex + 1)) + (connectorFieldGap * sourceIndex) - (connectorFieldHeight / 2)
      startingPoint.x += nodeWidth


      const endingPoint = {
        x: (targetNode.position?.x || 0) - (targetNode.dimension?.width || 0) / 2,
        y: (targetNode.position?.y || 0) - (targetNode.dimension?.height || 0) / 2,
      };


      endingPoint.y += boxMargin + topOffset + (connectorFieldHeight * (targetIndex + 1)) + (connectorFieldGap * targetIndex) - (connectorFieldHeight / 2)
      endingPoint.x += boxMargin + 4

      // generate new points
      // edge.points = [startingPoint, endingPoint];
      // edge.points = getEdgePoints(startingPoint, endingPoint)

      // return graph


      const Point1 = {
        // x: startingPoint.x + ((endingPoint.x-startingPoint.x)/3 > 20? 20:(endingPoint.x-startingPoint.x)/3) ,
        x: startingPoint.x + (Math.abs((endingPoint.x-startingPoint.x)/3) > 100? 100:Math.abs(endingPoint.x-startingPoint.x)/3) ,
        y: startingPoint.y - (equal_y ? 0:dir*1)
      };

      let Point2 = {
        x: Point1.x + 10,
        y: startingPoint.y - (equal_y ? 0:dir*5)
      };

      
      const Point4 = {
        x: endingPoint.x - (Math.abs(endingPoint.x-startingPoint.x)/3 > 50? 100:Math.abs(endingPoint.x-startingPoint.x)/3),
        y: endingPoint.y + (equal_y ? 0:dir*1)
      };

      let Point3 = {
        x: Point4.x - 10,
        y: endingPoint.y + (equal_y ? 0:dir*5)
      };
      

      edge.points = [startingPoint, Point1, Point4, endingPoint]
      // return graph
      
      //when source node is on left
      if(endingPoint.x < startingPoint.x){
       
        Point2 = {
          x: Point1.x,
          y: startingPoint.y - dir*(sourceNode?.dimension?.height as number)
        }

        Point3 = {
          x: Point4.x,
          y: endingPoint.y + dir*(targetNode?.dimension?.height as number)
        }


        if(Math.abs(startingPoint.y - endingPoint.y) < (sourceNode?.dimension?.height as number)/2){
          Point2.y = Point3.y; 
        }

        edge.points = [startingPoint, Point1, Point2, Point3, Point4, endingPoint]
      }
      //nodes are aligned horizantly
      else if(Math.abs(startingPoint.y - endingPoint.y) < (sourceNode?.dimension?.height as number)/2){
        edge.points = [startingPoint,endingPoint]
      }
      //nodes are aligned vertically
      

      return graph;
    }
  
    public createDagreGraph(graph: Graph): any {
      const settings = Object.assign({}, this.defaultSettings, this.settings);
      this.dagreGraph = new dagre.graphlib.Graph({ compound: settings.compound, multigraph: settings.multigraph });
      this.dagreGraph.setGraph({
        rankdir: settings.orientation,
        marginx: settings.marginX,
        marginy: settings.marginY,
        edgesep: settings.edgePadding,
        ranksep: settings.rankPadding,
        nodesep: settings.nodePadding,
        align: settings.align,
        acyclicer: settings.acyclicer,
        ranker: settings.ranker,
        multigraph: settings.multigraph,
        compound: settings.compound
      });
    
      // Default to assigning a new object as a label for each new edge.
      this.dagreGraph.setDefaultEdgeLabel(() => {
        return {
          /* empty */
        };
      });

  
      const boxMargin = 5 // px
      const topOffset = 30 // px
      const bottomOffset = 30 // px
      const nodeWidth = 250 - 2 * boxMargin // px
      const connectorFieldHeight = 16 // px
      const connectorFieldGap = 4 // px
      const nodeHeight = (topOffset + bottomOffset) + (connectorFieldHeight) - (2 * boxMargin)
      this.dagreNodes = graph.nodes.map((n:any) => {
        const node: any = Object.assign({}, n);
        node.width = nodeWidth // n.dimension.width;
        node.height = (nodeHeight) + (connectorFieldHeight * (n.fields||[]).length) + (connectorFieldGap * (n.fields||[]).length-1) // n.dimension.height;
        node.x = n.position.x;
        node.y = n.position.y;
        return node;
      });
    
      this.dagreEdges = graph.edges.map(l => {
        // let linkId: number = 1;
        // const newLink: any = Object.assign({}, l);
        // if (!newLink.id) {
        //   newLink.id = 'a' + linkId.toString();
        //   linkId++;
        // }
        // return newLink;
        return l
      });

      // console.log(graph)
    
      for (const node of this.dagreNodes) {
        if (!node.width) {
          node.width = 20;
        }
        if (!node.height) {
          node.height = 30;
        }
    
        // update dagre
        this.dagreGraph.setNode(node.id, node);
      }
    
      // update dagre
      for (const edge of this.dagreEdges) {
        if (settings.multigraph) {
          this.dagreGraph.setEdge(edge.source, edge.target, edge, edge.id);
        } else {
          this.dagreGraph.setEdge(edge.source, edge.target);
        }
      }
    
      return this.dagreGraph;
    }

    //drag properties
    public onDragStart(draggingNode: Node, $event: MouseEvent): void {}

    public onDrag(draggingNode: Node, $event: MouseEvent): void{
      if(draggingNode?.id) {
        this.dagState.next({type: DagEvents.MOUSE_MOVE, args: [$event, draggingNode.id]})
      }
    }

    public onDragEnd?(draggingNode: Node, $event: MouseEvent): void {
      if(draggingNode?.id) {
        this.dagState.next({type: DagEvents.MOUSE_UP, args: [$event, draggingNode.id]})
      }    
    } 

}
import { Component, OnInit,Inject, Type } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Nodev2Service } from 'src/app/services/api/nodev2.service';

type Node = {
  name: string,
  display_name: string,
  desc: string,
  icon: string,
  type: any,
  label: string
}

@Component({
  selector: 'app-selectnode',
  templateUrl: './selectnode.component.html',
  styleUrls: ['./selectnode.component.css']
})
export class SelectnodeComponent implements OnInit {

  constructor(private nodeService:Nodev2Service,@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<SelectnodeComponent>) { }
  nodes:Array<Node>=[];

  ngOnInit(): void {
    this.loadNodes();
  }

  async loadNodes(){
    let response:any = await this.nodeService.getNodes().toPromise(); 
    this.nodes = response.payload;
  }

  close(){
    this.dialogRef.close()
  }

  send(selectedNode:string){
    this.dialogRef.close(selectedNode)
  }
  
}

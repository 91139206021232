export {getTreeView} from './xml2json'

type NodeType = {
    $schema: string
    type?: string
    description?: string
    properties: {
        [key: string]: {
            type?: string
            $ref: string
            items?:Array<NodeType> | NodeType
            minLength?: number
            maxLength?: number
            pattern?: string
        } & NodeType
    },
    required: Array<string>
}


type VisitorObj = {
    visitorName: string
    visitor: (ode: NodeType, context: any, self: JsonSchemaParser) => any
}
export class JsonSchemaParser {
    private visitors: Map<string,(node: NodeType, context: any, self: JsonSchemaParser) => any> = new Map();

    constructor(){
        visitors.forEach(visitor => this.reigster(visitor))
    }


    currentSchema!:NodeType
    visit(node: NodeType, context: any, self?: JsonSchemaParser) {
        const visitor = this.visitors.get(node.type!);
        if (!visitor) {
        console.warn(`[schema] Visitor not found!`);
        return context;
        }

        const nextContext = visitor(node, context, self??this);
        return nextContext;
    }

    reigster(visitorObj: VisitorObj) {
        this.visitors.set(visitorObj.visitorName, visitorObj.visitor)
    }

    parseString(jsonSchemaString: string) {
        const schema = JSON.parse(jsonSchemaString)
        return [this.visit(schema, {})]
    }
}


function getPath(obj: any, arr: string[]): any {
    if(!obj)return obj;
    if(arr.length===0)return obj;
    let [first, ...rest] = arr
    return getPath(obj[first], rest)
}

export const visitors:Array<VisitorObj> = [
    {
        visitorName: "integer",
        visitor: (node, context={}, self) => {
            context.$type = "integer"
            if(node.description) context.$description = node.description
            return context
        }
    },
    {
        visitorName: "string",
        visitor: (node, context={}, self) => {
            context.$type = "string"
            if(node.description) context.$description = node.description
            return context
        }
    },
    {
        visitorName: "array",
        visitor: (node, context={}, self) => {
            context.$type = "array"
            if(node.description) context.$description = node.description
            return context
        }
    },
    {
        visitorName: 'object',
        visitor: (node, context={}, self) => {
            const required = node.required ?? [];
            if(node.$schema) self.currentSchema = node
    
            const elements: any = {}
            context.$type = 'object'
            if(node.description) context.$description = node.description
            context.elements = [elements]
    
            Object.keys(node.properties || {}).forEach(
                key => {
                    let value = node.properties[key]
                    elements[key] = {
                        $type: value.type??null,
                        $use: required.includes(key) ? 'required' : null,
                        $description: value.description
                    }
    
                    if(value.$ref) {
                        const ref = value.$ref
                        elements[key].$ref = ref
    
                        if(ref.startsWith('#')) {
                            let arr = ref.split('/')
                            arr.shift()
                            const obj = getPath(self.currentSchema, arr)
    
                            if(obj) {
                                self.visit(obj, elements[key], self)
                            }
                        }
    
                    }
    
                    if(value.type === 'object') {
                        const val = self.visit(value, {}, self)
                        elements[key].elements = [val]
                    }
    
                    if(value.type === 'array' && Array.isArray(value.items)) {
                        elements[key].elements = []
                        value.items.forEach(
                            item => {
                                let nextCtx = self.visit(item, {}, self)
                                elements[key].elements.push(nextCtx)
                            }
                        )
                    }
    
                    if(value.type === 'array' && !Array.isArray(value.items)) {
                        elements[key].$subtype = value.items?.type
                    }
                }
            )
    
            return context
        }
    }
]


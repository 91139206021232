export enum schema_data_type{
    string = "string",
    double = "double",
    integer = "integer",
    integer_with_na = "integer_with_na",
    boolean = "boolean",
    date = "date",
    date_ns = "date_ns"
  }

export enum schema_actions{
    delete="delete"
}

export type schema_key = {
    columnName: string,
    dType: schema_data_type,
    isMandatory: boolean,
    order: number,
    id?:number,
    action?:schema_actions
}

export type schema_sheet = {
    id?:number,
    sheetName: string, 
    matchingType: string,
    schema: Array<schema_key>,
    action?:schema_actions
}

export type schema_configs = {
    schemaDetails: Array<schema_sheet>
}

//schema response for api

export type schema_response = Array<{
    created_time?: any
    id: number
    match_type: string
    name: string
    schema: Array<{
        data_type: string
        id: number
        is_mandatory: boolean
        key_name: string
        order: number
    }>
    task_id: number
    update_time?:any
}>
import { Injectable } from '@angular/core';
import { Context } from '../config/context.service';

const noop = () => {}

@Injectable({
  providedIn: 'root'
})
export class ConsoleLoggerService  {

  readonly isDebugMode: boolean

  constructor(private context: Context) {
    this.isDebugMode = !context.environment.production
  }

  get log(): typeof console.log {
    if (this.isDebugMode) {
      return console.log.bind(console);
    } else {
      return noop;
    }
  }

  get info(): typeof console.info {
    if (this.isDebugMode) {
      return console.info.bind(console);
    } else {
      return noop;
    }
  }

  get warn(): typeof console.warn {
    if (this.isDebugMode) {
      return console.warn.bind(console);
    } else {
      return noop;
    }
  }

  get error():typeof console.error {
    if (this.isDebugMode) {
      return console.error.bind(console);
    } else {
      return noop;
    }
  }

}

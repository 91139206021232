import {Component, OnInit} from '@angular/core';
import {NbMenuItem, NbMenuService, NbSidebarService} from "@nebular/theme";
import {UIService} from "./services/config/ui.service";
import {Context} from "./services/config/context.service";
import {ActivatedRoute, NavigationEnd, Router, RoutesRecognized} from "@angular/router";
import {Title} from "@angular/platform-browser";
import { environment } from './../environments/environment';
import { ConsoleLoggerService } from './services/logger/console-logger.service';
import { SideNavService } from './services/core/sidenav.service';
import { BehaviorSubject } from 'rxjs';
import { Groupv2Service } from './services/api/v2/groupv2.service';
import { DirectoriesStoreService } from './services/store/directories-store.service';
import { AuthService } from './services/api/auth.service';
import { Accountv2Service } from './services/api/v2/accountv2.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  defaultUrl = '/pipelines(view:pipeline-list)';
  roles = ""
  allAccounts:Array<any> = []
  accountDetails:any = {
    label:'',
    type:'platform',
    icon: '/assets/images/logo-icon.png'
  }

  layoutType = new BehaviorSubject<'old'|'withSidebar'|'fullScreen'>('old')

  title = 'simplify-bridge';
  showSubHeader = true;
  moreMenu:NbMenuItem[]=[
    { title: 'Custom Node' , link:'/more/custom-node',icon:'settings-2-outline'},
    { title: 'Data Models' , link:'/more/data-models',icon:'layout-outline'},
    { title: 'Audit Logs' , link:'/more/audit-logs',icon:'file-text-outline'},
  ];
  headerRightUserMenu: NbMenuItem[] = [
    {
      title: 'Profile',
      icon: 'settings-2-outline',
    },
    {
      title: 'Logout',
      // icon: 'activity-outline',
    },
  ]
  headerRightMenu: NbMenuItem[] = [
    {
      title: 'Account',
      icon: 'person-outline',
    },
    {
      title: 'Contract',
      icon: 'activity-outline',
    },
    {
      title: 'Portfolio',
      icon: 'activity-outline',
    },
    {
      title: 'Manage Users',
      icon: 'activity-outline',
    },
    {
      title: 'Connect',
      icon: 'activity-outline',
    },
    {
      title: 'Settings',
      icon: 'activity-outline',
    },
  ]

  sideNevMenu: (NbMenuItem&{icon: string, hidden?: boolean})[] = [
    {
      title: 'Create Pipe',
      icon: 'activity-outline',
      link: "/create-pipeline",
    },
    {
      title: 'View Pipe',
      icon: 'layers-outline',
      link: "/view-pipeline",
    },
    {
      title: 'View Group',
      icon: 'folder-outline',
      link: "/view-group",
    },
    {
      title: 'Connections',
      icon: 'layers-outline',
      link: "/connections",
    }

  ];

  constructor(public sideNavService:SideNavService,private menuService: NbMenuService, public uiService: UIService, public context: Context, private router: Router, public route: ActivatedRoute, private titleService: Title, private logger:ConsoleLoggerService,private groupService:Groupv2Service,private authService:AuthService,private accountsService:Accountv2Service) {
    console.log(environment, context.principle)
      this.router.events.subscribe(event => {
      
      if(event instanceof NavigationEnd) {
        if(this.route.root.firstChild?.snapshot.data.v2){
          this.layoutType.next(this.route.root.firstChild?.snapshot.data.fullScreen || 'withSidebar')
        }
        

        let title = this.route.root.firstChild?.snapshot.data.title || 'Simplify'
        titleService.setTitle(title)

        // TODO: hide nav-links from login screen (move nave links to UI service), login path should come from router configs
        this.sideNevMenu = this.sideNevMenu.map(x => ({...x, hidden: this.route.root.firstChild?.snapshot.routeConfig?.path === 'login'}))
      }
    })
  }
  currentRoute:string = ''
  ngOnInit() {
    //temporary solution need to chnage with activated route
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let route = event.url.split('?')[0];
          this.currentRoute = route
          console.log(this.currentRoute)
        
      }
    });
    
    this.context.on('UIService*', (...args: Array<unknown>) => {
      this.logger.log(args)
    })

    this.context.on('principle*', (...args: Array<unknown>) => {
      this.logger.log(args)
    })

    this.context.currentUserGroup.subscribe(
      userGroup => this._userGroup = userGroup
    )

    //accout switch
    // this.accountsService.getAccounts({params:{}}).subscribe((_data:any)=>{
    //   this.allAccounts = _data.payload.data
    // })
     this.authService.accountId$.subscribe(accountId=>{
      if(accountId){
        this.accountsService.getUserInfoV2().subscribe((_data:any)=>{
          this.roles = _data.payload.accounts[accountId]?.role?.role || ""
          console.log('roles',this.roles)
          let myArray:Array<any> = []
          Object.values(_data.payload.accounts).forEach((account:any)=>{
            myArray.push({id:account.id,displayName:account.display_name,type:account.type})
          })
          this.allAccounts = [...myArray]
        })
    
        this.accountsService.getAccountDetails(accountId).subscribe((data:any)=>{
          this.accountDetails.label = data.payload.displayName;
          this.accountDetails.type = data.payload.type;
          this.accountDetails.icon = '/assets/images/logo-icon.png'
          this.accountDetails = {...this.accountDetails}
        })
      }
      
    })

  }
  switchAccount(event:any){
    // console.log(event.detail.accountId)
    this.authService.saveAccountId(event.detail.accountId)
    window.location.href = this.defaultUrl
  }

  ngAfterContentInit() {
    this.menuService.onItemClick().subscribe((event) => {
      this.onPageChange(event.item)      
    });
  }

  isActive(item: NbMenuItem) {
    return this.router.url.startsWith(item.link||'')
  }

  private _userGroup: {id: string, name:string} | null = this.context.currentUserGroup.getValue()
  set userGroup(value: {id: string, name: string}|null) {
    this.context.setUserGroup(value as any)
  }
  get userGroup() {
    return this._userGroup
  }

  onPageChange(item: NbMenuItem) {

    switch (item.title) {
    case 'Logout':
      this.context.setCurrentUser(undefined);
      this.router.navigate(['/login'])
      break

    default:
      if(item.link) {
        this.router.navigate([item.link])
      }
    }
  }

  customLogout(e:any){
    // this.context.setCurrentUser(undefined);
    this.authService.logout()
    // this.router.navigate(['/login'])
    this.router.navigateByUrl('/login')
  }
  switchToRoute(path:string){
    this.router.navigateByUrl(path)
  }

}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  HostListener,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { GroupService } from 'src/app/services/api/group.service';
import { NbContextMenuDirective, NbDialogService, NbMenuService } from '@nebular/theme';
import { CreateGroupComponent } from '../create-group/create-group.component';
import { filter, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { PipelineService } from 'src/app/services/api/pipeline.service';
import { group } from 'console';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-view-group',
  templateUrl: './view-group.component.html',
  styleUrls: ['./view-group.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewGroupComponent implements OnInit, OnDestroy {
  @ViewChild(NbContextMenuDirective) contextMenu:NbContextMenuDirective | undefined;
  viewMode:string = '';
  groups$:any;
  groupId:number = 0;
  searchText = '';
  default_group: any = {
    id: '',
    name: 'Default',
    email: '',
    icon: '',
    comments: '',
    createdBy: ''
  };



  context_items = [
    {title:"delete"},
    {title:"open"},
    {title:"paste"}
  ];
  more_items = [
    {title:"paste"}
  ];
  menu:Subscription;
  constructor(
    private group_service: GroupService,
    private pipeline_service: PipelineService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private dialogService: MatDialog,
    private nbMenuService: NbMenuService,
    private cdr: ChangeDetectorRef
  ) {
    this.menu = this.nbMenuService.onItemClick()
    .pipe(
      filter(({ tag }) => tag === 'my-context-menu'),
      map(({ item: { title } }) => title),
    )
    .subscribe((title: string)=>{
        this.selectOption(title);
    });
  }

  ngOnInit(): void {
    this.getGroups();
    this.viewMode='grid';

  }

  ngOnDestroy(): void{
    this.menu.unsubscribe();
  }

  selectOption(title: string){
    if(title=='paste')
          this.addPipeline(this.groupId);
        else if(title=='open'){
          this.openFolder(this.groupId);
        }
        else if(title=='delete'){

        }
  }

  async getGroups() {
    this.groups$ = await this.group_service.listGroups().toPromise();
    this.cdr.detectChanges()
  }

  updateViewModeValue(value: any): void {
    this.viewMode = value;
    this.cd.markForCheck();
  }

  openFolder(group_id: any) {
    this.router.navigate(['/view-pipeline/group/', group_id]);
  }

  // deleteGroup(id: any) {
  // 	this.group_service.delete(id).subscribe((result: any) => {
  // 		console.warn();
  // 	});
  // 	window.location.reload();
  // 	this.toastrService.show("Warning", `Pipeline deleted`, {status, position: NbGlobalPhysicalPosition.BOTTOM_RIGHT});
  // }
  openContextMenu(event: any) {
    alert('right click');
  }

  addPipeline(groupId: number) {
    var pipelineId: number = parseInt(localStorage.getItem('pipelineId')!);

    if(pipelineId){
        //get pipelines in group

        this.pipeline_service.getList({params: {}}).subscribe((result: any) => {
          console.log(result);
          var pipelines = result.payload.filter((pipeline:any) => pipeline.group_id == groupId);
          var pipelineIds:any = pipelines.map((x: any) => x.id);
          console.log(pipelineIds);

        if(!pipelineIds.includes(pipelineId)) { // FIXME: should be validated  from backend
          pipelineIds.push(pipelineId);
          this.group_service.addToGroup({
          groupId: groupId,
          pipelineIds: pipelineIds
        }).subscribe((response:any) => {
          console.log(response);
        });
      }
        else{
          alert("already in group");
        }
        console.log(pipelineIds);
        localStorage.removeItem('pipelineId');
        });

    }
  }



  createGroup(){
    this.dialogService.open(CreateGroupComponent, {panelClass: 'mat-dialog-container'}).afterClosed().subscribe(()=>{
      this.getGroups();

    });
  }

  editGroup(group:any){
    console.log(group)
     this.dialogService.open(CreateGroupComponent, {data: {group}}).afterClosed().subscribe(()=>{
      this.getGroups();
    });
  }

  open(){
    this.contextMenu?.show();
    return false;
  }

  @HostListener('document:click')
  close() {
    this.contextMenu?.hide();
  }


}

<div class="drag">
    <ng-container *ngFor="let item of items;let i=index">

    <div (drop)="falseDrop($event)" (dragleave)="dragOut($event)" (dragover)="allowDrop($event)" class="false-drop" [id]="'_'+i+'_'+item"></div>
    <div class="dragbox" draggable="true" (dragstart)="drag($event)" [id]="i" (drop)="drop($event)" (dragover)="allowDrop($event)">
      {{item}}
      <span (click)="remove(i)" style="color: red;">x</span>
    </div>

  </ng-container >
  <div (drop)="falseDrop($event)" (dragleave)="dragOut($event)" (dragover)="allowDrop($event)" class="false-drop" [id]="'_'+items.length"></div>

</div>
<div>
  <input nbInput type="text" (keyup.enter)="addItem($event)">
</div>
  
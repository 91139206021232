import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import cronstrue from 'cronstrue';
import { DashboardStoreService } from '../../services/store/dasboard-store.service'
import { PipelineService } from '../../services/api/pipeline.service'
import * as cronValidator from 'cron-expression-validator'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-schedule',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']

})
export class ScheduleComponent implements OnInit {


  date: Date = new Date()
  _text:string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public dialogRef: MatDialogRef<ScheduleComponent>,
    private pipeline: PipelineService,
    private dashboard: DashboardStoreService
  ) {
    // this.getSchedule()
    console.log(dialogParams)
    if(dialogParams.startTime) {
      this.date.setTime(dialogParams.startTime*1000)
    }
    this._text =  dialogParams.cronExpression
  }


  convertedExp: any
  isValidExp: boolean = true

  get text() {
    return this._text
  }

  set text(value:string){
    this._text = value
    //  const isValid = cronValidator.isValidCronExpression("* * * * * ? *", {error: true});
    //  console.log(isValid)

    try {
      cronstrue.toString(this._text)
      this.isValidExp = true
    } catch (error) {
      this.isValidExp = false
    }
  }

  get human() {
    let hr = ''
    try {
      hr = cronstrue.toString(this._text)
    } catch (error) {}
    return hr
  }

   pipelineId = this.dashboard.getCurrentPipeline()?.id

  ngOnInit(): void {
  }

  addSchedule() {
    this.pipeline.schedulePipepline({
      "pipelineId": this.pipelineId,
      "startTime": (this.date?.getTime() || 0) / 1000,
      "cronExpression": this._text
    }).subscribe((res: any) => {
      console.log(res)
    })
  }

  close() {
    this.dialogRef.close();
  }

}

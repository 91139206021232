import { Component, Input } from "@angular/core";
import { ClassicPreset } from "rete";

export class NodeSettingsIconControl extends ClassicPreset.Control {
  constructor(public label: string, public onClick: () => void, public iconName: string) {
    super();
  }
}

@Component({
  selector: "app-node-settings",
  template: `
    <nb-icon 
      nz-button
      (pointerdown)="$event.stopPropagation()"
      (dblclick)="$event.stopPropagation()"
      (click)="data.onClick()"
    [icon]="data.iconName">
      {{ data.label }}
    </nb-icon>
  `
})
export class NodeSettingsIconComponent {
  @Input() data!: NodeSettingsIconControl;
}


import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent, } from '@angular/material/tabs/tab-group';
import { Observable,Subject } from 'rxjs';
import { NodeInputField } from 'src/app/components/dag/dag_type';
import { FilterType } from '../../output-table/output-table.component';

export type NodeData = {
  id: number
  name: string
  isConditionalUpload: boolean | null
  status: string
  validation: string
  api_upload: {
    failed_rows: number
    success_rows: number
  }
  //...

  
  data: Array<{
    sheet_name: string
    download_url: string
    filetype: string
    cols: Array<{
      field: string
      headerName: string
      id: number
      type: string
    }>
    data: Array<{[col: string]: undefined}>
  }>
  field:NodeInputField
}

@Component({
  selector: 'app-nodes-output',
  templateUrl: './nodes-output.component.html',
  styleUrls: ['./nodes-output.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class NodesOutputComponent implements OnInit, AfterViewInit, OnDestroy {

  field_output$ = new Subject<{type: 'TAB_CHANGE', args: string }>();
  nodes: Array<NodeData> = []
  selectedNode?: NodeData
  currentIndex: number = 0
  selectedField:string = ''
  // @Output() close = new EventEmitter()
  @Input()
  events = new Observable<{type: 'ADD_NODE', args: Array<unknown> }>();

  // @Input() 
  // set outputNodes(value: Array<NodeData>) {
  //   this.nodes = value
  //   this.nodes = this.nodes.filter((x,y)=>this.nodes.findIndex(obj=>obj.id===x.id)===y)
  //   console.log(this.nodes)
  //   this.selectedNode = value[value.length-1]
  //   this.currentIndex = value.length-1
  // }
  // get outputNodes() {
  //   return this.nodes
  // }


  ngOnInit(): void {

    this.events.subscribe(event=>{
      if(event.type==='ADD_NODE')
      { 
        const node=event.args[0] as NodeData
        const idx = this.nodes.findIndex(obj=>obj.id===node.id);
        if(idx < 0){
          this.nodes.push(event.args[0] as NodeData)
        }

        else{
          this.nodes[idx]=node
          
        }
        this.selectedField = node.field.id
        this.nodes = [...this.nodes]
      }
    })
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
  }


  selectedTabChange(event: MatTabChangeEvent) {
    if(event.index === -1)
    {
      return
    }
    this.currentIndex = event.index
    this.selectedNode = this.nodes[this.currentIndex]
    console.log(event)
  }

  // closeSelectedNode() {
  //   this.selectedNode = this.nodes[(this.currentIndex + 1) % this.nodes.length]
  //   this.nodes.splice(this.currentIndex)
  //   this.currentIndex = (this.currentIndex + 1) % this.nodes.length

    // if(this.outputNodes.length === 0) {
    //   this.close.emit(false)
    // }
  // }

  closeTab(index:number, event:MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    const node = this.nodes[index];
    this.nodes.splice(index, 1)
    if(index===this.currentIndex)
    {
      this.currentIndex= (this.currentIndex) % this.nodes.length
    }
    console.log(this.currentIndex)

    delete(this.defaultFilters[node.id])

  }

  
  defaultFilters:{[nodeId:number]:{[sheet_name:string]:{[input_field:string]:FilterType}}} = {};
  filteredDataEvent(event:{event:any,sheet_name:string}){
    this.defaultFilters[this.selectedNode!.id]={...this.defaultFilters[this.selectedNode!.id] || {} , [event.sheet_name]:event.event};
  }
} 

import { Injector } from "@angular/core";
import { NodeEditor, GetSchemes, ClassicPreset } from "rete";
import { AreaPlugin, AreaExtensions, Area2D } from "rete-area-plugin";
import {ConnectionPlugin, Presets as ConnectionPresets} from "rete-connection-plugin";
import { AngularPlugin, Presets, AngularArea2D } from "rete-angular-plugin/12";
import { CustomSocketComponent } from "./custom-socket/custom-socket.component";
import { CustomNode, CustomReteNodeComponent } from "./custom-nodes/default-node/custom-node.component";
import { CustomConnectionComponent } from "./custom-connection/custom-connection.component";
import { addCustomBackground } from "./custom-background";

import {
  AutoArrangePlugin,
  Presets as ArrangePresets,
  ArrangeAppliers
} from "rete-auto-arrange-plugin";
import { MinimapExtra, MinimapPlugin } from "rete-minimap-plugin";
import { CustomControlComponent } from "./custom-controller/custom-control.component";
import { DashboardStoreService } from "../services/store/dasboard-store.service";
import { ButtonComponent, ButtonControl } from "./custom-controller/custom-button.component";
import { NodeSettingsIconComponent, NodeSettingsIconControl } from "./custom-controller/settings-icon.component";
import { IfNode, ReteIfNodeComponent } from "./custom-nodes/if-node/if-node.component";
import { LoopNode, ReteLoopNodeComponent } from "./custom-nodes/loop-node/loop-node.component";
import { ConnectionPathPlugin, Transformers } from "rete-connection-path-plugin";
import { curveStep, curveMonotoneX, curveLinear, CurveFactory, curveBumpX } from "d3-shape";


export type Schemes = GetSchemes<
  ClassicPreset.Node,
  ClassicPreset.Connection<ClassicPreset.Node, ClassicPreset.Node>
>;
type AreaExtra = AngularArea2D<Schemes>;

export const NodeTypeMap = new Map<string, typeof CustomNode>()
NodeTypeMap.set('', CustomNode)
NodeTypeMap.set('if', IfNode)
NodeTypeMap.set('loop', LoopNode)
NodeTypeMap.set('azure_blob_storage', CustomNode)
NodeTypeMap.set('mail', CustomNode)

export async function createEditor(container: HTMLElement, injector: Injector, dashboardService: DashboardStoreService) {

  const editor = new NodeEditor<Schemes>();
  const area = new AreaPlugin<Schemes, AreaExtra>(container);
  const connection = new ConnectionPlugin<Schemes, AreaExtra>();
  const render = new AngularPlugin<Schemes, AreaExtra>({ injector });

  const arrange = new AutoArrangePlugin();

  // @ts-ignore
  AreaExtensions.selectableNodes(area, AreaExtensions.selector(), {
    accumulating: AreaExtensions.accumulateOnCtrl()
  });

  // @ts-ignore
  render.addPreset(
    Presets.classic.setup({
      customize: {
        node(data) {
          if(data.payload instanceof IfNode)return ReteIfNodeComponent
          if(data.payload instanceof LoopNode)return ReteLoopNodeComponent
          if(data.payload instanceof CustomNode)return CustomReteNodeComponent
          return null;
        },
        connection() {
          return CustomConnectionComponent;
        },
        socket() {
          return CustomSocketComponent;
        },
        control(data) {
          if (data.payload instanceof ButtonControl) return  ButtonComponent 
          if (data.payload instanceof NodeSettingsIconControl) return  NodeSettingsIconComponent 
          return CustomControlComponent
        }
      }
    })
  );

  
  const pathPlugin = new ConnectionPathPlugin<Schemes, Area2D<Schemes>>({
    curve: (c:any) => c.curve || curveBumpX,
    // transformer: () => Transformers.classic({ vertical: false }),
    // arrow: () => true
  });

  // @ts-ignore
  render.use(pathPlugin);


  connection.addPreset(ConnectionPresets.classic.setup());
  const applier = new ArrangeAppliers.TransitionApplier({
    duration: 500,
    timingFunction: (t) => t,
    async onTick() {
      await AreaExtensions.zoomAt(area, editor.getNodes());
    }
  });
  
  const minimap = new MinimapPlugin({
    boundViewport: true
  });

  arrange.addPreset(ArrangePresets.classic.setup());

  // @ts-ignore
  editor.use(area);
  // @ts-ignore
  area.use(connection);
  // @ts-ignore
  area.use(render);
  //@ts-ignore
  area.use(minimap);
  //@ts-ignore
  area.use(arrange);



  // @ts-ignore
  render.addPreset(Presets.minimap.setup({ size: 100 }));

  AreaExtensions.simpleNodesOrder(area);
  addCustomBackground(area);

  // @ts-ignore
  await arrange.layout({ applier, nodes: editor.getNodes() });
  AreaExtensions.zoomAt(area, editor.getNodes());

  return {
    layout: async (animate: boolean) => {
      // @ts-ignore
      await arrange.layout({ applier: animate ? applier : undefined });
      AreaExtensions.zoomAt(area, editor.getNodes());
    },
    destroy: () => area.destroy(),
    editor
  };
}

// // @ts-nocheck
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import * as CodeMirror from 'codemirror';
import {ConfigModel} from "../../models/config.model";

import { DashboardStoreService } from 'src/app/services/store/dasboard-store.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import 'codemirror/mode/meta'
import 'codemirror/addon/edit/closebrackets'
import { Observable } from 'rxjs';
import { NbDialogService } from '@nebular/theme';
import { Nodev2Service } from 'src/app/services/api/nodev2.service';
enum Theme {
  light='default',
  dark='dracula'
}

export enum EventTypes {
  INIT = 'INIT',
  RESIZE = 'RESIZE',
  SAVE = 'SAVE',
  CLOSE = 'CLOSE',
  TEXT_CHANGE = 'TEXT_CHANGE',
  RUN_CODE = 'RUN_CODE'
}

const pythonHintFactory = (codemirror:CodeEditorOptionsComponent) =>{
  const pythonHint:CodeMirror.AsyncHintFunction = async function(editor: ReturnType<typeof CodeMirror>,cb:any):Promise<any>{
    const cursor = editor.getCursor()
    const token = editor.getTokenAt(cursor)
    const start: number = token.start
    const end: number = cursor.ch
    const line: number = cursor.line
    const currentWord: string = token.string
    //console.log(currentWord)
    let list: Array<any>;
    // const response = await fetch('http://localhost:5000/autocomplete', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({ code: editor.getValue(), row: line+1,col:end })
    // });
    // const data:{suggestions:Array<any>} = await response.json();

    const response:any= await codemirror.nodeService.pythonAutocomplete(codemirror.currentFile,editor.getValue(),line+1,end).toPromise();
    
    list = response.payload.suggestions
    //console.log(data.suggestions)
    //list = data.suggestions;
    if(token.string==='.')
    {
      cb(
        {
          list: list,
          from: CodeMirror.Pos(line, start+1),
          to: CodeMirror.Pos(line, end)
        }
      )
    }
      else{
      cb(
        {
          list: list,
          from: CodeMirror.Pos(line, start),
          to: CodeMirror.Pos(line, end)
        }
      )
    }
    
    
  }
  pythonHint.async = true;
  return pythonHint;
}

const pythonLintFactory = (codemirror:CodeEditorOptionsComponent) =>{
  const pythonValidator = (value:string, lintAsync:any, options:any, cm:any) =>{
    codemirror.nodeService.pythonLinter(codemirror.currentFile,value).subscribe((messages:any)=>{
      var result = [];
      for (var i in messages) {
          var message = messages[i];
          var severity = 'warning';
          if (message.type === 'error' || message.type === 'fatal') {
              severity = 'error';
          }

          result.push({message: message.message,
                      severity:severity,
                      from: CodeMirror.Pos(message.line - 1, message.column),
                      to: CodeMirror.Pos(message.line - 1, message.column)});
      }

      lintAsync(cm, result);
    })
    
    // let user = JSON.parse(localStorage.getItem('principle.user')||'{}')
    // const Authorization = `Bearer ${user.token}`;
    // var xmlhttp = new XMLHttpRequest();
    // xmlhttp.open("POST", "http://127.0.0.1:5000/lint", true);
    // xmlhttp.setRequestHeader("Content-type","application/x-www-form-urlencoded");
    // xmlhttp.setRequestHeader("Authorization",Authorization);
    // xmlhttp.onreadystatechange = function() {
    //     if (xmlhttp.readyState == 4) {
    //         if(xmlhttp.status == 200) {
    //             var result = [];
    //             var messages = JSON.parse(xmlhttp.responseText);
    //             for (var i in messages) {
    //                 var message = messages[i];
    //                 var severity = 'warning';
    //                 if (message.type === 'error' || message.type === 'fatal') {
    //                     severity = 'error';
    //                 }

    //                 result.push({message: message.message,
    //                             severity:severity,
    //                             from: CodeMirror.Pos(message.line - 1, message.column),
    //                             to: CodeMirror.Pos(message.line - 1, message.column)});
    //             }

    //             lintAsync(cm, result);
    //         }
    //     }
    // };

    // xmlhttp.send("code=" + encodeURIComponent(value));
  }
  return pythonValidator;
}

let mySnippets: Array<{text: string, displayText: string}> = [
  { text: `@app.route('/hello', methods=['GET'])
  def helloworld():
      if(request.method == 'GET'):
          data = {"data": "Hello World"}
          return jsonify(data)
    
    
  if __name__ == '__main__':
      app.run(debug=True)`, displayText: 'Flask hello world' },
  { text: 'data = getNodeData(nodes.FILE_001)\ndata.', displayText: 'Get node data' },
  { text: 'configs = fetchConfigValue()\nconfigs.', displayText: 'Fetch config value' },
  { text: 'configs = fetchConfigValue()\ncolumnName = configs.ColumnName', displayText: 'ColumnName' },
]

const findWords = function (editor: ReturnType<typeof CodeMirror>): any {
  const cursor = editor.getCursor()
  const token = editor.getTokenAt(cursor)
  const start: number = token.start
  const end: number = cursor.ch
  const line: number = cursor.line
  const currentWord: string = token.string
  let list: any[] = [];

  // mySnippets = mySnippets.map(snippet =>{
  //   return {...snippet,
  //     render: function (elt:HTMLLIElement, data:any, cur:any) {
  //       elt.textContent = snippet.displayText
  //       var contentElement:HTMLElement = document.createElement('div');
  //       contentElement.textContent = snippet.text;
  //       contentElement.style.display = 'none'
  //       contentElement.style.position = 'absolute'

  //       //elt.appendChild(contentElement);

  //       elt.addEventListener('mouseover', function () {
  //         // contentElement.style.display = 'block';
  //         elt.parentElement?.insertAdjacentHTML('afterend','<div>Yep</div>')
  //         console.log('mouseover',elt.parentElement)
          
  //       });

  //       elt.addEventListener('mouseout', function () {
  //         // contentElement.style.display = 'none';
  //         contentElement.style.display = 'none'
  //       });
  //     },
  //     hint: function (cm:any, data:any, completion:any) {
  //       // Replace the current token with the snippet code
  //       cm.replaceRange(snippet.text, data.from, data.to);
  //     }

  //   }
  // })

  // console.log(mySnippets)

  return {
    list: mySnippets,
    from: CodeMirror.Pos(line, start),
    to: CodeMirror.Pos(line, end)
  }
}

function snippets(editor: ReturnType<typeof CodeMirror>): void {
  editor.showHint({
    hint: function() {
      return findWords(editor)
    },
    completeSingle: false 
  })
}

@Component({
  selector: 'app-code-editor-options',
  templateUrl: './code-editor-options.component.html',
  styleUrls: ['./code-editor-options.component.css']
})
export class CodeEditorOptionsComponent implements OnInit, AfterViewInit {
  keyboardShortcuts:Array<{key:string,text:string}>=[
    {key:'ctrl + f',text:'Code-editor: Search'},
    {key:'ctrl + s',text:'Code-editor: Save File'},
    //{key:'ctrl + ⇧ + f',text:'Code-editor: Replace'},
    {key:'ctrl + space',text:'Code-editor: AutoComplete'},
    {key:'ctrl + r',text:'Code-editor: Check Errors'},
  ]
  //todo make fileBuffers as array
  fileBuffers:{[key: string]: any} = {}
  currentFile:any
  tabChange(fileName:string){
    this.selectBuffer(fileName)
    this.currentFile=fileName
  }
  closeFile(fileName:string){
    delete this.fileBuffers[fileName]
    let lastKey = Object.keys(this.fileBuffers).pop()?.toString()
    console.log(lastKey)
    this.currentFile = lastKey;
    if(lastKey)
      this.selectBuffer(lastKey)
    else{
      this.editor.setValue("")
    }
  }
  loadBuffer(fileName:string,fileMode:string | CodeMirror.ModeSpec<CodeMirror.ModeSpecOptions> | undefined, content:string){
    //create buffer
    this.fileBuffers[fileName] = CodeMirror.Doc(content,fileMode)
  }
  selectBuffer(fileName:string){
      let buf = this.fileBuffers[fileName];
      if (buf.getEditor()) buf = buf.linkedDoc({ sharedHist: true });
      var old = this.editor.swapDoc(buf);
      var linked
      old.iterLinkedDocs(function (doc) { linked = doc; });
      if (linked) {
        // Make sure the document in buffers is the one the other view is looking at
        for (var name in this.fileBuffers) if (this.fileBuffers[name] == old) this.fileBuffers[name] = linked;
        old.unlinkDoc(linked);
      }
      this.editor.focus();
  }
  //
  @ViewChild('textarea', { static: false }) textarea!: ElementRef;
  @Output() events:EventEmitter<{type: EventTypes, args: Array<unknown>}> = new EventEmitter()
  @Input() resizable: boolean = false
  @Input() content: string = 'def fun():\n    if x==5:\n        return 5'
  @Output() contentChange = new EventEmitter<string>();
  @Input() mode: 'javascript' | 'python' |'xml' | 'json' |'sql'= 'python'
  //@Input() theme: string = 'default'
  @Input() title: string = 'main.py'
  @Input() conteinerClass: string = ''
  @Input() editorConfig: CodeMirror.EditorConfiguration = {}
  @Input() theme: Theme.light | Theme.dark = Theme.dark
  @Input() height:any = null
  @Input() width:any = null
  @Input() readOnly:boolean = false;
  //
  @Input() outsideEvents: Observable<any> = new Observable;
  @Output() sendFiles: EventEmitter<any> = new EventEmitter()
  //
  
  codemirrorConfigs: CodeMirror.EditorConfiguration  = {
    lineNumbers: true, 
    theme: this.theme,
    foldGutter:true,
    gutters:["CodeMirror-lint-markers","CodeMirror-linenumbers", "CodeMirror-foldgutter"],
    indentUnit:4,
    autoCloseBrackets:true,
    lint: {
            "getAnnotations": pythonLintFactory(this),
            "async": true,
            lintOnChange:true
          },
    hintOptions:{
      hint:pythonHintFactory(this),
      completeSingle:false,
      closeOnUnfocus:false

    },
    extraKeys: {"Ctrl-Space": "autocomplete"}

  }
  editor!: ReturnType<typeof CodeMirror>
  currentLineNumber: number = 0
  currentColumnNumber: number = 0
  isFullScreen: boolean = false
  canOpenFile: boolean = false
  isDirty: boolean = false

  fileName: string = '' // imported filename
  dialog?: HTMLElement

  ngAfterViewInit() {
    this.outsideEvents.subscribe((file:{name:string,args:{name:string,content:string}})=>{
      if(file.name === 'OPEN_FILE'){
        this.loadBuffer(file.args.name,CodeMirror.findModeByFileName(file.args.name)?.mime || 'text/plain',file.args.content)
        //select buffer
        this.selectBuffer(file.args.name)
        this.currentFile = file.args.name
      }
    })
    this.editor = CodeMirror.fromTextArea(this.textarea.nativeElement, {...this.codemirrorConfigs, ...this.editorConfig})
    console.log(this.editor.isReadOnly())

    const editor = this.editor
    this.editor.setSize(this.width,this.height)
    let currFile:string = this.currentFile

    if(this.readOnly){
      this.editor.setOption('readOnly','nocursor');
      this.editor.getWrapperElement().classList.add('read-only');
    }
    //fullscreen extrakeys
    editor.setOption("extraKeys", {...editor.getOption("extraKeys") as any,
      "F11": function(cm) {
        console.log('fullscreen called')
        cm.setOption("fullScreen", !cm.getOption("fullScreen"));
      },
      "Esc": function(cm) {
        if (cm.getOption("fullScreen")) cm.setOption("fullScreen", false);
      },
      Tab: function(cm) {
        var spaces = Array((cm.getOption("indentUnit")||4) + 1).join(" ");
        cm.replaceSelection(spaces);
      },
      "Ctrl-S":(cm)=>{
        this.events.emit({type:EventTypes.SAVE,args:[{fileName:this.currentFile,fileContent:this.editor.getValue()}]})
      },
      "Ctrl-R":function(cm){
        cm.performLint()
      },
      "Ctrl-P":snippets
    })
    //
    this.editor.on("cursorActivity", (editor: ReturnType<typeof CodeMirror>) => {
      const cursor = editor.getCursor();
      this.currentLineNumber = cursor.line + 1;
      this.currentColumnNumber = cursor.ch + 1;

    });
    
    editor.on('change', () => {
      //editor.showHint()
      this.content = this.editor.getValue()
      //this.currentFile.content=this.editor.getValue()
      this.contentChange.emit(this.editor.getValue())
        this.isDirty=true;
        this.events.emit({type:EventTypes.TEXT_CHANGE,args:[{fileName:this.currentFile,fileContent:this.editor.getValue()}]})
      
      
      this.events.emit({
        type: EventTypes.TEXT_CHANGE,
        args: [{getValue: () => editor.getValue(), callback: () => {}}]
      })

    })

    // remove dialog border radius
    this.dialog = this.editor.getWrapperElement().closest('.mat-dialog-container') as HTMLElement
    if(this.dialog) {
      this.dialog.style.borderRadius = '0'
    }

    this.events.emit({
      type: EventTypes.INIT,
      args: [this.editor]
    })
  }

  constructor(private dialogService:NbDialogService,public nodeService:Nodev2Service) {
    //@ts-ignore
    this.canOpenFile = !!window.showOpenFilePicker;
  }

  ngOnInit(): void {
  }
  formatCode(){
    //
    let fMode = CodeMirror.findModeByFileName(this.currentFile)?.name
    
    console.log(fMode)
    // let formattedCode = formatCode(fMode||"",this.editor.getValue());

    // this.editor.setValue(formattedCode);
    
    //console.log(CodeMirror.findModeByFileName(this.currentFile))
  }
  //////////
  changeTheme(){
    this.theme = this.theme=== Theme.light? Theme.dark:Theme.light;
    this.editor.setOption('theme',this.theme)
  }

  


  resize() {
    this.events.emit({type: EventTypes.RESIZE, args: [(isFullScreen: boolean) => {this.isFullScreen = isFullScreen}]})
  }

  save() {
    this.isDirty = false
    //this.events.emit({type: EventTypes.SAVE, args:[{getValue: () => this.editor.getValue()}]})
    this.events.emit({type:EventTypes.SAVE,args:[{fileName:this.currentFile,fileContent:this.editor.getValue()}]})
  }


  async loadFile() {
    const pickerOpts = {
      types: [
        {
          description: 'Images',
          accept: {
            'text/python': ['.py', '.txt']
          }
        },
      ],
      excludeAcceptAllOption: true,
      multiple: false
    };
    //@ts-ignore
    if(!window.showOpenFilePicker) {
      return alert('Browser does not support this feature!')
    }

    //@ts-ignore
    const handles = await window.showOpenFilePicker(pickerOpts);
    if(handles.length > 0) {
      let file = await handles[0].getFile();
      this.fileName = file.name
      let fileText: string =  await file.text();
      this.editor.setValue(fileText)
    }
  }


  public toggleFullScreen() {
    this.editor.setOption("fullScreen",true)
    // if(!this.dialog) return;
    
    // @ts-ignore
    // if(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
    //   if (document.exitFullscreen) {document.exitFullscreen()} 
    //   // @ts-ignore
    //   else if (document.mozCancelFullScreen) {document.mozCancelFullScreen()} 
    //   // @ts-ignore
    //   else if (document.webkitExitFullscreen) {document.webkitExitFullscreen()} 
    //   // @ts-ignore
    //   else if (document.msExitFullscreen) {document.msExitFullscreen()}
    // } else {

    //   let element = (this.conteinerClass ? this.editor.getWrapperElement().closest('.'+this.conteinerClass) : (this.editor.getWrapperElement().closest('.mat-dialog-container') || this.editor.getWrapperElement())) as HTMLElement;

    //   if (element.requestFullscreen) {element.requestFullscreen()} 
    //   // @ts-ignore
    //   else if (element.mozRequestFullScreen) { element.mozRequestFullScreen()} 
    //   // @ts-ignore
    //   else if (element.webkitRequestFullscreen) { element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)} 
    //   // @ts-ignore
    //   else if (element.msRequestFullscreen) { element.msRequestFullscreen()}

    // }
  }

  showKeyboardShortcuts(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }


}
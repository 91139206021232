import {
  AfterViewInit,
  Component, ComponentFactoryResolver, OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Subscription} from "rxjs/internal/Subscription";
import {BehaviorSubject, Observable, Subject, config} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NB_TIME_PICKER_CONFIG, NbDialogService, NbToastrService, NbWindowService} from "@nebular/theme";

import {PipelineAPIResponse, PipelineService} from 'src/app/services/api/pipeline.service';
import {FileService} from 'src/app/services/api/file.service';
import {ConfigService} from 'src/app/services/api/config.service';
import {DataService} from 'src/app/services/api/data.service';
import {TaskService} from 'src/app/services/api/task.service';
import {ConfigureApiService} from 'src/app/services/api/configure-api.service';
import {ConfigKeyService, ConfigServiceResponse} from 'src/app/services/api/configKey.service';
import {NodeService} from "src/app/services/api/node.service";
import {ConsoleLoggerService} from "src/app/services/logger/console-logger.service";
import {NavbarService} from 'src/app/services/core/navbar.service';
import {ApiConfigurationComponent} from '../api-configuration/api-configuration.component';
import {EdgeconnectorComponent} from "../../components/edgeconnector/edgeconnector.component";
import {DagState, Event as DagEvent, EventTypes} from "../../components/dag/dag.component";
import {DashboardStoreService} from "../../services/store/dasboard-store.service";
import {DynamicField, Node, NodeField} from "../../models/tasknode.model";
import { buildTableDataAllOutput} from "./helper";
import {NodeStatusService} from "../../services/api/node-status.service";
import {ConfigKeyModel, ConfigModel} from "../../models/config.model";
import { PipelineConstantComponent } from '../pipeline-constant/pipeline-constant.component';
import { ScheduleComponent } from '../schedule/schedule.component';
import {UIService} from "../../services/config/ui.service";
import {Context} from "../../services/config/context.service";
import * as internal from 'stream';
import { NotificationRecipientComponent } from 'src/app/components/notification-recipient/notification-recipient.component';
import { AddSchemaComponent } from 'src/app/components/add-schema/add-schema.component';
import {ApiPopupComponent} from "../api-popup/api-popup.component";
import {dataTypes, keysOfObject} from "../../components/edit-api/edit-api.component";
import { isBoolean } from 'util';
import { thermometer } from 'ngx-bootstrap-icons';
import {ca} from "cronstrue/dist/i18n/locales/ca";
import { MultipleSheetSchemaComponent } from 'src/app/components/multiple-sheet-schema/multiple-sheet-schema.component';
import {TemplatePortal} from "@angular/cdk/portal";
import {AddTaskComponent, InputFieldType} from "./tasks/addtask/add-task.component";
import {TaskDetailsDirective} from "./task-details.directive";
import {Option} from "../../components/multi-select-dropdown/union.component";
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {UpdateTaskComponent} from "./tasks/update-task/update-task.component";
import {getDefaultFieldsName, OutputEventEnum} from "./tasks/helper-functions";
import {STRING_KEY_VALUE_TYPE, ViewJsonComponent} from "../../components/view-json/view-json.component";
import {CdkDropList, CdkDropListGroup} from '@angular/cdk/drag-drop';
import { DownloadFormComponent } from '../download-form/download-form.component';
import { Location } from '@angular/common';
import { EditorComponent } from 'src/app/components/codemirror/codemirror.component';
import {EventTypes as CodeMirrorComponentEvents} from 'src/app/components/codemirror/codemirror.component';
import { filter } from 'rxjs/operators';
import { CustomNodeEditorComponent, IConfig } from './custom-node-editor/custom-node-editor.component';
import { DataConnectionComponent } from '../data-connection/data-connection.component';
import { CopyTaskComponent } from './tasks/copy-task/copy-task.component';
import { ConfirmationPopupModel } from 'src/app/components/confirmation-popup/confirmation-popup';
import { ConfirmationPopupComponent } from 'src/app/components/confirmation-popup/confirmation-popup.component';
import { schema_sheet } from 'src/app/models/schema.model';
import { NodeData } from '../output/nodes-output/nodes-output.component';
import { DataMapperComponent } from 'src/app/components/data-mapper/data-mapper.component';
import { AddNodeComponent } from './add-node/add-node.component';
import { SelectnodeComponent } from 'src/app/components/selectnode/selectnode.component';
import { DynamicFormComponent, Form, FormUpdateHandler } from 'src/app/components/dynamic-form/dynamic-form.component';
import { FormService } from 'src/app/services/api/form.service';
import { NodeSettingsComponent } from './node-settings/node-settings.component';
import { NodeInputField } from 'src/app/components/dag/dag_type';
import { Nodev2Service } from 'src/app/services/api/nodev2.service';
import { Connector, PipelineVersion } from 'src/app/services/api/v2/interfacesv2';
import { Connectorv2Service } from 'src/app/services/api/v2/connectorv2.service';
import { NodeEditor, GetSchemes, ClassicPreset } from "rete";
import { CustomNode } from 'src/app/rete-dag/custom-nodes/default-node/custom-node.component';


export interface input {
   name: string;
  }

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(TaskDetailsDirective, {static: true}) detailsHost!: TaskDetailsDirective;
  @ViewChild('templatePortalContent') templatePortalContent!: TemplateRef<unknown>;
  @ViewChild('getConnectionTypeComponent') getConnectionTypeComponent!: TemplateRef<unknown>;

  templatePortal!: TemplatePortal<any>;

  @ViewChild(CdkDropListGroup)
  listGroup!: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList)
  placeholder!: CdkDropList;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  input: input[] = [];
  isViewMode: boolean = true

  
  Configdetails = <ConfigServiceResponse['payload']>Object();
  startTaskNodeId: any;
  url = '';
  configForm: FormGroup = new FormGroup({});
  //temporary variables
  booleanWeight:boolean|null = null;
  hasBooleanWeight: boolean = false;
  isNewNode:boolean = false;

  nodeStatus: boolean = false;
  enableAddTaskButton: boolean = true;
  nodes_output$ = new Subject<{type: 'ADD_NODE', args: Array<unknown> }>();
  selectEnabled:boolean = false;
  dagState = {undo: false, redo: false, save: false}
  dragEnabled:boolean = false;

  unionevents$ = new BehaviorSubject<{type:string, args: Array<unknown>}>({type: 'init', args:[]})

  // dropdowns
  unionDropdown: Array<Option> = []
  dependantDropdown: Array<Option> = []
  dataDependantDropdown: Array<Option> = []

  data: any;
  eventsSubject: Subject<{ type: 'ZOOM_IN' | 'ZOOM_OUT' | 'ZOOM_FIT' | 'UPDATE_LOADING_STATUS' | 'UPDATE_CONDITIONAL_NODE_STATUS' | 'DELETE_NODE' | 'MULTIPLE_SELECT_NODES'|'FOCUS_NODE', args: Array<unknown> }> = new Subject();
  openConfig: boolean = false;
  isFile: boolean = true;
  api: any;
  columns: any;
  mappings: Map<any, any> = new Map<any, any>();
  mappings_data: Map<any, any> = new Map<any, any>();

  // vars
  subscriptions: Array<Subscription> = []
  pipeline: PipelineAPIResponse['payload'] | null = null
  nodes: Array<Node> = []
  pipelineId: number = -1
  pipelineVersionId: number = -1 
  processId: number = -1
  isLoading: boolean = false
  runType:string = ''
  isProgress:boolean= false
  file_there = false
  configId: any
  configsResponse: any[] = []
  taskNodeId: any
  _text:string = "";
  type:any
  wight:boolean=true;
  dependent_nodes: Array<{type: string, weight: boolean, taskId: number}> = []
  items:Array<string>=[]
  pipelineNameInput: string = ''
  connections: Array<any> = [];

  backUrl: string | null = null

  //conditional node status
  conditionalNodeStatus:Map<number, boolean|null> = new Map<number, boolean|null>();

  nodeType = {
    SOURCE: 'source',
    CONDITIONAL: 'conditional'
  }

  components = {
    notification: NotificationRecipientComponent,
    addschema: AddSchemaComponent
  }

  isConfigNavLoading: boolean = false // show spinner if process is running

  // Buttons
  enableAddButton: boolean = true
  enableUpdateButton: boolean = true

  get dashboardConfig() {
    return this.dashboardService.getConfig()
  }

  activeConfiguration:AddTaskComponent | UpdateTaskComponent | CopyTaskComponent | null = null
  nodeConfigForm = new Form()
  nodeConfigFormHandler: FormUpdateHandler | null = null
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private configKeyService: ConfigKeyService,
    private data_service: DataService,
    private task_service: TaskService,
    private nodeService: NodeService,
    private apiService: ConfigureApiService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public navbarService: NavbarService,
    private pipelineService: PipelineService,
    // private dialogService: NbDialogService,
    private logger: ConsoleLoggerService,
    public dashboardService: DashboardStoreService,
    private nodeStatusService: NodeStatusService,
    public uiService: UIService,
    private context: Context,
    private _viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private location: Location,
    private fileService: FileService,
    private nodeV2Service:Nodev2Service,
    private connectorService:Connectorv2Service
  ) {

    this.url = context.getApiConfig().PIPELINE_APIS.POST.taskNode // FIXME: component should not dirctly depend on endpoint
    this.navbarService.create = false;
    this.navbarService.view = false;
    this.navbarService.back = true;
    this.uiService.showSideNav = false
    let previousNavigation = this.router.getCurrentNavigation()?.previousNavigation
    if(previousNavigation) {
      this.backUrl = previousNavigation.finalUrl?.toString() || this.backUrl
    }

    this.configForm = this.fb.group({});

    this.pipelineNameInput = this.route.snapshot.queryParams['name']
    
  }


  ngAfterViewInit() {
    this.templatePortal = new TemplatePortal(this.templatePortalContent, this._viewContainerRef);
  }

  ngOnInit(): void {
    this.uiService.toggleIndicator('/pipeline/v2/nodeStatus', false)
    this.uiService.toggleIndicator('/pipeline/v1/configDetails', false)
    this.uiService.toggleIndicator('/pipeline/v1/taskconfigDetails', false)
    this.uiService.toggleIndicator('/pipeline/v1/taskOutput', false)

    // Register events
    this.registerEvents()

    // Subscribe data
    this.subscribeData()

    // Load data
    this.loadPipelineFromRoute()
  }

  openCreateTask(type: ConfigModel, parentNodeIds: number[], pipelineId:number, processId: number) {
    this.dialog.open(AddNodeComponent, {data: {type,parentNodeIds,pipelineId,processId}}).afterClosed().subscribe(data=>console.log(data));
    
    // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AddTaskComponent);
    // const viewContainerRef = this.detailsHost.viewContainerRef;
    // viewContainerRef.clear();

    // const componentRef = viewContainerRef.createComponent<AddTaskComponent>(componentFactory);
    // this.uiService.showSideNav = true

    // console.log(componentRef.instance);
    // this.activeConfiguration = componentRef.instance
    // componentRef.instance.type = type
    // componentRef.instance.pipelineId = pipelineId;
    // componentRef.instance.processId = processId;
    // componentRef.instance.parentNodeIds = parentNodeIds;

    // //for schema
    // componentRef.instance.schemaProperties = { hasSchema:false, schema:[]}

    // this.uiService.showSideNav = true
    // this.isConfigNavLoading = true

    // componentRef.instance.output.pipe(filter(x => x.type === OutputEventEnum.ON_LOAD)).subscribe (event => {
    //   this.isConfigNavLoading = false
    // })

    // componentRef.instance.output.pipe(filter(x => x.type === OutputEventEnum.LOADING_ERROR)).subscribe (event => {
    //   this.isConfigNavLoading = false
    //   componentRef.instance.loadingError = `Failed to load initial configurations.`
    // })

    // const subscription = componentRef.instance.output.subscribe(async event => {

    //   if(event.type === OutputEventEnum.SAVED_AND_EXIT) {

    //     // prevent closig configuration if failed
    //     if(event.data.response.status === "failure") return;

    //     // Show success message
    //     this.logger.log('Task Created!');
    //     this.uiService.showInfo(`Task Created!`, 'success')

    //     subscription.unsubscribe()
    //     viewContainerRef.clear()
    //     this.showConfig = false
    //     // this.loadPipelineFromRoute()
    //     this.uiService.showSideNav = false

    //     // set new node as current node and open configs in edit mode
    //     try {
    //       const newTask: Node = event.data.response.payload.taskObjects[0]
    //       this.dashboardService.setCurrentNode(newTask);
    //       this.openUpdateTask(type, newTask.id, pipelineId, processId)
    //     } catch {
    //       this.logger.error(`Failed to get new task id`)
    //     }
    //   }

    //   if(event.type === OutputEventEnum.CANCEL) {
    //     this.activeConfiguration = null
    //     this.logger.log(event)
    //     subscription.unsubscribe()
    //     viewContainerRef.clear()
    //     this.showConfig = false
    //     this.uiService.showSideNav = false
    //   }


    //   // Open Code editor and pass the data back on save
    //   if(event.type === OutputEventEnum.OPEN_CODE_EDITOR) {
    //     let editorInstance = await this.openCodeEditor(event.data)
    //   }

    //   //open schema
    //   if(event.type === OutputEventEnum.OPEN_SCHEMA){
    //     let res =  await this.open_schema()
    //     componentRef.instance.schemaProperties = res
    //   }

    //   //edit schema
    //   if(event.type === OutputEventEnum.EDIT_SCHEMA){
    //     let res =  await this.edit_schema(event.data)
    //     componentRef.instance.schemaProperties = res
    //   }
    // })
  }

  openUpdateTask(type: ConfigModel, currentNodeId: number, pipelineId:number, processId: number) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UpdateTaskComponent);
    const viewContainerRef = this.detailsHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<UpdateTaskComponent>(componentFactory);
    this.activeConfiguration = componentRef.instance
    componentRef.instance.type = type
    componentRef.instance.pipelineId = pipelineId;
    componentRef.instance.processId = processId;
    componentRef.instance.currentNodeId = currentNodeId;
    componentRef.instance.isReadonly = this.isViewMode

    componentRef.instance.schemaProperties = { hasSchema:false, schema:[]}

    this.uiService.showSideNav = true
    this.isConfigNavLoading = true

    componentRef.instance.output.pipe(filter(x => x.type === OutputEventEnum.ON_LOAD)).subscribe (event => {
      this.isConfigNavLoading = false
    })

    componentRef.instance.output.pipe(filter(x => x.type === OutputEventEnum.LOADING_ERROR)).subscribe (event => {
      this.isConfigNavLoading = false
      componentRef.instance.loadingError = `Failed to load configuration details.`
    })

    const subscription = componentRef.instance.output.subscribe(async event => {
      if(event.type === OutputEventEnum.SAVED_AND_EXIT) {
        // this.loadPipelineFromRoute()

        // Show success message
        if(event.data.response.status === "success") {
          this.logger.log('Task Updated!');
          this.uiService.showInfo(`Task Updated!`, 'success')
        }
      }

      if(event.type === OutputEventEnum.CANCEL) {
        this.activeConfiguration = null
        this.logger.log(event)
        subscription.unsubscribe()
        viewContainerRef.clear()
        this.showConfig = false
        this.uiService.showSideNav = false
      }

      if(event.type === OutputEventEnum.OPEN_PREVIEW) {
        this.showNodePreview(event.data.event)
      }

      // Open Code editor and pass the data back on save
      if(event.type === OutputEventEnum.OPEN_CODE_EDITOR) {
        let editorInstance = await this.openCodeEditor(event.data)
      }

      //open schema
      // if(event.type === OutputEventEnum.OPEN_SCHEMA){
      //   let res =  await this.open_schema()
      //   componentRef.instance.schemaProperties = res
      // }

      //edit schema
      // if(event.type === OutputEventEnum.EDIT_SCHEMA){
      //   console.log(event.data);
      //   let res =  await this.edit_schema(event.data)
      //   componentRef.instance.schemaProperties = res
      // }
    })
  }

  openCopyTask(type: ConfigModel, currentNodeId: number,parentNodeIds: number[], pipelineId:number, processId: number) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CopyTaskComponent);
    const viewContainerRef = this.detailsHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<CopyTaskComponent>(componentFactory);
    this.activeConfiguration = componentRef.instance
    componentRef.instance.type = type
    componentRef.instance.pipelineId = pipelineId;
    componentRef.instance.processId = processId;
    componentRef.instance.currentNodeId = currentNodeId;
    componentRef.instance.isReadonly = this.isViewMode
    componentRef.instance.parentNodeIds = parentNodeIds
    this.uiService.showSideNav = true
    this.isConfigNavLoading = false

    const subscription = componentRef.instance.output.subscribe(async event => {
      if(event.type === OutputEventEnum.SAVED_AND_EXIT) {
        // this.loadPipelineFromRoute()
      }

      if(event.type === OutputEventEnum.CANCEL) {
        this.activeConfiguration = null
        this.logger.log(event)
        subscription.unsubscribe()
        viewContainerRef.clear()
        this.showConfig = false
        this.uiService.showSideNav = false
      }

      if(event.type === OutputEventEnum.OPEN_PREVIEW) {
        this.showNodePreview(event.data.event)
      }

      // Open Code editor and pass the data back on save
      if(event.type === OutputEventEnum.OPEN_CODE_EDITOR) {
        let editorInstance = await this.openCodeEditor(event.data)
      }
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
    this.dashboardService.onDelete()
  }
  pipelineDetails:any
  async loadPipelineFromRoute() {
    this.uiService.isContentLoading = true

    let route = this.route.snapshot.paramMap
    if (route.get('id')) {
      const pipelineId = parseInt(route.get('id') as string)
      const versionId = parseInt(route.get('version') as string)

      await this.loadPipelineData(pipelineId, versionId)

    } else {
      this.logger.warn('Failed to get pipeline id from route')
    }

    this.uiService.isContentLoading = false
  }


  pipelineGraph: {nodes: {[key: string]: any}, edges: {[key: string]: any}} = {nodes: {}, edges: {}}
  async loadPipelineData(pipeline_id: number, versionId: number) {
    let response = await this.pipelineService.getPipeline(pipeline_id, versionId).toPromise()

    this.pipelineService.getPipelineDetails(pipeline_id, versionId).subscribe(pipelineDetails => {
      this.pipelineDetails= pipelineDetails
      this.pipelineGraph = pipelineDetails.payload.config
      this.pipelineId = pipelineDetails.payload.pipelineId
      this.pipelineVersionId = pipelineDetails.payload.id
      this.dashboardService.setPipeline(pipelineDetails.payload)
    })
    let nodesConfig = await this.pipelineService.getNodesConfig().toPromise()

    this.dashboardService.setNodesConfig(nodesConfig)
    // this.dashboardService.setPipeline(response)
    this.data_service.subPipeline.next(response);
  }

  openReteNodeConfig(node: CustomNode) {
    this.openNodeConfig(node as any)
  }

  openCreateNodePopup(node: CustomNode) {
    console.log(node)
    this.openConnectorPopup(node.uid as unknown as number)
  }

  eventMapping: { [key: string]: Function } = {}
  unionEventMapping: { [key: string]: Function } = {}
  dependantEventMapping: { [key: string]: Function } = {}
  dataDependantEventMapping: { [key: string]: Function } = {}

  registerEvents() {
    this.eventMapping[EventTypes.OPEN_CONNECTOR] = this.openConnectorPopup
    this.eventMapping[EventTypes.CONNECT_NODES] = this.connectNodes
    this.eventMapping[EventTypes.OPEN_CONFIG] = this.openNodeConfig
    this.eventMapping[EventTypes.OPEN_FIELD_OUTPUT] = this.openFieldOutput
    this.eventMapping[EventTypes.DELETE_NODE] = this.deleteNode
    this.eventMapping[EventTypes.MOUSEOVER_CONNECTOR] = this.showRecentUsedTaskTypes,
    this.eventMapping[EventTypes.SELECTED_NODES] = this.selectedNodes
    this.eventMapping[EventTypes.OPEN_EDGE_MAPPING] = this.openEdgeMapper
  }

  goBack(event: MouseEvent) {
    event.preventDefault()
    this.location.back()
  }

  startNodeArray: Array<number> = []
  rundependantArray: Array<number> = []
 
  subscribeData() {
    let subscription = this.dashboardService.config$.subscribe(dashboardConfig => {
      console.log(dashboardConfig)
      this.isViewMode = dashboardConfig.isViewMode
    })
    this.subscriptions.push(subscription)

    subscription = this.dashboardService.pipeline$.subscribe(pipeline => {
      this.pipelineGraph = pipeline?.config as any // FIXME: define type
      this.logger.log(pipeline)
      this.data_service.subPipeline.next(pipeline)
      this.pipeline = pipeline
      // this.pipelineNameInput = this.pipeline?.name || ''
      this.nodes = pipeline?.nodeInstances || []

      // FIXME: fix set pipeline flow, app state can be stores in 'Store'
      this.pipelineId = pipeline?.pipelineId as number || -1
      this.pipelineVersionId = pipeline?.id || -1
      this.processId = pipeline?.process?.id || -1
    })
    this.subscriptions.push(subscription)

    subscription = this.nodeStatusService.nodeStatus$.subscribe(status => {

      let mapping = status?.reduce?.((prev, current) => {
        prev[current.task_node_id] = {status: current.status, message: current.error_message}
        return prev
      }, {} as {[key: number]: {status: string, message?: string|null}})

      this.eventsSubject.next({
        type: 'UPDATE_LOADING_STATUS',
        args: [mapping]
      })
    })
    this.subscriptions.push(subscription)

    subscription = this.nodeStatusService.pipelineStatus$.subscribe(status => {
      this.isProgress=status.status === 'in_progress'
    })

    this.subscriptions.push(subscription)

    const status = this.nodeStatusService.pipelineStatus$.subscribe(run_type => {
      this.runType=run_type.run_type
    })
    console.log(status)
    this.subscriptions.push(status)
    this.getConnection()
  }

  async openConnectorPopup(nodeId: number, booleanWeight?:boolean|null) {
    this.enableAddButton =true
    const currentNode = this.dashboardService.getNodeById(nodeId)!
    this.dashboardService.setCurrentNode(currentNode)
    this.hasSchema = false;

    if(booleanWeight != undefined){
      this.booleanWeight = booleanWeight;
      this.hasBooleanWeight = true;
    }

    // let configs = await this.configService.getAll()
    // FIXME: move connectors loading to service
    if(!this.connectors) {
      const connectors = await this.connectorService.getConnectors().toPromise();
      this.connectors = connectors.payload.data.map((x:any)=>{
        return {...x,displayName:x.label,type:'source'}
      });
    }

    // TODO: remove rendon group
    let dialogRef = this.dialog.open(EdgeconnectorComponent, {hasBackdrop: true, data: {configs: this.connectors, title: `Connect to`} , panelClass: 'custom-dialog-container', minHeight:'80vh', minWidth: '80vw'})
    // let response: { data: { id: number } } = await  dialogRef.afterClosed().toPromise()



  // let nodeName:string =  await dialogRef.afterClosed().toPromise();
  let connectorSelected:Connector =  await dialogRef.afterClosed().toPromise();
  console.log('connector selected',connectorSelected);
  if(connectorSelected)
  {
    const runAfter = {
      [nodeId]: true
    }
    let newNode: {nodeId:string,pipelineVersion:PipelineVersion} = await this.dialog.open(AddNodeComponent, {data: {connectorSelected,pipelineId:this.pipelineDetails.payload.pipelineId, pipelineVersion:this.pipelineDetails.payload.id, runAfter}}).afterClosed().toPromise();
    if(newNode) {

      // FIXME: define type
      this.dashboardService.setPipeline(newNode.pipelineVersion as any)
    
    // let testingNode:Node = {
    //   id:-1,
    //   nodeId: newNode.nodeId,
    //   pipelineId: newNode.pipelineVersion.pipelineId,
    //   parentId: null,
    //   isReference: false,
    //   name: newNode.pipelineVersion.config.nodes[newNode.nodeId].name,
    //   label: newNode.pipelineVersion.config.nodes[newNode.nodeId].label,
    //   nodeName: newNode.pipelineVersion.config.nodes[newNode.nodeId].connector,
    //   connectionInstanceId: null,
    //   connection: null,
    //   dynamicInputFields: null,
    //   outputFields: null,
    //   serviceName: null,
    //   inputConfigs: null,
    //   status: "",
    //   createdTime: "",
    //   updatedTime: ""
    // }
  }
}

    
    // if (response) {

    //   // FIXME: add type
    //   let configType = this.connectors.find((x: any) => x.id === response.data.id) as ConfigModel
    //   this.openCreateTask(configType, currentNode?.id?[currentNode?.id]:[], this.pipeline?.id as number, this.pipeline?.process.id as number)
    // }
  }


  currentNodeConfig: any = null
  async openNodeConfig(node: CustomNode) {
    console.log(node)
    // this.dashboardService.setCurrentNode(node)
    // let configType = node.nodeName // TODO: check node ConfigType (node?.nodeName) as per V2 API Response


    /* TODO: Open Update Node flow as per V2 Changes 
    this.openUpdateTask(configType, node.id, this.pipeline?.id as number, this.pipeline?.process.id as number)
    // */
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NodeSettingsComponent);
    const viewContainerRef = this.detailsHost.viewContainerRef;
    viewContainerRef.clear();

    if(!this.connectors) {
      const connectors = await this.connectorService.getConnectors().toPromise();
      this.connectors = connectors.payload.data.map((x:any)=>{
        return {...x,displayName:x.label,type:'source'}
      });
    }

    const componentRef = viewContainerRef.createComponent<NodeSettingsComponent>(componentFactory);
    this.uiService.showSideNav = true
    
    const connector = this.connectors.find((x: any) => x.name === node.meta.connector)
    // componentRef.instance.nodeInstanceResponse=node.meta;
    // componentRef.instance.connectorData = {
    //   "id": 1,
    //   "name": "mysql",
    //   "label": "My SQL",
    //   "desc": "My SQL Related Connector",
    //   "icon": "http://dgallery.s3.amazonaws.com/mysql-logo.jpg",
    //   "type": null,
    //   "connection": {
    //       "isEnabled": true
    //   },
    //   "services": [
    //       {
    //           "name": "select_query",
    //           "label": "Select Query",
    //           "description": "Select Query"
    //       }
    //   ]
    // }
    componentRef.instance.nodeData = {
      pipelineId: this.pipelineId,
      pipelineVersionId: this.pipelineVersionId,
      status:"Draft",
      // connectorId: 5,
      connectorId: connector?.id,


      uuid: node.uid,
      name: node.meta.name,
      label: node.meta.label,
      connector: node.meta.connector,
      description: node.meta.description,
      serviceName: node.meta.service_name,
      inputFields: node.meta.input_fields || {},
      connectionId: node.meta.connection_id
    }
    componentRef.instance.output.subscribe(event=>{
      if(event.type==='CLOSE'){
        this.uiService.showSideNav = false;
        viewContainerRef.clear();
      }
    })


  }

  
  async openFieldOutput(node: Node, field: NodeInputField) {
    console.log(node, field)
    this.output = true;

    const response: any = {
      status: 'success',
      error_message: null,
      files: {},
      validations: [],
      api_upload: {
        success_rows: 0,
        failed_rows: 0
      },
      conditional_upload: {}
    };

    let payload = await this.nodeV2Service.getNodeAllOutput(this.pipelineId.toString(),node.id.toString(),'-1').toPromise()
    console.log(payload)

    this.files_data = buildTableDataAllOutput(payload,field.id)
    // this.nodes_output$.next({type:'ADD_NODE', args:[{id: nodeId, name: node.name, ...outputResponse, data: this.files_data}]})
    setTimeout(() => {
      console.log(this.files_data)
      this.nodes_output$.next({type:'ADD_NODE', args:[{id: node.id, name: node.name, ...response, data: this.files_data,field}]})
    }, 0)
  }
 

  refreshDag() {
    this.loadPipelineData(this.pipelineId, this.pipelineVersionId)
  }

  focusNode(event: any) {
    this.eventsSubject.next({
      type: 'FOCUS_NODE',
      args: [parseInt(event.target.value)]
    })
  }

  // runNode() {
  //   this.logger.log(this.pipelineId, this.processId, this.taskNodeId);
  //   this.task_service.runNode(this.pipelineId, this.processId, this.taskNodeId),.subscribe((response: any) => {
  //     this.logger.log("Run node response:", response);
  //     this.uiService.showInfo(`Task run successfully`);
  //   }, (error) => {
  //     this.logger.warn(error)
  //     this.uiService.showError(`Failed to run task`)
  //   },() => {
  //     if(this.Configdetails.type == this.nodeType.CONDITIONAL ){
  //       this.eventsSubject.next({
  //         type: 'UPDATE_CONDITIONAL_NODE_STATUS',
  //         args: [{taskId:this.taskNodeId,status:this.get_conditional_node_status(this.taskNodeId)}]
  //       })
  //     }
  //   });
  // }

  // TODO: remove get_conditional_node_status
 
  get_conditional_node_status(taskNode:number): boolean|null {
    this.pipelineService.getoutput(this.pipelineId,this.processId,taskNode)
      .subscribe((outputResponse: any) => {
        this.logger.log(outputResponse["payload"],outputResponse.conditional_upload.status);
        return outputResponse.conditional_upload.status;
      });

    return null;
  }

  async selectTransformations(event: MouseEvent) {
    event.preventDefault()
    this.hasSchema = false;
    this.enableAddTaskButton = true
    this.nodeStatus = false
    this.isFile = true
    this.openConfig = false
    let configs = await this.configService.getAll()
    let sourceNodeTypes = configs.filter(x => ['source', 'management'].includes(x.type))


    let dialogRef = this.dialog.open(EdgeconnectorComponent, {
      hasBackdrop: true,
      panelClass: 'custom-dialog-container', minHeight:'80vh', minWidth: '80vw',
      data: {configs: sourceNodeTypes, title: 'Add Node'}
    })

    let response: { data: { id: number } } =  await dialogRef.afterClosed().toPromise()
    if (!response) return;

    let configType = configs.find(x => x.id === response.data.id) as ConfigModel
    this.openCreateTask(configType, [], this.pipeline?.id as number, this.pipeline?.process.id as number)
  }


  createFormControl() {
    this.configForm = this.fb.group({});
    this.configForm.addControl("nodeName", new FormControl(this.dashboardService.getCurrentNode()?.name))

    if (this.Configdetails.type === 'union') {
      this.configForm.addControl(this.Configdetails.name, new FormControl(''))
    }
    this.Configdetails.configs.forEach((element: any) => {
      this.configForm.addControl(element.id, new FormControl(''))
    })
  }


  // check if node was successfully added
  checkNodeStatus(status: any) {
    return status == "success"
  }


  mergeDependantNodeArray(startNodeArray: Array<Option>, dependantArray: Array<Option>, dataDependantArray: Array<Option>) {
    //TODO: remove any
    let mergeArray: Array<{ taskId: number, type: string, weight?: boolean } | any> = []
    mergeArray = mergeArray.concat(dependantArray.map(x => ({
      taskId: x.id,
      type: x.weight === undefined || x.weight === null? "run_dependent" : "conditional",
      weight: x.weight
    })));

    mergeArray = mergeArray.concat(startNodeArray.map(x => ({
      taskId: x.id,
      type: "data",
    })));
    mergeArray = mergeArray.concat(dataDependantArray.map(x => ({
      taskId: x.id,
      type: x.weight === undefined || x.weight === null? "data" : "conditional_data",
      weight: x.weight
    })));
    return mergeArray
  }

  async showNodePreview(event: MouseEvent) {

    let apiConfig = this.activeConfiguration?.configKeyResponse.configs.find(x => x.key_name === 'client_api')
    let taskId = this.dashboardService.getCurrentNode()?.id || -1
    let index = 1
    let apiId = -1

    if(apiConfig) {
      apiId = (apiConfig as any).userValue.value
    }

    let dialogRef = this.dialog.open(ViewJsonComponent, {panelClass: 'custom-dialog-container', minHeight:'80vh', minWidth: '80vw'})
    let instance = dialogRef.componentInstance;
    instance.title = 'Api Request'
    instance.hideMenu = false

    try {
      const response = await this.task_service.getTaskPreview({}, {apiId,taskId,index}).toPromise()      
      if(response.status !== 'success') {
        instance.isLoading = false
        instance.error = response.errorMessage
        return
      }
      instance.data = response.payload

      // mark General/headers section as String key Value
      if(instance.data.General) {
        instance.data.General[STRING_KEY_VALUE_TYPE] = true
      }
      if(instance.data.headers) {
        instance.data.headers[STRING_KEY_VALUE_TYPE] = true
      }

      instance.ngOnChanges({
        data: {
          currentValue: instance.data,
          isFirstChange: () => true,
          firstChange: true,
          previousValue: null
        }
      })
    } catch {
      this.uiService.showError('Failed to load preview!')
      instance.isLoading = false
    }
  }

  // ------------------------------------------------------------------------------------------------------------------- //

  //set file as value
  files: Array<{ id: number, file:File }> = [];
  file_value: Map<any, any> = new Map<any, any>();

  output_data: any;
  cols: any = [];
  taskData: any;
  files_data: any = [];
  output: boolean = false;

  selectedNodesOutput:Array<NodeData> = []
  
  //DB source connection node
  dbDialog: any
  openConnection(config: any) {
    let id: number = -1;
    this.dbDialog = this.dialog.open(DataConnectionComponent, {panelClass: 'custom-dialog-container'}).afterClosed().subscribe((res: any) => {
      this.logger.log(res);
      this.configService.addConfigValue({
        "config_key_id": config.id,
        "display_text": res.identifier,
        "value": res.connectionId
      }).subscribe((response: any) => {
        this.logger.log("adding dropdown value", response);
        id = response.payload.id;

        if (res.status) {
          this.logger.log("mysql", this.Configdetails.configs.filter((c) => c.id === config.id)[0]);
          this.Configdetails.configs.filter(c => c.id === config.id)[0].valueConfigs.push({
            "id": id,
            "config_key_id": config.id,
            "display_text": res.identifier,
            "value": res.connectionId

          });
          this.logger.log(this.Configdetails.configs.filter(c => c.id === config.id)[0].valueConfigs);
          /*
          write function for adding valueConfig
          push the result connection id and display text
          and also push for temporary changes
          */
          this.configForm.controls[config.id].setValue(id);
          this.logger.log(this.configForm.controls[config.id].value);
        }
      });
      this.logger.log("config for mysql connection", this.Configdetails.configs);

    });
  }
  getConnection() {
    this.apiService.getConnection().subscribe((response: any) => {
      this.connections = response.payload;
      console.log("connections", this.connections);
    });
  }

  editConnection(config:any) {
    let connection_identifier = this.configForm.controls[config.id].value
    let connection = this.connections.find(x=>x.id == connection_identifier)
    this.logger.log(connection)
    if(!connection)return;
    this.dialog.open(DataConnectionComponent, {panelClass: 'custom-dialog-container',
      data: {
        connection
      }
    }).afterClosed().subscribe((updatedConnection: any) => {
      if (updatedConnection && updatedConnection.status) {
        Object.assign(connection,{
          client_id: updatedConnection.client_id,
          id: updatedConnection.connectionId,
          identifier: updatedConnection.identifier,
          ip_address: updatedConnection.ip_address,
          password: updatedConnection.password,
          port: updatedConnection.port,
          username: updatedConnection.username,
        })
      }
    })
  }
  
  async newAPI() {
    let configApiField = this.Configdetails.configs.find(x => x.key_name === 'client_api')
    if(!configApiField) {
      this.logger.error('client api configuration not found');
      return
    }
    let data:Array<any> = [
      {title: 'headers', fields: []},
      {title: 'variables', fields: []},
      {title: 'schema', fields: []}
    ]
    this.dialog.open(ApiPopupComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        data,
        settings: {},
        create: true
      }
    })
  }

  // Side nave data
  private _showConfig: boolean = false
  get showConfig(): boolean {
    return this._showConfig
  }

  set showConfig(value: boolean) {
    this._showConfig = value
  }

  createNewAPI() {
    this.dialog.open(ApiConfigurationComponent)
  }
 
  dagEventHandler(event: DagEvent) {
    if (this.eventMapping.hasOwnProperty(event.type)) {
      this.eventMapping[event.type].call(this, ...event.args)
    } else {
      this.logger.warn(`Unhandled event: ${event.type}`)
    }
  }

  openConstants(){
    this.dbDialog = this.dialog.open(PipelineConstantComponent)
  }

  async openschdeuler(){
    let response:any = await this.pipelineService.getschedulePipepline(this.pipelineId as number).toPromise()
    this.dbDialog = this.dialog.open(ScheduleComponent, {panelClass: 'custom-dialog-container',data: response.payload})
  }

  openNotifications(){
    this.dialog.open(NotificationRecipientComponent,{data:{
      context:{
        pipelineId:this.pipelineId
      }}
    });
  }

  hasSchema:boolean = false;
  schema:any;

  // open_schema():Promise<{hasSchema:boolean,schema:any}>{

  //   return new Promise((resolve, reject) => {
  //     this.dialogService.open(MultipleSheetSchemaComponent,{
  //       context:{
  //         schemaResponse: [],
  //         hasSchema:this.hasSchema
  //       }
  //     }).onClose.toPromise().then((response:any)=>{
  //       this.logger.log(response);

  //       if(!response) {
  //         resolve({ hasSchema:false, schema:{}})
  //       }

  //       switch(response.state) {
  //       case 'update':
  //         this.hasSchema = true; // FIXME: should not change internal state, or add reset callback when child component is unmounted.
  //         this.schema = response;
  //         resolve({ hasSchema:true, schema:response.schemaConfigs.schemaDetails})
  //         break;

  //       case 'no-change':
  //         resolve({hasSchema:false, schema:{}})
  //         break;

  //       case 'delete':
  //         resolve({ hasSchema: false, schema: {}})
  //         break;

  //       default:
  //         resolve(response)
  //       }

  //     })
  
  //   })

   
  // }

  // edit_schema(schemaProperties:{hasSchema:boolean,schema:any}):Promise<{hasSchema:boolean,schema:any}>{
  //   console.log(schemaProperties);
  //   this.schema = schemaProperties.schema as Array<schema_sheet>
  //   return new Promise((resolve) => {
  //     return this.dialogService.open(MultipleSheetSchemaComponent,{
  //       context:{
  //         schemaResponse: JSON.parse(JSON.stringify(schemaProperties.schema)),
  //         hasSchema:schemaProperties.hasSchema
  //       }
  //     }).onClose.toPromise().then((response:any)=>{
  //       //console.log(response);
  //       console.log(this.schema,schemaProperties,response);
  //       if(!response) {
  //         return resolve({
  //           hasSchema:false,
  //           schema:{}
  //         })
  //       }

  //       switch(response.state){
        
  //       case 'no-change':{
  //         this.hasSchema = true;
  //         resolve({
  //           hasSchema: this.hasSchema,
  //           schema: this.schema
  //         })
  //       }
  //         break;

  //       case 'delete':{
  //         resolve({
  //           hasSchema: false,
  //           schema: {}
  //         })}
  //         break;

  //       case 'update':{
  //         this.hasSchema = true;
  //         this.schema = response;
  //         resolve({
  //           hasSchema:true,
  //           schema:response.schemaConfigs.schemaDetails
  //         })
  //       }
  //         break;

  //       default:{
  //         resolve(schemaProperties)
  //       }
  //       }
  //     })
  //   })
    

  // }

  delete_schema(){
    this.schema = {
      id: this.schema.id,
      action: "delete",
      schema: this.schema?.schema || []  
    }
    this.hasSchema = false;
  }

  deleteNode(nodes: number[]) {
    let q = nodes.map(nodeId => this.task_service.deleteNode(nodeId).toPromise())
    
    Promise.all(q).then(_ => this.refreshDag())
  }

  recentConfigs: Array<ConfigModel> = []
  currentHoverNode?:Node
  async showRecentUsedTaskTypes(nodeId: string, event: MouseEvent, setPortal: (portal:  TemplatePortal<any>, portalConfigs?: any)=>any) {
    let node = this.dashboardService.getNodeById(nodeId as unknown as number)!;
    this.currentHoverNode = node
    this.dashboardService.setCurrentNode(node)

    const configs = await this.dashboardService.getRecentUsedTaskTypes(node.nodeName); // TODO: check node ConfigType (node?.nodeName) as per V2 API Response
    this.recentConfigs = configs
    const connectorRect = (event.target as HTMLDivElement).getBoundingClientRect()

    if(configs.length === 0) return 

    setPortal(this.templatePortal, {top: connectorRect.y+Math.floor(connectorRect.width/2) + 'px', left: connectorRect.x + connectorRect.width + 'px', transform: 'translateY(-50%)', margin: 0, padding: '0 0 0 .5rem'})
  }

  async updatePipelineName(name: string) {
    this.logger.log(`Updating pipeline name: %c${this.pipeline?.name} %c--> %c${name}`, 'color: red', 'color:unset', 'color:green')
    await this.pipelineService.updatePipelineName({pipelineId: this.pipeline?.id as number, name}).toPromise()
    if(this.pipeline)this.pipeline.name = name
  }

  openWindow() {
    let dialogRef = this.dialog.open(DownloadFormComponent, { hasBackdrop: true, panelClass: 'custom-dialog-container', minHeight:'20vh', minWidth: '40vw',} )

    dialogRef.componentInstance.fileConfigs = {
      name: this.dashboardService.getCurrentPipeline()?.name || 'pipeline_export'
    }

    dialogRef.componentInstance.onDownload.subscribe(fileName => {
      let pipeline = this.dashboardService.getCurrentPipeline()
      this.dashboardService.downloadJson(this.context.getApiConfig().PIPELINE_APIS.GET.export+ "?pipelineId=" + pipeline?.id, fileName || pipeline?.name+"_export")
    })
  }

  connectNodes(sourceNodeId: number, targetNodeId: number, sourceFieldId: string, info: {} = {}) {
    // console.log(arguments)

    const node = this.dashboardService.getNodeById(targetNodeId)
    const dependentTypes = getDefaultFieldsName(node?.nodeName|| '') // TODO: check node ConfigType (node?.nodeName) as per V2 API Response
    const sourceNode = this.dashboardService.getNodeById(sourceNodeId)
    const targetNode = this.dashboardService.getNodeById(targetNodeId)
    console.log(sourceNode)

    const actions = {
      close: () => {
        popupRef.close(null)
      },
      submit: (option: string) => {
        popupRef.close(option)
      }
    }
    const popupRef = this.dialog.open(this.getConnectionTypeComponent, {data: {actions, info, options:dependentTypes, selected: dependentTypes[0], sourceNode, targetNode}, panelClass: 'custom-dialog-container'})

    popupRef.afterClosed().subscribe(async res => {
      if(!res) return;
      // this.dashboardService.addEdge(sourceNodeId, targetNodeId, res, info)
      const fields: Array<NodeInputField> = (targetNode as any).fields || [];

      fields.push({
        name: res,
        label: res,
        description: res,
        required: false,
        dataType: 'any',
        formatType: '',
        default: undefined,
        schema: undefined,
        source: {
          sourceNodeId: sourceNodeId,
          sourceFieldId: sourceFieldId
        },
        id: res,
        type: 'input',
        isError: false,
        meta: undefined
      });

      (targetNode as any).fields = fields;
      const dynamicInputField: DynamicField = {
        dataType: 'string',
        description: res,
        formatType: 'text',
        label: res,
        name: res,
        required: false,
        value: '',
        dependentOn: [
          {
            id: '-1',
            inputFieldRef: res,
            nodeInstanceId: (sourceNodeId|| -1).toString(),
            sourceFieldName: sourceFieldId,
            type: 'string'
           }
        ]
      }

      const addFieldResponse = await this.dashboardService.addDynamicField(sourceNode!, targetNode!, sourceFieldId, dynamicInputField);

      dynamicInputField.dependentOn![0].id = addFieldResponse.payload.id;

      targetNode!.dynamicInputFields = targetNode!.dynamicInputFields || {}
      targetNode!.dynamicInputFields[res] = dynamicInputField
      
      this.dashboardService.setPipeline(this.dashboardService.getCurrentPipeline()!)

    })
  }

  /**
   * 
   * @param data editor configurations
   * @returns Promise<EditorComponent>
   */
  async openCodeEditor(data: {value: string, title?: string, readOnly?: boolean, callback?:Function, [key: string]: any}): Promise<EditorComponent> {

    const config = {
      width: '80vw',
      height: '80vh',
      minHeight:'80vh', 
      minWidth: '80vw',
      maxWidth: '100vw', 
      maxHeight: '100vh',
      isFullscreen: false
    }

    const setEditorConfigs = (editorInstance: EditorComponent, popup: MatDialogRef<any, any>) => {

      editorInstance.title = data.title || 'Script'
      editorInstance.resizable = true
      editorInstance.isPopup = true
      editorInstance.isFullScreen = config.isFullscreen
      let editorEvents = editorInstance.events;

      const SAVE_AS_FILE = true
      if(SAVE_AS_FILE && data.value) {
        this.dashboardService.getAWSFileContent(data.value).toPromise().then(response => editorInstance.setValue(response.payload.data))
      }
      
      else {
        // TODO: load template from backend
        const templateCode = `#Start Imports

#End Imports

#Mandatory Function customRun  
def customRun(config_dict, dataframe):
    #Start customRun Code


    return dataframe # Return A Dataframe Object Only
    #End customRun Code

#Start Custom Functions Definitions

#End Custom Function Definitions`;
        editorInstance.setValue(data.value || templateCode)
      }

      editorEvents.pipe(filter(x => x.type === CodeMirrorComponentEvents.SAVE)).subscribe(saveEvent => {
        let [{getValue}] = saveEvent.args as [{getValue: () => string}]
        const sourceCode = getValue();
        
        if(SAVE_AS_FILE) {
          let blob = new Blob([sourceCode], {type: 'text/plain;charset=utf-8'});
          let file = this.blobToFile(blob, 'script.py')
          let formData = new FormData();

          formData.append('pipeline_id', `${this.dashboardService.getCurrentPipeline()?.id}`)
          formData.append('process_id', `${this.dashboardService.getCurrentPipeline()?.process.id}`)
          formData.append('file', file)

          this.fileService.uploadFile(formData).subscribe((res) => {
            data.value = res.payload.url
          })
        }

        else {
          data.value = sourceCode
        }

      })      

      editorEvents.pipe(filter(x => x.type === CodeMirrorComponentEvents.RESIZE)).subscribe(resizeEvent => {

        if(config.isFullscreen) {
          config.height = config.minHeight
          config.width = config.minWidth
        } else {
          config.height = config.maxHeight
          config.width = config.maxWidth
        }

        config.isFullscreen = !config.isFullscreen
        popup.updateSize(config.width, config.height)

        if(typeof resizeEvent.args[0] === 'function') {
          resizeEvent.args[0](config.isFullscreen)
        }
        
      })

      editorEvents.pipe(filter(x => x.type === CodeMirrorComponentEvents.CLOSE)).subscribe(closeEvent => {
        popup.close()
        if(data.callback) {
          data.callback(data.value)
        }
      })
      
    }

    const popup = this.dialog.open(CustomNodeEditorComponent, {hasBackdrop: false, data: {} , panelClass: 'custom-dialog-container', ...config});
    popup.addPanelClass('resizable')
    popup.componentInstance.editorConfigs = { readOnly: data.readOnly || false}

    return new Promise<EditorComponent>((resolve) => {
 
      popup.componentInstance.events.subscribe(event => {
        if(event.type === CodeMirrorComponentEvents.INIT) {
          setEditorConfigs(popup.componentInstance.editor, popup)
          resolve(popup.componentInstance.editor)
        }
      })


      /**
       * Handel user define configs
       * TODO: remove custom logic
       */
      popup.componentInstance.events.pipe(filter(x => x.type === 'UPDATE_CONFIG')).subscribe(event => {
        const customConfig = event.args[0] as IConfig;
        console.log(customConfig)
        const CUSTOM_INPUT_ID = customConfig.name || -100
        // console.log(event.args)
        
        
        if(this.activeConfiguration instanceof AddTaskComponent || this.activeConfiguration instanceof UpdateTaskComponent) {

          const oldInputs = this.activeConfiguration.formInputs.filter(x => x.id === CUSTOM_INPUT_ID);
          oldInputs.forEach(x => this.activeConfiguration?.removeInputField(x))


          for(let input of customConfig.children||[]) {
            const textInputField: InputFieldType = {
              hidden: false,
              id: CUSTOM_INPUT_ID,
              type: ['select', 'map'].includes(input.inputType||'')? 'dropdown' : (input.inputType === 'text' ? 'text': (input.inputType === 'file' ? 'file' : 'text')),
              multiple: false,
              _value: '',
              display_text: input.name,
              placeholder: input.inputType || '',
              options: input.inputType === 'select' ? input.children?.map(x => ({id: x.name, isSelected: false, value: x.name}))|| []: [],
              isValid() {
                return true
              },
              async getValue() {
                return Promise.resolve({
                  configKeyId: CUSTOM_INPUT_ID,
                  configValueId: null,
                  value: textInputField._value
                })
              }
            }

            if(input.inputType === 'map') {
              textInputField.buttons = [
                {
                  name: 'API Mapping',
                  disable: false,
                  hidden: false,
                  status: "primary",
                  marginLeft: 'auto',
                  class: 'button button-basic',
                  click: () => {
                    console.log('un-implemented method')
                  }
                }
              ]
            }
            this.activeConfiguration.addInputField(textInputField)
          }
          
        }

      })

  
      if(data.customInputs && data.customInputs.length > 0) {
        try {

          for(let customConfig of data.customInputs||[]) {
            popup.componentInstance.addConfigGroup(customConfig.display_text)
            let inputs = customConfig?.__value?.inputs || [];
            this.logger.log(`Loading: [${customConfig.display_text}] ...`)
  
            inputs.forEach((input: ConfigKeyModel) => popup.componentInstance.addConfig(popup.componentInstance.inputToConfig(input), customConfig.display_text))
          }
        } catch {console.error('Failed to parse, customConfig');}
  
      }

    })

  }

  public blobToFile = (theBlob: Blob, fileName:string): File => {
    let b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return <File>theBlob;
  }
  selectedNodes(isSelectEnabled:boolean, selectedNodes: any[], dagState: DagState) {
    this.selectEnabled = isSelectEnabled
    this.dagState.undo = dagState.state.dragHistory.length > 0
    this.dagState.redo = dagState.state.dragRedoHistory.length > 0
    this.dagState.save = !dagState.isSaved
    //console.log(isSelectEnabled);
  }

  openEdgeMapper(sourceField: any, targetField: any) {
    const dialogRef = this.dialog.open(DataMapperComponent, {panelClass: 'custom-dialog-container', width: '90%', height: '90%', maxWidth: '100vw', maxHeight: 'calc(100vh - 3rem)'})
    dialogRef.componentInstance.config = {
      sourceItems: [],
      targetItems: []
    }
    dialogRef.componentInstance.edgeMap = {}
  }

  $selectNodeAction =  new Subject<{type: 'DRAG' | 'EDIT' | 'SAVE_AS_TEMPLATE' | 'DELETE' | 'CLEAR', args: Array<unknown> }>();

  selectedNodesActions(action_type:string){
    switch(action_type){
    case "clear":
      this.selectEnabled = false;
      this.$selectNodeAction.next({type:'CLEAR',args:[]});
      break;
    case "delete":
      this.$selectNodeAction.next({type:'DELETE',args:[]});
      this.selectEnabled = false;
      break;
    case "drag":
      this.$selectNodeAction.next({type:'DRAG',args:[]});
      break;
    }
  }

  pasteNode(){
    let copyNode = JSON.parse(localStorage.getItem("copiedNodeId") || '');
    if(copyNode != ''){
      this.openCopyNodeConfigs(copyNode);
    }
  }

  async openCopyNodeConfigs(nodeId: number){


    /* FIXME: Update Copy Node Logic as per V2 API 

    this.isConfigNavLoading = true
    this.enableAddButton = true
    let node = this.dashboardService.getNodeById(nodeId) as Node
    this.dashboardService.setCurrentNode(node)
    //this.currentNode = this.dashboardService.getNodeById(nodeId)
    let configType = node.taskConfigDetails
    console.log(configType)
    this.openCopyTask(configType, node.id, node.incomingTaskIds,this.pipeline?.id as number, this.pipeline?.processId as number)
    let configData = await this.nodeService.getNodeConfig(node.id, node.incomingTaskIds).toPromise()
    this.currentNodeConfig = configData

    // */
  }

  /*
    currentNodeConfig: any = null
  async openNodeConfig(nodeId: number) {
    this.isConfigNavLoading = true
    this.enableAddButton = true
    let node = this.dashboardService.getNodeById(nodeId) as Node
    this.dashboardService.setCurrentNode(node)
    this.currentNode = this.dashboardService.getNodeById(nodeId)
    let configType = node.taskConfigDetails
    this.openUpdateTask(configType, node.id, this.pipeline?.id as number, this.pipeline?.processId as number)
    let configData = await this.nodeService.getNodeConfig(node.id, node.incomingTaskIds).toPromise()
    this.currentNodeConfig = configData
  }
  */

  
  runPipeline(){
    // let pipelineId = this.dashboardService.getCurrentPipeline()?.id
    // let processId= this.dashboardService.getCurrentPipeline()?.process.id
    const dialogData = new ConfirmationPopupModel('RUN PIPELINE', 'Do you want to Run the Pipeline?');
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      maxWidth: '400px',
      closeOnNavigation: true,
      data: dialogData
    })
    dialogRef.afterClosed().subscribe(async dialogResult => {
      if (dialogResult) {
        this.isProgress= true
        try {
          const response = await this.pipelineService.triggerPipeline(this.pipelineId, this.pipelineVersionId).toPromise()
          if(response.status !== 'success') {
            this.isProgress = false
            return
          }
          if(response.status === 'success') {
            this.uiService.showInfo('Run request submitted!', 'success')
          }
        }
        catch {
          this.uiService.showError('Failed to run pipeline!')
          this.isProgress = false
       
        }
      }
    });
   
  }
  stopPipeline()
  {
    let pipelineId = this.dashboardService.getCurrentPipeline()?.id
    let processId= this.dashboardService.getCurrentPipeline()?.process.id
    const dialogData = new ConfirmationPopupModel('STOP PIPELINE', 'Do you want to STOP the Pipeline?');
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      maxWidth: '400px',
      closeOnNavigation: true,
      data: dialogData
    })
    dialogRef.afterClosed().subscribe(async dialogResult => {
      if (dialogResult) {
        this.isProgress= true
        try {
          const response = await this.pipelineService.stopSequentialPipeline(pipelineId,processId).toPromise()
          if(response.status !== 'success') {
            this.isProgress = false
            return
          }
          if(response.status === 'success') {
            this.uiService.showInfo('Stop request submitted!', 'success')
          }
        }
        catch {
          this.uiService.showError('Failed to stop pipeline!')
          this.isProgress = false
       
        }
      }
    });
  }

//dag events trigger
$dagEvents = new Subject<{type: 'DEFAULT' | 'DRAG_ENABLE' | 'RESET' | 'UNDO' | 'REDO' | 'SAVE', args: Object }>();

triggerDagEvents(event:'DEFAULT' | 'DRAG_ENABLE' | 'RESET' | 'UNDO' | 'REDO' | 'SAVE',args:unknown){
  switch(event){
  case 'DRAG_ENABLE':{
    this.dragEnabled = !this.dragEnabled
    if(!this.dragEnabled) this.selectEnabled = false
    this.$dagEvents.next({type:event,args:{drag_enabled:this.dragEnabled}});
  }
    break;
  case 'DEFAULT':{
    this.$dagEvents.next({type:event,args:{}});
    this.dragEnabled = false
  }break;
  default:
    this.$dagEvents.next({type:event,args:{}})
    break;
  }
}

// FIXME: move variable up, define variable dataType, move to service
connectors: any = null

async selectNodePopup(event:MouseEvent){
  event.preventDefault();
  this.uiService.showSideNav = false;
  //this will call getNode api and will give the selected node
  //let selectedNode
  if(!this.connectors) {
    const connectors = await this.connectorService.getConnectors().toPromise();
    this.connectors = connectors.payload.data.map((x:any)=>{
      return {...x,displayName:x.label,type:'source'}
    });
  }


  console.log('connectors', this.connectors);

  let dialogRef = this.dialog.open(EdgeconnectorComponent, {
    hasBackdrop: true,
    height:'calc(100vh - 100px)',
    width:'500px',
    panelClass: 'custom-dialog-container',
    data: {configs: this.connectors, title: 'Add Node'}
  })

  // let nodeName:string =  await dialogRef.afterClosed().toPromise();
  let connectorSelected:Connector =  await dialogRef.afterClosed().toPromise();
  console.log('connector selected',connectorSelected);
  if(connectorSelected)
  {
    let newNode:{nodeId:string,pipelineVersion:PipelineVersion} = await this.dialog.open(AddNodeComponent, {data: {connectorSelected,pipelineId:this.pipelineDetails.payload.version.pipelineId, pipelineVersion:this.pipelineDetails.payload.version.id}}).afterClosed().toPromise();
    if(newNode){
    
    let testingNode:Node = {
      id:-1,
      nodeId: newNode.nodeId,
      pipelineId: newNode.pipelineVersion.pipelineId,
      parentId: null,
      isReference: false,
      name: newNode.pipelineVersion.config.nodes[newNode.nodeId].name,
      label: newNode.pipelineVersion.config.nodes[newNode.nodeId].label,
      nodeName: newNode.pipelineVersion.config.nodes[newNode.nodeId].connector,
      connectionInstanceId: null,
      connection: null,
      dynamicInputFields: null,
      outputFields: null,
      serviceName: null,
      inputConfigs: null,
      status: "",
      createdTime: "",
      updatedTime: ""
    }

    this.dashboardService.addNode(testingNode)

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NodeSettingsComponent);
    const viewContainerRef = this.detailsHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<NodeSettingsComponent>(componentFactory);
    this.uiService.showSideNav = true
    // componentRef.instance.newNodeResponse={connector:connectorSelected,newNode};
    componentRef.instance.output.subscribe(event=>{
      if(event.type==='CLOSE'){
        this.uiService.showSideNav = false;
        viewContainerRef.clear();
      }
    })
    }
  }

  //test
  // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NodeSettingsComponent);
  //   const viewContainerRef = this.detailsHost.viewContainerRef;
  //   viewContainerRef.clear();

  //   const componentRef = viewContainerRef.createComponent<NodeSettingsComponent>(componentFactory);
  //   this.uiService.showSideNav = true

 
}



}

<!--table datatable [dtOptions]="dtOptions" class="row-border hover">
    <thead>
      <tr>
            <th *ngFor="let col of cols">{{col}}</th>
      </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of data">
            <td *ngFor="let cell of row">
                    {{cell}}
            </td>
    </tr>
    </tbody>
  </table-->
  <label for="file">File</label>
  <input type="file" (change)="onFileChange($event)" multiple="false" />
  
  <table *ngIf="dtRendered" datatable [dtOptions]="dtOptions" class="row-border hover">
     
  </table>
<nb-card>
    <nb-card-header>
        New Connection
        <button nbButton (click)="close()" style="float: right;"><nb-icon icon="close-outline"></nb-icon></button>
    </nb-card-header>
    <br>
    <nb-card-body>
      <form [formGroup]="mysqlDbForm">
        <table>
          <tr>
            <td>Identifier:</td>
            <td><input type="text" nbInput formControlName="identifier"></td>
          </tr>
          <tr>
            <td>Client id</td>
            <td><input type="text" nbInput formControlName="client_id"></td>
          </tr>
          <tr>
            <td>IP/Domain:</td>
            <td><input type="text" nbInput formControlName="ip_address"></td>
          </tr>
          <tr>
            <td>Port:</td>
            <td><input type="text" nbInput formControlName="port"></td>
          </tr>
          <tr>
            <td>Username:</td>
            <td><input type="text" nbInput formControlName="username"></td>
          </tr>
          <tr>
            <td>Password:</td>
            <td><input type="text" nbInput formControlName="password"></td>
          </tr>
        </table>
      </form>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
      <button nbButton (click)="validate();">Validate</button>
      <button nbButton  [hidden]="newConnectionId" (click)="createConnection();">Save</button>
      <button nbButton [hidden]="!newConnectionId" (click)="updateConnection();">Update</button>
      
      <!-- <button nbButton (click)="ref.close()">Close Dialog</button> -->
    </nb-card-footer>

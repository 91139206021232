import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Context} from "../../services/config/context.service";
import {MatDialog} from "@angular/material/dialog";

export const STRING_KEY_VALUE_TYPE = Symbol('string-key-value')

@Component({
  selector: 'app-view-json',
  templateUrl: './view-json.component.html',
  styleUrls: ['./view-json.component.css']
})
export class ViewJsonComponent implements OnInit, OnChanges {

  STRING_KEY_VALUE_TYPE: typeof STRING_KEY_VALUE_TYPE = STRING_KEY_VALUE_TYPE

  @Input() data: any = null
  @Input() hideMenu: boolean = false
  @Input() title: string = ''
  @Input() selectedTabName!: string

  @Input() error!: string

  isLoading:boolean = true

  toolbar!: {selectedTabName: string, options:  Array<{name: string, key?:string}>}

  constructor() {
    let self = this
    this.toolbar = {
      get selectedTabName () {
        return self.selectedTabName
      },
      set selectedTabName (value) {
        self.selectedTabName = value
      },
      options: []
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.data) {
      this.isLoading = false
      const keys = Object.keys(changes.data.currentValue);
      this.toolbar.options = keys.map(x => ({name: x}))
      if(changes.data.firstChange) {
        this.toolbar.selectedTabName = keys[0]
      }
    }
  }

}

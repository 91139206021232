import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {key} from "ngx-bootstrap-icons";
import {FormArray, FormControl, FormGroup} from "@angular/forms";

export enum dataTypes {
  list = "list",
  object = "object",
  string = "string",
  integer = "integer",
  decimal = "decimal",
  boolean = "boolean",
  array_in_string = "array_in_string",
  dict_in_string = "dict_in_string",
  date_time_timestamp = "dt_timestamp",
  date_time_string = "dt_string"
}

export enum keysOfObject {
  name = 'key',
  data_type = 'data_type',
  default_value = 'default_value',
  is_mandatory = 'is_mandatory',
  is_null_allowed = 'is_null_allowed',
  is_blank_allowed = 'is_blank_allowed',
  skip_field = 'skip_field',
  sub_type = 'sub_type',
  fields = 'fields',
  $id = 'id'
}

export type Obj = {
  [keysOfObject.$id]?: number
  [keysOfObject.name]: string
  [keysOfObject.data_type]: dataTypes
  [keysOfObject.default_value]: string
  [keysOfObject.is_mandatory]: boolean
  [keysOfObject.is_null_allowed]: boolean
  [keysOfObject.is_blank_allowed]: boolean
  [keysOfObject.skip_field]: boolean
  [keysOfObject.sub_type]?: dataTypes
  [keysOfObject.fields]?: Array<Obj>
}

export enum ActionType {
  add = 'add',
  delete = 'delete',
  update = 'change'
}

const defaultInitialObject: Obj = {
  [keysOfObject.name]: '',
  [keysOfObject.data_type]: dataTypes.string,
  [keysOfObject.default_value]: '',
  [keysOfObject.is_mandatory]: false,
  [keysOfObject.is_null_allowed]: true,
  [keysOfObject.is_blank_allowed]: true,
  [keysOfObject.skip_field]: true,
  [keysOfObject.sub_type]: dataTypes.string,
  [keysOfObject.fields]: []
}

const nameAlias:{[key in keyof Obj]: string} = {
  [keysOfObject.name]: 'Name',
  [keysOfObject.data_type]: 'Data type',
  [keysOfObject.default_value]: 'Default value',
  [keysOfObject.is_mandatory]: 'Is mandatory',
  [keysOfObject.is_null_allowed]: 'Is null allowed',
  [keysOfObject.is_blank_allowed]: 'Is blank allowed',
  [keysOfObject.skip_field]: 'Skip field',
  [keysOfObject.sub_type]: 'Sub data type',
  [keysOfObject.fields]: 'Fields'
}

@Component({
  selector: 'app-edit-api',
  templateUrl: './edit-api.component.html',
  styleUrls: ['./edit-api.component.css']
})
export class EditApiComponent implements OnInit {
  @Input() dataTypes = Object.keys(dataTypes)
  keysOfDataTypes = dataTypes
  keysOfObject = keysOfObject
  nameAlias = nameAlias

  @Input() showTitle = true
  @Output() event = new EventEmitter<{type: string, args:unknown[]}>()
  @Input() form: Array<{ title: string, fields: Array<Obj> }> = []
  @Input() objOrder: Array<keysOfObject> = [
    keysOfObject.name,
    keysOfObject.data_type,
    keysOfObject.sub_type,
    keysOfObject.default_value,
    keysOfObject.is_mandatory,
    keysOfObject.is_null_allowed,
    keysOfObject.is_blank_allowed,
    keysOfObject.skip_field,
    keysOfObject.fields
  ]

  fc = new FormGroup({})
  constructor() {
  }

  ngOnInit(): void {
    this.form.forEach(form => {
      let fc = new FormArray([])
      this.addField(fc, form.fields)
      this.fc.addControl(form.title, fc)
    })
    this.event.emit({type: 'init', args:[this.fc.value, this.getResultWithoutId()]})

    this.fc.valueChanges.subscribe(data => {
      this.removeInvalidChildElements(this.fc)
      this.sendChangeEvent()
    })
  }

  addField(form: FormArray, objs: Obj[], markDirty:boolean=false) {
    objs.forEach(obj => {
      let formGroup:{[key: string]: FormControl|FormArray} = {}
      let fields = new FormArray([])
      this.addField(fields, obj[keysOfObject.fields]||[])
      this.objOrder.filter(x => x !== keysOfObject.fields).forEach(key => {
        formGroup[key] = new FormControl(obj[key]);
        if(markDirty)formGroup[key].markAsDirty()
      })
      formGroup[keysOfObject.fields] = fields
      formGroup[keysOfObject.$id] = new FormControl(obj[keysOfObject.$id]||null)
      let formGroupInstance = new FormGroup(formGroup)
      formGroupInstance.markAsDirty()
      form.push(formGroupInstance)
    })
  }


  removeInvalidChildElements(form: any) {
    if(form.controls && Array.isArray(form.controls)) {
      Object.keys(form.controls).forEach(index => {
        this.removeInvalidChildElements(form.controls[index])
      })
    }

    if(form.controls && !Array.isArray(form.controls) && form.controls[keysOfObject.fields]) {
      if(form.controls[keysOfObject.data_type].value!==dataTypes.object&&form.controls[keysOfObject.sub_type].value!==dataTypes.object) {
        let ids:Array<any> = (form.controls[keysOfObject.fields] as FormArray).controls.map(el => el.value[keysOfObject.$id]);
        this.sendDeleteEvent(ids);

        (form.controls[keysOfObject.fields] as FormArray).clear();
        // while (form.controls[keysOfObject.fields].length !== 0) {
        //   form.controls[keysOfObject.fields].removeAt(0)
        // }
      } else {
        this.removeInvalidChildElements(form.controls[keysOfObject.fields])
      }
    }

    if(form.controls && !Array.isArray(form.controls) && !form.controls[keysOfObject.fields]) {
      Object.keys(form.controls).forEach(key => {
        this.removeInvalidChildElements(form.controls[key])
      })
    }
  }

  getDirtyValues(form: any) {
    let isArray = Array.isArray(form.controls)
    let dirtyValues:any = Array.isArray(form.controls)?[]:{}

    if(form.controls[keysOfObject.$id]?.value) {
      dirtyValues[keysOfObject.$id]= form.controls[keysOfObject.$id]?.value
    }

    Object.keys(form.controls)
      .forEach(key => {
        const currentControl = form.controls[key];

        if (currentControl.dirty) {
          let data = undefined
          if (currentControl.controls) data = this.getDirtyValues(currentControl)
          else data = currentControl.value
          if(isArray) {
            dirtyValues.push(data)
          } else {
            dirtyValues[key] = data
          }
        }
      })

    return dirtyValues;
  }

  getFormAsArray(form: FormGroup, key: string) {
    return (form.get(key) as FormArray) || new FormArray([])
  }

  sendChangeEvent() {
    let changes = this.getDirtyValues(this.fc)
    this.event.emit({type: ActionType.update, args:[changes, this.fc.value, this.getResultWithoutId()]})
  }

  sendDeleteEvent(ids: Array<number|null>) {
    if(!Array.isArray(ids) || ids.length===0) return
    this.event.emit({
      type: 'action', args: ids.map(id => ({[keysOfObject.$id]: id, action: ActionType.delete}))
    })
  }

  sendAddEvent(ids: Array<number|null>) {
    if(!Array.isArray(ids) || ids.length===0) return
    this.event.emit({
      type: 'action', args: ids.map(id => ({['parent_'+keysOfObject.$id]: id, action: ActionType.add}))
    })
  }

  removeFormItem(formArray: FormArray, item: FormGroup|any, event: any) {
    event.stopPropagation()
    this.sendDeleteEvent([item.controls[keysOfObject.$id].value])
    let index = formArray.controls.indexOf(item)
    formArray.removeAt(index)
  }

  addNewField(parent: FormGroup, key:string) {
    if(!parent.controls[key])parent.addControl(key, new FormArray([]))
    this.addField((parent.controls[key] as FormArray), [{...defaultInitialObject, fields: []}], true)
    parent.controls[key].markAsDirty()
    parent.markAsDirty()
    this.sendAddEvent([parent.controls[keysOfObject.$id]?.value||(key===keysOfObject.fields?null:key)])
    this.sendChangeEvent()
  }

  getResultWithoutId() {
    let data = JSON.parse(JSON.stringify(this.fc.value))
    Object.keys(data).forEach(key => {
      data[key] = this._getResultWithoutId(data[key])
    })
    return data
  }
  _getResultWithoutId(data: any) {
    data.forEach((el: any) => {
      el[keysOfObject.$id] = undefined
      el[keysOfObject.fields] = this._getResultWithoutId(el[keysOfObject.fields])
    })
    return data
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Context } from 'src/app/services/config/context.service';
import { DashboardStoreService } from 'src/app/services/store/dasboard-store.service';
import {PipelineAPIResponse, PipelineService} from 'src/app/services/api/pipeline.service';

@Component({
  selector: 'app-download-form',
  templateUrl: './download-form.component.html',
  styleUrls: ['./download-form.component.css']
})
export class DownloadFormComponent implements OnInit {

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDownload:EventEmitter<string> = new EventEmitter<string>()
  @Input() fileConfigs: {name: string} = {name: ''}
  

  constructor(
    private context: Context,
  ) { }

  ngOnInit(): void {
  }
  
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Local packages
import { CreatePipelineComponent } from '../app/pages/create-pipeline/create-pipeline.component';
import { MainComponent } from '../app/pages/main/main.component';
import { ViewPipelineComponent } from '../app/pages/view-pipeline/view-pipeline.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DagComponent} from './components/dag/dag.component';
import { ExitConfirmGuard } from './guards/exit-confirm.guard';
import {TaskHistoryComponent} from "./pages/task-history/task-history.component";
import { CreateGroupComponent } from './pages/create-group/create-group.component';
import { ViewGroupComponent } from './pages/view-group/view-group.component';
// import { CreateConfigComponent } from './pages/create-config/create-config.component';
import { LoginComponent } from './pages/login/login.component';
import {AuthGuardGuard} from "./guards/auth-guard.guard";
import { PipelineTimelineComponent } from './components/pipeline-timeline/pipeline-timeline.component';
import { PipelineHistoryComponent } from './pages/pipeline-history/pipeline-history.component';
import { NodesOutputComponent } from './pages/output/nodes-output/nodes-output.component';
import { DataMapperComponent } from './components/data-mapper/data-mapper.component';
import { CodeEditorOptionsComponent } from './components/code-editor-options/code-editor-options.component';
import { CodeEditorComponent } from './components/code-editor/code-editor.component';
import { NodeSettingsComponent } from './pages/dashboard/node-settings/node-settings.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';

import { ConnectionsComponent } from './pages/connections/connections.component';
import { CellEditorComponent } from 'ag-grid-community/dist/lib/components/framework/componentTypes';
import { EditCellComponent } from '../app/components/upload-utility-basic-config/edit-cell/edit-cell.component';
import { UploadUtilityBasicConfigComponent } from './components/upload-utility-basic-config/upload-utility-basic-config.component';
import { PipelineDetailsComponent } from './components/pipeline-details/pipeline-details.component';
import { NodeEditorComponent } from './pages/node-editor/node-editor.component';
import { CustomNodeComponent } from './pages/custom-node/custom-node.component';
import { FileExplorerComponent } from './components/file-explorer/file-explorer.component';
import { WebComponentComponent } from './web-component/web-component.component';
import { SidebarPipelinesComponent } from './pages/sidebar-pipelines/sidebar-pipelines.component';
import { PipelineListingComponent } from './components/pipeline-listing/pipeline-listing.component';
import { GroupListComponent } from './pages/group-list/group-list.component';
import { GroupDetailComponent } from './pages/group-detail/group-detail.component';
import { GroupFolderDetailComponent } from './pages/group-folder-detail/group-folder-detail.component';
import { SelectnodeComponent } from './components/selectnode/selectnode.component';
import { AccountDetailsComponent } from './pages/account-details/account-details.component';
import { AccountListComponent } from './pages/account-list/account-list.component';
import { TopicsComponent } from './pages/topics/topics.component';
import { TemplatesComponent } from './pages/templates/templates.component';
import { SidebarTemplatesComponent } from './pages/sidebar-templates/sidebar-templates.component';
import { TemplateConfigurationsComponent } from './pages/template-configurations/template-configurations.component';
import { ConnectionPageComponent } from './pages/connection-page/connection-page.component';
import { MainDashboardComponent } from './pages/main-dashboard/main-dashboard.component';
import { TopicDetailComponent } from './pages/topic-detail/topic-detail.component';

// Constants
const routes: Routes = [
  {path:'', redirectTo: '/pipelines(view:pipeline-list)', pathMatch: 'full'},
  {path: 'login', component:LoginComponent,data:{v2:true,fullScreen:'old',title:'Login'}},

  // {path: 'view-pipeline', component: ViewPipelineComponent, canActivate:[AuthGuardGuard]},
  // {path: 'view-pipeline/group/:group_id', component: ViewPipelineComponent},
  // {path: 'dag', component: DagComponent},
  // {path: 'create-configs', component: CreateConfigComponent},
  // {path: 'home', component:MainComponent, canActivate:[AuthGuardGuard]},


  // TODO: update create pipeline apis
  // {path: 'create-pipeline', component: CreatePipelineComponent},
  // {path: 'create-group', component: CreateGroupComponent},
  // {path: 'view-group', component:ViewGroupComponent},
  {path: 'view-pipeline/:id/task-history', component:PipelineHistoryComponent, data: {title: 'Process History'}, canActivate:[AuthGuardGuard]},
  // {path: 'connections', component: ConnectionsComponent},
  {path: 'more/custom-node', component: CustomNodeComponent},
  {path: 'more/custom-node/:id/:versionId',component:NodeEditorComponent}
];

// routes.push({path: '__TEST__', component: UploadUtilityBasicConfigComponent})
// routes.push({path:'cell-edit-test', component:EditCellComponent})
// routes.push({path: '__TEST__', component: WebComponentComponent})
// routes.push({path: 'mytest', component: PipelineDetailsComponent})
// routes.push({path: 'mytest', component: SelectnodeComponent})
// routes.push({path: '__test__', component: TopicsComponent})

// new layout routes
const routesV2:Routes = [
  // { path: "", redirectTo: "/pipelines", pathMatch: "full" },
  { path: "pipelines",component:SidebarPipelinesComponent ,canActivate:[AuthGuardGuard],data:{v2:true}},
  { path: "templates",component:SidebarTemplatesComponent , canActivate:[AuthGuardGuard],data:{v2:true}},
  { path: "pipeline-list", component: PipelineListingComponent, canActivate:[AuthGuardGuard], outlet: "view",data:{v2:true} },
  { path: "pipeline-details/:id", component:PipelineDetailsComponent, canActivate:[AuthGuardGuard], outlet:"view",data:{v2:true}},
  { path: "connection-form/:form-id", component:PipelineDetailsComponent, canActivate:[AuthGuardGuard], outlet:"action",data:{v2:true}},
  {path: "upload", component: UploadUtilityBasicConfigComponent, canActivate:[AuthGuardGuard], outlet:"view",data:{v2:true}},
  {path: 'cell', component: EditCellComponent, canActivate:[AuthGuardGuard], outlet:"view", data:{v2:true}},
  { path: "groups", component:GroupListComponent, canActivate:[AuthGuardGuard], outlet:"view",data:{v2:true}},
  { path: "groups/:id", component:GroupDetailComponent, canActivate:[AuthGuardGuard], outlet:"view",data:{v2:true}},
  { path: "directory/:directoryId", component:GroupFolderDetailComponent, canActivate:[AuthGuardGuard], outlet:"view",data:{v2:true}},
  { path: "accounts", component:AccountListComponent, canActivate:[AuthGuardGuard], data:{v2:true,fullScreen:'fullScreen'}},
  { path: "connections", component:ConnectionPageComponent, canActivate:[AuthGuardGuard], data:{v2:true,fullScreen:'fullScreen'}},
  { path: "dashboard", component:MainDashboardComponent, canActivate:[AuthGuardGuard], data:{v2:true,fullScreen:'fullScreen'}},
  { path: "accounts/:accountId", component:AccountDetailsComponent, canActivate:[AuthGuardGuard], data:{v2:true,fullScreen:'fullScreen'}},
  { path: "topic", component:TopicsComponent, canActivate:[AuthGuardGuard], data:{v2:true,fullScreen:'fullScreen'}},
  { path: "topic-details/:topicId", component:TopicDetailComponent, canActivate:[AuthGuardGuard], data:{v2:true,fullScreen:'fullScreen'}},
  { path: "view-templates", component:TemplatesComponent, canActivate:[AuthGuardGuard], data:{v2:true}, outlet:"view"},
  { path: "template-details/:templateId", component:TemplateConfigurationsComponent, canActivate:[AuthGuardGuard], outlet:"view", data:{v2:true}},
  { path: 'dashboard/:id/:version', component: DashboardComponent, canDeactivate:[ExitConfirmGuard], data: {title: 'Dashboard', v2: true, fullScreen:'fullScreen'}, canActivate:[AuthGuardGuard]},
]
routesV2.forEach(route=>{
  routes.push(route)
})

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation:'reload'})],
  exports: [RouterModule],
  providers: [AuthGuardGuard]
})
export class AppRoutingModule { }

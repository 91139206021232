import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { SideNavService } from 'src/app/services/core/sidenav.service';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common'

@Component({
  selector: 'app-topic-detail',
  templateUrl: './topic-detail.component.html',
  styleUrls: ['./topic-detail.component.scss']
})
export class TopicDetailComponent implements OnInit {

  constructor(
    public portalBridge: SideNavService,
    private viewContainerRef: ViewContainerRef,
    private location: Location,
  ) { }

  @ViewChild('portalContent', { static: true })
  portalContent: any
  subscriptions: Array<Subscription> = [];
  ngOnInit(): void {
  }
  tabNumber = 0;
  customEventsHandler(event: any){
    if(event.detail.type === 'close'){
      this.closeSidebar();
    }
    else if(event.detail.type === 'view-request'){
      this.tabNumber = 0;
      this.viewMessageDetails();
    }
    else if(event.detail.type === 'view-response'){
      this.tabNumber = 1;
      this.viewMessageDetails();
    }
    else if(event.detail.type === 'back'){
      this.goBack()
    }
  }

  goBack(){
    this.location.back()
  }
  closeSidebar(){
    this.portalBridge.show = false;
  }
  viewMessageDetails(){
    this.portalBridge.show = true;
    const portal = new TemplatePortal(this.portalContent, this.viewContainerRef)
    this.portalBridge.setRightNavTemplate(portal)
  }

  ngOnDestroy() {
    this.portalBridge.removeRightNavTemplate()
    this.portalBridge.show = false;
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

}

<!-- <p>topics works!</p> -->
<div style="background-color: white;">
    <topics-listing-header (headerEvents)="customEventsHandler($event)"> </topics-listing-header>
    <topics-listing-table (pageEvents)="customEventsHandler($event)" [topicsData]="filteredData"></topics-listing-table>    
    <simplify-pagination *ngIf="totalCount>10" slot="allPagination" [totalCount]="totalCount" [pageIndex]="pageIndex" [itemsPerPage]="itemsPerPage" [pageSizeOptions]="pageSizeOptions" (paginate)="onPaginationEvent($event)" class="position-sticky bottom-0 bg-white"></simplify-pagination>
</div>


<ng-template #newTopic>
    <simplify-add-new-topics-modal (addTopicEvents)="customEventsHandler($event)"></simplify-add-new-topics-modal>
</ng-template>

<ng-template #portalContent>
    <simplify-topics-sidebar (sidebarEvents)="customEventsHandler($event)" [topicsData]="foundTopic"></simplify-topics-sidebar>
</ng-template>

<!-- <ng-template #updateTopic let-data>
    <form [formGroup]="topic"
        style="flex-grow: 1;height: 100%; background-color: white;display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 1rem;">
        <h1 style="color: rgba(0, 0, 0, 0.68);margin-top: -4rem;">Update Topic</h1>

        <div style="width: max(20rem, 40%);">
            <input #topicName nbInput fullWidth placeholder="Topic Name" formControlName="name">
        </div>

        <div style="width: max(20rem, 40%);">
            <h6>Label</h6>
            <input nbInput fullWidth placeholder="Label" formControlName="label">
        </div>

        <div style="width: max(20rem, 40%);">
            <h6>Description</h6>
            <input nbInput fullWidth placeholder="Description" formControlName="description">
        </div>

        <hr style="width: max(20rem, 40%);margin: 0;">

        <div style="width: max(20rem, 40%);height: 3rem;display: flex;justify-content: space-between;">
            <div style="height: 3rem;border-radius: .5rem;">
                <button nbButton (click)="close()">Cancel</button>
                <button style="margin: 1rem;" nbButton hero status="info" type="submit" (click)="onUpdate(data.topicId)">Submit</button>
            </div>
        </div>
    </form>
</ng-template> -->

<!-- <ng-template #changeAccess let-data>
    <div
        style="flex-grow: 1;height: 100%; background-color: white;display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 1rem;">
        <div style="display:flex; align-items: center;">
            <h5 style="color: rgba(0, 0, 0, 0.68);">Change Access Level To</h5>
        </div>
        <button style="margin-left: 1rem;" outline nbTooltip="Public" nbButton size="small"
            [status]="data.foundObject.accessLevel === 'public' ? 'primary' : 'success'"
            (click)="updateAccess(data.topicInstanceId, 'public')">
            <nb-icon icon="plus-outline" style="color: #00d68f"></nb-icon>
            Public
        </button>
        <button style="margin-left: 1rem;" outline nbTooltip="Private" nbButton size="small"
            [status]="data.foundObject.accessLevel === 'private' ? 'primary' : 'success'"
            (click)="updateAccess(data.topicInstanceId, 'private')">
            <nb-icon icon="plus-outline" style="color: #00d68f"></nb-icon>
            Private
        </button>
        <button style="margin-left: 1rem;" outline nbTooltip="Protected" nbButton size="small"
            [status]="data.foundObject.accessLevel === 'protected' ? 'primary' : 'success'"
            (click)="updateAccess(data.topicInstanceId, 'protected')">
            <nb-icon icon="plus-outline" style="color: #00d68f"></nb-icon>
            Protected
        </button>
    </div>

</ng-template> -->
<div style="background-color: white;">
  <div
    style="display: flex;align-items: center;padding-left: 1rem;border-bottom: 1px solid silver;background-color: #edf1f7">
    <h6>{{showCreateAPI?'Create':'Edit'}} API</h6>
    <button nbButton style="margin-left: auto" (click)="close()">
      <nb-icon icon="close-outline"></nb-icon>
    </button>
  </div>

  <nb-tabset  style="height: 80vh;width:80vw;overflow: auto" (changeTab)="handleTabChange($event)">

    <nb-tab tabTitle="API Settings" [badgeDot]="_updates.Settings" badgePosition="center right" badgeStatus="success">
      <div style="display: flex;justify-content: space-between;margin-top: 2px">
        <div style="padding-left: 1rem">
          <h6>Settings</h6>
          <table [formGroup]="apiSettings">
            <tr>
              <td><code>Client Id</code></td>
              <td>
                <input type="text" placeholder="Client Id" formControlName="client_id" nbInput fullWidth fieldSize="tiny"/>
              </td>
            </tr>
            <tr>
              <td><code>Name</code></td>
              <td>
                <input type="text" placeholder="Name" formControlName="name" nbInput fullWidth fieldSize="tiny"/>
              </td>
            </tr>
            <tr>
              <td><code>Description</code></td>
              <td><input type="text" placeholder="Description" formControlName="desc" nbInput fullWidth
                         fieldSize="tiny"/></td>
            </tr>
            <tr>
              <td><code>URL</code></td>
              <td><input type="text" placeholder="URL" formControlName="url" nbInput fullWidth fieldSize="tiny"/></td>
            </tr>
            <tr>
              <td><code>Connection Identifier</code></td>
              

              <td style="display: flex;
              gap: 0.5rem;">
                <nb-select placeholder="Connection Identifier" formControlName="connection_identifier" size="tiny">
                  <nb-option *ngFor="let connection of connections;"
                             [value]="connection.identifier">{{ connection.identifier }}</nb-option>
                </nb-select>
                <button nbButton shape="round" size="tiny"  [disabled]="!apiSettings.value['connection_identifier']" type="submit" (click)="editConnection()" style="margin-right: auto; width:3rem" *ngIf="activeTab.tabTitle==='API Settings'">
                  <nb-icon icon="edit-outline"></nb-icon>
                  </button>
              </td>
              
              <!--              <td>-->
              <!--                <button nbButton shape="round" status="primary" size="tiny" (click)="openConnection()" style="float: right;width: 4rem">-->
              <!--                  Add-->
              <!--                </button>-->
              <!--              </td>-->
            </tr>
            <tr>
              <td><code>Connection Id</code></td>
              <td>
                <nb-select placeholder="Connection Id" formControlName="connection_id" size="tiny" [disabled]="true">
                  <nb-option *ngFor="let connection of connections"
                             [value]="connection.id">{{ connection.id }}</nb-option>
                </nb-select>
              </td>
            </tr>
            <tr>
              <td><code>Is secure</code></td>
              <td>
                <nb-checkbox formControlName="is_secure"></nb-checkbox>
              </td>
            </tr>
            <tr>
              <td><code>Method</code></td>
              <td>
                <nb-select placeholder="Request Method" formControlName="method" size="tiny">
                  <nb-option value="GET">GET</nb-option>
                  <nb-option value="POST">POST</nb-option>
                  <nb-option value="PUT">PUT</nb-option>
                  <nb-option value="PATCH">PATCH</nb-option>
                  <nb-option value="DEL">DELETE</nb-option>
                </nb-select>
              </td>
            </tr>

            <tr>
              <td><code>Api_Content_Type</code></td>
              <td>
                <nb-select placeholder="Content Type" formControlName="api_content_type" size="tiny">
                  <nb-option value="json">Json</nb-option>
                  <nb-option value="xml">Xml</nb-option>
                </nb-select>
              </td>
            </tr>
          </table>
        </div>
        <app-print-json [data]="apiSettings.value" style="background-color: ghostwhite;width: 40% "></app-print-json>
      </div>
    </nb-tab>

    <nb-tab [tabTitle]="item.title" *ngFor="let item of data" [badgeDot]="_updates[item.title]" badgePosition="center right" badgeStatus="success">
      <div style="display: flex;justify-content: space-between;margin-top: 2px">
        <app-edit-api [form]="[item]" (event)="eventHandler($event)" [showTitle]="true"
                      [dataTypes]="item.title.toLowerCase()==='headers'||item.title.toLowerCase()==='variables'?headerDataOptions:schemaDataOptions"
                      style="padding-left: 1rem;width: 60%;overflow: auto"></app-edit-api>
        <app-print-json [data]="_data[item.title]" style="background-color: ghostwhite;width: 40% "></app-print-json>
      </div>
    </nb-tab>
  </nb-tabset>

  <div style="display:flex;flex-direction: row-reverse;gap: .5rem;padding: .5rem;border-top: 1px solid silver;">
    <button nbButton shape="round" size="small" [status]="submitting?'basic':'primary'" type="submit" (click)="submit()" [nbSpinner]="submitting" nbSpinnerStatus="primary">
      {{showCreateAPI?'Create':'Update'}}
    </button>
    <button nbButton shape="round" size="small" (click)="close()">Cancel</button>
    <button nbButton shape="round" size="small" type="submit" (click)="openConnection()" style="margin-right: auto" *ngIf="activeTab.tabTitle==='API Settings'">Add new connection</button>
  </div>
</div>

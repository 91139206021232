import { FormField } from "./json-form-type"

const COLUMN_KEY_VALUE = 'value'
const COLUMN_KEY_NAME = 'columnKeyName'
const CONFIG_API_KEY_ID = 'apiKeyId'
const COLUMN_KEY_ORDER = 'order'

//#region Base interface
export interface ConfigResponseType {
    Id?:number
    [CONFIG_API_KEY_ID]: number | null
    [COLUMN_KEY_VALUE]: string | boolean | number | null
    [COLUMN_KEY_NAME]: string | null
    [COLUMN_KEY_ORDER]: number
}

type InputFieldType = 'string' | 'number' | 'boolean'
export interface CustomForm {
    type: 'object' | 'array' | InputFieldType
    getValue(): Array<ConfigResponseType>
    includes(id: number): [any, any]

    getState(): any
}


export type DataProxy = {[id:number]: {[order:number]: {[key:string]: Partial<ConfigResponseType> & any}}}
export class BaseInput {

  // protected _toggle: 0 | 1 | 2 = 0
  // protected _value: string | null = null
  // protected _keyId: string | null = null
  constructor(protected dataProxy: DataProxy, readonly _id: number | null, readonly order: number) {
    this.init()
  }
    

  private init() {
    if(!this.dataProxy[this._id as number]) this.dataProxy[this._id as number] = {}
    if(!this.dataProxy[this._id as number][this.order])this.dataProxy[this._id as number][this.order] = {}

    if(this.dataProxy[this._id as number][this.order].toggle === undefined) this.dataProxy[this._id as number][this.order].toggle = 2           // set default toggle
    if(this.dataProxy[this._id as number][this.order].valueToggle === undefined) this.dataProxy[this._id as number][this.order].valueToggle = false  // set default valueToggle 

    if(!this.dataProxy[this._id as number][this.order][COLUMN_KEY_VALUE]) this.dataProxy[this._id as number][this.order][COLUMN_KEY_VALUE] = null
    if(!this.dataProxy[this._id as number][this.order][COLUMN_KEY_NAME]) this.dataProxy[this._id as number][this.order][COLUMN_KEY_NAME] = null

    // this.dataProxy[this._id as number][this._order].$input = this
  }

  get isValid(): boolean {
    return this.isMandatory ? this.toggle!==2 : true
  }

  private setProxyData(key: string, value: any) {
    this.dataProxy[this._id as number][this.order][key] = value
  }

  private getProxyData(key: string) {
    return this._id ? this.dataProxy[this._id as number][this.order][key] : undefined
  }

  get id() {
    return this._id
  }
  
  get toggle() {
    return this.getProxyData('toggle')
  }
  
  set toggle(value: 0|1|2) {
    this.setProxyData('toggle', value)
  }
  
  get valueToggle() {
    return this.getProxyData('valueToggle')
  }
  
  set valueToggle(value: 0|1|2) {
    this.setProxyData('valueToggle', value)
  }

  get value() {
    return this.getProxyData(COLUMN_KEY_VALUE)
  }

  set value(value) {
    this.setProxyData(COLUMN_KEY_VALUE, value)
  }

  get keyId() {
    return this.getProxyData(COLUMN_KEY_NAME)
  }

  set keyId(value) {
    this.setProxyData(COLUMN_KEY_NAME, value)
  }

  get isMandatory() {
    return (this.getProxyData('$metadata') || {}).isMandatory || false
  }
  
  set isMandatory(value: boolean) {
    let metaDataObj = this.getProxyData('$metadata') || {}
    this.setProxyData('$metadata', {...metaDataObj, isMandatory: value});
  }

  public getNULL(): ConfigResponseType {
    return {
      Id: this.getProxyData('Id'),
      [CONFIG_API_KEY_ID]: this._id,
      [COLUMN_KEY_VALUE]: null,
      [COLUMN_KEY_NAME]: null,
      [COLUMN_KEY_ORDER]: this.order
    }
  }
  protected getValueOrKeyName(): ConfigResponseType|null {
    
    const value = this.getNULL()

    if(!this._id) return value
    if(this.toggle === 0) {
      value[COLUMN_KEY_VALUE] = this.value || '' 
      if(this.valueToggle) value[COLUMN_KEY_VALUE] = null
    }
    if(this.toggle === 1) {
      value[COLUMN_KEY_NAME] = this.keyId;
      if(!value[COLUMN_KEY_NAME]) throw(`Api column mapping require!`) // TODO: open custom popup
    }

    // check mandatory field
    // if(this.isMandatory && (!value[COLUMN_KEY_VALUE] && !value[COLUMN_KEY_NAME])) {
    //   throw(`Mandatory field is missing!`)
    // }

    if(this.toggle === 2) return null

    return value
  }
}
//#endregion

export class FormInput extends BaseInput implements CustomForm {
  readonly type: InputFieldType
  constructor(type: InputFieldType, dataProxy: DataProxy, id: number|null, order: number) {
    super(dataProxy, id, order)
    this.type = type
  }
  getState() {
    return {
      id: this.id,
      type: this.type,
      order: this.order,
      toggle: this.toggle,
      valueToggle: this.valueToggle,
      value: this.value,
      keyId: this.keyId
    }
  }
  getValue(): ConfigResponseType[] {
    try {
      let value = this.getValueOrKeyName()
      if(!value) return []
      return [value]
    } catch(error) {
      throw error
    }
    return []
  }

  includes(id: number): [any, any] {
    if(this.id === id) return [null, this]
    return [null, null]
  }

}

export class FormObject extends BaseInput implements CustomForm {
  getState() {
    return {
      id: this.id,
      type: this.type,
      order: this.order,
      toggle: this.toggle,
      valueToggle: this.valueToggle,
      value: this.value,
      keyId: this.keyId,
      children: Object.keys(this._values).reduce((obj, keyName) => {
        obj[keyName] = this._values[keyName].getState()
        return obj
      }, {} as any),
    }
  }
  readonly type = 'object'
  public _values: {[key: string]: CustomForm} = {} // allow view to access child elements

  public set(key: string, value: CustomForm) {
    this._values[key] = value
  }

  public get(key: string) {
    return this._values[key]
  }

  public getValue():Array<ConfigResponseType> {
    if(!this._id || !this.toggle)return Array.from(Object.keys(this._values))
      .map(x => this._values[x])
      .reduce((array, input) => {array = array.concat(input!.getValue());return array}, [] as Array<ConfigResponseType>)
    if(this.toggle===1){let value = this.getValueOrKeyName(); return value?[value]:[]}
    if(this.toggle===2){
      if(this.isMandatory) {
        throw "Mandatory field is missing!"
      }
      return []
    }
    return []
  }

  public includes(id: number): [any, any] {
    if(this.id === id) return [null, this]

    for(let key of Array.from(Object.keys(this._values))) {
      const [root, element] = this._values[key].includes(id)
      if(element) return [root || this, element]
    }

    return [null, null]
  }
}


export class FormArray extends BaseInput implements CustomForm {
  getState() {
    return {
      id: this.id,
      type: this.type,
      order: this.order,
      toggle: this.toggle,
      valueToggle: this.valueToggle,
      value: this.value,
      keyId: this.keyId,
      children: this._elements.map(x => x.getState()),
      template: this.$child_elements_template
    }
  }
    
    readonly type = 'array'
    public _elements:Array<CustomForm> = [] // allow view to access child elements
    public $child_elements_template: any // template
    // public $schema: {children:Array<any>} = {children:[]}    // template array

    append(formInput?: CustomForm, order:number=0) {
      if(formInput) this._elements.push(formInput)
      // eslint-disable-next-line no-use-before-define
      else if(this.$child_elements_template) this._elements.push(buildForm(this.$child_elements_template, this.dataProxy, this.$child_elements_template.id||0, order))

      // this.$schema.children.push(this.$child_elements_template) // append in schema array
    }
    getValue(): ConfigResponseType[] {

      if(!this._id || this.toggle===0) return this._elements.reduce((array, input, index) => {
        // change the order id and append
        array = array.concat(input!.getValue().map(x => {x[COLUMN_KEY_ORDER] = index;return x}));
        return array
      }, [] as Array<ConfigResponseType>)

      if(this.toggle===1){let value = this.getValueOrKeyName(); return value?[value]:[]}
      if(this.toggle===2){
        if(this.isMandatory) {
          throw "Mandatory field is missing!"
        }
        return []
      }
      return []
    }

    removeItem(formInput: CustomForm) {
      this._elements = this._elements.filter(x => x !== formInput)
    }

    includes(id: number): [any, any] {
      if(this.id === id) return [this, this]

      for(let el of this._elements) {
        const [root, element] = el.includes(id)
        if(element) return [this, element]
      }
      return [null, null]
    }
}


//#region	Form Builder
export function buildForm(schema: FormField, dataProxy: DataProxy, id:number|null, order:number=0): FormObject | FormArray | FormInput {
  // eslint-disable-next-line no-use-before-define
  let form:ReturnType<typeof buildForm> = schema.type === 'list' ? buildArray(schema, dataProxy, id, order) : (schema.type === 'object' ? buildObject(schema, dataProxy, id, order) : buildInput(schema, dataProxy, id, order))

  return form
}

function buildObject(schema: FormField, dataProxy: DataProxy, id: number|null, order:number=0): FormObject {
  const form = new FormObject(dataProxy, id, order);

  // load child elements
  (schema.children || []).forEach(child => {
    form.set(child.name as string, buildForm(child.child as FormField, dataProxy, child.child?.id || null, order))
  });

  return form
}

function buildArray(schema: FormField, dataProxy: DataProxy, id: number|null, order:number=0): FormArray {
  const form = new FormArray(dataProxy, id, -1);

  form.$child_elements_template = (schema.children || [null])[0];

  // Check toggle state and append first item - remaining items can be appended manually
  if(dataProxy[id as number] && dataProxy[id as number][-1] && dataProxy[id as number][-1].toggle === 0) {
    form.append(undefined, form._elements.length); // append zero'th element
  }


  // form.$schema = schema as any;

  // let childCount = schema.children?.length || 0
  // schema.children = [];

  // form.append(undefined, form._elements.length)
  // for(let i=0; i<childCount; i++) form.append(undefined, form._elements.length);

  // load child elements
  // (schema.children || []).forEach(child => form.append(buildForm(child as FormField, dataProxy)));

  return form
}

function buildInput(schema: FormField, dataProxy: DataProxy, id: number|null, order:number=0): FormInput {

  let formInput!: FormInput

  // string input
  if(['string'].includes(schema.type)) {
    formInput = new FormInput('string', dataProxy, id, order)
  }


  // decimal|integer input
  if(['decimal', 'integer'].includes(schema.type)) {
    formInput = new FormInput('number', dataProxy, id, order)
  }

  // boolean input
  if(['boolean'].includes(schema.type)) {
    formInput = new FormInput('boolean', dataProxy, id, order)
  }


  if(!formInput) formInput = new FormInput('string', dataProxy, id, order)
  return formInput

}

export function setToggleStateFromResponse(data: Partial<ConfigResponseType>, buttons:{toggle: 0|1|2, valueToggle: boolean}) {
  buttons.toggle = 2
  buttons.valueToggle = false

  if(typeof data[COLUMN_KEY_VALUE] === 'string') {
    buttons.toggle = 0
    buttons.valueToggle = false
  }

  if(typeof data[COLUMN_KEY_NAME] === 'string') buttons.toggle = 1

  if(data[COLUMN_KEY_NAME] === data[COLUMN_KEY_VALUE] && data[COLUMN_KEY_VALUE] === null) {
    buttons.toggle = 0
    buttons.valueToggle = true
  }

}

export function match<T extends ConfigResponseType>(currentValue: T, obj: T):boolean {
  let equal = true

  // 1. check value
  // 2. check key
  if(obj[COLUMN_KEY_VALUE] !== currentValue[COLUMN_KEY_VALUE]) equal = false
  if(obj[COLUMN_KEY_NAME] !== currentValue[COLUMN_KEY_NAME]) equal = false

  return equal
}

//#endregion


//#region restore state
export function getState<T extends CustomForm>(form: T): any {
  return form.getState()
}

export function restoreState(state: any, dataProxy: DataProxy):CustomForm {
  // eslint-disable-next-line no-use-before-define
  return ['list', 'array'].includes(state.type) ? restoreArray(state, dataProxy) : (state.type === 'object' ? restoreObject(state, dataProxy) : restoreInput(state, dataProxy));
}

function restoreObject(state: any, dataProxy: DataProxy):CustomForm {
  const form = new FormObject(dataProxy, state.id, state.order);
  form.value = state.value
  form.toggle = state.toggle,
  form.valueToggle = state.valueToggle,
  form.value = state.value,
  form.keyId = state.keyId
  Object.keys(state.children).forEach(keyName => {
    form.set(keyName, restoreState(state.children[keyName], dataProxy))
  })

  return form
}

function restoreArray(state: any, dataProxy: DataProxy):CustomForm {
  const form = new FormArray(dataProxy, state.id, state.order);
  form.value = state.value
  form.toggle = state.toggle,
  form.valueToggle = state.valueToggle,
  form.value = state.value,
  form.keyId = state.keyId
  form.$child_elements_template = state.template

  for(const child of state.children) {
    form.append(undefined, child.order)
  }

  return form
}

function restoreInput(state: any, dataProxy: DataProxy):CustomForm {
  const form = new FormInput(state.type, dataProxy, state.id, state.order);
  form.value = state.value
  form.toggle = state.toggle,
  form.valueToggle = state.valueToggle,
  form.value = state.value,
  form.keyId = state.keyId

  return form
}

//#endregion
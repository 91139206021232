import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { GroupService } from 'src/app/services/api/group.service';
import { NbDialogRef,  NbDialogService} from '@nebular/theme';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.css']
})
export class CreateGroupComponent implements OnInit {

  group: FormGroup = new FormGroup({
  	name: new FormControl(''),
    email: new FormControl(''),
  	comment: new FormControl(''),
    icon: new FormControl('')
  });

  isUpdate: boolean = false
  newGroupId: number|null = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {group: any}, private group_service: GroupService, private dialogRef: MatDialogRef<CreateGroupComponent>) { 
    console.log(data)
    if(data && data.group) {
      this.newGroupId =data.group.id;
      this.isUpdate = true
      this.group.controls.name.setValue(data.group.name)
      this.group.controls.email.setValue(data.group.email)
      this.group.controls.icon.setValue(data.group.icon)
      this.group.controls.comment.setValue(data.group.comment)
    }
  }

  ngOnInit(): void {
  }

  createGroup(){
    console.log(this.group.value)
    this.group_service.createGroup(this.group.value).subscribe((response: any)=>{
      console.log(response);
      if(response.status=="success"){
        this.dialogRef.close();
      
      }
    });
  }

  async editGroupDetails(){
    let response = await this.group_service.editGroup(this.newGroupId, this.group.value).toPromise()
    console.log(response)
      // if(response.status=="success")
      // {
      //   this.dialogRef.close();
      // }
  }

  close(){
    this.dialogRef.close();
  }
}

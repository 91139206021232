import { AfterViewInit, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DataService } from 'src/app/services/api/data.service';
import { NavbarService } from 'src/app/services/core/navbar.service';
import { SideNavService } from 'src/app/services/core/sidenav.service';
import { NB_WINDOW, NbMenuService } from '@nebular/theme';
import { Subject } from 'rxjs'
import { filter, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  NbGetters,
  NbGlobalPhysicalPosition,
  NbSearchService,
  NbTreeGridDataSource,
  NbTreeGridDataSourceBuilder
} from "@nebular/theme";
import { UIService } from "../../services/config/ui.service";
import { GroupService, group } from 'src/app/services/api/group.service';
import { DashboardStoreService } from 'src/app/services/store/dasboard-store.service';
import { ConfirmationPopupComponent } from 'src/app/components/confirmation-popup/confirmation-popup.component';
import { ConfirmationPopupModel } from 'src/app/components/confirmation-popup/confirmation-popup';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs/internal/Subscription';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TopicResponse, Topicv2Service} from "../../services/api/v2/topicv2.service"
import { TemplatePortal } from '@angular/cdk/portal';

interface FSEntry {
  id: string;
  name: string;
  description: string;
  created_time: string;
  action?: string;
  status: string;
}
@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent implements OnInit {
  totalCount=0
  pageIndex=0
  itemsPerPage=10
  pageSizeOptions=[5,10,20,25,30]

  filterTextChanged: Subject<string> = new Subject<string>();
  customColumn = 'index';
  defaultColumns = ['id', 'name', 'label', 'description', 'accessLevel', 'uniqueId', 'actions'];
  defaultNames: { [key: string]: string } = {
    id: "Topic ID",
    name: "Topic Name",
    label: "Label",
    description: "Description",
    accessLevel: "Access Level",
    uniqueId: "Unique ID",
    // createdBy: "Created By",
    // createdTime: "Created Time",
    actions: "Actions"
  };
  allColumns = [/*this.customColumn, */...this.defaultColumns];
  dataSource: NbTreeGridDataSource<FSEntry>;
  subscriptions: Array<Subscription> = [];
  data: TopicResponse['data'] = [];
  menu_items = [
    { title: "Copy" }
  ]
  getters: NbGetters<FSEntry, FSEntry> = {
    dataGetter: (node: FSEntry) => node,
    childrenGetter: (node: FSEntry) => undefined,
    expandedGetter: (node: FSEntry) => false,
  };
  @ViewChild('newTopic') newTopic!: TemplateRef<any>;
  @ViewChild('changeAccess') changeAccess!: TemplateRef<any>;
  @ViewChild('portalContent', { static: true })
  portalContent: any
  isProgress: Map<number, boolean> = new Map
  constructor(
    private navbarService: NavbarService,
    private dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>,
    private nbMenuService: NbMenuService,
    private group_service: GroupService,
    public uiService: UIService,
    public dashboardService: DashboardStoreService,
    public topicService: Topicv2Service,
    public dialogRef: MatDialog,
    public portalBridge: SideNavService,
    private viewContainerRef: ViewContainerRef,
    public dialog: MatDialog,
    private router: Router) {
    // console.log(dialogRef)
    this.dataSource = dataSourceBuilder.create([], this.getters);
  }
  async ngOnInit() {
    this.navbarService.create = true;
    this.navbarService.view = true;
    this.navbarService.back = false;
    await this.loadTopics();
    this.loadTopicDetails(this.itemsPerPage,this.pageIndex);
  }

  customEventsHandler( event : any ){
    if (event.detail.type === 'create'){
      this.addTopicPopup()
    }
    else if(event.detail.type === 'view'){
      this.viewTopicDetail(event)
    }
    else if(event.detail.type === 'add-new-topic'){
      this.addTopic(event.detail.payload)
    }
    else if (event.detail.type === 'edit') {
      this.editTopic(event.detail.id)
    }
    else if (event.detail.type === 'edit-submit'){
      console.log(event.detail.id.topicId)
      this.onUpdate(event.detail.id.topicId, event.detail.payload)
    }
    else if (event.detail.type === 'protected'){
      this.updateAccess(event.detail.id, event.detail.payload)
    }
    else if (event.detail.type === 'private'){
      this.updateAccess(event.detail.id, event.detail.payload)
    }
    else if (event.detail.type === 'public'){
      this.updateAccess(event.detail.id, event.detail.payload)
    }
    else if (event.detail.type === 'close'){
      this.close()
    }
  }

  


  foundTopic: any
  async editTopic(topicId:number){
    console.log(topicId)
    this.portalBridge.show = true;
    const portal = new TemplatePortal(this.portalContent, this.viewContainerRef)
    this.portalBridge.setRightNavTemplate(portal)
    let topicDetails = await this.topicService.getTopicDetails({itemsPerPage:this.itemsPerPage,pageNum:this.pageIndex+1}).toPromise();
    let topicData = topicDetails.payload.data
    topicData.forEach(value => {
      if(value.id == topicId){
        this.foundTopic = {
          name: value.name,
          label: value.label,
          description: value.description,
          id: value.id,
          uniqueId: value.uniqueId,
          accessLevel: value.accessLevel
        };
      }
    })
  }

  async onUpdate(id: number, data: any){
    console.log(id)
    let result = JSON.parse(JSON.stringify(data));
    this.topicService.updateTopic(String(id), result).subscribe((response) => {
      console.log(result)
    })
    let topicResponse = await this.topicService.getTopicDetails({itemsPerPage: 10e7}).toPromise();
    this.data = topicResponse.payload.data
    this.filteredData = [...topicResponse.payload.data]
    this.dialog.closeAll()
    this.portalBridge.show = false;
    this.refresh()
  }

  // changeAccessLevel(topicInstanceId: number){
  //   const foundObject = this.filteredData.find(item => item.id === topicInstanceId);
  //   console.log(foundObject)
  //   let dialogService = this.dialog.open(this.changeAccess,{
  //     width:"500px",
  //     height:"300px",
  //     data: {topicInstanceId, foundObject}
  //   })

  // }

  close(){
    this.portalBridge.show = false;
    this.dialog.closeAll();
  }

  async updateAccess(id: number, payload: any){
    let result = JSON.parse(JSON.stringify(payload))
    console.log(result)
    this.topicService.updateAccessLevel(String(result.id), result).subscribe((response) => {
      console.log(result)
    })
    this.refresh()
  }

  filteredData: any[] = [];
  
  processedData:any[] = []
  async loadTopics(){
    this.uiService.isContentLoading = true

    let topicResponse = await this.topicService.getTopicDetails({itemsPerPage:this.itemsPerPage,pageNum:this.pageIndex+1}).toPromise();
    this.data = topicResponse.payload.data
    this.filteredData = [...topicResponse.payload.data]
    console.log(this.data)
    console.log(this.filteredData)
    this.uiService.isContentLoading = false
  }

  addTopicPopup(){
    let dialogReference = this.dialog.open(this.newTopic , {
      panelClass: 'custom-dialog-container',
      // height:'700px',
      // width:'600px',
      // disableClose:true
    });
  }
  addTopic(data: any){
    let res = JSON.parse(JSON.stringify(data))
    this.topicService.createTopic(res).subscribe((response) => {
      this.loadTopics()
      this.close()
    });
    
  }

  formSubmitted = false;
  
  viewTopicDetail(event: any){
    console.log(this.router)
    this.router.navigate(['/topic-details/', event.detail.data.id])
  }
  refresh(){
    setTimeout(() => window.location.reload(),1000)
  }

  ngOnDestroy() {
    this.portalBridge.removeRightNavTemplate()
    this.portalBridge.show = false;
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
  onPaginationEvent(e:any){
    this.loadTopicDetails(e.detail.itemsPerPage,e.detail.index)
  }
  loadTopicDetails(itemsPerPage: number, pageIndex: number){
    this.topicService.getTopicDetails({itemsPerPage,pageNum:pageIndex+1}).subscribe((data:any)=>{
      this.filteredData=this.topicDetailFormatter(data.payload.data);
      this.totalCount = data.payload.totalCount;
      this.pageIndex = data.payload.currentPage-1;
    })
  }
  topicDetailFormatter(topicPageDetails:Array<any>=[]){
    let arr=topicPageDetails.map((data)=>{
      return{
        name: data.name,
        label: data.label,
        description: data.description,
        id: data.id,
        uniqueId: data.uniqueId,
        accessLevel: data.accessLevel
      
      }
    })
    return arr

  }

}

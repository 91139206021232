

  <!-- <div class="popupcontainer">
  
    <div class="sidenav" >
      <h5 style="padding: 1rem;">{{dialogParams.title?dialogParams.title: 'Select Item'}}</h5>
      <input type="search" matInput [(ngModel)]="searchItem" placeholder="   Search" style="border-radius: 6px;
      height: 1.8rem;
      width: 90%;
      margin-left: 0.5rem;"> 
      <ul style="list-style-type: none;" >
        <li class="list-item" [class.active]="currentConfigType === configType.key" *ngFor="let configType of configTypeMap | keyvalue" (click)="currentConfigType=configType.key">
          <button>
            {{configType.key | titlecase}}
          </button>
          </li>
      </ul>
    </div>
   
      <div class="content">
        <header>
          <input type="search" matInput [(ngModel)]="searchContent" placeholder="     Search configs" style="border-radius: 20px;
          height: 2rem;"> 
          <button nbButton  style="float: right;" (click)="close()"><nb-icon icon="close-outline" ></nb-icon></button>
        </header>    
      <ng-container  *ngFor="let configtypes of searchResults|keyvalue">
        <h5>{{configtypes.key| titlecase}}</h5>
        
        <ng-container *ngFor="let configGroups of configtypes.value|keyvalue">
          <section *ngIf="configGroups.value.length">

            <p>{{configGroups.key | titlecase}}</p>
          
            <main>
              <div class="item" *ngFor="let config of configGroups.value" (click)="sendConfigId(config);">
                   
                <picture style="width: 5.5rem; height: 4rem; overflow: hidden;"> 
                  <img style="width: 5rem; height: 3.6rem;" [src]="config.icon">
                </picture>
      
                <div [title]="config.displayName" class="name" style="text-align: center;
                width: 8rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 0.7rem;">         
                  {{config.displayName}} 
                </div>
              </div>
            </main>
      
          </section>
        </ng-container>
      </ng-container>

       
      </div>
   
    </div> -->
    <simplify-add-node-popup (close)="close()" (nodeSelect)="sendConfig($event)"></simplify-add-node-popup>
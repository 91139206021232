import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SideNavService } from 'src/app/services/core/sidenav.service';

const templateCardData: Array<{
  id: number,
  label: string,
  description: string,
  category: string,
  steps: Array<
    { icon: string, label: string }
  >
  }
  > = [
    {
      id: 1,
      label: "Sync Cost Center From Workday to Simplify",
      description: "This pipeline is used to extract Cost Center from Workday and upload it to Simplify",
      category: "Workday",
      steps: [
        { icon: "../../../assets/images/trigger-img.png", label: "Workday Cost Center" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/Simplify.png", label: "Simplify Master Data" }

      ] 
    },

    {
      id: 2,
      label: "Upload Workday User Location to Simplify",
      description: "This pipeline is used to extract information from Workday user location and upload it to Simplify",
      category: "Workday",
      steps: [
        { icon: "../../../assets/images/trigger-img.png", label: "Daily User Location Upload" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/Simplify.png", label: "Simplify" }

      ]        
    },
    {
      id: 3,
      label: "Updating new user created in Workday to Simplify",
      description: "This pipeline is used to update the newly created users of Workday into Simplify",
      category: "Workday",
      steps: [
        { icon: "../../../assets/images/trigger-img.png", label: "New Workday User Data" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/Simplify.png", label: "Simplify" }
      ]        
    },
    {
      id: 4,
      label: "Syncing Users from Workday to Simplify",
      description: "This pipeline is used to sync the users from Workday to Simplify",
      category: "Workday",
      steps: [
        { icon: "../../../assets/images/trigger-img.png", label: "Workday User Event" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/Simplify.png", label: "Simplify" }
      ]        
    },
    {
      id: 5,
      label: "Syncing New Simplify Jobs to Workday Jobs Requisitions",
      description: "This pipeline is used to sync new Simplify jobs with the Workday job requisitions",
      category: "Workday",
      steps: [
        { icon: "../../../assets/images/Simplify.png", label: "New Simplify Job Data" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/trigger-img.png", label: "Workday Job Requisition" }
      ]        
    },
    {
      id: 6,
      label: "Syncing Simplify Workers with Workday Contingent Workers",
      description: "This pipeline is used to sync the workers in Simplify with contingent workers from Workday",
      category: "Workday",
      steps: [
        { icon: "../../../assets/images/Simplify.png", label: "Simplify Workers Data" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/trigger-img.png", label: "Workday Contingent Workers" }
      ]        
    },
    {
      id: 7,
      label: "Sync Worker Data Between Simplify and SuccessFactors",
      description: "This pipeline is used to Sync Worker Data Between Simplify and SuccessFactors",
      category: "SuccessFactors",
      steps: [
        { icon: "../../../assets/images/Simplify.png", label: "Simplify Worker Events" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/SuccessFactor.jpg", label: "SuccessFactors Employee" }
      ]        
    },
    {
      id: 8,
      label: "Onboarding a Simplify User into SuccessFactors",
      description: "This pipeline is used to streamline the transfer of Candidate Data from Simplify to SuccessFactors for onboarding once candidates are selected for a position",
      category: "SuccessFactors",
      steps: [
        { icon: "../../../assets/images/Simplify.png", label: "Candidate Onboarding" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/SuccessFactor.jpg", label: "SuccessFactors" }
      ]        
    },
    {
      id: 9,
      label: "Creating New Simplify Users in SuccessFactors",
      description: "This Pipeline is used to create a new User in SuccessFactors when New user is created in Simplify",
      category: "SuccessFactors",
      steps: [
        { icon: "../../../assets/images/Simplify.png", label: "New Simplify User" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/SuccessFactor.jpg", label: "SuccessFactors" }
      ]        
    },
    {
      id: 10,
      label: "Syncing Simplify Workers With ServiceNow Contingent Workers",
      description: "This pipeline is used to Sync the workers in simplify with contingent workers from ServiceNow",
      category: "ServiceNow",
      steps: [
        { icon: "../../../assets/images/Simplify.png", label: "Simplify Workers Data" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/ServiceNow.png", label: "ServiceNow Contingent Workers" }
      ]        
    },
    {
      id: 11,
      label: "Syncing Assets Management data from ServiceNow to Simplify",
      description: "This pipeline is used to Sync asset management data from servicenow to simplify, ensuring that equipment is properly accounted",
      category: "ServiceNow",
      steps: [
        { icon: "../../../assets/images/Simplify.png", label: "Simplify Worker Asset" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/ServiceNow.png", label: "ServiceNow" }
      ]        
    },
    {
      id: 12,
      label: "IT Support and Issue Management",
      description: "This pipeline is used to automatically create a IT support tickets in ServiceNow when Simplify workers report IT related issues through the Simplify Platform",
      category: "ServiceNow",
      steps: [
        { icon: "../../../assets/images/Simplify.png", label: "Simplify Worker Ticket" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/ServiceNow.png", label: "ServiceNow" }
      ]        
    },
    {
      id: 13,
      label: "Sync Organization Data From SFTP Server With Simplify",
      description: "This pipeline is used to Sync the organizational data provided like code, name, type, status, etc in the SFTP Server with simplify to create, update or Disable organizations",
      category: "File Based",
      steps: [
        { icon: "../../../assets/images/SFTP.png", label: "Organizational Data" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/Simplify.png", label: "Simplify" }
      ]        
    },
    {
      id: 14,
      label: "Fetching New User Data From SFTP Server to Simplify",
      description: "This pipeline is used to Fetch newly create user data from SFTP server to Simplify",
      category: "File Based",
      steps: [
        { icon: "../../../assets/images/SFTP.png", label: "New User Data" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/Simplify.png", label: "Simplify" }
      ]        
    },
    {
      id: 15,
      label: "Syncing Cost Center Data From SFTP Server with Simplify",
      description: "This pipeline is used to Sync the Cost Center data provided in the SFTP Server with simplify to create, update or Disable Cost Centers",
      category: "File Based",
      steps: [
        { icon: "../../../assets/images/SFTP.png", label: "Cost Center Data" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/Simplify.png", label: "Simplify" }
      ]        
    },
    {
      id: 16,
      label: "Syncing User Work location Data From SFTP Server with Simplify",
      description: "This pipeline is used to Sync the User Work Location data provided like code, name, Address, status, etc in the SFTP Server with simplify",
      category: "File Based",
      steps: [
        { icon: "../../../assets/images/SFTP.png", label: "User Work Location" },
        { icon: "../../../assets/images/transform.png", label: "Map and Transform" },
        { icon: "../../../assets/images/Simplify.png", label: "Simplify" }
      ]
    }
  ]

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})

export class TemplatesComponent implements OnInit {
  templates$ = new BehaviorSubject(templateCardData)
  constructor(private sidenavService: SideNavService, private router:Router) { }

  

  ngOnInit(): void {
    this.sidenavService.templateCategory.subscribe(
      category => {
        this.templates$.next(this.filter(category))
      }
    )
    this.filter("Workday")
  }
  
  viewTemplateCard(event: any){
    console.log(this.router)
    this.router.navigate([{ outlets: { view: ['template-details', event.detail.id],  action: null }}])
  }
  

  filter(category: string) {
    // this.router.navigate(['', { outlets: { view: ["view-templates"],action:null } }])
    return templateCardData.filter(x => x.category === category)
  }


  
}

<div class="task-config" style="height: 100%;width: 22rem;position: absolute;background-color: white;display: flex;flex-direction: column;border-left: 1px solid #D6D6D6;">
    <header>
    <div style="display: inline-block;">
        <span>Connector: {{connectorData?.label}}</span>
      </div>
      
      <!-- <button (click)="testingFun('Node Instance Updated')" style="border: 1px solid #00d68f;color: #00d68f;margin-right: -5rem;" mat-flat-button color="basic">Save</button> -->

      <button (click)="useService()" nbButton size="small" outline status="success" style="margin-left: auto;">Save</button>

      <!-- <button mat-icon-button >
        <mat-icon>close</mat-icon>
      </button> -->
      <button id="close-btn" size="small" nbButton (click)="close()"><nb-icon icon="close"></nb-icon></button>


     
    </header>
    

    <div style="display: flex;flex-direction: column;height: 100%;overflow: auto;width: 22rem;padding: 1rem;background-color: white;gap: .5rem;box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;">
        <div style="margin-left:auto;font-size: x-small;">Status: {{nodeData.status}}
            <div style="display:inline-block;height: 10px;width: 10px;border-radius: 50%;" [ngStyle]="{'background-color':nodeInstanceStatus === 'active' ? '#00d68f' : 'yellow' }"></div>
        </div>
        <div style="display: flex;flex-direction: column;">
            <label>Name</label>
            <nb-form-field>
                <nb-icon nbPrefix icon="text-outline"></nb-icon>
                <input nbInput fullWidth="true" fieldSize="small" type="text" name="nodeInstance" [(ngModel)]="nodeData.label" >
            </nb-form-field>
        </div>
            
        

        <div *ngIf="isConnectionEnabled" style="display: flex;flex-direction: column;">
            <hr>
            <label>Connection</label>
            <select [(ngModel)]="connectionInstanceId" (change)="popup({msg:'Services will be reset after this!\nAre you sure want to change the connection?',info:'warning'})">
                <option *ngFor="let connection of nodeConnections" [value]="connection.id">{{connection.label}}</option>
            </select>
            <br>
            <div class="connection-container" *ngIf="connectionInstanceId">
                <button nbButton fullWidth="true" size="small" (click)="editConnection()">{{isEditConnection?'Cancel':'Edit Connection'}}</button>
            </div>
            <div class="new-connection-form" *ngIf="isEditConnection" style="height: 100%;">
                <div class="new-connection-name" style="padding: 10px;">
                    <label style="font-weight: bold;font-size:small">Connection Name<span style="color: red;">*</span><span style="font-weight:normal"> (required)</span></label>
                    <nb-form-field>
                        <nb-icon nbPrefix icon="text-outline"></nb-icon>
                        <input nbInput fullWidth="true" fieldSize="small" type="text" name="connectionName" [(ngModel)]="newConnectionName">
                    </nb-form-field>
                </div>
                <app-dynamic-form [form]="newConnectionForm"></app-dynamic-form>
                <br>
                <div style="display:flex;justify-content: space-between;">
                    <button size="small" status="primary" nbButton (click)="saveEditConnection()">Test and Save</button>
                </div>
                <div *ngIf="testConnectionError" style="background-color: #fee;color:#c33;padding:0.25rem;margin:0.25rem;font-size:small;">{{testConnectionError}}</div>
                <br>
            </div>
            <br>

            
            <div class="connection-container" >
                <button fullWidth="true" size="small" nbButton (click)="newConnection()">{{isNewConnection?'Cancel':'NEW CONNECTION +'}}</button>
            </div>
            <div class="new-connection-form" *ngIf="isNewConnection" style="height: 100%;">
                <div class="new-connection-name" style="padding: 10px;">
                    <label style="font-weight: bold;font-size:small">Connection Name<span style="color: red;">*</span><span style="font-weight:normal"> (required)</span></label>
                    <nb-form-field>
                        <nb-icon nbPrefix icon="text-outline"></nb-icon>
                        <input nbInput fullWidth="true" fieldSize="small" type="text" name="connectionName" [(ngModel)]="newConnectionName">
                    </nb-form-field>
                </div>
                <app-dynamic-form [form]="newConnectionForm"></app-dynamic-form>
                <br>
                <div style="display:flex;justify-content: space-between;">
                    <button size="small" status="primary" nbButton (click)="testAndSave()">Test and Save</button>
                </div>
                <div *ngIf="testConnectionError" style="background-color: #fee;color:#c33;padding:0.25rem;margin:0.25rem;font-size:small;">{{testConnectionError}}</div>
                <br>
            </div>


        </div>
        
        <hr>

        <div *ngIf="isServicesEnabled" style="display: flex;flex-direction: column;">
            <label>Service</label>
            <select [(ngModel)]="selectedService" (change)="serviceSelectedEvent()">
                <option *ngFor="let service of nodeServices" [value]="service.name">{{service.label}}</option>
            </select>
            <br>
            <app-dynamic-form *ngIf="selectedService" [form]="serviceForm"></app-dynamic-form>
            
        </div>

    </div>


    <!-- <button style="width:22rem" nbButton size="small" status="success" (click)="updateNodeInstance()">Update Node Instance</button> -->
</div>

<ng-template #confirmationPopup let-data>
    <div>
        <span *ngIf="data?.info==='warning'"><nb-icon status="warning" icon="alert-triangle-outline"></nb-icon></span>
        {{data.msg}}
        <hr>
        <div style="display: flex;">
            <button style="margin-right:1rem" nbButton status="warning" mat-dialog-close="no">No</button>
            <button nbButton status="success" mat-dialog-close="yes">Yes</button>
        </div>
    </div>
</ng-template>

import { T } from '@angular/cdk/keycodes';
import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { TransitionCheckState } from '@angular/material/checkbox';
import { platformBrowserDynamicTesting } from '@angular/platform-browser-dynamic/testing';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'medal-component',
  templateUrl: './detail-cell-renderer.component.html',
  styleUrls: ['./detail-cell-renderer.component.css'],
})
export class DetailCellRendererComponent implements ICellRendererAngularComp {
  testObject:any;

  agInit(params: any): void {
    //this.displayValue = new Array(params.value).fill('#').join('');
    //params.colDef.autoHeight=false
    //params.api.resetRowHeights();
    //this.value = JSON.stringify(params.value);
    this.testObject = params.value;

    //this.testObject = JSON.stringify(this.testObject)
    // this.keys = Object.keys(this.testObject)
    // this.values=Object.values(this.testObject)
    // console.log(this.keys,this.values)
    // this.field = params.colDef.field;
    // console.log(params)
  }

  refresh(params: ICellRendererParams) {
    return false;
  }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Context } from '../../config/context.service';
import { ServiceResponse } from '../../core/data.service';
import { PipelineVersion } from './interfacesv2';

interface IService {
    name: string;
    label: string;
    description: string;
    }

interface Connector {
    id: number;
    name: string;
    label: string;
    desc: string;
    icon: string;
    type: null;
    connection: {
      isEnabled: boolean;
    };
    services: IService[];
  }
  
interface GenericResponse<T>{
  errorMessage: string
  errorCode:string
  status: "success" | "error" | 'failure'
  payload: T
}
interface PaginationResponse<T>{
  totalCount:number
  pageCount:number
  currentPage:number
  data:Array<T>
}

@Injectable({
  providedIn: 'root'
})
export class Connectorv2Service {
  connectorApi
  constructor(private http:HttpClient,private context:Context){
    this.connectorApi = context.getApiConfig().CONNECTOR_API;
  }
  getConnectors(){
    return this.http.get<GenericResponse<PaginationResponse<Connector>>>(this.connectorApi.GET.connectors)
  }

  getConnectorDetail(connectorId:string){
    return this.http.get<any>(this.connectorApi.GET.connectorDetail(connectorId));
  }
  createNodeInit(newNodeData:any,pipelineId:string,pipelineVersion:string){
    return this.http.post<GenericResponse<{nodeId:string,pipelineVersion:PipelineVersion}>>(this.connectorApi.POST.createNode(pipelineId,pipelineVersion),newNodeData)
  }

  //connections
  getConnectionsByConnectorId(connectorId:string){
    return this.http.get<GenericResponse<PaginationResponse<any>>>(this.connectorApi.GET.connections(connectorId))
  }

  connectionRemap(connectorId:string,req:any){
    return this.http.post(this.connectorApi.POST.connectionRemap(connectorId),req).pipe(map((data: any) => {
      const inputArray:any = [];
      for (const key in data.payload.connection.inputFields) {
          if (data.payload.connection.inputFields.hasOwnProperty(key)) {
              inputArray.push({ ...data.payload.connection.inputFields[key] });
          }
      }
      return inputArray;
  }));
  }

  getNewConnectionForm(connectorId:string){
    //API to fetch input fields for the new connection page
    return this.http.get(this.connectorApi.GET.connectionFormDetails(connectorId)).pipe(map((data: any) => {
      const inputArray:any = [];
      for (const key in data.payload.connection.inputFields) {
          if (data.payload.connection.inputFields.hasOwnProperty(key)) {
              inputArray.push({ ...data.payload.connection.inputFields[key] });
          }
      }
      return inputArray;
  }))
    }

  getConnectionDetailById(connectionId:string){
    return this.http.get(this.connectorApi.GET.connectionDetailsById(connectionId)).pipe(map((data: any) => {
      const inputArray:any = [];
      for (const key in data.payload.input_fields) {
          if (data.payload.connection.inputFields.hasOwnProperty(key)) {
              inputArray.push({ ...data.payload.connection.inputFields[key] });
          }
      }
      return inputArray;
  }))
  }

  testConnection(connectorId:string,data:any){
    return this.http.post(this.connectorApi.POST.testConnection(connectorId),data);
  }
  createConnection(data:any){
    return this.http.post(this.connectorApi.POST.createConnection,data)
  }
}

<div class="json-form-container">

  <div style="display: flex;gap: 1rem;border-bottom: 1px solid;align-items: center;padding:.5rem;background-color: #edf1f7;">
    <div style="margin-right: auto;color: black;">
      <span>API Mapping</span>
    </div>

    <nb-icon icon="save-outline" style="cursor: pointer;font-size: x-large" (click)="saveData()" title="Save Mapping"></nb-icon>
    <nb-icon icon="close-outline" style="cursor: pointer;font-size: x-large" (click)="close()" title="Close"></nb-icon>
  </div>
  

  <nb-tabset (changeTab)="selectedTab=$event.tabId" #tabset>
    <nb-tab tabId="Request" tabTitle="Request" badgePosition="center right" badgeStatus="success">

      <div style="height: 80vh;width: 60vw;overflow-y: scroll;padding-left: 1rem;font-size: x-large">
        <ng-component  *ngTemplateOutlet="jsonForm;context:{node:Request, parent:{}}"></ng-component>
      </div>

    </nb-tab>

    <nb-tab tabId="Variables" tabTitle="Variables" badgePosition="center right" badgeStatus="success">

      <div style="height: 80vh;width: 60vw;overflow-y: scroll;padding-left: 1rem;font-size: x-large">
        <ng-component  *ngTemplateOutlet="jsonForm;context:{node:Variables, parent:{}}"></ng-component>
      </div>
      
    </nb-tab>

    <nb-tab tabId="Headers" tabTitle="Headers" badgePosition="center right" badgeStatus="success">

      <div style="height: 80vh;width: 60vw;overflow-y: scroll;padding-left: 1rem;font-size: x-large">
        <ng-component  *ngTemplateOutlet="jsonForm;context:{node:Headers, parent:{}}"></ng-component>
      </div>
      
    </nb-tab>
  </nb-tabset>

</div>

<!-- JSON Form -->
<ng-template #jsonForm let-node="node" let-parent="parent">

  <!-- String -->
  <ng-container *ngIf="node.type==='string'">
    <div style="display: inline-flex;align-items: center;gap: .3rem;" [class]="[node.type]">        
      <div 
      style="display: inline-flex;align-items: center;margin-left: auto;gap: .5rem;padding-right:.5rem">
        <div style="width: 2rem;display:flex;height: calc(1rem - 2px);border-radius: .5rem;background-color: #0000000f;cursor: pointer;" (click)="node.toggle=((node.toggle||0)+1)%3;">
          <div [nbTooltip]="!node.toggle?'Constant':(node.toggle===1 ? 'Source': 'null')"
          [class]="['toggle-bullet', !node.toggle?'toggle-left':(node.toggle===1?'toggle-center':'toggle-right')]" style="width: 1rem;height: 1rem;border-radius: .5rem;margin-top: -1px;"></div>
        </div>
      </div>

      <!-- <nb-icon icon="backspace-outline" style="margin-right: .5rem;cursor: pointer;" (click)="clearInput(node)"></nb-icon> -->

      <input *ngIf="!node.toggle" type="checkbox" [(ngModel)]="node.valueToggle" nbTooltip="Null value">
      <input *ngIf="!node.toggle&&!node.valueToggle" type="text" [attr.list]="'data-'+node.id" [placeholder]="node.valueToggle?'null':node.placeholder||node.type" [(ngModel)]="node.value" [disabled]="node.valueToggle">

      <ng-container *ngIf="node.toggle===1">
        <select [(ngModel)]="node.keyId" class="source-node-dropdown" style="background-color: #F2F4FC;min-width: 10rem;border-radius: 20px;padding: 0 1rem">
          <option [value]="option.key" *ngFor="let option of dropdownData" >{{option.value}} ({{option.data_type}})</option>
        </select>
      </ng-container>


    </div>
  </ng-container>

  <!-- Number -->
  <ng-container *ngIf="node.type==='number'">
    <div style="display: inline-block" [class]="[node.type]">        
      <div 
      style="display: inline-flex;align-items: center;margin-left: auto;gap: .5rem;padding-right:.5rem">
        <div style="width: 2rem;display:flex;height: calc(1rem - 2px);border-radius: .5rem;background-color: #0000000f;cursor: pointer;" (click)="node.toggle=((node.toggle||0)+1)%3;">
          <div [nbTooltip]="!node.toggle?'Constant':(node.toggle===1 ? 'Source': 'null')"
          [class]="['toggle-bullet', !node.toggle?'toggle-left':(node.toggle===1?'toggle-center':'toggle-right')]" style="width: 1rem;height: 1rem;border-radius: .5rem;margin-top: -1px;"></div>
        </div>
      </div>

      <!-- <nb-icon icon="backspace-outline" style="margin-right: .5rem;cursor: pointer;" (click)="clearInput(node)"></nb-icon> -->

      <input *ngIf="!node.toggle" type="number" [attr.list]="'data-'+node.id" [placeholder]="node.placeholder||node.type" [(ngModel)]="node.value">
      <ng-container *ngIf="node.toggle===1">
        <select [(ngModel)]="node.keyId" class="source-node-dropdown" style="background-color: #F2F4FC;min-width: 10rem;border-radius: 20px;padding: 0 1rem">
          <option [value]="option.key" *ngFor="let option of dropdownData" >{{option.value}} ({{option.data_type}})</option>
        </select>
      </ng-container>
    </div>
  </ng-container>

  <!-- Boolean -->
  <ng-container *ngIf="node.type==='boolean'">
    <div style="display: inline-block" [class]="[node.type]">        
      <div 
      style="display: inline-flex;align-items: center;margin-left: auto;gap: .5rem;padding-right:.5rem">
        <div style="width: 2rem;display:flex;height: calc(1rem - 2px);border-radius: .5rem;background-color: #0000000f;cursor: pointer;" (click)="node.toggle=((node.toggle||0)+1)%3;">
          <div [nbTooltip]="!node.toggle?'Constant':(node.toggle===1 ? 'Source': 'null')"
          [class]="['toggle-bullet', !node.toggle?'toggle-left':(node.toggle===1?'toggle-center':'toggle-right')]" style="width: 1rem;height: 1rem;border-radius: .5rem;margin-top: -1px;"></div>
        </div>
      </div>

      <!-- <nb-icon icon="backspace-outline" style="margin-right: .5rem;cursor: pointer;" (click)="clearInput(node)"></nb-icon> -->

      <!-- <input *ngIf="!node.toggle" type="number" [attr.list]="'data-'+node.id" [placeholder]="node.placeholder||node.type" [(ngModel)]="node.value"> -->
      <ng-container *ngIf="!node.toggle">
        <select [(ngModel)]="node.value" class="source-node-dropdown" style="background-color: #F2F4FC;min-width: 10rem;border-radius: 20px;padding: 0 1rem">
          <option [value]="true">True</option>
          <option [value]="false">False</option>
        </select>
      </ng-container>

      <ng-container *ngIf="node.toggle===1">
        <select [(ngModel)]="node.keyId" class="source-node-dropdown" style="background-color: #F2F4FC;min-width: 10rem;border-radius: 20px;padding: 0 1rem">
          <option [value]="option.key" *ngFor="let option of dropdownData" >{{option.value}} ({{option.data_type}})</option>
        </select>
      </ng-container>
    </div>
  </ng-container>

  <!-- Object -->
  <ng-container *ngIf="node.type==='object'">
    <div [class]="[node.type]">

      <div style="display: flex">

        <div nbTooltip="delete" class="delete-object" (click)="deleteItem(node, parent)" [hidden]="parent.type!=='array'"></div>

        <div>
          <ng-container *ngFor="let child of castArray(node._values)|keyvalue">
            <div style="margin-left: 2rem;padding:.3rem 0;">
              <div style="display: inline-flex;align-items: center;gap: 0.3rem;">
                
                <span style="color: rgba(255,0,0,0.94)"> {{child.value?.isMandatory ? '*' : ''}} </span>
                <span class="key" [style.color]="child.value.isValid?'':'red'">"{{child.key}}" : </span>

                <nb-icon
                  nbTooltip="Add Item" icon="plus-circle-outline"
                  *ngIf="child.value.type==='array'&&!child.value.toggle"
                  style="cursor: pointer; background-color: unset; border: unset"
                  (click)="addItem(child.value)">
                </nb-icon>

                <!-- Object toggle START -->
                <div 
                  *ngIf="(child.value.type==='object')||(child.value.type==='array')"
                  style="display: inline-flex;align-items: center;margin-left: auto;gap: .5rem;padding-right:.5rem">
                    <div style="width: 2rem;display:flex;height: calc(1rem - 2px);border-radius: .5rem;background-color: #0000000f;cursor: pointer;" (click)="child.value.toggle=((child.value.toggle||0)+1)%3;">
                      <div [nbTooltip]="!child.value.toggle?'Constant':(child.value.toggle===1?'Source': 'null')"
                      [class]="['toggle-bullet', !child.value.toggle?'toggle-left':(child.value.toggle===1?'toggle-center':'toggle-right')]" style="width: 1rem;height: 1rem;border-radius: .5rem;margin-top: -1px;"></div>
                    </div>
                </div>
              </div>


              <!-- Object Custom Input START -->
              <div style="display: inline-block" *ngIf="child.value.toggle===1&&(child.value.type==='object'||child.value.type==='array')" class="string">

              <!-- <input *ngIf="isConstValues[child.child.id+'_obj']" type="text" [attr.list]="'data-'+node.id" [placeholder]="child.child.type" [(ngModel)]="response[child.child.id+'_obj']"> -->

                <ng-container *ngIf="child.value.toggle===1">
                  <select [(ngModel)]="child.value.keyId" class="source-node-dropdown dropdown" style="min-width: 10rem;border-radius: 20px;padding: 0 1rem" >
                    <option [value]="option.key" *ngFor="let option of dropdownData" >{{option.value}} ({{option.data_type}})</option>
                  </select>
                </ng-container>
              </div>
              <!-- Object Custom Input END -->

              <ng-container *ngIf="(child.value.type!=='object'&&child.value.type!=='array')||!child.value.toggle">
                <ng-component *ngTemplateOutlet="jsonForm;context:{node:child.value, parent:node}"></ng-component>
              </ng-container>
            </div>
          </ng-container>
        </div>

      </div>


    </div>
  </ng-container>

  <!-- array -->
  <ng-container *ngIf="node.type==='array'">
    <div [class]="['list','list-append']" >
      <ng-container *ngFor="let child of node._elements">
        <div style="margin-left: 2rem; padding: .3rem 0">
          <div nbTooltip="delete" *ngIf="child.type!=='object'" class="delete-item" (click)="deleteItem(child, parent)" ></div>
          <ng-component *ngTemplateOutlet="jsonForm;context:{node:child, parent:node}"></ng-component>
        </div>
      </ng-container>
    </div>
  </ng-container>

</ng-template>

<div style="height: calc(100% - 50px);overflow: hidden;position: relative" class="grid"
     #containerRef id="containerRef" dropzone="true">

    <div class="selector" [class.fixed]="!isEditEnable" #selector [style]="{display: dagState.state.showSelectRect?'block':'none'}"></div>
    <!-- [cdkDragDisabled]="dagState.stateName!==dagState.DagStates.DRAGGING" 
      (cdkDragStarted)="select_rect_ondragstart($event)"
      (cdkDragMoved)="select_rect_ondrag($event)"
      (cdkDragEnded)="select_rect_ondragend($event)" -->

  <div
    style="position: absolute;bottom: 10px; right: 10px;z-index: 100;background-color: white;border-radius: 5px;padding: .2rem">

    <div (click)="zoomOut()" style="cursor: pointer">
      <nb-icon icon="plus-outline">plus-outline</nb-icon>
    </div>

    <div style="width: 100%;height: 1px;background-color: #8080803b;margin: 5px 0"></div>
    <div (click)="zoomIn()" style="cursor: pointer">
      <nb-icon icon="minus-outline">minus-outline</nb-icon>
    </div>
  </div>

  <div id="progress-bar-container">
    <app-multi-progress-bar [vertical]="false" [showInfo]="false" [progress]="progress"></app-multi-progress-bar>
  </div>

  <div class="example-portal-outlet" (mouseover)="showPortal=true" (mouseout)="showPortal=false" [style]="portalStyle" [style.visibility]="showPortal?'visible':'hidden'" [style.opacity]="showPortal?1:0">
    <ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>
  </div>

  <ngx-graph
    #graph
    id="graph"
    style="position: absolute"
    class="chart-container"
    (api)="api($event)"
    [links]="links"
    [nodes]="nodes"
    [panOffsetX]="1000"
    [draggingEnabled]="isEditEnable"
    [enableZoom]="graphSettings.zoomingEnabled"
    [panningEnabled]="!isCtrlKeyDown"
    [autoZoom]="false"
    [layout]="layout"
    [update$]="update$"
    [autoCenter]="false"
    [zoomToFit$]="zoomToFit$"
    [minZoomLevel]="0.1"
    [showMiniMap]="true"
    [miniMapPosition]="minMapPosition"
    [panToNode$]="panToNodeObservable"
    [view]="[containerRef.offsetWidth, containerRef.offsetHeight]"
    [curve]="curve"
    (zoomChange)="zoomChange($event)"
  >

    <div class="zoom-controls">
      <nb-icon class="zoom-controls__icons" icon="plus-outline" (click)="zoomOut()"></nb-icon>
      <nb-icon class="zoom-controls__icons" icon="minus-outline" (click)="zoomIn()"></nb-icon>
    </div>
    <ng-template #defsTemplate>
      <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4" markerHeight="4" orient="auto">
        <svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
      </svg:marker>
    </ng-template>



    <ng-template #nodeTemplate let-node>
      <svg:g class="node" #nodes [id]="node.id">
        <svg:foreignObject width="250" [attr.height]="70+(16*node.fields.length) + (4 * node.fields.length -1)" 
        [style]="{'--status-color': 'var(--'+(track_incoming_nodes.get(node.id) ? 'track' : getNodeInfo(node.id).status)+'-status-color)'}" 
        [attr.data-node-type]="node.metaData.name==='source_queue'?'queue':(node.metaData.name==='sink_queue'? 'queue': node.metaData.name)">
          <xhtml:div class="node"
          (mouseover)="trackNodes(node.id)" (mouseout)="reset_tracks()"
            [class]="['node-status-'+getNodeInfo(node.id).status,'boolean-weight-'+conditional_node_status.get(node.id), track_incoming_nodes.get(node.id) === true? 'track' : 'donot-track', 'node-type-'+node.type]"
           (click)="singleSelectNode(node.id,$event)">
            <!-- [class]="['node-status-'+getNodeInfo(node.id).status,'boolean-weight-'+conditional_node_status.get(node.id), track_incoming_nodes.get(node.id) == true? 'track' : 'donot-track']" -->

            <div style="display: flex;gap: .5rem;padding: 5px;height: 30px;background-color: rgba(var(--status-color, 0,200,0), .2)">
              <div class="action-icon" *ngIf="nodesConfigMap[node.metaData?.nodeName]?.icon">
                <img style="width: 1rem; height: 1rem;" [attr.src]="nodesConfigMap[node.metaData.nodeName].icon"/>
              </div>

              <div class="node-label" >{{node.name}} </div>

              <!-- Progress Icon -->
              <div 
                style="margin-left:auto;"
                class="action-icon" >
                <!-- <nb-spinner message="" status="warning" size="tiny"
                            style="background-color: transparent;position: unset;"
                            *ngIf="getNodeInfo(node.id).status==='in_progress'"></nb-spinner> -->

                <!-- <nb-icon
                  [nbTooltip]="getNodeInfo(node.id).message||getNodeInfo(node.id).status"
                  [icon]="nodeStatusIcons[getNodeInfo(node.id).status]"
                  [class]="'node-status-icon-'+getNodeInfo(node.id).status"
                  *ngIf="getNodeInfo(node.id).status!=='in_progress'"></nb-icon> -->

                <!-- <span 
                  [nbTooltip]="getNodeInfo(node.id).message||getNodeInfo(node.id).status"
                  class="material-icons-round"
                  [class]="'node-status-icon-'+getNodeInfo(node.id).status"
                  *ngIf="getNodeInfo(node.id).status!=='in_progress'"
                  style="font-size: var(--unnamed-font-size-16);"
                  >
                    {{nodeStatusIcons[getNodeInfo(node.id).status]}}
                  </span> -->
                <span 
                  class="material-icons-round"
                  [class]="'node-status-icon-new'"
                  style="font-size: var(--unnamed-font-size-16);"
                  >
                    {{nodeStatusIcons.new}}
                  </span>

              </div>
            </div>

            <div style="display: flex;flex-direction: column;height: inherit;gap:4px" *ngIf="node.fields.length">


              <ng-container *ngFor="let field of node.fields">

                <div class="node-field config" style="display: flex;align-items: center;gap: .3rem;height: 16px;" *ngIf="field.type==='config'">
                  <div class="node-details" style="flex-grow: 1;display: flex;align-items: center;">
                    <span style="overflow:hidden;text-overflow: ellipsis;width:50%;">{{field.name}}</span>
                    <button style="border: none;margin: none;width:50%;">
                      {{
                          field.id === 'connection' ? (node?.metaData[field.id]?.name)||'NULL' : (node?.metaData?.serviceName)||'NULL' 
                      }}
                    </button>
                  </div>
                </div>


                <div class="node-field input" style="display: flex;align-items: center;gap: .3rem;height: 16px;" *ngIf="field.type==='input'" (contextmenu)="onFieldRightClick(node, field, $event)">
                  <span class="material-symbols-outlined" style="font-size: 18px;color: rgba(var(--status-color));">arrow_circle_right</span>
                  
                  <!-- <div (pointerdown)="connectorIconEventHandler(node, $event, [], field)" style="height: 12px;width: 12px;border-radius: 50%;background-color: rgba(var(--status-color));"></div> -->

                  <div class="node-details" style="flex-grow: 1;display: flex;align-items: center;justify-content: space-between;">
                    <span style="overflow:hidden;text-overflow: ellipsis;">{{field.name}}</span> 
                    <button style="border: none;margin: none;" (click)="openNodeMapper()">Edit</button>
                  </div>
                </div>


                <div class="node-field output"  style="display: flex;justify-content: space-between;align-items: center;gap: .3rem;height: 16px;"  *ngIf="field.type==='output'">
                  <div class="node-details" [title]="field.name" style="flex-grow: 1;display: flex;align-items: center;justify-content: space-between;">
                    <span style="overflow:hidden;text-overflow: ellipsis;">{{field.name}}</span> 
                    <button style="border: none;margin: none;margin-left: auto;" (click)="openOutputPreview(node, field)">Preview</button>
                  </div>

                  <!-- <span class="material-symbols-outlined" style="font-size: 18px;color: rgba(var(--status-color));" [style]="{'color': field.isError?'red':'rgba(var(--status-color))'}">{{field.isError?'do_not_disturb_on':'arrow_circle_right'}}</span> -->
                  <span (click)="setSource(node)" (pointerdown)="connectorIconEventHandler(node, $event, [], field)" style="height: 12px;width: 12px;border-radius: 50%;background-color: rgba(var(--status-color));cursor: pointer;" [style]="{'background-color': field.isError?'red':'rgba(var(--status-color, 0,200,0))'}"></span>
                </div>


                <div class="node-field add" style="display: flex;align-items: center;gap: .3rem;height: 16px;" *ngIf="field.type==='add'">
                  <div class="node-details" style="flex-grow: 1;display: flex;align-items: center;">
                    <span class="material-symbols-outlined" style="font-size: 18px;color: rgba(var(--status-color));">add</span>
                  </div>
                </div>

              </ng-container>
              
            </div>
            
         

            <!-- FIXME: V2 API Changes, reference node not defined
            <div style="padding: 5px;display: flex;gap: .2rem;align-items: center;cursor: pointer;" *ngIf="tasknode[node.id].parent_id" title="Reference Node" (click)="focusReferenceNode(node.id)">
              <span class="material-icons-outlined" style="color: #8c8c8c;font-size: var(--unnamed-font-size-16);">cast_connected</span>
              <span style="font-size: 12px;color: #8c8c8c;">{{tasknode[node.id].parent_id}}</span>
            </div> -->

            <div class="arrow" *ngIf="node.type==='management'" title="Reference Node"></div>

            <div class="node-actions">

              
              <!-- <code style="color: currentColor;">{{node.id}}</code> -->
              

              <!-- Delete Icon -->
              <div (click)="deleteNode(node.id)" style="margin-right: auto;" class="node-action-icon">
                <span nbTooltip="delete Node" class="material-icons-outlined" style="color: currentColor;font-size: var(--unnamed-font-size-16);">delete</span>
                <!-- <nb-icon icon="trash-2-outline" class="preview-config" style="stroke: #0091ea;color: #0091ea"></nb-icon> -->
              </div>

              <!--Copy Node-->
              <div (click)="copyNode(node.id)" class="node-action-icon">
                <span class="material-icons-outlined" style="color: #4C7FF0;font-size: var(--unnamed-font-size-16);">content_copy</span>
                <!-- <nb-icon icon="eye-outline" class="preview-config" style="stroke: #0091ea;color: #0091ea"></nb-icon> -->
              </div>
              
              <!--Run Node-->
              <div class="node-action-icon" (click)="runNodeInstance(node)">
                <span class="material-icons-outlined" style="color: #4C7FF0;font-size: var(--unnamed-font-size-24);">play_arrow</span>
                <!-- <nb-icon icon="eye-outline" class="preview-config" style="stroke: #0091ea;color: #0091ea"></nb-icon> -->
              </div>

              <!-- <div (click)="clickNode(pipelineId,processId,node.id)">-->
                <div *ngIf="getNodeInfo(node.id).status==='success' || getNodeInfo(node.id).status==='stopped'" (click)="openOutput(node)" class="node-action-icon">
                  <span class="material-icons-outlined" style="color: #4C7FF0;font-size: var(--unnamed-font-size-16);">visibility</span>
                  <!-- <nb-icon icon="eye-outline" class="preview-config" style="stroke: #0091ea;color: #0091ea"></nb-icon> -->
                </div>

                <div *ngIf="node.type==='sink' && getNodeInfo(node.id).status==='in_progress'" class="node-action-icon" (click)="stopProcess()">
                  <nb-icon nbTooltip="Stop" icon="stop-circle-outline" class="stop" style="stroke: #ea0000;color: #ea0000; font-size: var(--unnamed-font-size-16);"></nb-icon>
                </div>
              
              <div class="action-icon" (click)="openNodeConfig(node)" class="node-action-icon">
                <span class="material-icons-outlined" style="color: #4C7FF0;font-size: var(--unnamed-font-size-16);" >settings</span>
                <!-- <nb-icon icon="settings-outline" class="preview-config" style="stroke: #0091ea;color: #0091ea"></nb-icon> -->
              </div>


              <!-- <div class="action-icon" *ngIf="getNodeInfo(node.id).status">
                <nb-spinner message="" status="warning" size="tiny"
                            style="background-color: transparent;position: unset;"
                            *ngIf="getNodeInfo(node.id).status==='in_progress'"></nb-spinner>

                <nb-icon
                  [nbTooltip]="getNodeInfo(node.id).message||getNodeInfo(node.id).status"
                  [icon]="nodeStatusIcons[getNodeInfo(node.id).status]"
                  [class]="'node-status-icon-'+getNodeInfo(node.id).status"
                  *ngIf="getNodeInfo(node.id).status!=='in_progress'"></nb-icon>

              </div> -->
            </div>


        
            <!-- <div (click)="setSource(node)" *ngIf="!(node.type==nodeType.CONDITIONAL)" (mouseover)="onConnectorHover(node, $event)" (mouseout)="showPortal=false" style="z-index: 10;" (pointerdown)="connectorIconEventHandler(node, $event)" (pointerup)="connectorIconEventHandler(node, $event)" (mouseout)="showPortal=false">
              <span class="connector material-icons-outlined">add_circle</span>
            </div>
            <div (click)="setSource(node,true)" *ngIf="node.type==nodeType.CONDITIONAL" (pointerdown)="connectorIconEventHandler(node, $event, ['right'])" (pointerup)="connectorIconEventHandler(node, $event)">
              <span class="true" nbTooltip="true"></span>
            </div>
            <div (click)="setSource(node,false)" *ngIf="node.type==nodeType.CONDITIONAL" (pointerdown)="connectorIconEventHandler(node, $event, ['bottom'])" (pointerup)="connectorIconEventHandler(node, $event)">
              <span class="false" nbTooltip="false"></span>
            </div>  -->



          </xhtml:div>

        </svg:foreignObject>
      </svg:g>
    </ng-template>

    <ng-template #linkTemplate let-link>
      <svg:g class="edge">

        <svg:path [class]="['line', 'linktype-'+link.type,track_incoming_edges.get(link.id) == true? 'track-line' : 'donot-track']" x2="20" y2="0" style="stroke:#8c8c8c;stroke-width:2"

                  ></svg:path>


        <svg:text class="edge-label" text-anchor="middle">
          
          <!-- <textPath
            class="delete-edge"
            [attr.href]="'#' + link.id"
            [style.dominant-baseline]="link.dominantBaseline"
            startOffset="50%"
            *ngIf="!track_incoming_edges.get(link.id)"
            nbTooltip="Remove edge"
            (click)="removeEdge(link)"
          >
          ✖
          </textPath>  -->

          <textPath
            class="text-path"
            [attr.href]="'#' + link.id"
            [style.dominant-baseline]="link.dominantBaseline"
            startOffset="50%"
            *ngIf="track_incoming_edges.get(link.id)"
          >
            <!--  *ngIf="track_incoming_edges.get(link.id)" -->
       
              {{link.type + ' '}}{{link.label}}
              
           
          </textPath>
        </svg:text>
      </svg:g>

      <svg:g
        *ngIf="link.midPoint&&!track_incoming_edges.get(link.id)"
        [attr.transform]="'translate(' + (link.points[link.points.length-1].x-22) + ',' + (link.points[link.points.length-1].y - 9)+ ')'"
      >
      <!-- [attr.transform]="'translate(' + (link.midPoint.x-9)+ ',' + (link.midPoint.y-9) + ')'" -->
      <!-- [attr.transform]="'translate(' + (link.points[link.points.length-1].x - 20) + ',' + link.points[link.points.length-1].y + ')'" -->
        <!-- <circle alignment-baseline="central" cx="0" cy="0" r="6" stroke="black" stroke-width="0" fill="red"  /> -->
        <svg:foreignObject width="16" height="16" class="edge-transform">
          <div class="edge-connector" (click)="setEdgeTransformation()" title="Trensformation">
            <span class="material-symbols-outlined connector-icon" style="color: white;font-size: 16px;">function</span>
          </div>
            <!-- <span class="material-symbols-outlined edge-transform-icon">function</span> -->
        </svg:foreignObject>
        <!-- <svg:text alignment-baseline="central">a</svg:text> -->
      </svg:g>

    </ng-template>
  </ngx-graph>
  <div class="legend" nbTooltip="edge legend">
    <button  nbButton [nbPopover]="edge_legend" nbPopoverPlacement="bottom" ><nb-icon icon="shuffle-outline" ></nb-icon></button>
  </div>
</div>


<!-- FIXME: use portal for context menu -->
<div 
class="custom-contextmenu" #contextmenu>
  <div>
    <div *ngFor="let item of contextMenuItems" [class.disabled]="item.disable" (mousedown)="item.onClick($event)">{{item.text}}</div>
  </div>
</div>

<ng-template #edge_legend>
    <div class="legend-body">
      <div class="legend-item" *ngFor="let edge of legend_edges; let i = index" >
        <span class="legend-color">
        <svg width="3rem" height="3rem"  [attr.fill]="edge.color" viewBox="0 0 10 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="8" r="3" />
        </svg>
      </span>
        <span class="legend-text">
          {{edge.name}}
        </span>
      </div>
    </div>
</ng-template>

<ng-template>
  <nb-button-group style="position: absolute; bottom: 1rem; left: 2rem">
    <!-- <button nbButton nbTooltip="save node positions" (click)="savePosition();">SAVE</button> -->
    <button nbButton nbTooltip="download graph" (click)="saveTemplateAsFile('pipeline.json', graph)"><nb-icon icon="download-outline"></nb-icon>SAVE</button>
    <button nbButton nbTooltip="save node positions" (click)="resetPosition();">RESET</button>
    <!-- <button nbButtonToggle [pressed]="isEditEnable" (pressedChange)="updateForDrag()"> EDIT DAG</button> -->
  </nb-button-group>
</ng-template>



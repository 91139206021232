import {DataService, ServiceResponse} from "../core/data.service";
import {ConfigKeyModel, ConfigModel, ValueConfig} from "../../models/config.model";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UserValueModel} from "../../models/userValue.model";
import {Observable, Subject, of} from "rxjs";
import {map} from "rxjs/operators";
import {Context} from "../config/context.service";
import { schema_response } from "src/app/models/schema.model";

export type NodeServiceResponse = ServiceResponse<{
  config_id: number
  configDetails: ConfigModel
  configs: Array<ConfigKeyModel & { userValue: UserValueModel, userValues: UserValueModel }>
  parentConfigs: Array<ConfigKeyModel & { userValue: UserValueModel, userValues: UserValueModel }>
  created_time: Date
  id: number
  name: string
  parent_id: number,
  pipeline_id: number
  process_id: number
  schemaConfigs: schema_response,// TODO : need to create schema Model
  startTaskNodeId: Array<number>
  dependent_nodes:Array<{type: string, weight: boolean, taskId: number, order: number}>
  updated_time: Date
}>;

@Injectable({
  providedIn: 'root'
})
export class NodeService implements DataService<ConfigKeyModel> {
  private readonly url: string = ''
  private nodes: Record<number, NodeServiceResponse> = {}
  private nodeRequestQueue: Record<number, Array<Subject<NodeServiceResponse>>> = {}

  constructor(private context: Context, private http: HttpClient) {
    this.url = context.getApiConfig().PIPELINE_APIS.GET.taskConfigDetails
  }

  getNodeConfig(nodeId: number, startNodeIds: number[]): Observable<NodeServiceResponse> {

    return this.http.get<NodeServiceResponse>(this.url + "?taskId=" + nodeId+'&startNode='+startNodeIds)
    // if(this.nodes[nodeId]) {
    //   return of(this.nodes[nodeId])
    // }
    // let subscriber = new Subject<NodeServiceResponse>()
    // this.nodeRequestQueue[nodeId] = this.nodeRequestQueue[nodeId] || []
    // this.nodeRequestQueue[nodeId].push(subscriber)
    //
    // if(this.nodeRequestQueue[nodeId].length === 1) {
    //   let request = this.http.get<NodeServiceResponse>(this.url + "?taskId=" + nodeId+'&startNode='+startNodeIds)
    //   request.subscribe(response => {
    //     this.nodes[nodeId] = response
    //     this.nodeRequestQueue[nodeId].forEach(subs => {subs.next(response);subs.complete()})
    //     this.nodeRequestQueue[nodeId] = []
    //   })
    // }
    //
    // return subscriber.asObservable()
  }

  clear(nodeId: number) {
    if(this.nodes[nodeId]) delete this.nodes[nodeId]
  }
}

<header class="textarea-header">
    <ul>
        <li><span>{{title}}</span><span>: {{mode|titlecase}}</span></li>
        <li style="margin-left: auto;"></li>

        <li><button style="border: unset;padding: unset;background: unset;color: #EEFFFF;" title="Test Run" (click)="testScript()"><span class="material-icons-outlined">play_arrow</span></button></li>

        <li *ngIf="!editorConfig.readOnly&&canOpenFile"><button style="border: unset;padding: unset;background: unset;color: #EEFFFF;" (click)="loadFile()" title="Import File"><span class="material-icons-outlined">download</span></button></li>
        <li *ngIf="resizable"><button style="border: unset;padding: unset;background: unset;color: #EEFFFF;" (click)="resize()" [title]="isFullScreen? 'Minimize': 'Maximize'"><span class="material-icons-outlined">{{isFullScreen? 'close_fullscreen': 'fullscreen'}}</span></button></li>
        <li *ngIf="!editorConfig.readOnly"><button style="border: unset;padding: unset;background: unset;" (click)="save()" title="Save File" [disabled]="!isDirty" [style.color]="isDirty?'#EEFFFF':'#eeffff61'"><span class="material-icons-outlined">save</span></button></li>
        <li *ngIf="isPopup"><button style="border: unset;padding: unset;background: unset;color: #EEFFFF;" (click)="close()" title="Close Editor"><span class="material-icons-outlined">close</span></button></li>
    </ul>
</header>
<textarea #textarea></textarea>
<footer >
    <span>line {{currentLineNumber}}, Column {{currentColumnNumber}}</span>
    <span *ngIf="fileName">|</span>
    <span *ngIf="fileName" [title]="fileName" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:15rem">*{{fileName}}</span>
    
    <span *ngIf="editorConfig.readOnly">readonly</span>

    <span style="margin-left: auto;"></span>
    <span (click)="toggleFullScreen()" title="Full Screen">focus</span>
    <!-- <span (click)="testScript()" title="Run">run</span> -->

    <span>Spaces: {{codemirrorConfigs.tabSize}}, {{codemirrorConfigs.mode}}</span>
</footer>

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-multi-progress-bar',
  templateUrl: './multi-progress-bar.component.html',
  styleUrls: ['./multi-progress-bar.component.css']
})
export class MultiProgressBarComponent implements OnInit, OnChanges {

  @Input() vertical = false
  @Input() showInfo = true

  constructor() { }

  @Input() progress:Array<{count: number, color: string, name: string, countShow:string|number, tooltipStatus: string}> = [
    // {
    //   count: 30,
    //   color: '#1bc965',
    //   name: 'Success',
    //   countShow: 4,
    //   tooltipStatus: 'success'
    // },
    // {
    //   count: 20,
    //   color: 'orange',
    //   name: 'Running',
    //   countShow: 3,
    //   tooltipStatus: 'warning'
    // },
    // {
    //   count: 15,
    //   color: 'red',
    //   name: 'Error',
    //   countShow: 2,
    //   tooltipStatus: 'danger'
    // }
  ]

  ngOnInit(): void {
  }

  ngOnChanges(changes:SimpleChanges) {
    if(changes.progress) {
      this.progress = changes.progress.currentValue
    }
  }

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {

  public id: string = '';
  public type: string = '';
  public field: string = '';
  public index: any;//number
  public params: any;

  public parentComponent: any;

  @ViewChild('menuButton', { static: true }) menuButton: any;

  public typeMap: any = {
    object: 'String',
    string: 'String',
    int64: 'Integer',
    integer: 'Integer',
    float64: 'Decimal',
    double: 'Decimal',
    datetime64: 'Date',
    date: 'Date',
    boolean: 'Boolean'
  };

  constructor() { }

  ngOnInit() {
  }

  agInit(params:any) {
    this.params = params;
    this.parentComponent = params.context.componentParent;
    // console.log(params);
    const { id, type, field, index } = params;
    this.id = id;
    this.type = type;
    this.field = field;
    this.index = index;
  }

  refresh(params:any): boolean {
    console.log('refreshed');
    this.params = params;
    return true;
  }

  onMenuClicked() {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  }

  toggleMenu(event:any, columnId:any): void {
    const { x, y, width } = event.target.getBoundingClientRect();
    this.parentComponent.setCurrentMenuPosition(parseInt(x, 10), parseInt(y, 10), parseInt(width, 10), event, columnId);
  }

}

import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NbTabsetComponent } from '@nebular/theme';
import { ConsoleLoggerService } from 'src/app/services/logger/console-logger.service';
import { BaseInput, ConfigResponseType, DataProxy, FormObject, buildForm, getState, match, restoreState, setToggleStateFromResponse } from './helper-functions';

// const getId = function(dataProxy: DataProxy, keyId: number, order: number) {
//   return dataProxy[keyId]?.[order].Id
// }


export class FormState {
  constructor(private configObject: any, private dataObject: DataProxy) {}
  // eslint-disable-next-line no-use-before-define
  restore<T extends {restore:(configObject: any, dataObject: DataProxy) => T}>(component: T) {
    component.restore(this.configObject, this.dataObject)
  }
}

@Component({
  selector: 'app-json-form',
  templateUrl: './json-form.component.html',
  styleUrls: ['./json-form.component.scss']
})
export class JsonFormComponent implements OnInit, AfterViewInit {

  formData: DataProxy = {}
  Request!: FormObject
  Variables!: FormObject
  Headers!: FormObject

  changes:{[key: string]:Array<any>} = {}

  dropdownData: Array<{key: number, value: string, data_type:string}> = []

  @ViewChild("tabset") tabsetEl!: NbTabsetComponent;
  selectedTab: 'Request' | 'Variables' | 'Headers' = 'Headers'

  castArray(item: Array<any>) {
    return item
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public dialogRef: MatDialogRef<JsonFormComponent>,
    private logger: ConsoleLoggerService,) { 
    
    this.dropdownData = dialogParams.dropdownData
    
  }

  ngOnInit(): void {
    if(!this.dialogParams.state) {

      // fill form data
      this.refillMappings(this.dialogParams.defaultValue.mapping, this.dialogParams.schema)
      this.enableParentNodes(this.dialogParams.schema, this.dialogParams.defaultValue.mapping)

      // Create form
      this.Request = this.buildForm(this.dialogParams.schema, this.formData)
      this.Variables = this.buildForm(this.dialogParams.variables, this.formData)
      this.Headers = this.buildForm(this.dialogParams.headers, this.formData)
      this.appendChildElements(this.dialogParams.defaultValue.mapping)

      // this.enableParentNodes(this.dialogParams.schema, this.dialogParams.defaultValue.mapping)
    }

    else {
      (this.dialogParams.state as FormState).restore(this);
    }
  }

  ngAfterViewInit(): void {
    let activeTab = this.tabsetEl.tabs.find(x => x.tabId === this.selectedTab)!;
    this.tabsetEl.selectTab(activeTab);  
  }

  private rendomOrderId = (new Date()).getTime()
  addItem(item: any) {
    console.log(item)
    if(item.append)item.append(undefined, this.rendomOrderId++)
  }

  deleteItem(item: any, parent: any) {
    console.log(item, parent)
    if(parent.removeItem)parent.removeItem(item)
  }

  buildForm(schema: any, formData: DataProxy) {
    return buildForm(schema, formData, null) as FormObject
  }

  refillMappings(mappings: Array<ConfigResponseType>, schema: any) {
    const metadata = this.getKeyToMetedataMap(schema)

    mappings.forEach(mapping => {
      this.fillValue(mapping, metadata)
    })
  }

  private appendChildElements(mappings: Array<ConfigResponseType>) {
    mappings.forEach(mapping => {
      if(mapping.order > 0) {

        let [root, element] = this.Request.includes(mapping.apiKeyId as number)

        // Check in elements of request 
        if(root && root.append && root._elements.length < mapping.order + 1) root.append(undefined, mapping.order);

        // Check in elements of variable 
        else {
          [root, element] = this.Variables.includes(mapping.apiKeyId as number)
          if(root && root.append && root._elements.length < mapping.order + 1) root.append(undefined, mapping.order);

          // Check in elements of header 
          else {
            [root, element] = this.Headers.includes(mapping.apiKeyId as number)
            if(root && root.append && root._elements.length < mapping.order + 1) root.append(undefined, mapping.order);
          }
        }
      }
    })
  }

  private fillValue(mapping: ConfigResponseType, keyMetadata: {[keyId: number]: any}) {
    if(!this.formData[mapping.apiKeyId as number]) this.formData[mapping.apiKeyId as number] = {}
    if(!this.formData[mapping.apiKeyId as number][mapping.order])this.formData[mapping.apiKeyId as number][mapping.order] = {}
    
    this.formData[mapping.apiKeyId as number][mapping.order].$metadata = keyMetadata[mapping.apiKeyId as number] || {}

    this.formData[mapping.apiKeyId as number][mapping.order].Id = mapping.Id
    this.formData[mapping.apiKeyId as number][mapping.order].value = mapping.value
    this.formData[mapping.apiKeyId as number][mapping.order].columnKeyName = mapping.columnKeyName
    setToggleStateFromResponse(mapping, this.formData[mapping.apiKeyId as number][mapping.order] as any)
  }

  saveData() {

    try {
      this.changes = {
        Request: this.Request.getValue(),
        Headers: this.Headers.getValue(),
        Variables: this.Variables.getValue()
      }
      

      const processChanges = (prop: string) => {
        let changes = this.changes[prop];

        const newIds = changes.map(x => x.Id)
        const oldIds = (this.dialogParams.defaultValue.mapping as Array<ConfigResponseType>).map(x => x.Id)

        const removedIds = oldIds.filter(x => !newIds.includes(x))

        this.changes[prop] = this.removeUnchanged(changes)
        this.appendRemovedIds(changes, removedIds as Array<number>)
        this.appendUpdateAction(changes)
        this.appendCreateAction(changes)
      }

      Object.keys(this.changes).forEach(prop => processChanges(prop));

      this.logger.log(this.changes)
      
    } catch(error) {
      console.error(error)
      alert(error)
    }
    // this.appendIds(this.changes)
    // this.normalizeOrderId(this.changes)

    
  }

  // normalizeOrderId(changes: Array<Partial<ConfigResponseType>>) {
  //   const memo:{[id: number]: number} = {}

  //   changes.forEach(change => {
  //     if(!memo[change.Id as number]) memo[change.Id as number] = -1
  //     memo[change.Id as number]++;
  //     change.order = memo[change.Id as number]
  //   })
  // }

  // appendIds(changes: Array<Partial<ConfigResponseType>>) {
  //   changes.forEach(change => change.Id = getId(this.formData, change.apiKeyId as number, change.order as number))
  // }
  appendCreateAction(changes: Array<Partial<ConfigResponseType>&{action: string}>) {
    changes.forEach(change => {
      if(!change.Id) change.action = 'create'
    })
  }
  appendUpdateAction(changes: Array<Partial<ConfigResponseType>&{action: string}>) {
    changes.forEach(change => {
      if(change.Id && !change.action) change.action = 'update'
    })
  }

  appendRemovedIds(changes: Array<Partial<ConfigResponseType>&{action: string}>, removedIds: Array<number>) {
    removedIds.forEach(Id => changes.push({Id, action: 'delete'}))
  }

  removeUnchanged(changes: Array<Partial<ConfigResponseType>&{action: string}>) {
    const oldValueIdMap = (this.dialogParams.defaultValue.mapping as Array<ConfigResponseType>).reduce((prev, curr) => {

      if(!prev[curr.apiKeyId as number])prev[curr.apiKeyId as number] = {}
      if(!prev[curr.apiKeyId as number][curr.order])prev[curr.apiKeyId as number][curr.order] = {}
      Object.assign(prev[curr.apiKeyId as number][curr.order], curr)

      return prev
    }, {} as DataProxy)
    return changes.filter(change => {
      if(oldValueIdMap[change.apiKeyId as number]?.[change.order as number]) {
        return !match(oldValueIdMap[change.apiKeyId as number][change.order as number] as any, change)
      }
      return true
    })
  }

  getState() {
    // return new FormState(JSON.parse(JSON.stringify(this.dialogParams.schema)), this.formData)
    return new FormState(JSON.parse(JSON.stringify({
      Request: this.Request ? getState(this.Request) : null,
      Variables: this.Variables ? getState(this.Variables) : null,
      Headers: this.Headers ? getState(this.Headers) : null,
      selectedTab: this.selectedTab
    })), this.formData)
  }

  restore(configObject: any, formData: DataProxy) {
    this.formData = formData || {}
    this.Request =  restoreState(configObject.Request || [], formData) as FormObject
    this.Variables = restoreState(configObject.Variables || [], formData) as FormObject
    this.Headers = restoreState(configObject.Headers || [], formData) as FormObject

    // change active tab
    if(configObject.selectedTab) {
      this.selectedTab = configObject.selectedTab;
    }
    return this
  }

  close() {
    // Send changes as flat array
    let changes = Object.keys(this.changes).reduce((arr, key) => {
      return arr.concat(this.changes[key])
    }, [] as Array<ConfigResponseType>)

    this.dialogRef.close({response: changes, state: this.getState()});  
  }

  getParentChildMap(parent:number|null, schema: any, memo:{[key:number]:number|null}={}): any {

    if(schema.id) {
      memo[schema.id] = parent
    }

    if(schema.children) {
      for(const child of schema.children) this.getParentChildMap(schema.id||parent, child, memo)
    }

    if(schema.child) {
      this.getParentChildMap(schema.id || parent, schema.child, memo)
    }

    return memo
  }

  getParentChildList(schema: any, parent:number|string, memo:any = {null: []}): any {

    if(schema.id) {
      memo[schema.id] = []
      memo[parent].push(schema.id)
    }

    if(schema.children) {
      for(const child of schema.children) this.getParentChildList(child, schema.id||parent, memo)
    }

    if(schema.child) {
      this.getParentChildList(schema.child, schema.id || parent, memo)
    }


    if(schema.id) {
      for(let el of memo[schema.id]) {
        if(el === schema.id) break
        memo[parent].push(el) 
      }
    }
    return memo
  }

  getKeyToMetedataMap(schema: any, parent:number|null=null, memo:{[key:number]:any}={}): any {

    if(schema.id) {
      memo[schema.id] = parent || schema
    }

    if(schema.children) {
      for(const child of schema.children) this.getKeyToMetedataMap(child, parent, memo)
    }

    if(schema.child) {
      this.getKeyToMetedataMap(schema.child, schema, memo)
    }

    return memo
  }

  // change parent Node toggle state
  enableParentNodes(schema: any, mappings: Array<ConfigResponseType>) {

    let currentMappingIds = mappings.reduce((prev, curr) => {
      if(!prev[curr.apiKeyId as number]) prev[curr.apiKeyId as number] = 0
      prev[curr.apiKeyId as number] += 1;
      return prev
    }, {} as {[key: number]: number})

    let struct = this.getParentChildList(schema, 'null');


    //#region set null to empty parent nodes
    const parents = Object.entries(struct).map(([key, value]: any) => ({key, value})).filter(x => x.key!=='null' && x.value.length);
    const parentChild = parents.map(x => {
      let obj = {id: x.key, hasChild: false};

      for(let el of x.value as number[]) {
        if(currentMappingIds[el]) {
          obj.hasChild = true;
          break
        }
      }

      return obj
    })

    const parentWithChild = parentChild.filter(x => x.hasChild);
    // const nonParent = nonEmptyParent.filter(x => !currentMappingIds[x.id])

    parentWithChild.forEach(el => {
      let isArray = `${struct[el.id][0]}` === `${el.id}`

      if(!this.formData[el.id]) this.formData[el.id] = {}

      if(isArray) {
        this.formData[el.id][-1] = {toggle: 0}
      }

      else {
        this.formData[el.id][0] = {toggle: 0}
        this.formData[el.id][-1] = {toggle: 0}
      }
    })
    //#endregion


    const children = Object.entries(struct).map(([key, value]: any) => ({key, value})).filter(x => x.key!=='null' && x.value.length===0);
    console.log(struct, parents, children)
  }
}

import { Subject } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Pipelinev2Service } from 'src/app/services/api/v2/pipelinev2.service';


abstract class FormRemap {
  constructor() { }
  abstract loadForm(form: Form, formData: any, target: Input): unknown;
}

export class ManualTriggerInputRemap extends FormRemap {
  private sectionForms: { [key: string]: Form; };
  private activeSection!: { description: string; name: string; label: string; order: number; input_fields: any; };

  constructor(private pipelineId: number, private pipelineService: Pipelinev2Service, private processId: number, private pipelineVersionId: number, activeSection: { description: string; name: string; label: string; order: number; input_fields: any; }, sectionForms: { [key: string]: Form; }) {
    super();
    this.sectionForms = sectionForms;
    this.activeSection = activeSection;

  }
  async loadForm(form: Form, formData: any, target: Input) {
    // this.pipelineService.setLoaderState(true) // setting loader to true
    try {
      target.isLoading = true;
      const allFormData = [];
      for (const sectionName in this.sectionForms) {
        if (sectionName !== 'run_Pipeline') {
          {
            allFormData.push(...this.sectionForms[sectionName].getValue());
          }
        }
      }

      let obj: any = {};

      allFormData.forEach(x => {
        obj[x.name] = { "value": x.value };
        // obj[x.name] = { "value": x.value?.hasOwnProperty('raw') ? x?.value : {'raw': x?.value }};

      });

      // TODO: rename
      const xyz = await this.pipelineService.manualTriggerInputRemap(this.pipelineId, this.processId, this.pipelineVersionId, obj, this.activeSection).toPromise();
      
      for (const section of xyz) {
        if(section?.name in this.sectionForms) {
          const sectionName = section.name as string

          this.sectionForms[sectionName].build(Object.values(section.input_fields))
        }
      }
      // form.build(xyz);
    }
    catch(error) {
      console.log(error);
    }
    finally {
      target.isLoading = false;
    }
    // xyz.forEach(x=>{
    //   if (x.validated)
    // })
  }
}
export class Input {
  public childrens: Array<Input> = [];
  constructor(protected form: Form, public inputConfig: InputType) {
    this.isLoading = false;
    this.name = inputConfig.name;
    this.order = inputConfig.order
    this.value = (inputConfig as any).value || inputConfig.default;
    this.disabled = inputConfig.disabled || false;
    this.visible = inputConfig.visible || true;
    this.errors = inputConfig.error_message?.errors as any || [];
    this.options = inputConfig.options || [];
    this.dataType = inputConfig.dataType;
    this.formatType = inputConfig.formatType;
    this.required = inputConfig.required || false;
    this.description = inputConfig.description || '';
    this.section = inputConfig.section || '';
    this.sectionComplete = inputConfig.sectionComplete || false
    this.validated = inputConfig.validated || false

    
  }
  description: string;
  order:number
 
  showPassword: boolean = false;
  required: boolean;
  section: string;
  dataType: 'string' | 'integer' | 'float' | 'date_time' | 'date' | 'timestamp' | 'boolean' | 'file' | 'multi_file';
  formatType: 'text' | 'text_area' | 'password' | 'email' | 'phone' | 'integer' | 'float' | 'url' | 'select' | 'multi_select' | 'checkbox' | 'date' | 'date_time' | 'file' | 'radio' | 'multi_file';
  options: Array<any>;
  errors: Array<any>; 
  isLoading: boolean; 
  name: string;
  validated:boolean = false
  sectionComplete:boolean = false
  protected _value: string | number | boolean | Array<any> | undefined;

  get value() {
    if (this.formatType === 'multi_select' && this._value === null) {
      this._value = [];
    }
    return this._value;
  }

  set value(value) {
    this._value = value;
  }

  protected _disabled: boolean = false;
  get disabled() {
    return !!this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }


  protected _visible: boolean = true;
  get visible() {
    return !!this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
  }
  
  public getValue() {
    if (this.dataType === 'timestamp') {
      let ts: number = 1;
      if (this.value) {
        if (typeof this.value === 'string') {
          ts = Date.parse(this.value);
        }
        return ts;
      }

    }
    if (this.value === '') {
      return null;
    }
    return this.value;
  }


  public onLoad() {
    for (let child of this.childrens) {
      child.onLoad();
    }
    console.log(`${this.name} loaded.`);
  }

  public onBlur() {
    console.log(this.form);
    setTimeout(() => {
      this.form.eventSource.next({ type: 'blur', target: this });
    }, 10);
  }

  keyupEvent() {
    if (typeof this._value === 'number') {
      this._value = Math.trunc(this._value);
    }
  }

}

export class FileInput extends Input {
  public onLoad(): void {
    super.onLoad();
    this._value = (this._value || '').toString().toLowerCase() === 'true';
    this.errors = []
  }
}

export class MultiFileInput extends Input {
  public onLoad(): void {
    super.onLoad();
    this._value = (this._value || '').toString().toLowerCase() === 'true';
  }
}

class ObjectInput extends Input {
  public getValue() {
    const data = super.getValue();
    return data;
  }
}
class BooleanInput extends Input {
  public onLoad(): void {
    super.onLoad();
    this._value = (this._value || '').toString().toLowerCase() === 'true';
  }
}
class DropdownInput extends Input {
  options: Array<any> = [];
  constructor(form: Form, inputConfig: InputType) {
    super(form, inputConfig);
    this.options = inputConfig.options || [];
  }
}
class DatetimeInput extends Input {
  get value() {
    console.log('from datetimeInput GET', this._value);
    return this._value;
  }

  set value(value: any) {
    console.log('from datetimeInput SET', this._value);
    this._value = "2023-01-20T14:04";
  }
}
class InputFactory {
  private memo: Map<string, typeof Input> = new Map();

  private getProxy(input: Input) {
    return new Proxy(input, {
      get(target, prop: string, receiver) {
        if (prop in target)
          return (target as any)[prop];
        return (target as any).childrens.find((x: any) => x.key === prop);
      },
    });
  }



  public register(inputType: string, inputConstructor: any) {
    this.memo.set(inputType, inputConstructor);
  }

  public getInput(form: Form, config: InputType): Input | undefined {
    const inputConstructor = this.memo.get(config.formatType);
    if (inputConstructor) {
      const instance = new inputConstructor(form, config);
      return this.getProxy(instance);
    }
    console.warn(`Input type ${config.formatType} not registered!`);


    return undefined;
  }
}


export class Form {
  private inputFactory: InputFactory;
  constructor(_inputFactory?: InputFactory) {
    this.inputFactory = _inputFactory!;
    this.inputFactory = this.getInputFactory();
  }

  public eventSource = new Subject<{ type: string; target: Input; }>();
  public onChange$ = this.eventSource.asObservable().pipe(filter(x => x.type === 'change'));
  public onBlur$ = this.eventSource.asObservable().pipe(filter(x => x.type === 'blur'));

  private isLoaded: boolean = false;

  private inputs: { [key: string]: Input; } = {};
  public inputsInoder: Array<Input> = [];
  public build(inputs: Array<InputType>) {
    this.isLoaded = false;
    const _inputs: Array<Input> = [];
    this.inputs = {};
    this.inputsInoder = [];
    inputs.forEach(
      input => {
        const inputInstance = this.inputFactory.getInput(this, input);
        if (inputInstance) {
          this.setInputField(inputInstance);
          (input.children || []).forEach(child => inputInstance.childrens.push(this.inputFactory.getInput(this, child)!));

          _inputs.push(inputInstance);
        }
      }
    );

    _inputs.forEach(input => 
      {
      this.inputsInoder.push(input)
      this.inputsInoder.sort((a, b) => a.order - b.order)
    }
    );

    _inputs.forEach(
      input => input.onLoad()
    );
    this.isLoaded = true;
  }

  public setInputField(input: Input) {
    this.inputs[input.name] = input;
    console.log(input);
  }

  public updateAll(input: Input) {

    const Q: Array<Input> = [];
    for (const inputKey in this.inputs) {
      const input = this.inputs[inputKey];
    }
    if (this.isLoaded) {
      this.eventSource.next({ type: 'change', target: input });
    }
  }

  getValue() {
    const res = this.inputsInoder.map((input) => ({
      ...input.inputConfig,
      value: input.getValue(),
      children: input.childrens.map(ch => ({ ...ch.inputConfig, value: ch.getValue() }))
    }));
    console.log(res);
    return res;
  }

  // to set individual input externally
  updateInput(inputKey: string, value: any) {
    const input = this.inputs[inputKey];
    if (input) {
      input.value = value;
    }
  }

  private getInputFactory() {
    if (this.inputFactory) return this.inputFactory;
    const inputFactory = new InputFactory();
    inputFactory.register('group', Input);
    inputFactory.register('text', Input);
    inputFactory.register('text_area', Input);
    inputFactory.register('text_area', Input);
    inputFactory.register('password', Input);
    inputFactory.register('email', Input);
    inputFactory.register('phone', Input);
    inputFactory.register('integer', Input);
    inputFactory.register('float', Input);
    inputFactory.register('url', Input);
    inputFactory.register('select', Input);
    inputFactory.register('multi_select', Input);
    inputFactory.register('checkbox', Input);
    inputFactory.register('radio', Input);
    inputFactory.register('date', Input);
    inputFactory.register('date_time', Input);
    inputFactory.register('number', Input);
    inputFactory.register('file', Input);
    inputFactory.register('multi_file', Input);


    return inputFactory;
  }
}
export class FormUpdateHandler {
  private sectionForms: { [key: string]: Form; };
  private activeSection!: { description: string; name: string; label: string; order: number; input_fields: any; };
  protected sub: any;


  constructor(protected form: Form, private pipelineService: Pipelinev2Service, private formRemap: FormRemap, activeSection: { description: string; name: string; label: string; order: number; input_fields: any; }, sectionForms: { [key: string]: Form; }) {
    this.sectionForms = sectionForms;
    this.activeSection = activeSection;
  }

  public subscribe() {
    this.sub = this.sectionForms[this.activeSection.name].onChange$.pipe(debounceTime(500)).subscribe(this.onChangeHandler.bind(this));
  }
  
  protected onChangeHandler({ target }: { target: Input; }) {
    const data = this.form.getValue();
    const newForm = data;
    let obj: any = {};
    newForm.forEach(x => {
      obj[x.name] = { "value": x.value };
      // obj[x.name] = { "value": x.value?.hasOwnProperty('raw') ? x?.value : {'raw': x?.value }};

    });

    //call api
    //this.loadForm(obj);
    //console.log(this.form)
    //this.formRemap(this.form,obj)
    this.formRemap.loadForm(this.form, obj, target);
  }
}

export class FormUpdateHandlerOnBlur extends FormUpdateHandler {
  public subscribe() {
    this.sub = this.form.onBlur$.subscribe(this.onChangeHandler.bind(this));
  }
}

export type InputType = {
  children?: Array<InputType>;
  dataType: 'string' | 'integer' | 'float' | 'date_time' | 'date' | 'timestamp' | 'boolean' | 'file' | 'multi_file';
  formatType: 'text' | 'text_area' | 'password' | 'email' | 'phone' | 'integer' | 'float' | 'url' | 'select' | 'multi_select' | 'checkbox' | 'date' | 'date_time' | 'file' | 'radio' | 'multi_file';
  name: string;
  order:number;
  label: string;
  description?: string;
  required?: boolean;
  options?: Array<any>;
  default?: any;
  value?: any;
  disabled?: boolean;
  visible?: boolean;
  errors?: Array<string>;
  section?: 'basic_conf' | 'upload_assignment'| 'run_Pipeline';
  validated?:boolean
  error_message?: {
    type: string
    message: string
    errors: {
      [key: string]: any
    }
  }
  sectionComplete?:boolean
};

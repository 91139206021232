import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DynamicField, Node} from '../../models/tasknode.model'
import {NodesConfigAPIResponse, PipelineAPIResponse} from "../api/pipeline.service";
import {NbToastrService} from "@nebular/theme";
import {ActivatedRoute} from "@angular/router";
import {NodeStatusResponse, NodeStatusService} from "../api/node-status.service";
import {TaskService} from "../api/task.service";
import { ConfigModel } from 'src/app/models/config.model';
import { ConfigService } from '../api/config.service';
import { Context } from '../config/context.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { NodePositionService } from '../api/node-position.service';

export type DashboardConfig = {
  isViewMode: boolean,
  taskReloadDelay: number,
  processId?: number,
  recentUsedMaxCount: number
}

@Injectable({ providedIn: 'root' })
export class DashboardStoreService {
  private readonly _pipelineSource = new BehaviorSubject<PipelineAPIResponse['payload']|null>(null);
  private readonly _currentNodeSource = new BehaviorSubject<Node|null>(null);
  private readonly _currentOutputSource = new BehaviorSubject<Node|null>(null);
  private readonly _nodesConfigSource = new BehaviorSubject<NodesConfigAPIResponse['payload']>([]);
  private readonly _configSource = new BehaviorSubject<DashboardConfig>({isViewMode: true, recentUsedMaxCount: 5, taskReloadDelay: 10*1000});


  readonly pipeline$ = this._pipelineSource.asObservable();
  readonly currentNode$ = this._currentNodeSource.asObservable();
  readonly config$ = this._configSource.asObservable();
  readonly nodesConfig$ = this._nodesConfigSource.asObservable();


  readonly recentUsedConfigIds: Array<number> = []  
  constructor( 
    private toasterService: NbToastrService,
    private route: ActivatedRoute,
    private nodeStatusService: NodeStatusService,
    private taskService: TaskService,
    private configService: ConfigService,
    private context: Context,
    private http:HttpClient,
    private nodePositionService: NodePositionService
  ) {

    let recentTasks = this.context.getContextFromStorage('dashboard.dag.recent-tasks');
    if(Array.isArray(recentTasks)) {
      this.recentUsedConfigIds = recentTasks
    }

    // register events
    this.registerEvents()
    

    route.queryParams.subscribe(queryParams => {
      let config:{[key: string]: string} = {}
      if(queryParams.config) {
        try{
          config = JSON.parse(atob(queryParams.config))
        } catch (e) {
          console.error('invalid queryParams')
        }
      }
      this._configSource.next({...this._configSource.getValue(), ...config, isViewMode: !(Boolean(config.edit))})
    })
  }

  registerEvents() {

    // recent task events
    this.context.on('createTask*', (eventName:string, payload: any) => {
      if(payload.configId && !this.recentUsedConfigIds.includes(payload.configId)) {
        this.recentUsedConfigIds.push(payload.configId);
        if(this.recentUsedConfigIds.length > this._configSource.value.recentUsedMaxCount) {
          this.recentUsedConfigIds.shift()
        }
        this.context.saveContext('dashboard.dag.recent-tasks', this.recentUsedConfigIds)
      }
    })

  }


  setPipeline(pipeline: PipelineAPIResponse['payload']): void {
    this._pipelineSource.next(pipeline)
    let params = {
      pipelineId: pipeline.pipelineId,
      // processId: pipeline.process?.id, // TODO: remove process Id flow
      pipelineVersionId: pipeline.versionNum
    }
    // TODO: call disconnect on destroy
    // this.nodeStatusService.connect(params, this.getConfig().taskReloadDelay)

    // TODO: intigrate with new api
    //set data for node position
    // this.nodePositionService.connect({ pipelineId: pipeline.id })
  }
  getCurrentOutput(): Node|null {
    return this._currentOutputSource.getValue();
  }

  setCurrentOutput(node: Node): void {
    this._currentOutputSource.next(node);
  }
  getCurrentNode(): Node|null {
    return this._currentNodeSource.getValue();
  }
  getCurrentPipeline()
  {
    return this._pipelineSource.getValue();
  }
  getNodeById(nodeId: number): Node|null {
    const nodes = this._pipelineSource.getValue()?.config?.nodes
    return nodes[nodeId]
  }

  setCurrentNode(node: Node): void {
    this._currentNodeSource.next(node);
  }

  setNodesConfig(configs: NodesConfigAPIResponse['payload']) {
    this._nodesConfigSource.next(configs)
  }
  

  async downloadJson(uri:string, filename:string = "file"){
    let response:any = await this.http.get(uri).toPromise()
    let data= JSON.stringify(response.payload, null, 4)
    console.log(data)
    let blob = new Blob([data], {type:"application/json"})
    let url = URL.createObjectURL(blob)
    console.log(url)
    let a = document.createElement("a")
    a.href = url
    a.download = filename
    a.click()
  }
  
  setConfig(config: DashboardConfig) {

    this._configSource.next(config)
  }

  getConfig() {
    return this._configSource.getValue()
  }

  onDelete() {
    if(this.nodeStatusService) {
      this.nodeStatusService.stop()
    }
  }

  async addNode(node: Node) {
    const pipeline = this._pipelineSource.getValue()
    pipeline?.nodeInstances.push(node)
    this._pipelineSource.next(pipeline)
  }

  async updateNode(node:Node){
    const pipeline = this._pipelineSource.getValue();
    if(pipeline?.nodeInstances){
      pipeline.nodeInstances = pipeline?.nodeInstances.map((x:Node)=>{
        if(x.id===node.id){
          return node;
        }
        return x;
      })
    }
    this._pipelineSource.next(pipeline)
  }

  async createTask(payload: any) {
    this.context.emit('createTask.init', payload)

    const response = await this.taskService.createTaskNode(payload).toPromise() as any

    if(response.errorMessage) {
      this.context.emit('createTask.error', payload, response)
      return response;
    }

    // set new node data
    const newNode = response.payload.taskObjects[0]
    newNode.taskConfigDetails = newNode.configDetails
    newNode.incoming_task_with_weights = newNode.incoming_task_with_weights ?? newNode.dependent_nodes

    // @ts-ignore
    const currentNodes = this._pipelineSource.value.taskNodes
    currentNodes[response.payload.taskObjects[0].id] = newNode


    // connect incoming nodes
    for(let parentNodeRelation of newNode.incoming_task_with_weights) {
      let parentNode: any = currentNodes[parentNodeRelation.taskId]
      if(parentNode) {
        parentNode.outgoing_task_with_weights = parentNode.outgoing_task_with_weights ?? []
        parentNode.outgoing_task_with_weights.push({...parentNodeRelation, taskId: newNode.id})
      }
    }

    
    this._pipelineSource.next(this._pipelineSource.value)
    this.context.emit('createTask.success', payload)
    return response
  }

 
  async updateTask(id: any, payload: any) {
    this.context.emit('updateTask.init', payload)


    /* FIXME:  
    const response = await this.taskService.updateTaskNode(id, payload).toPromise() as any

    if(response.errorMessage) {
      this.context.emit('updateTask.error', payload, response)
      return response;
    }

    const newNode = response.payload.taskObjects[0]
    const oldNode = this._pipelineSource.value?.taskNodes[response.payload.taskObjects[0].id]
    const oldIncoming = oldNode?.incoming_task_with_weights || [];
    const newIncoming:typeof oldIncoming = newNode?.incoming_task_with_weights || [];
    const oldIncomingIds = oldIncoming.map(x => x.taskId)
    const newIncomingIds = newIncoming.map(x => x.taskId)

    const oldIncomingNodes = oldIncoming.map(oldIncomingId => this._pipelineSource.value?.taskNodes[oldIncomingId.taskId])
    const newIncomingNodes = newIncoming.map(newIncomingId => this._pipelineSource.value?.taskNodes[newIncomingId.taskId])


    const removedIncoming = oldIncoming.filter(x => !newIncomingIds.includes(x.taskId))
    const removedIncomingIds = removedIncoming.map(x => x.taskId)

    const addedIncoming = newIncoming.filter(x => !oldIncomingIds.includes(x.taskId))
    const addedIncomingIds = addedIncoming.map(x => x.taskId)
    const addedIncomingNodes = addedIncoming.map(addedIncomingId => this._pipelineSource.value?.taskNodes[addedIncomingId.taskId])


    // remove removed ids from parent's outgoing
    oldIncomingNodes.forEach(oldParentNode => {
      if(removedIncomingIds.includes(oldParentNode!.id)) {
        oldParentNode!.outgoing_task_with_weights = (oldParentNode?.outgoing_task_with_weights||[]).filter(x => x.taskId !== oldNode?.id);
        oldParentNode!.outgoingTaskIds = (oldParentNode?.outgoingTaskIds||[]).filter(x => x !== oldNode?.id);
      }
    })

    // add new ids to parent's outgoing
    addedIncomingNodes.forEach(addedParentNode => {
      if(!addedParentNode!.outgoing_task_with_weights)addedParentNode!.outgoing_task_with_weights = []
      if(!addedParentNode!.outgoingTaskIds)addedParentNode!.outgoingTaskIds = []
      
      const outgoingWeight = newIncoming.find(x => x.taskId === addedParentNode?.id);
      if(outgoingWeight) {
        addedParentNode!.outgoing_task_with_weights.push({...outgoingWeight, taskId: oldNode!.id})
      }
      addedParentNode!.outgoingTaskIds.push(oldNode!.id)
    })

    // console.log(oldNode)
    // console.log(newNode)
    // console.log(oldIncoming)
    // console.log(newIncoming)
    // console.log(oldIncomingNodes)
    // console.log(this._pipelineSource.value)

    Object.assign(oldNode, newNode)

    this._pipelineSource.next(this._pipelineSource.value)

    // console.log(response, this._pipelineSource.value)

    
    this.context.emit('updateTask.success', payload)
    return response

    // */
  }

  async getRecentUsedTaskTypes(sourceNodeType?: string): Promise<ConfigModel[]> {
    // get data from cache/API, cancel request on delay

    let configs = await this.configService.getAll()
    return configs.filter(x => this.recentUsedConfigIds.includes(x.id)).reverse()
  }

  testScript(requestBody: {codeAsString: string}) {
    return this.http.post<{status: string, payload: {status: string, error_message: string}}>(this.context.getApiConfig().PIPELINE_APIS.POST.testScript, requestBody)
  }

  getAWSFileContent(url: string) {
    return this.http.get<{payload: {data: string}, status: string}>(this.context.getApiConfig().DATA_APIS.GET.getFileContent, {params:{url}})
  }

  get currentCopyNode(){
    return JSON.parse(localStorage.getItem("copiedNodeData")||'{}')
  }

  set currentCopyNode(value:any){
    localStorage.setItem("copiedNodeData",JSON.stringify(value))
    localStorage.setItem("copiedNodeId",`${value.id}`)
  }

  async addDynamicField(sourceNode: Node, targetNode: Node, sourceFieldId: string, dynamicField: DynamicField) {
    const pipelineId = this._pipelineSource.getValue()?.id!;

    return await this.taskService.addDynamicField(pipelineId, targetNode.id, {
      nodeInstanceId: sourceNode.id.toString(),
      sourceFieldName: sourceFieldId,
      variableRef: {},
      variable: {
        name: dynamicField.name,
        label: dynamicField.label,
        description: dynamicField.description,
        required: dynamicField.required,
        dataType: dynamicField.dataType,
        formatType: dynamicField.formatType,
        options: [],
        default: '',
        value: dynamicField.value,
      }
    }).toPromise();
  }

  async removeDynamicField(nodeInstanceId: number, fieldId: number) {
    const pipelineId = this._pipelineSource.getValue()?.id!;
    return await this.taskService.removeDynamicField(pipelineId, nodeInstanceId, fieldId).toPromise()
  }
  async runNodeInstance(nodeInstanceId: number|string) {
    const pipelineId = this._pipelineSource.getValue()?.id!;
    return await this.taskService.runNodeInstance(pipelineId, nodeInstanceId).toPromise()
  }

  addEdge(sourceNodeId: number, targetNodeId: number, connectionType: string, info: any) {
    const sourceNode = this.getNodeById(sourceNodeId)!
    const targetNode = this.getNodeById(targetNodeId)!
    // console.log(sourceNode, targetNode, info)



    /* FIXME:


    const isConditional = sourceNode.taskConfigDetails.type === 'conditional'
    const edgeWeight: null | boolean = isConditional ? (info.conditional_node_status === undefined ? null : info.conditional_node_status) : null
    let dataType = connectionType === 'dependent' ? 'run_dependent': 'data'
    if(isConditional) {

      if(dataType === 'run_dependent') {
        dataType = edgeWeight === true ? 'conditional_run' : (edgeWeight === false ? 'conditional_run' : dataType)
      }

      else {
        dataType = edgeWeight === true ? 'conditional_data' : (edgeWeight === false ? 'conditional_data' : dataType)
      }
    }
    


    sourceNode.outgoingTaskIds = (sourceNode.outgoingTaskIds || []).filter(x => x != targetNodeId)
    sourceNode.outgoingTaskIds.push(targetNodeId)
    sourceNode.outgoing_task_with_weights = (sourceNode.outgoing_task_with_weights || []).filter(x => x.taskId != targetNodeId)
    sourceNode.outgoing_task_with_weights.push({taskId: targetNodeId, type: dataType, weight: edgeWeight })


    targetNode.incomingTaskIds = (targetNode.incomingTaskIds || []).filter(x => x != sourceNodeId)
    targetNode.incomingTaskIds.push(sourceNodeId)
    targetNode.incoming_task_with_weights = (targetNode.incoming_task_with_weights || []).filter(x => x.taskId != sourceNodeId)
    targetNode.incoming_task_with_weights.push({taskId: sourceNodeId, type: dataType, weight: edgeWeight })
    // console.log(`Connecting: ${sourceNodeId} --[${connectionType}]--> ${targetNodeId}`)

    this._pipelineSource.next(this._pipelineSource.getValue())

    // */

  }

  removeEdge(sourceNodeId: number, targetNodeId: number) {



    /* FIXME:

    const sourceNode = this.getNodeById(sourceNodeId)!
    const targetNode = this.getNodeById(targetNodeId)!

    sourceNode.outgoingTaskIds = (sourceNode.outgoingTaskIds||[]).filter(x => x !== targetNodeId)
    targetNode.incomingTaskIds = (targetNode.incomingTaskIds||[]).filter(x => x !== sourceNodeId)

    sourceNode.outgoing_task_with_weights = (sourceNode.outgoing_task_with_weights || []).filter(x => x.taskId !== targetNodeId)
    targetNode.incoming_task_with_weights = (targetNode.incoming_task_with_weights || []).filter(x => x.taskId !== sourceNodeId)
    
    this._pipelineSource.next(this._pipelineSource.getValue())

    // */
  }

}

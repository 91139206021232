
<header class="textarea-header">
    <ul>
        <li *ngFor="let obj of fileBuffers | keyvalue"><button class="nav-btn" style="border: unset;padding: unset;background: unset;color: #EEFFFF;" (click)="tabChange(obj.key)" [class.active]="currentFile===obj.key">{{obj.key}}
            <button style="border: unset;padding: unset;background: unset;color: #EEFFFF;" title="Close"><span style="font-size: small;" class="material-icons-outlined" (click)="closeFile(obj.key)">close</span></button>
        </button></li>
       
        <!-- <li><button style="border: unset;padding: unset;background: unset;color: #EEFFFF;" title="Add file" (click)="addFile()"><span class="material-icons-outlined">add</span></button></li> -->
        <!-- <li><span>{{title}}</span><span>: {{mode|titlecase}}</span></li> -->
        <li style="margin-left: auto;"></li>

        
        <li><button style="border: unset;padding: unset;background: unset;color: #EEFFFF;" title="Keyboard Shortcuts" (click)="showKeyboardShortcuts(shortcutsdialog)"><span class="material-icons-outlined">keyboard</span></button></li>
        <li><button style="border: unset;padding: unset;background: unset;color: #EEFFFF;" title="Format" (click)="formatCode()"><span class="material-icons-outlined">code</span></button></li>
        <li><button style="border: unset;padding: unset;background: unset;color: #EEFFFF;" title="Theme" (click)="changeTheme()"><span *ngIf="theme==='default'" class="material-icons-outlined">light_mode</span><span *ngIf="theme==='dracula'" class="material-icons-outlined">dark_mode</span></button></li>

        <li *ngIf="!editorConfig.readOnly&&canOpenFile"><button style="border: unset;padding: unset;background: unset;color: #EEFFFF;" (click)="loadFile()" title="Import File"><span class="material-icons-outlined">download</span></button></li>
        <li *ngIf="resizable"><button style="border: unset;padding: unset;background: unset;color: #EEFFFF;" (click)="resize()" [title]="isFullScreen? 'Minimize': 'Maximize'"><span class="material-icons-outlined">{{isFullScreen? 'close_fullscreen': 'fullscreen'}}</span></button></li>
        <li *ngIf="!editorConfig.readOnly"><button style="border: unset;padding: unset;background: unset;" (click)="save()" title="Save File" [disabled]="!isDirty" [style.color]="isDirty?'#EEFFFF':'#eeffff61'"><span class="material-icons-outlined">save</span></button></li>
        <!-- <li ><button style="border: unset;padding: unset;background: unset;color: #EEFFFF;" (click)="close()" title="Close Editor"><span class="material-icons-outlined">close</span></button></li> -->
    </ul>
</header>

<!-- <header class="files-header">
    <ul>
        <li *ngFor="let obj of fileBuffers | keyvalue"><button class="nav-btn" style="border: unset;padding: unset;background: unset;color: #EEFFFF;" (click)="tabChange(obj.key)" [class.active]="currentFile===obj.key">{{obj.key}}
            <button style="border: unset;padding: unset;background: unset;color: #EEFFFF;" title="Close"><span style="font-size: small;" class="material-icons-outlined" (click)="closeFile(obj.key)">close</span></button>
        </button></li>
       
        <li><button style="border: unset;padding: unset;background: unset;color: #EEFFFF;" title="Add file" (click)="addFile()"><span class="material-icons-outlined">add</span></button></li>
    </ul>
</header> -->
<textarea #textarea></textarea>
<footer >
    <span>line {{currentLineNumber}}, Column {{currentColumnNumber}}</span>
    <span *ngIf="fileName">|</span>
    <span *ngIf="fileName" [title]="fileName" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:15rem">*{{fileName}}</span>
    
    <span *ngIf="editorConfig.readOnly">readonly</span>

    <span style="margin-left: auto;"></span>
    <span (click)="toggleFullScreen()" title="Full Screen">focus</span>

    <span>Spaces: {{codemirrorConfigs.tabSize}}, {{codemirrorConfigs.mode}}</span>
</footer>

<ng-template #shortcutsdialog let-data let-ref="dialogRef">
    <nb-card style="line-height: 1.5rem;">
      <nb-card-header style="display:flex;justify-content: space-between;align-items: center;">
        <h6>Keyboard Shortcuts:</h6>
        <button style="margin-left:2rem;" nbButton size="small" (click)="ref.close()"><nb-icon icon="close-outline" ></nb-icon></button>
      </nb-card-header>
      <nb-card-body>
        <table>
            <tbody>
                <tr *ngFor="let shortcut of keyboardShortcuts">
                    <td class="shortcut-keys">
                        <span class="shortcut-key">{{shortcut.key}}</span>
                    </td>
                    <td class="shortcut-text">{{shortcut.text}}</td>
                </tr>
            </tbody>
        </table>
      </nb-card-body>
    </nb-card>
  </ng-template>
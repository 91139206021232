import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { NbDialogRef } from '@nebular/theme';
import { schema_actions, schema_key, schema_sheet } from 'src/app/models/schema.model';

@Component({
  selector: 'app-add-schema',
  templateUrl: './add-schema.component.html',
  styleUrls: ['./add-schema.component.css']
})
export class AddSchemaComponent implements OnInit {

  schema:FormGroup = new FormGroup({
    ColumnName: new FormControl(''),
    DataType: new FormControl(''),
    order: new FormControl(''),
    mandatory: new FormControl(false),
  });


  schemaDetails:FormGroup = new FormGroup({});
  schema_arrays:Array<schema_key> = [];

  @Input()
  hasSchema: boolean = false;

  @Input()
  schemaResponse!: schema_sheet;
  current_schema: any;

  @Input()
  match_type: string = "002";//Exact Match

  @Output()
  delete = new EventEmitter<any>();
  //delete event

  matchingTypeOptions = [
    {key:"Exact Match with New Columns",value:"001"},
    {key:"Exact Match",value:"002"},
  ];

  deletedColumns:FormArray = new FormArray([]);

  // <reference>
  // data_type:any = {
  //   string : ["object", "string", "string"],
  //   double : ["float64", "double", "double"],
  //   integer : ["int64", "integer", "integer"],
  //   integer_with_na : ["Int64", "integer", "integer_with_na"],
  //   boolean : ["bool", "boolean", "boolean"],
  //   date : ["datetime64", "date", "date"],
  //   date_ns : ["datetime64[ns]", "date", "date_ns"]
  // }; 

  //data_type for unique name
  data_type:any = {
    string : "string",
    double : "double",
    integer : "integer",
    integer_with_na : "integer_with_na",
    boolean : "boolean",
    date : "date",
    date_ns : "date_ns"
  }; 

  $columns:any;

  constructor(private fb: FormBuilder, 
    private dialogRef: NbDialogRef<AddSchemaComponent>, private cd:ChangeDetectorRef) {
      // this.schemaDetails = new FormGroup({
      //   matchingType: new FormControl(''),
      //   schema: new FormArray([])
      // });
   }

  ngOnInit(): void {
    this.schemaDetails = new FormGroup({
      matchingType: new FormControl('002'),
      schema: new FormArray([])
    });
    console.log(this.schemaResponse);
    this.schema_arrays = this.schemaResponse.schema;
    this.remove_deleted_columns()
    this.updateSchema();
  }


  

  get SchemaKeys(){
    return this.schemaDetails.get('schema') as FormArray;
  }

  addSchema(){
    this.SchemaKeys.push(this.newSchema());
  }

  newSchema(){
    return new FormGroup({
      columnName: new FormControl(''),
      dType: new FormControl('string'),
      isMandatory: new FormControl(false),
      order: new FormControl()
    }) as FormGroup;
  }

  fill_schema(schema: any){
    if(schema.hasOwnProperty('id')){
      this.SchemaKeys.push( new FormGroup({
        columnName: new FormControl(schema.key_name || schema.columnName),
        dType: new FormControl(schema.data_type || schema.dType),
        isMandatory: new FormControl(schema.is_mandatory || schema.isMandatory),
        order: new FormControl(schema.order),
        id: new FormControl(schema.id)
      }) as FormGroup);
    }
    else{
      this.SchemaKeys.push(
     new FormGroup({
      columnName: new FormControl(schema.key_name || schema.columnName),
      dType: new FormControl(schema.data_type || schema.dType),
      isMandatory: new FormControl(schema.is_mandatory || schema.isMandatory),
      order: new FormControl(schema.order),
     }) as FormGroup);
    }
  }

  removeColumn(index: number){
    (this.SchemaKeys.at(index) as FormGroup).addControl('action',new FormControl('delete'));
    this.deletedColumns.push(this.SchemaKeys.at(index));
    this.SchemaKeys.removeAt(index);
    console.log(this.SchemaKeys)
  }

  onDrop(event: CdkDragDrop<string>) {
    console.log(event);
    moveItemInArray(this.SchemaKeys as any, event.previousIndex, event.currentIndex);
    let item = this.SchemaKeys.at(event.previousIndex);
    //this.removeColumn(event.previousIndex);
    this.SchemaKeys.removeAt(event.previousIndex);
    this.SchemaKeys.insert(event.currentIndex,item);
  }

  order(){
    
    for(let i = 0; i<this.SchemaKeys.length; i++){
      this.SchemaKeys.at(i).get("order")?.setValue(i);
    }
     for(var col of this.deletedColumns.controls){
       this.SchemaKeys.push(col);
     }
    let data = this.schemaDetails.value;
    console.log(data);
    return data;
  }

  // submit(){
  //   var ordered_data = this.order();
  //   if(this.schemaResponse?.id != undefined){
  //   console.log(this.deletedColumns);
  //   ordered_data["id"] = this.schemaResponse.id;
  //   return this.dialogRef.close({schema:{
  //     id:this.schemaResponse.id,
  //     matchingType:ordered_data.matchingType,
  //     schema: ordered_data.schema
  //   }});
  // }
  // else{
  //   this.dialogRef.close({schema:{
  //     matchingType:ordered_data.matchingType,
  //     schema: ordered_data.schema
  //   }});
  // }
  // }

  schema_request:any;

  submit(){
    var ordered_data = this.order();
    if(this.schemaResponse?.id != undefined){
    console.log(this.deletedColumns);
    ordered_data["id"] = this.schemaResponse.id;
    this.schema_request =  {schema:{
      id:this.schemaResponse.id,
      sheetName: this.schemaResponse.sheetName, 
      matchingType:ordered_data.matchingType,
      schema: ordered_data.schema
    }};
  }
  else{
    this.schema_request = {schema:{
      sheetName: this.schemaResponse.sheetName,
      matchingType:ordered_data.matchingType,
      schema: ordered_data.schema
    }};
  }
  }

  close(){
    this.dialogRef.close();
  }

  get columns(){
    return this.schemaDetails.value.schema.map((schema: any)=>{
      return schema.columnName
    });
  }

  checkDuplicates(event: any, index: number){
    //console.log(event.target.invalid)
    //console.log(this.columns.filter((column:string) => column!=event.target.value).length >=2, this.SchemaKeys.controls[index].get('columnName'))
    
      if(this.columns.filter((column:string) => column==event.target.value).length >1){
        this.SchemaKeys.controls[index].get('columnName')?.setErrors({invalid:true});
        this.duplicate_columns.set(index,true);
      }
      else{
        this.SchemaKeys.controls[index].get('columnName')?.setErrors(null);
        this.duplicate_columns.set(index,false);
      }
    
      console.log(this.schemaDetails.invalid, this.SchemaKeys.controls[index].get('columnName')?.valid)
  }

  duplicate_columns: Map<any,any> = new Map();
  duplicates(){
    for(let i=0; i<this.columns.length; i++){
      if( this.columns.filter((column:string) => column==this.columns[i]).length >1 ){
        this.duplicate_columns.set(i,true);
      }
      else{
        this.duplicate_columns.set(i,false);
      }
    }

  }

  updateSchema(){
    //update matchingType

    
      this.schemaDetails.get('matchingType')?.setValue(this.schemaResponse?.matchingType || this.match_type);
    //fill other fields
    
    for(let schema of this.schemaResponse?.schema){
      this.fill_schema(schema);
    }
  
  }

  delete_sheet(){
    this.delete.emit();
  }

  remove_deleted_columns(){
    let i = 0
    for(let column of this.schema_arrays){
      if(column?.action == schema_actions.delete){
        this.schema_arrays = this.schema_arrays.slice(0,i).concat(this.schema_arrays.slice(i+1));
        i--
      }
      i++
    }
  }

  

}

<div style="display: flex;flex-direction: column;"  #dragContainer >

  <div id="drag-bar" #dragBar [style.border-top]="DRAG_BORDER_PIXEL+'px solid #00000000'" [style.border-bottom]="DRAG_BORDER_PIXEL+'px solid #00000000'"  [style.margin-top]="-DRAG_BORDER_PIXEL+'px'">
    <div></div>
  </div>
  
  <div #tableHeader [nbSpinner]="loader" nbSpinnerStatus="success" class="noselect" style="flex-grow:auto;display: flex;justify-content: space-between; background-color: rgb(223 223 223);" >
    
    <div class="tab scroll" style="display: flex;" #tabsNevBar>
      <div *ngFor="let file of taskData" (click)="onTabChange($event, file)"
          [id]="file.sheet_name" [class.active]="currentTabId===file.sheet_name"
          nbTooltip="{{file.sheet_name}}">
        {{file.sheet_name}}
      </div>

    </div>


    <div style="display: flex;overflow: unset;" class="tab tab-right">
      <ng-container *ngFor="let file of taskData">
        <ng-container *ngIf="currentTabId===file.sheet_name">
          
          <div *ngFor="let op of file.outputTypes;let i = index" (click)="changeOutputType($event,op)" [class.active]="outputType?outputType===op:i===0" [nbTooltip]="op">
            <nb-icon *ngIf="op==='table'" icon="layout-outline" (click)="changeOutputType($event,op)"></nb-icon>
            <nb-icon *ngIf="op==='data'" icon="code-outline" (click)="changeOutputType($event,op)"></nb-icon>
            <nb-icon *ngIf="op==='schema'" icon="file-text-outline" (click)="changeOutputType($event,op)"></nb-icon>
          </div>

          <div nbTooltip="download">
            <nb-icon icon="download-outline"></nb-icon>
          </div>
        </ng-container>
      </ng-container>
    </div>


  </div>

  <div class="tab-content" style="flex-grow: 1;overflow-y:auto;background-color: white;" #tabsContent>
    <div *ngFor="let file of taskData" [style.display]="currentTabId===file.sheet_name?'block':'none'">
      <ng-container *ngIf="currentTabId===file.sheet_name && outputType==='table'">
        <app-output-table (filteredData)="filteredDataEvent($event)" [defaultFilters]="defaultFilters[currentTabId] || {}" [previewData]=file (changeDataTypeNotify)='changeDataType(file.sheet_name,$event)' [height]="contentHeight.asObservable()"></app-output-table>
      </ng-container>
      
      <app-code-editor *ngIf="outputType==='data' && currentTabId===file.sheet_name" style="height:100%" [content]="file.dataString" [mode]="file.fileType"></app-code-editor>

      <!-- <app-code-editor *ngIf="outputType==='schema'" style="height:100%" [content]="file.schema" mode="json"></app-code-editor> -->
      <ng-container *ngIf="outputType==='schema' && currentTabId===file.sheet_name">
        <div style="padding: 0.5rem;background-color: #ccf4cc;">
          <div><input nbInput fieldSize="small" (keyup)="filterSchema($event,file.schema)" type="text" placeholder="filter"><nb-icon style="margin-left:0.5rem" icon="funnel-outline"></nb-icon></div>
        </div>
        <mat-list role="list">
          <ng-container *ngIf="!schemaQuery">
            <mat-list-item role="listitem" *ngFor="let item of file.schema"><span style="font-size: xx-large;">{{indentLine(item.indent)}}</span><span class="material-symbols-outlined">{{getDataTypeIconName(item.type)}}</span> &nbsp; {{item.text}}</mat-list-item>
          </ng-container>

          <ng-container *ngIf="schemaQuery">
            <mat-list-item role="listitem" *ngFor="let item of filteredSchema"><span style="font-size: xx-large;">{{indentLine(item.indent)}}</span><span class="material-symbols-outlined">{{getDataTypeIconName(item.type)}}</span> &nbsp; {{item.text}}</mat-list-item>
          </ng-container>

        </mat-list>
        
    </ng-container>
  </div>

  

  </div>
  
</div>

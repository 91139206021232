import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { ConfigModel } from "../../models/config.model";
import { Node } from "../../models/tasknode.model";
import { Context } from "../config/context.service";
import { ServiceResponse } from '../core/data.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Pipeline } from 'src/app/models/pipeline.model';

// FIXME: update response type as per V2 api changes
export type PipelineAPIResponse = ServiceResponse<{
  id: number;
  pipelineId: number;
  name: string;
  email: string;
  status: 'active' | 'draft';
  groupId: number;
  createdTime: string;
  updatedTime: string;
  versionNum: number;


  // FIXME: process flow to-be removed from interface as per v2 APIs
  process: {
    id: number;
    status: string;
    runType: null; // FIXME: define type
    isEditMode: boolean;
    createdTime: string;
    updatedTime: string;
  };


  nodeInstances: Array<Node>
  config: {
    nodes: any // FIXME:
    edges: any
  }
}>;

export type NodesConfigAPIResponse = ServiceResponse<
  Array<{
    name: string;
    display_name: string;
    desc: string;
    icon: string;
    type: string;
    label: string;
  }>
>;

let xmlstring = `<?xml version="1.0" encoding="UTF-8"?>    
<Root>
  <Employee type="admin">    
     <id>1</id>  
     <id>2</id>  
     <name><fname>Vikasssssssssssssssss</fname><lname>Bhadoriya</lname></name>    
     <gender>Male</gender>    
     <mobile>514545</mobile>    
  </Employee>    
  <Employee>    
     <id>2</id>    
     <name><fname>Akash</fname><lname>Bhadoriya</lname></name>     
     <gender>Male</gender>    
     <mobile>5431643</mobile>    
  </Employee>    
  <Employee>    
     <id>3</id>    
     <name><fname>Shivam</fname><lname>Bhadoriya</lname></name>     
     <gender>Male</gender>    
     <mobile>43265436</mobile>    
  </Employee>    
  <User>    
     <id>4</id>    
     <name><fname>Ashish</fname><lname>Bhadoriya</lname></name>      
     <gender>Male</gender>    
     <mobile>5435431</mobile>    
  </User>    
  <User>    
     <id>5</id>    
     <name><fname>Krishna</fname><lname>Bhadoriya</lname></name>     
     <gender>Male</gender>    
     <mobile>432656</mobile>    
  </User>   

</Root>`

let xmlschema = `<?xml version="1.0" encoding="utf-8"?>
<!-- Created with Liquid Technologies Online Tools 1.0 (https://www.liquid-technologies.com) -->
<xs:schema attributeFormDefault="unqualified" elementFormDefault="qualified" xmlns:xs="http://www.w3.org/2001/XMLSchema">
  <xs:element name="Root">
    <xs:complexType>
      <xs:sequence>
        <xs:element maxOccurs="unbounded" name="Employee">
          <xs:complexType>
            <xs:sequence>
              <xs:element maxOccurs="unbounded" name="id" type="xs:unsignedByte" />
              <xs:element name="name">
                <xs:complexType>
                  <xs:sequence>
                    <xs:element name="fname" type="xs:string" />
                    <xs:element name="lname" type="xs:string" />
                  </xs:sequence>
                </xs:complexType>
              </xs:element>
              <xs:element name="gender" type="xs:string" />
              <xs:element name="mobile" type="xs:unsignedInt" />
            </xs:sequence>
            <xs:attribute name="type" type="xs:string" use="optional" />
          </xs:complexType>
        </xs:element>
        <xs:element maxOccurs="unbounded" name="User">
          <xs:complexType>
            <xs:sequence>
              <xs:element name="id" type="xs:unsignedByte" />
              <xs:element name="name">
                <xs:complexType>
                  <xs:sequence>
                    <xs:element name="fname" type="xs:string" />
                    <xs:element name="lname" type="xs:string" />
                  </xs:sequence>
                </xs:complexType>
              </xs:element>
              <xs:element name="gender" type="xs:string" />
              <xs:element name="mobile" type="xs:unsignedInt" />
            </xs:sequence>
          </xs:complexType>
        </xs:element>
      </xs:sequence>
    </xs:complexType>
  </xs:element>
</xs:schema>`


export type PipelineResponse = { totalCount: number, pageCount: number, data: Array<Pipeline>, currentPage: number }

@Injectable({
  providedIn: 'root'
})
export class PipelineService {
  url = '';
  details_url = '';
  output_url = '';
  taskconfig_url = '';
  pipelineVariable_url = '';
  pipelineschedule = '';
  config_url = '';
  public_url = '';
  stopPipeline = '';
  constructor(private context: Context, private http: HttpClient) {
    this.url = context.getApiConfig().PIPELINE_APIS.GET.pipeline
    this.details_url = context.getApiConfig().PIPELINE_APIS.GET.pipelineDetails
    this.output_url = context.getApiConfig().DATA_APIS.GET.taskOutput
    this.taskconfig_url = context.getApiConfig().PIPELINE_APIS.GET.taskConfigDetails
    this.pipelineVariable_url = context.getApiConfig().PIPELINE_APIS.GET.pipelinevariables
    this.pipelineschedule = context.getApiConfig().PIPELINE_APIS.GET.schedule
    this.config_url = context.getApiConfig().PIPELINE_APIS.GET.config
    this.public_url = context.getApiConfig().PIPELINE_APIS.GET.public
    this.stopPipeline = context.getApiConfig().PIPELINE_APIS.POST.stopPipeline


  }

  getList({ params }: { params: { [key: string]: string | number | undefined, pageNum?: number, itemsPerPage?: number, search?: string } }) {
    return this.http.get<ServiceResponse<PipelineResponse>>(this.url, { params: JSON.parse(JSON.stringify(params)) });
  }
  createPipeline(data: any) {

    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }

    return this.http.post<any>(this.url, {
      "name": data.name,
      "email": data.email
    });
  }


  deletePipeline(id: any) {
    return this.http.delete(this.url + '?id=' + id);
  }

  updatePipeline(id: any, data: any) {
    return this.http.put(this.url + '/' + id, data);
  }

  importPipeline(data: any) {

    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }

    return this.http.post<any>(this.context.getApiConfig().PIPELINE_APIS.POST.import, data);
  }


  // TODO: define response type
  getPipeline(id: number, versionId?: number) {
    let params: { [key: string]: string | number } = { }
    return this.http.get<PipelineAPIResponse>(this.context.getApiConfig().PIPELINE_APIS_V2.GET.pipelineDetails(id.toString()), { params }).pipe(
      map((data) => data.payload)
    );
  }
  getPipelineDetails(pipeline_id: number, pipeline_version_id: number, params: { [key: string]: string | number }={}) {

    return this.http.get<ServiceResponse<{data: Array<PipelineAPIResponse['payload']>}>>(
      this.context.getApiConfig().PIPELINE_APIS_V2.GET.pipelineVersions(pipeline_id.toString()
      ), {params})
      .pipe(map(res => ({payload:res.payload.data.find(x => x.id === pipeline_version_id)!})))
  }
  
  getNodesConfig() {
    return this.http.get<NodesConfigAPIResponse>(this.context.getApiConfig().PIPELINE_APIS_V2.GET.getNodesConfig(), { }).pipe(
      map((data) => data.payload)
    );
  }

  getoutput(pipeline_id: number, process_id: number, task_node_id: number) { 
    /* outputData structure:
{
  files: <array of sheets>,
  api_upload: 3 feilds,
  error_message:
}

file format:
  <Sheet name>:{
    columns
    data
    file_type
    url
  }
 */
  
    return this.http.get<any>(this.output_url + "?pipeline_id=" + pipeline_id + "&process_id=" + process_id + "&task_node_id=" + task_node_id).pipe(
      map((data: any) => {
        console.log(data.payload);
        // let [file_name_1] = Object.keys(data.payload);
        // console.log(file_name_1);
        return {...data.payload,files:xmlstring,type:'text/xml',schema:xmlschema}
        //return data.payload;
      }),
    );
  }


  getPublicUrl(url: string, filename: string) {
    return this.http.get(this.public_url + "?url=" + url + "&filename=" + filename).pipe(
      map((data: any) => {
        return data.payload;
      }),
    );
  }

  // getNodeConfig(id: number) {
  //   return this.http.get<any>(this.taskconfig_url + "?taskId=" + id).pipe(
  //     map((data: any) => {
  //       return data.payload;
  //     }),
  //   );
  // }

  createNode(payload: any) {
    return this.http.post<any>(this.context.getApiConfig().PIPELINE_APIS.POST.taskNode, payload).pipe(
      map((data: any) => {
        return data.payload;
      }),
    );
  }

  createLink(params: any) {
    return this.http.post<any>(this.context.getApiConfig().PIPELINE_APIS.POST.taskNode, params).pipe(
      map((data: any) => {
        return data.payload;
      }),
    );
  }

  saveNodeConfig(params: any) {
    return this.http.post<any>(this.context.getApiConfig().PIPELINE_APIS.POST.taskNode, params).pipe(
      map((data: any) => {
        return data.payload;
      }),
    );
  }

  getPipelineGraph(id: any) {
    return this.http.get<any>(this.details_url + "?id=" + id).pipe(
      map((data: any) => {
        return data.payload;
      }),
    );
  }


  getSinkDetailsV2(params: { api_id: string }) {
    console.log('API Call')

    return this.http.get<any>(this.context.getApiConfig().PIPELINE_APIS.GET.apiSinkDetailsV2, { params })
  }

  getPipelineVariable(id: number) {
    return this.http.get<any>(this.pipelineVariable_url + "?pipelineId=" + id).pipe(
      map((data: any) => {
        return data.payload;
      }),
    );
  }

  createVariable(payload: any) {
    console.log(payload)
    return this.http.post(this.pipelineVariable_url, payload)
  }

  updateVariable(payload: any) {
    console.log(payload)
    return this.http.put(this.pipelineVariable_url, payload)
  }

  deleteVariable(id: number) {
    return this.http.delete<any>(this.pipelineVariable_url + "?pipelinevariableId=" + id)
  }

  schedulePipepline(payload: any) {
    return this.http.post(this.pipelineschedule, payload)
  }


  getschedulePipepline(id: number) {
    return this.http.get(this.pipelineschedule + "?pipelineId=" + id)
  }

  createConfig(data: any) {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
    return this.http.post<any>(this.config_url, data);
  }

  getApiDetails(params: { api_id: string | number }) {
    return this.http.get<{ payload: any }>(this.context.getApiConfig().PIPELINE_APIS.GET.apiDetails, { params })
  }

  updateApiDetails(params: { api_id: number }) {
    return this.http.put<{ payload: any }>(this.context.getApiConfig().PIPELINE_APIS.PUT.updateDetails, params)
  }

  updatePipelineName(params: { pipelineId: number, name: string }) {
    return this.http.put<ServiceResponse<any>>(this.context.getApiConfig().PIPELINE_APIS.PUT.updatePipelineName, params)
  }

  triggerPipeline(pipelineId: number, versionId?: number) {
    return this.http.post<ServiceResponse<any>>(this.context.getApiConfig().PIPELINE_APIS_V2.POST.runPipeline(pipelineId, versionId), {});
  }

  stopSequentialPipeline(pipelineId?: number, processId?: number) {
    return this.http.post<ServiceResponse<any>>(this.stopPipeline, {
      "pipelineId": pipelineId,
      "processId": processId
    });
  }
}


<simplify-vms-bulk-upload-header [sections]="sections" [activeSection]="activeSection"
    [completedSections]="completedSections" [pipelineName]="pipelineName">

</simplify-vms-bulk-upload-header>

<div [style.display]="isLoading?'flex':'none'"
    style="display:flex;flex-direction: column;align-items: center;justify-content: center;gap:.5rem;height:30rem">
    <span class="loader"></span>
    <span>Loading...</span>
</div>


<div *ngIf="!showOuputTable" class="row justify-content-center my-4 py-3 row-scroller">
    <div class="col-lg-10 col-xl-9">
        <ng-container *ngIf="processStatus === 'intermediate' || processStatus === 'intermmediate' || processStatus === 'success'">
            <div class="row align-items-center">
                <div class="col-12">
                    <div *ngIf="activeSection?.name === 'basic_conf'" class="form-heading mb-4">
                        <h5>Basic Configuration</h5>
                        <p>Configure the require fields to initiate the process</p>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="activeSection">
                <ng-container *ngFor="let input of sectionForms[activeSection.name]?.inputsInoder">

                    <ng-component [required]=input.required *ngTemplateOutlet="recTemplate; context: { input: input }">
                    </ng-component>
                    
                </ng-container>
            </ng-container>


        </ng-container>

        <simplify-vms-run-pipeline-card (customClick)="runPipeline()"
            *ngIf="activeSection?.name === 'run_Pipeline' && showPipelineRun">
        </simplify-vms-run-pipeline-card>

        <simplify-vms-run-success-pipeline-card
            *ngIf="isPipelineRunSuccess && (activeSection?.name==='run_Pipeline'|| activeSection?.name===null)"
            [successJobs]="successJobs" [level1Message]="level1Message" [subject]="subject"
            (showOutput)="showOutputPreview($event)"
            (downloadInput)="createUrl(downloadInputOutputFileUrl, pipelineName+'_input_output')"
            (downloadOutput)="createUrl(downloadFileUrl, pipelineName+'_output')"
            [downloadInputOutputFileUrl]="downloadInputOutputFileUrl">
        </simplify-vms-run-success-pipeline-card>

        <simplify-vms-run-fail-pipeline-card
            *ngIf="isPipelineRunFailed && (activeSection?.name==='run_Pipeline'|| activeSection?.name===null)"
            [level1Message]="level1Message" [level2Message]='level2Message' [successJobs]="successJobs"
            [failedJobs]="failedJobs" [subject]="subject" (showOutput)="showOutputPreview($event)"
            (retry)="retrytrigger()"
            (downloadInput)="createUrl(downloadInputOutputFileUrl, pipelineName+'input_output')"
            (downloadOutput)="createUrl(downloadFileUrl, pipelineName+'_output')"
            [downloadInputOutputFileUrl]="downloadInputOutputFileUrl">
        </simplify-vms-run-fail-pipeline-card>

        <simplify-vms-process-failed-card
            *ngIf="isProcessFailed && (activeSection?.name ==='run_Pipeline'|| activeSection?.name===null)">         <!-- (retry)="retrytrigger()" -->
        </simplify-vms-process-failed-card>


        <simplify-vms-run-progress-pipeline-card *ngIf="processInProgress && activeSection?.name === 'run_Pipeline'"
            [loader]="processInProgress" (homePageClick)="navigateToHomePage($event)"
            (OnProcessDetailsClick)="navigateToProcessDetails($event)">
        </simplify-vms-run-progress-pipeline-card>

        <div class="row pt-4">
            <div  *ngIf="processStatus === 'intermediate' || processStatus === 'intermmediate' && showBackAndContinueButton" class="col-12 text-end">
                <simplify-button class="mx-1" *ngIf="activeSection?.order!== 1"status='light'
                    (click)="onBackButtonClick()">Back</simplify-button>
                    
                <simplify-button [disabled]="!isFormValid()"
                    *ngIf="activeSection?.name!=='run_Pipeline'" class="mx-1"
                    status='primary' (click)="onContinueButtonClick()">Continue</simplify-button>
            </div>
        </div>
    </div>
</div>





<ng-template #recTemplate let-input="input">
    <div *ngIf="input.visible">
        <ng-container [ngSwitch]="input?.inputConfig?.formatType">

            <div style="display: flex;justify-content: space-between;" *ngSwitchCase="'group'">
                <div *ngFor="let child of input.childrens">
                    <ng-component *ngTemplateOutlet="recTemplate;context:{input: child}"></ng-component>

                </div>
            </div>

            <ng-container *ngSwitchCase="'text'">
                <ng-component *ngTemplateOutlet="stringInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'integer'">
                <ng-component *ngTemplateOutlet="numberInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'number'">
                <ng-component *ngTemplateOutlet="numberInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'float'">
                <ng-component *ngTemplateOutlet="floatInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'select'">
                <ng-component *ngTemplateOutlet="dropdownInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'multi_select'">
                <ng-component *ngTemplateOutlet="multiDropdownInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'checkbox'">
                <ng-component *ngTemplateOutlet="checkboxInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'radio'">
                <ng-component *ngTemplateOutlet="radioInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'text_area'">
                <ng-component *ngTemplateOutlet="textAreaTemplate;context:{input:input}"></ng-component>
            </ng-container>

            <!-- <ng-container *ngSwitchCase="'tel'">
                <ng-component *ngTemplateOutlet="telInputTemplate;context:{input:input}"></ng-component>
            </ng-container> -->

            <ng-container *ngSwitchCase="'date'">
                <ng-component *ngTemplateOutlet="dateInputTemplate;context:{input:input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'date_time'">
                <ng-component *ngTemplateOutlet="dateTimeInputTemplate;context:{input:input}"></ng-component>
            </ng-container>

            <!-- <ng-container *ngSwitchCase="'password'">
                <ng-component *ngTemplateOutlet="passwordInputTemplate;context:{input:input}"></ng-component>
            </ng-container> -->

            <!-- <ng-container *ngSwitchCase="'url'">
                <ng-component *ngTemplateOutlet="urlInputTemplate;context:{input:input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'email'">
                <ng-component *ngTemplateOutlet="emailInputTemplate;context:{input:input}"></ng-component>
            </ng-container> -->

            <ng-container *ngSwitchCase="'file'">
                <ng-component *ngTemplateOutlet="FileInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'multi_file'">
                <ng-component *ngTemplateOutlet="MultiFileInputTemplate;context:{input: input}"></ng-component>
            </ng-container>


            <ng-container *ngSwitchDefault>
                <ng-component *ngTemplateOutlet="stringInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

        </ng-container>
    </div>
</ng-template>


<ng-template #stringInputTemplate let-input="input">
    <!-- <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container
                *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="text-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'" nbInput fullWidth="true" fieldSize="small"
                type="text" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled"
                (blur)="input.onBlur()">
        </nb-form-field>

    </div> -->

    <div class="row align-items-center mb-4">
        <div class="col-lg-5 mb-2">
            <div class="label-heading">
                <h5>{{input.inputConfig.label}}</h5>
                <p>{{input.inputConfig.description}}</p>
            </div>
        </div>

        <div class="col-lg-7 mb-2">
            <bulk-upload-text [loader]="input.isLoading" [inputSet]="input.value" (blur)="input.onBlur()"
                (inputChange)="onInputValueChange($event,input)"></bulk-upload-text>
            <div *ngIf="!input.inputConfig.validated && input.validate" class="error mt-1"><i
                    class="ri-information-fill"></i> Invalid Input </div>
        </div>
    </div>
</ng-template>


<ng-template #numberInputTemplate let-input="input">
    <!-- <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container
                *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="hash-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'" nbInput fullWidth="true" fieldSize="small"
                type="number" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled"
                (blur)="input.onBlur()" (keyup)="input.keyupEvent()">
        </nb-form-field>
    </div> -->
</ng-template>

<ng-template #floatInputTemplate let-input="input">
    <!-- <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container
                *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="facebook-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'" nbInput fullWidth="true" fieldSize="small"
                type="number" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled"
                (blur)="input.onBlur()">
        </nb-form-field>
    </div> -->
</ng-template>




<ng-template #dropdownInputTemplate let-input="input">
    <!-- <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container
                *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="arrow-down-outline"></nb-icon>
            <nb-select [status]="input.errors.length?'danger':'basic'" fullWidth="true" size="small"
                [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled"
                (ngModelChange)="input.onBlur()">
                <nb-option *ngFor="let option of input.options" [value]="option">{{option}}</nb-option>
            </nb-select>
        </nb-form-field>

    </div> -->
    <div class="row align-items-center mb-4">
        <div class="col-lg-5 mb-2">
            <div class="label-heading">
                <h5>{{input.inputConfig.label}}</h5>
                <p>{{input.inputConfig.description}}</p>
            </div>
        </div>
        <div class="col-lg-7 mb-2">
            <bulk-upload-dropdown [loader]="input.isLoading" [inputSet]="input.value" (blur)="input.onBlur()"
                (optionSelected)="onInputValueChange($event, input)" [options]="input.options"></bulk-upload-dropdown>
            <div *ngIf="!(input?.inputConfig?.validated) && input?.validate" class="error mt-1"><i
                    class="ri-information-fill"></i> Invalid Input </div>
        </div>
    </div>

</ng-template>


<!-- <ng-template #multiDropdownInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}</label>
        <select  [(ngModel)]="input.value" [disabled]="input.disabled" multiple (change)="input.onBlur()">
            <option *ngFor="let option of input.options" [value]="option.key">{{option.value}}</option>
        </select>
    </div>
</ng-template> -->

<ng-template #multiDropdownInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <!-- <label>{{input.inputConfig.label}}<ng-container
                *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field (click)="input.toggleOpen()">
            <nb-icon nbPrefix icon="checkmark-square-outline"></nb-icon>
            <nb-select [status]="input.errors.length?'danger':'basic'" size="small" fullWidth="true" multiple
                [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled">
                <nb-option *ngFor="let option of input.options" [value]="option">{{option}}</nb-option>
            </nb-select>
        </nb-form-field> -->

    </div>
</ng-template>


<ng-template #checkboxInputTemplate let-input="input">
    <!-- <div>
        <label style="display: flex;flex-direction: row;align-items: center; gap: .5rem;">

            <nb-checkbox [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled"
                (change)="input.onBlur()">{{input.inputConfig.label}}<ng-container
                    *ngIf="!input.required; else requiredTemplate"></ng-container></nb-checkbox>

        </label>
    </div> -->

    <div class="row align-items-center mb-4">
        <div class="col-lg-5 mb-2">
            <div class="label-heading">
                <h5>{{input.inputConfig.label}}</h5>
                <p>{{input.inputConfig.description}}</p>
            </div>
        </div>

        <div class="col-lg-7 mb-2">
            <simplify-checkbox></simplify-checkbox>
            <!-- <div class="error mt-1"><i class="ri-information-fill"></i> Invalid Input </div> -->
        </div>
    </div>
</ng-template>



<ng-template #radioInputTemplate let-input="input">
    <!-- <div>
        <label style="display: flex;flex-direction: row;align-items: center; gap: .5rem;"
            *ngFor="let option of input.options">{{input.inputConfig.label}}
            <ng-container *ngIf="!input.required; else requiredTemplate"></ng-container>
        </label>
        <input type="radio" [(ngModel)]="input.value" [disabled]="input.disabled" (change)="input.onBlur()">
    </div> -->

    <div class="row align-items-center mb-4">
        <div class="col-lg-5 mb-2">
            <div class="label-heading">
                <h5>{{input.inputConfig.label}}</h5>
                <p>{{input.inputConfig.description}}</p>
            </div>
        </div>

        <div class="col-lg-7 mb-2">
            <simplify-radio (blur)="input.onBlur()"></simplify-radio>
            <!-- <div class="error mt-1"><i class="ri-information-fill"></i> Invalid Input </div> -->
        </div>
    </div>
</ng-template>

<ng-template #textAreaTemplate let-input="input">
    <!-- <div>
        <label>{{input.inputConfig.label}}<ng-container
                *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <textarea [status]="input.errors.length?'danger':'basic'" nbInput fullWidth [(ngModel)]="input.value"
                (blur)="input.onBlur()"></textarea>
        </nb-form-field>
    </div> -->
</ng-template>


<!-- 
<ng-template #passwordInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container
                *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="lock-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'" fullWidth fieldSize="small" [(ngModel)]="input.value"
                [required]="input.required" [disabled]="input.disabled"
                [type]="input.showPassword ? 'text' : 'password'" nbInput (blur)="input.onBlur()">
            <button nbSuffix nbButton ghost (click)="input.toggleShowPassword()">
                <nb-icon [icon]="input.showPassword ? 'eye-outline' : 'eye-off-2-outline'" pack="eva"
                    [attr.aria-label]="input.showPassword ? 'hide password' : 'show password'">
                </nb-icon>
            </button>
        </nb-form-field>

    </div>
</ng-template> -->

<ng-template #urlInputTemplate let-input="input">
    <!-- <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container
                *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="link-2-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'" nbInput fullWidth="true" fieldSize="small" type="url"
                [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled"
                (blur)="input.onBlur()">
        </nb-form-field>
    </div> -->
</ng-template>

<!-- <ng-template #telInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container
                *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="phone-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'" nbInput fullWidth="true" fieldSize="small" type="tel"
                [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled"
                (blur)="input.onBlur()">
        </nb-form-field>
    </div>
</ng-template> -->

<ng-template #dateInputTemplate let-input="input">
    <!-- <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container
                *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="calendar-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'" nbInput fullWidth="true" fieldSize="small"
                type="date" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled"
                (blur)="input.onBlur()">
        </nb-form-field>

    </div> -->
</ng-template>

<!-- <ng-template #emailInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container
                *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="email-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'" nbInput fullWidth="true" fieldSize="small"
                type="email" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled"
                (blur)="input.onBlur()">
        </nb-form-field>

    </div>
</ng-template> -->

<ng-template #dateTimeInputTemplate let-input="input">
    <!-- <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container
                *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="calendar-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'" nbInput fullWidth="true" fieldSize="small"
                type="datetime-local" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled"
                (blur)="input.onBlur()">
        </nb-form-field>

    </div> -->
</ng-template>

<ng-template #requiredTemplate>
</ng-template>


<ng-template #FileInputTemplate let-input="input">
    <div class="row mb-4">
        <div class="col-lg-5 mb-2">
            <div class="label-heading">
                <div class="d-flex align-items-center">
                    <h5 class="mb-0">{{input.inputConfig.label}}
                    </h5>
                    <div class="position-relative m-1">
                        <simplify-icon name='information-line' size='medium' lib='remix' data-popover-id="Instructions">
                        </simplify-icon>
                        <simplify-popover class="instructions"  target="simplify-icon[data-popover-id=Instructions]">
                            <strong>{{ input?.inputConfig?.description?.split('\n')[1]}}</strong>
                            <ul class="instruction-list">
                                <ng-container *ngFor="let instruction of input?.inputConfig?.description?.split('\n').slice(2)">
                                    <li>{{instruction.trim()}}</li>
                                </ng-container>
                            </ul>
                        </simplify-popover>
                    </div>
                   
                </div>
                
              
                <!-- <p>{{input.inputConfig.description}}</p> -->
                <p>{{ input?.inputConfig?.description?.split('\n')[0]}}<br></p>
             
                

            </div>
        </div>

        <div class="col-lg-7 mb-2">
            <simplify-vms-bulk-file-upload [loader]="input.isLoading" [duration]="input.duration"
                [uploadState]="!!input.inputConfig?.error_message" [schemaCheckError]="input.inputConfig?.error_message"
                [selectedFile]="form_memo[input.name]" (previewClick)="schemapreviewEvent($event, input?.inputConfig)"
                (fileSelected)="onFileSelected($event, input)" (resetFile)="resetFile($event, input)"
                (onDownload)="onDownload($event, input)" (onEditCellsClick)="editCellPreview($event, input)"
                (schemaCheck)="schemaCheckPreview($event, input)"></simplify-vms-bulk-file-upload>
            <div *ngIf="!input.inputConfig.validated && input.validate" class="error mt-1"><i
                    class="ri-information-fill"></i> Invalid Input </div>
        </div>
    </div>

</ng-template>

<ng-template #MultiFileInputTemplate let-input="input">
    <div class="row mb-4">
        <div class="col-lg-5 mb-2">
            <div class="label-heading">
                <h5>{{input.inputConfig?.label}}</h5>
                <p>{{input.inputConfig?.description}}</p>
            </div>
        </div>

        <div class="col-lg-7 mb-2">
            <simplify-multi-files-upload  
                [filesRemoved]="filesRemoved"
                (submitMultiFile)="submitMultiFile($event, input)"
                (removeFile)="removeFile($event, input)"
                [uploadState]="uploadState"
                [loader]="fileUploadLoader||input.isLoading"
                [selectedFiles]="form_memo[input.name]"
                (fileSelected)="onMultiFileSelected($event, input)"
                (resetFile)="resetFile($event, input)">
            </simplify-multi-files-upload>    
            <div *ngIf="!input.inputConfig.validated && input.validate" class="error mt-1"><i
                    class="ri-information-fill"></i> Invalid Input </div>
        </div>
    </div>

</ng-template>





<simplify-modal [modalSize]="'xl'" #schemapreview>
    <div class="modal-header border-bottom-0 align-items-center py-2">
        <h6 class="modal-title py-2">Schema Preview</h6>
        <simplify-button data-role='close-modal'>
            <simplify-icon name='close-line' size='large' lib='remix'></simplify-icon>
        </simplify-button>
    </div>
    <div class="modal-body p-0">
        <simplify-accordian>
            <simplify-accordian-item slot="item">
                <simplify-accordian-header slot="header">
                    <a class="accordion-button d-flex align-items-center text-decoration-none">
                        <div class="col">
                            <h6>{{fileSchemaInput.label}}</h6>
                            <p class="mb-0">{{fileSchemaInput.schemaPreview.data?.length}} columns</p>
                        </div>
                        <div class="col col-auto text-end me-4 d-flex align-items-center">
                            <a style="cursor: pointer;"
                                (click)="createUrl(fileSchemaInput.downloadFileUrl, pipelineName + '_schema')"
                                class="btn-link text-blue text-decoration-none ms-auto fs-body-default"><simplify-icon
                                    class="text-dark" name='download-2-line' size='medium' lib='remix'></simplify-icon>
                                Download</a>
                            <simplify-icon class="text-dark mx-4" name='arrow-down-s-line' size='large'
                                lib='remix'></simplify-icon>
                        </div>
                    </a>
                </simplify-accordian-header>
                <simplify-accordian-body slot="body">
                    <div class="p-4">
                        <div class="table-responsive w-100">
                            <simplify-table>
                                <simplify-table-row class="header-row">
                                   
                                    <simplify-table-cell *ngFor="let column of fileSchemaInput.schemaPreview.columns[0]">
                                        <ng-container *ngIf="column.column_name !=='Metadata'">
                                            {{column.column_name}}
                                        </ng-container>
                                    </simplify-table-cell>
                                </simplify-table-row>

                                <simplify-table-row *ngFor="let data of fileSchemaInput?.schemaPreview?.data">

                                    <ng-container *ngFor="let column of fileSchemaInput.schemaPreview.columns[0]">
                                        <simplify-table-cell *ngIf="column.column_name !== 'Metadata'">
                                            {{data[column.column_name].value}}
                                            <sup *ngIf="data[column.column_name]?.is_required"
                                                class="text-danger">*</sup>
                                        </simplify-table-cell>
                                        <!-- <simplify-table-cell *ngIf="column.column_name === 'Metadata'"> -->
                                            <!--MetaData is hardcoded for now to show-->
                                            <!-- <ng-container *ngFor="let value of data['MetaData'] || metaData">
                                              <simplify-badge class="m-1 d-inline-block" [status]="MetaDataType[value] || 'outline-info'" radius="rounded-3">{{value|uppercase}}</simplify-badge>
                                            </ng-container> -->

                                            <!-- <ng-container *ngFor="let value of metaData.value">
                                                <simplify-badge class="m-1 d-inline-block"
                                                    [status]="MetaDataType[value] || 'outline-info'"
                                                    radius="rounded-3">{{value|uppercase}}</simplify-badge>
                                            </ng-container> -->


                                            <!-- <ng-container *ngIf="data['Metadata'] === 'text'">
                                                <simplify-badge class="mx-1" status="outline-info" radius="rounded-3">Text</simplify-badge>
                                              </ng-container>
                                              <ng-container *ngIf="data['Metadata'] === 'uniqueId'">
                                                <simplify-badge class="mx-1" status="outline-success" radius="rounded-3">Unique ID</simplify-badge>
                                              </ng-container>
                                              <ng-container *ngIf="data['Metadata'].includes('=')">
                                                <simplify-badge class="mx-1" status="outline-warning" radius="rounded-3">&lt;Number</simplify-badge>
                                              </ng-container> -->
                                        <!-- </simplify-table-cell> -->
                                    </ng-container>

                                </simplify-table-row>

                            </simplify-table>
                        </div>
                    </div>
                </simplify-accordian-body>
            </simplify-accordian-item>
        </simplify-accordian>
    </div>
</simplify-modal>


<simplify-modal [modalSize]="'xl'" *ngIf="showSchemaCheck && selectedFileInput" #schemaCheck>
    <div class="modal-header align-items-center py-2">
        <h6 class="modal-title py-2">Schema Check</h6>
        <div class="ms-auto">
            <!-- <a class="btn-link text-blue text-decoration-none ms-auto fs-body-default" href="#"><simplify-icon
                    class="text-dark" name='download-2-line' size='medium' lib='remix'></simplify-icon> Download</a> -->
            <simplify-button data-role='close-modal'>
                <simplify-icon name='close-line' size='large' lib='remix' size='large'></simplify-icon>
            </simplify-button>
        </div>

    </div>
    <div class="modal-body p-0 schema-check">
        <div class="row g-0">
            <div class="col-lg-3">
                <div class="sehema-list">
                    <ul class="p-0 m-0">
                        <li><simplify-icon class="me-1 text-success" name='checkbox-circle-fill' size='medium'
                                lib='remix'></simplify-icon>{{selectedFileInput.inputConfig?.error_message?.errors?.numberOfcolumnsMatched}}
                            Columns Matched
                        </li>
                        <li><simplify-icon style="cursor: pointer;" class="me-1 text-error" name='information-fill' size='medium'
                                lib='remix'></simplify-icon>{{selectedFileInput.inputConfig?.error_message?.errors?.numberOfcolumnsMisMatched}}
                            Columns Mismatched</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="row g-0">
                    <div class="col-lg-6 border-left">
                        <div class="table-resposnive w-100">
                            <simplify-table>
                                <simplify-table-row class="header-row table-heading">
                                    <simplify-table-cell>Expected Schema</simplify-table-cell>
                                </simplify-table-row>
                                <simplify-table-row class="header-row">
                                    <simplify-table-cell>Columns
                                        ({{selectedFileInput.inputConfig?.error_message?.errors?.schema_expected?.length}})</simplify-table-cell>
                                </simplify-table-row>
                                <simplify-table-row
                                    *ngFor="let data of selectedFileInput.inputConfig?.error_message?.errors?.schema_expected||[]">
                                    <simplify-table-cell>
                                        {{data}}
                                    </simplify-table-cell>
                                </simplify-table-row>
                            </simplify-table>
                        </div>

                    </div>
                    <div class="col-lg-6 border-left">
                        <div class="table-resposnive w-100">
                            <simplify-table>
                                <simplify-table-row class="header-row table-heading">
                                    <simplify-table-cell>Uploaded Schema</simplify-table-cell>
                                </simplify-table-row>
                                <simplify-table-row class="header-row">
                                    <simplify-table-cell>Columns
                                        ({{selectedFileInput.inputConfig?.error_message?.errors?.uploaded_schema?.length}})</simplify-table-cell>
                                </simplify-table-row>
                                <simplify-table-row
                                    *ngFor="let data of selectedFileInput.inputConfig?.error_message?.errors?.uploaded_schema||[]">
                                    <simplify-table-cell [ngClass]="{'bg-error': data.is_error}" class="d-flex">
                                        {{ data.name }}
                                    </simplify-table-cell>
                                </simplify-table-row>
                            </simplify-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer text-end">
        <simplify-button (click)="onCloseSchemaCheck()" status='primary' size='small'>
            Upload Again
        </simplify-button>
    </div>
</simplify-modal>

<app-edit-cell *ngIf="showEditCellPage" [cellCheckTableColumn]="cellCheckTableColumn"
    [cellCheckTableData]="cellCheckTableData" [cellCheckErrors]="cellCheckErrors" (onClose)="tableClose()"
    [downloadFileUrl]="downloadFileUrl" (showEditCell)="onCloseCellErrorCheck()">
</app-edit-cell>


<app-show-output *ngIf="showOuputTable" [nodeOutput]="nodeOutput" [downloadFileUrl]="downloadFileUrl"
    [nodeOutputError]="nodeOutputError" (onClose)="tableClose()">
</app-show-output>
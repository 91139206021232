import {Injectable} from '@angular/core';
import {Context, Event} from './context.service'
import {NbSidebarService, NbToastrService} from "@nebular/theme";

const UI_EVENT_TYPE = 'UIService'
const TOGGLE_SIDE_NAV = 'TOGGLE_SIDE_NAV'
const EXPAND_SIDE_NAV = 'EXPAND_SIDE_NAV'
const COLLAPSE_SIDE_NAV = 'COLLAPSE_SIDE_NAV'
const COMPACT_SIDE_NAV = 'COMPACT_SIDE_NAV'
const OPEN_SIDE_NAV = 'OPEN_SIDE_NAV'
const CLOSE_SIDE_NAV = 'CLOSE_SIDE_NAV'

@Injectable({ providedIn: 'root' })
export class UIService {

  /// keep the ui state private so every action will go through custom getter/setter
  private uiState = {
    showSideNav: false,
    sideBarCompact: true,
    isContentLoading: false
  }

  private pendingRequestCount = 0
  private disableLoadingIndicator: {[key: string]: boolean} = {}

  constructor(private context: Context, private sidebarService: NbSidebarService,private toasterService: NbToastrService,) {
    this.registerEvents()
  }

  get sideBarCompact(): boolean {
    return this.uiState.sideBarCompact;
  }

  set sideBarCompact(value: boolean) {
    this.uiState.sideBarCompact = value;
  }

  get isContentLoading(): boolean {
    return this.uiState.isContentLoading
  }

  set isContentLoading(value) {
    this.uiState.isContentLoading = value
  }

  get showSideNav(): boolean {
    return this.uiState.showSideNav
  }

  set showSideNav(value) {
    this.uiState.showSideNav = value
  }

  isSideNavEnable(): boolean {
    return this.uiState.showSideNav
  }
  showError(message: string) {
    this.toasterService.show(
      message,
      `Error`,
      // @ts-ignore
      {position: 'bottom-right', status: 'warning', duration: 5000}
    );
  }
  showInfo(message: string, status:string = 'info') {
    this.toasterService.show(
      message,
      status,
      // @ts-ignore
      {position: 'bottom-right', status: status, duration: 5000}
    );
  }

  toggleSideNav() {
    this.sidebarService.toggle(this.uiState.sideBarCompact, 'left');
    this.uiState.showSideNav = !this.uiState.showSideNav
    this.context.emit(UI_EVENT_TYPE + '.' + (this.uiState.showSideNav ? OPEN_SIDE_NAV : CLOSE_SIDE_NAV), !this.uiState.showSideNav, this.uiState.showSideNav, {...this.uiState})
  }
  /**
   * Toggle Loading indicator for provided `api`
   * @param api url pathname
   * @param flag toggle state. true/false === enable/disable
   * indicator state must be set before calling the api
   */
  public toggleIndicator(api: string, flag: boolean) {
    this.disableLoadingIndicator[api] = flag
  }

  toggleLeftSideNav() {
    this.sidebarService.toggle(this.uiState.sideBarCompact, 'left')
    this.context.emit(UI_EVENT_TYPE + '.' + (this.uiState.showSideNav ? OPEN_SIDE_NAV : CLOSE_SIDE_NAV), !this.uiState.showSideNav, this.uiState.showSideNav, {...this.uiState})
  }

  private registerEvents() {
    this.context.on('REQUEST.*', (event: string, args: any) => {

      // Show loading indicator for GET request
      if(this.disableLoadingIndicator[args.url.pathname]===undefined||this.disableLoadingIndicator[args.url.pathname]) {
        // Set loading indicator on get start
        if(args.state === "INIT" && args.method === 'GET' && ++this.pendingRequestCount) {
          this.isContentLoading = true
        }
        if(args.state === "END" && args.method === 'GET') {
          if((--this.pendingRequestCount) === 0) {
            this.isContentLoading = false
          }
        }
      }

      // Show error message
      if(args.status===404) {
        return
      }
      if(args.state === 'END' && args.body && args.body.errorMessage) {
        this.showError(args.body.errorMessage)
      } else if(args.ok === false) {
        if(args?.error.errorMessage !== 'Signature has expired.')
          this.showError(`[${args.status}] ${args?.error.errorMessage || args.statusText}`)
      }

      // Show response popup
      if(args.state === 'END' && args.body && args.body.showPopup && args.body.message) {
        let msg = args.body.message
        let message = ''
        let title = 'Status'
        let config = {status: 'info', position: 'bottom-right', duration: 5000}

        try {
          msg = JSON.parse(msg)
          message = msg.message
          title = msg.title || title
          Object.assign(config, msg)
        } catch {
          message = msg
        }

        this.toasterService.show(message,title,
        // @ts-ignore
          config)
      }

    })
  }

  get sideNav() {
    return this.sidebarService
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {Obj, dataTypes, keysOfObject} from "../../components/edit-api/edit-api.component";
import {NbDialogRef, NbDialogService, NbTabComponent} from "@nebular/theme";
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material/dialog";
import {FormControl, FormGroup} from "@angular/forms";
import {ConfigureApiService} from "../../services/api/configure-api.service";
import {DataConnectionComponent} from "../data-connection/data-connection.component";
import {PipelineService} from "../../services/api/pipeline.service";

@Component({
  selector: 'app-api-popup',
  templateUrl: './api-popup.component.html',
  styleUrls: ['./api-popup.component.css']
})
export class ApiPopupComponent implements OnInit {
  headerDataOptions = [
    dataTypes.string,
    dataTypes.integer,
    dataTypes.decimal,
    dataTypes.boolean,
    dataTypes.date_time_timestamp,
    dataTypes.date_time_string,
  ]
  variableDataOptions = [
    dataTypes.string,
    dataTypes.integer,
    dataTypes.decimal,
    dataTypes.boolean,
    dataTypes.date_time_timestamp,
    dataTypes.date_time_string,
  ]
  schemaDataOptions = [
    dataTypes.list,
    dataTypes.object,
    dataTypes.string,
    dataTypes.integer,
    dataTypes.decimal,
    dataTypes.boolean,
    dataTypes.date_time_timestamp,
    dataTypes.date_time_string,
    dataTypes.dict_in_string,
    dataTypes.array_in_string,
  ]
  connections: Array<any> = [];
  settings: any = {}
  apiSettings = new FormGroup({
    client_id: new FormControl(''),
    name: new FormControl(''),
    desc: new FormControl(''),
    url: new FormControl(''),
    connection_identifier: new FormControl(''),
    connection_id: new FormControl(''),
    is_secure: new FormControl(false),
    method: new FormControl(''),
    api_content_type: new FormControl(''),
  })

  data: Array<{ title: string, fields: Array<Obj> }> = []
  showCreateAPI: boolean = true
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    public dialogRef: MatDialogRef<ApiPopupComponent>,
    private api_service: ConfigureApiService,
    private dialogService: MatDialog,
    private pipelineService: PipelineService,
    private apiService:ConfigureApiService) {
    dialogRef.disableClose = true;
    this.showCreateAPI = dialogParams.create === true
    this.data = dialogParams.data
    this.settings = dialogParams.settings || {}

    this.apiSettings.get('client_id')?.setValue(this.settings.client_id||'')
    this.apiSettings.get('name')?.setValue(this.settings.name||'')
    this.apiSettings.get('desc')?.setValue(this.settings.desc||'')
    this.apiSettings.get('url')?.setValue(this.settings.url||'')
    this.apiSettings.get('connection_identifier')?.setValue(this.settings.connection_identifier||'')
    this.apiSettings.get('connection_id')?.setValue(this.settings.connection_id||'')
    this.apiSettings.get('is_secure')?.setValue(this.settings.is_secure||false)
    this.apiSettings.get('method')?.setValue(this.settings.method||'GET')
    this.apiSettings.get('api_content_type')?.setValue(this.settings.api_content_type||'JSON')


  }


  private last_connection_identifier = null;
  ngOnInit(): void {
    this.getConnection()
    this.apiSettings.valueChanges.subscribe(data => {
      this._updates = {...this._updates, Settings: data};
      this.updateConnectionId()
    })
  }

  _data:any = []
  _updates: any = {}
  _actions:any[] = []

  eventHandler(event: { type: string, args: unknown[] }) {
    if(event.type === 'change') {
      this._data = {...this._data,...event.args[2] as Object}
      this._updates = {...this._updates, ...event.args[0] as Object}
    }

    if(event.type === 'init') {
      this._data = {...this._data,...event.args[1] as Object}
    }

    if(event.type === 'action') {
      this._actions = this._actions.concat(event.args)
    }
  }

  getConnection() {
    this.api_service.getConnection().subscribe((response: any) => {
      this.connections = response.payload;
      console.log("connections", this.connections);
    });
  }


  submitting = false

  submit() {
    this.showCreateAPI ? this.createAPI() : this.updateAPI()
  }

  private updateConnectionId() {
    let data = this.apiSettings.value
    let connection_id = this.apiSettings.get('connection_id');
    let newValue = null
    if(data.connection_identifier && this.last_connection_identifier !== data.connection_identifier) {
      let option = this.connections.find(x => x.identifier == data.connection_identifier) || {id: null}
      newValue = option.id

      if(newValue !== connection_id?.value) {
        connection_id?.patchValue(newValue)
      }
      this.last_connection_identifier = data.connection_identifier
    }
  }

  async createAPI() {
    let _payload = {...this._updates}
    let payload:any = {}
    Object.keys(_payload).forEach(key => {
      // @ts-ignore
      payload[key.toString().toLowerCase()] = _payload[key]
    })

    this.submitting = true
    try {
      await this.apiService.createNewApi({request:[], schema:[], variables:[],headers:[],...payload, ...this.apiSettings.value, id: this.settings.id}).toPromise()
      this.close()
    } catch {
      // TODO: show error
    } finally {
      this.submitting = false
    }
  }

  async updateAPI() {
    let _payload = {...this._updates, Actions: this._actions.filter(x => x.action ==='delete'&&x.id)}
    let payload:any = {}
    Object.keys(_payload).forEach(key => {
      // @ts-ignore
      payload[key.toString().toLowerCase()] = _payload[key]
    })

    this.submitting = true
    try {
      await this.pipelineService.updateApiDetails({request:[], schema:[], variables:[],headers:[],...payload, settings:this.apiSettings.value, id: this.settings.id}).toPromise()
      this.close()
    } catch {
      // TODO: show error

    } finally {
      this.submitting = false
    }
  }

  close() {
    this.dialogRef.close()
  }

  openConnection() {
    this.dialogService.open(DataConnectionComponent, { panelClass: 'custom-dialog-container' }).afterClosed().subscribe((connection: any) => {
      if (connection && connection.status) {
        this.connections.push({
          client_id: connection.client_id,
          id: connection.connectionId,
          identifier: connection.identifier,
          ip_address: connection.ip_address,
          password: connection.password,
          port: connection.port,
          username: connection.username,
        })
      }
    })
  }

 editConnection() {
    let connection_identifier = this.apiSettings.value['connection_identifier'];
    let connection = this.connections.find(x => x.identifier === connection_identifier)
    this.dialogService.open(DataConnectionComponent, {panelClass: 'custom-dialog-container',
      data: {
        connection
      }
    }).afterClosed().subscribe((connection: any) => {
      if (connection && connection.status) {
        this.connections.push({
          client_id: connection.client_id,
          id: connection.connectionId,
          identifier: connection.identifier,
          ip_address: connection.ip_address,
          password: connection.password,
          port: connection.port,
          username: connection.username,
        })
      }
    })
  }

  activeTab = {
    tabTitle: 'API Settings'
  }
  handleTabChange(event: NbTabComponent) {
    this.activeTab = event
  }
}

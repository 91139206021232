
<div style="height: 100%;width: 100%;background-color: white;display:flex;flex-direction: row;gap:1px; position: relative;" class="noselect">


    <div style="position: relative; box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;display: flex;flex-direction: column;height: 100%;width: 75%;">
        <div style="display: flex; height: 2.4rem;border-bottom: 1px solid rgba(0, 0, 0, 0.12);">
            <div style="width: calc(100% / 4);background-color: #00bcd4;display: flex">
    
                <input (keyup)="onSourceChange()" [(ngModel)]="filters.sourceFilter" class="filter-input" type="text" style="width: inherit;flex-grow: 1;background-color: transparent;border: none;outline: none;padding: .2rem 1rem;color: white;" placeholder="filter">
                <div style="width: 3rem;background-color: black;color: white;display: flex;justify-content: center;align-items: center;">
                    <nb-icon icon="funnel-outline"></nb-icon>
                </div>
    
            </div>
            <div style="flex-grow: 1;"></div>
            <div style="width: calc(100% / 2);background-color: #00bcd4;display: flex">
    
                <input (keyup)="onTargetChange()" [(ngModel)]="filters.targetFilter" class="filter-input" type="text" style="width: inherit;flex-grow: 1;background-color: transparent;border: none;outline: none;padding: .2rem 1rem;color: white;" placeholder="filter">
                <div style="width: 3rem;background-color: black;color: white;display: flex;justify-content: center;align-items: center;">
                    <nb-icon icon="funnel-outline"></nb-icon>
                </div>
    
            </div>
        </div>
        <svg #svgContainer style="flex-grow: 1;" class="d3-graph">
        </svg>
    </div>

    <div style="position: relative;height: 100%;width: 25%;">
        <app-code-editor mode="xml" [content]="previewData"></app-code-editor>
    </div>

    <!-- <button style=" color:black;font-size:x-large; position:absolute;top:0;right:0;z-index: 10;" mat-button >&times;</button> -->
    
</div>


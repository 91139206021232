import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Context} from "../../services/config/context.service";
import {AuthService} from "../../services/api/auth.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  showError:boolean = false
  private authService: AuthService
  private context: Context
  private router: Router
  private route: ActivatedRoute


  constructor(private injector: Injector) {
    this.authService = injector.get(AuthService)
    this.context = injector.get(Context)
    this.router = injector.get(Router)
    this.route = injector.get(ActivatedRoute)
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }


  login({detail: payload}: any) {


    let request = this.authService.login({username:payload.email, ...payload}).toPromise()
    request.then(async loginResponse => {
      const access_token = loginResponse.payload.token.access_token
      const refresh_token = loginResponse.payload.token.refresh_token
      this.authService.saveToken(access_token)
      this.authService.saveRefreshToken(refresh_token)

      this.context.setCurrentUser({...loginResponse.payload, token: undefined})
      const userInfoResponse = await this.authService.getUserInfo().toPromise()
      
      
      let accounts = userInfoResponse.payload?.accounts || [];
      accounts = accounts.filter(account => account.attributes.account_attr)

      if(!this.authService.getAccountId()) {
        const first_account = accounts[0]
        this.authService.saveAccountId(first_account?.attributes.account_attr.id)
      }

      this.context.userGroups.next(userInfoResponse.payload?.accounts||[])
      this.redirectNextPage()
    }).catch(error => {
      this.showError = true
    })
  }

  private redirectNextPage() {
    let redirectUrl = '/'
    
    if(this.route.snapshot.queryParams.next) {
      try {
        redirectUrl = atob(this.route.snapshot.queryParams.next)
      } catch {}
    }

    this.router.navigateByUrl(redirectUrl)
  }
}

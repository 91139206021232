<div class="cropper">
  <div class="cropper-header">
    <ul>
      <li><nb-icon nbTooltip="Rotate" style="cursor:pointer" icon="refresh-outline" (click)="rotate()"></nb-icon></li>
      <li><nb-icon [nbTooltip]="roundCropper?'Round Cropper':'Square Cropper'" style="cursor:pointer" (click)="roundCropper=!roundCropper" [icon]="roundCropper?'square-outline':'radio-button-off-outline'"></nb-icon></li>
      <li><nb-icon nbTooltip="Zoom In" icon="plus-outline" style="cursor: pointer;" (click)="zoom('+')"></nb-icon></li>
      <li><nb-icon nbTooltip="Zoom Out" icon="minus-outline" style="cursor:pointer;" (click)="zoom('-')"></nb-icon></li>
    </ul>
  </div>
  <div class="cropper-body">
      <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="1 / 1"
          format="png"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
          [roundCropper]="roundCropper"
          [transform]="obj"
          [imageURL]="imageUrl"
      ></image-cropper>
      <!-- <img style="width:30%;height:fit-content;border: 1px solid rgba(50, 49, 49, 0.233);margin:0.2rem" [ngStyle]="{'border-radius': roundCropper?'50%':'0'}" [src]="croppedImage" /> -->
  </div>
</div>

import { jsonix } from "./jsonix";
const w3schemas = require('w3c-schemas')


type Node = {
  TYPE_NAME: string;
  name?: string;
  otherAttributes?: { [key: string]: string };
  element?: Array<Node>;

  type?: { [key: string]: string; name: string; localPart: string, prefix: string };
  complexType?: Node;

  sequence?: Node;
  all?: Node;
  choice?: Node;
  attributeGroup?: Array<Node>;
  attribute?: Array<Node>

  ref?: {
    key: string
    localPart: string
    namespaceURI: string
    prefix: string
    string: string
  }
};

export class Xml2Json {
  private visitors: Map<
    string,
    (node: Node, context: any, self: Xml2Json) => any
  > = new Map();

  attributeGroup: Array<Node> = [];
  complexType: Array<Node> = [];

  visit(node: Node, context: any, self: Xml2Json) {
    const visitor = this.visitors.get(node.TYPE_NAME);
    if (!visitor) {
      console.warn(`[${node.TYPE_NAME}] Visitor not found!`);
      return context;
    }

    const nextContext = visitor(node, context, this);
    return nextContext;
  }

  register({
    visitorName,
    visitor,
  }: {
    visitorName: string;
    visitor: (node: Node, context: any, self: Xml2Json) => any;
  }) {
    this.visitors.set(visitorName, visitor);
  }
}



export class XmlSchemaParser {

  private context = new jsonix.Context([w3schemas.XSD_1_0])
  private unmarshaller = this.context.createUnmarshaller()
  private xml2Json = new Xml2Json()

  constructor(){
    visitors.forEach(visitorFactory => this.xml2Json.register(visitorFactory()))
  }

  public parseString(xmlSchemaString: string) {
    const struct = this.parseXmlSchemaString(xmlSchemaString)
    // console.log(struct)
    return struct
  }

  private parseXmlSchemaString(xmlStr: string) {
    const ss = this.unmarshaller.unmarshalString(xmlStr)
    // console.log(ss)
    const json = this.xml2Json.visit(ss.value, [], this.xml2Json)
    return json
  }
}




const getTokenType = (tokenName: string) => {
  const schema = "XSD_1_0";
  return `${schema}.${tokenName}`;
};

type VisitorObject = {
  visitorName: string;
  visitor: (node: Node, context: any, self: Xml2Json) => any;
};
const getSchemaVisitor = (): VisitorObject => {
  return {
    visitorName: getTokenType("Schema"),
    visitor: (node, context = [], self: Xml2Json) => {
      if (node.attributeGroup) {
        self.attributeGroup = node.attributeGroup;
      }

      if (node.complexType) {
        self.complexType = (node.complexType as any) || [];
      }

      for (let element of node.element || []) {
        const ctx = self.visit(element, context, self);
        context.push(ctx);
      }
      return context;
    },
  };
};

const getTopLevelElementVisitor = (): VisitorObject => {
  return {
    visitorName: getTokenType("TopLevelElement"),
    visitor: (node, context, self) => {
      const ctx = {
        [node.name!]: {
          $type: node.type?.localPart,
          $use: node.otherAttributes?.use || null,
        },
      };

      // Complex Type
      if (node.complexType) {
        self.visit(node.complexType, ctx[node.name!], self);
      }

      return ctx;
    },
  };
};

const getLocalComplexTypeVisitor = (): VisitorObject => {
  return {
    visitorName: getTokenType("LocalComplexType"),
    visitor: (node, context, self) => {
      if (node.sequence) {
        context.$type = "complex:sequence";
        self.visit(node.sequence, context, self);
      }

      if (node.all) {
        context.$type = "complex:all";
        self.visit(node.all, context, self);
      }

      if (node.choice) {
        context.$type = "complex:choice";
        self.visit(node.choice, context, self);
      }

      if(node.attributeGroup) {
        for(let attribute of node.attributeGroup) {
            self.visit(attribute, context, self);
        }
      }

      if(node.attribute) {
        const attributes = node.attribute || []
        attributes.forEach(attribute => self.visit(attribute, context, self))
      }

      return {};
    },
  };
};

const getExplicitGroupVisitor = (): VisitorObject => {
  return {
    visitorName: getTokenType("ExplicitGroup"),
    visitor: (node, context, self: Xml2Json) => {
      context.elements = [];
      for (let element of node.element || []) {
        const ctx = self.visit(element, {}, self);
        context.elements.push(ctx);
      }
      return context;
    },
  };
};

const getAllVisitor = (): VisitorObject => {
  return {
    visitorName: getTokenType("All"),
    visitor: (node, context, self: Xml2Json) => {
      context.elements = [];
      for (let element of node.element || []) {
        const ctx = self.visit(element, {}, self);
        context.elements.push(ctx);
      }
      return context;
    },
  };
};

const getLocalElementVisitor = (): VisitorObject => {
  return {
    visitorName: getTokenType("LocalElement"),
    visitor: (node, context, self: Xml2Json) => {
      const ctx = {
        [node.name!]: {
          $type: node.type?.localPart,
          $use: node.otherAttributes?.use || null,
        },
      };

      if(!node.type?.prefix) {
        const refType = self.complexType.find(x => node.type!.key)
        if(refType) {
            self.visit(refType, ctx[node.name!], self)
        }
      }

      if(node.complexType) {
        self.visit(node.complexType, ctx[node.name!], self)
      }

      return ctx;
    },
  };
};


const getAttributeGroupRefVisitor = (): VisitorObject => {
    return {
      visitorName: getTokenType("AttributeGroupRef"),
      visitor: (node, context, self: Xml2Json) => {
        const attributeGroup = self.attributeGroup.find(x => x.name === node.ref?.key)
        
        if(attributeGroup) {
            // context.$ref = attributeGroup
            self.visit(attributeGroup, context, self)
        }


        return context;
      },
    };
};


const getAttributeGroupVisitor = (): VisitorObject => {
    return {
      visitorName: getTokenType("NamedAttributeGroup"),
      visitor: (node, context, self: Xml2Json) => {
        const attributes = node.attribute || []
        for(let attribute of attributes) {
            self.visit(attribute, context, self)
        }
        return context;
      },
    };
};


const getAttributeVisitor = (): VisitorObject => {
    return {
      visitorName: getTokenType("Attribute"),
      visitor: (node, context, self: Xml2Json) => {
        context.$attributes = context.$attributes || []
        context.$attributes.push({
            [node.name!]: {
                $type: node.type?.localPart
            }
        })
        return context;
      },
    };
};


const getTopLevelComplexTypeVisitor = (): VisitorObject => {
    // const visitor = getLocalComplexTypeVisitor()
    return {
      visitorName: getTokenType("TopLevelComplexType"),
      visitor: (node, context, self) => {
        // console.log(node, context)
        if (node.sequence) {
            context.$type = "complex:sequence";
            self.visit(node.sequence, context, self);
          }
    
          if (node.all) {
            context.$type = "complex:all";
            self.visit(node.all, context, self);
          }
    
          if (node.choice) {
            context.$type = "complex:choice";
            self.visit(node.choice, context, self);
          }
    
          if(node.attributeGroup) {
            for(let attribute of node.attributeGroup) {
                self.visit(attribute, context, self);
            }
          }
    
          if(node.attribute) {
            const attributes = node.attribute || []
            attributes.forEach(attribute => self.visit(attribute, context, self))
          }
      }
    };
  };

export const visitors = [
  getSchemaVisitor,
  getTopLevelElementVisitor,
  getLocalComplexTypeVisitor,
  getExplicitGroupVisitor,
  getLocalElementVisitor,
  getAllVisitor,
  getAttributeGroupRefVisitor,
  getAttributeGroupVisitor,
  getAttributeVisitor,
  getTopLevelComplexTypeVisitor
];



type ParserNodeType = {
    [name: string]: {
      $type: string
      $use: string
      elements: Array<ParserNodeType>
    }
  }


export const getTreeView = (
  elements: Array<ParserNodeType>, 
  index: number = 0, 
  ls: Array<{indent: number, text: string, use: string, id: string, type: string}>=[], 
  prefix: string = ''
  ) => {
  (elements || []).forEach(element => {
      Object.keys(element).forEach(
          (key, i) => {
              const path = `${prefix}.${key}[${element[key].$type}]`
              ls.push({
                indent: index,
                  text: key,
                  use: element[key].$use,
                  type: element[key].$type,
                  id: path
              })
              getTreeView(element[key].elements, index + 1, ls, path)
          }
      )
  });
  return ls
}
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ConnectionFormRemap,
  Form,
  FormUpdateHandlerOnBlur,
  InputType,
  ServiceFormRemap
} from 'src/app/components/dynamic-form/dynamic-form.component';
import { Node } from 'src/app/models/tasknode.model';
import { Nodev2Service } from 'src/app/services/api/nodev2.service';
import { Connectorv2Service } from 'src/app/services/api/v2/connectorv2.service';
import {
  Connector,
  PipelineVersion
} from 'src/app/services/api/v2/interfacesv2';
import { UIService } from 'src/app/services/config/ui.service';
import { DashboardStoreService } from 'src/app/services/store/dasboard-store.service';

const toSnakeCase = (str: any) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x: any) => x.toLowerCase())
    .join('_');
@Component({
  selector: 'app-node-settings',
  templateUrl: './node-settings.component.html',
  styleUrls: ['./node-settings.component.css']
})
export class NodeSettingsComponent implements OnInit, AfterViewInit {
  @Output() output = new EventEmitter<{ type: string; data: any }>();
  @Input() nodeData = {
    pipelineId: 12,
    pipelineVersionId: 7,
    uuid: 'd9935aeb-02c2-4630-ae71-6ba652ea71fe',
    name: 'my_sql_testing_node_1001',
    label: 'My SQL 1001',
    status: 'Draft',
    connectorId: 5,
    connector: 'mysql',
    description: 'My sql',
    serviceName: '',
    inputFields: {
      "query": {
          "value": {
              "raw": "select * from df"
          }
      },
      "table": {
          "value": {
              "raw": "failed_jobs"
          }
      }
  },
    connectionId: 8
  };

  connectorData: any = {
    // id: 5,
    // name: 'mysql',
    // label: 'My SQL',
    // desc: 'My SQL Related Connector',
    // icon: 'http://dgallery.s3.amazonaws.com/mysql-logo.jpg',
    // type: null,
    // connection: {
    //   isEnabled: true
    // },
    // services: [
    //   // {
    //   //   name: 'select_query',
    //   //   label: 'Select Query',
    //   //   description: 'Select Query'
    //   // }
    // ]
  };

  pipelineId: string = '';
  nodeInstanceId: string = '';
  nodeName: string = '';
  nodeInstanceName: string = '';
  nodeInstanceStatus: string = '';
  isConnectionEnabled: boolean = false;
  nodeServices: Array<any> = [];
  nodeConnections: Array<any> = [];

  isNewConnection: boolean = false;
  newConnectionName: string = '';

  newConnectionForm = new Form();
  formUpdateHandler: any;
  connectionFormRemap: any;

  testConnectionError: string = '';

  isServicesEnabled: boolean = false;
  serviceForm = new Form();
  serviceFormUpdateHandler: any;
  serviceFormRemap: any;
  selectedService: string = '';

  isEditConnection: boolean = false;

  private previousConnectionId: any;
  _connectionInstanceId: any;
  get connectionInstanceId(): number {
    return this._connectionInstanceId;
  }
  set connectionInstanceId(value: number) {
    this.previousConnectionId = this.connectionInstanceId;
    this._connectionInstanceId = value;
  }
  
  @ViewChild('confirmationPopup') confirmationPopup!: TemplateRef<
    HTMLDivElement
  >;
  constructor(
    private changeDetector : ChangeDetectorRef,
    private nodeService: Nodev2Service,
    private dashboardService: DashboardStoreService,
    private uiService: UIService,
    private dialog: MatDialog,
    private connectorService: Connectorv2Service
  ) {}

  async ngOnInit() {

  }

  async ngAfterViewInit() {
    this.connectionInstanceId = this.nodeData.connectionId as any;
    //call connectorDetail api with connectorId
    this.connectorData = (await this.connectorService.getConnectorDetail(this.nodeData.connectorId.toString()).toPromise())?.payload;

    this.isConnectionEnabled = this.connectorData.connection.isEnabled;
    if (!this.isConnectionEnabled) {
      this.isServicesEnabled = true;
    } else {
      this.loadAllConnections();
    }
    this.nodeServices = this.connectorData.services;

    if (this.connectionInstanceId) {
      this.isServicesEnabled = true;
      this.selectedService = this.nodeData.serviceName || '';
      if (this.selectedService) {
        this.serviceSelectedEvent();
        //load previous values form
        let serviceFormDetails: any = await this.nodeService
          .fillServiceDetails(
            this.nodeData.connectorId.toString(),
            this.connectionInstanceId,
            this.selectedService,
            this.nodeData.inputFields
          )
          .toPromise();
        this.serviceForm.build(serviceFormDetails);
      }
    }
    //dynamic form part
    this.connectionFormRemap = new ConnectionFormRemap(
      this.nodeData.connectorId.toString(),
      this.connectorService
    );
    this.formUpdateHandler = new FormUpdateHandlerOnBlur(
      this.newConnectionForm,
      this.connectionFormRemap
    );
    this.formUpdateHandler.subscribe();
    this.changeDetector.detectChanges();
  }

  //
  async loadAllConnections() {
    let allCI = await this.connectorService
      .getConnectionsByConnectorId(this.nodeData.connectorId.toString())
      .toPromise();
    this.nodeConnections = allCI.payload.data;
    console.log(this.nodeConnections);
  }
  //

  async testAndSave() {
    const data = this.newConnectionForm.getValue();
    const newForm = data;
    let formData: any = {
      inputFields: {}
    };
    newForm.forEach(d => {
      formData.inputFields[d.name] = { value: d.value };
    });
    //console.log(formData)
    let res: any = await this.connectorService
      .testConnection(this.nodeData.connectorId.toString(), formData)
      .toPromise();
    if (res.payload.errorMessage) {
      this.testConnectionError = res.payload.errorMessage;
    }
    if (res.payload.isConnected) {
      this.saveConnection();
    }
  }
  async saveConnection() {
    const data = this.newConnectionForm.getValue();
    const newForm = data;
    let newConnectionReqest: any = {
      connectorId: this.nodeData.connectorId,
      name: this.newConnectionName,
      label: this.newConnectionName,
      inputFields: {}
    };
    newForm.forEach(d => {
      newConnectionReqest.inputFields[d.name] = { value: d.value };
    });

    let res: any = await this.connectorService
      .createConnection(newConnectionReqest)
      .toPromise();
    this.loadAllConnections();
    this.connectionInstanceId = res.payload.id;
    this.isNewConnection = false;
    this.connectionSelectedEvent();
    this.uiService.showInfo('New connection saved and selected!!');
  }

  async updateNodeInstance() {
    let nodeInstanceData = {
      name: this.nodeInstanceName,
      label: this.nodeInstanceName,
      connectionInstanceId: this.connectionInstanceId
    };

    console.log(nodeInstanceData);
    let res: any = await this.nodeService
      .updateNodeInstance(
        this.pipelineId,
        this.nodeInstanceId,
        nodeInstanceData
      )
      .toPromise();
    console.log(res);
    if (this.selectedService) {
      this.useService();
    }
    this.uiService.showInfo('Node Instance Updated');
    let updatedNode: Node = res.payload;
    this.dashboardService.updateNode(updatedNode);
  }

  close() {
    this.output.emit({ type: 'CLOSE', data: {} });
  }

  connectionSelectedEvent() {
    this.uiService.showInfo('Services will be resetted now');
    // this.updateNodeInstance();
    if (this.connectionInstanceId) {
      this.isServicesEnabled = true;
      this.selectedService = '';
    }
  }

  async serviceSelectedEvent() {
    //render dynamic form
    if (this.selectedService === '') {
      this.serviceForm.build([]);
      return;
    }
    let serviceFormDetails: any = await this.nodeService
      .getServiceDetails(
        this.nodeData.connectorId.toString(),
        this.connectionInstanceId,
        this.selectedService
      )
      .toPromise();
    this.serviceForm.build(serviceFormDetails);

    this.serviceFormRemap = new ServiceFormRemap(
      this.nodeData.connectorId.toString(),
      this.connectionInstanceId.toString(),
      this.selectedService,
      this.nodeService
    );
    this.serviceFormUpdateHandler = new FormUpdateHandlerOnBlur(
      this.serviceForm,
      this.serviceFormRemap
    );
    this.serviceFormUpdateHandler.subscribe();
  }

  useService() {
    const data = this.serviceForm.getValue();

    const newForm = data;
    let inputFields: any = {};
    newForm.forEach(d => {
      inputFields[d.name] = { value: { raw: d.value } };
    });

    this.nodeService
      .saveService(
        this.nodeData.pipelineId.toString(),
        this.nodeData.pipelineVersionId.toString(),
        this.nodeData.uuid.toString(),
        this.selectedService,
        inputFields
      )
      .subscribe(data => console.log(data));

    // let nodeInstanceServiceUpdateData = {
    //   serviceName:this.selectedService,
    //   inputConfigs:inputConfigs,
    //   status:'active'
    // }
    // this.nodeInstanceStatus = 'active';
    // console.log(nodeInstanceServiceUpdateData)
    // this.nodeService.updateNodeInstance(this.pipelineId,this.nodeInstanceId,nodeInstanceServiceUpdateData).subscribe((res:any)=>{
    //   let updatedNode:Node = res.payload;
    //   this.dashboardService.updateNode(updatedNode)
    // });
  }

  async editConnection() {
    if (this.isEditConnection === true) {
      this.isEditConnection = false;
      return;
    }
    this.isEditConnection = true;
    let connid = this.connectionInstanceId.toString();
    let connectionInstance: any = await this.connectorService
      .getConnectionDetailById(connid)
      .toPromise();
    this.newConnectionName = connectionInstance.payload.label;
    let inputFields = connectionInstance.payload.input_fields;
    this.newConnectionForm.build(inputFields);
  }

  async saveEditConnection() {
    let conn = this.connectionInstanceId.toString();
    const data = this.newConnectionForm.getValue();
    const newForm = data;
    let newConnectionReqest: any = {
      nodeName: this.nodeName,
      name: this.newConnectionName,
      label: this.newConnectionName,
      inputConfigs: {}
    };
    newForm.forEach(d => {
      newConnectionReqest.inputConfigs[d.name] = { value: d.value };
    });

    await this.nodeService
      .updateConnectionInstance(conn, newConnectionReqest)
      .toPromise();
    this.isEditConnection = false;
  }

  //working
  async newConnection() {
    if (this.isNewConnection === true) {
      this.isNewConnection = false;
      return;
    }
    this.isNewConnection = true;
    this.newConnectionName = '';
    let connectionInputFields = await this.connectorService
      .getNewConnectionForm(this.nodeData.connectorId.toString())
      .toPromise();
    console.log(connectionInputFields);
    this.newConnectionForm.build(connectionInputFields);
  }

  //working
  popup(data: any) {
    let dialogRef = this.dialog
      .open(this.confirmationPopup, {
        data
      })
      .afterClosed()
      .subscribe((str: string) => {
        if (str === 'no') {
          //change connection to previous
          this.connectionInstanceId = this.previousConnectionId;
        } else if (str === 'yes') {
          //call the api as usual
          // this.updateNodeInstance();
          // {{url}}/core/v2/pipeline/1/3/node/4413d5cf-2217-451d-82e4-b0f7a1a95bb0/setConnection
          console.log('yo');
          this.nodeService
            .setNodeConnection(
              this.nodeData.pipelineId.toString(),
              this.nodeData.pipelineVersionId.toString(),
              this.nodeData.uuid,
              this.connectionInstanceId
            )
            .subscribe(data => console.log(data));
          if (this.connectionInstanceId) {
            this.isServicesEnabled = true;
            this.selectedService = '';
          }
        }
      });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Context} from "../config/context.service";

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private http:HttpClient,
              private context: Context,) {
    this.url = context.getApiConfig().DATA_APIS.GET.upload
    this.temp_url = context.getApiConfig().DATA_APIS.GET.upload
    this.changeDataType_url = context.getApiConfig().DATA_APIS.POST.changeDataType
  }

  url='';
  temp_url = '';//to connect to django server
  changeDataType_url = '';

  getAllFiles(){
  	return this.http.get(this.url);
  }


  getFileById(){
  	return this.http.get(this.url+'/file/'+'id');//need to change url as per end-points
  }

  uploadFile(data: FormData) {
  	return this.http.post<{payload: any}>(this.url, data)
  }

  changeDatatype(data:any){
    return this.http.post(this.changeDataType_url,data)
  }
  

  getPublicUrl(privateUrl: string, filename: string) {
    return this.http.get<{payload: {URL: string}}>(this.context.getApiConfig().DATA_APIS.GET.getPublicUrl, {params: {
      url: privateUrl,
      filename: filename
    }})
  }

  /**
   * getFile file from url
   * @param publicUrl - any public url
   */
  getPublicFile(publicUrl: string) {
    return this.http.get<File>(publicUrl);
  }

}

<div #board style="display: flex;position: sticky; height: 100%;" nbSpinnerSize="giant" nbSpinnerStatus="primary"
     [nbSpinner]="isLoading"
     nbSpinnerMessage="">

  <!--  Toolbar  -->
  <!--    <nb-layout-header subheader>-->
  <!--      <b>{{currentPipeline.name}}</b>-->
  <!--      <nb-actions>-->
  <!--        <nb-action-->
  <!--          nbTooltip="Add Node"-->
  <!--          nbTooltipPlacement="bottom" (click)="selectTransformations();addTaskButton=true;">-->
  <!--          <button nbTooltip="Add Node"-->
  <!--                  nbTooltipPlacement="bottom"-->
  <!--                  nbButton-->
  <!--                  shape="round"-->
  <!--                  size="small"-->
  <!--                  status="primary">-->
  <!--            <nb-icon icon="plus-outline" style="color: white"></nb-icon>-->
  <!--            Node-->
  <!--          </button>-->
  <!--        </nb-action>-->
  <!--      </nb-actions>-->
  <!--    </nb-layout-header>-->








  <!-- Board -->
  <div style="flex: 3;padding: 0;margin: 0;position: relative;">
<!--    <div [nbSpinner]="true" nbSpinnerStatus="warning" [style.height]="isRunning?'2rem':'0'"-->
<!--         style="width: 2rem; position: absolute; bottom: 0; right: 0;transition: 1s;">-->
<!--    </div>-->



    <!-- Toolbar -->
    <!--  TODO: move custom toolbar css to file  -->
    <div style="padding: .5rem;
      display: flex;
      gap:.5rem;
      align-items: center;
      background: transparent;
      border: 1px solid #D6D6D6;
      ">


      <a style="background-color: transparent;" nbButton outline size="small" status="info" nbTooltipPlacement="right" nbTooltip="Back" [routerLink]="backUrl" [replaceUrl]="false" [skipLocationChange]="false" (click)="goBack($event)">
        <nb-icon icon="arrow-back-outline" style="color: #4C7FF0"></nb-icon>
      </a>

      <div id="toolbar-pipeline">
        {{pipelineNameInput}}
      </div>

      <!-- <div id="toolbar-pipeline">
        <input type="text" [(ngModel)]="pipelineNameInput" title="Update Pipeline Name">
        <button title="save pipeline name" [style.opacity]="pipelineNameInput===pipeline?.name?0:1" [style.visibility]="pipelineNameInput===pipeline?.name?'hidden':'visible'" (click)="updatePipelineName(pipelineNameInput)">
          <nb-icon icon="save" style="color: #0095ff;"></nb-icon>
        </button>
      </div>

      <div>
        <select (change)="focusNode($event)" style="padding: .2rem;border: 1px solid #eee;border-radius: 0.5rem;">
          <option *ngFor="let item of nodes" [value]="item.id">{{item.name}}</option>
        </select>
      </div> -->

      <!-- <nb-button-group status="basic" size="small" outline  *ngIf="selectEnabled" style="margin-left: auto;">
        <button nbButton nbTooltip="Drag" (click)="selectedNodesActions('drag')"><nb-icon [icon]="'move-outline'"></nb-icon></button>
        <button nbButton nbTooltip="Edit"><nb-icon [icon]="'edit-2-outline'"></nb-icon></button>
        <button nbButton nbTooltip="Save as Template"><nb-icon [icon]="'save-outline'"></nb-icon></button>
        <button nbButton nbTooltip="Delete" (click)="selectedNodesActions('delete')"><nb-icon [icon]="'trash-2-outline'"></nb-icon></button>
        <button nbButton nbTooltip="Clear" (click)="selectedNodesActions('clear')"><nb-icon [icon]="'close-outline'"></nb-icon></button>
      </nb-button-group> -->


      <!-- <nb-button-group  style="margin-left: auto;"  status="basic" size="small" class="drag-menu">
        <button nbButton nbTooltip="default layout" (click)="triggerDagEvents('DEFAULT',{})"><mat-icon>cached</mat-icon></button>
        <button nbButton nbTooltip="reset positions" (click)="triggerDagEvents('RESET',{})" *ngIf="dragEnabled" [disabled]="!(dagState.undo || dagState.redo)"><nb-icon icon="flip-2-outline" [class.disabled]="!(dagState.undo || dagState.redo)"></nb-icon></button>
        <button nbButton nbTooltip="undo" (click)="triggerDagEvents('UNDO',{})" *ngIf="dragEnabled" [disabled]="!dagState.undo"><nb-icon icon="corner-up-left-outline" [class.disabled]="!dagState.undo"></nb-icon></button>
        <button nbButton nbTooltip="redo" (click)="triggerDagEvents('REDO',{})" *ngIf="dragEnabled" [disabled]="!dagState.redo"><nb-icon icon="corner-up-right-outline" [class.disabled]="!dagState.redo"></nb-icon></button>
        <button nbButton nbTooltip="save positions" (click)="triggerDagEvents('SAVE',{})" *ngIf="dragEnabled" [disabled]="!(dagState.save)"><nb-icon icon="save-outline" [class.disabled]="!(dagState.save)"></nb-icon></button>
        <button nbButton nbTooltip="edit disabled" *ngIf="!dragEnabled" (click)="triggerDagEvents('DRAG_ENABLE',{})"><nb-icon icon="lock-outline"></nb-icon></button>
        <button nbButton nbTooltip="edit enabled" *ngIf="dragEnabled" (click)="triggerDagEvents('DRAG_ENABLE',{})"><nb-icon icon="unlock-outline"></nb-icon></button>
      </nb-button-group>
    

      <button [disabled]="!dashboardService.currentCopyNode.id" class="paste-button" nbTooltip="paste Node" nbButton size="small" status="success" (click)="pasteNode()">
        <nb-icon icon="clipboard-outline"></nb-icon>
      </button> -->

      <!-- <button style="margin-left: 1rem;" outline nbTooltip="Add Node" nbButton size="small" status="success"
        (click)="selectTransformations($event)">
        <nb-icon icon="plus-outline" style="color: #00d68f"></nb-icon>
        Node
      </button> -->
      
      <!-- <div  nbSpinnerStatus="warning" nbSpinnerSize="small" style="height: 1rem; width: 1rem;" ></div> -->

      
      <button  *ngIf="isProgress && runType==='sequential_run'" nbButton (click)="stopPipeline()" shape="round" size="small" style="background-color: rgb(255, 204, 204); border: solid 1px #fd2e2e;margin-left: auto;"><nb-icon nbTooltip="Stop Pipeline" icon="square" style="color:  #fa0808; font-size: 2rem; cursor: pointer;"></nb-icon></button>
      <button  *ngIf="!isProgress || runType!=='sequential_run'" nbButton (click)="runPipeline()" shape="round" size="small" style="background-color: rgb(196, 235, 196); border: solid 1px #2dc55c;margin-left: auto;"><nb-icon nbTooltip="Run Pipeline" icon="arrow-right" style="color:  #2dc55c; font-size: 2rem; cursor: pointer;"></nb-icon></button>

      <button outline nbTooltip="Add Node" nbButton size="small" status="success"
        (click)="selectNodePopup($event)">
        <nb-icon icon="plus-outline" style="color: #00d68f"></nb-icon>
        Node
      </button>


      
      <button nbButton outline size="small" status="info" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <nb-icon icon="more-horizontal-outline" style="color: #4C7FF0"></nb-icon>
      </button>


      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openConstants()">
          <mat-icon>grading</mat-icon>
          <span>Add Pipeline Variable</span>
        </button>
        <button mat-menu-item (click)="openschdeuler()">
          <mat-icon>timer</mat-icon>
          <span>Schedule Pipeline</span>
        </button>
        <button mat-menu-item (click)="openWindow()">
          <mat-icon>download</mat-icon>
          <span>Export Pipeline</span>
        </button>
        <button mat-menu-item (click)="openNotifications()">
          <mat-icon>notifications_none</mat-icon>
          <span>Notification</span>
        </button>
      </mat-menu>
      

      
      

      <!-- <nb-actions size="small">
        <nb-action icon="search-outline"></nb-action>
        <nb-action icon="power-outline"></nb-action>
        <nb-action icon="person-outline"></nb-action>
        <nb-action icon="home-outline"></nb-action>
      </nb-actions> -->

<!--
      <button nbTooltip="Add Variables" nbButton shape="round" size="small" status="primary"
        (click)="openConstants()">
        <nb-icon icon="plus-outline" style="color: white"></nb-icon>PIPELINE VARIABLES
      </button>
      <button nbTooltip="Schedule Pipeline" nbButton shape="round" size="small" status="primary"
        (click)="openschdeuler()">
        <nb-icon icon="plus-outline" style="color: white"></nb-icon>SCHEDULE PIPELINE
      </button> -->
    </div>

    <!-- <app-dag class="dag-component"
      [events]="eventsSubject.asObservable()"
      (callbackEvents)="dagEventHandler($event)"
      [selectionEvents]="$selectNodeAction"
      [dagEvents]="$dagEvents"
      [conditional_node_status]="conditionalNodeStatus"
      >
    </app-dag> -->

    <app-rete-dag style="display: block;height: calc(100% - 3.5rem);position: relative;" [graph]="pipelineGraph" (openSettings)="openReteNodeConfig($event)" (openActions)="openCreateNodePopup($event)"></app-rete-dag>

    <!--  Node Output  -->
    <!-- TODO: Move all css part to style component or css file   -->
    <!-- <app-output
      style="
          position: absolute;
          bottom: 0;
          width: 100%;
          background-color: white;
          border: 1px solid #8080802b;
          z-index: 200;"
      *ngIf="output"
      [status]="output_data.status"
      [taskData]="files_data"
      [validation]="output_data.validations"
      [api]="output_data.api_upload"
      [taskId]="output_data.taskId"
      [Conditional_upload]="output_data.conditional_upload?.status"
      [name]="output_data['name']"
      (close)="output=false">
    </app-output> -->


    <app-nodes-output 
      style="
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 200;"
      *ngIf="output"
      [events]="nodes_output$.asObservable()">
    </app-nodes-output>

  </div> 

  <div style="background-color: white;overflow: auto;position: relative;transition: opacity .5s" [style]="{opacity: uiService.showSideNav?'1':'0', width: uiService.showSideNav?'22rem':'0rem'}">
    <div>
      <ng-template appTaskDetails></ng-template>
    </div>
  </div>

</div>

<ng-template #templatePortalContent>
  
  <ul style="list-style: none;padding: .2rem;margin:0;background-color: white;border-radius: .5rem;display: flex;flex-direction: column;position: relative;z-index: 2;">
    <li *ngFor="let config of recentConfigs" style="cursor: pointer;padding: .3rem .5rem;display: flex;align-items: center;" [title]="config.desc" class="dag-menu-item" (click)="openCreateTask(config, [currentHoverNode?.id||-1], pipeline?.id||-1, pipeline?.process?.id || -1)">
      <img *ngIf="config.icon.type==='custom'" style="width: 2.5rem; height: 1.8rem;" [attr.src]="config.icon.icon"/>
      <span style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{config.desc}}</span>
    </li>
  </ul>

  <div style="background-color: white;width: 1rem;height: 1rem;top:50%;left:2px;transform: translateY(-50%) rotateZ(45deg);position: absolute;z-index: 1;"></div>
  
</ng-template>

<ng-template let-data #getConnectionTypeComponent>
  <div style="display: flex; flex-direction:column;gap: .5rem; min-width: 20rem;min-height: 10rem;">
    <header style="padding: .5rem;">
      <div style="display: flex;flex-direction: column;">
        <span><b>Connect</b></span>
        <div style="display: flex;align-items: center;">
          <span style="font-size:12px"> {{data.sourceNode.label}}</span>

          <span class="material-icons-outlined">arrow_right_alt</span>

          <span style="font-size:12px">{{data.targetNode.label}}</span>
        </div>
      </div>
    </header>

    <section style="padding: 0 .5rem;display: flex;flex-direction: column;gap: 0.5rem;">
      <input type="text" placeholder="input name" [(ngModel)]="data.field_name">
    </section>
  
    <footer style="padding: .5rem;display: flex;gap: 1rem;align-items: center;justify-content: end;">
      <button nbButton size="small" status="info" (click)="data.actions.submit(data.field_name)">Connect</button>
      <button nbButton size="small" (click)="data.actions.close()">Cancel</button>
    </footer>
  </div>
</ng-template>

import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {ConfigKeyModel, ConfigModel} from "../../../../models/config.model";
import {ConfigKeyService, ConfigServiceResponse} from "../../../../services/api/configKey.service";
import {PipelineAPIResponse, PipelineService} from "../../../../services/api/pipeline.service";
import {NodeService, NodeServiceResponse} from "../../../../services/api/node.service";
import {DropdownOptionType, InputFieldType} from "../addtask/add-task.component";
import {ConsoleLoggerService} from "../../../../services/logger/console-logger.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FileService} from "../../../../services/api/file.service";
import {ConfigureApiService} from "../../../../services/api/configure-api.service";
import {NbDialogService} from "@nebular/theme";
import {Node} from "../../../../models/tasknode.model";
import {TaskService} from "../../../../services/api/task.service";
import {
  ButtonType,
  CloseButton,
  CreateButton,
  LoadDefaultConfigurations,
  OutputEventEnum,
  PreviewButton,
  RetryTaskButton,
  RunTaskButton,
  UpdateButton,
  addInputField,
  boolThreeState,
  checkDropdown,
  enableButtons,
  getInputFromConfigKey,
  getInputHandlers,
  getSingleDropdownSelector,
  inputsVisitor,
  removeInputField,
  transformData_v1,
  userDefineConfigValues,
  disableInputField,
  formatSql
} from "../helper-functions";
import { BehaviorSubject, Subject } from 'rxjs';
import { EventTypes } from 'src/app/components/multi-select-dropdown/union.component';
import { DashboardStoreService } from 'src/app/services/store/dasboard-store.service';
import { NodeStatusResponse, NodeStatusService } from 'src/app/services/api/node-status.service';
import { UIService } from 'src/app/services/config/ui.service';

import { schema_configs, schema_key, schema_response, schema_sheet } from 'src/app/models/schema.model';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

export type OutputEventType = keyof typeof OutputEventEnum

export const INPUT_FIELD_KEY = 'id'
export const INPUT_FIELD_CONFIG_KEY = 'id'


@Component({
  selector: 'app-update-task',
  templateUrl: './update-task.component.html',
  styleUrls: ['./update-task.component.css'],
  providers: [MatSnackBar]
})
export class UpdateTaskComponent implements OnInit {
  INPUT_FIELD_KEY = INPUT_FIELD_KEY
  INPUT_FIELD_CONFIG_KEY = INPUT_FIELD_CONFIG_KEY

  @Input() type!: ConfigModel;
  @Input() pipelineId!: string | number;
  @Input() processId!: string | number;
  @Input() currentNodeId!: number;
  @Output() output = new EventEmitter<{type: OutputEventType|string, data: any}>();
  @Input() isReadonly: boolean = false
  @Input() schemaProperties: {
    hasSchema:boolean,
    schema:Array<schema_sheet>
  } = {
    hasSchema : false,
    schema : []
  }

  // Metadata
  configKeyResponse!: ConfigServiceResponse['payload']

  // Data
  pipeline!: PipelineAPIResponse['payload']
  configData!: NodeServiceResponse['payload']
  taskConfigDetails!: NodeServiceResponse
  currentNode!: Node
  cacheData: Map<string, any> = new Map<string, any>()


  // State
  loadingError: string = ''
  actionButtons: Array<ButtonType> = []
  inputActionButtons: Array<ButtonType> = []
  inputHandlers!:  ReturnType<typeof getInputHandlers>

  formInputs: Array<InputFieldType> = []
  keyInputMap: {[key: string]: InputFieldType, [key: number]: InputFieldType} = {}

  // sideEffect functions should be called after change in any input field
  // !!should not change configKeyValues or call handleInputChange function!!
  sideEffects: Array<(state: UpdateTaskComponent, event: any) => void> = []

  @ViewChildren(CodemirrorComponent) codemirrorComponents!: QueryList<CodemirrorComponent>;

  formatCode(id: string, input: InputFieldType) {
    let editor = this.codemirrorComponents.find((el: any) => el._options.id === id)
    if(editor) {
      let value = formatSql(editor.value)
      input._value = value
      // editor.writeValue(value)
    }
  }

  @ViewChild('editor') editor!: TemplateRef<HTMLDivElement>
  openCodeEditor(event: any, input: InputFieldType) {
    // console.log(input)
    let dialogConfig = {
      _value: input._value as string || '',
      get value() {
        return dialogConfig._value
      },
      set value(value) {
        input._value = value
        dialogConfig._value = value
      },
      isLoading: true,
      format: () => {
        let code = formatSql(dialogConfig.value)
        input._value = code
        dialogConfig.value = code
      },
      options: {
        id: `editor-${input.id}`, 
        lineNumbers: true,
        theme: 'default',
        mode: 'sql'
      }
    }
    let dialogRef = this.dialog.open(this.editor, {
      data: dialogConfig,
      panelClass: 'custom-dialog-container',
      // minHeight:'80vh',
      maxHeight: '50vh', 
      minWidth: '60vw',
      maxWidth: '60vw',
    })
    setTimeout(() => {
      let editor = this.codemirrorComponents.find((el: any) => el._options.id === dialogConfig.options.id)
      if(editor) {
        editor.codeMirror?.refresh()
        // editor.codeMirror?.setSize("100%", "100%")
        dialogConfig.isLoading = false
      }
    }, 500)
  }
  
  constructor(
    private logger: ConsoleLoggerService,
    private configKeyService: ConfigKeyService,
    private dashboardStoreService : DashboardStoreService,
    public pipelineService: PipelineService, // TODO: make service private
    public taskService: TaskService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public nodeService: NodeService,
    public fileService: FileService,
    public dialogService: NbDialogService,
    public configureAPIService :ConfigureApiService,
    public nodeStatusService:NodeStatusService,
    public uiService: UIService,
  ) {

    this.inputHandlers = getInputHandlers(this)

    this.sideEffects.push(enableButtons)
    this.sideEffects.push(inputsVisitor)
    this.sideEffects.push(checkDropdown)

    this.output.subscribe(logger.log)
  }

  async ngOnInit() {
    try {
      await this.loadData()
      this.output.next({type: OutputEventEnum.ON_LOAD, data: {component: this.constructor.name}})
    } catch (error) {
      this.output.next({type: OutputEventEnum.LOADING_ERROR, data: {component: this.constructor.name, error}})
    }
  }

  private async loadData() {
    // Load pipeline
    this.pipeline = this.dashboardStoreService.getCurrentPipeline()!
    if(!this.pipeline) {
      this.pipeline = await this.pipelineService.getPipeline(this.pipelineId as number).toPromise()
    }

    this.currentNode = this.pipeline.nodeInstances.find(node => node.id === this.currentNodeId)!

    // Load configuration
    // this.configKeyResponse = (await this.configKeyService.getConfigKey(this.type.id, this.currentNode.incomingTaskIds).toPromise()).payload

    // taskConfigDetails
    this.taskConfigDetails = await this.nodeService.getNodeConfig(this.currentNode.id, []).toPromise() // FIXME: V2 incoming node api changes
    // this.taskConfigDetails = await this.nodeService.getNodeConfig(this.currentNode.id, this.currentNode.incomingTaskIds).toPromise()
    this.configKeyResponse = {configs: this.taskConfigDetails.payload.configs||[], parentConfigs: this.taskConfigDetails.payload.parentConfigs||[], ...this.taskConfigDetails.payload.configDetails}

    // Load default input fields
    LoadDefaultConfigurations(this)

    // Load input fields from configuration
    this.configKeyResponse.configs.forEach(key => addInputField(this, getInputFromConfigKey(this, key)))
    if(this.configKeyResponse.parentConfigs.length)this.formInputs.push({display_text: 'Parent Configs', isValid: ()=> true, type: 'input-separator'} as any)
    this.configKeyResponse.parentConfigs.forEach(key => addInputField(this, disableInputField(getInputFromConfigKey(this, key))))

    // refill data
    this.refillData()

    // load sideEffects
    const dependentDropdowns: Array<InputFieldType> = []
    if(this.keyInputMap['dependent'])dependentDropdowns.push(this.keyInputMap['dependent'])
    if(this.keyInputMap['data_dependent'])dependentDropdowns.push(this.keyInputMap['data_dependent'])
    if(this.keyInputMap['union'])dependentDropdowns.push(this.keyInputMap['union'])
    this.sideEffects.push(getSingleDropdownSelector(this, dependentDropdowns))

    // Add Save button
    this.actionButtons.push(UpdateButton)
    this.actionButtons.push(RunTaskButton)
    this.actionButtons.push(RetryTaskButton)
    enableButtons(this, {type: 'INIT'})
    this.sideEffects.forEach(visitor => visitor(this, {type: 'INIT'}))
  }

  public addInputField(input: InputFieldType) {
    addInputField(this, input)
    this.handleInputChange({configKeyName: '', value: ''})
  }

  public removeInputField(input: InputFieldType) {
    removeInputField(this, input)
    this.handleInputChange({configKeyName: '', value: ''})
  }

  // handle final input change.
  handleInputChange(event: { configKeyName: string|number, value: string | number | unknown }): void {
    this.sideEffects.forEach(visitor => visitor(this, event))
  }

  // validate input after update
  // TODO: use input validator
  validateInput(configKeyId: number, value: string | number | unknown): { valid: boolean, error?: string } {
    return {valid: true, error: ''}
  }

  refillData() {

    const APPLY_TASK_SPACIFIC_TO = 'union';

    // update name
    if(this.keyInputMap['name'] && this.keyInputMap['name']) {
      this.keyInputMap['name']._value = this.taskConfigDetails.payload.name
    }

    
    // reference node id
    if(['management'].includes(this.configKeyResponse.type) && ['reference_node'].includes(this.configKeyResponse.name)) {
      this.keyInputMap['reference']._value = `${this.taskConfigDetails.payload.parent_id}`

      // this.taskConfigDetails.payload.configs = this.taskConfigDetails.payload.parentConfigs
      console.log(this.taskConfigDetails.payload.parentConfigs)
    }

    //update schema
    if(!this.taskConfigDetails.payload.schemaConfigs) {
      this.logger.error(`schemaConfigs not found in taskDetails!`);
      this.schemaProperties = {
        hasSchema: false,
        schema: []
      }
    }
    if(this.taskConfigDetails.payload.schemaConfigs && (this.taskConfigDetails.payload.schemaConfigs as Array<Object>).length > 0) {

      let schema:schema_response = this.taskConfigDetails.payload.schemaConfigs
      let sheets_array:schema_configs = {
        schemaDetails : schema.map((sheet:any)=>{

          let schema_sheet:schema_sheet = {
            id:sheet.id,
            sheetName: sheet.name,
            matchingType: sheet.match_type!=null ? sheet.match_type : "001",
            schema: sheet.schema.map((key:any)=>{
              let schema_key:schema_key = {
                columnName: key.key_name,
                dType: key.data_type,
                isMandatory: key.is_mandatory,
                order: key.order,
                id:key.id
              }
              return schema_key
            })
          };

          return schema_sheet
        })
      }

      this.schemaProperties = {
        hasSchema: true,
        schema: sheets_array.schemaDetails
      }
    }

    // fill dependent
    if(this.keyInputMap['dependent']) {
      const dependentNode = this.taskConfigDetails.payload.dependent_nodes.filter(x => ['run_dependent', 'conditional'].includes(x.type));
      dependentNode.sort((a, b) => {
        return a.order - b.order;
      });
      this.keyInputMap['dependent']._value = dependentNode.map(x => x.taskId)
      let selectedNodeMap = dependentNode.reduce((prev, current) => {
        prev[current.taskId] = current
        return prev
      }, {} as {[key: number]: any})
      this.keyInputMap['dependent'].options.map(option => {
        if(selectedNodeMap[option.id as number]) {
          option.booleanValue = boolThreeState(selectedNodeMap[option.id as number].weight)
        }
        return option
      })
    }

    // fill data dependent
    if(this.keyInputMap['data_dependent']) {
      const dependentNode = this.taskConfigDetails.payload.dependent_nodes.filter(x => ['data', 'conditional_data'].includes(x.type));
      dependentNode.sort((a, b) => {
        return a.order - b.order;
      });
      this.keyInputMap['data_dependent']._value = dependentNode.map(x => x.taskId)
      let selectedNodeMap = dependentNode.reduce((prev, current) => {
        prev[current.taskId] = current
        return prev
      }, {} as {[key: number]: any})
      this.keyInputMap['data_dependent'].options.map(option => {
        if(selectedNodeMap[option.id as number]) {
          option.booleanValue = boolThreeState(selectedNodeMap[option.id as number].weight)
        }
        return option
      })
    }

    // fill union
    if(this.keyInputMap['union']) {
      const unionNode = this.taskConfigDetails.payload.dependent_nodes.filter(x => ['data'].includes(x.type));
      this.keyInputMap['union']._value = unionNode.map(x => x.taskId)
      let selectedNodeMap = unionNode.reduce((prev, current) => {
        prev[current.taskId] = current
        return prev
      }, {} as {[key: number]: any})
      this.keyInputMap['union'].options.map(option => {
        if(selectedNodeMap[option.id as number]) {
          option.booleanValue = boolThreeState(selectedNodeMap[option.id as number].weight)
        }
        return option
      })
    }

    const refillConfigs = (configs: NodeServiceResponse['payload']['configs']) => {
      for(const key of configs) {

        // taskSpecific
        if(key.is_node_specific) {
          // refill value
          this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = key.userValue.node_specific_values || []
  
          // load dropdowns
          let unionSelectedOptions = this.keyInputMap[APPLY_TASK_SPACIFIC_TO].options.filter(x => x.isSelected);
          let taskSpectfigConfigHandlers = this.keyInputMap[key.id].eventHandlers;
  
          taskSpectfigConfigHandlers?.forEach(handler => {
            unionSelectedOptions.forEach(option => handler(this, {type: EventTypes.ON_SELECT, args: [option]}))
          });
  
          continue;
        }
  
        // text input
        if(key.key_value_input_box_type === "text" && key.key_value_type !== 'file' && this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]) {
          if(key.is_multi_select) {
            this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = key.userValues.value
          }
          else {
            this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = key.userValue.value
          }
        }
  
        // sql input
        if(key.key_value_input_box_type === "sql_query" && this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]) {
          if(key.is_multi_select) {
            this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = formatSql(key.userValues.value)
          }
          else {
            this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = formatSql(key.userValue.value)
          }
        }

        // custom_inputs Object
        if(key.key_value_input_box_type === "custom_inputs" && key.key_value_type !== 'file' && this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]) {
          // TODO: Create custom inputs
          this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]].__value = JSON.parse(key.userValue.value || '{}');
          this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = JSON.stringify((this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]].__value as any).value || {});
  
          const refillData = (this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]].__value as any).value;
  
          const inputs = (this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]].__value as any).inputs as Array<ConfigKeyModel>;
  
          // console.log('Creating custom inputs...', inputs);
  
          inputs?.forEach(inputTemplate => {
            let input = getInputFromConfigKey(this, inputTemplate)
            console.log(input)
            input._value = refillData[input.display_text];
            this.addInputField(input)
          })
        }
  
        // file input
        if(key.key_value_input_box_type === 'text' && key.key_value_type === 'file') {
          this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._url = key.userValue.value
        }
  
        if(key.key_value_input_box_type === 'task_specific') {
          this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = JSON.parse(key.userValue.value||'[]')
        }
  
        // dropdown input
        if(['drop_down', 'dropdown'].includes(key.key_value_input_box_type) && this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]) {
          if(key.userValues) {
            this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = (key.userValues as any).value
            if(this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]].events) {
              (this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]].events as BehaviorSubject<{type:string, args: Array<unknown>}>).next({
                type: 'update-selected-nodes', args: [this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value]
              });
            }
  
          }
          if(key.userValue) {
            this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = key.userValue.value
          }
        }
  
        // textarea input
        if(key.key_value_input_box_type === 'textarea_code') {
          this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = key.userValue.value
        }
      }
    }

    refillConfigs(this.taskConfigDetails.payload.configs ?? [])
    refillConfigs(this.taskConfigDetails.payload.parentConfigs ?? [])
  }

  async save() {

    // transform data to payload
    let payload = await transformData_v1(this)

    // appind user define configs
    // let custom_input_config =  this.configKeyResponse.configs.find(x => x.key_value_input_box_type = 'custom_inputs');
    // if(custom_input_config) {

    //   const userCustonConfig = await userDefineConfigValues(this, -100)
    //   console.log(userCustonConfig)

    // }


    // Update TaskNode
    const response = await this.dashboardStoreService.updateTask(this.currentNode.id, payload);

    // emit save event
    this.output.next({type: OutputEventEnum.SAVED_AND_EXIT, data: {payload, response}})

  }

  close() {
    this.output.next({type: OutputEventEnum.CANCEL, data: {}})
  }


  open_schema(){
    this.output.next({type: OutputEventEnum.OPEN_SCHEMA, data: {}})
  }

  edit_schema(){
    this.output.next({type: OutputEventEnum.EDIT_SCHEMA, data: this.schemaProperties})
  }

}


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Context } from '../../config/context.service';
import { ServiceResponse } from '../../core/data.service';

export type GroupResponse = { totalCount: number, pageCount: number, data: Array<Group>, currentPage: number }
export interface Group {
  id: number;
  accountId: number;
  name: string;
  label: string;
  description: string;
  icon: string;
  createdBy: string;
  createdTime: number;
  updatedTime: number;
  directories:Array<Directory>;
}

export interface Directory {
  id: number;
  accountId: number;
  groupId: number;
  parentDirId: number | null;
  name: string;
  label: string;
  description: string;
  createdBy: string;
  createdTime: number;
  updatedTime: number;
  childDirectories?:Array<Directory>
}

@Injectable({
  providedIn: 'root'
})
export class Groupv2Service {
  constructor(private http:HttpClient,private context:Context){

  }

  getGroups(params: { [key: string]: string | number | undefined, pageNum?: number, itemsPerPage?: number, search?: string, groupId?:string, directoryId?:string } = {}){
    return this.http.get<ServiceResponse<GroupResponse>>(this.context.getApiConfig().GROUP_APIS_V2.GET.groups, { params: JSON.parse(JSON.stringify(params)) });
  }

  getGroupById(groupId:number|string){
    return this.http.get(this.context.getApiConfig().GROUP_APIS_V2.GET.group(groupId.toString()));
  }

  getDirectory(directoryId:number){
    return this.http.get(this.context.getApiConfig().DIRECTORY_APIS_V2.GET.directory(directoryId.toString()))
  }
  createGroup(data:any){
    let groupData = {
      name:data.name,
      label:data.label,
      description:data.description,
      icon:"https://icon3.com"
    }
    return this.http.post(this.context.getApiConfig().GROUP_APIS_V2.POST.group,groupData)
  }
  createDirectory(data:any,groupId:any,directoryId?:any){
    let directoryData = {
      parentDirId:directoryId,
      groupId:groupId,
      name:data.name,
      label:data.label,
      description:data.description,
      icon:"https://icon3.com"
    }
    return this.http.post(this.context.getApiConfig().DIRECTORY_APIS_V2.POST.directory,directoryData)
  }

  getAllDirectories(){
    return this.http.get(this.context.getApiConfig().GROUP_APIS_V2.GET.allDirectories)
  }

  updateGroup(groupId:string,data:any){
    let mappedData = {
      label:data.name,
      description:data.description
    }
    return this.http.put(this.context.getApiConfig().GROUP_APIS_V2.PUT.group(groupId),mappedData)
  }

  updateDirectory(directoryId:string,data:any){
    let mappedData = {
      label:data.name,
      description:data.description
    }
    return this.http.put(this.context.getApiConfig().DIRECTORY_APIS_V2.PUT.directory(directoryId),mappedData)
  }

}

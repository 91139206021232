import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject, of} from "rxjs";
import {map} from "rxjs/operators";


import {ConfigModel} from '../../models/config.model';
import {DataService, ServiceResponse} from '../core/data.service';
import {Context} from '../config/context.service';

type ConfigServiceResponse = ServiceResponse<{ data: Array<ConfigModel> }>;

@Injectable({
  providedIn: 'root'
})
export class ConfigService implements DataService<ConfigModel> {
  private readonly url: string;
  public taskConfigTypes: Array<ConfigModel> = []

  constructor(private context: Context, private http: HttpClient) {
    this.url = context.getApiConfig().CONNECTOR_API.GET.connectors
  }

  public async getAll(filter?: Partial<ConfigModel>): Promise<Array<ConfigModel>> {
    if(this.taskConfigTypes.length === 0) return await this.loadConfigs()

    return this.taskConfigTypes
  }

  public async loadConfigs(): Promise<Array<ConfigModel>> {
    let response = await this.http.get<ConfigServiceResponse>(this.url).toPromise()
    let configs = response.payload.data.map(x => ({...x,icon: this.parseTheme(x.icon as unknown as string)}))
    this.taskConfigTypes = configs
    return configs
  }

  private parseTheme(str: string) {
    if(str.startsWith('{')) {
      return JSON.parse(str)
    }
    return {icon: ''}
  }

  addConfigValue(data: { config_key_id: number, display_text: string, value: number }) {
    return this.http.post(this.context.getApiConfig().CONFIG_APIS.POST.nodeType, data)
  }
}

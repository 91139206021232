import { Component, OnInit, Input as Ip, TemplateRef, QueryList, ViewChildren, HostListener, EventEmitter, Output, ElementRef, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Nodev2Service } from 'src/app/services/api/nodev2.service';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Pipelinev2Service } from 'src/app/services/api/v2/pipelinev2.service';
import { FormService } from 'src/app/services/api/form.service';
import { InputType, Form, ManualTriggerInputRemap, FormUpdateHandlerOnBlur, FileInput, Input, MultiFileInput } from './InputType';
import { UIService } from 'src/app/services/config/ui.service';
@Component({
  selector: 'app-upload-utility-basic-config',
  templateUrl: './upload-utility-basic-config.component.html',
  styleUrls: ['./upload-utility-basic-config.component.scss']
})
export class UploadUtilityBasicConfigComponent implements OnInit, OnDestroy {
  fileSchemaInput: { label: string, schemaPreview: { columns: Array<any>, data: Array<any> }, downloadFileUrl: string } = { label: '', schemaPreview: { columns: [], data: [] }, downloadFileUrl: "" }
  formUpdateHandler: any;
  selectedFile!: string | null;
  schemaPreview: string = ''
  newfileUploadForm = new Form()
  manualTriggerFormRemap: any
  pipelineVersionId: number = 1
  nodeOutput: any
  nodeOutputError: any
  isProcessFailed: boolean = false
  
  runPipelineSection: Array<{ description: string, name: string, label: string, order: number, input_fields: any }> = []
  @Output() formCompleted = new EventEmitter<void>();

  @ViewChild('schemapreview') schemapreview!: any;
  @ViewChild('editcell') editcell!: any;
  @ViewChild('schemaCheck') schemaCheck!: any;

  sectionForms: { [key: string]: Form } = {};
  sections: Array<{ description: string, name: string, label: string, order: number, input_fields: any }> = []
  activeSection: { description: string, name: string, label: string, order: number, input_fields: any } = this.sections[0]
  completedSections: Array<{ description: string, name: string, label: string, order: number, input_fields: any }> = []
  completedSection: { description: string, name: string, label: string, order: number, input_fields: any } = { description: '', name: '', label: '', order: 0, input_fields: {} }
  processStatus: string = '';
  pipelineRunStatus: string = ''
  showPipelineRun: boolean = true
  isPipelineRunSuccess: boolean = false
  isPipelineRunFailed: boolean = false
  isLoading: boolean = false
  showOuputTable = false;
  showEditCellPage = false
  enableContinue: boolean = false
  showSchemaCheck: boolean = true
  formInputLoader: boolean = false
  failedJobs: number = 0
  successJobs: number = 0
  fileError: { type: string, message: string, errors: any } = { type: 'schema', message: '', errors: {} }
  pipelineName: string = ''
  pipelineId: number = 0
  uploadState: boolean = false
  showBackAndContinueButton:boolean = false

  cellCheckTableColumn: Array<{ column_name: string, data_type: {}, order: string, column_id: string }> = [{ column_name: "", data_type: {}, order: "", column_id: "" }]
  cellCheckTableData: [] = []
  cellCheckErrors: Array<{ message: string, effected_cell: string }> = [{ message: '', effected_cell: 'A2' }]
  fileSchemaError: any
  selectedFileInput: FileInput | null = null
  selectedMultiFileInput: MultiFileInput | null = null
  level1Message: string = ''
  level2Message: string = ''
  subject: string = ''
  processInProgress: boolean = false
  downloadFileUrl: string = ''
  downloadInputOutputFileUrl:string = ''
  fileUploadLoader: boolean = false
  metaData = { value: ['Text', 'Unique_Id', 'Null', '<=3', 'Primary'] } // for temporary
  filesRemoved: boolean = false
  constructor(private UIService: UIService, private changeDetectorRef: ChangeDetectorRef, private router: Router, private formDataService: FormService, private dialog: MatDialog, private route: ActivatedRoute, private pipelineService: Pipelinev2Service) {
  }

  MetaDataType = {
    Text: 'outline-info',
    Unique_Id: 'outline-success',
    Null: 'outline-warning',
    '<=  ': 'outline-info',
    Primary: 'outline-primary'
  } as any

  @Output() previewData: EventEmitter<any> = new EventEmitter<any>();
  private interval: any
  ngOnInit(): void {
    // this.processInProgress = true
    this.showPipelineRun = false
    this.route.queryParams.subscribe(async params => {
      const pipelineId = +params['pipelineId']; // Extract the pipelineId from the route parameter
      const processId = +params['processId']; // Extract the processId from the route parameter
      this.checkStatus(pipelineId, processId);
      this.loadpipelineDetails(pipelineId)

    });

  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  async loadpipelineDetails(id: number) {
    const data = await this.pipelineService.getPipelineById(id).toPromise()
    const pipelineName = data.payload.label
    this.pipelineName = pipelineName
    this.pipelineId = data.payload.id

  }

  checkStatus(pipelineId: number, processId: number) {
    const self = this
    self.isLoading = true
    self.processStatus = 'open'

    const execute = async (status: string) => {

      if (self.processStatus === 'open' && (status === 'intermmediate' || status === 'intermediate')) {
        self.processStatus = status
        self.processInProgress = false
        self.isLoading = false
        let data: any = await self.pipelineService.nodeOutput(pipelineId, processId).toPromise();
        let selectedService = data.payload?.response?.value?.services
        self.sections = selectedService?.sections;
        self.activeSection = self.sections[0] || JSON.parse(localStorage.getItem('activeSection')!)
        self.sections.forEach(section => {
          const inputFields = section.input_fields;
          self.sectionForms[section.name] = new Form();
          self.sectionForms[section.name].build(Object.values(inputFields));
          self.showBackAndContinueButton = true
          const manualTriggerFormRemap = new ManualTriggerInputRemap(pipelineId, this.pipelineService, processId, this.pipelineVersionId, self.activeSection, self.sectionForms);

          const formUpdateHandler = new FormUpdateHandlerOnBlur(self.sectionForms[section.name], this.pipelineService, manualTriggerFormRemap, self.activeSection, self.sectionForms);
          formUpdateHandler.subscribe()
          self.formDataService.setFormData(Object.values(inputFields));
        })
      }

      if (status === 'success') {

        try {
          self.showBackAndContinueButton = false

          self.sections.forEach(section => {
            const inputFields = section.input_fields;
            self.sectionForms[section.name] = new Form();
            self.sectionForms[section.name].build(Object.values(inputFields));

            const manualTriggerFormRemap = new ManualTriggerInputRemap(pipelineId, this.pipelineService, processId, this.pipelineVersionId, self.activeSection, self.sectionForms);

            const formUpdateHandler = new FormUpdateHandlerOnBlur(self.sectionForms[section.name], this.pipelineService, manualTriggerFormRemap, self.activeSection, self.sectionForms);
            formUpdateHandler.subscribe()
            self.formDataService.setFormData(Object.values(inputFields));
          })
          self.runPipelineSection.push(JSON.parse(localStorage.getItem('activeSection')!))
          self.sections = self.sections.length > 0 ? self.sections : self.runPipelineSection
          self.completedSections = self.completedSections.length > 0 ? this.completedSections : self.runPipelineSection
          self.processStatus = status
          console.log(self.sectionForms)
          self.isLoading = false;
          self.processInProgress = false
          const finalNodeOutput: any = await self.pipelineService.nodeOutput(pipelineId, processId).toPromise();
          let data = finalNodeOutput?.payload?.response?.value;
          self.nodeOutput = data?.output?.value?.raw;
          self.nodeOutputError = data?.errors?.value?.raw
          self.downloadFileUrl = data?.downloadFileUrl?.output_file || data?.downloadFileUrl
          self.downloadInputOutputFileUrl = data?.downloadFileUrl?.input_output_file
          self.failedJobs = parseInt(data.noOfFailedTasks)
          self.subject = data?.subject
          self.level1Message = data?.level1Message
          self.level2Message = data?.level2Message
          self.successJobs = parseInt(data.noOfSuccessfulTasks)
          // this.UIService.showInfo('Process is Successful', 'success')
          if (self.failedJobs !== 0) {
            self.isPipelineRunFailed = true;
          } else if (self.successJobs !== 0 && self.failedJobs === 0) {
            self.isPipelineRunSuccess = true;
          } else {
            this.UIService.showInfo('Pipeline Failed')
          }
        } catch (error) {
          this.UIService.showInfo(error as string)
        }
      }

      if (status === 'in_progress') {
        self.activeSection = JSON.parse(localStorage.getItem('activeSection')!)
        self.showBackAndContinueButton = false
        self.isLoading = false
        self.processInProgress = true
      }

      if (status === 'failed') {
        self.isLoading = false
        self.processInProgress = false
        self.isProcessFailed = true
        // self.UIService.showInfo("Failed Process");
      }
    }


    this.interval = setInterval(async () => {
      let response: any = await self.pipelineService.process(pipelineId, processId).toPromise();
      self.pipelineVersionId = response?.payload?.pipelineVersionId
      await execute(response?.payload?.status)
      if (response.payload.status === 'success') {
        clearInterval(this.interval);

      }

    }, 5000);
  }


  async createUrl(uri: string, Filename: string) {
    console.log(uri)
    let url = await this.pipelineService.getDownloadPublicUrl(uri, Filename).toPromise()
    console.log(url)
    let a = document.createElement("a")
    a.download = Filename
    a.href = url.URL
    a.style.display = 'none';  
    document.body.appendChild(a);
    a.click();  
    document.body.removeChild(a); 
  }

  onDownload(event: any, input: any) {
    this.createUrl(input?.inputConfig?.downloadFileUrl, this.pipelineName + "_schema")
  }

  retrytrigger() {
    this.isPipelineRunFailed = false
    this.isProcessFailed = false
    this.processInProgress = false
    this.pipelineService.triggerPipeline(this.pipelineId).subscribe((response: any) => {
      const processId = response?.payload?.id;
      if (processId) {
        localStorage.removeItem('activeSection')
        this.router.navigate([{ outlets: { view: ['upload'] } }], {
          queryParams: { pipelineId: this.pipelineId, processId: processId }
        });
        this.completedSections = []
      } else {
        this.UIService.showInfo('Invalid response from trigger API');
      }
    });

  }

  onContinueButtonClick() {
    // this.processInProgress = false
    this.showPipelineRun = true
    const currentActiveIndex = this.activeSection?.order - 1; //For understanding of Code, as section order is 1 greater the index so if want next index means current order value of section
    const nextActiveSection = this.sections[currentActiveIndex + 1]
    const currentForm = this.sectionForms[this.activeSection?.name]
    console.log(currentForm.getValue())
    if (nextActiveSection) {
      this.completedSections.push(this.activeSection);
      this.activeSection = nextActiveSection;
      localStorage.setItem('activeSection', JSON.stringify(this.activeSection));
    }
    else {
      // console.log("No more sections. You have reached the end.");
    }
  }

  onBackButtonClick() {
    const currentActiveIndex = this.activeSection?.order - 1;
    const previousActiveSection = this.sections[currentActiveIndex - 1]
    const currentForm = this.sectionForms[this.activeSection?.name]
    if (previousActiveSection) {
      this.completedSections.pop();
      this.activeSection = previousActiveSection
      localStorage.setItem('activeSection', JSON.stringify(this.activeSection));
    }
    else {

    }
  }

  navigateToHomePage(event: any) {
    console.log(event)
    this.router.navigate(["", { outlets: { view: ['pipeline-list'], action: null } }])
  }

  navigateToProcessDetails(event: any) {
    this.route.queryParams.subscribe(async params => {
      const pipelineId = +params['pipelineId']
      this.router.navigate(["", { outlets: { view: ['pipeline-details', pipelineId], action: null } }],)

    });
  }

  form_memo: { [input_field_name: string]: any } = {}
  //for file input
  async onFileSelected(event: any, input: Input) {
    const file = event.detail as File
    this.form_memo[input.name] = file
    const fileObjects: { fileName: string, data: string }[] = [];

    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const fileContentBase64 = btoa(reader.result as string)
        const fileObject = { fileName: file.name, data: fileContentBase64 };
        fileObjects.push(fileObject);
        this.selectedFile = fileContentBase64
        input.value = fileContentBase64

        const pipelineId = this.route.snapshot.queryParams['pipelineId']
        const processId = this.route.snapshot.queryParams['processId']
        let response = await this.pipelineService.uploadFile(pipelineId, this.pipelineVersionId, processId, fileObjects).toPromise()
        input.value = response?.payload[0][fileObject.fileName];
        input.onBlur()
      }
      reader.readAsBinaryString(file);
    }
    this.fileError = input.inputConfig?.error_message!
    console.log(event)
  }




  async onMultiFileSelected(event: any, input: Input) {
    
    const files = event.detail as File[];
    const validFiles = files.filter(file => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      return fileExtension === 'pdf' || fileExtension === 'doc' || fileExtension === 'docx';
  });

    this.uploadState = true;

    this.fileUploadLoader = true
    if (this.form_memo[input.name]) {
      this.form_memo[input.name] = [...this.form_memo[input.name], ...validFiles];
      this.filesRemoved = false
    } else {
      this.form_memo[input.name] = validFiles;
    }
    
    const updatedFiles = this.form_memo[input.name] as File[]

    const processedFiles = await Promise.all(updatedFiles.map(async (file: any) => {
      const reader = new FileReader();

      return new Promise<{ fileName: string, data: string }>((resolve, reject) => {
        reader.onload = () => {
          const fileContentBase64 = btoa(reader.result as string);
          resolve({ fileName: file.name, data: fileContentBase64 });
        }

        reader.onerror = reject;

        reader.readAsBinaryString(file);
      });
    }));
    const pipelineId = this.route.snapshot.queryParams['pipelineId']
    const processId = this.route.snapshot.queryParams['processId']

    let response = await this.pipelineService.uploadFile(pipelineId, this.pipelineVersionId, processId, processedFiles).toPromise()
    input.value = response?.payload;
    input.onBlur();
    this.fileUploadLoader = false

  }

  removeFile(event: any, input: Input) {
    this.filesRemoved = true
    console.log(event)
    const indexToRemove = this.form_memo[input.name].findIndex((x: any) => x.name === event?.detail?.name)// value is coming on 0th index in inputrea
    if (indexToRemove !== -1 && input.value) {
      this.form_memo[input.name].splice(indexToRemove, 1)
      this.form_memo[input.name] = [...this.form_memo[input.name]]
      this.changeDetectorRef.detectChanges()

      //@ts-ignore
      delete input?.value[0][event?.detail?.name]
      // input.onBlur();
      if (this.form_memo[input.name].length === 0) {
        this.uploadState = false
      }
    }
  }

  submitMultiFile(event: any, input: Input) {
    input.onBlur()
  }

  // for other inputs like text and dropdown
  onInputValueChange(event: any, input: any) {
    input.value = event.detail.value
  }

  onCloseSchemaCheck = () => {

  }

  onCloseCellErrorCheck = () => {

  }

  resetFile(event: Event, input: Input) {
    this.form_memo[input.name]  = null
    if (input.formatType === 'multi_file') {
      input.inputConfig.value = undefined
      input.value = undefined
      input.onBlur()
      this.uploadState = false
    }
    input.value = undefined
    input.inputConfig.error_message = undefined
    //@ts-ignore
    input.errors = undefined
    //@ts-ignore
    input.validated = undefined
  }


  schemapreviewEvent(event: any, input: any) {
    this.schemapreview.nativeElement.setAttribute('visible', true)
    this.fileSchemaInput = input
  }



  schemaCheckPreview(event: any, input: any) {
    this.showSchemaCheck = true
    this.selectedFileInput = input
    this.schemaCheck?.nativeElement.setAttribute('visible', true)
    this.onCloseSchemaCheck = () => {
      this.showSchemaCheck = false
      this.resetFile(event, input)
    }
  }

  editCellPreview(event: any, input: any) {
    this.showEditCellPage = true
    this.downloadFileUrl = input.inputConfig?.error_message?.errors?.downloadFileUrl //check BE for correct signature
    this.cellCheckTableColumn = input.inputConfig?.error_message?.errors?.columns[0]
    this.cellCheckTableData = input.inputConfig?.error_message?.errors?.data
    this.cellCheckErrors = input.inputConfig?.error_message?.errors?.cell_errors
    this.onCloseCellErrorCheck = () => {
      this.showEditCellPage = false
      this.resetFile(event, input)
    }
  }

  showOutputPreview(event: any) {
    this.showOuputTable = true
  }

  tableClose() {
    this.showEditCellPage = false
    this.showOuputTable = false;
  }

  isFormValid(): boolean {
    const currentForm = this.sectionForms[this.activeSection?.name]
    const currentInputs = currentForm?.inputsInoder

    // check if form is complete
    const lastInput = currentInputs.slice(-1)[0]
    if(!lastInput?.sectionComplete) return false

    // inputs validation
    const isValid = (input: Input): boolean => {
      if (!input?.required) return true;
      if(input?.errors?.length) return false
      if(!input?.value) return false
      if(input?.validated === false || input?.validated === undefined) return false
      return true
    }
    const invalidField = currentInputs.find(input => !isValid(input))
    // console.log("status", !invalidField);
    return !invalidField
  }


  async runPipeline() {
    this.route.queryParams.subscribe(async params => {
      const pipelineId = +params['pipelineId']
      const processId = +params['processId']
      const formDataArray = [];
      for (const sectionName in this.sectionForms) {
        if (sectionName !== 'run_Pipeline') {
          formDataArray.push(...this.sectionForms[sectionName].getValue());
        }
      }

      let obj: any = {
      }

      formDataArray.forEach(x => {
        obj[x.name] = { "value": x.value };
        // obj[x.name] = { "value": x.value?.hasOwnProperty("raw") ? x?.value : {"raw": x?.value }};
      });

      console.log(obj)
      let data = await this.pipelineService.runTrigger(pipelineId, processId, true, obj).toPromise()
      console.log(data)
      this.showPipelineRun = false
      this.processInProgress = true
      // this.pipelineRunStatus = data.status
      // this.checkStatus(pipelineId, processId)
    });

  }

}


import {Component, Inject, OnInit} from '@angular/core';
import {Context} from "../../services/config/context.service";
import {FormControl, FormGroup} from "@angular/forms";
import {AuthService} from "../../services/api/auth.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  loginError: string = ''
  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  })

  constructor(@Inject(MAT_DIALOG_DATA) public dialogParams:any, public dialogRef: MatDialogRef<LoginFormComponent>, public context: Context,private authService: AuthService) {
    this.loginForm.get('username')?.patchValue(context.principle.user?.apiKey)    
    if(context.principle.user?.apiKey){
      this.loginForm.get('password')?.patchValue(this.loginForm.get('password')?.value)
    }
  }

  ngOnInit(): void {
  }

  showPassword = false;

  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  login() {
    let request = this.authService.login({...this.loginForm.value}).toPromise()
    request.then(loginResponse => {
      this.context.setCurrentUser({...loginResponse.payload})
      const access_token = loginResponse.payload.token.access_token
      const refresh_token = loginResponse.payload.token.refresh_token
      this.authService.saveToken(access_token)
      this.authService.saveRefreshToken(refresh_token)

      this.context.setCurrentUser({...loginResponse.payload, token: undefined})

      this.loginForm.reset()
      this.close()
      // window.location.href = window.location.href
      this.authService.saveAccountId(this.authService.getAccountId() || '')
    }).catch(error => {
      this.loginError = error?.error.errorMessage || error.statusText
      this.loginForm.get('username')?.setErrors({invalid: true})
      this.loginForm.get('password')?.setErrors({invalid: true})
    })
  }
  close() {
    this.dialogRef.close({})
  }
}

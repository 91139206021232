import { Injectable, Pipe } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Context } from '../../config/context.service';
import { ServiceResponse } from '../../core/data.service';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';


export type PipelineResponse = { totalCount: number, pageCount: number, data: Array<Pipeline>, currentPage: number }
export type PipelineVersionsResponse = { totalCount: number, pageCount: number, data: Array<PipelineVersion>, currentPage: number }
export type PipelineHistoryResponse = { totalCount: number, PageCount: number, data: Array<PipelineHistory>, currentPage: number }
export interface Group {
  id: number;
  accountId: number;
  name: string;
  label: string;
  description: string;
  icon: string;
  createdBy: string;
  createdTime: number;
  updatedTime: number;
}

export interface Directory {
  id: number;
  accountId: number;
  groupId: number;
  parentDirId: number | null;
  name: string;
  label: string;
  description: string;
  createdBy: string;
  createdTime: number;
  updatedTime: number;
}

export interface Pipeline {
  id: number;
  accountId: number;
  group: Group;
  directory: Directory;
  name: string;
  label: string;
  description: string;
  status: string;
  triggerName: string;
  buildType: string;
  tags: Array<string>;
  attributes: any;
  createdBy: string;
  createdTime: number;
  updatedTime: number;
  process?: any;
}

interface ConfigNode {
  name: string;
  next: Record<string, boolean>;
  label: string;
  connector: string;
  run_after: Record<string, boolean>;
  description: string;
  input_fields: Record<string, { value: { raw: any } }>;
  service_name: string;
  connection_id: number;
}

interface ConfigEdge {
  src: string;
  dest: string;
}

interface Config {
  edges: Record<string, ConfigEdge>;
  nodes: Record<string, ConfigNode>;
  node_name_map: Record<string, string>;
}

interface PipelineVersion {
  id: number;
  accountId: number;
  pipelineId: number;
  versionNum: number;
  status: string;
  comment: string;
  config: Config;
  createdBy: string;
  createdTime: number;
  updatedTime: number;
}
interface PipelineHistory {
  id: number;
  accountId: number;
  pipelineId: number;
  pipelineVersionId: number;
  status: string;
  errorMessage: string;
  createdTime: number;
  updatedTime: number;
}

type InitPipelineResponse = ServiceResponse<{
  "accountId": number,
  "pipelineId": number,
  "version": {
    "id": number,
    "accountId": number,
    "pipelineId": number,
    "versionNum": number,
    "status": string,
    "comment": string,
    "config": {
      "edges": { [key: string]: any },
      "nodes": {
        [key: string]: {
          "name": string,
          "next": {},
          "label": string,
          "trigger": false,
          "connector": string,
          "run_after": { [key: string]: boolean },
          "description": string,
          "input_fields": {
            [key: string]: any
          },
          "service_name": string,
          "connection_id": number
        }
      },
      "node_name_map": {
        "sql_fetch": string
      }
    },
    "createdBy": string,
  },
  "process": {
    "id": number,
    "status": string
  }

}>

@Injectable({
  providedIn: 'root'
})
export class Pipelinev2Service {
  // allPipelines = new BehaviorSubject<Array<Pipeline>>([]);
  // filter = new BehaviorSubject<{groupId:any,directoryId:any}>({groupId:-1,directoryId:-1})
  // filteredPipelines = this.allPipelines.pipe(combineLatest(this.filter, map(data => this.filterPipeline(data)))).pipe(map(data => this.filterPipeline(data)))

  constructor(private http: HttpClient, private context: Context) {


  }
  filterPipeline(groupId = -1, directoryId: number = -1) {
    return this.http.get(this.context.getApiConfig().PIPELINE_APIS_V2.GET.pipelines(), { params: { groupId, directoryId } });
  }

  getPipelines({ params }: { params: { [key: string]: string | number | undefined, pageNum?: number, itemsPerPage?: number, search?: string, groupId?: string, directoryId?: string } }) {
    return this.http.get<ServiceResponse<PipelineResponse>>(this.context.getApiConfig().PIPELINE_APIS_V2.GET.pipelines(), { params: JSON.parse(JSON.stringify(params)) });
  }
  // getList({ params }: { params: { [key: string]: string | number | undefined, pageNum?: number, itemsPerPage?: number, search?: string } }) {
  //   return this.http.get<ServiceResponse<PipelineResponse>>(this.url, { params: JSON.parse(JSON.stringify(params)) });
  // }

  getPipelineById(pipelineId: number | string) {
    return this.http.get<ServiceResponse<Pipeline>>(this.context.getApiConfig().PIPELINE_APIS_V2.GET.pipeline(pipelineId.toString()));
  }

  getPipelineVersions(pipelineId: number | string, params: { [key: string]: string | number | undefined, pageNum?: number, itemsPerPage?: number, search?: string } = {}) {
    return this.http.get<ServiceResponse<PipelineVersionsResponse>>(this.context.getApiConfig().PIPELINE_APIS_V2.GET.pipelineVersions(pipelineId.toString()), { params: JSON.parse(JSON.stringify(params)) });
  }
  // getPipelineVersions(pipelineId: number | string) {
  //   return this.http.get<ServiceResponse<PipelineVersionsResponse>>(this.context.getApiConfig().PIPELINE_APIS_V2.GET.pipelineVersions(pipelineId.toString()));
  // }  
  getPipelineProcesses(pipelineId: number | string, params: { [key: string]: string | number | undefined, pageNum?: number, itemsPerPage?: number, search?: string } = {}) {
    return this.http.get<ServiceResponse<PipelineHistoryResponse>>(this.context.getApiConfig().PIPELINE_APIS_V2.GET.pipelineProcesses(pipelineId.toString()), { params: JSON.parse(JSON.stringify(params)) });
  }
  // getPipelineProcesses(pipelineId:number|string){
  //   return this.http.get<any>(this.context.getApiConfig().PIPELINE_APIS_V2.GET.pipelineProcesses(pipelineId.toString()));
  // }

  createPipeline(payload: any) { // FIXME:
    return this.http.post<ServiceResponse<any>>(this.context.getApiConfig().PIPELINE_APIS_V2.POST.createPipeline(), payload);
  }

  editInitPipeline(pipeline_id: number) {
    return this.http.post<InitPipelineResponse>(this.context.getApiConfig().PIPELINE_APIS_V2.POST.editInitPipelint(pipeline_id), {});
  }

  updatePipelineDetails(pipelineId: string, data: any) {
    let objj = data.attributes
    let attrObj: any = {}
    for (let obj of objj) {
      let temp: any = Object.values(obj)
      attrObj[temp[0]] = temp[1]
    }
    let mappedData = {
      label: data.name,
      description: data.description,
      tags: data.tags,
      attributes: attrObj
    }
    return this.http.put(this.context.getApiConfig().PIPELINE_APIS_V2.PUT.pipeline(pipelineId), mappedData)
  }

  triggerPipeline(pipelineId: number) {
    return this.http.post(this.context.getApiConfig().PIPELINE_APIS_V2.POST.trigger(pipelineId), {});
  }

  process(pipelineId: number, processId: number) {
    return this.http.get(this.context.getApiConfig().PIPELINE_APIS_V2.GET.process(pipelineId, processId));
  }

  nodeOutput(pipelineId: number, processId: number) {
    return this.http.get(this.context.getApiConfig().PIPELINE_APIS_V2.GET.nodeOutput(pipelineId, processId));
  }

  manualTriggerInputRemap(pipelineId: number,
    processId: number, pipelineVersionId: number, inputFields: any, activeSection: { description: string, name: string, label: string, order: number, input_fields: any }) {

    const req = {
      processId,
      inputFields
    };

    return this.http.post(this.context.getApiConfig().PIPELINE_APIS_V2.POST.manualtriggerInputRemap(pipelineId, pipelineVersionId), req).pipe(
      map((data: any) => {
        const inputArray: any = [];
        console.log(data)

      // let sections = data.payload?.result?.outputFields?.response?.value?.services?.sections;
        let sections = data.payload?.response?.value?.services?.sections;
        console.log(sections)
      
        return sections;
      })
    );
  }
  

  runTrigger(pipelineId: number, processId: number, isTriggerResume: boolean, inputFields: any) {

    const req = {
      "processId": processId,
      "isTriggerResume": isTriggerResume,
      "triggerData": inputFields
    };

    return this.http.post(this.context.getApiConfig().PIPELINE_APIS_V2.POST.trigger(pipelineId), req).pipe(
      map((data: any) => {
        console.log(data)
        const inputArray: any = [];

        // let sections = data.payload?.result?.outputFields?.response?.value?.services?.sections; // Assuming this is how you access sections

        // for (const section of sections) {
        //   for (const key in section.input_fields) {
        //     if (section.input_fields.hasOwnProperty(key)) {
        //       inputArray.push({ ...section.input_fields[key] });
        //     }
        //   }
        // }

        return data;
      })
    );
  }

  getDownloadPublicUrl(url: string, fileName: string) {
    let queryParams = new HttpParams()
      .append("url", url)
      .append("fileName", fileName)
    return this.http.get(this.context.getApiConfig().DATA_API_V2.GET.public, { params: queryParams }).pipe(
      map((data: any) => {
        return data.payload;
      }),
    );
  }

  uploadFile(pipeline_id: string | null, pipelineVersionId: number, process_id: string | null, files: Array<{ fileName: string, data: string }|null>) {

    const req = {
      "processId": process_id,
      "files": files
    }
    return this.http.post(this.context.getApiConfig().DATA_API_V2.POST.upload(pipeline_id, pipelineVersionId), req).pipe(
      map((data: any) => {
        return data
      })
    );
  }

  //Dashboard API's
  getPipelineStats(){
    return this.http.get(this.context.getApiConfig().DASHBOARD_APIS_V2.GET.pipelineStats);  
  }

  getRecentProcesses(){
    return this.http.get(this.context.getApiConfig().DASHBOARD_APIS_V2.GET.recentProcesses);  
  }

  processesRunData({ params }: { params: { [key: string]: string | number | undefined, pageNum?: number, itemsPerPage?: number, search?: string, groupId?: string, directoryId?: string } }){
    return this.http.get(this.context.getApiConfig().DASHBOARD_APIS_V2.GET.processesRunData, { params: JSON.parse(JSON.stringify(params)) });
  }


}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {TaskConfig} from '../../models/tasknode.model';
import {Context} from "../config/context.service";
import { ServiceResponse } from '../core/data.service';


type CreateDynamicFieldPayload = {
  "nodeInstanceId": string,
  "sourceFieldName": string,
  "variableRef": {},
  "variable": {
      "name": string,
      "label": string,
      "description": string,
      "required": boolean,
      "dataType": string,
      "formatType": string,
      "options": Array<unknown>,
      "default": unknown,
      "value": unknown
  }
}

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private http: HttpClient,
              private context: Context,) {
    this.url = context.getApiConfig().PIPELINE_APIS.POST.taskNode
    this.runtasknode = context.getApiConfig().PIPELINE_APIS.POST.runTaskNode
    this.stopTaskNode = context.getApiConfig().PIPELINE_APIS.POST.stopNode
    this.deletenode = context.getApiConfig().PIPELINE_APIS.DELETE.deleteTaskNode
  }

  url = '';
  runtasknode = '';
  deletenode = '';
  stopTaskNode='';

  gettasknode(id: any) {
    return this.http.get<any>(this.context.getApiConfig().PIPELINE_APIS.GET.taskNode + '?id=' + id);
  }

  createTaskNode(task: any) {
    return this.http.post(this.url, task);
  }

  updateTaskNode(id: any, data: any) {
    return this.http.put(`${this.url}?taskId=${id}`, data);
  }

  runNode(pipelineId:any,processId:any,taskNodeId:any, is_retry:boolean){
    return this.http.post<ServiceResponse<any>>(this.runtasknode, {
      "pipeline_id": pipelineId,
      "process_id": processId,
      "task_node_id": taskNodeId,
      "is_retry":is_retry
    });
  }

  stopNode(pipelineId:any,processId:any,taskId:any)
  {
    return this.http.post<ServiceResponse<any>>(this.stopTaskNode, {
      "pipelineId" : pipelineId,
      "processId" :processId,
      "taskId":taskId
    });
  }

  deleteNode(taskId: number) {
    //http://127.0.0.1:8000/pipeline/v1/tasknode?taskId=194' \
    return this.http.delete(`${this.deletenode}?taskId=${taskId}`);
  }

  getTaskPreview(body:unknown , query: {apiId: number, taskId: number, index: number}) {
    return this.http.post<ServiceResponse<any>>(this.context.getApiConfig().PIPELINE_APIS.POST.sinkPreview, body, {params: query});
  }

  addDynamicField(pipelineId: number, nodeInstanceId: number, data: CreateDynamicFieldPayload) {
    return this.http.post<ServiceResponse<{id: string,inputFieldRef: string,pipelineId: string,sourceFieldName: string,startNodeInstanceId: string, type: string}>>(this.context.getApiConfig().PIPELINE_APIS_V2.POST.addDynamicField(pipelineId, nodeInstanceId), data);
  }
  removeDynamicField(pipelineId: number, nodeInstanceId: number, fieldId: number) {
    return this.http.delete<ServiceResponse<any>>(this.context.getApiConfig().PIPELINE_APIS_V2.DELETE.removeDynamicField(pipelineId, nodeInstanceId, fieldId));
  }
  runNodeInstance(pipelineId: number, nodeInstanceId: number|string) {
    return this.http.post<ServiceResponse<any>>(this.context.getApiConfig().PIPELINE_APIS_V2.POST.runNodeInstance(pipelineId, nodeInstanceId), {});
  }
}


/*
  this.http.get<any>(`${this.url}?id=`+id).subscribe(
      response =>{
        console.log(response);
        this.data = response.payload;
      }
    );
*/

import { Node } from 'src/app/models/tasknode.model';
import {
  PipelineHistoryComponent,
  PopoverUI,
} from './pipeline-history.component';

export class Grid<T> {
  public gridData: Map<number, Map<number, T>> = new Map();
  constructor() {}

  public addItem(row: number, col: number, item: T) {
    if (!this.gridData.has(row)) this.gridData.set(row, new Map());
    this.gridData.get(row)?.set(col, item);
  }

  public getItem(row: number, col: number) {
    return this.gridData.get(row)?.get(col);
  }

  public has(row: number, col: number) {
    return !!this.gridData.get(row)?.get(col);
  }

  public replaceGrid(grid: Grid<T>) {
    this.gridData = grid.gridData;
  }
}

export interface GridItem {
  type: string;
  props: {
    onClick?: string;
    [key: string]: any;
  };
}

export const buildGraph = (nodes: Array<Node>) => {
  let roots: Array<string> = [];
  let visited: any = {};
  let nodesMap = nodes.reduce((prev, curr) => {
    prev[curr.id] = curr
    return prev
  }, {} as {[id: string]: Node})

  // console.log(roots)

  const dfs = (nodeId: string, v: any) => {
    let node = nodesMap[nodeId];
    v[nodeId] = true;


    // FIXME: fetch parents from v2 api response type
    // let visit = node.incoming_task_with_weights.filter((x) => !v[`${x.taskId}`] && !visited[`${x.taskId}`]);
    const visit: Array<Node> = []

    // if (visit.length) {
    //   for (let id of visit) {
    //     dfs(`${id.taskId}`, v);
    //   }
    // }


    roots.push(nodeId);
    visited[nodeId] = true;
  };

  for (let nodeId of Object.keys(nodes)) {
    let v: any = {};
    if (!visited[nodeId]) {
      dfs(nodeId, v);
    }
  }


  // let ls: Array<string> = []
  // for(let nodeId of Object.keys(nodes)) {
  //   let node = nodes[nodeId]
  //   if(node.incoming_task_with_weights.length === 0) {
  //     ls.push(nodeId)
  //   }
  // }
  // for (let nodeId of ls) {
  //   let v: any = {};
  //   if (!visited[nodeId]) {
  //     dfs(nodeId, v);
  //   }
  // }

  // console.log(Object.keys(nodes).length);
  // console.log(roots);
  return roots;
};

export const addEdge = (
  nodes: { [nodeId: string]: { x: number; y: number } },
  sequence: Array<[string, string]>,
  edges: Grid<Array<{ x: number; y: number; id?: string }>>
) => {
  for (let pair of sequence) {
    let node1 = nodes[pair[0]];
    let node2 = nodes[pair[1]];

    let parent = node1;
    let chile = node2;

    if (!node1 || !node2) continue;

    if (chile.y < parent.y) {
      parent = node2;
      chile = node1;
    }

    if (!edges.has(chile.y, chile.x)) {
      edges.addItem(chile.y, chile.x, []);
    }

    edges
      .getItem(chile.y, chile.x)
      ?.push({ y: parent.y, x: parent.x, id: `${pair[0]}-${pair[1]}` });
  }
};

export const buildEdges = (
  nodes: {
    [id: string]: {
      id: number;
      name: string;
      incoming_task_with_weights: Array<{ taskId: number; type: string }>;
    };
  },
  sequence: Array<string>,
  nodesMap: { [nodeId: string]: { x: number; y: number } }
) => {
  let edges = new Grid<Array<{ x: number; y: number }>>();

  let visited: any = {};
  let _edges = [];

  for (let nodeId of sequence) {
    if (visited[nodeId]) continue;
    visited[nodeId] = true;

    let node = nodes[nodeId];
    let parentNodes = node.incoming_task_with_weights;

    for (let parentId of parentNodes) {
      let edge: [string, string] = [`${parentId.taskId}`, nodeId];
      _edges.push(edge);
    }
  }

  addEdge(nodesMap, _edges, edges);
  // console.log(edges)

  // edges.addItem(3, 2, {y: 0, x: 5})
  // edges.addItem(4, 8, {y: 0, x: 5})

  return edges;
};

const getDefaultPopover = (
  self: PipelineHistoryComponent,
  element: { row: number; col: number; gridItem: GridItem }
) => {
  if (element?.gridItem?.props?.onClick !== 'handle-tree-node-click')
    return undefined;

  let popoverUI: PopoverUI = {
    items: [
      element.gridItem.props.nodeId
        ? {
          type: 'kayvalue',
          key: 'Id',
          value: element.gridItem.props.nodeId,
        }
        : {},
      {
        type: 'kayvalue',
        key: 'Name',
        value: element.gridItem.props.name,
      },
      {
        type: 'kayvalue',
        key: 'Status',
        value: element.gridItem.props.status,
        class:
          element.gridItem.props.status === 'failed'
            ? 'pipe-node status-failed'
            : '',
      },
      element.gridItem.props.scheduledTime
        ? {
          type: 'kayvalue',
          key: 'scheduledTime',
          value: element.gridItem.props.scheduledTime,
        }
        : {},
      element.gridItem.props.executionTime
        ? {
          type: 'kayvalue',
          key: 'executionTime',
          value: element.gridItem.props.executionTime,
        }
        : {},
      {
        type: 'action-bar',
        actions: [
          // {
          //   onClick: 'run-node-v1',
          //   icon: 'flash-outline',
          //   title: 'Run node',
          // },
          // {
          //   onClick: 'run-node-v2',
          //   icon: 'sync-outline',
          //   title: 'Run node',
          // },
          // {
          //   onClick: 'run-node-v3',
          //   icon: 'activity-outline',
          //   title: 'Run node',
          // },
        ],
      },
      {
        type: 'action',
        onClick: 'run-selected',
        text: 'Run',
        title: 'Run only selected node',
        // icon: 'activity-outline',
      },
      {
        type: 'action',
        onClick: 'run-all',
        text: 'Run all',
        title: 'Run all nodes',
      },
      self.taskNodes[element.gridItem.props.nodeId]
        ? {
          type: 'kayvalue',
          key: 'Parent',
          value: self.taskNodes[
            element.gridItem.props.nodeId
          ].incoming_task_with_weights.map((x: any) => x.taskId),
          onClick: 'goto-parent',
        }
        : {},
      self.taskNodes[element.gridItem.props.nodeId]
        ? {
          type: 'kayvalue',
          key: 'Child',
          value: self.taskNodes[
            element.gridItem.props.nodeId
          ].outgoing_task_with_weights.map((x: any) => x.taskId),
          onClick: 'goto-parent',
        }
        : {},
    ],
  };
  return popoverUI;
};

const getStatusNodePopover = (
  self: PipelineHistoryComponent,
  element: { row: number; col: number; gridItem: GridItem }
) => {
  if (element?.gridItem?.props?.onClick !== 'handle-status-node-click')
    return undefined;

  let popoverUI: PopoverUI = {
    items: [
      {
        type: 'kayvalue',
        key: element.gridItem.type === 'status-circle' ? 'Pocess Id' : 'Name',
        value: element.gridItem.type === 'status-circle' ? element.gridItem.props.processId: element.gridItem.props.name,
      },
      {
        type: 'kayvalue',
        key: 'Status',
        value: element.gridItem.props.status,
        class:
          element.gridItem.props.status === 'failed'
            ? 'pipe-node status-failed'
            : '',
      },
      element.gridItem.props.scheduledTime
        ? {
          type: 'kayvalue',
          key: 'scheduledTime',
          value: element.gridItem.props.scheduledTime,
        }
        : {},
      element.gridItem.props.executionTime
        ? {
          type: 'kayvalue',
          key: 'executionTime',
          value: element.gridItem.props.executionTime,
        }
        : {},
      {
        type: 'action-bar',
        actions: [
          {
            onClick: `run-${element.gridItem.type === 'status-circle' ? 'process' : 'task'}`,
            icon: 'flash-outline',
            title: `Run ${element.gridItem.type === 'status-circle' ? 'process' : 'task'}`,
            args: [{processId: element.gridItem.props.processId, nodeId: element.gridItem.props.nodeId}]
          },
          element.gridItem.props.status === 'in_progress' ? {
            onClick: `stop-${element.gridItem.type === 'status-circle' ? 'process' : 'task'}`,
            icon: 'flash-off-outline',
            title: `Stop ${element.gridItem.type === 'status-circle' ? 'process' : 'task'}`,
            args: [{processId: element.gridItem.props.processId, nodeId: element.gridItem.props.nodeId}]
          }: {},
          // {
          //   onClick: 'run-node-v3',
          //   icon: 'activity-outline',
          //   title: 'Run node',
          // },
          element.gridItem.type === 'status-circle' ? {} : {
            onClick: `preview-log`,
            icon: 'clipboard-outline',
            title: `Preview log`,
            class: 'shift-right',
            args: [{processId: element.gridItem.props.processId, nodeId: element.gridItem.props.nodeId}]
          }
        ],
      },
      // {
      //   type: 'action',
      //   onClick: 'run-selected',
      //   text: 'Run',
      //   title: 'Run only selected node',
      //   // icon: 'activity-outline',
      // },
      element.gridItem.type === 'status-circle'
        ? {
          type: 'action',
          onClick: 'run-process',
          text: 'Run Process',
          title: 'Run selected process',
          args: [{processId: element.gridItem.props.processId}]
          // icon: 'activity-outline',
        }
        : {},
      element.gridItem.type === 'status-circle'
        ? {
          type: 'action',
          onClick: 'run-nodes-in-sequence',
          text: 'Run node in sequence',
          title: 'Select run sequence',
          args: [{processId: element.gridItem.props.processId, nodeId: element.gridItem.props.nodeId}]
          // icon: 'activity-outline',
        }
        : {},
      {
        type: 'action',
        onClick: 'run-process-node',
        text: 'Run selected node',
        title: 'Run selected node from process process',
        args: [{processId: element.gridItem.props.processId, nodeId: element.gridItem.props.nodeId}]
        // icon: 'activity-outline',
      },
      element.gridItem.type === 'status-circle' ? {
        type: 'action',
        onClick: `set-process-as-${element.gridItem.props.status==='success'? 'failed' : 'success'}`,
        text: `Mark process as ${element.gridItem.props.status==='success'? 'failed' : 'success'}`,
        title: `Mark process as ${element.gridItem.props.status==='success'? 'failed' : 'success'}`,
        icon: element.gridItem.props.status==='success'? 'alert-triangle-outline' : 'checkmark-outline',
      }: {
        type: 'action',
        onClick: `set-node-as-${element.gridItem.props.status==='success'? 'failed' : 'success'}`,
        text: `Mark node as ${element.gridItem.props.status==='success'? 'failed' : 'success'}`,
        title: `Mark node as ${element.gridItem.props.status==='success'? 'failed' : 'success'}`,
        icon: element.gridItem.props.status==='success'? 'alert-triangle-outline' : 'checkmark-outline',
        args: [{processId: element.gridItem.props.processId, nodeId: element.gridItem.props.nodeId}]
      },
      element.gridItem.type === 'status-circle' ? {} : {
        type: 'action-bar',
        actions: [
          {
            onClick: `run-horizotal`,
            icon: 'log-out-outline',
            title: `Run node in horizontal`,
            args: [{processId: element.gridItem.props.processId, nodeId: element.gridItem.props.nodeId}]
            // class: 'shift-right'
          }
        ]
      }
    ],
  };
  return popoverUI;
};

const getPopovers: Array<
  (
    self: PipelineHistoryComponent,
    element: { row: number; col: number; gridItem: GridItem }
  ) => any
> = [getStatusNodePopover, getDefaultPopover];

export const getPopoverUI = (
  self: PipelineHistoryComponent,
  element: { row: number; col: number; gridItem: GridItem }
) => {
  for (let h of getPopovers) {
    let result = h(self, element);
    if (result) return result;
  }
  return undefined;
};

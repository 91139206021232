import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, interval} from "rxjs";
import {Context} from "../config/context.service";
import {HttpClient} from "@angular/common/http";
import {ServiceResponse} from "../core/data.service";
import {Subscription} from "rxjs/internal/Subscription";


export type NodeStatus = {
  error_message: string | null
  id: number
  status: 'success' | 'failed'
  task_history_id: number
  task_node_id: number
}
type PipelineStatus = {
  id: number,
  run_type: 'sequential_run'|'static',
  status: 'in_progress'|'success'|'failed'|null
}
export type NodeStatusResponse = ServiceResponse<{
  process:PipelineStatus
  tasks:Array<NodeStatus>
}>


@Injectable({providedIn: 'root'})
export class NodeStatusService {

  private readonly _nodeStatusSource = new BehaviorSubject<Array<NodeStatus>>([])
  private readonly _pipelineStatusSource = new BehaviorSubject<PipelineStatus>({
    id: -1,
    run_type: 'static',
    status: null
  })

  private config = {
    params: {},
    delay: 15*60*1000
  }

  private sub: Subscription | null = null


  readonly nodeStatus$ = this._nodeStatusSource
  readonly pipelineStatus$=this._pipelineStatusSource
  constructor(private context: Context, private http: HttpClient) {}


  connect(params: {pipelineId:number, processId: number, taskNodeId?: number}, delay: number) {
    Object.assign(this.config.params, params)
    this.config.delay = delay
    this.run()
  }

  private run() {
    this.stop()
    this.fetch()
    this.sub = interval(this.config.delay).subscribe(x => {
      this.fetch()
    })
  }

  private fetch() {
    this.http.get<NodeStatusResponse>(this.context.getApiConfig().PIPELINE_APIS.GET.nodeStatus, {params: this.config.params}).toPromise().then(res => {
      if(!res) return;
      if(res.status === 'failure') {
        this._nodeStatusSource.next([])
        this._pipelineStatusSource.next({} as any)
      } else {
        this._nodeStatusSource.next(res.payload.tasks||res.payload)
        this._pipelineStatusSource.next(res.payload.process||{})

      }
    })
  }


  public stop() {
    if(this.sub !== null) {
      this.sub.unsubscribe()
      this.sub = null
    }
  }

}


import { TemplatePortal } from '@angular/cdk/portal';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/api/auth.service';
import { Accountv2Service } from 'src/app/services/api/v2/accountv2.service';
import { SideNavService } from 'src/app/services/core/sidenav.service';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {
  totalCount=0
  pageIndex=0
  itemsPerPage=10
  pageSizeOptions=[10,20,25,30]
  accountId
  accountDetails:{icon:string,label:string,description:string}={
    icon:"",
    label:"",
    description:""
  }
  accountSideBar:{icon:string,label:string,pipeline:string,jobs:string,user:string,description:string,createdOn:string,createdBy:string}={
    icon:"",
    label:"",
    pipeline:"",
    jobs:"",
    user:"",
    description:"",
    createdOn:"",
    createdBy:""
  }
  
  clientsList:Array<any> = []
  @ViewChild('portalContent', { static: true })
  portalContent: any;
  constructor(private viewContainerRef: ViewContainerRef, public portalBridge: SideNavService,private router:Router,private route:ActivatedRoute,private accountService:Accountv2Service,private authService: AuthService) {
    this.accountId = this.route.snapshot.params.accountId;
  }

  ngOnInit(): void {
    this.accountService.getAccountDetails(this.accountId).subscribe((accountRes:any)=>{
      this.accountDetails.label = accountRes.payload.displayName;
      this.accountDetails.description = accountRes.payload.description
      this.accountDetails = {...this.accountDetails}
    })

    this.loadAccounts(this.itemsPerPage,this.pageIndex)
  }

  loadAccounts(itemsPerPage:number,pageIndex:number){
    this.accountService.getAccounts({ params: { parentId: this.accountId, itemsPerPage,pageNum:pageIndex +1 } }).subscribe((accounts: any) => {
      this.totalCount = accounts.payload.totalCount;
      this.pageIndex = accounts.payload.currentPage - 1;
      this.clientsList = accounts.payload.data.map((acc: any) => {
        return {
          id: acc.id,
          // icon: this.tempIcon,
          label: acc.displayName,
          description: acc.description,
          status: acc.status
        }
      })
    })
  }

  onPaginationEvent(e:any) {
    // console.log(e.detail)
    this.loadAccounts(e.detail.itemsPerPage,e.detail.index)
  }

  openDetails(event:any) {
    this.accountService.getAccountDetails(event.detail).subscribe((data:any)=>{  
      
      console.log(data.payload.displayName)
      this.accountSideBar.icon="https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
      this.accountSideBar.label=data.payload.displayName;
      this.accountSideBar.pipeline = '-';
      this.accountSideBar.jobs = '-';
      this.accountSideBar.user = '-';
      this.accountSideBar.description = data.payload.description;
      this.accountSideBar.createdBy='-',
      this.accountSideBar.createdOn=this.formatTimestamp(data.payload.createdTime);

    this.portalBridge.show = true;
    const portal = new TemplatePortal(this.portalContent, this.viewContainerRef)
    this.portalBridge.setRightNavTemplate(portal)
  })
  }
  formatTimestamp(timestamp:any) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(timestamp* 1000);
    const day = date.getDate();
    const month = date.toLocaleDateString(undefined, { month: 'long' });
    const year = date.getFullYear();
    let daySuffix = 'th';
    if (day >= 1 && day <= 3) {
      daySuffix = ['st', 'nd', 'rd'][day - 1];
    }
    return `${day}${daySuffix} ${month} ${year}`;
  }

  customEventsHandler(event:any){
    if(event.detail?.back) this.router.navigate(["accounts"])
  }

  switchAccount(event:any){
    this.authService.saveAccountId(event.detail)
    this.router.navigateByUrl('/pipelines(view:pipeline-list)')
  }

  ngOnDestroy() {
    this.portalBridge.removeRightNavTemplate()
    this.portalBridge.show = false;
  }

}

<div style="width: 100%; display: flex">
  <a href="#" style="text-decoration: none">
    <h4>Simplify-Bridge</h4>
  </a>

  <div style="margin-left: auto;">
    <nb-actions>
      <nb-action icon="bell-outline"></nb-action>
      <nb-action icon="keypad-outline"></nb-action>
    </nb-actions>
  </div>
  <button nbButton status="info" [nbContextMenu]="items">
    <nb-icon icon="pantone-outline"></nb-icon>
    Primary
  </button>
</div>




<div class="form_border">
<nb-card>
  <nb-card-header>
    <h3 [hidden]="isUpdate">Create Group</h3>
    <h3 [hidden]="!isUpdate">Edit Group</h3>

    <button nbButton  style="float: right;" (click)="close()"><nb-icon icon="close-outline" ></nb-icon></button>
  </nb-card-header>
  <nb-card-body>
    <form *ngIf="!isUpdate" [formGroup]="group" method="post" (ngSubmit)="createGroup()">
      <div class="form-group">
        <label for="name">Group Name</label>
        <input type="text" [hidden]="isUpdate" class="form-control" formControlName="name" id="name" required>
        <input type="text" [hidden]="!isUpdate"  class="form-control" formControlName="name" id="name">

      </div>
      <br>
      <!-- <div class="form-group">
        <label for="name">Group Email</label>
        <input type="text" [hidden]="isUpdate" class="form-control" formControlName="email" id="email" required>
        <input type="text" [hidden]="!isUpdate" class="form-control" formControlName="email" id="email">

      </div> -->
      <!-- <br> -->
      <div class="form-group">
        <label for="createdBy">Comment</label>
        <input type="text" class="form-control" formControlName="comment" id="comment">
      </div>
      <br>
      <div class="form-group">
        <label for="createdBy">Icon Url</label>
        <input type="text" class="form-control" formControlName="icon" id="icon">
      </div>
      <br>
      <div class="form-submit form-group">
      <button class="btn btn-primary" [hidden]="isUpdate" type="submit" [disabled]="group.invalid">
        Create
      </button>
      <button style="cursor:pointer;" class="btn btn-primary" [hidden]="!isUpdate"  type="submit" [disabled]="group.pristine" >
        Edit
      </button>
      </div>
    </form>

    <form *ngIf="isUpdate" [formGroup]="group"  method="put" (ngSubmit)="editGroupDetails()">
      <div class="form-group">
        <label for="name">Group Name</label>
        <input type="text" class="form-control" formControlName="name" id="name">

      </div>
      <br>
      <!-- <div class="form-group">
        <label for="name">Group Email</label>
        <input type="text"  class="form-control" formControlName="email" id="email" >

      </div> -->
      <!-- <br> -->
      <div class="form-group">
        <label for="createdBy">Comment</label>
        <input type="text" class="form-control" formControlName="comment" id="comment">
      </div>
      <br>
      <div class="form-group">
        <label for="createdBy">Icon Url</label>
        <input type="text" class="form-control" formControlName="icon" id="icon">
      </div>
      <br>
      <div class="form-submit form-group">
      <button class="btn btn-primary" [hidden]="isUpdate" type="submit" [disabled]="group.invalid">
        Create
      </button>
      <button style="cursor:pointer;" class="btn btn-primary" [hidden]="!isUpdate"  type="submit" [disabled]="group.pristine" >
        Edit
      </button>
      </div>
    </form>
  
  </nb-card-body>
</nb-card>
</div>
    
  
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Context } from '../../config/context.service';

@Injectable({
    providedIn: 'root'
  })

export class Accountv2Service {
    constructor(private http:HttpClient,private context:Context){
    }

    getAccounts({ params }: { params: { [key: string]: string | number | undefined, pageNum?: number, itemsPerPage?: number, type?: 'master'|'platform'|'client', parentId?:string} }){
        return this.http.get(this.context.getApiConfig().ACCOUNTS_APIS_V2.GET.accounts, { params: JSON.parse(JSON.stringify(params)) });
    }

    getAccountDetails(accountId:string){
        return this.http.get(this.context.getApiConfig().ACCOUNTS_APIS_V2.GET.account(accountId));
    }

    getUserInfoV2(){
        return this.http.get(this.context.getApiConfig().ACCOUNTS_APIS_V2.GET.userInfoV2);
    }

}
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import * as d3 from 'd3-selection'
import * as d3Shape from 'd3-shape'
import {easeElastic, svg} from 'd3'
import { Subject } from 'rxjs';
import { commands } from 'codemirror';
import {jsonix} from '../../libs/jsonix';
import {Xml2Json, getTreeView, XmlSchemaParser} from '../../libs/xml2json'
import {JsonSchemaParser} from '../../libs/jsonSchema2json'



const xml2 = `<?xml version="1.0"?>
<xs:schema xmlns:xs="http://www.w3.org/2001/XMLSchema">

<xs:complexType name="userType">
    <xs:sequence>
      <xs:element name="email" use="required" type="xs:string"/>
      <xs:element name="fname" type="xs:string"/>
      <xs:element name="lname" type="xs:string"/>
    </xs:sequence>
</xs:complexType>

<xs:element name="Email">
  <xs:complexType>
    <xs:sequence>
      <xs:element name="to" type="userType" use="required"/>
      <xs:element name="from" type="userType" use="required"/>
      <xs:element name="heading" type="xs:string"/>
      <xs:element name="body" type="xs:string"/>
    </xs:sequence>
  </xs:complexType>
</xs:element>

</xs:schema>`.trim()

const xml = `<?xml version="1.0" encoding="utf-8"?>
<!-- Created with Liquid Technologies Online Tools 1.0 (https://www.liquid-technologies.com) -->
<xs:schema attributeFormDefault="unqualified" elementFormDefault="qualified"
    xmlns:xs="http://www.w3.org/2001/XMLSchema">
    <xs:element name="Root">
        <xs:complexType>
            <xs:sequence>
                <xs:element maxOccurs="unbounded" name="Sender">
                    <xs:complexType>
                        <xs:all>
                            <xs:element maxOccurs="unbounded" name="id" type="xs:unsignedByte" />
                            <xs:element name="name">
                                <xs:complexType>
                                    <xs:sequence>
                                        <xs:element name="fname" type="xs:string" />
                                        <xs:element name="lname" type="xs:string" />
                                    </xs:sequence>
                                </xs:complexType>
                            </xs:element>
                            <xs:element name="gender" type="xs:string" />
                            <xs:element name="mobile" type="xs:unsignedInt" />
                        </xs:all>
                        <xs:attribute name="type" type="xs:string" use="optional" />
                    </xs:complexType>
                </xs:element>
                <xs:element maxOccurs="unbounded" name="Receiver">
                    <xs:complexType>
                        <xs:sequence>
                            <xs:element name="id" type="xs:unsignedByte" />
                            <xs:element name="name">
                                <xs:complexType>
                                    <xs:sequence>
                                        <xs:element name="fname" type="xs:string" />
                                        <xs:element name="lname" type="xs:string" />
                                    </xs:sequence>
                                </xs:complexType>
                            </xs:element>
                            <xs:element name="gender" type="xs:string" />
                            <xs:element name="dob" type="xs:date" />
                            <xs:element name="time" type="xs:dateTime" />
                            <xs:element name="isActive" type="xs:boolean" />
                        </xs:sequence>
                    </xs:complexType>
                </xs:element>
            </xs:sequence>
        </xs:complexType>
    </xs:element>
</xs:schema>`


const jsonSchema = `{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "additionalProperties": false,
  "type": "object",
  "properties": {
      "from": {
          "$ref": "#/$defs/user"
      },
      "to": {
          "type": "object",
          "properties": {
              "name": {"type": "string"},
              "email": {"type": "string"}
          }
      },
      "ids": {
          "type":"array",
          "items": {
              "type":"integer",
              "description": "Account Id"
          }
      },
      "products": {
          "type":"array",
          "items": [
              {
                  "type":"integer",
                  "description": "Product Id"
              },
              {
                  "type":"string",
                  "description": "Product Name"
              },
              {
                  "type":"object",
                  "properties": {
                      "weight": {
                          "type": "integer",
                          "description": "Weight in kg"
                      },
                      "battery": {
                          "type": "integer",
                          "description": "Battery life"
                      }
                  },
                  "required": [
                      "weight"
                  ]
              }
          ]
      }
  },
  "$defs": {
      "user": {
          "type": "object",
          "properties": {
              "contact": {
                  "type":"string",
                  "pattern": "^(\([0-9]{3}\))?[0-9]{3}-[0-9]{4}$",
                  "maxLength": 10,
                  "minLength": 1,
                  "description": "User contact number"
              },
              "name": {
                  "$ref": "./subSchema.json"
              }
          },
          "required": ["contact"]
      }
  },
  "required": [
      "to"
  ]
}`



export type ItemType = {
  id: string
  text: string
  indent?: number
  description?:string
  use?:string
  type: string
}

export type BulletType = {index: number, itemType: ItemTypes, item: ItemType, isConnected: boolean, edgeCount: number}
export type EdgeType = {source: BulletType, target: BulletType, sourceIndex: number, targetIndex: number}
type DraggerType = {start:{x: number, y: number},end:{x: number, y: number}, source: BulletType|null, isDragging: boolean, sourceIndex: number, targetIndex: number}


export function getDataTypeIconName(dataType: string) {
  if(['string'].includes(dataType)) return 'text_rotation_none'
  if(['boolean'].includes(dataType)) return 'check_box'
  if(['number', 'integer', 'unsignedInt', 'unsignedByte'].includes(dataType)) return 'numbers'
  if(['array', 'complex:sequence'].includes(dataType)) return 'data_array'
  if(['object', 'complex:all'].includes(dataType)) return 'data_object'
  if(['date'].includes(dataType)) return 'event'
  if(['dateTime'].includes(dataType)) return 'schedule'
  return ''
}


enum ItemTypes {
  source = 'source',
  target = 'target'
}

@Component({
  selector: 'app-data-mapper',
  templateUrl: './data-mapper.component.html',
  styleUrls: ['./data-mapper.component.scss']
})
export class DataMapperComponent implements OnInit, OnDestroy, AfterViewInit {
  EdgeCurve = d3Shape.line<{x:number, y:number}>().x((p) => p.x).y((p) => p.y).curve(d3Shape.curveMonotoneX)
  private subject = new Subject<{type: string, args: any[]}>()
  private connected = new Subject<{[id: string]: string[]}>()

  @ViewChild('svgContainer', {static: true}) svgContainer!: ElementRef<SVGElement>
  @Input() config: {sourceItems: Array<ItemType>, targetItems: Array<ItemType>} = {
    sourceItems: [
      // ...['id',
      // 'user',
      // 'org', ].map(x => ({text: x, id: x})),
      // {
      //   text: 'Hello',
      //   indent: 1,
      //   id: 'Hello'
      // },
      // {
      //   text: 'World',
      //   indent: 2,
      //   id: 'World'
      // },
      // ...Array(50).fill(0).map((x, i) => ({text:`${i}`, indent: Math.floor(Math.random()*5), id: `${i}`}))
    ],
    targetItems: [
      // ...['Id',
      // 'username',
      // 'company', ].map(x => ({text: x, id: x})),
      // {
      //   text: 'Bonjour',
      //   indent: 1,
      //   id: 'Bonjour'
      // },
      // ...Array(5).fill(0).map((x, i) => ({text:`${i}`, indent: Math.floor(Math.random()*5), id: `${i}`}))
    ]
  }
  @Input() edgeMap: {[key: string]: string[]} = {
    // id: ['Id'],
    // user: ['company', 'username'],
    // Hello: ['Bonjour']
  }
  
  //TODO: previewData should come from parent component
  @Input()previewData: string = xml

  filters = {
    sourceFilter: '',
    targetFilter: ''
  }

  private state: {
    svg: d3.Selection<SVGElement, unknown, null, undefined>,
    rect: DOMRect,
    svgRect: DOMRect,
    listWidth: number,
    listItemHeight: number
  } = {} as any

  
  fontSize = 15
  scrollBarWidth = 5
  bulletRadius = 7
  connectorsSection = {x: 0, y: 0, transform: {x: 0, y: 0}}
  sourceSection = {x: 0, y: 0, transform: {x: 0, y: 0}}
  targetSection = {x: 0, y: 0, transform: {x: 0, y: 0}}
  scrollbars: Array<{x: number, y: number, brushHeight: number, step: number, stepCount: number, stepHeight: number}> = [
    {x: 0, y: 0, brushHeight: 10, step: 0, stepCount: 20, stepHeight: 15},
    {x: 0, y: 0, brushHeight: 50, step: 0, stepCount: 10, stepHeight: 15},
  ]
  leftConnectorBullets: Array<BulletType> = []
  rightConnectorBullets: Array<BulletType> = []
  edges: Array<EdgeType> = []
  expressionMap: {[targetId: string]: string} = {}
  dragger:DraggerType = {start: {x: 0, y: 0}, end:{x: 100, y: 100}, source: null, isDragging: false, sourceIndex: 0, targetIndex: 0}

  xmlSchemaParser = new XmlSchemaParser()
  jsonSchemaParser = new JsonSchemaParser()
  constructor() { }

  ngOnInit(): void {
    const xmlSchemaStruct = this.xmlSchemaParser.parseString(xml)
    const sourceList = getTreeView(xmlSchemaStruct)
    this.config.sourceItems = sourceList

    const jsonSchemaStruct = this.jsonSchemaParser.parseString(jsonSchema)
    const targetList = getTreeView(jsonSchemaStruct)
    this.config.targetItems = targetList

    console.log(xmlSchemaStruct, sourceList)
    console.log(jsonSchemaStruct, targetList)
  }
  ngAfterViewInit(): void {
    this.initState()
    this.initDom()
    this.initEvents()
    this.renderConnectors()
  }

  ngOnDestroy(): void {
  }


  private initState() {
    const svg = d3.select(this.svgContainer.nativeElement)
    const rect = this.svgContainer.nativeElement.parentElement!.getBoundingClientRect()
    const svgRect = this.svgContainer.nativeElement.getBoundingClientRect()
    const listWidth = (rect.width / 4)
    const listItemHeight = this.fontSize * 2


    this.sourceSection.x = 0
    this.connectorsSection.x = listWidth
    this.targetSection.x = 2 * listWidth



    const mappedSources = new Set<string>(Object.keys(this.edgeMap))
    const mappedTargets = new Set<string>(([] as Array<string>).concat(...Array.from(Object.values(this.edgeMap))))

    const sourceSearchString = this.filters.sourceFilter.toLowerCase()
    const targetSearchString = this.filters.targetFilter.toLowerCase()

    this.leftConnectorBullets = this.config.sourceItems.filter(x => x.text.toLowerCase().includes(sourceSearchString)).map((item, i) => ({index: i, itemType: ItemTypes.source, item: item, isConnected: mappedSources.has(item.id), edgeCount: 0}))
    this.rightConnectorBullets = this.config.targetItems.filter(x => x.text.toLowerCase().includes(targetSearchString)).map((item, i) => ({index: i, itemType: ItemTypes.target, item: item, isConnected: mappedTargets.has(item.id), edgeCount: 0}))


    this.scrollbars[0].x = 0
    this.scrollbars[1].x = listWidth - this.scrollBarWidth

    const leftScroll = getScrollBar(this.leftConnectorBullets.length, listItemHeight, svgRect.height)
    const rightScroll = getScrollBar(this.rightConnectorBullets.length, listItemHeight, svgRect.height)
    rightScroll.x = 2 * listWidth - this.scrollBarWidth
    this.scrollbars = [leftScroll, rightScroll]

    const sourceIdMap = this.leftConnectorBullets.reduce((prev, curr) => {
      prev[curr.item.id] = curr
      return prev
    }, {} as {[id: string]: BulletType})

    const targetIdMap = this.rightConnectorBullets.reduce((prev, curr) => {
      prev[curr.item.id] = curr
      return prev
    }, {} as {[id: string]: BulletType})

    const edges: Array<EdgeType> = []
    Object.entries(this.edgeMap).forEach(([sId, value]) => {
      const source = sourceIdMap[sId];
      if(!source) return;
      value.forEach(tId => {
        const target = targetIdMap[tId];
        if(!target) return;
        edges.push({
          source: source,
          target: target,
          sourceIndex: source.index,
          targetIndex: target.index
        })
      })
    })

    this.edges = edges

    this.state = {
      svg: svg as d3.Selection<SVGElement, unknown, null, undefined>,
      rect: rect as DOMRect,
      svgRect: svgRect as DOMRect,
      listWidth: listWidth as number,
      listItemHeight: this.fontSize * 2
    }
  }

  private initDom() {
    const {svg, listWidth, rect, svgRect} = this.state
    const listItemHeight = this.fontSize * 2
    const bulletRadius = this.bulletRadius
    const component = this

    // source list
    const sources = svg.append('g')
      .classed('source-list', true)
      .attr('transform', `translate(${component.sourceSection.x+component.sourceSection.transform.x}, ${component.sourceSection.y+component.sourceSection.transform.y})`)

    this.renderSource()


    // target list
    const targets = svg.append('g')
      .classed('target-list', true)
      .attr('transform', `translate(${component.targetSection.x+component.targetSection.transform.x}, ${component.targetSection.y+component.targetSection.transform.y})`)

    this.renderTarget()


    // connectors
    const connectors = svg.append('g')
      .classed('connectors', true)
      .attr('transform', `translate(${component.connectorsSection.x+component.connectorsSection.transform.x}, ${component.connectorsSection.y+component.connectorsSection.transform.y})`)

    // Edges
    connectors.append('g')
      .classed('edges', true)

    // Bullets
    connectors.append('g')
      .classed('bullets', true)

      // Left Scroll bar
      const leftScrollBar = svg.append('g')
        .classed('left-scrollbar', true)
        .attr('transform', `translate(${component.sourceSection.x+component.sourceSection.transform.x}, ${component.sourceSection.y+component.sourceSection.transform.y})`);
      leftScrollBar.selectAll('rect').data([component.scrollbars[0]]).enter()
        .append('rect')
        .attr('x', data => data.x)
        .attr('y', data => data.y)
        .attr('fill', 'darkgray')
        .style('display', data => data.stepCount?'block': 'none')
        .attr('width', this.scrollBarWidth)
        .attr('height', data => data.brushHeight)


      // Right Scroll bar
      const rightScrollBar = svg.append('g')
        .classed('right-scrollbar', true)
        .attr('transform', `translate(${component.targetSection.x+component.targetSection.transform.x}, ${component.targetSection.y+component.targetSection.transform.y})`)
      rightScrollBar.selectAll('rect').data([component.scrollbars[1]]).enter()
        .append('rect')
        .attr('x', data => data.x)
        .attr('y', data => data.y)
        .attr('fill', 'darkgray')
        .style('display', data => data.stepCount?'block': 'none')
        .attr('width', this.scrollBarWidth)
        .attr('height', data => data.brushHeight)


      const dragger = svg.append('g');
      dragger.classed('dragger', true);
      component.renderDragger();
  }

  private initEvents() {
    const component = this

    // drag edge event 
    d3.select(this.svgContainer.nativeElement)
      .on('mousemove', function(){
        const event = (d3 as unknown as {event: PointerEvent}).event;
        if(!component.dragger.isDragging)return;
        const svgRect = component.svgContainer.nativeElement.getBoundingClientRect();

        component.dragger.end = {x: event.clientX-svgRect.x-2, y: event.clientY-svgRect.y-2}
        component.renderDragger()
      })
      .on('mousedown', () => {
        if(component.dragger.isDragging) {
          component.dragger.isDragging = false;
          component.renderDragger()
        }
      })



    // Source Scroll event
    d3.select(this.svgContainer.nativeElement).select('.source-list')
      .on('wheel.zoom', function() {
        const event = (d3 as unknown as {event: WheelEvent}).event;
        const direction = event.deltaY > 0 ? 'down' : 'up'
        const directionDiff = direction === 'down' ? 1 : -1;

        const scrollBar = component.scrollbars[0]
        scrollBar.step = (scrollBar.step + directionDiff) >= 0 && (scrollBar.step + directionDiff) <= scrollBar.stepCount ? scrollBar.step + directionDiff : scrollBar.step;

        component.sourceSection.transform.y = -(scrollBar.step * component.state.listItemHeight);
        component.subject.next({type: `scroll-${direction}`, args: ['source-list']})
        component.renderSource()
        component.renderLeftScroll()
        // component.updateBulletOffset()
        component.renderConnectors()
        component.renderDragger()
      })


    // Target Scroll Event
    d3.select(this.svgContainer.nativeElement).select('.target-list')
      .on('wheel.zoom', function(data) {
        const event = (d3 as unknown as {event: WheelEvent}).event;
        const direction = event.deltaY > 0 ? 'down' : 'up'
        const directionDiff = direction === 'down' ? 1 : -1;

        const scrollBar = component.scrollbars[1]
        scrollBar.step = (scrollBar.step + directionDiff) >= 0 && (scrollBar.step + directionDiff) <= scrollBar.stepCount ? scrollBar.step + directionDiff : scrollBar.step;

        component.targetSection.transform.y = -(scrollBar.step * component.state.listItemHeight);
        component.subject.next({type: `scroll-${direction}`, args: ['source-list']})
        component.renderTarget()
        component.renderRightScroll()
        // component.updateBulletOffset()
        component.renderConnectors()
        component.renderDragger()
      })
  }

  renderSource() {
    const {svg, listWidth, rect, svgRect} = this.state
    const listItemHeight = this.fontSize * 2
    const component = this;
    const searchString = component.filters.sourceFilter.toLowerCase()


  const sourceList = d3.select('.source-list')
    .attr('transform', `translate(${component.sourceSection.x+component.sourceSection.transform.x}, ${component.sourceSection.y+component.sourceSection.transform.y})`)
    .selectAll('foreignObject')
    .data<ItemType>(component.config.sourceItems.filter(x => x.text.toLowerCase().includes(searchString)), (data: any) => data.id);


  sourceList.attr('y', (data, idx) => idx * listItemHeight)


  sourceList
    .enter()
    .append(() => {
      const el = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
      return el
    })
    .attr('x', (data) => 0)
    .attr('y', (data, idx) => idx * listItemHeight)
    .attr('width', (data, idx) => listWidth)
    .attr('height', (data, idx) => listItemHeight)
    .append((data) => {
      const el = document.createElement('div');
      const indent = document.createElement('span')
      const text = document.createElement('span')
      const dataTypeIcon = document.createElement('span')

      el.classList.add('source-item')
      el.setAttribute('title', data.description || data.id)

      indent.style.opacity = '.3'

      indent.innerText = '—'.repeat(data.indent||0);
      text.innerText = data.text;

      el.appendChild(indent)
      el.appendChild(dataTypeIcon)
      el.appendChild(text)

      // Data type
      {
        dataTypeIcon.classList.add('material-symbols-outlined')
        dataTypeIcon.innerText = getDataTypeIconName(data.type)
        dataTypeIcon.style.fontSize = '16px'
        dataTypeIcon.style.opacity = '.5'
      }

      // Is Required
      if(data.use === 'required') {
        const requiredIcon = document.createElement('span')
        requiredIcon.classList.add('material-symbols-outlined')
        requiredIcon.innerText = 'emergency'
        requiredIcon.style.fontSize = '10px'
        requiredIcon.style.color = 'red'
        requiredIcon.style.marginTop = '5px'
        requiredIcon.style.marginBottom = 'auto'
        requiredIcon.style.opacity = '.5'
        requiredIcon.style.marginLeft = '.2rem'
        el.appendChild(requiredIcon)
        // el.style.fontWeight = '600'
      }
      return el
    })

  sourceList.exit().remove()
  }

  renderTarget() {

    const {svg, listWidth, rect, svgRect} = this.state
    const listItemHeight = this.fontSize * 2
    const component = this;
    const searchString = component.filters.targetFilter.toLowerCase()

    const targetList = d3.select('.target-list')
      .attr('transform', `translate(${component.targetSection.x+component.targetSection.transform.x}, ${component.targetSection.y+component.targetSection.transform.y})`)
      .selectAll('foreignObject')
      .data<ItemType>(component.config.targetItems.filter(x => x.text.toLowerCase().includes(searchString)), (data: any) => data.id)
      
    targetList.attr('y', (data, idx) => idx * listItemHeight)

    targetList.enter()
      .append(() => {
        const el = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
        return el
      })
      .attr('x', (data) => 0)
      .attr('y', (data, idx) => idx * listItemHeight)
      // right list takes double space to render connector list and expression list
      .attr('width', (data, idx) => 2 * listWidth) 
      .attr('height', (data, idx) => listItemHeight)
      .append((data) => {
        const el = document.createElement('div')
        const connectorText = document.createElement('div')
        const connectorExpression = document.createElement('div')
        const indent = document.createElement('span')
        const text = document.createElement('span')
        const dataTypeIcon = document.createElement('span')

        el.appendChild(connectorText)
        el.appendChild(connectorExpression)
        el.classList.add('target-item')


        connectorText.style.width = '50%'
        connectorText.style.height = '100%'
        connectorExpression.style.width = '50%'
        connectorExpression.style.height = '100%'

        connectorText.appendChild(indent)
        connectorText.appendChild(dataTypeIcon)
        connectorText.appendChild(text)
        connectorText.setAttribute('title', data.description || data.id)

        indent.style.opacity = '.3'
        indent.innerText = '—'.repeat(data.indent||0);
        text.innerText = data.text;


        const expression = document.createElement('input')
        connectorExpression.appendChild(expression)
        expression.classList.add('expression-input')
        expression.placeholder = 'Expression'
        expression.value = this.expressionMap[data.id] || ''
        expression.addEventListener('change', event => {
          this.expressionMap[data.id] = (event.target as any).value
        })

        // Data type
        {
          dataTypeIcon.classList.add('material-symbols-outlined')
          dataTypeIcon.innerText = getDataTypeIconName(data.type)
          dataTypeIcon.style.fontSize = '16px'
          dataTypeIcon.style.opacity = '.5'
        }

        // Is Required
        if(data.use === 'required') {
          const requiredIcon = document.createElement('span')
          requiredIcon.classList.add('material-symbols-outlined')
          requiredIcon.innerText = 'emergency'
          requiredIcon.style.fontSize = '10px'
          requiredIcon.style.color = 'red'
          requiredIcon.style.marginTop = '5px'
          requiredIcon.style.marginBottom = 'auto'
          requiredIcon.style.opacity = '.5'
          connectorText.appendChild(requiredIcon)
          text.style.fontWeight = '600'
        }

        return el
      })

      targetList.exit().remove()

  }

  renderLeftScroll() {
    const {svg, listWidth, rect, svgRect} = this.state
    const listItemHeight = this.fontSize * 2
    const component = this;

    const leftScrollBar = d3.select(component.svgContainer.nativeElement).select('.left-scrollbar');

    leftScrollBar.selectAll('rect')
      .data([component.scrollbars[0]])
      .attr('x', data => data.x)
      .attr('y', data => data.y + (data.stepHeight * data.step))
      .attr('width', this.scrollBarWidth)
      .style('display', data => data.stepCount?'block': 'none')
      .attr('height', data => data.brushHeight)
  }


  renderRightScroll() {

    const {svg, listWidth, rect, svgRect} = this.state
    const listItemHeight = this.fontSize * 2
    const component = this;

    const leftScrollBar = d3.select(component.svgContainer.nativeElement).select('.right-scrollbar');

    leftScrollBar.selectAll('rect')
      .data([component.scrollbars[1]])
      .attr('x', data => data.x)
      .attr('y', data => data.y + (data.stepHeight * data.step))
      .style('display', data => data.stepCount?'block': 'none')
      .attr('width', this.scrollBarWidth)
      .attr('height', data => data.brushHeight)
  }

  renderConnectors() {

    const {svg, listWidth, rect, svgRect} = this.state
    const listItemHeight = this.fontSize * 2
    const bulletRadius = this.bulletRadius
    const component = this

    const connectors = d3.select(component.svgContainer.nativeElement).select('.connectors')

    // Source Bullets
    const sourceList = connectors.select('.bullets').selectAll('.source-bullet').data(component.leftConnectorBullets, (data: any) => data.item.id);

     sourceList.attr('fill', data => data.isConnected ? 'white' : '#00bcd4')
      .attr('stroke', data => data.isConnected ? '#00bcd4' : 'none')
      .attr('stroke-width', data => data.isConnected ? '1' : '0')
      .attr('cy', data => (data.index - component.scrollbars[0].step) * listItemHeight + (listItemHeight / 2))
      .attr('r', data => bulletRadius)

    sourceList
    .enter()
    .append('circle')
    .classed('source-bullet', true)
    .classed('source-bullet-connected', data => data.isConnected)
    .attr('fill', data => data.isConnected ? 'white' : '#00bcd4')
    .attr('stroke', data => data.isConnected ? '#00bcd4' : 'none')
    .attr('stroke-width', data => data.isConnected ? '1' : '0')
    .attr('cx', 0 - bulletRadius)
    .attr('cy', data => (data.index - component.scrollbars[0].step)*listItemHeight + listItemHeight / 2)
    .attr('r', data => bulletRadius)
    .style('cursor', 'pointer')
    .on('mouseover', function(){
      const selection = d3.select(this)
      selection.transition()
        .ease(easeElastic)
        .duration(500)
        .attr('r', bulletRadius+3)

    })
    .on('mouseout', function() {
      const selection = d3.select(this)
      selection.transition()
        .ease(easeElastic)
        .duration(500)
        .attr('r', bulletRadius)

    })
    .on('mousedown', function(data: BulletType) {
        const event = (d3 as any as {event: CustomEvent}).event;
        event.preventDefault();
        event.stopPropagation();

        component.dragger.sourceIndex = data.index
        if(component.dragger.isDragging &&  component.dragger.source?.itemType !== ItemTypes.source) {
          const newEdge = {
            source: data,
            target: component.dragger.source!,
            sourceIndex: component.dragger.sourceIndex,
            targetIndex: component.dragger.targetIndex
          }
          const oldEdge = component.edges.find(edge => edge.source === newEdge.source && edge.target === newEdge.target);
          if(oldEdge) {
            component.edges = component.edges.filter(edge => edge !== oldEdge)
            component.removeEdge(oldEdge.source.item, oldEdge.target.item)
            oldEdge.source.isConnected = (--oldEdge.source.edgeCount) > 0
            oldEdge.target.isConnected = (--oldEdge.target.edgeCount) > 0
          }
          else {
            component.edges.push(newEdge)
            component.addEdge(newEdge.source.item, newEdge.target.item)
            newEdge.source.edgeCount ++
            newEdge.target.edgeCount ++
            newEdge.source.isConnected = true
            newEdge.target.isConnected = true
          }

          component.dragger.isDragging = false
          component.renderConnectors()
          component.renderDragger()
          return
        };

        const start = {
          x: listWidth -bulletRadius, 
          y: (data.index - component.scrollbars[0].step)*listItemHeight + listItemHeight / 2
        }

        component.dragger.start = {...start}
        component.dragger.end = {...start}
        component.dragger.isDragging = true
        component.dragger.source = data

        component.renderDragger();
      })

    sourceList.exit().remove()



    // Target Bullets
  const targetList = connectors.select('.bullets').selectAll('.target-bullet').data(component.rightConnectorBullets, (data: any) => data.item.id)
    .attr('fill', data => data.isConnected ? 'white' : '#00bcd4')
    .attr('stroke', data => data.isConnected ? '#00bcd4' : 'none')
    .attr('stroke-width', data => data.isConnected ? '1' : '0')
    .attr('cy', data => (data.index - component.scrollbars[1].step) * listItemHeight + (listItemHeight / 2))
    .attr('r', data => bulletRadius)

  
    targetList.enter()
    .append('circle')
    .classed('target-bullet', true)
    .classed('target-bullet-connected', data => data.isConnected)
    .attr('fill', data => data.isConnected ? 'white' : '#00bcd4')
    .attr('stroke', data => data.isConnected ? '#00bcd4' : 'none')
    .attr('stroke-width', data => data.isConnected ? '1' : '0')
    .attr('cx', listWidth + bulletRadius)
    .attr('cy', data => (data.index - component.scrollbars[1].step) * listItemHeight + listItemHeight / 2)
    .attr('r', data => bulletRadius)
    .style('cursor', 'pointer')
    .on('mouseover', function(){
      const selection = d3.select(this)
      selection.transition()
        .ease(easeElastic)
        .duration(500)
        .attr('r', bulletRadius+3)

    })
    .on('mouseout', function() {
      const selection = d3.select(this)
      selection.transition()
        .ease(easeElastic)
        .duration(500)
        .attr('r', bulletRadius)

    })
    .on('mousedown', function(data: BulletType) {
      const event = (d3 as any as {event: CustomEvent}).event;
      event.preventDefault();
      event.stopPropagation();

      component.dragger.targetIndex = data.index
      if(component.dragger.isDragging && component.dragger.source?.itemType !== ItemTypes.target) {
        const newEdge = {
          source: component.dragger.source!,
          target: data,
          sourceIndex: component.dragger.sourceIndex,
          targetIndex: component.dragger.targetIndex
        }
        const oldEdge = component.edges.find(edge => edge.source === newEdge.source && edge.target === newEdge.target);
        
        if(oldEdge) {
          component.edges = component.edges.filter(edge => edge !== oldEdge)
          component.removeEdge(oldEdge.source.item, oldEdge.target.item)
          oldEdge.source.isConnected = (--oldEdge.source.edgeCount) > 0
          oldEdge.target.isConnected = (--oldEdge.target.edgeCount) > 0
        }
        else {
          component.edges.push(newEdge)
          component.addEdge(newEdge.source.item, newEdge.target.item)
          newEdge.source.edgeCount ++
          newEdge.target.edgeCount ++
          newEdge.source.isConnected = true
          newEdge.target.isConnected = true
        }

        component.dragger.isDragging = false
        component.renderConnectors()
        component.renderDragger()
        return
      };


      const start = {
        x: 2 * listWidth, 
        y: (data.index) * listItemHeight + listItemHeight / 2
      }

      component.dragger.start = {...start}
      component.dragger.end = {...start}
      component.dragger.isDragging = true
      component.dragger.source = data

      component.renderDragger();
    })  
  targetList.exit().remove()


    // Edges
    const edges = connectors.select('.edges').selectAll('path.connector').data(component.edges);

    edges.attr('d', data => {
        const start = {x: 0, y: (data.sourceIndex - component.scrollbars[0].step) * listItemHeight + listItemHeight / 2}
        const end = {x: listWidth, y: (data.targetIndex - component.scrollbars[1].step) * listItemHeight + listItemHeight / 2}
        const m1 = {x: start.x + 10, y: start.y}
        const m2 = {x: end.x - 10, y: end.y}
        return component.EdgeCurve([start, m1, m2, end])
      });

    edges.enter().append('path')
      .classed('connector', true)
      .attr('fill', 'none')
      .attr('stroke', '#00bcd4')
      .attr('stroke-width', '1')
      .attr('d', data => {
        const start = {x: 0, y: (data.sourceIndex - component.scrollbars[0].step) * listItemHeight + listItemHeight / 2}
        const end = {x: listWidth, y: (data.targetIndex - component.scrollbars[1].step) * listItemHeight + listItemHeight / 2}

        const m1 = {x: start.x + 10, y: start.y}
        const m2 = {x: end.x - 10, y: end.y}
        return component.EdgeCurve([start, m1, m2, end])
      });

    edges.exit().remove()

  }

  renderDragger() {
    const listItemHeight = this.fontSize * 2
    const component = this
    const draggers = [this.dragger].filter(x => x.isDragging);

    const draggerContainer = d3.select(component.svgContainer.nativeElement).select('.dragger');

    const draggersSelection = draggerContainer.selectAll('path').data(draggers);

    draggersSelection.attr('d', data => {

      const scrollBar = component.scrollbars[data.source!.itemType === ItemTypes.source ? 0: 1];
      let start = {x: data.start.x, y: (listItemHeight / 2) + (data.source!.index - scrollBar.step) * listItemHeight}
      let end = {...data.end}

      if(start.x > end.x) [start, end] = [end, start]


      const m1 = {x: start.x + 10, y: start.y}
      const m2 = {x: end.x - 10, y: end.y}
      return component.EdgeCurve([start, m1, m2, end])
    })

    draggersSelection.enter()
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', '#00bcd4')
      .attr('stroke-width', '1')
      .attr('d', data => {
        const scrollBar = component.scrollbars[data.source!.itemType === ItemTypes.source ? 0: 1];
        let start = {x: data.start.x, y: (listItemHeight / 2) + (data.source!.index - scrollBar.step) * listItemHeight}
        let end = {...data.end}

        if(start.x > end.x) [start, end] = [end, start]


        const m1 = {x: start.x + 10, y: start.y}
        const m2 = {x: end.x - 10, y: end.y}

        const line = component.EdgeCurve([start, m1, m2, end])
        return line
      })

    draggersSelection.exit().remove()
  }

  onSourceChange() {
    this.initState()
    this.renderSource()
    this.renderConnectors()
    this.renderLeftScroll()
    this.renderRightScroll()
  }

  onTargetChange() {
    this.initState()
    this.renderTarget()
    this.renderConnectors()
    this.renderLeftScroll()
    this.renderRightScroll()
  }

  addEdge(sourceItem: ItemType, targetItem: ItemType) {
    if(!this.edgeMap[sourceItem.id])this.edgeMap[sourceItem.id] = []
    this.edgeMap[sourceItem.id] = Array.from(new Set<string>([...this.edgeMap[sourceItem.id], targetItem.id]))
    this.connected.next({...this.edgeMap})
    console.log(this.edgeMap)
  }

  removeEdge(sourceItem: ItemType, targetItem: ItemType) {
    if(!this.edgeMap[sourceItem.id])this.edgeMap[sourceItem.id] = []
    this.edgeMap[sourceItem.id] = this.edgeMap[sourceItem.id].filter(item => item !== targetItem.id)
    this.connected.next({...this.edgeMap})
    console.log(this.edgeMap)
  }

}




const getScrollBar = (itemCount: number, itemHeight: number, viewBoxHeight: number) => {
  const itemPerFrame = Math.floor(viewBoxHeight / itemHeight);
  const stepCount = Math.max(itemCount - itemPerFrame, 0);

  let brushHeight = (viewBoxHeight * viewBoxHeight) / (itemCount * itemHeight)
  let stepHeight = (viewBoxHeight - brushHeight) / Math.max(itemCount - itemPerFrame, 0);

  if(Math.max(itemCount - itemPerFrame, 0) === 0) stepHeight = 0

  const scrollBar = {
    x: 0, y: 0,
    step: 0,
    stepCount: isFinite(stepCount) ? stepCount : 0,
    stepHeight: isFinite(stepHeight) ? stepHeight : 0,
    brushHeight: isFinite(brushHeight) ? brushHeight : 0
  }
  return scrollBar
}



import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, ObservedValueOf, Observer} from 'rxjs';
import {ConsoleLoggerService} from "../../services/logger/console-logger.service";
import {Subscription} from "rxjs/internal/Subscription";
import {UIService} from "../../services/config/ui.service";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export type Option = {id: number|string, value: string, isSelected?:boolean, weight?:boolean|null, isBoolean: boolean}
export enum EventTypes {
  ON_NODE_SELECTION_CHANGE = 'on-incoming-node-change',
  ON_SELECT = 'on-select',
  ON_REMOVE = 'on-remove',
  ON_INDEX_CHANGE = 'on-index-change',
  ON_SHOW_DETAILS = 'show-details'
}


@Component({
  selector: 'app-union',
  templateUrl: './union.component.html',
  styleUrls: ['./union.component.css']
})
export class UnionComponent implements OnInit, OnChanges, OnDestroy {

  @Input() dropdownOptions: Array<{id: string|number, value: string|number, isSelected?: boolean, isBoolean?:boolean, booleanValue?:number, showDetaiIcon?: boolean}> = []
  @Input() events = new Observable<{type:string, args: Array<unknown>}>()
  @Output() callbackEvents = new EventEmitter<{type:string, args: Array<unknown>}>()
  @Input() dropdownSelectedOrder: Array<number> = []

  eventMapping: { [key: string]: Function } = {}
  subscriptions: Array<Subscription> = []

  constructor(private logger: ConsoleLoggerService,private uiService: UIService) {}


  selectedNodes: Array<Option|any> = []

  ngOnChanges(changes: any) {
    if(changes.events && changes.events.currentValue) {
      this.events = changes.events.currentValue
      this.subscriptions.forEach(subscription => subscription.unsubscribe())
      this.subscriptions = []
      this.registerEvents()
    }
  }

  ngOnInit(): void {
    let selectedNodes = this.dropdownOptions.filter(x => !!x.isSelected)
    let orderMap = this.dropdownSelectedOrder.reduce((prev, curr, i) => { prev[curr]=i; return prev }, {} as {[key: number]: number})

    let outOfOrder: typeof selectedNodes = []
    selectedNodes = selectedNodes.reduce((prev, curr) => {
      if(orderMap[curr.id as number] !== undefined) {
        prev[orderMap[curr.id as number]] = curr
      }
      else {
        outOfOrder.push(curr)
      }
      return prev
    }, [] as Array<any>)

    this.selectedNodes = selectedNodes.concat(outOfOrder)

    this.registerEvents()
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  initValues(selectedItems: Array<number|string>=[]) {
    this.dropdownOptions = this.dropdownOptions.map(x => ({...x, isSelected: selectedItems.includes(x.id)}))
    this.sendSelectedNodes()
  }

  onchange(event: any) {
    let selectedNodeId = event.target.value
    let selectedNode = this.dropdownOptions.find(x => x.id == selectedNodeId)
    if(selectedNode) {
      selectedNode.isSelected = !(!!selectedNode.isSelected)
    }

    if(selectedNode && selectedNode.isSelected) {
      this.callbackEvents.emit({
        type: EventTypes.ON_SELECT,
        args: [selectedNode]
      })
    }

    this.sendSelectedNodes()
  }

  removeItem(option: {id: string|number, value: string|number, isSelected?: boolean}) {
    option.isSelected = false
    this.callbackEvents.emit({
      type: EventTypes.ON_REMOVE,
      args: [option]
    })
    this.sendSelectedNodes()
  }

  itemClicked(item: {id: string|number, value: string|number, isSelected?: boolean}) {
    this.callbackEvents.emit({
      type:'option-clicked',
      args: [item]
    })
  }

  showDetails(item: {id: string|number, value: string|number, isSelected?: boolean}) {
    this.callbackEvents.emit({
      type:'show-details',
      args: [item]
    })
  }

  sendSelectedNodes() {
    const previusSelectedOptions = this.selectedNodes.map(x => x.id)
    const newSelectedOptions = this.dropdownOptions.filter(x => !!x.isSelected && !previusSelectedOptions.includes(x.id))
    this.selectedNodes = this.selectedNodes.filter(x => !!x.isSelected).concat(newSelectedOptions)

    let selectedNodes = this.selectedNodes.map(x => x.id)
    this.callbackEvents.emit({
      type:'on-incoming-node-change',
      args: [selectedNodes]
    })
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedNodes, event.previousIndex, event.currentIndex);
    this.dropdownSelectedOrder = this.selectedNodes.map(x => x.id)
    this.callbackEvents.emit({
      type: EventTypes.ON_INDEX_CHANGE,
      args: [this.dropdownSelectedOrder]
    })
  }

  registerEvents() {
    // Event mapping
    this.eventMapping['update-selected-nodes'] = this.initValues

    // Subscribe events
    let subscription =  this.events.subscribe(event => {
      console.log(event)
      if (this.eventMapping.hasOwnProperty(event.type)) {
        this.eventMapping[event.type].apply(this, event.args)
      } else {
        this.logger.warn(`Unhandled event: ${event.type}`)
      }
    })
    this.subscriptions.push(subscription)
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
    // console.log("Clicked!");
  }
}


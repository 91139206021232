<div>
  <ag-grid-angular
    #agGrid
    style="width: 100%; height: 50vh;"
    class="ag-theme-alpine"
    [rowData]="rowData | async"
    [columnDefs]="columnDefs"
    rowSelection="multiple"
  >
  </ag-grid-angular>
</div>


<div  style="background-color: white;height: 50vh;position: relative;">
    <div><b>Enter Node details</b><span><button nbButton size="small"  style="float: right;" (click)="close()"><nb-icon icon="close-outline" ></nb-icon></button></span></div>
    <hr>
    <label>Node name</label>
    <nb-form-field>
        <!-- <mat-icon style="margin:auto auto;">text_fields</mat-icon> -->
        <nb-icon nbPrefix icon="text-outline"></nb-icon>
        <input nbInput fullWidth="true" fieldSize="small" type="text" [(ngModel)]="nodeInstanceName">
    </nb-form-field>
    <div *ngIf="!nodeInstanceName" style="background-color: #fee;color:#c33;font-size:smaller;padding:0.25rem;margin-top:0.1rem"><nb-icon style="margin:0 3px 0 3px" icon="alert-triangle-outline"></nb-icon>Node name can't be empty!!</div>
    <hr>

    <label>Node Description</label>
    <textarea nbInput [(ngModel)]="description"></textarea>

    <button style="position: absolute;left:0px;bottom:0px" nbButton status="success" size="small" (click)="addNode()">Create Node</button>
</div>
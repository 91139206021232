import {Injectable} from '@angular/core';
import {Context} from "../config/context.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ServiceResponse} from "../core/data.service";
import { BehaviorSubject } from 'rxjs';

type SignupResponse = ServiceResponse<{ username: string, email: string}>
type LoginResponse = ServiceResponse<{ apiKey: string, email: string, token: {access_token: string, refresh_token: string} }>
type UserInfoResponse = ServiceResponse<{accounts: Array<{id: string, name: string, type: string, slug: string, attributes: any}>}>

export type SignupBody = {
  username: string,
  email: string,
  password: string,
  is_staff: boolean,
  is_superuser: boolean
}

export type LoginBody =  {
  username: string,
  password: string
} | {
  email: string,
  password: string
}

const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
const USER_KEY = 'auth-user';
const ACCOUNT_ID_KEY = 'auth-user';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accountId = new BehaviorSubject<string|null>(this.getAccountId());
  public accountId$ = this.accountId.asObservable();
  constructor(private context: Context, private httpClient: HttpClient) {}

  signup(body: SignupBody) {
    return this.httpClient.post<SignupResponse>(this.context.getApiConfig().AUTH_APIS.POST.register, body, {})
  }

  login(body: LoginBody) {
    return this.httpClient.post<LoginResponse>(this.context.getApiConfig().AUTH_APIS.POST.login, body, {})
  }
 
  refreshToken(token: string) {
    return this.httpClient.post(this.context.getApiConfig().PIPELINE_APIS_V2.POST.refreshToken(), {
      refresh_token: token
    }, httpOptions);
  }
  
  getUserInfo() {
    return this.httpClient.get<UserInfoResponse>(this.context.getApiConfig().AUTH_APIS.GET.accounts)
  }

  logout(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveAccountId(accountId: string) {
    if(accountId) window.sessionStorage.setItem(ACCOUNT_ID_KEY, accountId);
    else window.sessionStorage.removeItem(ACCOUNT_ID_KEY);

    this.accountId.next(accountId || null)

  }

  public getAccountId() {
    return window.sessionStorage.getItem(ACCOUNT_ID_KEY);
  }

  public saveRefreshToken(token: string): void {
    window.sessionStorage.removeItem(REFRESHTOKEN_KEY);
    window.sessionStorage.setItem(REFRESHTOKEN_KEY, token);
  }

  public getRefreshToken(): string | null {
    return window.sessionStorage.getItem(REFRESHTOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    this.context.setCurrentUser(user)
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  isValidToken(token?: string) {
    token ??= this.getToken() || ''
    
    const payload = JSON.parse(atob((token || '..').split('.')[1])||'{"exp":0}') as {exp: number}
    return (payload.exp * 1000) > Date.now()
  }

}

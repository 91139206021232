<div class="ag-cell-label-container gh" role="presentation" style="border-bottom: solid blue 6px;">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button">
        <nb-icon [hidden]="!params.enableMenu" icon="more-horizontal-outline" class="edit-icons"
            (click)="toggleMenu($event, id)" nbTooltip="Options" nbTooltipPlacement="top"></nb-icon>
    </span>
    <div ref="eLabel" class="ag-header-cell-label gh-info" role="presentation">
        <span ref="eText" class="ag-header-cell-text gh-info__name" role="columnheader"  nbTooltip="{{field}}">{{field}}</span>
        <br>
        <span class="gh-info__type">{{type}}</span>

        <!-- <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
		<span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
		<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
		<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span> -->
    </div>
</div>
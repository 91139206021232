import {Directive, ElementRef, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appTaskDetails]'
})
export class TaskDetailsDirective {

  constructor(public viewContainerRef: ViewContainerRef, private el: ElementRef) {
    // this.el.nativeElement.style.backgroundColor = 'red'
    // viewContainerRef.clear()
  }

}

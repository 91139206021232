import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Nodev2Service } from 'src/app/services/api/nodev2.service';
import { ActivatedRoute, Router } from "@angular/router";
import { FileExplorerComponent } from 'src/app/components/file-explorer/file-explorer.component';



type Version = {
  id: number;
  accountId: number;
  versionNum: number;
  is_release: boolean;
  createdBy: string;
  createdTime: number;
  updatedTime: number;
};

@Component({
  selector: 'app-node-editor',
  templateUrl: './node-editor.component.html',
  styleUrls: ['./node-editor.component.css']
})
export class NodeEditorComponent implements OnInit {
  @ViewChild('fileExplorer') fileExplorer: any;
  uiLoading = false;
  editorLoading = true
  connectorId:number
  versionId:number
  
  
  versionNum = -1
  versionDetails:any


  nodeData:{id:number,name:string,description:string,image:string}= {id:-1,name:'',description:'',image:''}
  files:Array<{name:string,content:string,modified:boolean}>=[
  ]
  sidenavPageSelected: 'editor' | 'debugger' = 'editor'

  displayedColumns: string[] = ['id','updatedBy','updatedOn','comments'];
  dataSource:Version[] = [];

   //Page variables for mat-paginator
   pageIndex: number = 0;
   pageSize: number = 20;
   totalCount: number = 0;
 
   //Default page-variables
   PAGE_NUM: number = 1;
   PAGE_SIZE: number = 20;
 
   pageQuery: string = '';
  constructor(private nodeService:Nodev2Service,private dialogService: NbDialogService,private route: ActivatedRoute,private router: Router) {
    this.route.params.subscribe
    this.connectorId = +this.route.snapshot.params.id || -1
    this.versionId = +this.route.snapshot.params.versionId || -1
    console.log(this.connectorId,this.versionId)
   }

  ngOnInit() {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      //call and load connector details
      this.loadNodeData(this.connectorId);
      this.loadConnectorVersions();

      let files:Array<{name:string,content:string,modified:boolean}> = []
      this.nodeService.customSdkConnector.connectorId = this.connectorId;
      this.nodeService.customSdkConnector.versionId = this.versionId;
      this.nodeService.getVersionDetails(this.connectorId,this.versionId).subscribe((data:any)=>{
        this.versionNum = data.versionNum
        this.isDeployed();
        let _files = data.code;
        this.loadEditorFiles(_files.map((file:{file:any,code:any})=>{return {name:file.file,content:file.code,modified:false}}))
      })

  }

  loadNodeData(id:number){
    this.nodeService.getCustomConnector(id.toString()).subscribe((data:{payload:any})=>{
      this.nodeData.id = id;
      this.nodeData.name = data.payload.label;
      this.nodeData.description = data.payload.description;
      this.nodeData.image = data.payload.icon;
    })
  }

  loadEditorFiles(files:any){
    //call connector edit api-> get files
    this.files = files;
  }

  openNodeVersion(row:any,dialog:TemplateRef<any>){
    let {id} = this.dataSource.find(version=>version.versionNum===row.versionNum) || {id:-1}
    this.nodeService.getVersionDetails(this.connectorId,id).subscribe(data=>{
      let _files = data.code
      this.restoreFiles =  _files.map((file:{file:any,code:any})=>{return {name:file.file,content:file.code,modified:false}});
    })
    this.dialogService.open(dialog, { context: {row} }).onClose.subscribe((data:any)=>{
      if(data==='restore'){
        this.editorLoading = true;
        this.nodeService.restoreVersion(this.connectorId,id).subscribe((data:any)=>{
          this.editorLoading = false;
          this.router.navigate([`/more/custom-node/${this.connectorId}/${data.versionId}`]);
        })
      }
  });
  }

  loadConnectorVersions(){
    this.nodeService.getConnectorVersions(this.connectorId).subscribe(data=>{
      this.dataSource = data;
    })
  }
  restoreFiles = []
  saveConnector(){
    //call save connector api
    console.log(this.fileExplorer.getAllFiles());
  }
  releaseConnector(){
    this.uiLoading = true;
    this.nodeService.releaseConnector(this.connectorId,this.versionId).subscribe((data:any)=>{
      if(data.status==='success') console.log('connector released');
      this.uiLoading = false;
    });
  }
  pageChange(event: any) {
    // for page size change pageIndex
    let page_index = this.pageSize !== event.pageSize ? this.PAGE_NUM : event.pageIndex + 1
    this.pageSize=event.pageSize
    // this.loadConnectorVersions(page_index, event.pageSize, this.pageQuery)

  }
  myInterval:any
  isDeployed(){
    this.myInterval = setInterval(()=>{
      this.nodeService.getDeployerStatus(this.connectorId,this.versionId).subscribe((data:any)=>{
        if(data.payload.status==='deployed'){
          this.editorLoading = false;
          clearInterval(this.myInterval);
        }
      })
    },5000)
    
  }

  ngOnDestroy(){
    clearInterval(this.myInterval)
  }
}


<simplify-groups [recents]="recentGroupCount" [allGroups]="allGroupCount">
    <simplify-groups-header [showCreateNew]="hasAdminRole" slot="header" (createGroup)="openCreateGroupPopup()" (searchQuery)="onGroupSearch($event)"></simplify-groups-header>
    <simplify-group-list-item [showEdit]="hasAdminRole" (groupEdit)="onGroupEdit(group.id)" (groupOpen)="onOpenGroup(group.id)" *ngFor="let group of filteredRecentGroups" [groupdata]="getGroupData(group)"></simplify-group-list-item>
    <simplify-group-list-item  [showEdit]="hasAdminRole" (groupEdit)="onGroupEdit(group.id)" slot="all-groups" (groupOpen)="onOpenGroup(group.id)" *ngFor="let group of allGroups" [groupdata]="getGroupData(group)"></simplify-group-list-item>
    <simplify-pagination slot="pagination" *ngIf="allGroupCount>10" [totalCount]="allGroupCount" [pageIndex]="pageIndex" [itemsPerPage]="itemsPerPage" [pageSizeOptions]="pageSizeOptions" (paginate)="onPaginationEvent($event)"></simplify-pagination>
</simplify-groups>

<ng-template #createGroupModal let-data>
    <!-- <simplify-create-group-modal (save)="createGroup($event)" (close)="closeCreateGroupPopup()"></simplify-create-group-modal> -->
    <simplify-create-group-modal-2 (customEvents)="customHeaderHandler($event)" (close)="closeCreateGroupPopup()" [finalImage]="croppedImage">
      <app-image-cropper slot="icon" [imageChangedEvent]="imageChangedEvent"  [imageUrl]="imageUrl" (croppedImage)="croppedImage = $event"></app-image-cropper>
    </simplify-create-group-modal-2>
  
  </ng-template>
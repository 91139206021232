

<div style="display: flex;flex-direction: column;height: 100%;">
  <div style="display: flex;justify-content: space-between;height: 28px;flex-shrink: 0;">
    <span>{{data.label}}</span>
    <div 
      class="settings-icon"
      *ngIf="settingControl"
      refComponent
      [data]="{type: 'control', payload: settingControl }"
      [emit]="emit"
      [attr.data-testid]="'control-'+'Settings'">
    </div>
  </div>


  <div
    *ngFor="let input of data.inputs | keyvalue"
    [attr.data-testid]="'input-'+input.key"
    style="display: flex;justify-content: space-between;height: 28px;flex-shrink: 0;">
    <div
      class="input-socket"
      refComponent
      [data]="{type: 'socket', side: 'input', key: input.key, nodeId: data.id, payload: input.value?.socket, seed: seed }"
      [emit]="emit"
      data-testid="input-socket"
    ></div>
    <div
      class="input-title"
      data-testid="input-title"
      *ngIf="!input.value?.control || !input.value?.showControl"
    >
    </div>
  </div>

  
  <div
    *ngFor="let output of data.outputs | keyvalue"
    [attr.data-testid]="'output-'+output.key"
    style="display: flex;justify-content: space-between;height: 28px;flex-shrink: 0;">
    <div class="output-title" data-testid="output-title">
      {{output.value?.label}}
    </div>
    <div
      class="output-socket"
      refComponent
      [data]="{type: 'socket', side: 'output', key: output.key, nodeId: data.id, payload: output.value?.socket, seed: seed }"
      [emit]="emit"
      data-testid="output-socket"
    ></div>
  </div>

</div>


<!-- 
  <div class="title" data-testid="title">
    <span>{{data.label}}</span>
    <div 
      class="settings-icon"
      *ngIf="settingControl"
      refComponent
      [data]="{type: 'control', payload: settingControl }"
      [emit]="emit"
      [attr.data-testid]="'control-'+'Settings'">
    </div>

  </div>
  


  <div
    class="control"
    *ngFor="let control of inputControls | keyvalue"
    refComponent
    [data]="{type: 'control', payload: control.value }"
    [emit]="emit"
    [attr.data-testid]="'control-'+control.key"
  ></div>


  <div
    class="input"
    *ngFor="let input of data.inputs | keyvalue"
    [attr.data-testid]="'input-'+input.key"
  >
    <div
      class="input-socket"
      refComponent
      [data]="{type: 'socket', side: 'input', key: input.key, nodeId: data.id, payload: input.value?.socket, seed: seed }"
      [emit]="emit"
      data-testid="input-socket"
    ></div>

    <div
      class="input-title"
      data-testid="input-title"
      *ngIf="!input.value?.control || !input.value?.showControl"
    >

    </div>

  </div> 

  <div
    class="output"
    *ngFor="let output of data.outputs | keyvalue"
    [attr.data-testid]="'output-'+output.key"
  >
    <div class="output-title" data-testid="output-title">
      {{output.value?.label}}
    </div>
    <div
      class="output-socket"
      refComponent
      [data]="{type: 'socket', side: 'output', key: output.key, nodeId: data.id, payload: output.value?.socket, seed: seed }"
      [emit]="emit"
      data-testid="output-socket"
    ></div>
  </div>

 -->

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfigKeyModel, ConfigModel} from "../../../../models/config.model";
import {ConfigKeyService, ConfigServiceResponse} from "../../../../services/api/configKey.service";
import {PipelineAPIResponse, PipelineService} from "../../../../services/api/pipeline.service";
import {NodeService, NodeServiceResponse} from "../../../../services/api/node.service";
import {DropdownOptionType, InputFieldType} from "../addtask/add-task.component";
import {ConsoleLoggerService} from "../../../../services/logger/console-logger.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FileService} from "../../../../services/api/file.service";
import {ConfigureApiService} from "../../../../services/api/configure-api.service";
import {NbDialogService} from "@nebular/theme";
import {Node} from "../../../../models/tasknode.model";
import {TaskService} from "../../../../services/api/task.service";
import {
  ButtonType,
  CloseButton,
  CreateButton,
  LoadDefaultConfigurations,
  OutputEventEnum,
  PreviewButton,
  RunTaskButton,
  UpdateButton,
  addInputField,
  boolThreeState,
  checkDropdown,
  enableButtons,
  getInputFromConfigKey,
  getInputHandlers,
  getSingleDropdownSelector,
  transformData_v1,
  removeInputField,
  userDefineConfigValues,
  inputsVisitor
} from "../helper-functions";
import { BehaviorSubject, Subject } from 'rxjs';
import { EventTypes } from 'src/app/components/multi-select-dropdown/union.component';
import { DashboardStoreService } from 'src/app/services/store/dasboard-store.service';
import { AddTaskComponent } from '../addtask/add-task.component';
import { UpdateTaskComponent } from '../update-task/update-task.component';
import { schema_sheet } from 'src/app/models/schema.model';


export type OutputEventType = keyof typeof OutputEventEnum

export const INPUT_FIELD_KEY = 'id'
export const INPUT_FIELD_CONFIG_KEY = 'id'

@Component({
  selector: 'app-copy-task',
  templateUrl: './copy-task.component.html',
  styleUrls: ['./copy-task.component.css']
})
export class CopyTaskComponent implements OnInit {
  INPUT_FIELD_KEY = INPUT_FIELD_KEY
  INPUT_FIELD_CONFIG_KEY = INPUT_FIELD_CONFIG_KEY

  @Input() type!: ConfigModel;
  @Input() pipelineId!: string | number;
  @Input() processId!: string | number;
  @Input() parentNodeIds: number[] = [];
  @Input() currentNodeId!: number;
  @Output() output = new EventEmitter<{type: OutputEventType|string, data: any}>()
  @Input() isReadonly: boolean = false
  @Input() schemaProperties: {
    hasSchema:boolean,
    schema:Array<schema_sheet>
  } = {
    hasSchema : false,
    schema : []
  }


  // Metadata
  configKeyResponse!: ConfigServiceResponse['payload']

  // Data
  pipeline!: PipelineAPIResponse['payload']
  configData!: NodeServiceResponse['payload']
  taskConfigDetails!: NodeServiceResponse
  currentNode!: Node
  cacheData: Map<string, any> = new Map<string, any>()

 
  // State
  // eslint-disable-next-line no-use-before-define
  actionButtons: Array<ButtonType> = []
  inputActionButtons: Array<ButtonType> = []
  inputHandlers!:  ReturnType<typeof getInputHandlers>

  formInputs: Array<InputFieldType> = []
  keyInputMap: {[key: string]: InputFieldType, [key: number]: InputFieldType} = {}
 
  // sideEffect functions should be called after change in any input field
  // !!should not change configKeyValues or call handleInputChange function!!
  sideEffects: Array<(state: UpdateTaskComponent|CopyTaskComponent, event: any) => void> = []

  constructor(
    private logger: ConsoleLoggerService,
    private configKeyService: ConfigKeyService,
    public dashboardStoreService : DashboardStoreService,
    public pipelineService: PipelineService, // TODO: make service private
    public taskService: TaskService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public nodeService: NodeService,
    public fileService: FileService,
    public dialogService: NbDialogService,
    public configureAPIService :ConfigureApiService
  ) { 

    this.inputHandlers = getInputHandlers(this)// need to add copytask node in helper functions

    this.sideEffects.push(enableButtons)
    this.sideEffects.push(inputsVisitor)
    this.sideEffects.push(checkDropdown)

    this.output.subscribe(logger.log)

  }

  async ngOnInit() {
    // Add Close button
    // this.actionButtons.push(CloseButton)

    this.pipeline = this.dashboardStoreService.getCurrentPipeline()!
    if(this.pipeline) {
      this.pipeline = await this.pipelineService.getPipeline(this.pipelineId as number).toPromise()
    }

    this.currentNode = this.dashboardStoreService.currentCopyNode;
    this.dashboardStoreService.currentCopyNode = {}
    console.log(this.currentNode);
    

    // Load configuration
    // FIXME: update incoming Task Ids as per V2 API response
    this.configKeyResponse = (await this.configKeyService.getConfigKey(this.type.id, []).toPromise()).payload
    // this.configKeyResponse = (await this.configKeyService.getConfigKey(this.type.id, this.currentNode.incomingTaskIds).toPromise()).payload

    // taskConfigDetails
    // FIXME: update incoming Task Ids as per V2 API response
    this.taskConfigDetails = await this.nodeService.getNodeConfig(this.currentNode.id, []).toPromise()
    // this.taskConfigDetails = await this.nodeService.getNodeConfig(this.currentNode.id, this.currentNode.incomingTaskIds).toPromise()

    // Load default input fields
    LoadDefaultConfigurations(this)

    // Load input fields from configuration
    this.configKeyResponse.configs.forEach(key => addInputField(this, getInputFromConfigKey(this, key)))

    // refill data
    this.refillData()

    // load sideEEffects
    const dependentDropdowns: Array<InputFieldType> = []
    if(this.keyInputMap['dependent'])dependentDropdowns.push(this.keyInputMap['dependent'])
    if(this.keyInputMap['data_dependent'])dependentDropdowns.push(this.keyInputMap['data_dependent'])
    if(this.keyInputMap['union'])dependentDropdowns.push(this.keyInputMap['union'])
    this.sideEffects.push(getSingleDropdownSelector(this, dependentDropdowns))

    // Add Save button
    this.actionButtons.push(CreateButton)
    enableButtons(this, {})

    this.output.next({type: OutputEventEnum.ON_LOAD, data: {component: this.constructor.name}})
  }

  public addInputField(input: InputFieldType) {
    addInputField(this, input)
    this.handleInputChange({configKeyName: '', value: ''})
  }

  public removeInputField(input: InputFieldType) {
    removeInputField(this, input)
    this.handleInputChange({configKeyName: '', value: ''})
  }

  // handle final input change.
  handleInputChange(event: { configKeyName: string|number, value: string | number | unknown }): void {
    this.sideEffects.forEach(visitor => visitor(this, event))
  }

  // validate input after update
  // TODO: use input validator
  validateInput(configKeyId: number, value: string | number | unknown): { valid: boolean, error?: string } {
    return {valid: true, error: ''}
  }

  refillData() {

    const APPLY_TASK_SPACIFIC_TO = 'union';

    // update name
    if(this.keyInputMap['name'] && this.keyInputMap['name']) {
      this.keyInputMap['name']._value = this.taskConfigDetails.payload.name
    }

    /*
    // fill dependent
    if(this.keyInputMap['dependent']) {
      const dependentNode = this.taskConfigDetails.payload.dependent_nodes.filter(x => ['run_dependent', 'conditional'].includes(x.type));
      this.keyInputMap['dependent']._value = dependentNode.map(x => x.taskId)
      let selectedNodeMap = dependentNode.reduce((prev, current) => {
        prev[current.taskId] = current
        return prev
      }, {} as {[key: number]: any})
      this.keyInputMap['dependent'].options.map(option => {
        if(selectedNodeMap[option.id as number]) {
          option.booleanValue = boolThreeState(selectedNodeMap[option.id as number].weight)
        }
        return option
      })
    }
    */
   
    /*
    // fill data dependent
    if(this.keyInputMap['data_dependent']) {
      const dependentNode = this.taskConfigDetails.payload.dependent_nodes.filter(x => ['data', 'conditional_data'].includes(x.type));
      this.keyInputMap['data_dependent']._value = dependentNode.map(x => x.taskId)
      let selectedNodeMap = dependentNode.reduce((prev, current) => {
        prev[current.taskId] = current
        return prev
      }, {} as {[key: number]: any})
      this.keyInputMap['data_dependent'].options.map(option => {
        if(selectedNodeMap[option.id as number]) {
          option.booleanValue = boolThreeState(selectedNodeMap[option.id as number].weight)
        }
        return option
      })
    }
    */
    // fill union
    if(this.keyInputMap['union']) {
      const unionNode = this.taskConfigDetails.payload.dependent_nodes.filter(x => ['data'].includes(x.type));
      this.keyInputMap['union']._value = unionNode.map(x => x.taskId)
      let selectedNodeMap = unionNode.reduce((prev, current) => {
        prev[current.taskId] = current
        return prev
      }, {} as {[key: number]: any})
      this.keyInputMap['union'].options.map(option => {
        if(selectedNodeMap[option.id as number]) {
          option.booleanValue = boolThreeState(selectedNodeMap[option.id as number].weight)
        }
        return option
      })
    }

    for(const key of this.taskConfigDetails.payload.configs) {

      // taskSpecific
      if(key.is_node_specific) {
        // refill value
        this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = key.userValue.node_specific_values || []

        // load dropdowns
        let unionSelectedOptions = this.keyInputMap[APPLY_TASK_SPACIFIC_TO].options.filter(x => x.isSelected);
        let taskSpectfigConfigHandlers = this.keyInputMap[key.id].eventHandlers;

        taskSpectfigConfigHandlers?.forEach(handler => {
          unionSelectedOptions.forEach(option => handler(this, {type: EventTypes.ON_SELECT, args: [option]}))
        });

        continue;
      }

      // text input
      if(key.key_value_input_box_type === "text" && key.key_value_type !== 'file' && this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]) {
        if(key.is_multi_select) {
          this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = key.userValues.value
        }
        else {
          this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = key.userValue.value
        }
      }

      // custom_inputs Object
      if(key.key_value_input_box_type === "custom_inputs" && key.key_value_type !== 'file' && this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]) {
        // TODO: Create custom inputs
        this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]].__value = JSON.parse(key.userValue.value || '{}');
        this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = JSON.stringify((this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]].__value as any).value || {});

        const refillData = (this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]].__value as any).value;

        const inputs = (this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]].__value as any).inputs as Array<ConfigKeyModel>;

        console.log('Creating custom inputs...', inputs);

        inputs?.forEach(inputTemplate => {
          let input = getInputFromConfigKey(this, inputTemplate)
          console.log(input)
          input._value = refillData[input.display_text];
          this.addInputField(input)
        })

        
      }

      // file input
      // if(key.key_value_input_box_type === 'text' && key.key_value_type === 'file') {
      //   this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._url = key.userValue.value
      // }

      // if(key.key_value_input_box_type === 'task_specific') {
      //   this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = JSON.parse(key.userValue.value||'[]')
      // }

      // dropdown input
      if(key.key_value_input_box_type === "drop_down" && this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]) {
        if(key.userValues) {
          this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = (key.userValues as any).value
          if(this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]].events) {
            (this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]].events as BehaviorSubject<{type:string, args: Array<unknown>}>).next({
              type: 'update-selected-nodes', args: [this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value]
            });
          }

        }
        if(key.userValue) {
          this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = key.userValue.value
        }
      }

      // textarea input
      if(key.key_value_input_box_type === 'textarea_code') {
        this.keyInputMap[key[INPUT_FIELD_CONFIG_KEY]]._value = key.userValue.value
      }


    }
  }

  async save() {

    // transform data to payload
    let payload = await transformData_v1(this)

    // appind user define configs
    // let custom_input_config =  this.configKeyResponse.configs.find(x => x.key_value_input_box_type = 'custom_inputs');
    // if(custom_input_config) {

    //   const userCustonConfig = await userDefineConfigValues(this, -100)
    //   console.log(userCustonConfig)

    // }


    // return // TODO: remove return

    // Update TaskNode
    const response = await this.dashboardStoreService.createTask(payload);

    // emit save event
    this.output.next({type: OutputEventEnum.SAVED_AND_EXIT, data: {payload, response}})

  }

  close() {
    this.output.next({type: OutputEventEnum.CANCEL, data: {}})
  }

}

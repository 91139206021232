import { Component, Input, OnInit, ViewChildren, AfterViewInit, QueryList, TemplateRef, OnDestroy} from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { schema_actions, schema_sheet } from 'src/app/models/schema.model';
import { AddSchemaComponent } from '../add-schema/add-schema.component';

@Component({
  selector: 'app-multiple-sheet-schema',
  templateUrl: './multiple-sheet-schema.component.html',
  styleUrls: ['./multiple-sheet-schema.component.css']
})
export class MultipleSheetSchemaComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() schemaResponse: any;
  @Input() hasSchema:boolean = false;

  @ViewChildren(AddSchemaComponent)
  sheets!: QueryList<AddSchemaComponent>;

  schema_arrays:Array<any>=[];
  previous_schema!: Array<schema_sheet>

  new_sheet_name:string | undefined;

  state = {
    'UPDATE':"update",
    'NO_CHANGE':"no-change",
    'DELETE':"delete"
  }

  constructor(private dialogRef: NbDialogRef<MultipleSheetSchemaComponent>, private dialogService:NbDialogService) { }
  ngOnDestroy(): void {
    this.schemaResponse = null
  }

  ngOnInit(): void {
    //console.log(this.schemaResponse);
    //seperate deleted sheets
    this.previous_schema = this.schemaResponse
    this.remove_deleted_sheets(this.schemaResponse)
  }

  ngAfterViewInit(): void {
    //console.log(this.schemaResponse,this.sheets);
  }


  close(){
    this.dialogRef.close();
  }

  request:any;
  submit(){
    this.schema_arrays = [];
    this.sheets.forEach(element => {
      element.submit();
      console.log(element.schema_request);
      this.schema_arrays.push(element.schema_request.schema);
    });
    this.add_deleted_sheets();
    this.request = {
      "state":this.state['UPDATE'],
      "schemaConfigs":{
        schemaDetails:this.schema_arrays
      }
    }
    console.log(this.request);
    this.dialogRef.close(this.request);
  }

  no_change(){
    this.schema_arrays = [];
    this.sheets.forEach(element => {
      element.submit();
      console.log(element.schema_request);
      this.schema_arrays.push(element.schema_request.schema);
    });
    this.request = {
      "state":this.state['NO_CHANGE'],
      "schemaConfigs":this.previous_schema
    }
    console.log(this.request);
    this.dialogRef.close(this.request);
  }

  delete(){
    this.request = {
      "state":this.state['DELETE'],
      "schemaConfigs":{
        schemaDetails:[]
      }
    }

    this.dialogRef.close(this.request);
  }

  addSheet(dialog: TemplateRef<any>){
    var sheet_name = 'sheet '+(this.schemaResponse.length + 1);
    if(this.schemaResponse.length == 0){
      sheet_name = 'Sheet1';
      this.schemaResponse.push({
        sheetName: sheet_name,
        match_type: "001"
      })
    }
    else{
      this.dialogService.open(dialog).onClose.subscribe((response:string)=>{
        sheet_name = response;
        if(sheet_name){
          this.schemaResponse.push({
            sheetName: sheet_name,
            match_type: '001'
          })
        }
      });
      this.new_sheet_name = undefined;
    }
  }

  get Sheets(){
    return this.schemaResponse
  }

  add_deleted_sheets(){
    for(let sheet of this.deleted_sheets)
      this.schema_arrays.push(sheet)
  }

  remove_deleted_sheets(sheet_array:Array<schema_sheet>){
    let i = 0
    for(let sheet of sheet_array){
      if(sheet?.action == schema_actions.delete){
        this.delete_sheet(i)
        i--
      }
      i++
    }
      
  }

  deleted_sheets:Array<schema_sheet> = []

  delete_sheet(index: number){
    
    let deleted_sheet:schema_sheet = this.Sheets[index];
    console.log(deleted_sheet);
    deleted_sheet['action'] = schema_actions.delete
    this.deleted_sheets.push(deleted_sheet);
    this.schemaResponse = this.Sheets.slice(0,index).concat(this.Sheets.slice(index+1));
  }

  schema_actions = schema_actions

}

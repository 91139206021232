import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigKeyModel, ConfigModel} from "../../models/config.model";
import {map, tap} from "rxjs/operators";
import {DataService, ServiceResponse} from "../core/data.service";
import {Observable, Subject, of} from "rxjs";
import {Context} from "../config/context.service";

export type ConfigServiceResponse = ServiceResponse<ConfigModel & { configs: Array<ConfigKeyModel>, parentConfigs: Array<ConfigKeyModel> }>;

@Injectable({
  providedIn: 'root'
})
export class ConfigKeyService implements DataService<ConfigKeyModel> {
  private readonly url: string

  constructor(private context: Context, private http: HttpClient) {
    this.url = context.getApiConfig().CONFIG_APIS.GET.nodeParams
  }

  public getConfigKey(id: number, startNode: number[]): Observable<ConfigServiceResponse> {
    return this.http.get<ConfigServiceResponse>(this.url + '?id=' + id + '&startNode='+startNode)
  }

  public clearNodeCache(nodeId: number) {
    console.error('clearNodeCache not implemented!')
  }
}

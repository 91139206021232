import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { resolve } from 'dns';
import { Subject, Subscription } from 'rxjs';
import { GroupService } from 'src/app/services/api/group.service';
import { PipelineService } from 'src/app/services/api/pipeline.service';
import { Groupv2Service } from 'src/app/services/api/v2/groupv2.service';
import { Group, Pipeline, Pipelinev2Service } from 'src/app/services/api/v2/pipelinev2.service';
import { filter, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from 'src/app/services/api/auth.service';
import { Accountv2Service } from 'src/app/services/api/v2/accountv2.service';

@Component({
  selector: 'app-pipeline-listing',
  templateUrl: './pipeline-listing.component.html',
  styleUrls: ['./pipeline-listing.component.scss']
})

export class PipelineListingComponent implements OnInit {
  hasAdminRole = false
  pipelineOptions:Array<any> = [
    {label:'History',icon:'history-line',type:'history'},
    {label:'Run',icon:'play-line',type:'run'},
  ]
  searchQueryChange: Subject<string> = new Subject<string>();
  searchQuery:string = ''
  subscriptions: Array<Subscription> = [];
  recentsCount=0
  totalCount=0
  pageIndex=0
  itemsPerPage=10
  pageSizeOptions=[10,20,25,30]

  isLoading = true
  pipelines: Array<any> = []
  recentPipelines: Array<any> = []
  filteredRecentPipelines: Array<any> = []
  groups: Array<Group> = []
  newPipeline: {
    name: string,
    label: string,
    groupId: string,
    description: string,
    creationType: 'custom' | string,
    tags: Array<string>,
    attributes: Array<{ key: string, value: string }>
  } = { name: '', label: '', groupId: '', description: '', creationType: 'custom', tags: [], attributes: [] }
  constructor(private router: Router, private pipelineService: Pipelinev2Service, private groupService: Groupv2Service, private authService:AuthService, private accountsService:Accountv2Service) { }

  async ngOnInit() {
    this.authService.accountId$.subscribe(accountId=>{
      if(accountId){
        this.accountsService.getUserInfoV2().subscribe((_data:any)=>{
          let role = _data.payload.accounts[accountId]?.role?.role || ""
          this.hasAdminRole = role === "rrole:admin"
          if(role === 'rrole:admin'){
            this.pipelineOptions = [
              {label:'Edit',icon:'user-settings-line',type:'edit'},
              {label:'Disable',icon:'file-copy-line',type:'disable'},
              {label:'History',icon:'history-line',type:'history'},
              {label:'Run',icon:'play-line',type:'run'},
              {label:'Delete',icon:'delete-bin-2-line',type:'delete'},
            ]
          }
          else{
            this.pipelineOptions = [
              {label:'History',icon:'history-line',type:'history'},
              {label:'Run',icon:'play-line',type:'run'},
            ]
          }
        })
      }
      
    })
    this.loadPipelines(this.itemsPerPage,this.pageIndex);
    let groupData = (await this.groupService.getGroups().toPromise()).payload;
    this.groups = groupData.data

    this.pipelineService.getPipelines({params:{itemsPerPage:10,pageNum:1}}).subscribe(data=>{
      let cnt = data.payload.totalCount;
      cnt>10 ? this.recentsCount=10 : this.recentsCount=cnt;
      this.recentPipelines = this.pipelineDataFormatter(data.payload.data);
      this.filteredRecentPipelines = this.recentPipelines
      this.isLoading = false
    })

    let subscription = this.searchQueryChange
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(filterQuery => {
        this.loadPipelines(this.itemsPerPage,this.pageIndex,filterQuery);
        this.loadRecents(filterQuery);
      });

    this.subscriptions.push(subscription)
  }

  ngAfterViewInit(): void {
  }
  convertTime(timestamp: any) {
    timestamp = timestamp * 1000;
    const date: any = new Date(timestamp);
    const currentTime: any = new Date();
    const timeDifference = currentTime - date;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursDifference = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    const minutesDifference = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    const secondsDifference = Math.floor((timeDifference % (1000 * 60)) / 1000);

    let updatedTime = '';
    if (daysDifference > 0) {
      updatedTime += `${daysDifference} day${daysDifference > 1 ? 's' : ''} `;
    }

    if (hoursDifference > 0) {

      updatedTime += `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} `;

    }

    // if (minutesDifference > 0) {

    //   updatedTime += `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''} `;

    // }

    // if (secondsDifference > 0) {

    //   updatedTime += `${secondsDifference} second${secondsDifference > 1 ? 's' : ''} `;

    // }

    if (!updatedTime) {

      updatedTime = 'less than a second';

    }
    return updatedTime;
  }

  loadPipelines(itemsPerPage:number,pageIndex:number,search:string=''){
    this.pipelineService.getPipelines({params:{itemsPerPage,pageNum:pageIndex+1,search_label:search}}).subscribe(data=>{
      this.pipelines = this.pipelineDataFormatter(data.payload.data);
      this.totalCount = data.payload.totalCount;
      this.pageIndex = data.payload.currentPage-1
      this.isLoading = false
    })
  }

  loadRecents(search:string=''){
    this.filteredRecentPipelines = this.recentPipelines.filter((pipeline) => {
      return pipeline.label.toLowerCase().includes(search.toLowerCase())
    })
    this.recentsCount = this.filteredRecentPipelines.length;
    this.isLoading = false
  }
  //api listen
  onPaginationEvent(e:any) {
    // console.log(e.detail)
    this.isLoading = true;
    this.loadPipelines(e.detail.itemsPerPage,e.detail.index,this.searchQuery);
  }

  pipelineDataFormatter(pipelines: Array<Pipeline> = []) {
    let arr = pipelines.map((pipeline) => {
      return {
        id: pipeline.id,
        label: pipeline.label,
        group: {
          label: pipeline.group.label
        },
        description:pipeline.description,
        status: pipeline.status.charAt(0).toUpperCase() + pipeline.status.slice(1),
        updatedTime: `${this.convertTime(pipeline.updatedTime)} ago`,
        trigger: pipeline.triggerName ?? "Custom",
        buildType: pipeline.buildType ?? "Custom Pipeline",
        runStatus:[pipeline?.process?.inProgress ?? `${(pipeline.id)%50}`, pipeline?.process?.success ?? `${(pipeline.id +5)%50}`, pipeline?.process?.failure ?? `${(pipeline.id +1)%50}`]
      }
    })
    return arr;
  }

  // viewPipeline(id: number) {
  //   let config = { edit: false }
  //   this.router.navigate(['/dashboard', id], { queryParams: { config: btoa(JSON.stringify(config)) } });
  // }

  viewPipelineDetails(pipeline: Pipeline) {
    this.router.navigate(["", { outlets: { view: ['pipeline-details', pipeline.id], action: null } }])
  }

  customEventsHandler(event: any) {
    console.log(event)
    if (event.detail.type === 'pipeline') {
      this.viewPipelineDetails(event.detail.pipeline)
    }
    else if (event.detail.type === 'run'){
      this.triggerPipeline(event.detail.pipeline.id)
    }
    else if(event.detail.type === 'edit') {
      this.router.navigate(["", { outlets: { view: ['pipeline-details', event.detail.pipeline.id], action: null } }], { queryParams: { edit: true } })
    }
    else if (event.detail.type === 'history') {
      this.router.navigate(["", { outlets: { view: ['pipeline-details', event.detail.pipeline.id], action: null } }], { queryParams: { show: "history" } })
    }
    else {
      this.viewPipelineDetails(event.detail.pipeline)
    }
  }

  modalVisible = false;
  currentModal = 1;
  async headerEventsHandler(e:any){
    if(e.detail.createNew==='custom') {
      this.modalVisible=true;
    }

    if(e.detail.type === 'create-pipeline') {
      const groups = await this.groupService.getGroups().toPromise()
      const options = groups.payload.data.map(x => ({label: x.label, value: x.id}))
      e.target?.showModal?.(e.detail.type, {
        groups: options,
        triggers: [{label: 'custom', value: 'custom'}, {label: 'manual', value: 'manual'}, {label: 'event', value: 'event'},],
      })
    }

    if(e.detail.type === 'import-pipeline') {

      e.target?.showModal?.(e.detail.type, {})
    }

    if(e.detail.type === 'template-pipeline') {
      this.router.navigateByUrl('/templates(view:view-templates)')
    }
  }

  createPipeline(event: any) {
    console.log(event);
    const newPipeline = event.detail?.payload

    let objj = newPipeline.attributes
    let attrObj:any = {}
    for(let obj of objj){
        let temp:any = Object.values(obj)
        attrObj[temp[0]] = temp[1]
    }
    const mappedData = {
      ...newPipeline,
      attributes:attrObj
    }
    this.pipelineService.createPipeline(mappedData).toPromise()
      .then(async res => {
        console.log(res)
        if(res.status === "success") {
          if(event.detail.setModal) {
            event.detail.setModal('created', {})
            setTimeout(() => {
              this.router.navigate(["", { outlets: { view: ['pipeline-details', res.payload.id], action: null } }])
            }, 1000);
          }
        }

        if(res.status === "failure") {
          const groups = await this.groupService.getGroups().toPromise()
          const options = groups.payload.data.map(x => ({label: x.label, value: x.id}))
      
          if(event.detail.setModal) {
            event.detail.setModal('basic', {
              groups: options,
              triggers: [{label: 'custom', value: 'custom'}, {label: 'manual', value: 'manual'}, {label: 'event', value: 'event'}],
            })
          }
        }
        
        // this.loadData()
      })
  }

  triggerPipeline(pipelineId: number){
    this.pipelineService.triggerPipeline(pipelineId).subscribe((response: any) => {
      const processId = response?.payload?.id;
      if (processId) {
        localStorage.removeItem('activeSection')
        this.router.navigate([{ outlets: { view: ['upload'] } }], {
          queryParams: { pipelineId: pipelineId, processId: processId }
        }); } else {
        throw new Error('Invalid response from trigger API');
      }
    });
  }

  onSearchQueryChange(event: any) {
    console.log('search')
    let searchVal = event.detail.detail.value
    this.searchQueryChange.next(searchVal); 
    this.searchQuery = searchVal
  }

  ngOnDestroy(){
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

}


<div class="container-fluid">
  <br>
  <label for="file-upload">
    <form action="" [formGroup]='uploadFile' (ngSubmit)="sendFile()">
      <label for="">
        <input type="file" formControlName="files" class="btn btn-sm">
      </label>
      <br>
      <br>
      <button type="submit" class="btn btn-primary d-flex" [disabled]="uploadFile.invalid">Upload</button>
    </form>
    </label>
</div>


<nb-card style="padding: 0;margin-bottom: 0;">
    <nb-card-body style="display: flex;justify-content: space-between;">
        <div style="display: flex;">

            <!-- <nb-actions size="tiny">
                <nb-action icon="power-outline"></nb-action>
                <nb-action icon="person-outline"></nb-action>
                <nb-action icon="home-outline"></nb-action>
            </nb-actions> -->
        </div>

        <div style="display: flex;">
            <nb-actions size="tiny">
                <nb-action icon="search-outline"></nb-action>
            </nb-actions>
            <nb-toggle [(ngModel)]="treeView" style="zoom: .5;"></nb-toggle>
        </div>
    
    </nb-card-body>
</nb-card>


<app-task-history [pipelineId]="pipelineId" *ngIf="!treeView"></app-task-history>

<div *ngIf="treeView" style="height: calc(100% - 4.5rem);padding: 1rem;">
    <div>
        <ul style="display: flex;list-style: none;padding: 0;margin: 0 0 0 auto;gap: 1rem;align-items: center;">
            <li class="color-pallet-item" >
                <span style="background-color: var(--node-success);display: inline-block;"></span>
                <span>success</span>
            </li>
            <li class="color-pallet-item" >
                <span style="background-color: var(--node-running);display: inline-block;"></span>
                <span>running</span>
            </li>
            <li class="color-pallet-item" >
                <span style="background-color: var(--node-error);display: inline-block;"></span>
                <span>error</span>
            </li>
        </ul>
    </div>
    <nb-card style="height: 100%;margin: 0;">
        <!-- <nb-card-header>You searched for:</nb-card-header> -->
        <nb-card-body style="padding: 0;">
          <div style="display: flex;height: 100%;">
            <div style="flex-grow: 2;">
                <svg style="position:relative;z-index:1;width:100%;height:100%">
                    <!-- <text 
                        [attr.x]="0" 
                        [attr.y]="svgConfig.fontsize" class="pipe-node-text" [attr.font-size]="svgConfig.fontsize">Node-One</text>
                 -->
                    <defs>
                        <pattern id="grid" width="1000" [attr.height]="svgConfig.fontsize+2*svgConfig.gap" patternUnits="userSpaceOnUse">
                        <path [attr.d]="'M 1000 0 L 0 0 0 '+svgConfig.fontsize+2*svgConfig.gap" fill="none" stroke="gray" stroke-width="1"/>
                        </pattern>
                    </defs>
                    <!-- <rect width="100%" height="100%" fill="url(#grid)" /> -->
                    
                    <ng-component  *ngTemplateOutlet="svgEdgeRenderer;context:{edges:edges, offset: leftGridOffset}"></ng-component>
                    <ng-component  *ngTemplateOutlet="svgGridRenderer;context:{grid:leftGrid, offset: leftGridOffset}"></ng-component>
                    <ng-component  *ngTemplateOutlet="svgGridRenderer;context:{grid:leftGridText, offset: leftGridOffset}"></ng-component>

                
                </svg>
            </div>
            <div style="flex-grow: 1;">
                <svg style="position:relative;z-index:1;width:100%;height:100%">
                    <defs>
                        <pattern id="grid" width="1000" [attr.height]="svgConfig.fontsize+2*svgConfig.gap" patternUnits="userSpaceOnUse">
                          <path [attr.d]="'M 1000 0 L 0 0 0 '+svgConfig.fontsize+2*svgConfig.gap" fill="none" stroke="gray" stroke-width="1"/>
                        </pattern>
                    </defs>
                    <!-- <rect width="100%" height="100%" fill="url(#grid)" /> -->
                    <!-- <text 
                        [attr.x]="10" 
                        [attr.y]="(svgConfig.fontsize+2*svgConfig.gap) * row" class="pipe-node-text" [attr.font-size]="svgConfig.fontsize">Hello</text> -->


                    <line [attr.x1]="((statusLineOffset.x)*(svgConfig.fontsize + svgConfig.gap*2))" [attr.y1]="((statusLineOffset.y+1)*(svgConfig.fontsize + svgConfig.gap*2))" [attr.x2]="((statusCount + statusLineOffset.x)*(svgConfig.fontsize + svgConfig.gap*2))" [attr.y2]="((statusLineOffset.y+1)*(svgConfig.fontsize + svgConfig.gap*2))" fill="none" stroke="gray" stroke-width="1" />
               
                    <ng-container *ngFor="let timeline of timelineSlots">
                        <text 
                            nbPopoverPlacement="bottom" 
                            nbPopoverTrigger="hover"
                            [nbPopover]="detailPopover"
                            [nbPopoverContext]="{row: -1, col: -1, popoverUI: {items:[{type: 'kayvalue', key: 'Run date', value: timeline.text}, {type: 'kayvalue', key: 'Scheduled date', value: timeline.text}]}}"
                            
                            [attr.x]="(timeline.column + statusLineOffset.x)*(svgConfig.fontsize + svgConfig.gap*2)" 
                            [attr.y]="(statusLineOffset.y)*(svgConfig.fontsize + svgConfig.gap*2)" class="pipe-node-text" 
                            [attr.font-size]="10+'px'" 
                            [attr.transform]="'rotate(-45, '+((timeline.column + statusLineOffset.x)*(svgConfig.fontsize + svgConfig.gap*2))+', '+((statusLineOffset.y)*(svgConfig.fontsize + svgConfig.gap*2))+')'">
                            {{timeline.text}}</text>
    
                        <line 
                            [attr.x1]="(timeline.column + statusLineOffset.x)*(svgConfig.fontsize + svgConfig.gap*2)" 
                            [attr.y1]="(statusLineOffset.y)*(svgConfig.fontsize + svgConfig.gap*2)" 
                            [attr.x2]="(timeline.column + statusLineOffset.x)*(svgConfig.fontsize + svgConfig.gap*2)" 
                            [attr.y2]="(statusLineOffset.y+1)*(svgConfig.fontsize + svgConfig.gap*2) " 
                            fill="none" stroke="gray" stroke-width="1" />
                    </ng-container>

                    <ng-component  *ngTemplateOutlet="svgGridRenderer;context:{grid:rightGrid, offset: rightGridOffset}"></ng-component>

                </svg>
            </div>
          </div>
        </nb-card-body>
    </nb-card>
</div>



<template>
    <svg>
        <ng-template #svgEdgeRenderer let-edges="edges">
            <ng-container *ngFor="let child of gridType(edges).gridData|keyvalue">
                <ng-container *ngFor="let edgeList of child.value|keyvalue">
                    <path *ngFor="let edge of edgeList.value" [attr.d]="getPath(edgeList.key, child.key, edge.x, edge.y)" 
                        class="pipe-edge" 
                        [attr.stroke-width]="highlightEdges[edge.id]?2:0" 
                        fill="none"
                        stroke="#afc8e0"
                        [class]="highlightEdges[edge.id]?'highlight-edge':''"
                    />
                </ng-container>
            </ng-container>
        </ng-template>
    </svg>
</template>

<template>
    <svg>
        <ng-template #svgGridRenderer let-grid="grid" let-offset="offset">
            
            <ng-container *ngFor="let row of gridType(grid).gridData|keyvalue">
                <g *ngFor="let col of row.value|keyvalue">

                    <ng-container [ngSwitch]="col.value.type">

                        <!-- <text 
                            *ngSwitchCase="'status-circle'"
                            [nbPopover]="col.value.props.name" nbPopoverPlacement="top" nbPopoverTrigger="hint"
                            [attr.x]="10" 
                            [attr.y]="(svgConfig.fontsize+2*svgConfig.gap) * (row.key + offset.y)" class="pipe-node-text" [attr.font-size]="svgConfig.fontsize">
                            {{col.value.type}}
                        </text> -->

                         <circle 
                            *ngSwitchCase="'status-circle'"
                            nbPopoverPlacement="bottom" 
                            nbPopoverTrigger="noop"
                            [nbPopover]="detailPopover"
                            [attr.data-popoverid]="''+col.value.props.popoverId"
                            [nbPopoverContext]="{row: row.key, col: col.key, popoverUI: col.value.props.popoverUI||{}, gridItem: col.value}"
                            [attr.cx]="(svgConfig.fontsize+2*svgConfig.gap) * (col.key + offset.x)" 
                            [attr.cy]="(svgConfig.fontsize+2*svgConfig.gap) * (row.key + offset.y)" 
                            [attr.r]="svgConfig.fontsize/2" [class]="col.value.props.class"
                            (click)="handelClick($event, col.value.props.onClick, {row: row.key, col: col.key, gridItem: col.value})"
                            [class]="col.value.props.class + ' ' + (highlightNode[col.value.props.nodeId]?'highlight-node': '')"
                            (mouseenter)="traceRoot($event, col.value.props.nodeId)"  (mouseleave)="resetHighlight($event)"
                            />
                    

                        <rect
                            *ngSwitchCase="'status-square'"
                            [nbPopover]="detailPopover" 
                            nbPopoverPlacement="top" 
                            nbPopoverTrigger="noop"
                            [attr.data-popoverid]="''+col.value.props.popoverId"
                            [nbPopoverContext]="{row: row.key, col: col.key, popoverUI: col.value.props.popoverUI||{}, gridItem: col.value}"
                            [attr.x]="(svgConfig.fontsize+2*svgConfig.gap) * (col.key + offset.x) - svgConfig.fontsize/2" 
                            [attr.y]="(svgConfig.fontsize+2*svgConfig.gap) * (row.key + offset.y) - svgConfig.fontsize/2"  
                            [attr.width]="svgConfig.fontsize" 
                            [attr.height]="svgConfig.fontsize" 
                            [class]="col.value.props.class + ' ' + (highlightNode[col.value.props.nodeId]?'highlight-node': '')"
                            (mouseenter)="traceRoot($event, col.value.props.nodeId, col.value.type==='status-square')"  (mouseleave)="resetHighlight($event)"
                            (click)="handelClick($event, col.value.props.onClick, {row: row.key, col: col.key, gridItem: col.value})" />

                        <text 
                            *ngSwitchCase="'text'"
                            nbPopoverPlacement="bottom" 
                            nbPopoverTrigger="noop"
                            [nbPopover]="detailPopover"
                            [nbPopoverContext]="{row: row.key, col: col.key, popoverUI: col.value.props.popoverUI||{}, gridItem: col.value}"
                            [attr.x]="(svgConfig.fontsize+2*svgConfig.gap) * (col.key + offset.x)" 
                            [attr.y]="(svgConfig.fontsize+2*svgConfig.gap) * (row.key + offset.y) + svgConfig.fontsize/2"
                            [class]="col.value.props.class + ' ' + (highlightNode[col.value.props.nodeId]?'highlight-node': '')"
                            [attr.font-size]="svgConfig.fontsize"
                            (mouseenter)="traceRoot($event, col.value.props.nodeId)"  (mouseleave)="resetHighlight($event)"
                            (click)="handelClick($event, col.value.props.onClick, {row: row.key, col: col.key, gridItem: col.value})">
                            {{col.value.props.text}}
                        </text>

                    </ng-container>

                </g>
            </ng-container>
        </ng-template>
    </svg>
</template>


<ng-template #detailPopover let-el>

    <div style="min-width:15rem;">
        <div *ngFor="let item of asPopoverUI(el.popoverUI).items">
            <ng-container [ngSwitch]="item.type">
    
                <ng-container *ngSwitchCase="'kayvalue'">
                    <div style="display: flex;gap: .5rem;align-items: center;padding: .2rem 1rem" [class]="item.class">
                        <code>{{item.key}}:</code>
                        <span *ngIf="getType(item.value) !== 'object'" (click)="handelClick($event, item.onClick, item)">{{item.value}}</span>
                        <ng-container *ngIf="getType(item.value) === 'object'" >
                            <span *ngFor="let el of item.value" (click)="handelClick($event, item.onClick, item, el)">{{el}}</span>
                        </ng-container>
                    </div>
                </ng-container>


                <ng-container *ngSwitchCase="'action-bar'">
                    <div style="display:flex;background-color: #eeeeee67;padding: .5rem 1rem;gap:.5rem;margin-top: .5rem;" [class]="item.class">

                        <nb-icon 
                            *ngFor="let action of item.actions"
                            [icon]="action.icon" 
                            style="color: green;cursor: pointer;" 
                            [title]="action.title"
                            [class]="action.class"
                            (click)="handelClick($event, action.onClick, action)"
                            >
                        </nb-icon>
                        
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="'action'">
                    <div style="background-color: #eeeeee1a;padding: 0rem 1rem;border-top: 1px solid white;cursor: pointer;" (click)="handelClick($event, item.onClick, item)" [class]="item.class">
                        <nb-icon *ngIf="item.icon" [icon]="item.icon" style="margin-right: .5rem;font-size: smaller"></nb-icon>
                        <a style="color: blue;font-size: 12px;" [title]="item.title">{{item.text}}</a>
                    </div>
                </ng-container>
    
            </ng-container>
        </div>
    </div>
    

    <!-- <div style="min-width:15rem;padding:1rem" data-type='detailPopover'>
        <div style="display: flex;gap: .5rem;align-items: center;">
            <code>Name:</code>
            <span>{{el.props.name}}</span>
        </div>
        <div style="display: flex;gap: .5rem;align-items: center;">
            <code>Status:</code>
            <span>{{el.props.status}}</span>
            <nb-icon icon="arrow-right-outline" style="margin-left: auto;color: green;cursor: pointer;" title="run"></nb-icon>
        </div>

        
    </div>
    <div style="display:flex;background-color: #eeeeee67;padding: .5rem 1rem;gap:.5rem">
        <nb-icon icon="sync-outline" style="color: green;cursor: pointer;" title="run"></nb-icon>
        <nb-icon icon="activity-outline" style="color: green;cursor: pointer;" title="run"></nb-icon>
        <nb-icon icon="flash-outline" style="color: green;cursor: pointer;" title="run"></nb-icon>
    </div>
    <div style="background-color: #eeeeee1a;padding: .5rem 1rem;border-top: 1px solid white;">
        <div>
            <a style="color: blue;font-size: 12px;cursor: pointer;">Run only current</a>
        </div>
        <div>
            <a style="color: blue;font-size: 12px;cursor: pointer;">Run with succession</a>
        </div>
    </div> -->
</ng-template>

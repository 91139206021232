import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbPopoverComponent,
  NbPopoverDirective,
  NbToastrService,
} from '@nebular/theme';
import { TaskHistoryStoreService } from 'src/app/services/store/task-history-store.service';
import { PipelineService } from 'src/app/services/api/pipeline.service';
import {
  Grid,
  GridItem,
  buildEdges,
  buildGraph,
  getPopoverUI,
} from './helper-functions';
import { Node } from 'src/app/models/tasknode.model';

interface TimelineSVGConfig {
  fontsize: number;
  // textSize: number;
  gap: number;
}

const defaultTimelineSVGConfig: TimelineSVGConfig = {
  fontsize: 12,
  // textSize: 12,
  gap: 2,
};
interface ActionHandler {
  (event: any, ...args: any): void;
}

export interface PopoverUI {
  items: Array<
    | { type: 'kayvalue'; key: string; value: string }
    | {
        type: 'action-bar';
        actions:
          | Array<{ onClick: string; icon: string; title?: string }>
          | {
              type: 'action';
              onClick: string;
              icon?: string;
              text: string;
              title?: string;
            };
      }
    | any
  >;
}
type TimelineItem = {
  column: number;
  text: string;
};

const initialOffset = {
  leftGridOffset: { x: 1, y: 6 },
  rightGridOffset: { x: 1, y: 5 },
  statusLineOffset:  { x: 1, y: 3 },
}

const copyObject = <T>(obj: Object):T => JSON.parse(JSON.stringify(obj))

@Component({
  selector: 'app-pipeline-history',
  templateUrl: './pipeline-history.component.html',
  styleUrls: ['./pipeline-history.component.css'],
})
export class PipelineHistoryComponent implements OnInit, OnDestroy {
  private _treeView = true;
  
  @Input() pipelineId: number = -1;
  @Input() get treeView(): boolean {
    return this._treeView;
  }

  set treeView(value) {
    let params = this.activatedRoute.snapshot.queryParams;
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: { ...params, treeView: value },
      })
      .toString();
    this._treeView = value;
    this.location.go(url);
  }

  svgConfig: TimelineSVGConfig = defaultTimelineSVGConfig;

  statusCount: number = 25; // number of columns in status row
  leftGridOffset = copyObject<{x: number, y: number}>(initialOffset.leftGridOffset)
  rightGridOffset = copyObject<{x: number, y: number}>(initialOffset.rightGridOffset)
  statusLineOffset = copyObject<{x: number, y: number}>(initialOffset.statusLineOffset)
  leftGrid: Grid<GridItem> = new Grid();
  leftGridText: Grid<GridItem> = new Grid();
  rightGrid: Grid<GridItem> = new Grid();
  edges: Grid<Array<{ x: number; y: number; id?: string }>> = new Grid();
  nodesMap: { [nodeId: string]: { x: number; y: number } } = {};
  taskNodes: { [nodeId: string]: any } = {};

  highlightNode: { [id: string]: boolean } = {};
  highlightEdges: { [id: string]: boolean } = {};

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  timelineSlots: Array<TimelineItem> = [
    // {
    //   column: 0,
    //   text: 'Fab 1, 01:30',
    // },
    // {
    //   column: 5,
    //   text: 'Fab 1, 01:55',
    // },
    // {
    //   column: 10,
    //   text: 'Fab 2, 00:30',
    // },
    // {
    //   column: 15,
    //   text: 'Fab 3, 06:15',
    // },
    // {
    //   column: 20,
    //   text: 'Fab 4, 01:30',
    // },
    // {
    //   column: 25,
    //   text: 'Fab 4, 03:19',
    // },
    // {
    //   column: 30,
    //   text: 'Fab 5, 03:19',
    // },
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialogService: NbDialogService,
    private router: Router,
    private location: Location,
    private toastrService: NbToastrService,
    private taskHistoryStoreService: TaskHistoryStoreService,
    private taskHistory: TaskHistoryStoreService,
    private pipelineService: PipelineService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      let { pipelineId, processId, treeView } = params;
      this.treeView = treeView === 'true';
      if(pipelineId) {
        this.taskHistory.pipelineId = parseInt(pipelineId || '-2')
        this.pipelineId = this.taskHistory.pipelineId;
      }
    });
    this.activatedRoute.paramMap.subscribe((route) => {
      if(route.get('id')) {
        this.taskHistory.pipelineId = parseInt(route.get('id') || '-2')
        this.pipelineId = this.taskHistory.pipelineId;
      }
    })
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.onClick);
    document.removeEventListener('scroll', this.onScroll);
  }

  onClick = (event: any) => {
    if (
      this.currentPopupElement &&
      (event.target as HTMLElement).closest('[data-type=detailPopover]')
    ) {
      return;
    }

    if (
      this.currentPopupElement &&
      (this.currentPopupElement as any).hostRef.nativeElement &&
      (this.currentPopupElement as any).hostRef.nativeElement !== event.target
    ) {
      (this.currentPopupElement as any).hostRef.nativeElement.classList.remove(
        'popover-show'
      );
      this.currentPopupElement.hide();
      this.currentPopupElement = undefined;
    }
  };

  onScroll = (event: any) => {
    let dx = -1;
    let dy = -1;

    let direction: 'x' | 'y' = 'x';

    if (Math.abs(event.deltaX) < Math.abs(event.deltaY)) direction = 'y';

    let weight = direction === 'x' ? event.deltaX : event.deltaY;
    if (event.shiftKey) direction = direction === 'x' ? 'y' : 'x';

    // scroll in X
    if (direction === 'x') {
      this.leftGridOffset.x += dx * weight;
    }

    // scroll in Y
    if (direction === 'y') {
      this.rightGridOffset.y += dy * weight;
      this.leftGridOffset.y += dy * weight;
      this.statusLineOffset.y += dy * weight;
    }

    // console.log(event.deltaY > 0, initialOffset.rightGridOffset.y, this.rightGridOffset.y)
    if (this.rightGridOffset.y >= initialOffset.rightGridOffset.y) {
      this.rightGridOffset.y = initialOffset.rightGridOffset.y;
      this.leftGridOffset.y = initialOffset.leftGridOffset.y;
      this.statusLineOffset.y = initialOffset.statusLineOffset.y;
    }

    if (this.leftGridOffset.x <= initialOffset.leftGridOffset.x) {
      this.leftGridOffset.x = initialOffset.leftGridOffset.x;
    }
  };

  commandHandlers: { [commandName: string]: ActionHandler } = {};
  ngOnInit(): void {
    document.addEventListener('click', this.onClick);

    // document.addEventListener('scroll', this.onScroll);
    document.addEventListener('wheel', this.onScroll);

    this.loadData();

    this.commandHandlers['goto-parent'] = (
      event: any,
      element: { key: string; onClick: string; type: string; value: string },
      el: string
    ) => {
      this.openPopoverById(`${el}-left`);
    };

    this.commandHandlers['handle-status-node-click'] = (
      event: any,
      element: { row: number; col: number; gridItem: GridItem }
    ) => {
      let popoverUI = getPopoverUI(this, element);

      element.gridItem.props.popoverUI = popoverUI;
      this.openPopover(event.target);
    };

    this.commandHandlers['handle-tree-node-click'] = (
      event: any,
      element: { row: number; col: number; gridItem: GridItem }
    ) => {
      let popoverUI = getPopoverUI(this, element);

      element.gridItem.props.popoverUI = popoverUI;
      this.openPopover(event.target);
    };
  }

  async loadData() {

    
    const pipelineDetails = await this.pipelineService
      .getPipeline(this.pipelineId)
      .toPromise();
    const taskNodes = pipelineDetails.nodeInstances;
    let nodesCache = taskNodes.reduce((prev, curr) => {
      prev[curr.id] = curr
      return prev
    }, {} as {[id: string]: Node})
    console.log(taskNodes);

    let nodes = buildGraph(taskNodes as any);

    const response = await this.taskHistoryStoreService.getPipelineHistory(nodes);

    let nodesMap: { [nodeId: string]: { x: number; y: number } } = {};
    
    this.taskNodes = taskNodes;

    const tempGrid = new Grid<GridItem>();
    const tempTextGrid = new Grid<GridItem>();
    const tempStatusGrid = new Grid<GridItem>();

    console.log(response.payload);

    const pipelineStatusRecords = response.payload.processRun

    this.timelineSlots = []

    for(let index=0; index < pipelineStatusRecords.length; index++) {
      
      if(index % 5 === 0) {
        this.timelineSlots.push({
          column: index,
          text: pipelineStatusRecords[index].scheduledTime,
        })
      }

      tempStatusGrid.addItem(0, index, {
        type: 'status-circle',
        props: {
          processId: pipelineStatusRecords[index].processId,
          // name: 'Status node',
          class: 'pipe-node',
          status: pipelineStatusRecords[index].processStatus,
          onClick: 'handle-status-node-click',
          scheduledTime: pipelineStatusRecords[index].scheduledTime,
          executionTime: pipelineStatusRecords[index].executionTime
        },
      });

      const nodeStatus = pipelineStatusRecords[index].nodes
      for (let nodeIndex = 0; nodeIndex < nodes.length; nodeIndex++) {
        let nodeId = nodes[nodeIndex]
        if(!nodeStatus[nodeId]) continue;
        tempStatusGrid.addItem(nodeIndex + 1, index, {
          type: 'status-square',
          props: {
            processId: pipelineStatusRecords[index].processId,
            nodeId: nodeId,
            name: this.taskNodes[nodeId].name,
            class: `status-node status-${nodeStatus[nodeId].status}`,
            status: nodeStatus[nodeId].status,
            onClick: 'handle-status-node-click',
            executionTime: nodeStatus[nodeId].executionTime,
          },
        });
      }
    }

    for (let i = 0; i < nodes.length; i++) {
      let x = 8;
      let y = i;

      nodesMap[nodes[i]] = { x, y };

      const element: GridItem = {
        type: 'status-circle',
        props: {
          nodeId: nodesCache[nodes[i]].id,
          name: nodesCache[nodes[i]].name,
          class: 'pipe-node',
          status: 'success',
          onClick: 'handle-tree-node-click',
          date: 'Fab 1, 01:30',
          popoverId: `${nodesCache[nodes[i]].id}-left`,
        },
      };
      element.props.popoverUI = getPopoverUI(this, {
        row: y,
        col: x,
        gridItem: element,
      });

      const text: GridItem = {
        type: 'text',
        props: {
          text: nodesCache[nodes[i]].name,
          nodeId: nodesCache[nodes[i]].id,
          class: 'text-node',
          name: 'Status node',
          onClick: 'show-node-details'
        },
      };

      tempGrid.addItem(y, x, element);
      tempTextGrid.addItem(y, x + 1, text);
    }

    this.leftGrid.replaceGrid(tempGrid);
    this.leftGridText.replaceGrid(tempTextGrid);
    this.rightGrid.replaceGrid(tempStatusGrid);
    this.nodesMap = nodesMap;

    this.edges = buildEdges(taskNodes as any, nodes, nodesMap);
  }

  gridType(grid: any) {
    return grid as Grid<any>;
  }

  getPath(x1: number, y1: number, x2: number, y2: number) {
    let dx = x1 - x2;
    let dy = y1 - y2;

    let d = this.svgConfig.fontsize + 2 * this.svgConfig.gap;

    x2 = (x2 + this.leftGridOffset.x) * d;
    y2 = (y2 + this.leftGridOffset.y) * d;

    dx *= d;
    dy *= d;

    // return `M ${x2} ${y2} q ${dx} ${dy/3} ${dx} ${dy}`;
    return `M ${x2} ${y2} q ${-dy / 2} ${dy / 2} ${dx} ${dy}`;
  }

  @ViewChildren(NbPopoverDirective) popups!: QueryList<NbPopoverDirective>;
  currentPopupElement?: NbPopoverDirective;
  handelClick(event: any, command: string, element: any, ...args: any) {
    event.stopPropagation();

    console.log(command, element.args ? element.args : element);

    if (command && this.commandHandlers[command]) {
      this.commandHandlers[command](event, element, ...args);
    }

    if (command && !this.commandHandlers[command]) {
      this.toastrService.show(
        `User action [${command}] not implemented.`,
        `Invalid action`,
        {
          position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
          duration: 5000,
          icon: element?.icon || 'alert-triangle-outline',
          status: 'basic',
        }
      );
    }
  }

  openPopover(element: HTMLElement) {
    if (this.currentPopupElement) this.currentPopupElement.hide();

    for (let popover of this.popups) {
      if (
        (popover as any).hostRef.nativeElement === element &&
        !popover.isShown
      ) {
        this.currentPopupElement = popover;
        (popover as any).hostRef.nativeElement.classList.add('popover-show');
        popover.show();
      } else {
        (popover as any).hostRef.nativeElement.classList.remove('popover-show');
        popover.hide();
      }
    }
  }

  openPopoverById(popoverId: string) {
    let el = document.querySelector(`[data-popoverid="${popoverId}"]`);
    if (el) {
      this.openPopover(el as HTMLElement);
    }
  }

  asPopoverUI(data: any) {
    return data as PopoverUI;
  }

  getType(data: any) {
    return typeof data;
  }

  traceRoot(event: any, nodeId: number, firstNode:boolean=false) {
    if (!nodeId) return;

    if(firstNode) {
      this.highlightNode = {[nodeId]: true}
      return
    }

    let trace: Array<number> = [];

    let Q = [nodeId];
    let v: any = {};
    let edges: any = {};

    while (Q.length > 0) {
      let n = Q[0];
      Q.shift();
      if (v[n]) continue;

      v[n] = true;
      trace.push(n);

      let children = (this.taskNodes[n]?.incoming_task_with_weights || []).map(
        (x: any) => x.taskId
      );
      Q = Q.concat(children);
      children.forEach((parentId: number) => {
        edges[`${parentId}-${n}`] = true;
        edges[`${n}-${parentId}`] = true;
      });
    }

    this.highlightNode = v;
    this.highlightEdges = edges;
  }

  resetHighlight(event: any) {
    this.highlightNode = {};
    this.highlightEdges = {};
  }
}

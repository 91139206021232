import { Injectable, Pipe } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Context } from '../../config/context.service';
import { ServiceResponse } from '../../core/data.service';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { Topic } from 'src/app/models/topic.model'

export type TopicResponse = { data: Array<Topic> }
@Injectable({
    providedIn: 'root'  
})
export class Topicv2Service{
    constructor(private context: Context, private http: HttpClient){
    }
    getTopicDetails(params: {[key: string]:string|number| undefined, pageNum?: number, itemsPerPage?: number, search?: string}){
        return this.http.get<ServiceResponse<TopicResponse>>(this.context.getApiConfig().TOPICS_APIS.GET.topicDetails, {params: JSON.parse(JSON.stringify(params))})
    }
    createTopic(data: any){
        return this.http.post(this.context.getApiConfig().TOPICS_APIS.POST.topicInstance, data)
    }
    updateTopic(topicId:string, data:any){
        return this.http.put(this.context.getApiConfig().TOPICS_APIS.PUT.topicUpdateInstance(topicId), data)
    }
    updateAccessLevel(topicId:string, data:any){
        return this.http.post(this.context.getApiConfig().TOPICS_APIS.POST.updateAccessInstance(topicId), data)
    }
}
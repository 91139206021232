<div style="height: 100%;padding: 1rem;">

      <!-- <label class="search-label" for="search">Search Pipeline:</label> -->
  
  
      <div style="width: 100%;height: 4rem;background-color: white;display: flex;align-items: center;padding: 0 1rem;box-shadow: 0px 1px 3px -2px lightgrey;position: sticky;top:3rem;z-index: 1;">
        <div class="left-toolbar" style="display: flex;align-items: center;height: 100%;gap: .5rem;">
          <!-- <input nbInput [nbFilterInput]="dataSource" id="search" title="Search Pipeline by name" placeholder="Pipeline name" class="search-input mat-background-primary"> -->
          <!-- <input type="text" [nbFilterInput]="dataSource" style="border: 1px solid lightgray;padding: 0.4rem 0.5rem;" placeholder="Search by name."> -->
          <input (keyup)="onFilterTextChanged($event)" [(ngModel)]="pageQuery"  nbInput fieldSize="small" placeholder="Small"  id="search" title="Search Pipeline by name" placeholder="Search by name." class="search-input mat-background-primary">
          <nb-select   (selectedChange)="filterGroupBased()" [(ngModel)]="group_name" >
            <nb-option >None</nb-option>
            <nb-option [value]="group.name" *ngFor="let group of grouplist">{{group.name}}</nb-option>
          </nb-select>
        </div>
        <div style="margin-left: auto;"></div>
        <div class="right-toolbar">
          
          <mat-paginator
              [length]="totalCount"
              [pageSize]="pageSize"
              [pageIndex]="pageIndex"
              [pageSizeOptions]="[5, 10, 20, 50, 100]"
              [showFirstLastButtons]="true"
              aria-label="Select page"
              (page)="pageChange($event)">
          </mat-paginator>

        </div>
      </div>




      <div style="height: calc(100vh - 9rem);overflow:auto;">
        <table [nbTreeGrid]="dataSource" style="width:-webkit-fill-available">
          <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumns"></tr>
          <tr nbTreeGridRow [clickToToggle]="false" *nbTreeGridRowDef="let row; columns: allColumns"></tr>
    
            <ng-container
              *ngFor="let column of defaultColumns"
              [nbTreeGridColumnDef]="column"
            >
              <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
                <span style="color:var(--bs-blue);">{{ defaultNames[column] }}</span>
              </th>

              
      
              <ng-container *ngIf="(column !== 'action') && (column !== 'pipeline_run')">
                <td nbTreeGridCell *nbTreeGridCellDef="let row">
    
                  <ng-container [ngSwitch]="column">
                    
                    <span *ngSwitchCase="'created_time'">
                      {{row.data[column]|date: "medium"}}
                    </span>
    
                    <span *ngSwitchCase="'name'" (click)="viewPipeline(row.data.id)">
                      {{row.data[column]}}
                    </span>

                    <span *ngSwitchCase="'index'">
                      <label class="switch" style="zoom: .7;">
                        <input type="checkbox" checked>
                        <span class="slider round"></span>
                      </label>
                    </span>
                    
                    <span *ngSwitchCase="'group'" (click)="viewPipeline(row.data.id)">
                      {{row.data[column]?.name}}
                    </span>
    
                    <span *ngSwitchDefault>
                      {{row.data[column]}}
                    </span>
                  </ng-container>
                </td>
              </ng-container>
              <ng-container *ngIf="column === 'action'">
                <td nbTreeGridCell *nbTreeGridCellDef="let row">
                  <nb-button-group ghost>
                    <button
                    nbTooltip="Run Pipeline"
                    nbTooltipPlacement="top"
                    nbButtonToggle
                    [disabled]="isProgress.get(row.data.id)"
                    class="btn-success"
                    (click)="runPipeline(row.data.id)">
                    <nb-icon status="success"  icon="play-circle-outline"></nb-icon>
                  </button>
                  
                    <button
                      nbTooltip="Open"
                      nbTooltipPlacement="top"
                      nbButtonToggle
                      class="btn-primary"
                      (click)="viewPipeline(row.data.id)">
                      <nb-icon status="info" icon="eye-outline"></nb-icon>
                    </button>
                    <button
                      nbTooltip="Edit"
                      nbTooltipPlacement="top"
                      nbButtonToggle
                      class="text-italic"
                      (click)="editPipeline(row.data.id)"
                    >
                      <nb-icon status="warning" icon="edit-2-outline"></nb-icon>
                    </button>
                    <button
                      nbTooltip="History"
                      nbTooltipPlacement="top"
                      nbButtonToggle
                      class="text-italic"
                      (click)="viewPipelineHistory(row.data.id)">
                      <nb-icon status="warning" icon="cast-outline"></nb-icon>
                    </button>
                    <button
                      nbTooltip="Delete"
                      nbTooltipPlacement="top"
                      nbButtonToggle
                      class="btn btn-warning"
                      (click)="delete(row.data.id)">
                      <nb-icon status="danger" icon="trash-2-outline"></nb-icon>
                    </button>
                    <button
                      nbTooltip="More options"
                      nbTooltipPlacement="top"
                      nbButtonToggle
                      class="btn btn-basic"
                      [nbContextMenu]="menu_items"
                      nbContextMenuTrigger="click"
                      nbContextMenuTag="my-context-menu"
                      (click)="moreOptionsMenu(row.data.id)">
                      <nb-icon status="basic" icon="more-vertical-outline"></nb-icon>
                    </button>
                  </nb-button-group>
                </td>
              </ng-container>

              <ng-container *ngIf="column === 'pipeline_run'">
                <td nbTreeGridCell *nbTreeGridCellDef="let row">
                  <ul class="pipeline-status-list">
                    <li style="--color: #1ef917">A</li>
                    <li>Bb</li>
                    <li>A</li>
                  </ul>
                </td>
              </ng-container>
            </ng-container>
          </table>

          <nb-card style="margin-top: 1rem;" *ngIf="data.length===0">
            <nb-card-body>
              <span>{{uiService.isContentLoading ? 'Loading...' : 'No data found.'}}</span>
            </nb-card-body>
          </nb-card>
      </div>

     

</div>


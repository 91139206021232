import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, interval} from "rxjs";
import {Context} from "../config/context.service";
import {HttpClient} from "@angular/common/http";
import {ServiceResponse} from "../core/data.service";
import {Subscription} from "rxjs/internal/Subscription";

export type Point = {
  x: number,
  y: number
}
export type TaskPositionRequest = {
  "pipelineId" : number,
  "position" : {[nodeId: string]: Point}
}

export type TaskPositionResponse = {
  "id":number
  "task_id" : number,
  "pipelineId" : number,
  "position" : string,
  "created_time"?: unknown,
  "updated_time"?: unknown
}


@Injectable({
  providedIn: 'root'
})

export class NodePositionService {

  private readonly task_position_url: string = ''
  private readonly all_tasks_position_url: string = ''

  private readonly _nodePositionSource = new BehaviorSubject<Array<TaskPositionResponse>>([])

  private config = {
    params: {},
    delay: 30*1000
  }

  private sub: Subscription | null = null

  readonly nodesPosition$ = this._nodePositionSource

  constructor(private context: Context, private http: HttpClient) {     
    this.task_position_url = context.getApiConfig().TASK_APIS.GET.getTaskPosition
    this.all_tasks_position_url = context.getApiConfig().TASK_APIS.GET.getAllTaskPositions
  }

  getTaskPositionById(id:number|string){
    return this.http.get(`${this.task_position_url}?taskId=${id}`);
  }

  // getTaskPositionByPipelineId(id:number){
  //   return this.http.get(`${this.all_tasks_position_url}?pipelineId=${id}`);
  // }

  getNodesPosition(params: {pipelineId: number}) {
    return this.http.get<ServiceResponse<{ id: number, pipeline_id: number, position: {[nodeId: string]: Point} }>>(this.context.getApiConfig().TASK_APIS.GET.getTaskPosition, {params});
  }

  saveNodesPosition(data:TaskPositionRequest) {
    return this.http.post(this.context.getApiConfig().TASK_APIS.POST.taskposition, data);
  }

  deleteTaskPosition(taskId:number){
    return this.http.delete(this.task_position_url+"?taskId="+taskId);
  }

  connect(params: {pipelineId:number}, delay?: number) {
    Object.assign(this.config.params, params)
    this.run()
  }

  private run() {
    this.stop()
    this.fetch()
    this.sub = interval(this.config.delay).subscribe(x => {
      this.fetch()
    })
  }

  private fetch() {
    this.http.get(this.context.getApiConfig().TASK_APIS.GET.getTaskPosition, {params: this.config.params}).toPromise().then((res:any) => {
      if(!res) return;
      if(res.status === 'failure') {
        this._nodePositionSource.next([])
      } else {
        this._nodePositionSource.next(res.payload)
      }
    })
    .catch((error)=>{
      this.stop();
    })
  }


  public stop() {
    if(this.sub !== null) {
      this.sub.unsubscribe()
      this.sub = null
    }
  }
}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { PipelineService } from '../services/api/pipeline.service';
import { GroupService } from '../services/api/group.service';


@Component({
  selector: 'app-web-component',
  templateUrl: './web-component.component.html',
  styleUrls: ['./web-component.component.css']
})
export class WebComponentComponent implements OnInit, AfterViewInit {

  pipelines: Array<{name: string, id: number, group_id: number}> = []
  groups: {[key: number]: any} = {}

  constructor(private router: Router, private pipelineService: PipelineService, private groupService: GroupService) { }

  ngOnInit(): void {

    // this.router.events.subscribe((event) => {
      document.body.classList.remove('nb-theme-default');
    // });
  }

  ngAfterViewInit(): void {
      this.loadData()
  }

  private async loadData() {
    const groups  = await this.groupService.listGroups().toPromise();
    const pipelineResponse = await this.pipelineService.getList({ params: {  } }).toPromise();

    this.groups = (groups as unknown as Array<{id: number, name: string}>).reduce((prev, curr) => {
      prev[curr.id] = curr
      return prev
    }, {} as any);
    this.pipelines = pipelineResponse.payload.data

    console.log(this.groups, this.pipelines)
  }



  viewPipeline(id: number) {
    let config = { edit: false }
    this.router.navigate(['/dashboard', id], { queryParams: { config: btoa(JSON.stringify(config)) } });
  }
}

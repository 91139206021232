import { Portal } from '@angular/cdk/portal';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SideNavService } from '../services/core/sidenav.service';

@Component({
  selector: 'app-action-area',
  templateUrl: './action-area.component.html',
  styleUrls: ['./action-area.component.scss']
})
export class ActionAreaComponent implements OnInit {
  portal$:any ;

  constructor(public portalBridge: SideNavService) {}

  ngOnInit(): void {
    // this.portal$ = this.portalBridge.portal$;
  }

}

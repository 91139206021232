import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Context} from "../config/context.service";
import {ServiceResponse} from "../core/data.service";

@Injectable({
  providedIn: 'root'
})
export class ConfigureApiService {

  base_url:string= '';
  constructor(
    private http:HttpClient,
    private context: Context,) {
    this.base_url = context.getApiConfig().apiUrl + '/'
  }

  createNewConnection(data:any) {
  	// data:{
  	//   "identifier": "program_microservice",
  	//   "client_id": "simplifyvms123",
  	//   "ip":"127.0.0.1",
  	//   "port":8080,
  	//   "username":"",
  	//   "password":""
  	//   }
  	return this.http.post<{payload: any, status:string}>(`${this.base_url}pipeline/v1/api/newConnection`,data);
  }


  editConnection(id:number,data:any){
    // data:{
    //   "identifier": "program_microservice",
    //   "client_id": "simplifyvms123",
    //   "ip":"127.0.0.1",
    //   "port":8080,
    //   "username":"",
    //   "password":""
    //   }
    return this.http.put<{payload: any, status: string}>(`${this.base_url}pipeline/v1/api/newConnection?connectionId=`+ id ,data);
  }



  createNewApi(data:any){
  	// {
  	//   "clientId":"simplifyvms123",
  	//   "name": "ProgramAPI",
  	//   "url": "/simplifyvms/proagram",
  	//   "connection_identifier": "program_microservice", // either pass identifier or connection_id
  	//   "connection_id": 12345,
  	//   "is_secure": false, //true for https
  	//   "method": "POST",
  	//   "apiKeys": [
  	//    {
  	//    "key":"clientID",
  	//    "dataType":"String",
  	//    "isMandatory":true,
  	//    "comment":"client Id . Has to be unique"
  	//    },
  	//    {
  	//    "key":"ProgramID",
  	//    "dataType":"String",
  	//    "isMandatory":true,
  	//    "comment":"program Id . Has to be unique"
  	//    }
  	//   ]
  	//   }
  	return this.http.post(`${this.base_url}pipeline/v1/apiSink/newApi`,data);
  }

  getAPIList(){
  	return this.http.get(`${this.base_url}pipeline/v1/apiSink/allApis`);
  }

  getAPIById(id:any){
  	return this.http.get(`${this.base_url}pipeline/v1/apiSink/apiDetails?api_id=${id}`);
  }

  getNodeSchema(data:any) {
  	return this.http.post<ServiceResponse<any>>(`${this.base_url}pipeline/v1/nodeSchema`,data);
  }

  getConnection(){
	  return this.http.get<{payload: Array<any>}>(`${this.base_url}pipeline/v1/api/getallconnection`);
  }


}

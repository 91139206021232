<div #gridArea>
	<ag-grid-angular
		#agGrid [style.height]="tableHeight+'px'"
		class="ag-theme-alpine grid-table"
		[gridOptions]="gridOptions"
		[rowData]="rowData"
		[columnDefs]="columnDefs"
		[defaultColDef]="defaultColDef"
		(gridReady)="onGridReady($event)"
		(firstDataRendered)="onFirstDataRendered($event)"
		[rowSelection]="'multiple'"
		[enableRangeSelection]="true"
		(rowSelected)="onRowSelected($event)"
		(columnPinned)="onColumnPinned($event)"
		(filterChanged)="onFilterChanged($event)"
		
		>
  </ag-grid-angular>
</div>


<div id="context-menu" #menu>
  <div class="item" (click)="copyLastSelectedCell()">
    <div><mat-icon aria-hidden="false" aria-label="Example home icon">content_copy</mat-icon></div>
    <div>Copy</div>
    <div style="color: gray;margin-left: auto">Ctrl+C</div>
  </div>
  <div class="item" (click)="copyLastSelectedRow()">
    <div><mat-icon aria-hidden="false" aria-label="Example home icon">content_copy</mat-icon></div>
    <div>Copy with header</div>
    <div style="color: gray;margin-left: auto">Ctrl+Alt+C</div>
  </div>
  <hr style="margin: 0">
  <div class="item">
    <div><nb-icon icon="download-outline"></nb-icon> </div>
    <div>Export</div>
  </div>
</div>

<div class="ag-popup-wrapper" #agOptionMenu *ngIf="currentMenu?.show"
	style="position:absolute; min-width: 181px; left: 200px; top: 3rem;" [ngStyle]="{'left.px': currentMenu.left - 250 }">
	<div class="ag-theme-alpine ag-popup">
		<div role="presentation" class="ag-ltr ag-popup-child" style="min-width: 181px;">

			<div ref="eBody" role="presentation" class="ag-tabs-body ag-menu-body">
				<div class="ag-menu-list ag-focus-managed" role="tree">
					<div class="ag-tab-guard ag-tab-guard-top" role="presentation" tabindex="0"></div>
					<div *ngIf="true" class="ag-menu-option" (mouseenter)="showDependentMenu($event, 'dataTypeMenu')" tabindex="1" role="treeitem"
						aria-expanded="false" aria-level="1">
						<span ref="eName" class="ag-menu-option-part ag-menu-option-text">Change Data Type
						</span>
						<span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer">
							<span class="ag-icon ag-icon-small-right" unselectable="on" role="presentation">
							</span>
						</span>
					</div>
					<!-- <div class="ag-menu-option" (mouseenter)="showDependentMenu($event, 'pinMenu')" tabindex="2" role="treeitem"
						aria-expanded="false" aria-level="2">
						<span ref="eName" class="ag-menu-option-part ag-menu-option-text">Pin Column
						</span>
						<span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer">
							<span class="ag-icon ag-icon-small-right" unselectable="on" role="presentation">
							</span>
						</span>
					</div>
					<div *ngIf="true" class="ag-menu-option" (mouseover)="hideDependentMenu($event)" tabindex="3" role="treeitem" aria-level="3"
						(click)="deleteColumn()">
						<span ref="eName" class="ag-menu-option-part ag-menu-option-text">Delete Column
						</span>
						<span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer">
							<span class="" unselectable="on" role="presentation">
							</span>
						</span>
					</div> -->
				</div>
			</div>
		</div>
	</div>
	<!-- data type option dependent menu -->
	<div #agOptionDependentMunu (mouseleave)="hideDependentMenu($event)"
		class="ag-theme-alpine ag-popup ag-popup-popup-right" style="position:absolute; width: 200px;"
		*ngIf="currentMenu?.dependentMenu?.show" [ngStyle]="{'top.px':
		currentMenu.dependentMenu.top + 30 }">
		<div class="ag-ltr ag-popup-child" role="presentation">
			<div ref="eBody" role="presentation" class="ag-tabs-body ag-menu-body">
				<div class="ag-filter">
					<div *ngIf="currentMenu?.dependentMenu?.menuMap?.dataTypeMenu">
						<div class="form-check" *ngFor="let rs of dataTypeOptionsView; let i = index">
							<input class="form-check-input" type="radio" name="dataType" [value]="rs?.value"
								id="dataType-{{i}}" (change)="changeDataType($event)">
							<label class="form-check-label" for="dataType-{{i}}">
								{{rs?.label}}
							</label>
						</div>
					</div>
					<div *ngIf="currentMenu?.dependentMenu?.menuMap?.pinMenu">
						<div class="form-check " *ngFor="let rs of pinMenuOptions; let i = index"
							(click)="!rs.checked ? pinColumn(rs?.value) : null">
							<span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation">
								<span *ngIf="rs?.checked" class="ag-icon ag-icon-tick" unselectable="on" role="presentation"></span>
							</span>
							<span ref="eName" class="ag-menu-option-part form-check-label">{{rs?.label}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

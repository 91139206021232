import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
  Inject,
  HostListener
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  BodyScrollEvent,
  CellClickedEvent,
  GridOptions,
  RowClickedEvent, RowSelectedEvent,
  SelectionChangedEvent
} from 'ag-grid-community';
import { HeaderComponent } from 'ag-grid-community/dist/lib/components/framework/componentTypes';
import { inputCursor } from 'ngx-bootstrap-icons';
import { BehaviorSubject, Observable } from 'rxjs';
import { DetailCellRendererComponent } from 'src/app/components/detail-cell-renderer/detail-cell-renderer.component';
import { TableHeaderComponent } from './table-header/table-header.component';


export type FilterType = {
  filter:string,
  filterType:string,
  type:string
}

@Component({ 
  selector: 'app-output-table',
  templateUrl: './output-table.component.html',
  styleUrls: ['./output-table.component.scss'],
  host: {
    '(document:click)': 'onMenuClick($event)',
    '(document:wheel)': 'onMenuWheel($event)',
  },
})
export class OutputTableComponent implements OnInit, OnChanges {

  @Input() previewData: Object | undefined;
  @Input() permissionConfig: Object | undefined;
  @Input() height!:Observable<number>
  @ViewChild('agGrid') agGrid: AgGridAngular | undefined;
  @ViewChild('gridArea') gridArea: ElementRef | undefined;

  // @ViewChild('agGrid', undefined) agGrid: AgGridAngular | undefined;
  @ViewChild('agOptionMenu', { read: ElementRef, static: false }) agOptionMenu: ElementRef | undefined;
  @ViewChild('agOptionDependentMunu', { read: ElementRef, static: false }) agOptionDependentMunu: ElementRef | undefined;
  @ViewChild('menu') contextMenu: any

  // @Output() Save = new EventEmitter();
  // @Output() Cancel = new EventEmitter();
  @Output() changeDataTypeNotify: EventEmitter<any> = new EventEmitter();
  @Output() deleteColumnNotify: EventEmitter<any> = new EventEmitter();

  enableSave = false;
  title = 'app';
  selectedRow = null;

  public defaultColDef: any = {
    // editable: true,
    // sortable: true,
    // flex: 1,
    // minWidth: 100,
    floatingFilter: true,
    filter: true,
    resizable: true,

    wrapText:true,
    autoHeight:true,
  };
  public columnDefs: Array<any> = [];
  public rowData: Array<any> = [];
  public gridApi: any;
  public gridColumnApi: any;

  public gridOptions: GridOptions;
  public statusBar: any;
  public rowClassRules:any;

  public currentMenu: any = {
    show: false,
    column: null,
    columnId: '',
    left: 0,
    top: 0,
    event: null,
    dependentMenu: {
      show: false,
      left: 0,
      top: 0,
      menuMap: {
        dataTypeMenu: false,
        pinMenu: false
      }
    }
  };

  public dataTypeOptionsMap: any = {
    boolean: [
      {
        label: 'To Decimal',
        value: 'double'
      },
      {
        label: 'To String',
        value: 'string'
      }
    ],
    date: [
      {
        label: 'To Boolean',
        value: 'boolean'
      },
      {
        label: 'To String',
        value: 'string'
      }
    ],
    double: [
      {
        label: 'To Boolean',
        value: 'boolean'
      },
      {
        label: 'To String',
        value: 'string'
      },
      {
        label: 'To Integer',
        value: 'integer'
      }
    ],
    integer: [
      {
        label: 'To Boolean',
        value: 'boolean'
      },
      {
        label: 'To Decimal',
        value: 'double'
      },
      {
        label: 'To String',
        value: 'string'
      }
    ],
    string: [
      {
        label: 'To Boolean',
        value: 'boolean'
      },
      {
        label: 'To Date',
        value: 'date'
      },
      {
        label: 'To Decimal',
        value: 'double'
      }
    ],
  };

  public pinMenuOptions: Array<any> = [
    {
      label: 'Left',
      value: 'left',
      checked: false
    },
    {
      label: 'No pin',
      value: null,
      checked: false
    }
  ];

  public dataTypeOptionsView: Array<any> = [];

  lastSelectedCellData = ''
  lastSelectedRowData = {}
  @HostListener('window:keydown',['$event'])
  onKeyPress($event: KeyboardEvent) {
    if(($event.ctrlKey || $event.metaKey) && $event.altKey && $event.keyCode == 67) {
      this.copyLastSelectedRow()
    }
    if(($event.ctrlKey || $event.metaKey) && !$event.altKey && $event.keyCode == 67) {
      // console.log('CTRL + C');
      // this.gridApi.getCellRanges()
      this.copyLastSelectedCell()
    }
    if(($event.ctrlKey || $event.metaKey) && $event.keyCode == 86) {
      // console.log('CTRL +  V');
    }
  }

  copyLastSelectedCell() {
    this.contextMenu.nativeElement.classList.remove("visible")
    if(this.lastSelectedCellData) {
      navigator.clipboard.writeText(this.lastSelectedCellData)
    }
  }

  copyLastSelectedRow() {
    this.contextMenu.nativeElement.classList.remove("visible")
    let data = '';
    let selectedRowIndexes = Object.keys(this.gridApi.selectionController.selectedNodes).filter(i => this.gridApi.selectionController.selectedNodes[i] !== undefined)
    if (selectedRowIndexes.length > 0) {
      let firstNode = this.gridApi.selectionController.selectedNodes[selectedRowIndexes[0]]
      data = Object.keys(firstNode.data).join('\t') + '\t\n'

      for (let i of selectedRowIndexes) {
        data += Object.values(this.gridApi.selectionController.selectedNodes[i].data).join('\t') + '\t\n'
      }
      navigator.clipboard.writeText(data)
    }
  }

  constructor() {
    let self = this
    this.gridOptions = <GridOptions>{
      onCellClicked(event: CellClickedEvent) {
        self.lastSelectedCellData = event.value
      },
      onBodyScroll(event: BodyScrollEvent) {
        // console.log(event)
      },

      onRowSelected(event: RowSelectedEvent) {
        self.lastSelectedRowData = event.data
        // console.log(event.data)
      },
      onSelectionChanged(event: SelectionChangedEvent) {
        // console.log(event)
      },
      suppressFieldDotNotation: true,
      // suppressColumnStateEvents: true,
      context: {
        componentParent: this
      },
      enableRangeSelection: true,
      rowSelection: 'multiple',
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              // possible values are: 'count', 'sum', 'min', 'max', 'avg'
              aggFuncs: ['min', 'max', 'avg']
            }
          }
        ]
      }
    };

    this.statusBar = {
      statusPanels: [{statusPanel: 'agAggregationComponent'}],
    };
  }
  ngAfterViewInit() {
    let contextMenu = this.contextMenu.nativeElement
    let scope = this.gridArea?.nativeElement
    scope.addEventListener("contextmenu", (event:any) => {
      event.preventDefault();
      const { clientX: mouseX, clientY: mouseY } = event;
      const { normalizedX, normalizedY } = this.normalizePozition(mouseX, mouseY);
      contextMenu.classList.remove("visible");
      contextMenu.style.top = `${normalizedY}px`;
      contextMenu.style.left = `${normalizedX}px`;
      setTimeout(() => {
        contextMenu.classList.add("visible");
      });
    });

    scope.addEventListener("click", (e:any) => {
      // ? close the menu if the user clicks outside of it
      if (e.target.offsetParent != contextMenu) {
        contextMenu.classList.remove("visible");
      }
    });
    console.log(this.previewData)
  }

  normalizePozition(mouseX:number, mouseY:number) {
    let contextMenu = this.contextMenu.nativeElement
    let scope = this.gridArea?.nativeElement
    // ? compute what is the mouse position relative to the container element (scope)
    let {
      left: scopeOffsetX,
      top: scopeOffsetY,
    } = scope.getBoundingClientRect();

    scopeOffsetX = scopeOffsetX < 0 ? 0 : scopeOffsetX;
    scopeOffsetY = scopeOffsetY < 0 ? 0 : scopeOffsetY;

    const scopeX = mouseX - scopeOffsetX;
    const scopeY = mouseY - scopeOffsetY;

    // ? check if the element will go out of bounds
    const outOfBoundsOnX =
      scopeX + contextMenu.clientWidth > scope.clientWidth;

    const outOfBoundsOnY =
      scopeY + contextMenu.clientHeight > scope.clientHeight;

    let normalizedX = mouseX;
    let normalizedY = mouseY;

    // ? normalize on X
    if (outOfBoundsOnX) {
      normalizedX =
        scopeOffsetX + scope.clientWidth - contextMenu.clientWidth;
    }

    // ? normalize on Y
    if (outOfBoundsOnY) {
      normalizedY =
        scopeOffsetY + scope.clientHeight - contextMenu.clientHeight;
    }

    return { normalizedX, normalizedY };
  };

  tableHeight: number = 200
  ngOnChanges(changes: SimpleChanges): void {
    const { previewData, permissionConfig, height} = changes;
    if (previewData) {
      // console.log(previewData);
      const { cols, data, dataSummary } = previewData.currentValue;

      this.setColumns(previewData.currentValue.cols, []);
      this.rowData = data;
      // this.gridApi.setRowData(data)
      // if (this.gridApi) {
      //   this.gridApi.redrawRows({columnDefs, rowData});
      // }
    }

    if (permissionConfig) {
      this.permissionConfig = permissionConfig.currentValue;
    }

    if(height && height.firstChange) {
      height.currentValue.subscribe((rectHeight: number) => {
        this.tableHeight = rectHeight
      })
    }
  }

  ngOnInit(): void {
  }


  onGridReady(params:any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onFirstDataRendered(params:any) {
    // params.api.sizeColumnsToFit();
    params.api.setFilterModel(this.defaultFilters || {});
  }

  setColumns(columns:any, dataSummary:any) {
    const columnDefs = [];
    console.log(this.previewData)

    // index column
    const slCol = {
      headerName: ' ',
      field: 'sl',
      width: 70,
      pinned: true,
      lockPosition: true,
      cellClass: 'locked-col',
      suppressNavigable: true,
      suppressMenu: true,
      resizable: false,
      valueGetter: function (params:any) {
        return params.node.rowIndex + 1;
      },
    };
    columnDefs.push(slCol);

    const dataSummaryMap = {};
    dataSummary.forEach((el:any) => {
      const { rowCount, sum } = el;
      // dataSummaryMap[el.schemaKeyId] = { rowCount, sum };
    });

    columns.forEach((col:any, i:any) => {
      // console.log(col);
      col = {
        ...col,
        // ...dataSummaryMap[col.id],
        index: i,
        // menuIcon: 'fa-bars'
      };
      const colObj = {
        field: col.field,
        headerComponentFramework: TableHeaderComponent,
        headerComponentParams: col,
        cellClass: function (params:any) {
          return (params.value === null) ? 'ag-cel-red' : (typeof(params.value)==='object'?'zero-padding':'');
        },
        resizable: true,
        pinned: null,

        cellRendererFramework:col.type==='complex:sequence'?DetailCellRendererComponent:null,
        //cellRendererFramework:null,
        filter:col.type==='complex:sequence'?false:true,
        
        valueFormatter: (params:any) => {
          const { colDef: { headerComponentParams: { type, field } } } = params;
          const val = params.data[field];
          //console.log(params)
          
          // if(type === 'complex:sequence'){
          //   return JSON.stringify(val)
          // }
          // else{
          //   return val
          // }

          // if (type === 'date') {
          //   return this.datePipe.transform(val, 'dd/MM/yyyy');
          // } else {
          //   return val;
          // }
        }
        // suppressSizeToFit: true
        // filter: 'agTextColumnFilter'
      };
      columnDefs.push(colObj);
    });
    this.columnDefs = columnDefs
    // this.gridApi.setColumnDefs(columnDefs);
  }

  onRowSelected(event:any) {
    this.selectedRow = event.rowIndex + 1;
    // console.log(event)
  }

  setCurrentMenuPosition(left:any, top:any, width:any, event:any, columnId:any) {
    // console.log("col Id", columnId);
    const columnDefs = this.gridApi.getColumnDefs();
    const column = columnDefs.find(({ headerComponentParams }:any) => {
      // console.log(headerComponentParams);
      if (headerComponentParams) {
        return headerComponentParams.id === columnId;
      }
      return false;
    });

    if (column) {
      this.dataTypeOptionsView = this.dataTypeOptionsMap[column.headerComponentParams.type];

      // pin menu
      this.pinMenuOptions.forEach(el => {
        if (column.pinned === el.value) {
          el.checked = true;
        } else {
          el.checked = false;
        }
      });
    }

    const innerWidth  = "100px";
    const menuWidth = 181;
    if (menuWidth + left > innerWidth) {
      left = left - (menuWidth - width);
    }

    this.currentMenu = {
      ...this.currentMenu,
      show: true,
      column,
      columnId,
      left,
      top,
      event,
      dependentMenu: {
        show: false,
        left: 0,
        top: 0,
        menuMap: {
          ...this.currentMenu.dependentMenu.menuMap
        }
      }
    };
  }

  closeMenu(): void {
    this.currentMenu.show = false;
    this.currentMenu.column = null;
    this.currentMenu.columnId = '';
  }

  onMenuClick(event:any): void {
    if (this.agOptionMenu && !this.agOptionMenu.nativeElement.contains(event.target) && !this.currentMenu.event.srcElement.contains(event.target)) {
      this.closeMenu();
    }
  }

  onMenuWheel(event:any): void {
    if (this.agOptionMenu && !this.agOptionMenu.nativeElement.contains(event.target) && !this.currentMenu.event.srcElement.contains(event.target)) {
      this.closeMenu();
    }
  }

  showDependentMenu(event:any, menuName:any): void {
    // console.log(event,menuName);
    this.currentMenu.dependentMenu.show = true;

    const menuMap = this.currentMenu.dependentMenu.menuMap;
    // console.log(menuMap);
    Object.keys(menuMap).forEach(el => {
      menuMap[el] = false;
    });
    this.currentMenu.dependentMenu.menuMap[menuName] = true;

    const { width } = event.target.getBoundingClientRect();
    const outerWidth = 100;

    const dependentMenuWidth = 182;
    if (this.currentMenu.left + (1.5 * width) >= outerWidth) {
      this.currentMenu.dependentMenu.left = - (dependentMenuWidth + 18);
    } else {
      this.currentMenu.dependentMenu.left = dependentMenuWidth;
    }

    if (menuName === 'dataTypeMenu') {
      this.currentMenu.dependentMenu.top = 0;
    } else if (menuName === 'pinMenu') {
      this.currentMenu.dependentMenu.top = 30;
    }

  }

  hideDependentMenu(event:any): void {
    const menuMap = this.currentMenu.dependentMenu.menuMap;
    Object.keys(menuMap).forEach(el => {
      menuMap[el] = false;
    });
    this.currentMenu.dependentMenu = {
      show: false,
      left: 0,
      top: 0,
      menuMap
    };
  }

  changeDataType(event:any): void {
    const { target: { value } } = event;
    const columnDefs = this.gridApi.getColumnDefs();
    const column = columnDefs.find(({ headerComponentParams }:any) => {
      if (headerComponentParams) {
        return headerComponentParams.id === this.currentMenu.columnId;
      }
      return false;
    });
    this.changeDataTypeNotify.emit({ columnId: this.currentMenu.columnId, columnName: column.headerComponentParams.field, targetDataType: value });
  }

  deleteColumn(): void {
    const columnDefs = this.gridApi.getColumnDefs();
    const column = columnDefs.find(({ headerComponentParams }:any) => {
      if (headerComponentParams) {
        return headerComponentParams.id === this.currentMenu.columnId;
      }
      return false;
    });
    this.deleteColumnNotify.emit({ ...column.headerComponentParams });
  }

  pinColumn(value:any): void {
    const columnDefs = this.gridApi.getColumnDefs();
    const idx = columnDefs.findIndex(({ headerComponentParams }:any) => {
      if (headerComponentParams) {
        return headerComponentParams.id === this.currentMenu.columnId;
      }
      return false;
    });

    // const obj = {
    //   colId: column.field,
    //   pinned: value
    // };
    // this.gridColumnApi.applyColumnState({
    //   state: [
    //     {
    //       colId: 'sl',
    //       pinned: 'left'
    //     },
    //     obj
    //   ],
    //   defaultState: { pinned: column.pinned }
    // });

    columnDefs[idx].pinned = value;
    this.gridApi.setColumnDefs(columnDefs);
    this.closeMenu();
  }

  onColumnPinned(e:any) {
    // console.log('Event Column Pinned', e);
  }
  @Output() filteredData = new EventEmitter<any>();
  @Input() defaultFilters:{[input_field:string]:FilterType} = {}

  onFilterChanged(params:any) {
    this.filteredData.emit(params.api.getFilterModel())
   }

}

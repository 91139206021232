<simplify-groups-subheader 
    (editDetails)="openEditDetails()"
    [path]="groupName" 
    (showInfo)="openSidebar()" 
    (customEvents)="headerEventsHandler($event)" 
    (createModalEvents)="createPipeline($event)"
    (searchQuery)="onSearchQueryChange($event)"
    [showCreateNew]="hasAdminRole"
>
</simplify-groups-subheader>

<simplify-vms-groups-data2 
    (editDirectory)="switchToEditDirectory($event)"
    (directoryClicked)="switchToDirectory($event)" 
    [directories]="directoriesData"
    [directoryLoading]="directoriesLoading" 
    [pipelineLoading]="pipelinesLoading"
    [totalPipelines]="totalCount"
    [showEdit]="hasAdminRole"
    #groupDetails
>
    <simplify-group-details-preview 
        (editDetails)="openEditDetails()" 
        (sidebarClosed)="closeSidebar()" 
        slot="group-details" 
        [showEdit]="hasAdminRole"
        [groupDetails]="groupDetailsData">
    </simplify-group-details-preview>

    <simplify-pipeline-table 
        (pageEvents)="customEventsHandler($event)"
        (pipelineSelected)="pipelineSelected($event)" 
        slot="pipeline-table" 
        [pipelineOptions]="pipelineOptions"
        [pipelines]="pipelineData">
    </simplify-pipeline-table>

    <simplify-pagination slot="pagination" *ngIf="totalCount>10" [totalCount]="totalCount" [pageIndex]="pageIndex" [itemsPerPage]="itemsPerPage" [pageSizeOptions]="pageSizeOptions" (paginate)="onPaginationEvent($event)"></simplify-pagination>

</simplify-vms-groups-data2>


<ng-template #portalContent>
    <simplify-edit-details 
        [initialDetails]="groupDetailsData" 
        (save)="updateGroupDetails($event)" 
        (close)="closeEditDetails()">
    </simplify-edit-details>   
  </ng-template>
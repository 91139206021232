import {  Component,  ElementRef,  EventEmitter,  HostListener,  Input,  OnChanges,  OnDestroy,  OnInit,  Output,  QueryList,  Renderer2, AfterViewInit,  RendererStyleFlags2,  TemplateRef,  ViewChild, ViewChildren, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subject, of} from 'rxjs';
import { ClusterNode, DagreSettings, Edge, Graph, GraphComponent, Node as GraphNode, Layout, MiniMapPosition, NodePosition, PanningAxis } from '@swimlane/ngx-graph';
import { DagreClusterLayout } from '@swimlane/ngx-graph';

import {NbDialogService, NbToastrService} from '@nebular/theme';
import {Subscription} from "rxjs/internal/Subscription";
import {MatDialog} from "@angular/material/dialog";

import {NodesConfigAPIResponse, PipelineService} from '../../services/api/pipeline.service';
import {DataService} from '../../services/api/data.service';
import {Link, Node, NodeInputField, Workflow} from "./dag_type";
import {ConfigService} from "../../services/api/config.service";
import {NodeService, NodeServiceResponse} from "../../services/api/node.service";
import { NodePositionService } from 'src/app/services/api/node-position.service';

import {ConsoleLoggerService} from "../../services/logger/console-logger.service";
import {ConfigModel} from "../../models/config.model";
import {key} from "ngx-bootstrap-icons";
import { Portal, TemplatePortal } from '@angular/cdk/portal';

import * as shape from 'd3-shape';
import { customDagreLayout1 } from './customLayout1';
import { ConfirmationPopupModel } from '../confirmation-popup/confirmation-popup';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { UIService } from 'src/app/services/config/ui.service';
import { TaskService } from 'src/app/services/api/task.service';
import {Node as TaskNode, NodeField} from "../../models/tasknode.model";
import {DashboardStoreService} from "../../services/store/dasboard-store.service";
import { getDefaultFieldsName } from 'src/app/pages/dashboard/tasks/helper-functions';


const IS_MAC = (window.navigator.platform || '').toLowerCase().startsWith('mac');


function getLine(startingPoint: Point, endingPoint: Point, {height, width}: {height: number, width: number}): Array<Point> {
  
  const edge = {points: [] as Array<Point>}
  const dir = 1
  const equal_y = startingPoint?.y == endingPoint?.y; 


  // generate new points
  edge.points = [startingPoint, endingPoint];


  const Point1 = {
    // x: startingPoint.x + ((endingPoint.x-startingPoint.x)/3 > 20? 20:(endingPoint.x-startingPoint.x)/3) ,
    x: startingPoint.x + (Math.abs((endingPoint.x-startingPoint.x)/3) > 100? 100:Math.abs(endingPoint.x-startingPoint.x)/3) ,
    y: startingPoint.y - dir
  };

  let Point2 = {
    x: Point1.x + 10,
    y: startingPoint.y - (equal_y ? 0:dir*5)
  };

  
  const Point4 = {
    x: endingPoint.x - (Math.abs(endingPoint.x-startingPoint.x)/3 > 50? 100:Math.abs(endingPoint.x-startingPoint.x)/3),
    y: endingPoint.y + (equal_y ? 0:dir*1)
  };

  let Point3 = {
    x: Point4.x - 10,
    y: endingPoint.y + (equal_y ? 0:dir*5)
  };
  


  //edge.line = `d: C ${startingPoint.x} ${startingPoint.y}, ${endingPoint.x} ${endingPoint.y}, ${midPoint.x} ${midPoint.y}`
  
  // this.dagreGraph.setEdge(edge.source, edge.target, edge,{
  //   id: edge.id,
  //   //curve: shape.curveMonotoneY
  // });
  
  edge.points = [startingPoint, Point1, Point4,endingPoint]
  
  //when source node is on left
  if(endingPoint.x < startingPoint.x) {
   
    Point2 = {
      x: Point1.x,
      y: startingPoint.y - dir * height
    }

    Point3 = {
      x: Point4.x,
      y: endingPoint.y + dir * height
    }


    if(Math.abs(startingPoint.y - endingPoint.y) < height / 2) {
      Point2.y = Point3.y; 
    }

    edge.points = [startingPoint, Point1, Point2,Point3, Point4, endingPoint]
  }
  //nodes are aligned horizantly
  else if(Math.abs(startingPoint.y - endingPoint.y) < height / 2 ) {
    edge.points = [startingPoint,endingPoint]
  }
  //nodes are aligned vertically

  return edge.points
  
}


// Export Events
export enum EventTypes {
  OPEN_CONFIG = 'OPEN_CONFIG_CLICKED',
  OPEN_OUTPUT = 'OPEN_OUTPUT',
  OPEN_FIELD_OUTPUT = 'OPEN_FIELD_OUTPUT',
  OPEN_CONNECTOR = 'OPEN_CONNECTOR',
  CONNECT_NODES = 'CONNECT_NODES',
  DELETE_NODE = 'DELETE_NODE',
  MOUSEOVER_CONNECTOR = 'MOUSEOVER_CONNECTOR',
  SELECTED_NODES = 'MULTIPLE_SELECT_NODES',
  OPEN_EDGE_MAPPING = 'OPEN_EDGE_MAPPING'
}

export type Event = {
  type: EventTypes;
  args: Array<any>;
};

enum DagStates {
  NULL = 'NULL', // Allow dargging plan
  SELECTING = 'SELECTING', // Create Rectangle
  SELECTED = 'SELECTED', // Rectangle Created
  START_DRAGGING = 'START_DRAGGING',
  DRAGGING = 'DRAGGING',
  NODE_HOVER = 'NODE_HOVER',
  SINGLE_DRAGGING = 'SINGLE_DRAGGING'
}
export enum DagEvents {
  MOUSE_DOWN = 'MOUSE_DOWN',
  MOUSE_UP = 'MOUSE_UP',
  MOUSE_MOVE = 'MOUSE_MOVE',
  GOTO_NULL = 'GOTO_NULL',
  CTRL_DOWN = 'CTRL_DOWN',
  CTRL_UP = 'CTRL_UP'
}

export enum NodeRunningStatusIcons {
  new = 'more_time',
  active = 'plus-outline',
  'in_progress' = 'plus-outline',
  failed = 'error',
  success = 'check_circle',
  stopped = 'check_circle'
}

export enum KEY_CODE {
  LEFT_ARROW = 37,
  TOP_ARROW = 38,
  RIGHT_ARROW = 39,
  BOTTOM_ARROW = 40
}

export enum SELECT_ACTIONS {
  DRAG = 'DRAG',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  SAVE_AS_TEMPLATE = 'SAVE_AS_TEMPLATE',
  CLEAR = 'CLEAR',
  NONE = 'NONE'
}

type Point = { x: number; y: number };

const isPointInRect = (
  { start, end }: { start: Point; end: Point },
  point: Point
) => {
  const topLeft = {
    x: Math.min(start.x, end.x),
    y: Math.min(start.y, end.y)
  };
  const bottomRight = {
    x: Math.max(start.x, end.x),
    y: Math.max(start.y, end.y)
  };

  return (
    point.x > topLeft.x &&
    point.x < bottomRight.x &&
    point.y > topLeft.y && point.y < bottomRight.y
  );
};

const isIntersecting = (
  a: { start: Point; end: Point },
  { start, end }: { start: Point; end: Point }
) => {
  const r1 = {
    topLeft: {
      x: Math.min(start.x, end.x),
      y: Math.min(start.y, end.y)
    },
    bottomRight: {
      x: Math.max(start.x, end.x),
      y: Math.max(start.y, end.y)
    }
  };

  const r2 = {
    topLeft: {
      x: Math.min(a.start.x, a.end.x),
      y: Math.min(a.start.y, a.end.y)
    },
    bottomRight: {
      x: Math.max(a.start.x, a.end.x),
      y: Math.max(a.start.y, a.end.y)
    }
  };

  // Zero area
  if (
    r1.topLeft.x - r1.bottomRight.x === 0 ||
    r1.topLeft.y - r1.bottomRight.y === 0 ||
    r2.topLeft.y - r2.bottomRight.y === 0 ||
    r2.topLeft.y - r2.bottomRight.y === 0
  )
    return false;

  // non-intersecting on x axis
  if (r1.bottomRight.x < r2.topLeft.x || r2.bottomRight.x < r1.topLeft.x)
    return false;

  // non-intersecting on y-axis
  if (r1.bottomRight.y < r2.topLeft.y || r2.bottomRight.y < r1.topLeft.y)
    return false;

  return true;
};

@Component({
  selector: 'app-dag',
  templateUrl: './dag.component.html',
  styleUrls: ['./dag.component.scss']
})
export class DagComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  // #region Ref
  @ViewChild('generalNode', { static: true }) generalNodeTemplate: any;
  @ViewChild('deal', { static: true }) dealTemplate: any;
  @ViewChild('graph', { static: false }) graph!: GraphComponent;
  @ViewChild('selector') selector!: ElementRef;
  @ViewChild('contextmenu') contextmenu!: ElementRef;
  @ViewChildren('nodes') nodeTemplates!: QueryList<ElementRef>;
  @ViewChild('selectOptions') selectOptionsMenu!: ElementRef;
  // #endregion

  // #region Inputs
  @Input() events = new Observable<{
    type:
      | 'ZOOM_IN'
      | 'ZOOM_OUT'
      | 'ZOOM_FIT'
      | 'UPDATE_LOADING_STATUS'
      | 'UPDATE_CONDITIONAL_NODE_STATUS'
      | 'DELETE_NODE'
      | 'MULTIPLE_SELECT_NODES'
      | 'FOCUS_NODE';
    args: Array<unknown>;
  }>();
  @Input() selectionEvents = new Observable<{
    type: 'DRAG' | 'EDIT' | 'SAVE_AS_TEMPLATE' | 'DELETE' | 'CLEAR';
    args: Array<unknown>;
  }>();
  @Input() dagEvents = new Observable<{
    type: 'DEFAULT' | 'DRAG_ENABLE' | 'RESET' | 'UNDO' | 'REDO' | 'SAVE';
    args: Object;
  }>();
  @Input() updateNodes: boolean = false;
  @Input() conditional_node_status: Map<number, boolean | null> = new Map<
    number,
    boolean | null
  >();
  // #endregion

  // #region Component events
  @Output() config_id = new EventEmitter();
  @Output() callbackEvents = new EventEmitter<Event>();
  @Output() taskConfig = new EventEmitter();
  @Output() output_task_data = new EventEmitter();
  // #endregion

  panToNodeObservable: Subject<string> = new Subject<string>();
  selectEnabled: boolean = false;
  contextMenuItems: Array<{text: string, disable?:boolean, onClick: Function}> = []

  // #region DagState
  // eslint-disable-next-line no-use-before-define
  dagState: DagState = new DagState(this);
  // #endregion

  minMapPosition: MiniMapPosition = MiniMapPosition.UpperRight;
  track_incoming_nodes: Map<number, boolean | null> = new Map<
    number,
    boolean | null
  >();
  track_incoming_edges: Map<string, boolean | null> = new Map<
    string,
    boolean | null
  >();

  // component configs
  // nodeMetadataMap: { [key: number]: ConfigModel['icon'] } = {};

  nodesConfigMap: {[key: number]: NodesConfigAPIResponse['payload'][0]} = {}

  nodeRunningStatus: {
    [key: number]: {
      status: keyof typeof NodeRunningStatusIcons;
      message?: string;
    };
  } = {};
  nodeStatusIcons = NodeRunningStatusIcons;
  portalStyle: { top: string; left: string } = { top: '0px', left: '10px' };
  defaultNodeStyleConfig: ConfigModel['icon'] = {
    icon: '',
    color: '',
    bottomStyle: {
      backgroundColor: '#F8F9FF',
      color: '#EB0252'
    }
  };
  isEditEnable: boolean = false;
  selectedNode: Node | null = null;
  changeNodePosition: boolean = false;

  // child components data
  zoomToFit$ = new Subject();
  update$ = new Subject();

  // state variables
  configdata: NodeServiceResponse['payload'] | undefined;
  subscriptions: Array<Subscription> = [];
  links: Array<Link> = [];
  nodes: Array<GraphNode> = [];
  workflow: any = new Workflow();
  currentPipeline: any;
  pipelineId: any;
  processId: any;
  id: any;
  currentNodeId: string | null = null;
  source: any;
  nodesMap: any;
  currentLinkStatus: any;
  zoomValue: number = 1;
  tasknode: Array<TaskNode> = [];
  filetype: any;
  output: boolean = false;
  cols: any = [];
  progress: Array<any> = [];

  legend_edges: any = [
    {
      type: 'default',
      name: 'default',
      color: 'gray'
    },
    {
      type: 'run_dependent',
      name: 'run dependent',
      color: '#fd01b2'
    },
    {
      type: 'conditional_dependent',
      name: 'conditional dependent',
      color: 'yellow'
    },
    {
      type: 'conditional_data_dependent',
      name: 'conditonal & data dependent',
      color: 'purple'
    }
  ];

  nodeType = {
    SOURCE: 'source',
    CONDITIONAL: 'conditional'
  };

  // Portal for external data view
  selectedPortal!: Portal<any>;
  curve: any = shape.curveBasis;

  initNodePositionMap: { [nodeId: string]: Point } | null = null;
  lastUpdatedPositionMap: { [nodeId: string]: Point } | null = null;
  nodePositionMap: { [nodeId: string]: Point } = {};
  layouts = {
    dragLayout: new customDagreLayout1(this.nodePositionMap, this.dagState)
    // dagre: "dagre"
  };
  layout: Layout | string = this.layouts.dragLayout;

  eventMapping: { [key: string]: Function } = {};
  private dragStart: number = 0;
  private dragOver: number = 0;

  // x1 = 0; y1 = 0; x2 = 0; y2 = 0;

  isRectangeVisible = false;
  isMouseDown = false;
  selectedNodes: Array<string> = [];
  // selectedNodesInitState: {[id: string]: {position: Point}} = {};
  areNodesSelected: boolean = false;

  setSelectAction: Subscription = new Subscription();
  dagEventSubscription: Subscription = new Subscription();

  graphSettings = {
    paningEnabled: true,
    zoomingEnabled: true
  };
  select_rect_settings: {
    action: SELECT_ACTIONS;
  } = {
    action: SELECT_ACTIONS.NONE
  };
  drag_x1: number = 0;
  drag_y1: number = 0;
  drag_x2: number = 0;
  drag_y2: number = 0;

  SELECT_ACTIONS = SELECT_ACTIONS;
  showPortal: boolean = false;

  constructor(
    private pipelineService: PipelineService,
    private data_service: DataService,
    private toastrService: NbToastrService,
    private logger: ConsoleLoggerService,
    // private configService: ConfigService,
    private _viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private nodeService: NodeService,
    private nodePositionService: NodePositionService,
    private dashboardService: DashboardStoreService,
    private dialog: MatDialog,
    public uiService: UIService,
    public taskserive: TaskService
  ) {
    // init component
    this.resetDag();
    //console.log(this.getNodeMetaData)
  }

  ngOnInit() {
    // Register events
    this.registerEvents();

    //subscribe for selectedNodesActions
    this.setSelectAction = this.selectionEvents.subscribe(
      (event: { type: string; args: Array<unknown> }) => {
        this.selectAction(event.type, event.args);
      }
    );

    this.dagEventSubscription = this.dagEvents.subscribe(
      (event: { type: string; args: Object }) => {
        this.setDagEvent(event.type, event.args);
      }
    );

    // Subscribe data
    this.subscribeData();
  }

  graphElement:HTMLElement | null = null
  ngAfterViewInit() {
    this.graphElement = this.graph.chart.nativeElement
    const connectorGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g')

    connectorGroup.setAttribute('class', 'connectors')

    const connector = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    connector.style.display = 'none'
    connector.style.pointerEvents = 'none'
    const props = {
      // d: "",
      stroke: "#2196f3",
      'stroke-width': '2',
      fill: "transparent",
      id: "connector-1",
      'stroke-dasharray': "10,10"
      // 'marker-end':"url(#arrow)"
    }

    Object.keys(props).forEach(prop => connector.setAttribute(prop, props[prop as keyof typeof props]))

    connectorGroup.appendChild(connector)


    const svg = this.graphElement!.querySelector('svg .graph')!;
    svg.appendChild(connectorGroup)
  }

  // #region Dom Events
  @HostListener('mousedown', ['$event'])
  onMouseDown(ev: MouseEvent) {
    this.dagState.next({ type: DagEvents.MOUSE_DOWN, args: [ev] });

    const contectMenuElement = (this.contextmenu.nativeElement as HTMLDivElement);
    contectMenuElement.style.display = 'none'

    let drawRect = (<HTMLElement>ev.target)?.classList.contains('panning-rect');
    if (drawRect && this.select_rect_settings.action == SELECT_ACTIONS.NONE) {
      this.dragStart = ev.clientY;
      this.selectEnabled = true;
    }
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(ev: MouseEvent) {
    if(this.isDragging) {
      this.isDragging = 2;
      const svg = this.graphElement!.querySelector('svg #connector-1')! as HTMLElement;
      const node = this.graph.nodes.find(x => `${x.id}` === `${this.draggingEdgeNode?.id}`)!;
      const graphRect = this.graphElement?.getBoundingClientRect()!;
      svg.style.display = 'block'

      const startPoint: Point = {
        x: (node.position?.x || 0) - (node.dimension?.width || 0) / 2,
        y: (node.position?.y || 0) - (node.dimension?.height || 0) / 2,
      }

      const fieldIdx = this.draggingEdgeNode?.fields.indexOf(this.draggingEdgeField!) ?? -1;
      if(fieldIdx < 0) return;

      const boxMargin = 5 // px
      const topOffset = 30 // px
      const bottomOffset = 30 // px
      const nodeWidth = 250 - 2 * boxMargin // px
      const connectorFieldHeight = 16 // px
      const connectorFieldGap = 4 // px

      startPoint.y += boxMargin + topOffset + (connectorFieldHeight * (fieldIdx + 1)) + (connectorFieldGap * fieldIdx) - (connectorFieldHeight / 2)
      

      if(this.draggingEdgeField?.type === 'input') {
        startPoint.x += boxMargin + 4
      }
      if(this.draggingEdgeField?.type === 'output') {
        startPoint.x += nodeWidth
      }


      // console.log(fieldIdx, this.draggingEdgeField)

      // if(this.dragStartPoint.includes('left')) startPoint.x -= (node.dimension?.width || 0) / 2
      // if(this.dragStartPoint.includes('right')) startPoint.x += (node.dimension?.width || 0) / 2
      // if(this.dragStartPoint.includes('top')) startPoint.y -= (node.dimension?.height || 0) / 2
      // if(this.dragStartPoint.includes('bottom')) startPoint.y += (node.dimension?.height || 0) / 2

      const endPoint: Point = {
        x: (ev.clientX - this.graph.transformationMatrix.e - graphRect.x) / this.graph.zoomLevel,
        y: (ev.clientY - this.graph.transformationMatrix.f - graphRect.y) / this.graph.zoomLevel
      }

      const line = this.graph.generateLine([...getLine(startPoint, endPoint, {...node.dimension!})])
      svg.setAttribute('d', line)
      // console.log(this.draggingEdgeNode, this.graph)
      // const node = this.graph.nodeElements.find(x => x.nativeElement.id == this.draggingEdgeNode?.id)
    }

    this.dagState.next({ type: DagEvents.MOUSE_MOVE, args: [ev] });
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(ev: MouseEvent) {

    if(this.isDragging) {
      const svg = this.graphElement!.querySelector('svg #connector-1')! as HTMLElement;
      svg.style.display = 'none'

      if(this.hoverNodeId !== null && this.isDragging === 2) {
        this.connectNodes(parseInt(this.draggingEdgeNode?.id || '-1'), this.hoverNodeId)
        this.hoverNodeId = null
        this.draggingEdgeNode = null
      }
      this.draggingEdgeNode = null
      this.hoverNodeId = null
      this.isDragging = 0
    }

    this.dagState.next({ type: DagEvents.MOUSE_UP, args: [ev] });
  }

  isCtrlKeyDown = false;
  @HostListener('window:keydown.Control', ['$event'])
  onKeyDown(ev: KeyboardEvent) {
    if (IS_MAC) return;
    this.isCtrlKeyDown = true;
    this.dagState.next({ type: DagEvents.CTRL_DOWN, args: [ev] });
  }

  @HostListener('window:keyup.Control', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    console.log(IS_MAC, ev);
    if (IS_MAC) return;
    this.isCtrlKeyDown = false;
    this.dagState.next({ type: DagEvents.CTRL_UP, args: [ev] });
  }
  @HostListener('window:keydown.Meta', ['$event'])
  onMetaKeyDown(ev: KeyboardEvent) {
    if (!IS_MAC) return;
    this.isCtrlKeyDown = true;
    this.dagState.next({ type: DagEvents.CTRL_DOWN, args: [ev] });
  }

  @HostListener('window:keyup.Meta', ['$event'])
  onMetaKeyUp(ev: KeyboardEvent) {
    if (!IS_MAC) return;
    this.isCtrlKeyDown = false;
    this.dagState.next({ type: DagEvents.CTRL_UP, args: [ev] });
  }

  @HostListener('selectstart', ['$event'])
  preventTextSelect(ev: KeyboardEvent) {
    ev.preventDefault();
  }
  // #endregion

  getNodeInfo(nodeId: number) {
    return this.nodeRunningStatus[nodeId] || { status: null, message: null };
  }

  // getNodeMetaData(nodeConfigId: number): ConfigModel['icon'] {
  //   // Return node config/nodeMetadata, pass default value if value not found in map
  //   return {
  //     ...this.defaultNodeStyleConfig,
  //     ...this.nodeMetadataMap[nodeConfigId]
  //   };
  // }

  focusNode(nodeId: string) {
    this.panToNodeObservable.next(nodeId);
  }

  focusReferenceNode(nodeId: string) {
    // const node = this.tasknode.find(x => `${x.id}` === nodeId)!; // keep a map to get a the node by id
    
    /* FIXME: V2 Changes
    const nodeConfig = node.taskConfigDetails;

    if (['reference_node'].includes(nodeConfig.name)) {
      // console.log(node)
      const parentId = node.parent_id;
      this.focusNode(parentId);
    }
    // */
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.setSelectAction.unsubscribe();
    this.dagEventSubscription.unsubscribe();
  }

  ngOnChanges() {}

  buildGraph(taskNodes: Array<TaskNode>): [any, any] {
    let nodes: Array<Node> = [];
    let links: Array<Link> = [];
    const booleanMap: { [key: string]: boolean } = {};

    //console.log("taskNodes:",taskNodes);
    for (let element of taskNodes) {
      const fields: Array<NodeInputField> = [
        {
          id: 'connection',
          name: 'Connection',
          label: 'Connection',
          description: "Connection",
          required: true,
          dataType: "",
          formatType: "",
          default: "",
          schema: {}, 

          type: 'config',
          isError: false,
          meta: {},
        },
        { 
          id: 'service',
          name: 'Service',
          label: 'Service',
          description: "Service",
          required: true,
          dataType: "",
          formatType: "",
          default: "",
          schema: {}, 

          type: 'config',
          isError: false,
          meta: {},
         },
        // { 
        //   id: 'data',
        //   name: 'Data',
        //   label: 'Data',
        //   description: "Data",
        //   required: true,
        //   dataType: "",
        //   formatType: "",
        //   default: "",
        //   schema: {}, 

        //   type: 'output',
        //   isError: false,
        //   meta: {},
        //  },
        // { 
        //   id: 'rows',
        //   name: 'Rows',
        //   label: 'Rows',
        //   description: "Rows",
        //   required: true,
        //   dataType: "",
        //   formatType: "",
        //   default: "",
        //   schema: {}, 

        //   type: 'output',
        //   isError: false,
        //   meta: {},
        //  },
        // { 
        //   id: 'varName',
        //   name: 'var1',
        //   label: 'Var',
        //   description: "Add Field",
        //   required: true,
        //   dataType: "",
        //   formatType: "",
        //   default: "",
        //   schema: {}, 

        //   type: 'input',
        //   isError: false,
        //   meta: {},
        //  },
        
      ]; // TODO: add node Input Configs, define input config format (typescript dto), V2 Changes

      const dynamicInputFields = element.dynamicInputFields || {};
      const dynamicOutputFields = element.outputFields || {};
      // const elementFields = (element as any).fields as Array<NodeInputField> || [];

      Object.entries(dynamicOutputFields).forEach(([key, dynamicOutput], index) => {
        fields.push({
          name: dynamicOutput.name,
          label: dynamicOutput.label,
          description: dynamicOutput.description,
          required: dynamicOutput.required,
          dataType: dynamicOutput.dataType,
          formatType: dynamicOutput.formatType,
          default: dynamicOutput.value,
          schema: {}, // TODO: add schema
          id: dynamicOutput.name,
          type: 'output',
          isError: false,
          meta: dynamicOutput,
        });
      });

      Object.entries(dynamicInputFields).forEach(([key, dynamicInput], index) => {
        fields.push({
          name: dynamicInput.name,
          label: dynamicInput.label,
          description: dynamicInput.description,
          required: dynamicInput.required,
          dataType: dynamicInput.dataType,
          formatType: dynamicInput.formatType,
          default: dynamicInput.value,
          schema: {}, // TODO: add schema
          id: dynamicInput.name,
          type: 'input',
          isError: false,
          meta: dynamicInput,
        });


          
        const dependentOn = dynamicInput.dependentOn || [];

        dependentOn.forEach(
          edge => {
            links.push(new Link(
              `link-${edge.nodeInstanceId}-${element.id}-${edge.sourceFieldName}-${dynamicInput.name}`,
              (edge.nodeInstanceId||'').toString(),
              element.id.toString(),
              dynamicInput.name,
              'node',
              '',
              [{x: 0, y: 0}],
              {sourceFieldId: edge.sourceFieldName, targetFieldId: dynamicInput.name}
            ))
          }
        )

          
          
        


      })

      // elementFields.forEach(
      //   field => {
      //     fields.push({
      //       name: field.name,
      //       label: field.label,
      //       description: field.description,
      //       required: field.required,
      //       dataType: field.dataType,
      //       formatType: field.formatType,
      //       default: field.default,
      //       schema: field.schema,
      //       id: field.name,
      //       type: 'input',
      //       isError: false,
      //       meta: field,
      //     })

      //     if(field.source) {
      //       links.push(new Link(
      //         `link-${field.source.sourceNodeId}-${element.id}-${field.source.sourceFieldId}-${field.name}`,
      //         field.source.sourceNodeId.toString(),
      //         element.id.toString(),
      //         field.name,
      //         'node',
      //         '',
      //         [{x: 0, y: 0}],
      //         {sourceFieldId: field.source.sourceFieldId, targetFieldId: field.name}
      //       ))
      //     }
      //   }
      // )

      nodes.push(
        new Node({
          id: element.id.toString(),
          name: element.label,
          label: element.label,
          type: element.nodeName,
          conditional_status: false, // TODO: add Conditional node Input Configs, V2 Changes
          metaData: element,
          fields: fields
        })
      );

      fields.push({ 
        id: 'add-button',
        name: 'add-field',
        label: 'Add Field',
        description: "Add Field",
        required: true,
        dataType: "",
        formatType: "",
        default: "",
        schema: {}, 

        type: 'add',
        isError: false,
        meta: {},
      })

      /* FIXME: V2 api does not have outgoing_task_with_weights

      for (let target of element.outgoing_task_with_weights) {
        let label = target.weight ?? null;
        let source = parseInt(key);
        const connector = {sourceFieldId: target.sourceFieldId, targetFieldId: target.targetFieldId}

        links.push(
          new Link(
            'edge_' + key + '_' + target.taskId + `${connector.sourceFieldId}_${connector.targetFieldId}`,
            (source as unknown) as string,
            target.taskId,
            label,
            target.type,
            '',
            [{x: 0, y: 0}],
            connector,
            target.weight
          )
        );
        booleanMap[`${target.taskId}`] = target.weight;
      }
      // */
    
    }
    for (let node of nodes) {
      node.boolean_weight = booleanMap[node.id];
    }
    return [nodes, links];
  }

  // Reset / Init
  resetDag() {
    this.nodes = [];
    this.links = [];
  }

  openNodeConfig(node: Node) {
    this.callbackEvents.emit({
      type: EventTypes.OPEN_CONFIG,
      args: [node.id]
    });
    this.currentNodeId = node.id;
  }

  setSource(node: Node, booleanWeight?: boolean) {
    this.callbackEvents.emit({
      type: EventTypes.OPEN_CONNECTOR,
      args: [node.id, booleanWeight]
    });
    this.currentNodeId = node.id;
  }

  connectNodes(sourceNodeId: number, targetNodeId: number) {
    this.callbackEvents.emit({
      type: EventTypes.CONNECT_NODES,
      args: [sourceNodeId, targetNodeId, this.draggingEdgeField?.id, {conditional_node_status: !this.dragStartPoint.includes('bottom')}]
    });
  }

  updatePortal(
    templatePortal: TemplatePortal<any>,
    portalStyle?: { top: string; left: string }
  ) {
    if (portalStyle) {
      this.portalStyle = portalStyle;
    } else {
      this.portalStyle = { top: '0px', left: '10px' };
    }

    this.showPortal = true;
    this.selectedPortal = templatePortal;
  }

  onConnectorHover(node: Node, event: MouseEvent) {
    this.callbackEvents.emit({
      type: EventTypes.MOUSEOVER_CONNECTOR,
      args: [
        node.id,
        event,
        (...args: any) => this.updatePortal.apply(this, args)
      ]
    });
  }

  isDragging: number = 0
  draggingEdgeNode: Node | null = null
  draggingEdgeField: NodeInputField | null = null
  dragStartPoint: Array<'top'|'left'|'bottom'|'right'> = []
  hoverNodeId: number | null = null

  // TODO: add types
  connectorIconEventHandler(node: Node, event: any, startPoint:Array<'top'|'left'|'bottom'|'right'>=['right'], field: NodeInputField) {
    this.isDragging = event.type === 'pointerdown' ? 1 : 0
    this.draggingEdgeNode = node;
    this.dragStartPoint = startPoint

    this.draggingEdgeField = field || null
  }

  openOutput(node: Node) {
    this.callbackEvents.emit({
      type: EventTypes.OPEN_OUTPUT,
      args: [node]
    });
    this.currentNodeId = node.id;
  }

  deleteNode(nodeId: number) {
    if (confirm(`Do you want to delete node with node id ${nodeId}`)) {
      this.callbackEvents.emit({
        type: EventTypes.DELETE_NODE,
        args: [[nodeId]]
      });
      // this.removeTaskPosition(nodeId);
    }
  }

  emitStateChange() {
    this.callbackEvents.emit({
      type: EventTypes.SELECTED_NODES,
      args: [
        this.dagState.state.selectedNodes.length > 0,
        this.dagState.state.selectedNodes,
        this.dagState
      ]
    });
  }

  // deselect() {
  //   this.callbackEvents.emit({
  //     type: EventTypes.SELECTED_NODES,
  //     args: [this.dagState.state.selectedNodes.length > 0, this.dagState.state.selectedNodes, this.dagState]
  //   })
  // }

  zoomOut() {
    this.zoomValue = Math.min(this.zoomValue + 0.2, 2);
  }

  zoomIn() {
    this.zoomValue = Math.max(this.zoomValue - 0.2, 0.1);
  }

  zoomFit() {
    this.zoomToFit$.next(true);
  }

  setRunningStatus(nodeRunningStatus: {}) {
    this.nodeRunningStatus = nodeRunningStatus;
    let memo: any = {};
    Object.keys(nodeRunningStatus).forEach(el => {
      // @ts-ignore
      if (!memo[nodeRunningStatus[el].status]) {
        // @ts-ignore
        memo[nodeRunningStatus[el].status] = 0;
      }
      // @ts-ignore
      memo[nodeRunningStatus[el].status]++;
    });

    // this.progress = Object.keys(memo).map((key) => ({count: memo[key], name: key, tooltipStatus: key, countShow: memo[key]}))
    let progress = [];
    let totalCount = this.nodes.length;
    progress.push({
      name: 'Success',
      count: ((memo['success'] || 0) * 100) / totalCount,
      tooltipStatus: 'success',
      // color: 'var(--bs-green)',
      color: '#00d68f',
      countShow: `${memo['success'] || 0}/${totalCount}`
    });
    progress.push({
      name: 'Running',
      count: ((memo['in_progress'] || 0) * 100) / totalCount,
      tooltipStatus: 'warning',
      // color: 'var(--bs-warning)',
      color: '#ffaa00',
      countShow: `${memo['in_progress'] || 0}/${totalCount}`
    });
    progress.push({
      name: 'Failed',
      count: ((memo['failed'] || 0) * 100) / totalCount,
      tooltipStatus: 'danger',
      // color: 'var(--bs-danger, #ff3d71)',
      color: '#ff3d71',
      countShow: `${memo['failed'] || 0}/${totalCount}`
    });
    progress.push({
      name: 'New',
      count: ((memo['new'] || 0) * 100) / totalCount,
      tooltipStatus: 'control',
      color: 'var(--bs-light)',
      countShow: `${memo['new'] || 0}/${totalCount}`
    });
    this.progress = progress;
  }

  setConditionalStatus(taskId: any, status: boolean | null) {
    this.get_conditional_node_status(taskId.taskId);
  }

  registerEvents() {
    this.eventMapping['ZOOM_IN'] = this.zoomIn;
    this.eventMapping['ZOOM_OUT'] = this.zoomOut;
    this.eventMapping['ZOOM_FIT'] = this.zoomFit;
    this.eventMapping['UPDATE_LOADING_STATUS'] = this.setRunningStatus;
    this.eventMapping[
      'UPDATE_CONDITIONAL_NODE_STATUS'
    ] = this.setConditionalStatus;
    this.eventMapping['FOCUS_NODE'] = this.focusNode;

    let subscription = this.events.subscribe(event => {
      if (this.eventMapping.hasOwnProperty(event.type)) {
        this.eventMapping[event.type].call(this, ...event.args);
      } else {
        this.logger.warn(`Unhandled event: ${event.type}`);
      }
    });
    this.subscriptions.push(subscription);
  }

  subscribeData() {
    // Subscribe pipeline data
    let subscription = this.data_service.subPipeline.subscribe(
      async (pipelineDetails) => {
        this.currentPipeline = pipelineDetails;
        this.pipelineId = this.currentPipeline.id;
        this.processId = this.currentPipeline.processId;
        this.workflow = pipelineDetails;
        this.tasknode = pipelineDetails?.nodeInstances || [];

        let nodePositionRes = await this.nodePositionService
          .getNodesPosition({ pipelineId: this.pipelineId })
          .toPromise();
        if (typeof nodePositionRes.payload.position === 'object') {
          if (this.initNodePositionMap === null) {
            this.initNodePositionMap = nodePositionRes.payload.position;
            this.lastUpdatedPositionMap = JSON.parse(
              JSON.stringify(nodePositionRes.payload.position)
            );
          }
          Object.assign(this.nodePositionMap, nodePositionRes.payload.position);
        }
        let [nodes, links] = this.buildGraph(pipelineDetails?.nodeInstances||[]);

        this.nodes = nodes;
        this.links = links;
        this.update$.next(true);
        // this.zoomToFit$.next(true)
      }
    );
    this.subscriptions.push(subscription);

    // Subscribe config data
    // this.configService.getAll().then(config => {
    //   this.nodeMetadataMap = config.reduce((prev, current) => {
    //     prev[current.id] = current.icon;
    //     return prev;
    //   }, {} as { [key: number]: any });
    // });
    subscription = this.dashboardService.nodesConfig$.subscribe(
      nodesConfig => {
        this.nodesConfigMap = nodesConfig.reduce(
          (prev, curr) => {
            prev[curr.name] = curr
            return prev
          }, {} as {[key: string]: typeof nodesConfig[0]})
      }
    )
    this.subscriptions.push(subscription);
  }

  showError(message: string) {
    this.toastrService.show(
      message,
      `Error`,
      // @ts-ignore
      { position: 'bottom-right', status: 'warning', duration: 5000 }
    );
  }

  get_conditional_node_status(taskNode: number) {
    let response = this.pipelineService
      .getoutput(this.pipelineId, this.processId, taskNode)
      .subscribe((outputResponse: any) => {
        this.logger.log(
          outputResponse['payload'],
          outputResponse.conditional_upload.status
        );
        this.conditional_node_status.set(
          taskNode,
          outputResponse.conditional_upload.status
        ); // BUG: get function should not update state
        return outputResponse.conditional_upload.status;
      });

    //console.log(response);

    // FIXME: function should either return value or Promise
    return null;
  }

  trackNodes(nodeId: number) {
    this.track_incoming_nodes.set(nodeId, true);

    this.hoverNodeId = parseInt(this.draggingEdgeNode?.id || '-1') == parseInt(`${nodeId}`) ? null : parseInt(`${nodeId}`)

    const node = this.tasknode.find(node => node.id === nodeId); // TODO: keep a map to get node by id

    /* FIXME: V2 Changes
    const nodeConfig = node.taskConfigDetails;
    if (['reference_node'].includes(nodeConfig.name)) {
      // console.log(node)
      const parentId = node.parent_id;
      // nodeId = parentId
      this.track_incoming_nodes.set(parentId, true);
    }
    // */


    for (let edge of this.links) {
      if (parseInt(edge.target) == nodeId) {
        this.track_incoming_edges.set(edge.id, true);
        this.track_incoming_nodes.set(parseInt(`${edge.source}`), true);
      }
    }
  }

  reset_tracks() {
    this.track_incoming_nodes = new Map<number, boolean | null>();
    this.track_incoming_edges = new Map<string, boolean | null>();
    this.hoverNodeId = null
  }

  resetPosition() {
    this.dagState.state.dragHistory.push(JSON.stringify(this.nodePositionMap));
    this.dagState.markDirty();

    Object.keys(this.nodePositionMap).forEach(
      key => delete this.nodePositionMap[key]
    );
    if (this.initNodePositionMap !== null) {
      Object.assign(this.nodePositionMap, this.initNodePositionMap);
    }

    // this.dagState.state.dragHistory = []
    // this.dagState.state.dragRedoHistory = []
    this.update$.next(true);
  }

  saveTemplateAsFile(filename: string, dataObjToWrite: any) {
    // console.log(dataObjToWrite.graph.nodes);
    let nodes = dataObjToWrite.graph.nodes;
    let node_positions = nodes.map((node: GraphNode) => {
      return {
        id: node.id,
        position: node.position
      };
    });
    const blob = new Blob([JSON.stringify(node_positions)], {
      type: 'text/json'
    });
    const link = document.createElement('a');

    link.download = filename;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ['text/json', link.download, link.href].join(
      ':'
    );

    const evt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
    });

    link.dispatchEvent(evt);
    link.remove();
  }

  hideNodeSelectMenu() {
    this.renderer.setStyle(
      this.selectOptionsMenu.nativeElement,
      'visibility',
      'hidden'
    );
  }

  setSelectRectPosition(
    start: { x: number; y: number },
    end: { x: number; y: number }
  ) {
    const topLeft = {
      x: Math.min(start.x, end.x),
      y: Math.min(start.y, end.y)
    };

    const bottomRight = {
      x: Math.max(start.x, end.x),
      y: Math.max(start.y, end.y)
    };

    // const screenHeightOffset = document.getElementById("containerRef")?.getBoundingClientRect().top as number;
    this.renderer.setStyle(
      this.selector.nativeElement,
      'left',
      topLeft.x + 'px'
    );
    this.renderer.setStyle(
      this.selector.nativeElement,
      'top',
      topLeft.y + 'px'
    );
    this.renderer.setStyle(
      this.selector.nativeElement,
      'width',
      bottomRight.x - topLeft.x + 'px'
    );
    this.renderer.setStyle(
      this.selector.nativeElement,
      'height',
      bottomRight.y - topLeft.y + 'px'
    );
  }

  getNodesInRectArea(rect: { start: Point; end: Point }): Array<string> {
    const domNodes = this.getNodesPosition();

    const checkIntersection = (
      a: { start: Point; end: Point },
      { x, y, height, width }: Point & { height: number; width: number }
    ) => {
      return isIntersecting(a, {
        start: { x, y },
        end: { x: x + width, y: y + height }
      });
    };
    const nodes: Array<string> = this.graph.nodes
      .filter(x => checkIntersection(rect, domNodes[x.id] || { x: -1, y: -1 }))
      .map(x => x.id);
    return nodes;
  }

  singleSelectNode(nodeId: number | string, $event?: MouseEvent) {
    nodeId = nodeId.toString();

    // FIXME: remove duplicate computation
    const nodeMap = this.graph.nodes.reduce((prev, curr) => {
      prev[curr.id] = curr;
      return prev;
    }, {} as { [key: string]: any });

    if (!this.selectedNodes.includes(nodeId)) {
      this.selectedNodes.push(nodeId);
      let node = JSON.parse(JSON.stringify(nodeMap[nodeId] || {}));
      // console.log(nodeId, node)
      // this.selectedNodesInitState[nodeId] = node
    } else {
      //remove node
      if ($event?.ctrlKey || false) {
        this.selectedNodes = this.selectedNodes.filter(
          (node_id: string) => node_id != nodeId
        );
        // delete this.selectedNodesInitState[nodeId]
      }
    }
  }

  isSelected(nodeId: any) {
    return this.selectedNodes.includes(nodeId.toString());
  }

  selectAction(type: string, args: Array<unknown>) {
    switch (type) {
      case 'DELETE':
        this.deleteSelectedNodes(this.dagState.state.selectedNodes);
        break;
      case 'EDIT':
        break;
      case 'SAVE_AS_TEMPLATE':
        break;
    }
  }

  deleteSelectedNodes(selectedNodes: string[]) {
    if (confirm(`Do you want to delete following nodes ${selectedNodes}`)) {
      this.callbackEvents.emit({
        type: EventTypes.DELETE_NODE,
        args: [selectedNodes]
      });
    }
  }

  undo() {
    // TODO: restore select-rectangle position
    const stackLenth = this.dagState.state.dragHistory.length;
    if (stackLenth === 0) return;
    let state = JSON.parse(this.dagState.state.dragHistory[stackLenth - 1]);
    this.dagState.state.dragRedoHistory.push(
      JSON.stringify(this.nodePositionMap)
    );
    this.dagState.markDirty();
    Object.assign(this.nodePositionMap, state);
    this.dagState.state.dragHistory.pop();
    this.dagState.state.showSelectRect = false;
    this.update$.next(true);
    this.emitStateChange();
  }

  redo() {
    // TODO: restore select-rectangle position
    const stackLenth = this.dagState.state.dragRedoHistory.length;
    if (stackLenth === 0) return;
    let state = JSON.parse(this.dagState.state.dragRedoHistory[stackLenth - 1]);
    this.dagState.state.dragHistory.push(JSON.stringify(this.nodePositionMap));
    this.dagState.markDirty();
    Object.assign(this.nodePositionMap, state);
    this.dagState.state.dragRedoHistory.pop();
    this.dagState.state.showSelectRect = false;
    this.update$.next(true);
    this.emitStateChange();
  }

  // Return updated nodes position from last request
  getNodePositionUpdate() {
    if (this.lastUpdatedPositionMap === null) this.lastUpdatedPositionMap = {};
    const updates: { [nodeId: string]: Point } = {};

    const old_map = this.lastUpdatedPositionMap;
    const new_map = this.nodePositionMap;

    let keys = Object.keys(new_map);

    const isUpdated = (a: Point, b: Point) => {
      return !(
        Math.floor(a.x) === Math.floor(b.x) &&
        Math.floor(a.y) === Math.floor(b.y)
      );
    };

    keys.forEach(nodeId => {
      if (!old_map[nodeId] || isUpdated(old_map[nodeId], new_map[nodeId])) {
        updates[nodeId] = {
          x: Math.floor(new_map[nodeId].x),
          y: Math.floor(new_map[nodeId].y)
        };
      }
    });
    return updates;
  }

  async saveNodesPosition(params: {
    pipelineId: number;
    position: { [nodeId: string]: Point };
  }) {
    await this.nodePositionService.saveNodesPosition(params).toPromise();
  }

  // removeTaskPosition(nodeId:number){
  //   this.nodePositionService.deleteTaskPosition(nodeId).subscribe(()=>{console.log("deleted successfully")});
  // }

  stopProcess() {
    // console.log(this.currentPipeline.id, this.processId, this.currentNodeId)
    const dialogData = new ConfirmationPopupModel(
      'STOP',
      'Are you sure you want to Stop the Process?'
    );
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      maxWidth: '400px',
      closeOnNavigation: true,
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(async dialogResult => {
      if (dialogResult) {
        try {
          const response = await this.taskserive
            .stopNode(
              this.currentPipeline.id,
              this.processId,
              this.currentNodeId
            )
            .toPromise();
          this.uiService.showInfo('Stop request submitted!', 'success');

          if (response.payload.status === 'stopped') {
            this.uiService.showInfo('Process is Stopped');
          } else {
            this.uiService.showInfo('failed to stop the Process!', 'error');
          }
        } catch {
          this.uiService.showInfo('Internal Error', 'error');
        }
      }
    });
  }

  setDagEvent(event: string, args: any) {
    switch (event) {
      case 'DEFAULT':
        this.layout = this.layouts.dragLayout;
        this.update$.next(true);
        break;

      case 'DRAG_ENABLE':
        this.isEditEnable = args.drag_enabled;
        break;

      case 'RESET':
        this.resetPosition();
        break;
      case 'UNDO':
        this.undo();
        break;
      case 'REDO':
        this.redo();
        break;
      case 'SAVE':
        this.saveNodePositionManually();
        break;
      default:
        break;
    }
  }

  public getNodesPosition() {
    const container = (this.graph as any).el.nativeElement as HTMLDivElement;
    const containerRect = container.getBoundingClientRect();

    let nodeMap: {
      [nodeId: string]: Point & { height: number; width: number };
    } = {};
    document.querySelectorAll('.nodes > .node-group').forEach(node => {
      let rect = node.getBoundingClientRect();
      nodeMap[node.id] = {
        x: rect.x,
        y: rect.y - containerRect.top,
        width: rect.width,
        height: rect.height
      };
    });
    return nodeMap;
  }

  moveNodes(nodes: Array<string>, distance: { dx: number; dy: number }) {
    nodes.forEach(nodeId => {
      if (!this.nodePositionMap[nodeId]) return;
      let nodePosition = this.nodePositionMap[nodeId];
      this.nodePositionMap[nodeId] = {
        ...nodePosition,
        x: nodePosition.x - distance.dx / this.zoomValue,
        y: nodePosition.y - distance.dy / this.zoomValue
      };
    });
  }


  removeEdge(link: Link) {
    this.dashboardService.removeEdge(parseInt(link.source), parseInt(link.target))
  }

  async saveNodePositionManually() {
    let updates = this.getNodePositionUpdate();
    if (!Object.keys(updates).length) {
      this.dagState.markSaved();
      return;
    }

    await this.saveNodesPosition({
      pipelineId: this.pipelineId,
      position: updates
    });
    this.lastUpdatedPositionMap = JSON.parse(
      JSON.stringify(this.nodePositionMap)
    );
    this.dagState.markSaved();
  }

  zoomChange(zoomValue: number) {
    this.zoomValue = zoomValue;
  }

  api(e: any) {
    console.log(e);
  }

  copyNode(nodeId: number) {
    this.saveCopyNodeId(nodeId);
    this.saveCopiedNodeData(nodeId);
  }

  async saveCopiedNodeData(nodeId: number) {
    let task_data = await this.dashboardService.getNodeById(nodeId);
    localStorage.setItem('copiedNodeData', JSON.stringify(task_data));
  }

  saveCopyNodeId(nodeId: number | string) {
    localStorage.setItem('copiedNodeId', JSON.stringify(nodeId));
  }

  async setEdgeTransformation() {
    prompt('Set transform expression')
  }

  openNodeMapper() {
    this.callbackEvents.emit({
      type: EventTypes.OPEN_EDGE_MAPPING,
      args: []
    });
  }

  runNodeInstance(node: Node) {
    this.dashboardService.runNodeInstance(node.id)
  }


  onFieldRightClick(node: Node, field: NodeInputField, event: any) {
    event.preventDefault()
    event.stopPropagation()
    console.log(field);
    const target = event.target as HTMLElement;
    const tergetRect = target.getBoundingClientRect();

    const contectMenuElement = (this.contextmenu.nativeElement as HTMLDivElement);
    contectMenuElement.style.display = 'block'
    contectMenuElement.style.left = tergetRect.x + 'px'
    contectMenuElement.style.top = tergetRect.y + 'px'

    this.contextMenuItems = [
      {
        text: "Edit",
        disable: true,
        onClick: () => {}
      },
      {
        text: "Duplicate",
        disable: true,
        onClick: () => {}
      },
      {
        text: "Delete",
        disable:false,
        onClick: async () => {

          // TODO: add comment about code
          const dependentOn: Array<{id: string, nodeInstanceId:string, sourceFieldName: string}> = field.meta.dependentOn;
          if(!dependentOn || dependentOn.length===0)return;

          // FIXME: fix hardcode index
          const firstDependent = dependentOn[0];

          await this.dashboardService.removeDynamicField(node.id as unknown as number, firstDependent.id as unknown as number);

          const selectedNode = ((node.metaData as any).fields || []) as Array<NodeField>;
          (node.metaData as any).fields = selectedNode.filter(x => x.name !== field.name);

          const dynamicInputFields = (node.metaData).dynamicInputFields || {};
          delete dynamicInputFields[field.name];

          node.fields = node.fields.filter(x => x.name !== field.name);

          let links = this.links
          // remove links
          dependentOn.forEach(
            edge => {
              const linkId = `link-${edge.nodeInstanceId}-${node.id}-${edge.sourceFieldName}-${field.name}`
              links = this.links.filter(x => x.id !== linkId);
            }
          )
          this.links = links



        }
      }
    ]

    return false
  }

  openOutputPreview(node: Node, field: NodeInputField) {
    this.callbackEvents.emit({
      type: EventTypes.OPEN_FIELD_OUTPUT,
      args: [node, field]
    });
  }

}

type DagStateType = {
  isEditing: boolean
  showSelectRect: boolean

  selectedNodes: Array<string>
  dragHistory: Array<string>
  dragRedoHistory: Array<string>
  hoverNode?: {id: string, x: number, y: number, width: number, height: number}
  selectRect: {
    start: Point
    end: Point
    tap: Point
  }
}
export class DagState {
  stateName: DagStates = DagStates.NULL
  readonly DagStates = DagStates

  state: DagStateType = {
    isEditing: false,
    showSelectRect: false,

    selectedNodes: [],
    dragHistory: [],
    dragRedoHistory: [],
    selectRect: {
      start: {x: 0, y: 0},
      end: {x: 0, y: 0},
      tap: {x: 0, y: 0}
    }
  }

  constructor(private component: DagComponent) {
  }

  private isCtrlPressed(event: MouseEvent) {
    return IS_MAC ? event.metaKey === true : event.ctrlKey === true
  }

  public isSaved = true
  public markSaved() {
    this.isSaved = true
    this.component.emitStateChange()
  }

  public markDirty() {
    this.isSaved = false
    this.component.emitStateChange()
  }

  private getCursor(event: MouseEvent) {
    const container = (this.component.graph as any).el.nativeElement as HTMLDivElement
    const containerRect = container.getBoundingClientRect()
    const cursor = {
      x: event.pageX,
      y: event.pageY - containerRect.top
    }

    return cursor
  }

  // TODO: add on enter-state to set default data for new state
  private transitions: {[stateName in DagStates]: (event: {type: DagEvents, args: unknown[]})=> unknown} = {
    NULL: (event) => this.nullState(event, this),
    SELECTING: (event) => this.selectingState(event, this),
    SELECTED: (event) => this.selectedState(event, this),
    START_DRAGGING: (event) => this.startDraggingState(event, this),
    DRAGGING: (event) => this.draggingState(event, this),
    NODE_HOVER: (event) => this.nodeHoverState(event, this),
    SINGLE_DRAGGING: (event) => this.singleDraggingState(event, this),
  }

  private startDraggingState(event: {type: DagEvents, args: unknown[]}, state: DagState) {
    const cursor = this.getCursor(event.args[0] as MouseEvent)

    switch (event.type) {
    case DagEvents.CTRL_UP:
      this.gotoNullState();
      break

    case DagEvents.MOUSE_MOVE:
      this.state.dragHistory.push(JSON.stringify(this.component.nodePositionMap))
      this.state.dragRedoHistory = []
      state.markDirty()
      this.stateName = DagStates.DRAGGING;
      break
    }
  }

  next(event: {type: DagEvents, args: unknown[]}) {
    // const lastState = this.stateName

    // forword event
    this.transitions[this.stateName]?.(event);

    // check state
    // if(lastState !== this.stateName) {
    //   console.log(`${lastState} -->   ${this.stateName}`);
    // }
  }

  private gotoNullState() {
    this.state.showSelectRect = false
    this.state.selectedNodes = []
    this.component.emitStateChange()
    this.stateName = this.DagStates.NULL
  }

  private gotoSelectedState(selectedNodes?: string[]) {
    if(Array.isArray(selectedNodes)) {
      this.state.selectedNodes = selectedNodes
      this.component.emitStateChange()
    }
    this.stateName = this.DagStates.SELECTED
  }

  private gotoSelectingState(startPoint: Point) {
    this.state.selectedNodes = []
    this.state.selectRect.tap = startPoint
    this.state.selectRect.start = startPoint
    this.state.showSelectRect = true
    this.component.emitStateChange()
    this.stateName = this.DagStates.SELECTING
  }

  // Null State
  private nullState(event: {type: DagEvents, args: unknown[]}, state: DagState) {
    const cursor = this.getCursor(event.args[0] as MouseEvent)

    switch (event.type) {

    case DagEvents.MOUSE_MOVE:
      // FIXME: cache node positions, detact zoom change and refatch current position
      // Single select
      const nodes = this.component.getNodesPosition();
      const hoverNode = Object.keys(nodes).find(x => isPointInRect({start: nodes[x], end: {x: nodes[x].x + nodes[x].width, y: nodes[x].y + nodes[x].height}}, cursor))
      if(hoverNode) {
        state.state.hoverNode = {id: hoverNode, ...nodes[hoverNode]}
        state.stateName = DagStates.NODE_HOVER
      }
      break

    case DagEvents.MOUSE_DOWN:
      if(!this.isCtrlPressed(event.args[0] as MouseEvent)) return;

      this.state.selectRect.start = cursor
      this.state.selectRect.end = cursor
      this.state.selectRect.tap = cursor
      this.state.showSelectRect = true

      this.component.setSelectRectPosition(this.state.selectRect.start, this.state.selectRect.end)
      state.stateName = DagStates.SELECTING
      break;
    }
  }

  // Selecting multiple nodes state
  private selectingState(event: {type: DagEvents, args: unknown[]}, state: DagState) {
    const cursor = this.getCursor(event.args[0] as MouseEvent)

    switch (event.type) {
    case DagEvents.MOUSE_MOVE:
      if(!this.isCtrlPressed(event.args[0] as MouseEvent)) return;

      this.state.selectRect.end = cursor
      this.component.setSelectRectPosition(this.state.selectRect.start, this.state.selectRect.end)
      break;

    case DagEvents.MOUSE_UP:
      this.state.selectRect.end = cursor
      const selectedNodes = this.component.getNodesInRectArea(this.state.selectRect)

      if(selectedNodes.length > 0) {
        this.gotoSelectedState(selectedNodes)
      }
      else {
        this.gotoNullState()
      }
      
      break;
      
    }
  }

  // Nodes Selected State
  private selectedState(event: {type: DagEvents, args: unknown[]}, state: DagState) {
    const cursor = this.getCursor(event.args[0] as MouseEvent)

    switch (event.type) {
    case DagEvents.MOUSE_DOWN:
      const isCtrlPress = this.isCtrlPressed(event.args[0] as MouseEvent)
      const isRectSelected = isPointInRect(this.state.selectRect, cursor)

      if(!isCtrlPress && !isRectSelected) {
        this.gotoNullState()
        return
      }

      const isEditEnable = this.component.isEditEnable

      if(isRectSelected && isEditEnable) {
        this.state.selectRect.tap = cursor
        this.stateName = this.DagStates.START_DRAGGING
        return
      }

      if(isRectSelected) {
        return
      }
      
      this.gotoSelectingState(cursor)
      
      break;
    }
  }

  // Multiple node dragging state
  private draggingState(event: {type: DagEvents, args: unknown[]}, state: DagState) {
    const cursor = this.getCursor(event.args[0] as MouseEvent)

    switch (event.type) {
      case DagEvents.CTRL_UP:
        this.gotoSelectedState();
        break;

      case DagEvents.MOUSE_UP:
        this.gotoSelectedState();
        break;

      case DagEvents.MOUSE_MOVE:
        // if(!this.isCtrlPressed(event.args[0] as MouseEvent)) return;

        let diff = {
          dx: this.state.selectRect.tap.x - cursor.x,
          dy: this.state.selectRect.tap.y - cursor.y
        };
        const { start, end, tap } = this.state.selectRect;

        this.state.selectRect = {
          ...this.state.selectRect,
          start: { x: start.x - diff.dx, y: start.y - diff.dy },
          end: { x: end.x - diff.dx, y: end.y - diff.dy }
        };
        this.state.selectRect.tap = cursor;
        const selectedNodes = this.state.selectedNodes;
        this.component.moveNodes(selectedNodes, diff);
        this.component.setSelectRectPosition(
          this.state.selectRect.start,
          this.state.selectRect.end
        );
        this.component.update$.next(true);
        break;

      default:
        // console.error(`Invalid action [${event.type}] state: ${this.stateName}`)
        break;
    }
  }

  // Node Hover State
  nodeHoverState(event: {type: DagEvents, args: unknown[]}, state: DagState) {
    const cursor = this.getCursor(event.args[0] as MouseEvent)

    switch(event.type) {
    case DagEvents.MOUSE_MOVE:

      // move to null state if no node is hovered
      if(!state.state.hoverNode) {
        this.gotoNullState()
        return
      }

      // return if curser is in hover node rect
      let hoverNode = state.state.hoverNode
      if(isPointInRect({start: {x: hoverNode.x, y: hoverNode.y}, end: {x: hoverNode.x + hoverNode.width, y: hoverNode.y + hoverNode.height}}, cursor)) {
        return
      }

      state.state.hoverNode = undefined
      state.stateName = DagStates.NULL
      break

    case DagEvents.MOUSE_DOWN:
      if(!this.component.isEditEnable) return;
      this.state.selectRect.tap = cursor
      state.state.dragHistory.push(JSON.stringify(state.component.nodePositionMap))
      state.stateName = DagStates.SINGLE_DRAGGING
      state.markDirty()
      break
    }
  }

  // Single Node Dragging State
  singleDraggingState(event: {type: DagEvents, args: unknown[]}, state: DagState) {
    const [_, nodeId] = event.args as [any, number]
    const cursor = this.getCursor(event.args[0] as MouseEvent)
    switch (event.type) {

    case DagEvents.MOUSE_MOVE:
      if(state.state.hoverNode) {
        
        let diff = {
          dx: this.state.selectRect.tap.x - cursor.x,
          dy: this.state.selectRect.tap.y - cursor.y,
        }
        const {start, end, tap} = this.state.selectRect

        this.state.selectRect = {
          ...this.state.selectRect,
          start: {x: start.x - diff.dx, y: start.y - diff.dy},
          end: {x: end.x - diff.dx, y: end.y - diff.dy},
        }
        this.state.selectRect.tap = cursor
        
        this.component.moveNodes([state.state.hoverNode.id], diff)
      }
      break;

    case DagEvents.MOUSE_UP:
      state.component.update$.next(true)
      state.stateName = state.DagStates.NULL
      break;
    }
  }

}

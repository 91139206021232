import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup} from '@angular/forms';

import { DataService } from '../../services/api/data.service';
import { PipelineService } from '../../services/api/pipeline.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-config',
  templateUrl: './create-config.component.html',
  styleUrls: ['./create-config.component.css']
})
export class CreateConfigComponent implements OnInit {

  status: boolean = true;

  constructor(private router:Router,private pipelineService: PipelineService, private data_service: DataService) { }

  ngOnInit(): void {
  }
  configs: FormGroup = new FormGroup({
  	json: new FormControl(),
  });


  tryParse(data:string){
    let json = null
    try {
      json = JSON.parse(data);
      this.status = true

  } catch(error) {
    this.status = false      
  }
  return json;
  }


  addconfig(userData: string){
    let json_data = this.tryParse(userData)
  	if(json_data)
    {
  	this.pipelineService.createConfig(json_data).subscribe((response)=>{
  		console.log(json_data);
  		console.log(response);
  		// this.pipelineService.getPipeline(response.payload.pipelineId).subscribe((response)=>{
  		// 	this.data_service.changePipeline(response);
  		// 	this.data_service.subPipeline.next(response);
  		// 	console.log(this.data_service.currentPipeline);
  		// })
  		// console.log(this.data_service.currentPipeline);
  		// this.pipeline_id = response.payload.pipelineId;
  		// console.log(this.pipeline_id);
  		// this.router.navigate(['/dashboard',this.pipeline_id]);
  	});
  }
}

  onSubmit(){
  	console.log(this.configs.value);
  	this.addconfig(this.configs.value.json);
  	this.configs.reset({});

  }

}

<nb-card class="main">
    <nb-card-header>
        SCHEMA
        <button nbButton  style="float: right;"  (click)="no_change()"><nb-icon icon="close-outline" ></nb-icon></button>
    </nb-card-header>
    <nb-card-body>
        <div>
            <button nbButton status="basic" shape="round" style="float:right;" (click)="addSheet(sheet_name)">+ Add Sheet</button>
        </div>
        <nb-tabset>
            <nb-tab *ngFor="let sheet of Sheets; let i = index;" [tabTitle]="sheet.sheetName || sheet.name" [active]="i==0">
                <app-add-schema [schemaResponse]="sheet" [hasSchema]="!(sheet.id == undefined)" [match_type]="!(sheet.id == undefined)?'001':'002'" (delete)="delete_sheet(i)">
                </app-add-schema>
            </nb-tab>
        </nb-tabset>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton status="basic" shape="round" style="float: right;" (click)="no_change()">Cancel</button>
        <button nbButton status="primary" shape="round" style="float: right;" (click)="submit()">Submit</button>
    </nb-card-footer>
</nb-card>

<ng-template #sheet_name let-data let-ref="dialogRef">
    <nb-card class="sheet-name">
        <nb-card-body>
            Sheet Name: <input nbInput placeholder="new sheet Name" [(ngModel)]="new_sheet_name">
        </nb-card-body>
        <nb-card-footer style="align-items: center; margin: 1rem;">
            <button nbButton (click)="ref.close(new_sheet_name)">OK</button>&nbsp;
            <button nbButton (click)="ref.close()">Cancel</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<section *ngIf="!vertical">
  <div class="multi-progress-bar">
    <div *ngFor="let p of progress" [style]="{'--data-done':p.count+'%', '--data-color':p.color}" [nbTooltip]="p.countShow +' '+ p.name" nbTooltipPlacement="top" [nbTooltipStatus]="p.tooltipStatus">
<!--      <span style="position: absolute;margin-top: .7rem" *ngIf="showInfo"><code>{{p.count}}% </code> {{p.name}}</span>-->
    </div>
<!--    <div [style]="{'&#45;&#45;data-done':'30%', '&#45;&#45;data-color':'gray'}">-->
<!--      <span style="position: absolute;margin-top: .7rem" *ngIf="showInfo">in queue</span>-->
<!--    </div>-->
  </div>
</section>


<section style="height:100%;position: absolute" *ngIf="vertical">
  <div class="multi-progress-bar-vertical">
    <div *ngFor="let p of progress" [style]="{'--data-done':p.count+'%', '--data-color':p.color}">
      <span style="position: absolute;margin-left: .7rem;white-space: nowrap" *ngIf="showInfo"><code>{{p.countShow}}</code></span>
      <span style="position: absolute;margin-left: .7rem;white-space: nowrap;margin-top: 1rem" *ngIf="showInfo">{{p.name}}</span>
    </div>
  </div>
</section>

<style>

  .multi-progress-bar {
    --bar-height: .3rem;
    --bar-width: 100%;
    --bar-border-radius: calc(var(--bar-height) / 2);

    width: var(--bar-width);
    min-height: var(--bar-height);
    background-color: #ffffff42;
    display: flex;
    box-shadow: 0 0 10px #54545473;
    border-radius: var(--bar-border-radius);
  }

  .multi-progress-bar > div:first-child {
    border-top-left-radius: var(--bar-border-radius);
    border-bottom-left-radius: var(--bar-border-radius);
  }
  .multi-progress-bar > div:last-child {
    border-top-right-radius: var(--bar-border-radius);
    border-bottom-right-radius: var(--bar-border-radius);
  }

  .multi-progress-bar > div {
    background-color: var(--data-color, #1bc965);
    min-height: 100%;
    width: var(--data-done, 0%);
    transition: width 1s;
    cursor: pointer;
  }

  .multi-progress-bar > div > span {
    max-height: 0;
    transition: max-height 1s;
    overflow: hidden;
  }

  .multi-progress-bar > div:hover > span {
    max-height: 20rem;
  }




  .multi-progress-bar-vertical {
    --bar-height: 30rem;
    --bar-width: .5rem;
    --bar-border-radius: calc(var(--bar-width) / 2);

    width: var(--bar-width);
    height: 100%;
    background-color: #ffffff42;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px #54545473;
    border-radius: var(--bar-border-radius);

    position: relative;
  }

  .multi-progress-bar-vertical > div:first-child {
    border-top-left-radius: var(--bar-border-radius);
    border-top-right-radius: var(--bar-border-radius);
  }
  .multi-progress-bar-vertical > div:last-child {
    border-bottom-left-radius: var(--bar-border-radius);
    border-bottom-right-radius: var(--bar-border-radius);
  }


  .multi-progress-bar-vertical > div {
    background-color: var(--data-color, #1bc965);
    height: var(--data-done, 0%);
    transition: height 1s;
    cursor: pointer;
  }

  .multi-progress-bar-vertical > div > span {
    max-width: 0;
    transition: max-width 1s;
    overflow: hidden;
  }

  .multi-progress-bar-vertical > div:hover > span {
    max-width: 20rem;
  }
</style>

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import * as XLSX from 'xlsx';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-excel-preview',
  templateUrl: './excel-preview.component.html',
  styleUrls: ['./excel-preview.component.css']
})
export class ExcelPreviewComponent implements OnInit {
   @Input()
  data: any;
  cols:any = [];
  constructor(public cdr: ChangeDetectorRef) { }
  dtRendered = true;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  dtData: any = [];

  ngOnInit(): void {
  	this.dtOptions = {

  	};
  }

  onFileChange(evt: any) {
  	const target : DataTransfer =  <DataTransfer>(evt.target);

  	if (target.files.length !== 1) throw new Error('Cannot use multiple files');

  	const reader: FileReader = new FileReader();

  	reader.onload = (e: any) => {
  		const bstr: string = e.target.result;

  		const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

  		const wsname : string = wb.SheetNames[0];

  		const ws: XLSX.WorkSheet = wb.Sheets[wsname];

  		let jsonData = wb.SheetNames.reduce((initial:any, name:any) => {
  			const sheet = wb.Sheets[name];
  			initial[name] = XLSX.utils.sheet_to_json(sheet);
  			return initial;
  		}, {});
  		console.log(jsonData);

  		console.log(ws);

  		this.data = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
  		console.log(this.data);
  		console.log(this.data[0]);
  		this.cols = [];
  		for(let i of this.data[0]){
  			this.cols.push({
  				"title": i,
  				"data": i
  			});
  		}


  		console.log(this.dtData);

  		console.log(this.cols);

  		let x = this.data.slice(1);
  		this.data = x;
  		console.log(x);
  		this.dtRendered = false
  		this.dtOptions = {
  			columns: this.cols,
  			data: jsonData[wsname],
  			scrollX: true
  		};
  		// make sure your template notices it
  		this.cdr.detectChanges();
  		// initialize them again
  		this.dtRendered = true
  		this.cdr.detectChanges();

  	};
  	reader.readAsBinaryString(target.files[0]);


  }

}

<div style="background-color: white;">
  <div *ngIf="accountType==='platform'">
    <account-management-header></account-management-header>
    <account-management-manageracc (switchAccount)="switchAccount($event)" (showInfo)="openDetails($event)" [accountsList]="masterAccounts" (getId)="openClientDetails($event)">
      <simplify-pagination slot="pagination" *ngIf="totalCount>10" [totalCount]="totalCount" [pageIndex]="pageIndex" [itemsPerPage]="itemsPerPage" [pageSizeOptions]="pageSizeOptions" (paginate)="onPaginationEvent($event)"></simplify-pagination>
    </account-management-manageracc>
  </div>

  <ng-container *ngIf="accountType==='master'">
    <account-management-header header-name="Client Accounts"></account-management-header>
    <account-management-manageracc (switchAccount)="switchAccount($event)" (showInfo)="openDetails($event)" [accountsList]="clientAccounts" [isMaster]="false">
      <simplify-pagination slot="pagination" *ngIf="totalCount>10" [totalCount]="totalCount" [pageIndex]="pageIndex" [itemsPerPage]="itemsPerPage" [pageSizeOptions]="pageSizeOptions" (paginate)="onPaginationEvent($event)"></simplify-pagination>
    </account-management-manageracc>
  </ng-container>
  

</div>


<ng-template #portalContent>
    <account-management-client-details [accountSideBar]="accountSideBar" (close)="this.portalBridge.show = false;"></account-management-client-details>
  </ng-template>
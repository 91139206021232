
<ng-container *ngIf="data.type==='select'">
  <select style="width: 100%;">
    <option>A</option>
    <option>B</option>
    <option>C</option>
  </select>
</ng-container>



<ng-container *ngIf="data.type==='text'">

  <input
    [value]="data.value"
    [readonly]="data.readonly"
    [type]="data.type"
    (input)="onChange($event)"
  />
</ng-container>


<ng-container *ngIf="data.type==='number'">
  <input
    [value]="data.value"
    [readonly]="data.readonly"
    [type]="data.type"
    (input)="onChange($event)"
  />
</ng-container>

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Context } from '../config/context.service';


export type groupResponse={
  payload:{
    [key:string]:{
      id : number,
      name : string,
      icon : string,
      comment : string,
      email : string,
      created_time:Date,
      updated_time:Date
    }
  },
  status: string,
  error_message:string
}

export type group = {
  id?: number,
  name: string,
  email?: string,
  icon?: string,
  comments?: string,
  createdBy?: string
}


@Injectable({
  providedIn: 'root'
})

export class GroupService {

  
  url = {};

  groupsData = new Subject<group>();


  constructor(private http: HttpClient, private context:Context) {
    this.url = context.getApiConfig().GROUP_APIS;
   }

  //create group
  createGroup(data: any){
    return this.http.post(this.context.getApiConfig().GROUP_APIS.POST.newGroup,data);
  }

  listGroups(){
    return this.http.get<groupResponse>(this.context.getApiConfig().GROUP_APIS.GET.listGroups).pipe(
      map((data) => {
        return data.payload;
      }),
    );
  }

  /*
  {
    groupId:
    pipelineIds:[]
  }
  */
  addToGroup(data: any){
    return this.http.put(this.context.getApiConfig().GROUP_APIS.PUT.addToGroup,data);
  }

  getGroupById(id:number|string){
    return this.http.get<group>(this.context.getApiConfig().GROUP_APIS.GET.groupById + id).pipe(
      map((data:any)=>{
        return data.payload;
      })
    );
  }

  editGroup(id: any, data:any){
    return this.http.put(this.context.getApiConfig().GROUP_APIS.PUT.editGroup+'?id='+id, data);
  }
}


<nb-layout>
  <nb-layout-column style="padding:0">
    <ng-container *ngIf="(layoutType|async)==='old'">
      <router-outlet></router-outlet>
    </ng-container>


    <ng-container *ngIf="(layoutType|async)!=='old'">
      <mat-sidenav-container hasBackdrop="true" fullscreen>
        <mat-sidenav #sidenav disableClose="true" mode="over" position="end" [opened]="sideNavService.show">
          <app-action-area></app-action-area>
        </mat-sidenav>
        
        <bridge-layout [isFullScreen]="(layoutType|async)==='fullScreen'">
          <simplify-header slot="header" [user]='{"name":context.principle.user?.name}' (logoutEvent)="customLogout($event)">
            <simplify-account-switch slot="account-switch" [accountsList]="allAccounts" [accountDetails]="accountDetails" (switchAccount)="switchAccount($event)"></simplify-account-switch>
            <simplify-nav slot='nav'>
              <simplify-nav-item *ngIf="roles==='rrole:admin'" routerLinkActive="active" routerLink="dashboard" value='dashboard'>
                <simplify-icon name='dashboard-line' lib='remix'></simplify-icon>
                Dashboard
              </simplify-nav-item>
              <simplify-nav-item routerLinkActive="active" routerLink="pipelines" value="pipelines" (click)="switchToRoute('pipelines(view:pipeline-list)')">
                <simplify-icon name='flow-chart' lib='remix'></simplify-icon>
                Pipelines
              </simplify-nav-item>
              <simplify-nav-item *ngIf="roles==='rrole:admin'" routerLinkActive="active" routerLink="templates" value="templates" (click)="switchToRoute('templates(view:view-templates)')">
                <simplify-icon name='layout-4-line' lib='remix'></simplify-icon>
                Templates
              </simplify-nav-item>
              <!-- <simplify-nav-item routerLinkActive="active" routerLink="connections" value="connections" (click)="switchToRoute('connections')">
                <simplify-icon name='link' lib='remix'></simplify-icon>
                Connections
              </simplify-nav-item> -->
              <simplify-nav-item *ngIf="accountDetails.type !== 'client'" routerLinkActive="active" routerLink="accounts" value="accounts" (click)="switchToRoute('accounts')">
                <simplify-icon name='group-line' lib='remix'></simplify-icon>
                Accounts
              </simplify-nav-item>
              <div style="position: relative;" *ngIf="roles==='rrole:admin'">
                <simplify-nav-item value="more"  data-popover-id="navlist-o1">                   
                  More
                  <simplify-icon name='arrow-down-s-line' lib='remix'></simplify-icon>
                </simplify-nav-item>
                <simplify-popover target="simplify-nav-item[data-popover-id=navlist-o1]" class="mt-4 headeritem-wrapper" placement="bottom-end">
                  <div>
                    <simplify-list>
                        <simplify-list-item [routerLink]="['topic']" [replaceUrl]="true" [relativeTo]="route.parent">
                          <div class="d-flex align-items-center gap-2">
                            <simplify-icon class="text-dark" size="large" name='file-list-line' lib='remix'></simplify-icon>
                            <p class="mb-0 text-grey">Topics</p>
                          </div>
                        </simplify-list-item>
                    </simplify-list>
                  </div>
              </simplify-popover>
              </div>
              
              
    
            </simplify-nav>
    
          </simplify-header>
          <div slot="sidebar"><router-outlet></router-outlet></div>
          <div><router-outlet name="view"></router-outlet></div>
          
          
        </bridge-layout>
      </mat-sidenav-container>
      
    </ng-container>

</nb-layout-column>
</nb-layout>
<!--  -->

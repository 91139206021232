<div class="form_border">

    <form  [formGroup]="configs" method="post" (ngSubmit)="onSubmit()">
      <div>
        <h3>Create new configs</h3>
      </div>
      <div class="form-group">
        <label for="name" >Configs Details</label>
        <pre><textarea  type="text" class="form-control" formControlName="json" id="json" required style="margin-top:5px; "></textarea></pre>      
    </div>
        <br>

        <span>
            <nb-icon *ngIf="status" icon="checkmark-outline"  style="color:green; size: 5px;"></nb-icon>
            
            <nb-icon  *ngIf="!status" icon="close-outline" style="color:red;"></nb-icon>
            </span>

      <br>
      <div class="form-submit form-group">
      <button class="btn btn-primary" type="submit" [disabled]="configs.invalid">
        Create
      </button>
      </div>
    </form>
  </div>
  
  <router-outlet></router-outlet>
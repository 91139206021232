<div class="bulk-upload-card bulk-upload-card-full-width">

  <div class="card-header d-flex align-items-center">
    <div class="card-title mb-0">Cell Validations</div>
    <div style="cursor: pointer;" (click)="toggleErrorList()" class="error ms-4"><i class="ri-information-fill"></i> {{cellCheckErrors?.length}} Errors </div>
    <div class="ms-auto">
      <div class="d-flex align-items-center gap-3">
        <!-- <div class="d-flex align-items-center">
          <label>Rows:</label>
          <select class="form-control border-0 form-select">
            <option>All</option>
            <option>All</option>
            <option>All</option>
          </select>
        </div>
        <div class="d-flex align-items-center">
          <label>Scale:</label>
          <select class="form-control border-0 form-select">
            <option>100%</option>
            <option>75%</option>
            <option>50%</option>
          </select>
        </div> -->
        <a (click)="createUrl(downloadFileUrl, pipelineName +'_cell_validation')" style="cursor: pointer;" class="btn-link text-blue text-decoration-none ms-auto fs-body-default text-blue"><simplify-icon
            class="text-dark" name='download-2-line' size='medium' lib='remix'></simplify-icon> Download</a>
        <!-- <simplify-button class="mx-1" status='outline-primary' data-popover-id="lastViewed">
          <simplify-icon name='edit-2-fill' size='medium' lib='remix'></simplify-icon>
          Edit
        </simplify-button> -->
        <a (click)="onTableClose()" style="cursor: pointer;" data-role='close-card'>
          <simplify-icon name='close-line' size='large' lib='remix'></simplify-icon>
        </a>
      </div>
    </div>
  </div>
  <div class="card-body p-0">
    <div class="table-responsive w-100">
      <div class="formula-bar">
        <div class="formula-label">
          <Span
            *ngIf="selectedRowIndex && selectedColumnIndex !== null">{{selectedRowIndex+1}}{{selectedColumnIndex}}</Span>
        </div>
        <div class="formula_input w-100">
          <input [(ngModel)]="formulaInput || selectedCellValue" (input)="onCellEditingStopped($event)"
            class="formula-input">
        </div>
      </div>
      <ag-grid-angular #agGrid style="height: calc(100vh - 293px);" class="ag-theme-alpine" 
        [rowData]="cellCheckTableData"
        rowHeight="100px"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        (cellValueChanged)="onCellValueChanged($event)"
        (cellClicked)="onCellClicked($event)"
        enableBrowserTooltips="true"
        (cellEditingStopped)="onCellEditingStopped($event)"
        (cellEditingStarted)="onCellEditingStarted($event)"
        (gridReady)="onGridReady($event)"
        (rowSelected)="onRowSelected($event)"
        [rowSelection]="rowSelection"
        [tooltipShowDelay]="tooltipShowDelay">
      </ag-grid-angular>
    </div>
    <div *ngIf="isErrorListVisible" class="bulk-error-list-card">
      <simplify-file-bulk-upload-data-errorlist
        (onclose)="toggleErrorList()"
        (oncellclick)="handleCellClick($event)"
        [isErrorListVisible]="isErrorListVisible"
        [errorData]="cellCheckErrors"
        [effected_cell]="effected_cell">
      </simplify-file-bulk-upload-data-errorlist>

    </div>

  </div>

  <div class="bulk-upload-card-footer">

    <div class="bulk-upload-card-button text-end">
      <simplify-button (click)="onCloseEditCell()" status='primary' size='small'>Upload Again</simplify-button>
    </div>
  </div>

</div>

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ConfigModel} from "../../models/config.model";

@Component({
  selector: 'app-edgeconnector',
  templateUrl: './edgeconnector.component.html',
  styleUrls: ['./edgeconnector.component.css']
})
export class EdgeconnectorComponent implements OnInit {

  data: ConfigModel[] = [];
  show: boolean = true;
  private _searchContent:string=""
  private _searchItem:string=""
  panelOpenState = false;

  // @ts-ignore
  configTypeMap: {[key:string]:Set<string>} = {}
  searchResults: Record<ConfigModel['type'], {[key:string]:Array<ConfigModel>}> = {} as any
  _currentConfigType: string =""
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogParams: { configs: Array<ConfigModel>, title: string },
    public dialogRef: MatDialogRef<EdgeconnectorComponent>) {
    dialogRef.disableClose = true;  
    this.data = dialogParams.configs;
    console.log(this.data)
    this.configTypeMap = this.data.reduce((prev, current) => {
      if(!prev[current.type])prev[current.type] = new Set<string>()
      prev[current.type].add(current.label)

      return prev
    }, {} as {[key:string]:Set<string>})

    
    // Select default
    let configTypes = Object.keys(this.configTypeMap);
    console.log(configTypes)
    if(configTypes.length) {
      this.currentConfigType=configTypes[0]
      this.filterSearchResults()
    }

  }

  ngOnInit(): void {}

  sendConfigId(config:any) {
  	this.dialogRef.close(config);
  	
  }

  sendConfig(event:any){
    // const payload = {...this.data[0], id: event.detail}
    this.dialogRef.close(this.data[1]);
  }

  // getSelectedConfigs(configs: Array<ConfigModel>) {
  //   console.log(configs)
  //   if(!this.searchContent)return configs
  //   let p = configs.filter(x=>x.name.includes(this.searchContent) || x.displayName.includes(this.searchContent) || x.desc.includes(this.searchContent))
  //   return p
  // }

  

  get searchContent() {
    return this._searchContent
  }
  get searchItem() {
    return this._searchItem
  }
   
  private filterSearchResults() {
    const type = this._searchItem ? '' : this.currentConfigType.toLocaleLowerCase()
    const configs = this.data.filter(x => x.type.toLocaleLowerCase().includes(type) && x.displayName.toLocaleLowerCase().includes(this._searchItem) && (x.displayName||'').toLocaleLowerCase().includes(this._searchContent));

    // this.searchResults = configs.reduce((prev, curr) => {
    //   if(!prev[curr.label]) prev[curr.label] = []
    //   prev[curr.label].push(curr)
    //   return prev;
    // }, {} as any)
    this.searchResults = configs.reduce((prev, current) => {
      if(!prev[current.type])prev[current.type] = {}
      if(!prev[current.type][current.label]) prev[current.type][current.label] = []

      prev[current.type][current.label].push({...current})
      return prev
    }, {} as Record<ConfigModel['type'], {[key:string]:Array<ConfigModel>}>)
    console.log(this.searchResults)
  } 

  set searchContent(value) {
    this._searchContent = value.toLocaleLowerCase()
    this.filterSearchResults()
  }

  set searchItem(value) {
    this._searchItem = value.toLocaleLowerCase()
    this.filterSearchResults()
  }

  get currentConfigType(){
    return this._currentConfigType
  }
 
  set currentConfigType(value){
    this._currentConfigType = value.toLocaleLowerCase()
    this.filterSearchResults()
  }

  close() {
    this.dialogRef.close();
  }

}


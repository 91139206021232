import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Context } from '../config/context.service';
import { map } from 'rxjs/operators';

export type recipient_request = {
  pipelineId?:number|string,
  email:string,
  event: []
};

@Injectable({
  providedIn: 'root'
})

export class NotificationsService {

  notification_url = this.context.getApiConfig().NOTIFICATION_APIS;
  constructor(private _http:HttpClient, private context: Context) { }

  add_recipient(data: recipient_request){
    return this._http.post(this.notification_url.POST.add_recipient, data);
  }

  update_recipient(notification_id:number, data: recipient_request){
    return this._http.put(this.notification_url.PUT.update_recipient+notification_id, data);
  }

  delete_recipient(notification_id:number){
    return this._http.delete(this.notification_url.DELETE.delete_recipient+notification_id);
  }

  get_recipients_by_id(pipelineId: number){
    return this._http.get(this.notification_url.GET.get_notifications_by_id+pipelineId).pipe(
      map((data: any) => {
        return data.payload;
      }),
    );;
  }
}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConfigureApiService } from 'src/app/services/api/configure-api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-data-connection',
  templateUrl: './data-connection.component.html',
  styleUrls: ['./data-connection.component.css']
})
export class DataConnectionComponent implements OnInit {

  newConnectionId:number|null = null

  connection_details = {
    connectionId:'',
    identifier: '',
    client_id: '',
    ip_address: '',
    port: '',
    username: '',
    password: '',
    status: false
  };

  status:boolean = false;

  mysqlDbForm: FormGroup = this.fb.group({
    identifier: '',
    client_id: '',
    ip_address: '',
    port: '',
    username: '',
    password: ''
  });
  connectionId:any;

  constructor(
    
    @Inject(MAT_DIALOG_DATA) public dialogParams: any,
    private fb:FormBuilder,
    private apiService: ConfigureApiService,
    public dialogRef: MatDialogRef<DataConnectionComponent>) { 
    dialogRef.disableClose = true;  
    console.log(dialogParams)
    if(dialogParams && dialogParams.connection) {
      this.newConnectionId = dialogParams.connection.id;
      this.mysqlDbForm= this.fb.group({
        identifier: dialogParams.connection.identifier,
        client_id: dialogParams.connection.client_id,
        ip_address: dialogParams.connection.ip_address,
        port: dialogParams.connection.port,
        username: dialogParams.connection.username,
        password: dialogParams.connection.password
      });
    }
  }

  ngOnInit(): void {
  }

  async createConnection() {

    let dbData = this.mysqlDbForm.value;
    this.connection_details = {
      connectionId: '',
      identifier: dbData["identifier"],
      client_id: dbData["client_id"],
      ip_address: dbData["ip_address"],
      port: dbData["port"],
      username: dbData["username"],
      password: dbData["password"],
      status: false
    };
    
    const res = await this.apiService.createNewConnection({
      identifier: dbData["identifier"],
      client_id: dbData["client_id"],
      ip_address: dbData["ip_address"],
      port: dbData["port"],
      username: dbData["username"],
      password: dbData["password"]
    }).toPromise();
   
    console.log(res);
    this.connection_details.connectionId = res.payload.connectionId;
    console.log(this.connectionId);
    this.connection_details.status = res.status == "success";


    this.close()
  }
  

  async updateConnection(){

    let dbData = this.mysqlDbForm.value;
    this.connection_details = {
      connectionId: '',
      identifier: dbData["identifier"],
      client_id: dbData["client_id"],
      ip_address: dbData["ip_address"],
      port: dbData["port"],
      username: dbData["username"],
      password: dbData["password"],
      status: false
    };
    
    const res = await this.apiService.editConnection(this.newConnectionId as number, {
      identifier: dbData["identifier"],
      client_id: dbData["client_id"],
      ip_address: dbData["ip_address"],
      port: dbData["port"],
      username: dbData["username"],
      password: dbData["password"]
    }).toPromise();

    this.connection_details.connectionId = res.payload.connectionId;
    console.log(this.connectionId);
    this.connection_details.status = res.status == "success";

  }

  validate(){

  }

  close(){
    console.log(this.connectionId);
    this.dialogRef.close(this.connection_details);
  }

}

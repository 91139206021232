<nb-card card model-full>
    <nb-card-header>
SCHEDULE PIPELINE
        <button nbButton  style="float: right;" (click)="close()"><nb-icon icon="close-outline" ></nb-icon></button>
    </nb-card-header>

    <nb-card-body>
        <label> Start Date
        <!-- <input [nbDatepicker]="dateTimePicker"  [(ngModel)]="date">
        <nb-date-timepicker #dateTimePicker  singleColumn
        [step]="10"></nb-date-timepicker> -->

        <input nbInput placeholder="Pick Date" [nbDatepicker]="dateTimePicker" [(ngModel)]="date">
        <nb-date-timepicker
          #dateTimePicker
          singleColumn
          [step]="10"></nb-date-timepicker>
    </label>
    <br>

    <label> cronexp
        <input  
[(ngModel)]="text"
type="text"  style="margin-top: 1rem;"/>


<span>
<nb-icon *ngIf="isValidExp" icon="checkmark-outline" style="color:green;"></nb-icon>

<nb-icon  *ngIf="!isValidExp" icon="close-outline" style="color:red;"></nb-icon>
</span>
<!-- <div>{{date?.getTime()}}

</div> -->
</label>
<br>

<p style="margin-top: 1rem;"> {{human}} </p>


<br>


    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
    <button nbButton  shape= "round" status="primary" style="margin-top: 1rem; position:static;" (click)="addSchedule()">schedule</button>
    </nb-card-footer>

</nb-card>
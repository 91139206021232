<simplify-template-configuration-header (backClick)="goBack($event)" [templateCardData]="template$|async"></simplify-template-configuration-header>
<!-- <simplify-templates-configuration-successfactors [templateCardData]="template$|async"></simplify-templates-configuration-successfactors> -->
<div *ngIf="(template$ | async)?.id === 1">
    <simplify-templates-configuration-tabs [templateCardData]="template$|async"></simplify-templates-configuration-tabs>
</div>
<div *ngIf="(template$ | async)?.id === 7">
    <simplify-templates-configuration-successfactors [templateCardData]="template$|async"></simplify-templates-configuration-successfactors>
</div>

<div *ngIf="((template$ | async)?.id !== 1) && ((template$ | async)?.id !== 7)">
    <simplify-templates-configuration-workday [templateCardData]="template$|async"></simplify-templates-configuration-workday>
</div>

import { Component, Input, OnInit } from '@angular/core';
import {NbComponentStatus, NbDialogRef, NbDialogService, NbToastrService} from '@nebular/theme';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';

import { NotificationsService } from 'src/app/services/api/notifications.service';

@Component({
  selector: 'app-notification-recipient',
  templateUrl: './notification-recipient.component.html',
  styleUrls: ['./notification-recipient.component.css']
})
export class NotificationRecipientComponent implements OnInit {

  @Input()
  pipelineId:any;

  $recipients:any;
  

  recipientsForm:FormGroup = this.fb.group({
    email: this.fb.control(''),
    event: this.fb.control([]),
    recipients: this.fb.array([])
  });

  constructor(private fb: FormBuilder,
    private dialogRef: NbDialogRef<NotificationRecipientComponent>,
    private notification_service: NotificationsService,
    private toastr_service: NbToastrService) {

        
     }

  ngOnInit(): void {
    this.get_recipients();    
  }

  getRecipient(){
    this.notification_service.get_recipients_by_id(this.pipelineId);
    return this.recipientsForm.get('recipients') as FormArray;
  }

  addRecipient(email:string, event:any){
    this.get_recipients();
    this.recipientsForm.controls.email.reset();
    this.recipientsForm.controls.event.patchValue([]);
    
    this.notification_service.add_recipient({
      pipelineId:this.pipelineId,
      email: email,
      event: event
    }).subscribe((response:any)=>{
      if(response.status == "success"){
        this.show_toaster("Added recipient successfully", 'success');
        this.get_recipients();
      }
      else{
        this.show_toaster("Error occured", 'danger');
      }
    });
  }

  newRecipient(email:string, event:any):FormGroup{
    return this.fb.group({
      email: this.fb.control(email),
      event: this.fb.control(event)
    });
  }

  removeRecipient(recipientId: number){
    this.notification_service.delete_recipient(recipientId).subscribe(
      (response:any)=>{
        if(response.status == "success"){
          this.recipientsForm.removeControl(`${recipientId}`);
          this.show_toaster("deleted successfully",'success');
          this.get_recipients();
        }
        else{
          this.show_toaster("Error",'danger');
        }
      }
    );
  }

  updateRecipient(recipientId: number){
    
    this.notification_service.update_recipient(recipientId,{
      email:this.recipientsForm?.get(`${recipientId}`)?.value['email'],
      event:this.recipientsForm?.get(`${recipientId}`)?.value['event']//[0]
    }).subscribe(
      (response:any)=>{
        if(response.status == "success"){
          this.show_toaster("updated successfully",'success');
          this.get_recipients();
        }
        else{
          this.show_toaster("Error",'danger');
        }
      }
    );
  }

  show_toaster(message?:string, status?:NbComponentStatus){
    this.toastr_service.show(
      '',
      message,
      // @ts-ignore
      {position: 'bottom-right', status: status, duration: 5000}
    );
  }

  get_recipients(){
    console.log(this.pipelineId);
    this.notification_service.get_recipients_by_id(this.pipelineId).subscribe(
      (response:any)=>{
        console.log(response);
        this.$recipients = response;
        console.log(this.$recipients);
        this.refill();
      },
      (error)=>{
        console.log(error);
      }
    );
  }

  refill(){
    for(let recipient of this.$recipients){
      this.recipientsForm.addControl(recipient.id,this.newRecipient(recipient.email,recipient.events.map((event:any) => {return event.event})));
    console.log(recipient.events.map((event:any) => {return event.event}));
    }
  }

  extract_events(){

  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigureApiService } from 'src/app/services/api/configure-api.service';
import {apiKey} from '../../models/api.model';
@Component({
  selector: 'app-sink-set-mapping',
  templateUrl: './sink-set-mapping.component.html',
  styleUrls: ['./sink-set-mapping.component.css']
})
export class SinkSetMappingComponent implements OnInit {

  constructor(
    private fb:FormBuilder, public dialogRef: MatDialogRef<SinkSetMappingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private configureAPISerivice: ConfigureApiService
  ) {
    this.getSchema(this.data.pipelineId,this.data.processId,this.data.taskId);
    console.log(data);
    this.getAPIkeys(this.data.api);


  }

    schema:any;
    getSchema(pipelineId:any, processId:any, taskId:any){
    	this.configureAPISerivice.getNodeSchema({
    		"pipelineId": pipelineId,
    		"processId": processId,
    		"taskId": taskId
    	}).subscribe((response:any)=>{
    		console.log(response);
    		this.schema = response.payload.columns;
    		console.log("schema",this.schema);
    	});
    }
  //to collect data of mapping
  apiForm: FormGroup = new FormGroup({});
  apiKeys:any=[];
  columns:any = [];
  ngOnInit(): void {
  	this.apiForm = this.fb.group({

  	});
  }

  //call in get request of api_key
  createFormControl(){
  	this.apiKeys.forEach((api:any)=>{
  		this.apiForm.addControl(api.keyId, new FormControl(''))
  	})
	if(this.data.data != null){
		this.updateKeys();
	}
  }

  show_data(){
  	console.log(this.apiForm.value);
  	this.sendMappings();
  }
  api_keys_data: any = {
  	"apiId": "123",
  	"apiName": "Program",
  	"apiURL": "/simplify/program/new",
  	"apiMethod": "POST",
  	"payload": [
  		{
  			"keyId":"1234",
  			"key": "id",
  			"dataType": "Integer"
  		},
  		{
  			"keyId":"1235",
  			"key": "programName",
  			"dataType": "String"
  		},
  		{
  			"keyId":"1236",
  			"key": "clientName",
  			"dataType": "String"

  		}
  	]
  };

  api_keys = this.api_keys_data.payload;
  column_data: any = [{
  	"id":124,
  	"columnName":"name",
  	"dataType":"string"
  },
  {
  	"id":154,
  	"columnName":"income",
  	"dataType":"int64"
  },
  {
  	"id":324,
  	"columnName":"id",
  	"dataType":"string"
  }]
    ;

  getAPIkeys(id:any){
  	this.configureAPISerivice.getAPIById(id).subscribe(
  		(response:any)=>{
  			this.apiKeys = response.payload.payload;
  			console.log("API Keys",this.apiKeys);
  			this.createFormControl();
  		}
  	);
  }

    mapping: any = [];

    sendMappings(){
    	let key_mapping = this.apiForm.value;
    	for(const element of Object.keys(key_mapping)){
    		this.mapping.push({
    			apiKeyId:element,
    			columnKeyId: key_mapping[element]
    		});
    	};
    	console.log(this.mapping);
    	this.dialogRef.close({
    		apiId:this.data.api,
    		mappings:this.mapping
    	});
    }

	updateKeys(){
		this.data.data.forEach((api:any)=>{
			console.log(api);
			this.apiForm.controls[api.apiKeyId].setValue(api.columnKeyId);
		});
	}
}

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { debounce, throttle } from 'rxjs/operators';
import { Subject, Subscription, interval } from 'rxjs';
import {EventTypes as CodeMirrorComponentEvents, EditorComponent} from '../../../components/codemirror/codemirror.component'
import { DashboardStoreService } from 'src/app/services/store/dasboard-store.service';
import * as CodeMirror from 'codemirror';
import { DropdownOptionType, InputFieldType } from '../tasks/addtask/add-task.component';
import { ConfigKeyModel } from 'src/app/models/config.model';
import { UIService } from 'src/app/services/config/ui.service';


type ConfigTypes = 'input'| 'detail' | 'group' | 'code' | 'key' 
export interface IConfig {
  name: string
  displayText: string
  placeholder?: string
  type: ConfigTypes
  inputType?: 'text' | 'file' | 'select' | 'map'
  children?: Array<IConfig>
}

@Component({
  selector: 'app-custom-node-editor',
  templateUrl: './custom-node-editor.component.html',
  styleUrls: ['./custom-node-editor.component.css']
})
export class CustomNodeEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() events:EventEmitter<{type: CodeMirrorComponentEvents | 'UPDATE_CONFIG', args: Array<unknown>}> = new EventEmitter()
  @ViewChild(EditorComponent) editor!: EditorComponent

  @Input() editorConfigs: CodeMirror.EditorConfiguration = {
    readOnly: false, 
    lineNumbers: true,
    tabSize: 2
  }


  textChange: Subject<{getValue: Function}> = new Subject<{getValue: Function}>();
  subscriptions: Array<Subscription> = []

  nodeConfigs: Array<IConfig> = [
    {
      name: 'editor',
      type: 'code',
      displayText: 'Script'
    },
    // {
    //   name: 'config',
    //   type: 'group',
    //   displayText: 'API Mapping',
    //   children: [
        
    //   ]
    // },
    // {
    //   name: 'config_details',
    //   type: 'group',
    //   displayText: 'Config Details',
    //   children: [
    //     {
    //       name: 'group_by_columns',
    //       type: 'key',
    //       displayText: 'Key',
    //       placeholder: 'key',
    //       inputType: 'select',
    //       children: [
    //         {
    //           name: 'A',
    //           type: 'input',
    //           displayText: 'Option',
    //           placeholder: 'Option'
    //         },
    //         {
    //           name: 'B',
    //           type: 'input',
    //           displayText: 'Option',
    //           placeholder: 'Option'
    //         }
    //       ]
    //     },
    //     {
    //       name: 'to_list_columns',
    //       type: 'key',
    //       displayText: 'Key',
    //       placeholder: 'key',
    //       inputType: 'select',
    //       children: [
    //         {
    //           name: 'A',
    //           type: 'input',
    //           displayText: 'Option',
    //           placeholder: 'Option'
    //         },
    //         {
    //           name: 'B',
    //           type: 'input',
    //           displayText: 'Option',
    //           placeholder: 'Option'
    //         }
    //       ]
    //     },
    //     {
    //       name: 'group_by_mode',
    //       type: 'key',
    //       displayText: 'Key',
    //       placeholder: 'key',
    //       inputType: 'select',
    //       children: [
    //         {
    //           name: 'custom',
    //           type: 'input',
    //           displayText: 'Option',
    //           placeholder: 'Option'
    //         },
    //         {
    //           name: 'default',
    //           type: 'input',
    //           displayText: 'Option',
    //           placeholder: 'Option'
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   name: 'functions',
    //   type: 'group',
    //   displayText: 'Functions',
    //   placeholder: 'No function define in code. Atleast one function must be defined',
    //   children: []
    // },
    // {
    //   name: 'classes',
    //   type: 'group',
    //   displayText: 'Classes',
    //   placeholder: 'No class define in code.',
    //   children: []
    // },
    // {
    //   name: 'imports',
    //   type: 'group',
    //   displayText: 'Imports',
    //   placeholder: 'No import found. try running the code',
    //   children: []
    // }
  ]
  selectedConfig: IConfig = this.nodeConfigs[0]


  isConfigVisible: boolean = true

  constructor(private dashboardStoreService: DashboardStoreService, private uiService: UIService) { }
  

  ngOnInit(): void {
    this.subscribeEvents()
  }

  ngAfterViewInit() {
    this.events.next({type: CodeMirrorComponentEvents.INIT, args: []})
  }

  editorEventHandler(event: {type: CodeMirrorComponentEvents, args: Array<unknown>}) {
    if(event.type === CodeMirrorComponentEvents.TEXT_CHANGE) {
      const args = event.args[0] as {getValue: Function, callback: Function};
      args.callback();
      
      this.textChange.next(args)
    }

    if(event.type === CodeMirrorComponentEvents.RUN_CODE) {
      let {getValue} = event.args[0] as {getValue: Function};

      // this.loadCodeMetadata(getValue() as string).then(
      //   meta => {
      //     this.setMetadata(meta)
      //   }
      // )

      this.dashboardStoreService.testScript({codeAsString: getValue()}).toPromise().then(
        response => {
          if(response.status === 'success') {
            this.uiService.showInfo('No error found!', 'success')
          }
        }
      )
    }

  }

  subscribeEvents() {
    let subscription = this.textChange.pipe(debounce(() => interval(1000))).subscribe(event => {
      let value = event.getValue();
    })
    this.subscriptions.push(subscription)

  }

  
  setMetadata(meta: {fucnctions: Array<string>, classes: Array<string>, imports:  Array<[string, Array<string>]>}) {

    let functionConfigGroup = this.nodeConfigs.find(x => x.name === 'functions');
    let classConfigGroup = this.nodeConfigs.find(x => x.name === 'classes');
    let importConfigGroup = this.nodeConfigs.find(x => x.name === 'imports');

    if(functionConfigGroup) {
      let functionConfigs:Array<IConfig> = meta.fucnctions.map(f => ({
        name: 'function',
        displayText: f,
        type: 'detail'
      }))

      functionConfigGroup.children = functionConfigs
    }

    if(classConfigGroup) {
      let classConfigs:Array<IConfig> = meta.classes.map(c => ({
        name: 'class',
        displayText: c,
        type: 'detail'
      }))

      classConfigGroup.children = classConfigs
    }

    if(importConfigGroup) {
      let classConfigs:Array<IConfig> = meta.imports.map(i => ({
        name: 'class',
        displayText: `from ${i[0]} import ${i[1].join(', ')}`,
        type: 'detail'
      }))

      importConfigGroup.children = classConfigs
    }
    
  }

  async loadCodeMetadata(sourceCode: string): Promise<{fucnctions: Array<string>, classes: Array<string>, imports: Array<[string, Array<string>]>}> {
    // let response = await this.dashboardStoreService.testScript({python_string: sourceCode}).toPromise();
    // console.log(response.payload)
    return {
      fucnctions: [],
      classes: [],
      imports: []
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  removeItem(arr: Array<any>, index: number) {
    arr.splice(index, 1)
  }

  close() {
    this.editor.events.next({type: CodeMirrorComponentEvents.CLOSE, args: []})
  }

  resize() {
    this.editor.events.next({type: CodeMirrorComponentEvents.RESIZE, args: [(isFullScreen: boolean) => {this.editor.isFullScreen = isFullScreen}]})
  }

  saveConfig() {
    this.events.emit({type: 'UPDATE_CONFIG', args:[
      this.selectedConfig
    ]})
  }

  /**
   * get form input type object from ConfigKeyModel
   * @param input ConfigKeyModel
   * @returns 
   */
  public inputToConfig(input: ConfigKeyModel & {options?: Array<DropdownOptionType>}): IConfig {
    return {
      name: input.key_name,
      type: 'key',
      inputType: ['dropdown', 'drop_down'].includes(input.key_value_input_box_type) ? 'select':'text',
      displayText: 'Key',
      placeholder: 'Key',
      children: input.options?.filter(x => x.value).map(x => ({displayText: 'Option', name: x.value as string, type: 'input', placeholder: 'option'})) || []
    }
  }

  
  addConfig(input: IConfig, configName: string = 'config_details') {

    // find config
    let config = this.nodeConfigs.find(x => x.name === configName);
    if(!config) {
      config = {
        name: configName,
        type: 'group',
        displayText: configName,
        children: []
      }
      this.nodeConfigs.push(config);
    }
    config.children?.push(input)
  }

  addConfigGroup(configGroupName: string) {
    const config:IConfig = {
      name: configGroupName,
      type: 'group',
      displayText: configGroupName,
      children: []
    }
    this.nodeConfigs.push(config)
  }

}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { filter, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from 'src/app/services/api/auth.service';
import { Accountv2Service } from 'src/app/services/api/v2/accountv2.service';
import { Groupv2Service } from 'src/app/services/api/v2/groupv2.service';
import { Group } from 'src/app/services/api/v2/groupv2.service';
import { Directory } from 'src/app/services/api/v2/interfacesv2';
import { Pipeline, Pipelinev2Service } from 'src/app/services/api/v2/pipelinev2.service';
import { SideNavService } from 'src/app/services/core/sidenav.service';
import { DirectoriesStoreService } from 'src/app/services/store/directories-store.service';

@Component({
  selector: 'app-sidebar-pipelines',
  templateUrl: './sidebar-pipelines.component.html',
  styleUrls: ['./sidebar-pipelines.component.scss']
})
export class SidebarPipelinesComponent implements OnInit {
  hasAdminRole = false
  @ViewChild('createGroupModal') createGroupModal!: TemplateRef<HTMLDivElement>
  @ViewChild('directoryTree') directoryTree:any
  searchQueryChange: Subject<string> = new Subject<string>();
  subscriptions: Array<Subscription> = [];
  expandTo:string = ''
  directoriesData:any = []
  // '#i_9_4'
  groups:Array<Group> = []
  totalPipelines:number = 0
  totalGroups:number = 0

  imageChangedEvent:any = ''
  imageUrl:any=''
  croppedImage = ''

  constructor(private groupService:Groupv2Service,private pipelineService:Pipelinev2Service,private router:Router,public portalBridge:SideNavService,private route:ActivatedRoute,private directoriesStoreService:DirectoriesStoreService,private dialog:MatDialog,private authService:AuthService,private accountsService:Accountv2Service) {
    console.log(this.route.snapshot.paramMap)
  }

  ngOnInit(): void {
    this.authService.accountId$.subscribe(accountId=>{
      if(accountId){
        this.accountsService.getUserInfoV2().subscribe((_data:any)=>{
          let roles = _data.payload.accounts[accountId]?.role?.role || ""
          this.hasAdminRole = roles === "rrole:admin"
          // console.log('roles',this.roles)
        })
      }
    })
    this.directoriesStoreService.directoriesData.subscribe(data=>{
       this.directoriesData = this.directoriesStoreService.convertToCompleteTree(data);
       this.totalGroups = this.directoriesData.length;
    })
    let subscription = this.searchQueryChange
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(filterQuery => {
        let data = this.directoriesStoreService.directoriesData.getValue();
        let filteredData = this.directoriesStoreService.filterGroups(data,filterQuery);
        this.directoriesData = this.directoriesStoreService.convertToCompleteTree(filteredData);
      });

    this.subscriptions.push(subscription)
    // this.portalBridge.expandedFolder.subscribe((mappedId:string) => {this.expandTo = mappedId;console.log(mappedId)});
  }

  ngAfterViewInit(): void {
    this.loadData()
  }
  
  async loadData(){
    this.groupService.getAllDirectories().subscribe((data:any)=>{
      this.directoriesStoreService.directoriesData.next(data.payload.data)
    })
    // this.groupService.getAllDirectories().subscribe((data:any)=>{
    //   this.directoriesStoreService.directoriesData.next(data.payload.data)
    // })
    // let groupData = (await this.groupService.getGroups().toPromise()).payload;
    this.totalPipelines = (await this.pipelineService.getPipelines({params:{}}).toPromise()).payload.totalCount

    //mock data
    
  }

  folderSelected(e:any){
    // console.log(e.detail)
    this.portalBridge.selectedFolder.next(e.detail)
    const data = e.detail
    if(!data.groupId)
        this.router.navigate(['', { outlets: { view: ["groups",data.id],action:null }}])
    else{
      this.router.navigate(['', { outlets: { view: ["directory",data.id],action:null } }],{ state: {path:data.path } })
    }


  }
  loadFolders(e:any){
    // console.log(e)
    // Can be used for loading folders from api's
  }

  filterPipelines(directory:Directory){
    // pipelineService.filterPipelines.next(directory)
  }

  groupSwitch(event:MouseEvent,groupId:number,directoryId = -1){
      event.stopPropagation();
      if(directoryId===-1)
        this.router.navigate(['', { outlets: { view: ["groups",groupId],action:null } }])
      else{
        this.router.navigate(['', { outlets: { view: ["directory",groupId,directoryId],action:null } }])
      }
    
  }

  //create group modal
  dialogRef:any
  openCreateGroupPopup(){
    this.dialogRef = this.dialog.open(this.createGroupModal, {
      panelClass: 'group-dialog-container',
      height:'calc(100vh - 100px)',
      width:'500px',
      disableClose:true
    })
  }
  closeCreateGroupPopup(){
    this.dialogRef.close()
    this.imageChangedEvent = ''
    this.imageUrl = ''
    this.croppedImage = ''
  }
  createGroup(newGroupData:{name:string,label:string,description:string,icon:string,tags:Array<string>,attributes:Array<{key:string,value:string}>}){
    this.groupService.createGroup(newGroupData).subscribe((data)=>{
      this.groupService.getAllDirectories().subscribe((data:any)=>{
        this.directoriesStoreService.directoriesData.next(data.payload.data)
      });
      this.closeCreateGroupPopup();
    })
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.imageUrl='';
}
  customHeaderHandler(event:any){
    if(event.detail.type === "iconDetails" && event.detail.data){
      this.fileChangeEvent(event.detail.data);
    }
    else if(event.detail.type === "iconUrl" && event.detail.data){
      this.imageUrl=event.detail.data;
    }
    else if(event.detail.type === "advanceDetails" && event.detail.data){
       console.log(event.detail.data,this.croppedImage)
      this.createGroup({...event.detail.data,icon:this.croppedImage});
    }
  }

  resetDirectory(){
    this.directoryTree.nativeElement.removeActive();
  }

  onGroupSearch(event:any){
    this.searchQueryChange.next(event.detail.value);
  }

  ngOnDestroy(){
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

}

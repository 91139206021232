<div class="bulk-upload-card bulk-upload-card-full-width">
<div class="card-header d-flex align-items-center">
  <div class="card-title mb-0">Output</div>
  <div *ngIf="nodeOutputError?.length>0" class="error ms-4"><i class="ri-information-fill"></i>{{nodeOutputError?.length}} Errors
  </div>
  <div class="ms-auto">
    <div class="d-flex align-items-center gap-3">
      <!-- <div class="d-flex align-items-center">
          <label>Rows:</label> 
          <select class="form-control border-0 form-select">
            <option>All</option>
            <option>All</option>
            <option>All</option>
          </select>
        </div>
      <div class="d-flex align-items-center">
          <label>Scale:</label> 
          <select class="form-control border-0 form-select">
            <option>100%</option>
            <option>75%</option>
            <option>50%</option>
          </select>
        </div> -->
      <a (click)="createUrl(downloadFileUrl, pipelineName+'_output')" style="cursor: pointer;" class="btn-link text-blue text-decoration-none ms-auto fs-body-default text-blue">
        <simplify-icon class="text-dark" name='download-2-line' size='medium' lib='remix'></simplify-icon> Download</a>
      <!-- <simplify-button class="mx-1" status='outline-primary' data-popover-id="lastViewed">
          <simplify-icon name='edit-2-fill' size='medium' lib='remix'></simplify-icon>
          Edit
        </simplify-button> -->
      <a style="cursor: pointer;" (click)="ontableClose()" data-role='close-card'>
        <simplify-icon name='close-line' size='large' lib='remix'></simplify-icon>
      </a>
    </div>
  </div>
</div>
<div class="card-body p-0">
  <div class="table-responsive w-100">

    <ag-grid-angular #agGrid style="height:71vh;" class="ag-theme-alpine" [rowData]="rowData" rowHeight="100px"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
      (cellValueChanged)="onCellValueChanged($event)"
      (cellClicked)="onCellClicked($event)" enableBrowserTooltips="true"
      (cellEditingStopped)="onCellEditingStopped($event)"
      (cellEditingStarted)="onCellEditingStarted($event)"
      (gridReady)="onGridReady($event)"
      (rowSelected)="onRowSelected($event)"
      [rowSelection]="rowSelection"
      [tooltipShowDelay]="tooltipShowDelay">
    </ag-grid-angular>
  </div>

 

</div>
<div class="bulk-upload-card-footer">
  <!-- <div class="bulk-error-list-card">
    <simplify-file-bulk-upload-data-errorlist *ngIf="nodeOutputError"
      [errorData]=nodeOutputError></simplify-file-bulk-upload-data-errorlist>
  </div> -->
  <!-- <div class="bulk-upload-card-button text-end">
          <simplify-button status='primary' size='small'>Save Changes</simplify-button>
        </div> -->
</div>

</div>
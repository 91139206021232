import {
    Component,
    Input,
    HostBinding,
    ChangeDetectorRef,
    OnChanges,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ComponentFactoryResolver,
    ComponentRef,
    TemplateRef
  } from "@angular/core";
  import { ClassicPreset } from "rete";
  import { KeyValue } from "@angular/common";
import { CustomNode } from "../default-node/custom-node.component";

export const SETTINGS_CONTROLER_KEY = 'Settings'

const LIST_ITEM_HEIGHT = 28
const LIST_ITEM_WIDTH = 150
const NODE_PADDING = 4

export class IfNode extends CustomNode {
  width = LIST_ITEM_WIDTH;
  height = 100;
  constructor(public label: string, public uid: string, public meta: any) {// FIXME: add meta property interface
    super(label, uid, meta)
  }
  public async init() {
    const input_fields_count = 0 // Object.keys(this.meta.input_fields||{}).length
    const output_fields_count = Object.keys(this.meta.output_fields||{}).length

    const height = (input_fields_count+output_fields_count+3) * LIST_ITEM_HEIGHT + (NODE_PADDING*2)

    this.height = height
    this.width = LIST_ITEM_WIDTH
  }
}


  @Component({
    templateUrl: "./if-node.component.html",
    styleUrls: ["./if-node.component.scss"],
    host: {
      "data-testid": "node"
    }
  })
  export class ReteIfNodeComponent implements OnInit, OnChanges {
    @Input() data!: IfNode;
    @Input() emit!: (data: any) => void;
    @Input() rendered!: () => void;

    ignoreControls = [SETTINGS_CONTROLER_KEY]
    settingControl?: ClassicPreset.Control
    inputControls: {[key: string]: ClassicPreset.Control} = {}
  
    seed = 0;
  
    @HostBinding("class.selected") get selected() {
      return this.data.selected;
    }
  
    constructor(private cdr: ChangeDetectorRef) {
      this.cdr.detach();
    }
    
    ngOnInit(): void {
      this.ngOnChanges()
    }
  
    ngOnChanges(): void {
      this.cdr.detectChanges();
      requestAnimationFrame(() => {
        this.rendered()
      });
      this.settingControl = this.data.controls['Settings']
      this.inputControls = Object.keys(this.data.controls).reduce((prev, curr) => {

        if(!this.ignoreControls.includes(curr))prev[curr] = this.data.controls[curr]

        return prev
      }, {} as any)
      
      this.seed++; // force render sockets
    }
  }
  
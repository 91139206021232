import { Injectable } from "@angular/core";
import {BehaviorSubject} from 'rxjs'

@Injectable({
    providedIn: 'root',
  })
  export class DirectoriesStoreService {
    directoriesData = new BehaviorSubject({
        "groups": {
        },
        "directories": {
        }
    })

    getPath(directoryId:string,directoriesData:any){
        let pathArr = []
        let data = directoriesData.directories[directoryId]
        while(data.parentDirId !== null){
            pathArr.push(data);
            data =  directoriesData.directories[data.parentDirId]
        }
        pathArr.push(data)
        pathArr.push(directoriesData.groups[data.groupId])
        pathArr.reverse();
        return pathArr;
    }

    convertToCompleteTree(directoriesData:any){
        let treeArray:Array<any>= []
        let groups = {...directoriesData.groups};
        let directories= {...directoriesData.directories}
        function convertToTree(data:any, parentDirId = null) {
            const tree = [];
            
            for (const key in data) {
                const directory = data[key];
                if (directory.parentDirId === parentDirId) {
                const node = {
                    ...directory
                    
                };
                const childDirectories = convertToTree(data, directory.id);
                if (childDirectories.length > 0) {
                    node.childDirectories = childDirectories;
                }
                tree.push(node);
                }
            }
            
            return tree;
        }
        let halfTree:Array<any> = convertToTree(directories)
        for(const key in groups){
            let tempObj = groups[key];
            tempObj.childDirectories = halfTree.filter(data => {
                // console.log(data.groupId,tempObj.groupId)
                return data.groupId === tempObj.id;
            })
            treeArray.push(tempObj)
            // console.log(tempObj)
        }
        return treeArray;
    }

    filterGroups(directoriesData:any,filterQuery:string){
        let filteredData:any = {
            "groups": {
            },
            "directories": {...directoriesData.directories}
        }
        let groups = {...directoriesData.groups};
        for(const key in groups){
            let tempObj = groups[key];
            if(tempObj.label.toLowerCase().includes(filterQuery.toLowerCase())){
                filteredData.groups[key] = tempObj;
            }
        }
        return filteredData;
    }

    
  }
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject} from 'rxjs';
import { PipelineAPIResponse } from './pipeline.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private pipeline = new BehaviorSubject<any>('');
  currentPipeline = this.pipeline.asObservable();

  subPipeline = new Subject<PipelineAPIResponse['payload']|null>();
  subNodes = new Subject();
  conditional_node_status = new Subject();

  constructor() { }

  changePipeline(pipeline: any){
  	this.pipeline.next(pipeline);
  	console.log(pipeline,this.pipeline);
  }

  getPipeline(){
  	// return this.currentPipeline.subscribe();
  }

  private task = new BehaviorSubject<any>('');
  currentTask = this.task.asObservable();

  updateTask(pipeline: any){
  	this.task.next(pipeline);
  }

  private nodes = new BehaviorSubject<any>([]);
  currentNodes = this.nodes.asObservable();

  updateNodes(nodes: any){
  	this.nodes.next(nodes);
  }

  c:boolean = true;

}

<div style="min-width: 20rem">


  <h6>Select Node type</h6>

  <nb-accordion *ngIf="show">
    <nb-accordion-item *ngFor="let config of data">
      <nb-accordion-item-header>
        {{config.type}}
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        <button mat-button color="accent" (click)="sendConfigId(config.id);">{{config.displayName}}</button>
      </nb-accordion-item-body>
    </nb-accordion-item>
  </nb-accordion>

</div>

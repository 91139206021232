
<div class="container" style="background-color: white;padding: 2rem;">
    <div style="background-color: aliceblue;height: 2.5rem;border-bottom: 1px solid rgba(22, 23, 22, .08);display: flex;align-items: center;padding: 0 1rem;">
        <div>
            <span><b>Pipeline Status</b></span>
        </div>
        <ul style="display: flex;list-style: none;padding: 0;margin: 0 0 0 auto;gap: 1rem;align-items: center;">
            <li class="color-pallet-item" >
                <span style="background-color: var(--node-success);display: inline-block;"></span>
                <span>success</span>
            </li>
            <li class="color-pallet-item" >
                <span style="background-color: var(--node-running);display: inline-block;"></span>
                <span>running</span>
            </li>
            <li class="color-pallet-item" >
                <span style="background-color: var(--node-error);display: inline-block;"></span>
                <span>error</span>
            </li>
        </ul>
    </div>

    <div style="position: relative;" #container>
        <svg [attr.width]="width" [attr.height]="height" style="position:relative;z-index:1">

            <!-- Date -->
            <g [attr.transform]="'translate('+dateTimeline.x+','+dateTimeline.y+')'">
                <!-- <line x1="0" y1="0" x2="200" y2="0" fill="none" stroke="gray" stroke-width="1" /> -->
            </g>

            <!-- Grid -->
            <g [attr.transform]="'translate('+(graphOffset.x+statusOffset.x)+','+(graphOffset.y-lineHeight/2)+')'">
                <line [attr.x1]="0" [attr.y1]="0" [attr.x2]="((statusCount)*(statusNodeRadius*2 + statusGap))" [attr.y2]="0" fill="none" stroke="gray" stroke-width="1" />
               
                <!-- <circle
                [attr.cx]="0" 
                [attr.cy]="0" 
                [attr.r]="statusNodeRadius" 
                class="pipe-node-status" />

                <circle
                [attr.cx]="((statusCount-1)*(statusNodeRadius*2 + statusGap))" 
                [attr.cy]="0" 
                [attr.r]="statusNodeRadius" 
                class="pipe-node-status" /> -->


                <ng-container *ngFor="let timeline of timelineSlots">
                    <text 
                        [attr.x]="((timeline.column)*(statusNodeRadius*2 + statusGap))" 
                        [attr.y]="-10" class="pipe-node-text" [attr.font-size]="10+'px'" 
                        [attr.transform]="'rotate(-45, '+((timeline.column)*(statusNodeRadius*2 + statusGap))+', -10)'">
                        {{timeline.text}}</text>

                    <line 
                        [attr.x1]="((timeline.column)*(statusNodeRadius*2 + statusGap))" 
                        [attr.y1]="0" 
                        [attr.x2]="((timeline.column)*(statusNodeRadius*2 + statusGap))" 
                        [attr.y2]="-5" 
                        fill="none" stroke="gray" stroke-width="1" />
                </ng-container>

                


                

                <!-- <text [attr.x]="0" [attr.y]="0" class="pipe-node-text" [attr.font-size]="fontSize+'px'" transform="rotate(-45, 0, 0)">timeline</text> -->
                <!-- <text [attr.x]="((statusCount-1)*(statusNodeRadius*2 + statusGap))" [attr.y]="0" class="pipe-node-text" [attr.font-size]="fontSize+'px'" [attr.transform]="'rotate(-45, '+((statusCount-1)*(statusNodeRadius*2 + statusGap))+', 0)'">timeline</text> -->
                

      
            </g>
    
            <!-- Graph edge -->
            <g [attr.transform]="'translate('+graphOffset.x+','+(graphOffset.y+statusNodeRadius)+')'">
                <ng-container *ngFor="let edge of edgeMap|keyvalue">
                    <path [attr.d]="getPath(edge.key, child)" class="pipe-edge" stroke-width="1" fill="none" *ngFor="let child of edge.value.children" [id]="child" />
                </ng-container>
            </g>


            <g [attr.transform]="'translate('+graphOffset.x+','+(graphOffset.y+(lineHeight*i))+')'" *ngFor="let edge of graphEdges;let i=index">
                <circle [attr.cx]="(statusNodeRadius*2+graphNodeGap)*edgeMap[edge.id].column" [attr.cy]="statusNodeRadius" [attr.r]="statusNodeRadius" class="pipe-node" [class]="'pipe-node-'+edge.currentStatus"/>
                <text [attr.x]="(statusNodeRadius*2+graphNodeGap)*edgeMap[edge.id].column+statusNodeRadius+graphNodeGap" [attr.y]="5+statusNodeRadius" class="pipe-node-text" [attr.font-size]="fontSize+'px'" [nbPopover]="edge.currentStatus" nbPopoverPlacement="right" nbPopoverTrigger="hint">{{edge.name}}</text>
            
                <!-- Node status array -->
                <g [attr.transform]="'translate('+statusOffset.x+',0)'">
                    <ng-container *ngIf="!showRect">
                        <circle 
                            *ngFor="let status of edge.timeline;let j=index"
                            [nbPopover]="status.status+': '+status.date" nbPopoverPlacement="top" nbPopoverTrigger="hint"
                            [attr.cx]="(statusNodeRadius*2+statusGap)*j" 
                            [attr.cy]="0" 
                            [attr.r]="statusNodeRadius" 
                            class="pipe-node-status" 
                            [class]="'node-status-'+status.status"/>
                    </ng-container>

                    <ng-container *ngIf="showRect">
                        <rect *ngFor="let status of edge.timeline;let j=index"
                            [nbPopover]="status.status+': '+status.date" nbPopoverPlacement="top" nbPopoverTrigger="hint"
                            [attr.x]="(statusNodeRadius*2+statusGap)*j" 
                            [attr.y]="0"  
                            [attr.width]="statusNodeRadius*2" 
                            [attr.height]="statusNodeRadius*2" 
                            class="pipe-node-status" 
                            [class]="'node-status-'+status.status"/>
                    </ng-container>


                </g>
            </g>
    
            
            Sorry, your browser does not support inline SVG.
        </svg>
    </div>
</div>



<ng-content></ng-content>
<mat-accordion cdkDropList style="max-height: 12rem" (cdkDropListDropped)="drop($event)">
  <mat-expansion-panel style="background-color: white;" *ngFor="let item of selectedNodes" cdkDrag [hideToggle]="!item.taskSpecific">
    <mat-expansion-panel-header [style.padding]="item.taskSpecific?'0 1rem 0 0':'0'">
      <mat-panel-title (click)="!item.taskSpecific?stopPropagation($event):null" style="margin:0" [title]="item.value">
        <div style="height: 48px;display: flex;align-items: center;padding-left: .5rem;">
          <nb-icon icon="close-outline" style="color: rgba(0, 0, 0, 0.459);cursor: pointer;margin-right: 0.5rem;" (click)="removeItem(item)"></nb-icon>
          {{ item.value }}
        </div>
      </mat-panel-title>
    
      <mat-panel-description (click)="stopPropagation($event)" style="flex-grow: unset">
        <!-- <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" (click)="itemClicked(item)">{{ item.value }}</div> -->
        <div style="display: flex;align-items: center;margin-left: auto;gap: .5rem;height: 48px;padding-right:.5rem">
          <div [nbTooltip]="item.booleanValue===1?'null':(item.booleanValue===2?'false': 'true')" nbTooltipPlacement="top" nbTooltipStatus="basic" *ngIf="item.isBoolean" style="width: 2rem;display:flex;height: 1rem;border-radius: .5rem;background-color: #0000000f;cursor: pointer;" (click)="item.booleanValue=((item.booleanValue||0)+1)%3">
            <div [class]="['toggle-bullet', item.booleanValue===0?'toggle-left':(item.booleanValue===1?'toggle-center':'toggle-right')]" style="width: 1rem;height: 1rem;border-radius: .5rem"></div>
          </div>
          <!-- <nb-icon icon="more-vertical-outline" style="color: rgba(0, 0, 0, 0.459);cursor: pointer;" (click)="showDetails(item)" *ngIf="item.showDetailsIcon" ></nb-icon> -->
          <!-- <nb-icon icon="close-outline" style="color: rgba(0, 0, 0, 0.459);cursor: pointer;" (click)="removeItem(item)"></nb-icon> -->
        </div>
    
    </mat-panel-description>

  </mat-expansion-panel-header>

  <div role="list" *ngIf="item.taskSpecific" style="display: flex;flex-direction: column;gap: 1rem;">
    <div role="listitem" *ngFor="let keyValue of item.taskSpecific.keyValues">
      <label style="padding: .3rem 0;">{{keyValue.key.placeholder|titlecase}}</label>
      <input type="text" nbInput [(ngModel)]="keyValue.value.value" *ngIf="keyValue.value.type==='text'" [placeholder]="keyValue.value.placeholder" style="font-size: unset;font: unset;width: -webkit-fill-available;">
      <select [(ngModel)]="keyValue.value.value" *ngIf="keyValue.value.type==='dropdown'">
        <option *ngFor="let option of keyValue.value.data.options" [value]="option.id">{{option.value}}</option>
      </select>
    </div>
  </div>

  <div *ngIf="!item.taskSpecific" style="text-align: center;">
    <code>No Data</code>
  </div>
</mat-expansion-panel>
</mat-accordion>

<select (change)=onchange($event) style="margin-top: 5px">
  <option [value]="null"></option>
  <ng-container *ngFor="let option of dropdownOptions">
    <option [value]="option.id" *ngIf="!option.isSelected">{{option.value}}</option>
  </ng-container>
</select>


import { TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription,} from 'rxjs';
import { switchMap} from 'rxjs/operators';
import { Groupv2Service } from 'src/app/services/api/v2/groupv2.service';
import { Directory, Group } from 'src/app/services/api/v2/interfacesv2';
import { Pipelinev2Service } from 'src/app/services/api/v2/pipelinev2.service';
import { UIService } from 'src/app/services/config/ui.service';
import { SideNavService } from 'src/app/services/core/sidenav.service';
import { DirectoriesStoreService } from 'src/app/services/store/directories-store.service';
import { filter, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from 'src/app/services/api/auth.service';
import { Accountv2Service } from 'src/app/services/api/v2/accountv2.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.scss']
})
export class GroupDetailComponent implements OnInit, AfterViewInit {
  hasAdminRole = false
  pipelineOptions:Array<any> = [
    {label:'History',icon:'history-line',type:'history'},
    {label:'Run',icon:'play-line',type:'run'},
  ]
  searchQueryChange: Subject<string> = new Subject<string>();
  searchQuery:string = ''
  subscriptions: Array<Subscription> = [];
  directoriesLoading = true;
  pipelinesLoading = true;
  @ViewChild('portalContent', { static: true })
  portalContent: any;
  @ViewChild('groupDetails') groupDetails:any
  selectedGroup$ = new BehaviorSubject<number>(-1)

  totalCount=0
  pageIndex=0
  itemsPerPage=10
  pageSizeOptions=[10,20,25,30]
  
  groupId = -1
  groupData:any
  groupName:any = [{label:'',id:''}]
  constructor(private viewContainerRef:ViewContainerRef,public portalBridge:SideNavService,private groupService:Groupv2Service,private route:ActivatedRoute,private pipelineService:Pipelinev2Service,private router:Router, private directoriesStoreService: DirectoriesStoreService, private uiService:UIService, private authService:AuthService,private accountsService:Accountv2Service, private location: Location) {
    
    this.selectedGroup$.next(this.route.snapshot.params.id || -1)
    this.groupId = this.route.snapshot.params.id || -1
    this.route.params.subscribe(data=>{
      this.selectedGroup$.next(data.id || -1)
      this.groupId = data.id;
    })
    
    // this.groupId = this.router
  }

  ngOnInit(): void {
    this.authService.accountId$.subscribe(accountId=>{
      if(accountId){
        this.accountsService.getUserInfoV2().subscribe((_data:any)=>{
          let roles = _data.payload.accounts[accountId]?.role?.role || ""
          this.hasAdminRole = roles === "rrole:admin"
          if(roles === 'rrole:admin'){
            this.pipelineOptions = [
              {label:'Edit',icon:'user-settings-line',type:'edit'},
              {label:'Disable',icon:'file-copy-line',type:'disable'},
              {label:'History',icon:'history-line',type:'history'},
              {label:'Run',icon:'play-line',type:'run'},
              {label:'Delete',icon:'delete-bin-2-line',type:'delete'},
            ]
          }
          else{
            this.pipelineOptions = [
              {label:'History',icon:'history-line',type:'history'},
              {label:'Run',icon:'play-line',type:'run'},
            ]
          }
        })
      }
      
    })
    let subscription = this.searchQueryChange
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(filterQuery => {
        this.loadPipelines(this.itemsPerPage,this.pageIndex,filterQuery);
      });

    this.subscriptions.push(subscription)
  }

  ngAfterViewInit(){
    this.route.queryParams.subscribe(data=>{
      if(data.edit){
        this.openEditDetails();
      }
    })
    this.selectedGroup$.pipe(switchMap(groupId=>{
      this.directoriesLoading = true;
      this.pipelinesLoading = true;
      this.pipelineData = []
      this.directoriesData = []

      return this.groupService.getGroupById(groupId)
    })).subscribe((data:any)=>{
      this.loadData(data);
    })

  }
  
  loadData(data:any){
    this.groupId = data.payload.id

    this.totalCount=0
    this.pageIndex=0
    this.itemsPerPage=10
    this.loadPipelines(this.itemsPerPage,this.pageIndex)
    this.groupDetailsData={
      name:data.payload.label,
      description:data.payload.description,
      tags:['Simplify', 'VMS', 'Bulk Upload', 'Utility', 'Self Management'],
      attributes:[
        {key:'Department',value:'Finance'},
        {key:'Company',value:'Marriot'}
      ]
    }
    this.directoriesData = data.payload.directories;
    this.directoriesLoading = false;
    this.groupData = data.payload
    this.groupName = [{label:data.payload.label,id:`#i_${this.groupId}`}]

    //update sidebar
    this.portalBridge.expandedFolder.next(`#i_${data.payload.id}`)
  }

  loadPipelines(itemsPerPage:number,pageIndex:number,search:string=''){
    this.pipelinesLoading = true;
    this.pipelineService.getPipelines({params:{groupId:this.groupId.toString(),itemsPerPage,pageNum:pageIndex+1,search_label:search}}).subscribe(data=>{
        this.pipelineData = data.payload.data.map((pipeline:any)=>{
              return {
                id:pipeline.id,
                description:pipeline.description,
                name: pipeline.label,
                trigger: pipeline.triggerName ?? "Custom",
                buildType: pipeline.buildType ?? "Custom Pipeline",
                status: pipeline.status.charAt(0).toUpperCase() + pipeline.status.slice(1),
                runStatus: [pipeline?.process?.inProgress ?? `${(pipeline.id)%50}`, pipeline?.process?.success ?? `${(pipeline.id +5)%50}`, pipeline?.process?.failure ?? `${(pipeline.id +1)%50}`],
                lastUpdated: `${this.convertTime(pipeline.updatedTime)} ago`
              }
            });
      this.pipelinesLoading = false;
      this.totalCount = data.payload.totalCount;
      this.pageIndex = data.payload.currentPage-1
    })
  }

  onPaginationEvent(e:any) {
    this.loadPipelines(e.detail.itemsPerPage,e.detail.index,this.searchQuery);
  }


  directoriesData=[]

  pipelineData:any = []

  closeSidebar(){
    this.groupDetails.nativeElement.setAttribute('show-details',false)
  }
  openSidebar(){
    this.groupDetails.nativeElement.setAttribute('show-details',true)
  }

  switchToDirectory(event:any){
    this.router.navigate(['', { outlets: { view: ["directory",event.detail.id],action:null } }])
  }

  switchToEditDirectory(event:any){
    this.router.navigate(['', { outlets: { view: ["directory",event.detail.id],action:null } }],{ queryParams: { edit: true } })
  }

  groupDetailsData:any = {name:"",
  description:"",
  tags:[],
  attributes:[
  ]}
  openEditDetails(){
    this.portalBridge.show = true;
    const portal = new TemplatePortal(this.portalContent,this.viewContainerRef)
    this.portalBridge.setRightNavTemplate(portal)
  }
  closeEditDetails(){
    this.portalBridge.show = false;
    // remove edit query param
    this.router.navigate([], { queryParams: { edit: null }, queryParamsHandling: 'merge' });
  }
  updateGroupDetails(e:any){
    this.groupService.updateGroup(this.groupId.toString(),e.detail).subscribe(gData=>{
      this.uiService.showInfo("Group updated successfully",'success')
      this.loadData(gData);
        //sidebar logic
        this.groupService.getAllDirectories().subscribe((data:any)=>{
          this.directoriesStoreService.directoriesData.next(data.payload.data)
        })
    });
    this.portalBridge.show = false;
    
  }
  pipelineSelected(event:any){
    this.router.navigate(["",{ outlets: { view: ['pipeline-details',event.detail],action:null }}])
  }

  createDirectory(e:any){
    this.groupService.createDirectory(e.detail,this.groupId).subscribe(data=>{
      this.groupService.getGroupById(this.groupId).subscribe(gData=>{
        this.loadData(gData);
        //sidebar logic
        this.groupService.getAllDirectories().subscribe((data:any)=>{
          this.directoriesStoreService.directoriesData.next(data.payload.data)
        })
      })
    })
  }

  customEventsHandler(event: any) {
    console.log(event)
    if (event.detail.type === 'pipeline') {
      this.viewPipelineDetails(event.detail.pipeline)
    }
    else if (event.detail.type === 'run'){
      this.triggerPipeline(event.detail.pipeline.id)
    }
    else if(event.detail.type === 'edit') {
      this.router.navigate(["", { outlets: { view: ['pipeline-details', event.detail.pipeline.id], action: null } }], { queryParams: { edit: true } })
    }
    else if (event.detail.type === 'history') {
      this.router.navigate(["", { outlets: { view: ['pipeline-details', event.detail.pipeline.id], action: null } }], { queryParams: { show: "history" } })
    }
    else {
      this.viewPipelineDetails(event.detail.pipeline)
    }
  }
  viewPipelineDetails(pipeline: any) {
    this.router.navigate(["", { outlets: { view: ['pipeline-details', pipeline.id], action: null } }])
  }

  triggerPipeline(pipelineId: number){
    this.pipelineService.triggerPipeline(pipelineId).subscribe((response: any) => {
      const processId = response?.payload?.id;
      if (processId) {
        localStorage.removeItem('activeSection')
        this.router.navigate([{ outlets: { view: ['upload'] } }], {
          queryParams: { pipelineId: pipelineId, processId: processId }
        }); } else {
        throw new Error('Invalid response from trigger API');
      }
    });
  }
  
  convertTime(timestamp: any) {
    timestamp = timestamp * 1000;
    const date: any = new Date(timestamp);
    const currentTime: any = new Date();
    const timeDifference = currentTime - date;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursDifference = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    const minutesDifference = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    const secondsDifference = Math.floor((timeDifference % (1000 * 60)) / 1000);

    let updatedTime = '';
    if (daysDifference > 0) {
      updatedTime += `${daysDifference} day${daysDifference > 1 ? 's' : ''} `;
    }

    if (hoursDifference > 0) {

      updatedTime += `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} `;

    }

    // if (minutesDifference > 0) {

    //   updatedTime += `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''} `;

    // }

    // if (secondsDifference > 0) {

    //   updatedTime += `${secondsDifference} second${secondsDifference > 1 ? 's' : ''} `;

    // }

    if (!updatedTime) {

      updatedTime = 'less than a second';

    }
    return updatedTime;
  }

  //
  modalVisible = false;
  currentModal = 1;
  async headerEventsHandler(e:any){
    console.log(e)
    if(e.detail.createNew==='custom') {
      this.modalVisible=true;
    }

    if(e.detail.type === 'create-pipeline') {
      const groups = await this.groupService.getGroups().toPromise()
      const options = groups.payload.data.map(x => ({label: x.label, value: x.id}))
      e.target?.showModal?.(e.detail.type, {
        groups: options,
        triggers: [{label: 'custom', value: 'custom'}, {label: 'manual', value: 'manual'}, {label: 'event', value: 'event'},],
      })
    }

    if(e.detail.type === 'import-pipeline') {

      e.target?.showModal?.(e.detail.type, {})
    }
    if(e.detail.type === 'template-pipeline') {
      this.router.navigateByUrl('/templates(view:view-templates)')
    }
    if(e.detail.type === 'back'){
      this.location.back();
    }
  }

  createPipeline(event: any) {
    console.log(event);
    const newPipeline = event.detail?.payload

    let objj = newPipeline.attributes
    let attrObj:any = {}
    for(let obj of objj){
        let temp:any = Object.values(obj)
        attrObj[temp[0]] = temp[1]
    }
    const mappedData = {
      ...newPipeline,
      attributes:attrObj
    }
    this.pipelineService.createPipeline(mappedData).toPromise()
      .then(async res => {
        console.log(res)
        if(res.status === "success") {
          if(event.detail.setModal) {
            event.detail.setModal('created', {})
            setTimeout(() => {
              this.router.navigate(["", { outlets: { view: ['pipeline-details', res.payload.id], action: null } }])
            }, 1000);
          }
        }

        if(res.status === "failure") {
          const groups = await this.groupService.getGroups().toPromise()
          const options = groups.payload.data.map(x => ({label: x.label, value: x.id}))
      
          if(event.detail.setModal) {
            event.detail.setModal('basic', {
              groups: options,
              triggers: [{label: 'custom', value: 'custom'}, {label: 'manual', value: 'manual'}, {label: 'event', value: 'event'}],
            })
          }
        }
        
        // this.loadData()
      })
  }

  onSearchQueryChange(event: any) {
    let searchVal = event.detail.detail.value
    this.searchQueryChange.next(searchVal); 
    this.searchQuery = searchVal
  }

  ngOnDestroy() {
    this.portalBridge.removeRightNavTemplate()
    this.portalBridge.show = false;
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

}

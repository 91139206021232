import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AgGridAngular, ITooltipAngularComp } from 'ag-grid-angular';
import { CellClickedEvent, CellEditingStartedEvent, CellFocusedEvent, CellValueChangedEvent, ColumnApi, ExcelExportParams, GridApi, GridOptions, ICellRenderer, RowSelectedEvent } from 'ag-grid-community';
import { ITooltipComp, TooltipComponent } from 'ag-grid-community/dist/lib/rendering/tooltipComponent';
import { Observable } from 'rxjs';
import { CellRendererComponent } from 'ag-grid-community/dist/lib/components/framework/componentTypes';
import { event, param } from 'jquery';
import { Pipelinev2Service } from 'src/app/services/api/v2/pipelinev2.service';
import { ActivatedRoute } from '@angular/router';




@Component({
  selector: 'app-edit-cell',
  templateUrl: './edit-cell.component.html',
  styleUrls: ['./edit-cell.component.scss']
})
export class EditCellComponent implements OnInit {
  @Input() downloadFileUrl:string= ''
  @Input() cellCheckTableColumn: Array<{ column_name: string, data_type: {}, order: string, column_id: string }> = [{ column_name: "", data_type: {}, order: "", column_id: "" }]
  @Input() cellCheckTableData: [] = []
  @Input() cellCheckErrors: Array<{ message: string, effected_cell: string }> = [{ message: '', effected_cell: 'A2' }]
  selectedRowIndex: string = '';
  selectedColumn: any
  selectedColumnIndex: string = ''
  selectedCell: any
  public gridApi: any;
  public gridColumnApi: any;
  isFormulaBarFocused = false;
  columnDefs: Array<any> = []
  @ViewChild('formulaInput', { static: false })
  formulaInput!: ElementRef;
  isErrorListVisible:boolean = false
  public rowSelection: 'single' | 'multiple' = 'multiple';
  pipelineName: string = ''
  pipelineId: number = 0
  effected_cell:string=''



  selectedCellValue: string = ''
  @ViewChild('agGrid') agGrid: AgGridAngular | undefined;

  lastSelectedCellData = ''
  lastSelectedRowData = {}
  @HostListener('window:keydown',['$event'])
  onKeyPress($event: KeyboardEvent) {
    if(($event.ctrlKey || $event.metaKey) && $event.altKey && $event.keyCode == 67) {
      this.copyLastSelectedRow()
    }
    if(($event.ctrlKey || $event.metaKey) && !$event.altKey && $event.keyCode == 67) {
      this.copyLastSelectedCell()
    }
    // if(($event.ctrlKey || $event.metaKey) && $event.keyCode == 86) {
    // }
  }

  copyLastSelectedCell() {
    // this.contextMenu.nativeElement.classList.remove("visible")
    if(this.lastSelectedCellData) {
      navigator.clipboard.writeText(this.lastSelectedCellData)
    }
  }

  copyLastSelectedRow() {
    // this.contextMenu.nativeElement.classList.remove("visible")
    let data = '';
    let selectedRowIndexes = Object.keys(this.gridApi.selectionController.selectedNodes).filter(i => this.gridApi.selectionController.selectedNodes[i] !== undefined)
    if (selectedRowIndexes.length > 0) {
      let firstNode = this.gridApi.selectionController.selectedNodes[selectedRowIndexes[0]]
      data = Object.keys(firstNode.data).join('\t') + '\t\n'

      for (let i of selectedRowIndexes) {
        data += Object.values(this.gridApi.selectionController.selectedNodes[i].data).join('\t') + '\t\n'
      }
      navigator.clipboard.writeText(data)
    }
  }

  constructor(private route: ActivatedRoute, private http: HttpClient, public ElementRef: ElementRef, private pipelineService:Pipelinev2Service) {

  }
  public tooltipShowDelay = 0;
  public tooltipHideDelay = 1000;

  ngOnInit(): void {
    const pipelineId = this.route.snapshot.queryParams['pipelineId']
    this.loadpipelineDetails(pipelineId)
    console.log(this.cellCheckTableColumn)
    const slCol = {
      headerName: '',
      field: 'sl',
      width: 50,
      filter:true,
      pinned: true,
      lockPosition: true,
      cellClass: 'locked-col',
      suppressNavigable: false,
      suppressMenu: true,
      resizable: true,
      valueGetter: function (params: any) {
        return params.node.rowIndex+1;
      },
    };
    this.columnDefs.push(slCol as any);

    const dynamicColumns = this.cellCheckTableColumn.map(x => ({
      headerName: this.getExcelColumnLabel(parseInt(x.order)),
      field: x.column_name,
      editable: false,
      // tooltipField: String.fromCharCode('A'.charCodeAt(0) + parseInt(x.order) - 1),
      tooltipComponent: ((args: any) => { console.log(args) }) as any,
      width: 150,
      filter:true,
      cellStyle: (params: any) => {
        const isEffected = this.cellCheckErrors.some(error => error.effected_cell === (params.colDef.headerName + (params.node.rowIndex+1).toString()));
         if (isEffected) {
          return { backgroundColor: 'rgb(255 128 171 / 15%)' };
        } else {
          if (params.node.rowIndex === 0) {
            return { fontWeight: 'bold' };
          } else {
            return null;
          }
        }
      },
      order:x.order
    })).sort((a:any, b:any) => parseInt(a.order) - parseInt(b.order));

    this.columnDefs = [slCol, ...dynamicColumns];
    console.log(this.columnDefs)

  }

  async loadpipelineDetails(id: number) {
    const data = await this.pipelineService.getPipelineById(id).toPromise()
    const pipelineName = data.payload.label
    this.pipelineName = pipelineName

  }

  getExcelColumnLabel(number: number): string {
    let label = '';
    while (number > 0) {
        const remainder = (number - 1) % 26;
        label = String.fromCharCode(65 + remainder) + label;
        number = Math.floor((number - 1) / 26);
    }
    return label;
}

  
  @Output()
  showEditCell = new EventEmitter<void>();

  onCloseEditCell(){
    this.showEditCell?.emit()
  }

  @Output() onClose = new EventEmitter<void>();

  onTableClose() {
    this.onClose.emit()
  }

  async createUrl( uri:string , Filename:string){
    console.log(uri)
    let url = await this.pipelineService.getDownloadPublicUrl(uri, Filename).toPromise()
    console.log(url)
    let a = document.createElement("a")
    a.download = Filename
    a.href = url.URL
    a.click()  
  }
  
  onCellClicked(params: any) {
    this.lastSelectedCellData = params.value
    // params.api.setfocusedCell(params.rowIndex, params.colDef.headerName)
    this.selectedCell = params
    console.log(params)
    this.selectedRowIndex = params.rowIndex;
    this.selectedColumnIndex = params.colDef.headerName
    this.selectedColumn = params.colDef.field;
    this.selectedCellValue = params.data[this.selectedColumn];
    this.effected_cell = this.selectedRowIndex+1 + this.selectedColumnIndex
    this.formulaInput = this.selectedCellValue as any
    
  }

  onRowSelected(event: RowSelectedEvent) {
    this.lastSelectedRowData = event.data
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onCellValueChanged(params: any) {
    console.log(params)
    // const newValue = params.newValue;
    // const focusedCell = this.gridApi.getFocusedCell();
    // if (focusedCell && focusedCell.rowIndex === params.rowIndex && focusedCell.column.colId === params.column.colId) {
    //   this.selectedCellValue = newValue;
    //   this.formulaInput.nativeElement.value = newValue;
    // }
  }

  handleCellClick(event:any) {
    console.log(event)
    const cellIndex = event.detail
    const column = cellIndex.match(/[A-Z]+/)[0]; 
    const row = parseInt(cellIndex.match(/\d+/)[0], 10);
    console.log('Column:', column);
    console.log('Row:', row);
    console.log(this.gridApi)
    this.gridApi.ensureIndexVisible(row, 'middle');
    this.gridApi.ensureColumnVisible(column);  
    this.gridApi.setFocusedCell(row, column, null)
    this.gridApi.forEachNode((node:any) => {
      if (node.rowIndex === row-1) {
        node.setSelected(true, true); // Set 'true' to select, 'true' to suppress events
      } else {
        node.setSelected(false, true); // Set other rows to unselect
      }
    });
  }


  onCellEditingStarted(event: any) {
    const selectedCell = this.gridApi.getFocusedCell();
    if (selectedCell && selectedCell.node) {
      const selectedCellValue = selectedCell.node.data[event.column.colId];
      this.selectedCellValue = selectedCellValue;
    }
    const cellEditor = this.gridApi.getCellEditorInstances()[0];
    if (cellEditor) {
      const inputElement = cellEditor.getGui().querySelector('input');
      inputElement.addEventListener('input', (e: any) => {
        this.formulaInput = e.target.value;
      });
    }
  }

  onCellEditingStopped(event: any) {
    const cellEditor = this.gridApi.getCellEditorInstances()[0];
    if (cellEditor) {
      const inputElement = cellEditor.getGui().querySelector('input');
      inputElement.removeEventListener('input', () => { });
    }

  }

  onColumnSelectionChange(selectedValue: any) {
    if (selectedValue) {
      this.gridColumnApi.setColumnVisible(selectedValue, true);
      this.columnDefs.forEach((column) => {
        if (column.field !== selectedValue) {
          this.gridColumnApi.setColumnVisible(column.field, false);
        }
      });
    } else {
      this.columnDefs.forEach((column) => {
        this.gridColumnApi.setColumnVisible(column.field, true);
      });
    }
  }

  exportToExcel() {
    const params: ExcelExportParams = {
      allColumns: true,
      fileName: 'my-grid-data.xlsx',
    };
    this.gridApi.exportDataAsExcel(params);
  }


  saveData() {
    const rowData: any[] = [];
    this.gridApi.forEachNode((node: { data: any; }) => {
      rowData.push(node.data);
    });
    this.cellCheckTableData = rowData as any
    // update the data using API (PUT)
    console.log(rowData)
  }

  gridOptions = {
    defaultColDef: {
      filter: true,
      resizable: true,
      animateRows: true,
      enableRangeSelection: true,
      
      // tooltipComponent: CustomCellRendererComponent,
      tooltipComponentParams: { color: '#ffff' },
    },
    columnDefs: this.columnDefs,
    cellCheckTableData: this.cellCheckTableData,


    // pagination: true,
    // paginationPageSize: 10
  };

  toggleErrorList(){
    this.isErrorListVisible = !this.isErrorListVisible
  }
 


}



<!-- <div style="min-width: 25rem">
  <form>
    <div>
      <h4>Session expired</h4>
    </div>
    <div class="example-items-col" style="display: flex;flex-direction: column;gap:.5rem" [formGroup]="loginForm">
      <p style="color:var(--bs-danger)" *ngIf="loginError">{{loginError}}</p>
      <nb-form-field>
        <input type="text" nbInput fieldSize="small" placeholder="email" shape="round" formControlName="username">
        <nb-icon nbSuffix icon="at-outline" pack="eva"></nb-icon>
      </nb-form-field>
      <nb-form-field>
        <input [type]="getInputType()" nbInput  fieldSize="small" placeholder="password" shape="round" formControlName="password" >
        <div nbSuffix nbButton (click)="toggleShowPassword()">
          <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'" pack="eva" [attr.aria-label]="showPassword ? 'hide password' : 'show password'"></nb-icon>
        </div>
      </nb-form-field>
    </div>
    <div style="margin-top: .5rem">
      <button nbButton status="info" size="small" (click)="login()">Login</button>
    </div>
  </form>
</div> -->


<div style="min-width: 36rem" class="modal-header">
  <div class="d-flex align-items-center">
    <div class="">
      <img src="../../assets/images/session-expired.png" class="logo-icon" alt="img-fluid" />
    </div>
    <div class="ms-3 text-header-content">
      <h5 class="">Session Expired</h5>
    </div>
  </div>
  <simplify-button class="float-right" data-role='close-modal'><simplify-icon name='close-line' size='large' lib='remix'></simplify-icon></simplify-button>
</div>
<div class="modal-body">
  <div class="mb-2">
    <div class="card-body" [formGroup]="loginForm">
      <h6 class="mb-4">Login again to continue </h6>
      <div class="mb-4 validation-error">
        <label class="form-label">Email address <span class="email-madate">*</span></label>
        <input  formControlName="username" type="text" class="form-control" placeholder="Enter email address or username" id="email" name="email" aria-describedby="emailHelp" required>
      </div>

      <div class="mb-4 validation-error">
        <label class="form-label">Password <span class="email-madate">*</span></label>
        <input [type]="getInputType()" formControlName="password" type="password" class="form-control" placeholder="Enter Password" id="email" name="email" aria-describedby="emailHelp" required>
      </div>
    </div>
  </div>
</div>


<div class="modal-footer">
  <simplify-button (click)="login()" class="btn" status="primary">Login</simplify-button>
</div>
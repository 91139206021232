import {
    Component,
    Input,
    HostBinding,
    ChangeDetectorRef,
    OnChanges,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ComponentFactoryResolver,
    ComponentRef,
    TemplateRef
  } from "@angular/core";
  import { ClassicPreset } from "rete";
  import { KeyValue } from "@angular/common";
import { NodeSettingsIconControl } from "../../custom-controller/settings-icon.component";

  export const SETTINGS_CONTROLER_KEY = 'Settings'
  export const ACTION_CONTROLER_KEY = 'Action'



type NodeType = {
  connection_id: number
  connector: string
  description: string
  input_fields: any
  output_fields: any
  label: string
  name: string
  next:any
  run_after: any
  service_name: string
}


const LIST_ITEM_HEIGHT = 28
const LIST_ITEM_WIDTH = 170
const NODE_PADDING = 4

export class CustomNode extends ClassicPreset.Node {
  width = 24;
  height = 24;
  constructor(public label: string, public uid: string, public meta: NodeType, public socket?: ClassicPreset.Socket) {// FIXME: add meta property interface
    super(label)
    this.socket = this.socket ? this.socket : new ClassicPreset.Socket(`${uid}-socket`)
  }

  public async init() {
    const input_fields_count = 0 // Object.keys(this.meta.input_fields||{}).length
    const output_fields_count = Object.keys(this.meta.output_fields||{}).length

    // const height = (input_fields_count+output_fields_count+4) * LIST_ITEM_HEIGHT + (NODE_PADDING*2)
    const height = 3 * LIST_ITEM_HEIGHT + 2 * NODE_PADDING

    this.height = height
    this.width = LIST_ITEM_WIDTH



    // Object.keys(this.meta.input_fields||{}).forEach(input_key => {
    //   this.addInput(input_key, new ClassicPreset.Input(this.socket!, input_key, false))
    // })
  }
}


  @Component({
    templateUrl: "./custom-node.component.html",
    styleUrls: ["./custom-node.component.scss"],
    host: {
      "data-testid": "node"
    }
  })
  export class CustomReteNodeComponent implements OnInit, OnChanges {
    @Input() data!: CustomNode;
    @Input() emit!: (data: any) => void;
    @Input() rendered!: () => void;

    ignoreControls = [SETTINGS_CONTROLER_KEY, ACTION_CONTROLER_KEY]
    settingControl?: ClassicPreset.Control
    actionControl?: ClassicPreset.Control
    inputControls: {[key: string]: ClassicPreset.Control} = {}
  
    seed = 0;
  
    @HostBinding("class.selected") get selected() {
      return this.data.selected;
    }
  
    constructor(private cdr: ChangeDetectorRef) {
      this.cdr.detach();
    }
    
    ngOnInit(): void {
      this.ngOnChanges()
    }
  
    ngOnChanges(): void {
      this.cdr.detectChanges();
      requestAnimationFrame(() => {
        this.rendered()
      });
      this.settingControl = this.data.controls[SETTINGS_CONTROLER_KEY]
      this.actionControl = this.data.controls[ACTION_CONTROLER_KEY]
      this.inputControls = Object.keys(this.data.controls).reduce((prev, curr) => {
        if(!this.ignoreControls.includes(curr))prev[curr] = this.data.controls[curr]

        return prev
      }, {} as any)
      
      this.seed++; // force render sockets
    }
  }
  
<nb-card>
    <nb-card-header>Manage notification</nb-card-header>
    <nb-card-body>
        <div [formGroup]="recipientsForm">
            <div class="add-recipient">
                Email:<input nbInput type="text" name="" id="" formControlName="email" #email>
                Event:<nb-select multiple formControlName="event" #event>
                        <nb-option value="task_success">Task Success</nb-option>
                        <nb-option value="task_failure">Task Failure</nb-option>
                </nb-select>
                <button nbButton status="success" size="tiny" shape="round" (click)="addRecipient(email.value,event.selected)"><nb-icon icon="plus-outline"></nb-icon>ADD RECIPIENT</button>
                <br>
            </div>
            <!-- <div formArrayName="recipients">
                <div>
                    <table class="recipient">
                        <thead>
                            <th>#</th>
                            <th>Email</th>
                            <th>Events</th>
                            <th></th>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let recipient of getRecipient().controls; let i = index">
                                <tr>
                                    <ng-container [formGroupName]="i">
                                        <td>{{i}}</td>
                                        <td><input nbInput type="text" name="" id="" formControlName="email" class="recipient-email"></td>
                                        <td><nb-select multiple formControlName="event" class="recipient-event">
                                            <nb-option value="success">Success</nb-option>
                                            <nb-option value="failure">Failure</nb-option>
                                        </nb-select></td>
                                        <td class="actions">
                                            <button nbTooltip="delete" nbButton status="danger" shape="round" (click)="removeRecipient(i)"><nb-icon icon="minus-outline"></nb-icon></button>
                                            <button nbTooltip="update" nbButton status="info" shape="round"><nb-icon icon="edit-outline"></nb-icon></button>
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->
        <table>
            <thead>
                <th>#</th>
                <th>Email</th>
                <th>Events</th>
                <th></th>
            </thead>
            <tbody [formGroup]="recipientsForm">
                <ng-container *ngFor="let recipient of $recipients; let i = index">
                    <tr>
                        <ng-container formGroupName={{recipient.id}}>
                            <td>{{i+1}}</td>
                            <td><input nbInput type="text" name="" id="" formControlName="email" class="recipient-email"></td>
                            <td><nb-select multiple formControlName="event" class="recipient-event">
                                <nb-option value="task_success">Task Success</nb-option>
                                <nb-option value="task_failure">Task Failure</nb-option>
                            </nb-select></td>
                            <td class="actions">
                                <button nbTooltip="delete" nbButton status="danger" shape="round" (click)="removeRecipient(recipient.id)"><nb-icon icon="minus-outline"></nb-icon></button>
                                <button nbTooltip="update" nbButton status="info" shape="round" (click)="updateRecipient(recipient.id)"><nb-icon icon="edit-outline"></nb-icon></button>
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    </nb-card-body>
</nb-card>

<div style="display: flex;position: relative;height: 100%;background: #263238;color: whire;" class="custom-node-config-container">
    <section style="border-right: 1px solid #d3d3d329;">
        <ul style="width:3rem; display: flex;flex-direction: column;list-style: none;padding: 0;margin: 0;">
            <li style="padding: .5rem;color: white;cursor: pointer;text-align: center;" title="Configuration">
                <span class="material-icons-outlined" (click)="isConfigVisible=!isConfigVisible">settings</span>
            </li>
        </ul>
    </section>

    <section *ngIf="isConfigVisible" style="height: 100%;border-right: 1px solid #d3d3d329;">
        <div style="color: white;padding: 0.5rem 1rem;height: calc(2.5rem + 1px);border-bottom: 1px solid #d3d3d329;">Configuration</div>
        <ul style="margin: 0;padding: 0;display: flex;flex-direction: column;list-style: none;width: 15rem;">
            <li *ngFor="let config of nodeConfigs" style="color: white;padding: 0.5rem 1rem;" class="config-list-item" [class.active]="config.name===selectedConfig.name" (click)="selectedConfig=config">
                <!-- <div>{{config.name}}</div> -->
                <span>{{config.displayText}}</span>
            </li>
        </ul>
    </section>

    <section [style.display]="selectedConfig.type==='code'?'block':'none'" style="flex-grow: 1;overflow: hidden;">
        <app-editor #editor conteinerClass="custom-node-config-container" (events)="editorEventHandler($event)" [editorConfig]="editorConfigs"></app-editor>
    </section>

    <section *ngIf="selectedConfig.type!=='code'"  style="flex-grow: 1;height: 100%;">
        <header class="textarea-header">
            <ul>
                <li>
                    <div style="display: flex;gap: 1rem;align-items: center;">
                        <span>{{selectedConfig.displayText}}</span>
                        <span class="material-icons-outlined" title="Add item" style="cursor: pointer;border-radius: 50%;border: 1px solid #d3d3d329;" (click)="selectedConfig.children?.push({name: '',type: 'key', inputType: 'text', displayText: 'Key',placeholder: 'config key', children:[]})">add</span>
                    </div>
                </li>
                <li style="margin-left: auto;"></li>
                <li><span class="material-icons-outlined" style="cursor: pointer;" [title]="editor.isFullScreen? 'Minimize': 'Maximize'" (click)="resize()">{{editor.isFullScreen? 'close_fullscreen': 'fullscreen'}}</span></li>
                <li *ngIf="!editorConfigs.readOnly" (click)="saveConfig()"><span class="material-icons-outlined" style="cursor: pointer;" [title]="'Save '+selectedConfig.displayText">save</span></li>
                <li><span class="material-icons-outlined" style="cursor: pointer;" (click)="close()" title="Close">close</span></li>
            </ul>
        </header>
        <section class="config-container" style="background: #263238;height: calc(100% - 2.5rem - 1px);overflow: auto;">
            

            <!-- <div style="color: white;position:sticky;top: 0;background-color: #263238;padding: .5rem 0;display: flex;align-items: center;gap: 1rem;">
               <span>Add Key</span> <span class="material-icons-outlined" title="Add item" style="cursor: pointer;" (click)="selectedConfig.children?.push({name: '',type: 'key', inputType: 'text', displayText: 'Key',placeholder: 'config key', children:[]})">add</span>
            </div> -->

            <div *ngIf="!selectedConfig.children||selectedConfig.children.length===0">
                <code>{{selectedConfig.placeholder ?? 'No configuration found'}}</code>
            </div>

            <div class="config-item config-item-hover" *ngFor="let configItem of selectedConfig.children||[];let configItemIndex=index">

                <div style="color: white;right: 0;transform: translateX(100%);position: absolute;height: calc(100% - 4rem);display: flex;align-items: center;padding: 0 1rem;">
                    <span class="material-icons-outlined" style="cursor: pointer;" (click)="removeItem(selectedConfig.children||[], configItemIndex)">delete</span>
                </div>

                
                
                <div *ngIf="configItem.type==='key'">
                    <div style="display: flex;color: white;flex-direction: column;gap: .5rem;">
                        <label style="display: flex;gap: 1rem;align-items: center;">
                            <span>{{configItem.displayText}}</span> 
                            <select name="type" [(ngModel)]="configItem.inputType">
                                <option value="text">Text</option>
                                <option value="select">Dropdown</option>
                                <option value="file">File</option>
                                <option value="map">Map</option>
                            </select>
                        </label>
                        
                        <label style="display: flex;gap: 1rem;">
                            <input type="text" style="flex-grow: 1;" [(ngModel)]="configItem.name" [placeholder]="configItem.placeholder||''">
                            <span *ngIf="configItem.inputType==='select'" (click)="configItem.children?.push({name: '', type: 'input', displayText: 'Option', placeholder: 'Option'})" class="material-icons-outlined" style="margin-left: auto;cursor: pointer; border-radius: 50%;background-color: rgba(255, 255, 255, 0.01);" title="Add option">add</span>
                        </label>

                    </div>
                    <ng-container *ngIf="configItem.inputType==='select'">
                        <div style="padding:1rem 0 0 10rem;" class="config-item" *ngFor="let configItemChild of configItem.children||[];let i=index">
                            <label *ngIf="configItemChild.type==='input'">
                                <!-- <span>{{configItemChild.displayText}}</span> -->
                                <div style="width: 100%;display: flex;align-items: center;gap: 1rem;" >
                                    <span style="background-color: rgba(255, 255, 255, 0.034);padding: .3rem;border-radius: .3rem;">Option {{i + 1}}</span>
                                    <input style="flex-grow: 1;" type="text" [placeholder]="configItemChild.placeholder||''" [(ngModel)]="configItemChild.name">
                                    <span (click)="removeItem(configItem.children||[], i)" class="material-icons-outlined" style="margin-left: auto;cursor: pointer; border-radius: 50%;background-color: rgba(255, 255, 255, 0.01);" title="Remove">remove</span>
                                </div>
                            </label>
                        </div>
                    </ng-container>
                    
                </div>

                <label *ngIf="configItem.type==='input'">
                    <span>{{configItem.displayText}}</span>
                    <input type="text" [(ngModel)]="configItem.name" [placeholder]="configItem.placeholder||''">
                </label>
            </div>

        </section>
    </section>
</div>

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Context} from "../config/context.service";
import {ServiceResponse} from "../core/data.service";


export type TaskProcess = {
  process_id: number,
  created_time?: Date,
  updated_time?: Date,
  total_nodes: number,
  node_status: {
    [key: string]: number,
    open: number,
    in_progress: number,
    success: number,
    failed: number
  }
}
export type ProcessHistoryResponse = ServiceResponse<{
  pipeline_id: string,
  process_count: number,
  process_list: Array<TaskProcess>
}>


@Injectable({providedIn: 'root'})
export class ProcessHistoryService {
  url: string
  constructor(private context: Context, private http: HttpClient) {
    this.url = context.getApiConfig().PIPELINE_APIS.GET.processHistory
  }

  get(params: {pipelineId: number, from: number, size: number}) {
    return this.http.get<ProcessHistoryResponse>(this.url, {params})
  }
}

import { Component, OnInit,TemplateRef,OnDestroy } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { Nodev2Service } from 'src/app/services/api/nodev2.service';
import { EventEmitter } from 'stream';

@Component({
  selector: 'app-custom-node',
  templateUrl: './custom-node.component.html',
  styleUrls: ['./custom-node.component.css']
})
export class CustomNodeComponent implements OnInit, OnDestroy {
  imageChangedEvent:any = ''
  nodeData:{name:string,description:string,icon:any}={name:'',description:'',icon:''}
  nodeTypes:any = [
    {name:"All",count:10},
    {name:"ATS",count:10},
    {name:"DSaAS",count:10},
    {name:"Invoice",count:10},
    {name:"BI and Workforce Analytics",count:10},
    {name:"Database",count:10},
    {name:"Financial Management",count:10},
    {name:"Procure to Pay",count:10},
    {name:"HCM",count:10},
    {name:"All",count:10},
    {name:"ATS",count:10},
    {name:"DSaAS",count:10},
    {name:"Invoice",count:10},
    {name:"BI and Workforce Analytics",count:10},
    {name:"Database",count:10},
    {name:"Financial Management",count:10},
    {name:"Procure to Pay",count:10},
    {name:"HCM",count:10},
  ]
  nodeDetails:any = []
  uiLoading = false;
  constructor(private dialogService: NbDialogService,private nodeService:Nodev2Service,private router: Router) { }

  ngOnInit(): void {
    this.nodeService.getCustomConnectors().subscribe(((data:any)=>{
      data.payload.data.forEach((connector:any)=>{
        this.nodeDetails.push({id:connector.id,header:connector.label,body:connector.description,footer:`Last Updated By: ${connector.createdBy} on ${new Date(connector.createdTime)}`,icon:connector.icon})
      })
    }))
  }
  ngOnDestroy(): void {
  }
  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' }).onClose.subscribe((data:'CREATE'|'CANCEL')=>{
        if(data==='CREATE'){
          this.createCustomNode()
        }  
        else{
          //reset nodeData
          this.nodeData = {name:'',description:'',icon:''}
          this.imageChangedEvent = ''
        }
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
}
  async createCustomNode(){
    this.uiLoading = true;
    let iconUrl = ""
    if(this.nodeData.icon){
      //save the icon in assets 
      let iconRes = await this.nodeService.saveIcon(this.nodeData.icon).toPromise();
      iconUrl = iconRes.payload.url
      
    }
    let nodeCreated = await this.nodeService.createCustomConnector(this.nodeData.name,this.nodeData.description,iconUrl || "").toPromise()
    this.uiLoading = false;
    if(nodeCreated.status==="success"){
      //move to new page
      this.nodeService.getBoilerPlateFiles(nodeCreated.payload.id).subscribe((data:any)=>{
        this.router.navigate([`/more/custom-node/${nodeCreated.payload.id}/${data.versionId}`]);
      })
    }

  }

  editConnector(id:number){
    this.uiLoading = true;
    this.nodeService.getEditConnectorFiles(id).subscribe((data:any)=>{
      this.uiLoading = false;
      this.router.navigate([`/more/custom-node/${id}/${data.versionId}`]);
    })
  }
}

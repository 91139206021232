import {DataService, ServiceResponse} from "../core/data.service";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UserValueModel} from "../../models/userValue.model";
import {Observable, Subject, of, BehaviorSubject} from "rxjs";
import {map} from "rxjs/operators";
import {Context} from "../config/context.service";
import { schema_response } from "src/app/models/schema.model";
import { ConnectionModel } from "src/app/models/connection.model";

let xmlstring = `<?xml version="1.0" encoding="UTF-8"?>    
<Root>
  <Employee type="admin">    
     <id>1</id>  
     <id>2</id>  
     <name><fname>Vikasssssssssssssssss</fname><lname>Bhadoriya</lname></name>    
     <gender>Male</gender>    
     <mobile>514545</mobile>    
  </Employee>    
  <Employee>    
     <id>2</id>    
     <name><fname>Akash</fname><lname>Bhadoriya</lname></name>     
     <gender>Male</gender>    
     <mobile>5431643</mobile>    
  </Employee>    
  <Employee>    
     <id>3</id>    
     <name><fname>Shivam</fname><lname>Bhadoriya</lname></name>     
     <gender>Male</gender>    
     <mobile>43265436</mobile>    
  </Employee>    
  <User>    
     <id>4</id>    
     <name><fname>Ashish</fname><lname>Bhadoriya</lname></name>      
     <gender>Male</gender>    
     <mobile>5435431</mobile>    
  </User>    
  <User>    
     <id>5</id>    
     <name><fname>Krishna</fname><lname>Bhadoriya</lname></name>     
     <gender>Male</gender>    
     <mobile>432656</mobile>    
  </User>   

</Root>`

let xmlschema = `<?xml version="1.0" encoding="utf-8"?>
<!-- Created with Liquid Technologies Online Tools 1.0 (https://www.liquid-technologies.com) -->
<xs:schema attributeFormDefault="unqualified" elementFormDefault="qualified" xmlns:xs="http://www.w3.org/2001/XMLSchema">
  <xs:element name="Root">
    <xs:complexType>
      <xs:sequence>
        <xs:element maxOccurs="unbounded" name="Employee">
          <xs:complexType>
            <xs:sequence>
              <xs:element maxOccurs="unbounded" name="id" type="xs:unsignedByte" />
              <xs:element name="name">
                <xs:complexType>
                  <xs:sequence>
                    <xs:element name="fname" type="xs:string" />
                    <xs:element name="lname" type="xs:string" />
                  </xs:sequence>
                </xs:complexType>
              </xs:element>
              <xs:element name="gender" type="xs:string" />
              <xs:element name="mobile" type="xs:unsignedInt" />
            </xs:sequence>
            <xs:attribute name="type" type="xs:string" use="optional" />
          </xs:complexType>
        </xs:element>
        <xs:element maxOccurs="unbounded" name="User">
          <xs:complexType>
            <xs:sequence>
              <xs:element name="id" type="xs:unsignedByte" />
              <xs:element name="name">
                <xs:complexType>
                  <xs:sequence>
                    <xs:element name="fname" type="xs:string" />
                    <xs:element name="lname" type="xs:string" />
                  </xs:sequence>
                </xs:complexType>
              </xs:element>
              <xs:element name="gender" type="xs:string" />
              <xs:element name="mobile" type="xs:unsignedInt" />
            </xs:sequence>
          </xs:complexType>
        </xs:element>
      </xs:sequence>
    </xs:complexType>
  </xs:element>
</xs:schema>`

@Injectable({
  providedIn: 'root'
})
export class Nodev2Service {
  customSdkConnector = {
    versionId:-1,
    connectorId:-1
  }
  constructor( private http: HttpClient, private context:Context) {
  }

  //get all types of node
  getNodes(){
    return this.http.get(this.context.getApiConfig().NODE_APIS.GET.nodes);
  }

  createNodeInstance(nodeData:any,pipelineId:string){
      return this.http.post(this.context.getApiConfig().NODE_APIS.POST.nodeInstance(pipelineId),nodeData)
  }


  getNodeDetails(pipelineId:string,nodeName:string){
    return this.http.get(this.context.getApiConfig().NODE_APIS.GET.NodeDetails(pipelineId,nodeName)).pipe(map((data:any)=>data.payload))
  }

  updateNodeInstance(pipelineId:string,nodeInstanceId:string,nodeInstanceData:any){
    return this.http.put(this.context.getApiConfig().NODE_APIS.PUT.nodeInstance(pipelineId,nodeInstanceId),nodeInstanceData);
  }

  getConnectionDetails(pipelineId:string,nodeInstanceId:string){
  //API to fetch input fields for the new connection page
  return this.http.get(this.context.getApiConfig().CONNECTION_APIS.GET.connectionDetails(pipelineId,nodeInstanceId)).pipe(map((data: any) => {
    const inputArray:any = [];
    for (const key in data.payload.connection.inputFields) {
        if (data.payload.connection.inputFields.hasOwnProperty(key)) {
            inputArray.push({ ...data.payload.connection.inputFields[key] });
        }
    }
    return inputArray;
}))
  }

  connectionRemap(pipelineId:string,nodeInstanceId:string,req:any){
    return this.http.post(this.context.getApiConfig().CONNECTION_APIS.POST.connectionRemap(pipelineId,nodeInstanceId),req).pipe(map((data: any) => {
      const inputArray:any = [];
      for (const key in data.payload.connection.inputFields) {
          if (data.payload.connection.inputFields.hasOwnProperty(key)) {
              inputArray.push({ ...data.payload.connection.inputFields[key] });
          }
      }
      return inputArray;
  }));
  }

  testConnection(pipelineId:string,nodeInstanceId:string,req:any){
    return this.http.post(this.context.getApiConfig().CONNECTION_APIS.POST.connectionTest(pipelineId,nodeInstanceId),req).pipe(map((data:any)=>data.payload));
  }

  createConnectionInstance(req:any){
    return this.http.post<any>(this.context.getApiConfig().CONNECTION_APIS.POST.connectionInstance,req);
  }

  getConnectionInstances(nodeName:string){
    return this.http.get(this.context.getApiConfig().CONNECTION_APIS.GET.connectionInstances(nodeName))
  }

  getAllConnectionInstances(){
    return this.http.get<ServiceResponse<Array<ConnectionModel>>>(this.context.getApiConfig().CONNECTION_APIS.GET.connectionAllInstances)
  }

  //node_services
  getServiceDetails(connectorId:string,connectionId:number,serviceName:string){
    return this.http.post(this.context.getApiConfig().SERVICE_APIS.GET.serviceDetails(connectorId),{
      "serviceName": serviceName,
      "connectionId": connectionId
  }).pipe(map((data:any)=>{
      const inputArray:any = [];
      for (const key in data.payload.service.inputFields) {
          if (data.payload.service.inputFields.hasOwnProperty(key)) {
              inputArray.push({ ...data.payload.service.inputFields[key] });
          }
      }
      console.log(inputArray)
      return inputArray;
    }));
  }

  fillServiceDetails(connectorId:string,connectionId:number,serviceName:string,filledData:any){
    return this.http.post(this.context.getApiConfig().SERVICE_APIS.GET.serviceDetails(connectorId),{
      "serviceName": serviceName,
      "connectionId": connectionId
  }).pipe(map((data:any)=>{
      const inputArray:any = [];
      for (const key in data.payload.service.inputFields) {
          if (data.payload.service.inputFields.hasOwnProperty(key)) {
              inputArray.push({ ...data.payload.service.inputFields[key], value:filledData[key].value.raw });
          }
      }
      console.log(inputArray)
      return inputArray;
    }));
  }
  //dynamic for apis

  serviceFormRemap(connectorId:string,connectionId:string,serviceName:string,req:any){

    let newObj = req.inputFields
    for (const [key, val] of Object.entries(newObj)) {
      newObj[key] = {
          "value":{
              "raw":(val as any).value
          }
        }
      }
    let mappedData = {
      inputFields:newObj,
      serviceName,
      connectionId,

    }
    return this.http.post(this.context.getApiConfig().SERVICE_APIS.POST.serviceFormRemap(connectorId),mappedData).pipe(map((data: any) => {
      const inputArray:any = [];
      for (const key in data.payload.service.inputFields) {
          if (data.payload.service.inputFields.hasOwnProperty(key)) {
              inputArray.push({ ...data.payload.service.inputFields[key] });
          }
      }
      return inputArray;
  }));
  }

  saveService(pipelineId:string,pipelineVersion:string,nodeId:string,serviceName:string,req:any){
    return this.http.post(this.context.getApiConfig().SERVICE_APIS.POST.saveService(pipelineId,pipelineVersion,nodeId),{
      serviceName,
      inputFields:req
    })
  }

  getConnectionInstance(connectionInstanceId:string){
    return this.http.get(this.context.getApiConfig().CONNECTION_APIS.GET.connectionInstance(connectionInstanceId));
  }

  updateConnectionInstance(connectionInstanceId:string,formData:any){
    return this.http.put(this.context.getApiConfig().CONNECTION_APIS.PUT.connectionInstance(connectionInstanceId),formData)
  }

  getNodeOutput(pipelineId:string,nodeInstanceId:string,processId:string,field:string){
    return this.http.get(this.context.getApiConfig().PIPELINE_APIS_V2.GET.getNodeOutput(pipelineId,nodeInstanceId,processId,field)).pipe(
      map((data:any)=>data.payload)
    )
  }

  getNodeAllOutput(pipelineId:string,nodeInstanceId:string,processId:string){
    return this.http.get(this.context.getApiConfig().NODE_APIS.GET.nodeOutput(pipelineId,nodeInstanceId)).pipe(
      map((data:any)=>data.payload)
    )
  }

  setNodeConnection(pipelineId:string,versionId:string,nodeId:string,connectionId:number){
    return this.http.post(this.context.getApiConfig().NODE_APIS.POST.nodeSetConnection(pipelineId,versionId,nodeId),{"connectionId":connectionId})
  }

  // custom-node apis
  saveIcon(icon:string){
    let splitedArray = icon.split(",")
    return this.http.post<{payload:{url:string},status:string,errorCode:string,errorMessage:string}>(this.context.getApiConfig().CUSTOM_CONNECTOR_API.POST.saveAssets,{"data":splitedArray[1],"type":"icon"})
  }

  createCustomConnector(name:string,description:string,iconUrl:string){
    let data = {
      name,
      label:name,
      description,
      icon:iconUrl
    }
    return this.http.post<{payload:{id:number,name:string,label:string,description:string,icon:string,release_ver_id:string},status:string}>(this.context.getApiConfig().CUSTOM_CONNECTOR_API.POST.createConnector,data)
  }

  getCustomConnectors(){
    return this.http.get<{payload:Array<any>}>(this.context.getApiConfig().CUSTOM_CONNECTOR_API.GET.connectors)
  }

  getCustomConnector(id:string){
    return this.http.get<{payload:Array<any>}>(this.context.getApiConfig().CUSTOM_CONNECTOR_API.GET.connectorDetails(id))
  }

  // Code-Editor api's
  // myUrl = "http://127.0.0.1:5000"
  pythonLinter(fileName:string,code:string){
    if(this.customSdkConnector.versionId===-1){
      return new BehaviorSubject([]);
    }
    return this.http.post(this.context.getApiConfig().CUSTOM_CONNECTOR_API.POST.lint(this.customSdkConnector.connectorId.toString()),{versionId:this.customSdkConnector.versionId.toString(),code}).pipe(map((data:any)=>{return data.payload.output}))
  }

  pythonAutocomplete(fileName:string,code:string,row:number,col:number){
    return this.http.post(this.context.getApiConfig().CUSTOM_CONNECTOR_API.POST.autoComplete(this.customSdkConnector.connectorId.toString()),{versionId:this.customSdkConnector.versionId.toString(),code,row,col})
  }

  saveFile(fileName:string,code:string){
    return this.http.post(this.context.getApiConfig().CUSTOM_CONNECTOR_API.POST.saveFile(this.customSdkConnector.connectorId.toString()),{versionId:this.customSdkConnector.versionId.toString(),content:code,fileName})
  }

  getBoilerPlateFiles(connectorId:string|number){
    return this.http.post(this.context.getApiConfig().CUSTOM_CONNECTOR_API.POST.boilerPlate(connectorId.toString()),{}).pipe(map((data:any)=>{
      return data.payload;
    }))
  }
  getEditConnectorFiles(connectorId:string|number){
    return this.http.post(this.context.getApiConfig().CUSTOM_CONNECTOR_API.POST.editInit(connectorId.toString()),{}).pipe(map((data:any)=>{
      return data.payload;
    }))
  }

  getConnectorVersions(connectorId:string|number){
    return this.http.get(this.context.getApiConfig().CUSTOM_CONNECTOR_API.GET.versions(connectorId.toString())).pipe(map((data:any)=>{
      return data.payload.data;
    }))
  }

  restoreVersion(connectorId:string|number,versionId:string|number){
    return this.http.post(this.context.getApiConfig().CUSTOM_CONNECTOR_API.POST.restoreVersion(connectorId.toString()),{"versionId":versionId}).pipe(map((data:any)=>{
      return data.payload;
    }))
  }

  getVersionDetails(connectorId:string|number,versionId:string|number){
    return this.http.get(this.context.getApiConfig().CUSTOM_CONNECTOR_API.GET.versionDetails(connectorId.toString(),versionId.toString())).pipe(map((data:any)=>{
      return data.payload;
    }))
  }

  releaseConnector(connectorId:string|number,versionId:string|number){
    return this.http.post(this.context.getApiConfig().CUSTOM_CONNECTOR_API.POST.releaseVersion(connectorId.toString()),{versionId});
  }

  getDeployerStatus(connectorId:string|number,versionId:string|number){
    return this.http.get(this.context.getApiConfig().CUSTOM_CONNECTOR_API.GET.checkDeployerService(connectorId.toString(),versionId.toString()))
  }

}

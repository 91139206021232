<ng-template #field_view let-group="group" let-i="i">

    <ng-container [formGroup]="group">
      <div>
        <label for="name">Name</label>
        <input type="text" formControlName="name" name="name"/>
      </div>

      <div>
        <label for="data_type">data_type</label>
        <select formControlName="data_type" name="data_type">
          <option *ngFor="let dt of dataTypes | keyvalue" [value]="dt.value">{{
            dt.key
            }}</option>
        </select>
      </div>

      <div *ngIf="check(group)">
        <label for="sub_type">sub_type</label>
        <select formControlName="sub_type" name="sub_type">
          <option *ngFor="let dt of dataTypes | keyvalue" [value]="dt.value">{{
            dt.key
            }}</option>
        </select>
      </div>

      <div>
        <label for="is_mandatory">is_mandatory</label>
        <input type="checkbox" formControlName="is_mandatory" name="is_mandatory">
      </div>


      <div>
        <label for="is_null_allowed">is_null_allowed</label>
        <input type="checkbox" formControlName="is_null_allowed" name="is_null_allowed">
      </div>

      <div>
        <label for="is_blank_allowed">is_blank_allowed</label>
        <input type="checkbox" formControlName="is_blank_allowed" name="is_blank_allowed">
      </div>

      <div>
        <label for="skip_field">skipable</label>
        <input type="checkbox" formControlName="skip_field" name="skip_field">
      </div>


      <div>
        <label for="default_value">default value</label>
        <input
          type="text"
          formControlName="default_value"
          name="default_value"
        />
      </div>

      <div *ngIf="check_field(group)">
        <label for="fields">Fields</label>
        <ng-container
          formArrayName="fields"
          *ngFor="let field of get_fields(group).controls; let j = index"
        >
          <ng-container
            *ngTemplateOutlet="field_view; context: { group: field, i: j }"
          ></ng-container>
          <div>
            <button
              type="button"
              nbButton
              status="danger"
              size="tiny"
              (click)="remove_field(group, j)"
            >
              remove field
            </button>
          </div>
        </ng-container>
        <button
          nbButton
          status="primary"
          type="button"
          size="tiny"
          (click)="add_field(group)"
        >
          Add Field
        </button>
      </div>
    </ng-container>
</ng-template>

<!--<ng-template #variable>-->

<!--</ng-template>-->

<!--<ng-template #template let-group="group" let-i="i">-->
<!--  <div>-->
<!--    <div>-->
<!--      <label for="name">Name</label>-->
<!--      <input type="text" formControlName="name" name="name"/>-->
<!--    </div>-->

<!--    <div>-->
<!--      <label for="data_type">data_type</label>-->
<!--      <select formControlName="data_type" name="data_type">-->
<!--        <option *ngFor="let dt of dataType2 | keyvalue" [value]="dt.value">{{-->
<!--          dt.key-->
<!--          }}</option>-->
<!--      </select>-->
<!--    </div>-->

<!--    <div>-->
<!--      <label for="is_mandatory">is_mandatory</label>-->
<!--      <input type="checkbox" formControlName="is_mandatory" name="is_mandatory">-->
<!--    </div>-->

<!--    <div>-->
<!--      <label for="is_null_allowed">is_null_allowed</label>-->
<!--      <input type="checkbox" formControlName="is_null_allowed" name="is_null_allowed">-->
<!--    </div>-->

<!--    <div>-->
<!--      <label for="skipable">skipable</label>-->
<!--      <input type="checkbox" formControlName="skipable" name="skipable">-->
<!--    </div>-->

<!--    <div>-->
<!--      <label for="default_value">default value</label>-->
<!--      <input-->
<!--        type="text"-->
<!--        formControlName="default_value"-->
<!--        name="default_value"-->
<!--      />-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->

<div style="background-color: white">
  <div
    style="display: flex;align-items: center;padding-left: 1rem;border-bottom: 1px solid silver;background-color: #edf1f7">
    <h6>Create New API</h6>
    <button nbButton (click)="close()" style="margin-left: auto">
      <nb-icon icon="close-outline"></nb-icon>
    </button>
  </div>

  <form (ngSubmit)="addAPI()" style="min-height:50vh;max-height: 70vh;width: 80vw;overflow:overlay">

    <ng-container [formGroup]="apiForm">
      <div style="display: flex">
        <div style="padding-left: 1rem;width: 50%">
          <table>
            <tr>
              <td>client_id</td>
              <td>
                <input type="text" formControlName="client_id" nbInput fullWidth fieldSize="small"/>
              </td>
            </tr>
            <tr>
              <td>name</td>
              <td>
                <input type="text" formControlName="name" nbInput fullWidth fieldSize="small"/>
              </td>
            </tr>
            <tr>
              <td>desc</td>
              <td>
                <input type="text" formControlName="desc" nbInput fullWidth fieldSize="small"/>
              </td>
            </tr>
            <tr>
              <td>url</td>
              <td>
                <input type="text" formControlName="url" nbInput fullWidth fieldSize="small"/>
              </td>
            </tr>
            <tr>
              <td>connection_identifier</td>
              <td>
                <!-- <input type="text" formControlName="connection_identifier" /> -->
                <select formControlName="connection_identifier" (change)="setConnectionId($event)">
                  <ng-container *ngFor="let connection of connections;">
                    <option value="{{connection.identifier}}">{{connection.identifier}}</option>
                  </ng-container>
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <button nbButton shape="round" status="primary" size="small" (click)="openConnection()">Add new
                  Connection
                </button>
              </td>
            </tr>
            <tr>
              <td>connection_id</td>
              <td>
                <select formControlName="connection_id" (change)="setConnectionIdentifier($event)">
                  <ng-container *ngFor="let connection of connections">
                    <option value="{{connection.id}}">{{connection.id}}</option>
                  </ng-container>
                </select>
              </td>
            </tr>
            <tr>
              <td>is_secure</td>
              <td>
                <input type="checkbox" formControlName="is_secure"/>
              </td>
            </tr>
            <tr>
              <td>method</td>
              <td>
                <input type="text" formControlName="method" nbInput fullWidth fieldSize="small"/>
              </td>
            </tr>
          </table>
        </div>

        <!--  Separator  -->
        <div style="width: 1px;background-color: silver;position: sticky;top:0;bottom: 0"></div>

        <div style="background-color: ghostwhite; width: calc(50% - 3px);">
          <app-print-json [data]="apiForm.value"
                          [ignore]="{variables: undefined,headers:undefined,schema: undefined}"></app-print-json>
        </div>
      </div>

      <hr style="margin:0">

      <div style="display: flex">
        <!-- Headers--START -->
        <div style="padding-left: 1rem;width: 50%">
          <h6>Headers</h6>
          <ng-container formGroupName="headers">
            <ng-container *ngFor="let header of get_array('headers').controls; let i=index">
              <ng-container *ngTemplateOutlet="field_view;context: { group: header, i: i }"></ng-container>
              <div>
                <button
                  type="button"
                  nbButton
                  status="danger"
                  size="tiny"
                  (click)="remove(get_array('headers'), i)"
                >
                  remove header
                </button>
              </div>
            </ng-container>
            <button type="button" nbButton status="primary"
                    size="tiny" (click)="add_header()">
              Add header
            </button>
          </ng-container>
        </div>
        <!-- Headers--END -->

        <!--  Separator  -->
        <div style="width: 1px;background-color: silver;position: sticky;top:0;bottom: 0"></div>

        <div style="background-color: ghostwhite; width: calc(50% - 3px);">
          <app-print-json [data]="apiForm.value.headers"></app-print-json>
        </div>
      </div>

      <hr style="margin:0">


      <div style="display: flex"style="display: flex">
        <!-- Variables--START -->
        <div style="padding-left: 1rem;width: 50%">
          <h6>Variables</h6>
          <ng-container formGroupName="variables">
            <ng-container *ngFor="let variable of get_array('variables').controls; let i=index">
              <ng-container
                *ngTemplateOutlet="
                      field_view;
                      context: { group: variable, i: i }
                    "
              >
              </ng-container>
              <div>
                <button
                  type="button"
                  nbButton
                  status="danger"
                  size="tiny"
                  (click)="remove(get_array('variables'), i)"
                >
                  remove variable
                </button>
              </div>
            </ng-container>
            <button type="button" nbButton status="primary"
                    size="tiny" (click)="add_variable()">
              Add variable
            </button>
          </ng-container>
        </div>
        <!-- Variables--END -->

        <!--  Separator  -->
        <div style="width: 1px;background-color: silver;position: sticky;top:0;bottom: 0"></div>

        <div style="background-color: ghostwhite; width: calc(50% - 3px);">
          <app-print-json [data]="apiForm.value.variables"></app-print-json>
        </div>
      </div>


      <hr style="margin:0">

      <div style="display: flex">
        <!-- Schema--START -->
        <div style="padding-left: 1rem;width: 50%">
          <h6>Schema:</h6>
          <ng-container
            *ngFor="let schema of schemaKeys().controls; let i = index"
            formArrayName="schema"
            class="json__array"
          >
            <ng-container [formGroupName]="i">
              <tr>
                <td>name</td>
                <td>
                  <input type="text" formControlName="name" nbInput fullWidth fieldSize="small"/>
                </td>
              </tr>
              <tr>
                <td>data_type</td>
                <td>
                  <select formControlName="data_type" name="data_type">
                    <option
                      *ngFor="let dt of dataTypes | keyvalue"
                      [value]="dt.value"
                    >{{ dt.key }}</option
                    >
                  </select>
                </td>
              </tr>
              <tr #sub_type *ngIf="check(schema)">
                <td>sub data_type</td>
                <td>
                  <select formControlName="sub_type" name="sub_type">
                    <option
                      *ngFor="let dt of dataTypes | keyvalue"
                      [value]="dt.value"
                    >{{ dt.key }}</option
                    >
                  </select>
                </td>
              </tr>
              <tr>
                <td>is_mandatory</td>
                <td>
                  <input type="checkbox" formControlName="is_mandatory" name="is_mandatory">
                </td>
              </tr>
              <tr>
                <td>is_null_allowed</td>
                <td>
                  <input type="checkbox" formControlName="is_null_allowed" name="is_null_allowed">
                </td>
              </tr>
              <tr>
                <td>is_blank_allowed</td>
                <td>
                  <input type="checkbox" formControlName="is_blank_allowed" name="is_blank_allowed">
                </td>
              </tr>
              <tr>
                <td>skipable</td>
                <td>
                  <input type="checkbox" formControlName="skip_field" name="skip_field">
                </td>
              </tr>

              <tr>
                <td>default_value</td>
                <td>
                  <input type="text" formControlName="default_value" nbInput fullWidth fieldSize="small"/>
                </td>
              </tr>
              <ng-container *ngIf="checkField(i)">
                <p>Fields:</p>
                <ng-container
                  formArrayName="fields"
                  *ngFor="let field of fields(i).controls; let j = index"
                >
                  <ng-container
                    *ngTemplateOutlet="
                      field_view;
                      context: { group: field, i: j }
                    "
                  >
                  </ng-container>
                  <div>
                    <button
                      type="button"
                      nbButton
                      status="danger"
                      size="tiny"
                      (click)="remove(fields(i), j)"
                    >
                      remove field
                    </button>
                  </div>
                </ng-container>
                <button
                  type="button"
                  nbButton
                  status="primary"
                  size="tiny"
                  (click)="addAttribute(i)"
                >
                  Add Field
                </button>
              </ng-container>
            </ng-container>
            <div>
              <button
                type="button"
                nbButton
                status="danger"
                size="tiny"
                (click)="deleteSchema(i)"
              >
                remove schema
              </button>
            </div>
          </ng-container>
          <button type="button" nbButton status="primary"
                  size="tiny" (click)="addSchema()">
            Add shema
          </button>
        </div>
        <!-- Schema--END -->


        <!--  Separator  -->
        <div style="width: 1px;background-color: silver;position: sticky;top:0;bottom: 0"></div>

        <div style="background-color: ghostwhite; width: calc(50% - 3px);">
          <app-print-json [data]="apiForm.value.schema"></app-print-json>
        </div>
      </div>
    </ng-container>
  </form>


  <div style="display:flex;flex-direction: row-reverse;gap: .5rem;padding: .5rem;border-top: 1px solid silver;">
    <button nbButton shape="round" size="small" status="primary" type="submit" (click)="addAPI()">Submit</button>
    <button nbButton shape="round" size="small" (click)="close()">Cancel</button>
  </div>
</div>

// // @ts-nocheck
import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import * as CodeMirror from 'codemirror';
import {ConfigModel} from "../../models/config.model";

import 'codemirror/addon/hint/show-hint';
import { DashboardStoreService } from 'src/app/services/store/dasboard-store.service';
import { MatSnackBar } from '@angular/material/snack-bar';


export enum EventTypes {
  INIT = 'INIT',
  RESIZE = 'RESIZE',
  SAVE = 'SAVE',
  CLOSE = 'CLOSE',
  TEXT_CHANGE = 'TEXT_CHANGE',
  RUN_CODE = 'RUN_CODE'
}

const wordOperators = ["and", "or", "not", "is"]
const commonKeywords = [
  "as", "assert", "break", "class", "continue",
  "def", "del", "elif", "else", "except", "finally",
  "for", "from", "global", "if", "import",
  "lambda", "pass", "raise", "return",
  "try", "while", "with", "yield", "in"];
const commonBuiltins = ["abs", "all", "any", "bin", "bool", "bytearray", "callable", "chr",
  "classmethod", "compile", "complex", "delattr", "dict", "dir", "divmod",
  "enumerate", "eval", "filter", "float", "format", "frozenset",
  "getattr", "globals", "hasattr", "hash", "help", "hex", "id",
  "input", "int", "isinstance", "issubclass", "iter", "len",
  "list", "locals", "map", "max", "memoryview", "min", "next",
  "object", "oct", "open", "ord", "pow", "property", "range",
  "repr", "reversed", "round", "set", "setattr", "slice",
  "sorted", "staticmethod", "str", "sum", "super", "tuple",
  "type", "vars", "zip", "__import__", "NotImplemented",
  "Ellipsis", "__debug__"
]

let snippets: Array<{text: string, displayText: string}> = [
  { text: 'data = getNodeData(nodes.FILE_001)\ndata.', displayText: 'Get node data' },
  { text: 'configs = fetchConfigValue()\nconfigs.', displayText: 'Fetch config value' },
  { text: 'configs = fetchConfigValue()\ncolumnName = configs.ColumnName', displayText: 'ColumnName' },
]

const allTockens = [
  ...wordOperators.map(x => ({
    text: x, displayText: x
  })),
  ...commonKeywords.map(x => ({
    text: x, displayText: x
  })),
  ...commonBuiltins.map(x => ({
    text: x, displayText: x
  })),
]

snippets = snippets.concat(allTockens)

const findWords = function (editor: ReturnType<typeof CodeMirror>): any {
  const cursor = editor.getCursor()
  const token = editor.getTokenAt(cursor)
  const start: number = token.start
  const end: number = cursor.ch
  const line: number = cursor.line
  const currentWord: string = token.string


  let list: any[] = [];

  if(currentWord) {
    list = snippets.filter(function (item): boolean {
      return item.text.indexOf(currentWord) >= 0
    })
  }

  return {
    list: list,
    from: CodeMirror.Pos(line, start),
    to: CodeMirror.Pos(line, end)
  }
}

function snippet(editor: ReturnType<typeof CodeMirror>): void {
  editor.showHint({
    hint: function() {
      return findWords(editor)
    },
    completeSingle: false 
  })
}


@Component({
  selector: 'app-editor',
  templateUrl: './codemirror.component.html',
  styleUrls: ['./codemirror.component.css'],
})
export class EditorComponent implements OnInit, AfterViewInit {

  @ViewChild('textarea', { static: false }) textarea!: ElementRef;
  @Output() events:EventEmitter<{type: EventTypes, args: Array<unknown>}> = new EventEmitter()
  @Input() isPopup: boolean = false
  @Input() resizable: boolean = false
  @Input() content: string = ''
  @Input() mode: 'javascript' | 'python' = 'python'
  @Input() theme: string = 'default'
  @Input() title: string = 'Script'
  @Input() conteinerClass: string = ''
  @Input() editorConfig: CodeMirror.EditorConfiguration = {}
  
  codemirrorConfigs: CodeMirror.EditorConfiguration  = {
    lineNumbers: true, 
    theme: this.theme, 
    mode: this.mode, 
    extraKeys: {"Ctrl-Space": snippet},
    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
    tabSize: 2
  }

  editor!: ReturnType<typeof CodeMirror>
  currentLineNumber: number = 0
  currentColumnNumber: number = 0
  isFullScreen: boolean = false
  canOpenFile: boolean = false
  isDirty: boolean = false

  fileName: string = '' // imported filename
  dialog?: HTMLElement

  ngAfterViewInit() {
    this.editor = CodeMirror.fromTextArea(this.textarea.nativeElement, {...this.codemirrorConfigs, ...this.editorConfig})
    this.editor.setValue(this.content)
    const editor = this.editor
    
    // Update css
    this.setEditorCss({borderRight: '2px solid #263238'});

    this.editor.on("cursorActivity", (editor: ReturnType<typeof CodeMirror>) => {
      const cursor = editor.getCursor();
      this.currentLineNumber = cursor.line + 1;
      this.currentColumnNumber = cursor.ch + 1;

    });

    editor.on('change', () => {
      this.isDirty = true
      
      this.events.emit({
        type: EventTypes.TEXT_CHANGE,
        args: [{getValue: () => editor.getValue(), callback: () => {}}]
      })

      // TODO: enable hint after template added, fix z-index for hint 
      // editor.showHint({
      //   hint: function() {
      //     return findWords(editor)
      //   },
      //   completeSingle: false 
      // })
    })

    // remove dialog border radius
    this.dialog = this.editor.getWrapperElement().closest('.mat-dialog-container') as HTMLElement
    if(this.dialog) {
      this.dialog.style.borderRadius = '0'
    }

    this.events.emit({
      type: EventTypes.INIT,
      args: [this.editor]
    })
  }

  constructor() {
    //@ts-ignore
    this.canOpenFile = !!window.showOpenFilePicker;
  }

  ngOnInit(): void {
  }


  resize() {
    this.events.emit({type: EventTypes.RESIZE, args: [(isFullScreen: boolean) => {this.isFullScreen = isFullScreen}]})
  }

  save() {
    this.isDirty = false
    this.events.emit({type: EventTypes.SAVE, args:[{getValue: () => this.editor.getValue()}]})
  }

  close() {
    this.events.emit({type: EventTypes.CLOSE, args:[{getValue: () => this.editor.getValue()}]})
  }

  async loadFile() {
    const pickerOpts = {
      types: [
        {
          description: 'Images',
          accept: {
            'text/python': ['.py', '.txt']
          }
        },
      ],
      excludeAcceptAllOption: true,
      multiple: false
    };
    //@ts-ignore
    if(!window.showOpenFilePicker) {
      return alert('Browser does not support this feature!')
    }

    //@ts-ignore
    const handles = await window.showOpenFilePicker(pickerOpts);
    if(handles.length > 0) {
      let file = await handles[0].getFile();
      this.fileName = file.name
      let fileText: string =  await file.text();
      this.editor.setValue(fileText)
    }
  }


  public toggleFullScreen() {
    // if(!this.dialog) return;
    
    // @ts-ignore
    if(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
      if (document.exitFullscreen) {document.exitFullscreen()} 
      // @ts-ignore
      else if (document.mozCancelFullScreen) {document.mozCancelFullScreen()} 
      // @ts-ignore
      else if (document.webkitExitFullscreen) {document.webkitExitFullscreen()} 
      // @ts-ignore
      else if (document.msExitFullscreen) {document.msExitFullscreen()}
    } else {

      let element = (this.conteinerClass ? this.editor.getWrapperElement().closest('.'+this.conteinerClass) : (this.editor.getWrapperElement().closest('.mat-dialog-container') || this.editor.getWrapperElement())) as HTMLElement;

      if (element.requestFullscreen) {element.requestFullscreen()} 
      // @ts-ignore
      else if (element.mozRequestFullScreen) { element.mozRequestFullScreen()} 
      // @ts-ignore
      else if (element.webkitRequestFullscreen) { element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)} 
      // @ts-ignore
      else if (element.msRequestFullscreen) { element.msRequestFullscreen()}

    }
  }

  public setValue(sourceCode: string) {
    if(this.editor) this.editor.setValue(sourceCode);
    this.content = sourceCode
    this.isDirty = false
  }

  testScript() {
    this.events.next({
      type: EventTypes.RUN_CODE,
      args: [{getValue: () => this.editor.getValue()}]
    })
  }

  public setEditorCss(cssStyle: Partial<CSSStyleDeclaration>) {
    if(!this.editor)return;
    const editorElement = this.editor.getWrapperElement();

    // set custom style
    Object.assign(editorElement.style, cssStyle);
  }

}


// function getBrowserName() {
  
//   // Opera 8.0+
//   let isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

//   // Firefox 1.0+
//   let isFirefox = typeof InstallTrigger !== 'undefined';

//   // Safari 3.0+ "[object HTMLElementConstructor]" 
//   let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

//   // Internet Explorer 6-11
//   let isIE = /*@cc_on!@*/false || !!document.documentMode;

//   // Edge 20+
//   let isEdge = !isIE && !!window.StyleMedia;

//   // Chrome 1 - 79
//   let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

//   // Edge (based on chromium) detection
//   let isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

//   // Blink engine detection
//   let isBlink = (isChrome || isOpera) && !!window.CSS;

//   return {
//     isOpera, isFirefox, isSafari, isIE, isEdge, isChrome, isEdgeChromium, isBlink
//   }
// }
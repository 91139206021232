<div>
  <div class="group-view-header">
    <!-- TODO: create filter for group connected to group data -->
    <div class="search">
      <input
        nbInput
        fieldSize="medium"
        shape="round"
        #searchBar
        type="text"
        class="search search-bar"
        [(ngModel)]="searchText"
        placeholder="Search ..."
      />
    </div>
    <div class="group-actions">
      <!-- TODO: create Group Button which adds group dialog or navigation to page -->
      <!-- TODO:add create button with tool tip -->
      <button
        nbButton
        nbTooltip="create new folder"
        nbTooltipPlacement="bottom"
        shape="round"
        size="giant"
        hero
        status="info"
        (click)="createGroup()"
      >
        <nb-icon icon="folder-add-outline"></nb-icon>
      </button>
      <!-- TODO: toggle button between list and grid view -->
      <nb-button-group (valueChange)="updateViewModeValue($event)">
        <button
          nbButtonToggle
          value="grid"
          nbTooltip="grid view"
          nbTooltipPlacement="top"
          pressed
        >
          <nb-icon icon="grid-outline"></nb-icon>
        </button>
        <button
          nbButtonToggle
          value="list"
          nbTooltip="list view"
          nbTooltipPlacement="top"
        >
          <nb-icon icon="list-outline"></nb-icon>
        </button>
      </nb-button-group>
    </div>
    <br>
    <br>
    <ng-container class="group-body grid" *ngIf="viewMode == 'grid'">
      <!-- <ng-container *ngTemplateOutlet="grid,context:{group:default_group}">
            </ng-container> 
        [nbContextMenu]="items"
             nbContextMenuTag="my-context-menu"
        -->
        <div class="grid-box" style="padding: 1rem;">
      
        <nb-card *ngFor="let group of (groups$) | searchFilter: searchText">
          <nb-card-header fixed></nb-card-header>
          <nb-card-body
            [class]="[group?.icon == 'NULL' ? 'folder-body' : 'folder']"
            (dblclick)="openFolder(group.id)"
            [nbContextMenu]="context_items"
            nbContextMenuTag="my-context-menu"
            nbContextMenuTrigger="click"
            (click)='groupId = group.id'
          >
            <img
              class="thumbnail-image"
              src="{{ group?.icon}}"
              onerror=" this.src='https://static.vecteezy.com/system/resources/previews/000/436/705/non_2x/vector-folder-icon.jpg'"
              alt="..."
            />
          </nb-card-body>
          <nb-card-footer>
            {{ group.name }}
            <nb-icon style="cursor: pointer;" icon="edit-outline" (click)="editGroup(group)"></nb-icon>
          </nb-card-footer>
        </nb-card>
      
    </div>
    </ng-container>
    <ng-container class="group-body list" *ngIf="viewMode == 'list'">
      <div class="table-container">
      <table class="group-table">
        <thead>
          <th>Name</th>
          <th>Date Created</th>
          <th colspan="3">Actions</th>
        </thead>
        <tbody>
         <tr *ngFor="let group of (groups$)">
            <td>{{ group.name }}</td>
            <td>{{ group?.createdBy }}</td>
            <td colspan="3">
              <button nbButton status="success" (click)="openFolder(group.id)">
                Open
              </button>
              <button nbButton>Delete</button>
              <button nbButton [nbContextMenu]="more_items"
              nbContextMenuTag="my-context-menu"
              nbContextMenuTrigger="click"
              (click)='groupId = group.id'>
                <nb-icon icon="more-vertical-outline"></nb-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </ng-container>
  </div>
</div>

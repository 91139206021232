<!-- <app-node-editor></app-node-editor> -->
<div    [nbSpinner]="uiLoading"
    nbSpinnerStatus="danger"
    nbSpinnerSize="large"
    nbSpinnerMessage="">
            <ng-component *ngTemplateOutlet="nodePage"></ng-component>

    <!-- <div id="stepper">
        <div *ngFor="let step of steps" [class.active]="step.id===steps.length" style="font-weight:bold;cursor: pointer;" (click)="changeStep(step.id,confirmationPopup)">
            {{step.name}}
            <nb-icon icon="arrow-forward-outline" pack="eva"  *ngIf="step.id!==steps.length"></nb-icon>
        </div>
    </div> -->
    <!-- <ng-container [ngSwitch]="steps[steps.length-1].pageName">
        <ng-container *ngSwitchCase="'editorPage'">
            <app-node-editor></app-node-editor>
        </ng-container>
        <ng-container *ngSwitchCase="'nodePage'">
            <ng-component *ngTemplateOutlet="nodePage"></ng-component>
        </ng-container>
    </ng-container> -->
</div>


<ng-template #nodePage>
    <header>
        <div>
            <nb-form-field>
                <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
                <input type="text" placeholder="Search Node" nbInput>
            </nb-form-field>
        </div>
        <div>
            <button (click)="open(dialog)" nbButton status="success"><nb-icon icon="plus-outline" pack="eva"></nb-icon>Create Node</button>
        </div>
    </header>
    <section style="display: flex;padding:2rem 4rem;padding-bottom: 0;">
        <div style="flex:1">
            <nb-list style="height: 60vh;">
                <nb-list-item *ngFor="let node of nodeTypes" class="list-item">
                  {{ node.name }} ({{node.count}})
                </nb-list-item>
            </nb-list>
        </div>
        <div style="flex:3;height: 60vh;overflow-y: scroll;padding:0.5rem">
            <nb-card *ngFor = "let nodeDetail of nodeDetails">
                <nb-card-header style="display:flex;justify-content: space-between;align-items: center;">
                    <div>
                        {{nodeDetail.header}}
                    </div>

                    <div>
                        <span nbTooltip="Edit" style="cursor: pointer;height:50px;border:1px solid rgba(0, 0, 0, 0.162);padding:0.5rem;background-color: rgba(0, 0, 0, 0.062);">
                            <nb-icon icon="edit-outline" pack="eva" (click)="editConnector(nodeDetail.id)"></nb-icon>
                        </span>
                        <img style="height: 50px;width:50px" [src]="nodeDetail.icon" alt="bullhorn-image" >
                    </div>
                </nb-card-header>
                <nb-card-body>{{nodeDetail.body}}</nb-card-body>
                <nb-card-footer>{{nodeDetail.footer}}</nb-card-footer>
              </nb-card>
        </div>
    </section>
</ng-template>


<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card style="height:95vh;width:50vw">
      <nb-card-header>NEW NODE
    </nb-card-header>
      <nb-card-body>
        <div style="display: flex;justify-content: space-between;">
            <div>Name <span style="color:rgb(198, 44, 44)">*</span></div>
            <div>
                <input style="width: 15rem;" nbInput type="text" [(ngModel)]="nodeData.name" required #nodeName="ngModel">
                <div *ngIf="!nodeName.valid && nodeName.touched" style="font-size: small;color:rgb(175, 39, 39);border:rgb(248, 133, 133)">Node name is required!!</div>
            </div>
        </div>
        
        <br>
        <div style="display: flex;justify-content: space-between;">
            <div style="margin-right:1rem">Description</div>
            <div><textarea style="width: 15rem;" nbInput [(ngModel)]="nodeData.description"></textarea></div>
        </div>
        <br>
        <div style="display: flex;justify-content: space-between;">
            <div>Icon Upload</div>
            <div><input style="width:15rem" nbInput type="file" accept="image/png, image/jpeg" (change)="fileChangeEvent($event)"/></div>
        </div>
        <br>
        <app-image-cropper *ngIf="imageChangedEvent" [imageChangedEvent]="imageChangedEvent" (croppedImage)="nodeData.icon = $event"></app-image-cropper>
      </nb-card-body>
      <nb-card-footer style="display: flex;justify-content: right;">
        <button style="margin-right:1rem" nbButton size="medium" status="danger" (click)="ref.close('CANCEL')">Cancel</button>
        <button [disabled]="!nodeName.valid" nbButton size="medium" status="success" (click)="ref.close('CREATE')">Create</button>
      </nb-card-footer>
    </nb-card>
</ng-template>


<ng-template #confirmationPopup let-ref="dialogRef">
    <nb-card>
        <nb-card-header style="font-size:large">
            All the Progress will be lost!
        </nb-card-header>
        <nb-card-footer style="display: flex;justify-content: right;">
          <button style="margin-right:0.5rem" nbButton size="small" status="danger" (click)="ref.close('no')">CANCEL</button>
          <button nbButton size="small" status="success" (click)="ref.close('yes')">CONFIRM</button>
        </nb-card-footer>
      </nb-card>
</ng-template>
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {Obj, dataTypes, keysOfObject} from "../edit-api/edit-api.component";

@Component({
  selector: 'app-drag',
  templateUrl: './drag.component.html',
  styleUrls: ['./drag.component.css']
})
export class DragComponent implements OnInit {

  @Input() items: Array<string> = []
  @Output() onchange = new EventEmitter<Array<string>>();

  configForm: any;
  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes:SimpleChanges):void {
    // console.log(changes)
    if(changes.items && !Array.isArray(changes.items.currentValue)) {
      this.items = []
    }
  }

  addItem(event: any): void {
    if (event.target.value) {
     this.items.push(event.target.value);
     this.sendData();
    }
    event.target.value = "";
  }

  remove(index: number): void {
    this.items.splice(index, 1);
    this.sendData();
  }
  

  allowDrop(ev: any) {
    ev.preventDefault();
    // ev.target.style.width = "2rem"
  }

  dragOut(ev: any) {
    // ev.target.style.width = ".5rem"
  }
  

  drag(ev: any) {
    ev.dataTransfer.setData("text", ev.target.id);
  }
  
  drop(ev: any) {
    ev.preventDefault();
    const selectedItemIndex = parseInt(ev.dataTransfer.getData("text"));
    const droppedItemIndex = parseInt(ev.target.id);
    console.log(selectedItemIndex, droppedItemIndex, this.items[selectedItemIndex])
    
    if(selectedItemIndex > droppedItemIndex) {
      this.items.splice(droppedItemIndex, 0, this.items[selectedItemIndex]);
      this.items.splice(selectedItemIndex+1, 1);
    } 

    if(selectedItemIndex < droppedItemIndex) {
      this.items.splice(droppedItemIndex, 0, this.items[selectedItemIndex]);
      this.items.splice(selectedItemIndex, 1);
    }

    this.sendData()
    // ev.target.appendChild(document.getElementById(data));
  }

  falseDrop(ev: any) {
    ev.preventDefault();
    const selectedItemIndex = parseInt(ev.dataTransfer.getData("text"));
    const droppedItemIndex = parseInt(ev.target.id.split('_')[1]);
    console.log(ev.target.id)
    console.log(selectedItemIndex, droppedItemIndex, this.items[selectedItemIndex])
    
    if(selectedItemIndex > droppedItemIndex) {
      this.items.splice(droppedItemIndex, 0, this.items[selectedItemIndex]);
      this.items.splice(selectedItemIndex+1, 1);
    } 

    if(selectedItemIndex < droppedItemIndex) {
      this.items.splice(droppedItemIndex, 0, this.items[selectedItemIndex]);
      this.items.splice(selectedItemIndex, 1);
    }

    this.sendData()
    // ev.target.appendChild(document.getElementById(data));
  }


  sendData() {
    this.onchange.emit(this.items)
  }

}


import { Component, OnInit,Input, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { EventTypes } from '../codemirror/codemirror.component';
import { NbDialogService } from '@nebular/theme';
import { Nodev2Service } from 'src/app/services/api/nodev2.service';

@Component({
  selector: 'app-file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.css']
})
export class FileExplorerComponent implements OnInit {
  @Input() readOnly:boolean = false;
  loading=false
  newFileName=''
  @Input() files:Array<{name:string,content:string,modified:boolean}>=[
  ]

  editorEvents: Subject<any> = new Subject<any>();
  
  constructor(private dialogService: NbDialogService,private nodeService:Nodev2Service) { }

  ngOnInit(): void {
    // this.nodeService.getBoilerPlateFiles().subscribe((data:Array<{name:string,code:string}>)=>{
    //   data.forEach((data)=>{
    //     this.files.push({name:data.name,content:data.code,modified:false})
    //   })
    // })
  }

  openFile(file:{name:string,content:string}){
    this.editorEvents.next({name:"OPEN_FILE",args:file})

  }
  addFile(dialog:TemplateRef<any>){
    this.dialogService.open(dialog).onClose.subscribe((fileName:string)=>{
      if(fileName){
        this.files.push({name:fileName,content:'',modified:true})
        this.newFileName=''
      }
    })

  }

  deleteFile(file:{name:string,content:string}){
    console.log("deleteFile")
  }

  incomingEvents(e:{type: EventTypes, args: any}){
    let fileName = e.args[0].fileName
    let fileContent = e.args[0].fileContent
    if(e.type===EventTypes.TEXT_CHANGE){
      this.files = this.files.map((file)=>{
        if(file.name===fileName) return {name:fileName,content:fileContent,modified:true}
        return file
      })
    }
    else if(e.type===EventTypes.SAVE){
      this.saveFile(fileName,fileContent)
    }
  }


  saveFile(fileName:string,fileContent:string){
    this.loading = true;
    this.nodeService.saveFile(fileName,fileContent).subscribe(res=>{
      //if file saved
      this.loading = false;
      this.files = this.files.map(file=>{
        if(file.name === fileName) return {name:file.name,content:file.content,modified:false}
        return {name:file.name,content:file.content,modified:file.modified}
      })
    })
  }

  getAllFiles(){
    return this.files.map(file=>{return {file:file.name,code:file.content}});
  }

}
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { DataService } from '../../services/api/data.service';
import { PipelineService } from '../../services/api/pipeline.service';
import { Router } from '@angular/router';
import { GroupService } from '../../services/api/group.service';
import { data } from 'jquery';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-create-pipeline',
  templateUrl: './create-pipeline.component.html',
  styleUrls: ['./create-pipeline.component.css'],
})
export class CreatePipelineComponent implements OnInit, AfterViewInit {
  status: boolean = false;
  pipeline_id: any;
  selectedFile!: File | null;
  loader:boolean = false
  @ViewChild('pipelineName') pipelineNameRef!: ElementRef
 
  constructor(
    private groups: GroupService,
    private router: Router,
    private pipelineService: PipelineService,
    private data_service: DataService
  ) {
    this.pipeline.controls['groupid'].disable();
  }
  ngAfterViewInit(): void {
    this.pipeline.valueChanges.subscribe((data) => {
      let selectedFile = data.selectedFile
      let selectGroupDisabled = this.pipeline.controls['groupid'].disabled

      if(selectedFile && selectGroupDisabled) {
        this.pipeline.controls['groupid'].enable()
      }

      if(!selectedFile && !selectGroupDisabled) {
        this.pipeline.controls['groupid'].disable()
        this.pipeline.controls['groupid'].reset()
      }
    })

    if(this.pipelineNameRef) {
      this.pipelineNameRef.nativeElement.focus()
    }
  }

  ngOnInit(): void {
    this.groups.listGroups().subscribe((data: any) => {
      this.groupIds = data;
      console.log(this.groupIds);
    });
  }

  pipeline: FormGroup = new FormGroup({
    name: new FormControl(),
    email: new FormControl(),
    groupid: new FormControl(),
    selectedFile: new FormControl(),
  });

  groupIds: Array<any> = [];

  addPipeline(data: any) {
    data = JSON.parse(JSON.stringify(data));
    this.pipelineService.createPipeline(data).subscribe((response) => {
      console.log(data);
      console.log(response);
      this.pipelineService
        .getPipeline(response.payload.pipelineId)
        .subscribe((response) => {
          this.data_service.changePipeline(response);
          this.data_service.subPipeline.next(response);
          console.log(this.data_service.currentPipeline);
        });
      console.log(this.data_service.currentPipeline);
      this.pipeline_id = response.payload.pipelineId;
      console.log(this.pipeline_id);
      let config = { edit: true };
      this.router.navigate(['/dashboard', this.pipeline_id], {
        queryParams: { config: btoa(JSON.stringify(config)) },
      });
    });
  }

  async onSubmit(){
  	console.log(this.pipeline.value);
    let fileData = await this.readFile()
    console.log(fileData)
    if(fileData)
    {  
      this.loader = true
      let response = await this.pipelineService.importPipeline(
        { ...fileData,
          pipeline:
          {...this.pipeline.value},
        }
      ).toPromise()
      this.loader = false
      let config = {edit: true}        
      this.router.navigate(['/dashboard', response.payload.pipeline.id], {queryParams: {config: btoa(JSON.stringify(config))}});

    }
    else
    {
      this.addPipeline(this.pipeline.value);
    }   
    // this.pipeline.reset({});

    // let config = {edit: true}
    // this.router.navigate(['/dashboard', this.pipeline_id], {queryParams: {config: btoa(JSON.stringify(config))}});
  }

  onFileSelect(event: { target: HTMLInputElement | any }) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      this.pipeline.controls['groupid'].enable();
    }
    else {
      this.pipeline.controls['groupid'].disable();
    }
  }

  removeFile(event: MouseEvent) {
    event.preventDefault();
    this.pipeline.get('selectedFile')?.reset();
    this.selectedFile = null;
  }

  readFile() {
    return new Promise<any>((resolve, reject) => {
      if (this.selectedFile) {
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          let jsonData = JSON.parse(fileReader.result as string);
          resolve(jsonData);
        };
        fileReader.readAsText(this.selectedFile);
      } else {
        resolve(null);
      }
    });
  }
}

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { config } from 'rxjs';
import { ConfigService } from 'src/app/services/api/config.service';
import {ConfigModel} from "../../models/config.model";
@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.css']
})
export class AddTaskComponent implements OnInit {


  data:Array<ConfigModel> = [];
  show:boolean=true;
  constructor(public dialogRef: MatDialogRef<AddTaskComponent>,private configService:ConfigService) {
  }

  ngOnInit(): void {
  	this.configService.getAll().then((configs)=>{
  		this.data = configs.filter(x => x.type === "source");
  		console.log(configs,this.data);
      this.show = true;
  	});
  }

  sendConfigId(id:any){
  	this.dialogRef.close({id:id, node:null});
  }

}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  create = true;
  view = true;
  back = false;
  dag= false;

  constructor() { 
    
  }

  
}

<div style="display: flex;flex-direction:column;align-items: center;justify-content: center;">

    <form style="display: flex;flex-direction: column;overflow: auto;width: 100%;padding: 1rem;background-color: white;gap: .5rem;box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;">
        
        <ng-container *ngFor="let input of form.inputsInoder">
            <ng-component *ngTemplateOutlet="recTemplate;context:{input:input}"></ng-component>
        </ng-container>
    
    </form>

    

</div>

<ng-template #recTemplate let-input="input">
    <div *ngIf="input.visible">
        <ng-container [ngSwitch]="input.inputConfig.formatType">
            
            <div style="display: flex;justify-content: space-between;" *ngSwitchCase="'group'">
                    <div *ngFor="let child of input.childrens">
                        <ng-component *ngTemplateOutlet="recTemplate;context:{input: child}"></ng-component>
                        
                    </div>
            </div>
            <ng-container *ngSwitchCase="'sql'">
                <ng-component *ngTemplateOutlet="codeInputTemplate;context:{input: input}"></ng-component>
            </ng-container>
            <ng-container *ngSwitchCase="'json'">
                <ng-component *ngTemplateOutlet="codeInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'python'">
                <ng-component *ngTemplateOutlet="codeInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'text'">
                
                <ng-component *ngTemplateOutlet="stringInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'integer'">
                <ng-component  *ngTemplateOutlet="numberInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'number'">
                <ng-component  *ngTemplateOutlet="numberInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'float'">
                <ng-component  *ngTemplateOutlet="floatInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'select'">
                <ng-component  *ngTemplateOutlet="dropdownInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'multi_select'">
                <ng-component  *ngTemplateOutlet="multiDropdownInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'checkbox'">
                <ng-component  *ngTemplateOutlet="checkboxInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

            <ng-container *ngSwitchCase="'radio'">
                <ng-component  *ngTemplateOutlet="radioInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

        <ng-container *ngSwitchCase="'text_area'">
            <ng-component *ngTemplateOutlet="textAreaTemplate;context:{input:input}"></ng-component>
        </ng-container>

        <ng-container *ngSwitchCase="'tel'">
            <ng-component *ngTemplateOutlet="telInputTemplate;context:{input:input}"></ng-component>
        </ng-container>

        <ng-container *ngSwitchCase="'date'">
            <ng-component *ngTemplateOutlet="dateInputTemplate;context:{input:input}"></ng-component>
        </ng-container>

        <ng-container *ngSwitchCase="'date_time'">
            <ng-component *ngTemplateOutlet="dateTimeInputTemplate;context:{input:input}"></ng-component>
        </ng-container>

        <ng-container *ngSwitchCase="'password'">
            <ng-component *ngTemplateOutlet="passwordInputTemplate;context:{input:input}"></ng-component>
        </ng-container>

        <ng-container *ngSwitchCase="'url'">
            <ng-component *ngTemplateOutlet="urlInputTemplate;context:{input:input}"></ng-component>
        </ng-container>

        <ng-container *ngSwitchCase="'email'">
            <ng-component *ngTemplateOutlet="emailInputTemplate;context:{input:input}"></ng-component>
        </ng-container>

        <ng-container *ngSwitchCase="'multi'">
            <ng-component *ngTemplateOutlet="arrayInputTemplate;context:{input:input}"></ng-component>
        </ng-container>


            <ng-container *ngSwitchDefault>
                <ng-component *ngTemplateOutlet="stringInputTemplate;context:{input: input}"></ng-component>
            </ng-container>

        </ng-container>
    </div>

    <!-- <ng-container *ngIf="input.error.length">
        <div *ngFor="let error of input.error" *ngIf="input.error" style="background-color: #fee;margin-top: -8px;color:#c33;font-size:smaller;padding:3px;"><nb-icon style="margin:0 3px 0 3px" icon="alert-triangle-outline"></nb-icon>{{input.error}}</div>
    </ng-container>
    <div *ngIf="input.error" style="background-color: #fee;margin-top: -8px;color:#c33;font-size:smaller;padding:3px;"><nb-icon style="margin:0 3px 0 3px" icon="alert-triangle-outline"></nb-icon>{{input.error}}</div>
    <div *ngIf="input.error" style="background-color: #fee;margin-top: -8px;color:#c33;font-size:smaller;padding:3px;"><nb-icon style="margin:0 3px 0 3px" icon="alert-triangle-outline"></nb-icon>{{input.error}}</div> -->
    <div *ngIf="input.description" style="margin-top:-8px"><em style="font-size: smaller;opacity: 50%;">{{input.description}}</em></div>
    <div *ngFor="let error of input.errors" style="background-color: #fee;margin-top: -8px;color:#c33;font-size:smaller;padding:3px;"><nb-icon style="margin:0 3px 0 3px" icon="alert-triangle-outline"></nb-icon>{{error}}</div>
</ng-template>


<ng-template #stringInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <!-- <mat-icon style="margin:auto auto;">text_fields</mat-icon> -->
            <nb-icon nbPrefix icon="text-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'" nbInput fullWidth="true" fieldSize="small" type="text" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled" (blur)="input.onBlur()">
        </nb-form-field>
        
    </div>
</ng-template>


<ng-template #numberInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="hash-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'"  nbInput fullWidth="true" fieldSize="small" type="number" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled" (blur)="input.onBlur()" (keyup)="input.keyupEvent()">
        </nb-form-field>
    </div>
</ng-template>

<ng-template #floatInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="facebook-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'"  nbInput fullWidth="true" fieldSize="small" type="number" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled" (blur)="input.onBlur()">
        </nb-form-field>
    </div>
</ng-template>




<ng-template #dropdownInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="arrow-down-outline"></nb-icon>
            <nb-select [status]="input.errors.length?'danger':'basic'"  fullWidth="true" size="small" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled" (ngModelChange)="input.onBlur()">
                <!-- <nb-option *ngFor="let option of input.options" [value]="option[input.inputConfig.optionKey||'key']">{{option[input.inputConfig.optionValueKey||'value']}}</nb-option> -->
                <nb-option *ngFor="let option of input.options" [value]="option">{{option}}</nb-option>
            </nb-select>
        </nb-form-field>
        
    </div>
</ng-template>


<!-- <ng-template #multiDropdownInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}</label>
        <select  [(ngModel)]="input.value" [disabled]="input.disabled" multiple (change)="input.onBlur()">
            <option *ngFor="let option of input.options" [value]="option.key">{{option.value}}</option>
        </select>
    </div>
</ng-template> -->

<ng-template #multiDropdownInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field (click)="input.toggleOpen()">
            <nb-icon nbPrefix icon="checkmark-square-outline"></nb-icon>
            <nb-select [status]="input.errors.length?'danger':'basic'"  size="small" fullWidth="true" multiple [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled" >
                <nb-option *ngFor="let option of input.options" [value]="option">{{option}}</nb-option>
            </nb-select>
        </nb-form-field>

        <!-- <nb-form-field>
            <nb-icon nbPrefix icon="lock-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'"  fullWidth fieldSize="small" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled" [type]="getInputType()" nbInput (blur)="input.onBlur()">
            <button nbSuffix nbButton ghost (click)="toggleShowPassword()">
              <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                       pack="eva"
                       [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
              </nb-icon>
            </button>
          </nb-form-field> -->

    </div>
</ng-template>


<ng-template #checkboxInputTemplate let-input="input">
    <div>
        <label style="display: flex;flex-direction: row;align-items: center; gap: .5rem;">

            <nb-checkbox [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled" (change)="input.onBlur()">{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></nb-checkbox>
            
        </label>
    </div>
</ng-template>



<ng-template #radioInputTemplate let-input="input" > 
    <div>
        <label style="display: flex;flex-direction: row;align-items: center; gap: .5rem;" *ngFor="let option of input.options">
            <input type="radio" [(ngModel)]="input.value" [disabled]="input.disabled" (change)="input.onBlur()">
            {{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container>
        </label>
    </div>
</ng-template>

<ng-template #textAreaTemplate let-input="input">
    <div>
        <label>{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <!-- <mat-icon style="margin:auto auto;">text_fields</mat-icon> -->
            <!-- <nb-icon nbPrefix icon="text-outline"></nb-icon> -->
            <textarea [status]="input.errors.length?'danger':'basic'"  nbInput fullWidth [(ngModel)]="input.value" (blur)="input.onBlur()"></textarea>
        </nb-form-field>
    </div>
</ng-template>



<ng-template #passwordInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <!-- <div style="display: flex;">
            <mat-icon style="margin:auto auto;">password</mat-icon>
            <input nbInput fullWidth="true" fieldSize="small" type="password" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled" (blur)="input.onBlur()">
        </div> -->
        <nb-form-field>
            <nb-icon nbPrefix icon="lock-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'"  fullWidth fieldSize="small" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled" [type]="input.showPassword ? 'text' : 'password'" nbInput (blur)="input.onBlur()">
            <button nbSuffix nbButton ghost (click)="input.toggleShowPassword()">
              <nb-icon [icon]="input.showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                       pack="eva"
                       [attr.aria-label]="input.showPassword ? 'hide password' : 'show password'">
              </nb-icon>
            </button>
          </nb-form-field>
        
    </div>
</ng-template>

<ng-template #urlInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="link-2-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'"  nbInput fullWidth="true" fieldSize="small" type="url" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled" (blur)="input.onBlur()">
        </nb-form-field>
    </div>
</ng-template>

<ng-template #telInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="phone-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'"  nbInput fullWidth="true" fieldSize="small" type="tel" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled" (blur)="input.onBlur()">
        </nb-form-field>
    </div>
</ng-template>

<ng-template #dateInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="calendar-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'"  nbInput fullWidth="true" fieldSize="small" type="date" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled" (blur)="input.onBlur()">
        </nb-form-field>
        
    </div>
</ng-template>

<ng-template #emailInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="email-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'"  nbInput fullWidth="true" fieldSize="small" type="email" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled" (blur)="input.onBlur()">
        </nb-form-field>
        
    </div>
</ng-template>

<ng-template #dateTimeInputTemplate let-input="input">
    <div style="display: flex;flex-direction: column;">
        <label>{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <nb-icon nbPrefix icon="calendar-outline"></nb-icon>
            <input [status]="input.errors.length?'danger':'basic'"  nbInput fullWidth="true" fieldSize="small" type="datetime-local" [(ngModel)]="input.value" [required]="input.required" [disabled]="input.disabled" (blur)="input.onBlur()">
        </nb-form-field>
        
    </div>
</ng-template>

<ng-template #requiredTemplate>
    <span style="color: red;">*</span><span style="color:rgba(0, 0, 0, 0.493);font-weight: normal;">  (required)</span>
</ng-template>


<ng-template #codeInputTemplate let-input="input">
    <div class="code-input">
        <label>{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <div class="code-input-buttons" >
            <nb-icon *ngIf="input.formatCode" icon="code-outline" (click)="input.formatCode()" title="Format code"></nb-icon>
            <nb-icon icon="browser-outline" (click)="openCodeEditor(input)" title="Expand editor"></nb-icon>
          </div>
        <ngx-codemirror 
          (focusChange) = "input.onBlur()"
          style="max-height: 10rem;"
          [(ngModel)]="input.value"
          [options]="{
            lineNumbers: true,
            theme: 'default',
            mode: input.formatType==='json'?'javascript':input.formatType
          }">
        </ngx-codemirror>
    </div>
    

  </ng-template>

<ng-template #arrayInputTemplate let-input="input">
    <!-- <div class="code-input">
        <label>{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <div class="code-input-buttons" >
            <nb-icon *ngIf="input.formatCode" icon="code-outline" (click)="input.formatCode()" title="Format code"></nb-icon>
            <nb-icon icon="browser-outline" (click)="openCodeEditor(input)" title="Expand editor"></nb-icon>
          </div>
        <ngx-codemirror 
          (focusChange) = "input.onBlur()"
          style="max-height: 10rem;"
          [(ngModel)]="input.value"
          [options]="{
            lineNumbers: true,
            theme: 'default',
            mode: 'text'
          }">
        </ngx-codemirror>
    </div> -->

    <div>
        <label>{{input.inputConfig.label}}<ng-container *ngIf="!input.required; else requiredTemplate"></ng-container></label>
        <nb-form-field>
            <!-- <mat-icon style="margin:auto auto;">text_fields</mat-icon> -->
            <!-- <nb-icon nbPrefix icon="text-outline"></nb-icon> -->
            <textarea [status]="input.errors.length?'danger':'basic'"  nbInput fullWidth [(ngModel)]="input.value" (blur)="input.onBlur()"></textarea>
        </nb-form-field>
    </div>
</ng-template>


  <ng-template #editor let-data>
      <div class="code-input">
        
        <div class="code-input-buttons">
          <nb-icon *ngIf="data.formatCode" icon="code-outline" title="Format code" (click)="data.formatCode()"></nb-icon>
        </div>    
        <ngx-codemirror
            style="width:auto" 
          [(ngModel)]="data.value"
          [options]="{
            id:data.formatType,
            autoRefresh:true,
            lineNumbers: true,
          theme: 'default',
          mode: data.formatType==='json'?'javascript':data.formatType
        }">
        </ngx-codemirror>
      </div>
  </ng-template>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { TemplateService } from 'src/app/services/core/template.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-template-configurations',
  templateUrl: './template-configurations.component.html',
  styleUrls: ['./template-configurations.component.css']
})
export class TemplateConfigurationsComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private templateService: TemplateService, private location: Location) { }

  template$ = this.activatedRoute.params.pipe(
    switchMap(({templateId: id}) => this.templateService.getTemplateById(parseInt(id))),
    map(response => response.payload)
    
  )

  ngOnInit(): void {
  }

  goBack(event: any){
    console.log(event)
    this.location.back()
  }

}

import {Component, OnInit} from '@angular/core';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {group} from '@angular/animations';
import {ConfigureApiService} from 'src/app/services/api/configure-api.service';
import {DataConnectionComponent} from '../data-connection/data-connection.component';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-api-configuration',
  templateUrl: './api-configuration.component.html',
  styleUrls: ['./api-configuration.component.scss']
})
export class ApiConfigurationComponent implements OnInit {

  dataTypes = {
    list: "list",
    object: "object",
    string: "string",
    integer: "integer",
    decimal: "decimal",
    boolean: "boolean",
    date_time_timestamp: "dt_timestamp",
    date_time_string: "dt_string"
  };

  // for header and variables
  dataType2 = {
    string: "string",
    integer: "integer",
    decimal: "decimal",
    boolean: "boolean",
    date_time_timestamp: "dt_timestamp",
    date_time_string: "dt_string"
  };

  connections: any = [];
  apiForm: FormGroup;

  constructor(
    private dialogRef: NbDialogRef<ApiConfigurationComponent>,
    private fb: FormBuilder,
    private api_service: ConfigureApiService,
    private dialogService: NbDialogService
  ) {
    
    this.apiForm = this.fb.group({
      "client_id": this.fb.control(''),
      "name": this.fb.control(''),
      "desc": this.fb.control(''),
      "url": this.fb.control(''),
      "connection_identifier": this.fb.control(''),
      "connection_id": this.fb.control(''),
      "is_secure": this.fb.control(false),
      "method": this.fb.control(''),
      "variables": this.fb.array([]),
      "headers": this.fb.array([]),
      "schema": this.fb.array([])
    });
    //console.log(this.apiForm);
  }

  ngOnInit(): void {
    this.getConnection();
  }

  /*
    To add delete get schema keys
  */

  schemaKeys(): FormArray {
    return this.apiForm.get("schema") as FormArray;
  }

  newSchema(): FormGroup {
    return this.fb.group({
      "name": this.fb.control(''),
      "data_type": this.fb.control(''),
      "default_value": this.fb.control(''),
      "is_mandatory": this.fb.control(false),
      "is_null_allowed": this.fb.control(false),
      "is_blank_allowed": this.fb.control(false),
      "skip_field": this.fb.control(false)
    });
  }

  addSchema() {
    this.schemaKeys().push(this.newSchema());
  }

  deleteSchema(index: number) {
    this.schemaKeys().removeAt(index);
  }

  // To add subtype when data_type is of type "list"
  addSubtype(schema_index: number) {
    let schema = this.schemaKeys().at(schema_index) as FormGroup;
    schema.addControl("sub_type", new FormControl(''));
  }

  addField(schema_index: number) {
    let schema = this.schemaKeys().at(schema_index) as FormGroup;
    schema.addControl("fields", this.fb.array([]));
  }

  addAttribute(schema_index: number) {
    let schema = this.schemaKeys().at(schema_index) as FormGroup;
    let field = schema.controls["fields"] as FormArray;
    field.push(this.newSchema());
  }

  checkSubType(schema_index: number): boolean {
    let schema = this.schemaKeys().at(schema_index) as FormGroup;
    if (schema.controls["data_type"].value == "list") {
      this.addSubtype(schema_index);
      return true;
    }

    return false;
  }

  checkField(schema_index: number): boolean {
    let schema = this.schemaKeys().at(schema_index) as FormGroup;
    if (schema.controls["data_type"].value == "list") {
      if (schema.controls["sub_type"].value == "object") {
        this.addField(schema_index);
        return true;
      }
    }
    if (schema.controls["data_type"].value == "object") {
      this.addField(schema_index);
      return true;
    }

    if (schema.contains("fields")) {
      schema.removeControl("fields");
    }

    return false;
  }

  fields(schema_index: number): FormArray {
    let schema = this.apiForm.get("schema") as FormArray;
    //console.log(schema);
    return schema.at(schema_index).get("fields") as FormArray;
  }

  check(schema: AbstractControl) {
    let group = schema as FormGroup;
    if (group.controls["data_type"].value == "list") {
      this.add_sub_type(group);
      return true;
    }

    if (group.contains("sub_type")) {
      group.removeControl("sub_type");
    }
    return false;
  }

  add_sub_type(group: FormGroup) {
    group.addControl("sub_type", this.fb.control(''));
  }

  add_field(group: FormGroup) {

    let field_array = group.get("fields") as FormArray;
    //console.log(field_array);
    field_array.push(this.newSchema());
  }

  add_fields(group: FormGroup) {
    group.addControl("fields", this.fb.array([]));
  }

  check_field(group: FormGroup) {
    // console.log(group.controls["data_type"]);
    if (group.controls["data_type"].value == "list") {
      if (group.controls["sub_type"].value == "object") {
        this.add_fields(group);
        return true;
      }
    }
    if (group.controls["data_type"].value == "object") {
      this.add_fields(group);
      return true;
    }

    if (group.contains("fields")) {
      group.removeControl("fields");
    }

    return false;
  }

  get_fields(group: FormGroup): FormArray {
    return group.get("fields") as FormArray;
  }

  remove_field(group: FormGroup, field_index: number) {
    //console.log("remove", group, field_index);
    let fields_array = group.controls["fields"] as FormArray
    fields_array.removeAt(field_index);
  }

  /*
  headers
  */
  addHeaders() {
    this.apiForm.addControl("headers", this.fb.array([]));
  }

  add_header() {
    let header = this.apiForm.controls["headers"] as FormArray;
    header.push(this.newSchema());
  }

  addVariables() {
    this.apiForm.addControl("variables", this.fb.array([]));
  }

  add_variable() {
    let header = this.apiForm.controls["variables"] as FormArray;
    header.push(this.newSchema());
  }

  get_array(array_name: string): FormArray {
    return this.apiForm.controls[array_name] as FormArray;
  }

  remove(array: any, i: number) {
    array.removeAt(i);
  }


  /*
    For connection values
  */

  getConnection() {
    this.api_service.getConnection().subscribe((response: any) => {
      this.connections = response.payload;
      //console.log("connections", this.connections);
    });
  }

  setConnectionIdentifier(event: any) {
    let connection = this.connections.filter((connection: any) => (connection.id == event.target.value))[0];
    this.apiForm.get("connection_identifier")?.setValue(connection.identifier);
    this.apiForm.get("connection_id")?.setValue(connection.id);
  }

  setConnectionId(event: any) {
    let connection = this.connections.filter((connection: any) => (connection.identifier == event.target.value))[0];
    //console.log(connection);
    this.apiForm.get("connection_id")?.setValue(connection.id);
    this.apiForm.get("connection_identifier")?.setValue(connection.identifier);
  }

  openConnection() {
    this.dialogService.open(DataConnectionComponent).onClose.subscribe((connection: any) => {
      //console.log(connection);
      if (connection.status) {
        this.connections.push({
          client_id: connection.client_id,
          id: connection.connectionId,
          identifier: connection.identifier,
          ip_address: connection.ip_address,
          password: connection.password,
          port: connection.port,
          username: connection.username,
        });
        this.apiForm.get("connection_identifier")?.setValue(connection.identifier);
        this.apiForm.get("connection_id")?.setValue(connection.connectionId);
      }
    });
  }

  /*
  create new api
  */
  addAPI() {
    //console.log(this.apiForm.value);
    this.api_service.createNewApi(this.apiForm.value).subscribe(res => {
      console.log(res);
      this.close()
    });
  }
  

  close() {
    this.dialogRef.close();
  }


}

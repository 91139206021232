import { HttpClient, HttpHeaders } from '@angular/common/http';
import { chainedInstruction } from '@angular/compiler/src/render3/view/util';
import { Component, OnInit, Input as Ip, ViewChild, TemplateRef,ViewChildren,QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { group } from 'console';
import { data } from 'jquery';
import { Subject } from 'rxjs';
import { filter, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormService } from 'src/app/services/api/form.service';
import { Nodev2Service } from 'src/app/services/api/nodev2.service';
import { formatSql } from 'src/app/pages/dashboard/tasks/helper-functions';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { Connectorv2Service } from 'src/app/services/api/v2/connectorv2.service';


let formConfig2:Array<InputType>=[]
let xyz:Array<InputType>=[];

let inputFields:Array<InputType> = [
  {
    "name": "python",
    "label": "Python",
    "description": "Python code",
    "required": true,
    "dataType": "string",
    "formatType": "python",
    "options": [],
    "default": null,
    "value": `def fun: if x==5: print('yes')`
},
  {
    "name": "json",
    "label": "JSON",
    "description": "JSON data",
    "required": true,
    "dataType": "string",
    "formatType": "json",
    "options": [],
    "default": null,
    "value": `{"name":"vikas","age":23,"others":{"address":"new janakpuri,Adityapuram,Gwalior"}}`
},
  {
    "name": "query",
    "label": "Query",
    "description": "SQL query",
    "required": true,
    "dataType": "string",
    "formatType": "sql",
    "options": [],
    "default": null,
    "value": "select * from tb where id=5;"
},
{
    "name": "port",
    "label": "Port Number",
    "description": "Port Number",
    "required": true,
    "dataType": "integer",
    "formatType": "integer",
    "options": [],
    "default": null,
    "value": 3306
},
{
    "name": "database",
    "label": "Database Name",
    "description": "Database Name",
    "required": true,
    "dataType": "string",
    "formatType": "text",
    "options": [],
    "default": null,
    "value": "sm_bridge"
},
{
    "name": "username",
    "label": "Username",
    "description": "Username",
    "required": true,
    "dataType": "string",
    "formatType": "text",
    "options": [],
    "default": null,
    "value": "root"
},
{
    "name": "password",
    "label": "Password",
    "description": "Password",
    "required": true,
    "dataType": "string",
    "formatType": "password",
    "options": [],
    "default": null,
    "value": "root@1234"
}
]
@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})

export class DynamicFormComponent implements OnInit {

  @Ip() form = new Form()
  
  constructor(private dialog:MatDialog) {
  }
  @ViewChildren(CodemirrorComponent) codemirrorComponents!: QueryList<CodemirrorComponent>;
  @ViewChild('editor') editor!: TemplateRef<HTMLDivElement>
  openCodeEditor(input: Input) {
    let isLoading:boolean = true;
    let dialogRef = this.dialog.open(this.editor, {
      data: input,
      panelClass: 'custom-dialog-container',
      maxHeight: '50vh', 
      minWidth: '60vw',
      maxWidth: '60vw'
    }).afterClosed().subscribe(()=>{
      input.onBlur()
    })

    setTimeout(() => {
      let editor = this.codemirrorComponents.find((el: any) => el._options.id === input.formatType)
      if(editor) {
        editor.codeMirror?.refresh()
        //editor.codeMirror?.setSize("100%", "100%")
        isLoading = false;
      }
    }, 100)
  }


  ngOnInit(): void {
    // this.formUpdateHandler.subscribe()
    //this.form.build(inputFields)
  }


  getOutput() {
    const result = this.form.getValue()
    console.log(result)
  }

  reset() {
    this.form.build(formConfig2)
  }



}

abstract class FormRemap {
  constructor(){}
  abstract loadForm(form:Form,formData:any): unknown
}

export class ConnectionFormRemap extends FormRemap {
  constructor(private connectorId:string,private connectorService:Connectorv2Service){
    super();
  }
  async loadForm(form:Form,formData:any){
    xyz = await this.connectorService.connectionRemap(this.connectorId,formData).toPromise();
    // will remove it later
      xyz = xyz.map(obj=>{
        if(obj.dataType==='timestamp' && obj.value!=null)
        {
          let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
          let tsd = new Date(obj.value-tzoffset)
          let dateString=tsd.toISOString().slice(0,16);

          if(obj.formatType==='date'){
            return{
              ...obj,value:dateString.slice(0,10)
            }
          }

          return{
            ...obj,value:dateString
          }
        }
        return obj;
      })
    ////
    form.build(xyz)
  }
}

export class ServiceFormRemap extends FormRemap {
  constructor(private connectorId:string,private connectionId:string,private serviceName:string,private nodeService:Nodev2Service){
    super();
  }
  async loadForm(form:Form,formData:any){
    xyz = await this.nodeService.serviceFormRemap(this.connectorId,this.connectionId,this.serviceName,formData).toPromise();
    // will remove it later
      xyz = xyz.map(obj=>{
        if(obj.dataType==='timestamp' && obj.value!=null)
        {
          let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
          let tsd = new Date(obj.value-tzoffset)
          let dateString=tsd.toISOString().slice(0,16);

          if(obj.formatType==='date'){
            return{
              ...obj,value:dateString.slice(0,10)
            }
          }

          return{
            ...obj,value:dateString
          }
        }
        return obj;
      })
    ////
    form.build(xyz)
  }
}

class Input {
  public childrens: Array<Input> = []
  constructor(protected form: Form, public inputConfig: InputType) {
    this.isLoading = false
    this.name = inputConfig.name
    this.value = (inputConfig as any).value || inputConfig.default
    this.disabled = inputConfig.disabled || false
    this.visible = inputConfig.visible || true
    this.errors = inputConfig.errors || []
    this.options = inputConfig.options || []
    this.dataType = inputConfig.dataType
    this.formatType = inputConfig.formatType
    this.required = inputConfig.required || false
    this.description= inputConfig.description || ''
  }
  description:string
  //will move to dropdown input later
  isOpen:boolean=false;
  toggleOpen(){
    this.isOpen=!this.isOpen;
    if(!this.isOpen){
      this.onBlur();
    }
  }
  //////
  showPassword:boolean = false;
  required:boolean
  dataType: 'string'|'integer'|'float'|'date_time'|'date'|'timestamp' | 'boolean'
  formatType: 'text'|'text_area'| 'password'| 'email' |'phone'| 'integer' |'float'| 'url' |'select' |'multi_select' |'checkbox' |'date' |'date_time'|'sql' |'python' | 'json'
  options:Array<any>
  errors: Array<string> // any error: transform error, onChange api error, other promise error
  isLoading: boolean // input will be set to isLoading when: the data is loading or if the onChange returns a promise
  //key: string
  //keyName: string | null // if keyName is null the input will not be the part of form output
  name:string
  protected _value: string| number | boolean|Array<any> | undefined

  get value() {
    // if(this.dataType==='timestamp'){
    //   //convert this._value to YYYY:MM:DDTHH:MM format
    //   let dateVar:string='';
    //   if(typeof this._value=== 'number'){
    //     let dd= new Date(this._value);
    //     dateVar = dd.toISOString().slice(0,16);
    //   }
    //   if(this.formatType==='date'){
    //     return(dateVar.slice(0,10));
    //   }
    //   return(dateVar);
    // }
    if(this.formatType==='multi_select' && this._value===null){
      this._value=[]
    }
    return this._value
  }

  set value(value) {
    this._value = value;
    // //const oldValue = this._value
    // else{
    //   this._value = value

    // }
    //this._value = this.getTransform({ oldValue, newValue: value })
    //this.form.updateAll(this)
  }

  protected _disabled: boolean = false
  get disabled() {
    return !!this._disabled
  }

  set disabled(value: boolean) {
    this._disabled = value
  }


  protected _visible: boolean = true
  get visible() {
    return !!this._visible
  }

  set visible(value: boolean) {
    this._visible = value
  }

  public getValue() {
    //return customEval(this.inputConfig.getValue || this.defaultValueExpr, this)
    // if(this.dataType==='date'){
    //   //date conversion
    //   let dd = new Date(this.value)
    //   return this.value
    // }
      if(this.dataType==='timestamp')
      {
        let ts:number=1;
        if(this.value){
          if(typeof this.value === 'string'){
            ts = Date.parse(this.value);
          }
          return ts;
        }
        
      }
    if(this.value===''){
      return null;
    }
    return this.value
  }


  public onLoad() {
    for(let child of this.childrens){
      child.onLoad()
    }
    console.log(`${this.name} loaded.`)
  }

  public onBlur(){
    setTimeout(() => {
      this.form.eventSource.next({type:'blur',target:this})
    }, 10)
  }

  keyupEvent(){
    if(typeof this._value==='number')
    {
      this._value = Math.trunc(this._value)
    }
  }

  // getInputType() {
  //   if (this.showPassword) {
  //     return 'text';
  //   }
  //   return 'password';
  // }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

}

class SqlInput extends Input{
  formatCode(){
    let value = formatSql(this.value as string)
    this.value = value
  }
}

class JsonInput extends Input{
  formatCode(){
    let value = JSON.parse(this.value as string)
    this.value = JSON.stringify(value,null,4)
  }
  public getValue() {
    return JSON.parse(this.value as string || "{}")
  }
}
class ArrayNewlineInput extends Input{


  public onLoad(): void {
    super.onLoad()
    if(Array.isArray(this._value))this.value = this._value.join('\n')
  }

  formatCode(){
  }
  public getValue() {
    const value = this.value as string || ""
    return value.split(/\r?\n/)
  }
}

class PythonInput extends Input{

}
class ObjectInput extends Input {
  public getValue() {
    const data = super.getValue()
    return data
  }
}

class BooleanInput extends Input {
  public onLoad(): void {
    super.onLoad()
    this._value = (this._value || '').toString().toLowerCase() === 'true'
  }
}

class DropdownInput extends Input {
  options: Array<any> = []
  constructor(form: Form, inputConfig: InputType) {
    super(form, inputConfig)
    this.options = inputConfig.options || []
  }


  // private async loadOptions() {
  //   // this.options = []
  //   this.isLoading = true
  //   if (typeof this.inputConfig.options === 'object' && Array.isArray(this.inputConfig.options)) {
  //     this.options = this.inputConfig.options
  //     return
  //   }

  //   if (this.inputConfig.options) {
  //     const expressionResult = customEval(this.inputConfig.options, this)
  //     if (expressionResult && Array.isArray(expressionResult)) {
  //       this.options = expressionResult
  //       return
  //     }

  //     if (expressionResult && expressionResult.then) {
  //       const result = await expressionResult

  //       if (result && Array.isArray(result)) {
  //         this.options = result
  //       }

  //       else if (result && Array.isArray(result.payload)) {
  //         this.options = result.payload
  //       }
  //     }
  //   }
  //   this._value = this._value
  //   this.isLoading = false
  // }

  // public onLoad(): void {
  //   super.onLoad()
  //   this.loadOptions()
  // }

  // public onFormUpdated() {
  //   this.loadOptions()
  // }

  // public getValue() {
  //   if (this.inputConfig.getValue) return super.getValue()
  //   //return this.options.find(x => x.key === this._value)
  //   return this.value
  // }
}

class DatetimeInput extends Input {
  get value(){
    console.log('from datetimeInput GET',this._value)
    return this._value;
  }

  set value(value:any){
    console.log('from datetimeInput SET',this._value)
    this._value="2023-01-20T14:04"
  }
}


class InputFactory {
  private memo: Map<string, typeof Input> = new Map()

  private getProxy(input:Input){
    return new Proxy(input,{
      get(target, prop: string, receiver) {
          if(prop in target)
            return (target as any)[prop];
        return (target as any).childrens.find((x: any) => x.key === prop)
      },
    })
  }

  public register(inputType: string, inputConstructor: any) {
    this.memo.set(inputType, inputConstructor)
  }

  public getInput(form: Form, config: InputType): Input | undefined {
    const inputConstructor = this.memo.get(config.formatType);
    if (inputConstructor){
      const instance = new inputConstructor(form, config)
      return this.getProxy(instance)
    }
    console.warn(`Input type ${config.formatType} not registered!`)


    return undefined
  }
}


export class Form {
  private inputFactory: InputFactory
  constructor(_inputFactory?: InputFactory) {
    this.inputFactory = _inputFactory!;
    this.inputFactory = this.getInputFactory()
  }

  public eventSource = new Subject<{type:string,target:Input}>()
  public onChange$ = this.eventSource.asObservable().pipe(filter(x=>x.type==='change'))
  public onBlur$ = this.eventSource.asObservable().pipe(filter(x=>x.type==='blur'))

  private isLoaded: boolean = false

  private inputs: { [key: string]: Input } = {}
  public inputsInoder: Array<Input> = []
  public build(inputs: Array<InputType>) {
    this.isLoaded = false
    const _inputs: Array<Input> = []
    this.inputs = {}
    this.inputsInoder = []
    inputs.forEach(
      input => {
        const inputInstance = this.inputFactory.getInput(this, input)
        if (inputInstance) {
          this.setInputField(inputInstance);
          (input.children||[]).forEach(child => inputInstance.childrens.push(this.inputFactory.getInput(this, child)!));

          _inputs.push(inputInstance);
        }
      }
    )

    _inputs.forEach(
      input => this.inputsInoder.push(input)
    )

    _inputs.forEach(
      input => input.onLoad()
    )
    this.isLoaded = true;
  }

  public setInputField(input: Input) {
    this.inputs[input.name] = input
  }

  public updateAll(input: Input) {

    const Q: Array<Input> = []
    for (const inputKey in this.inputs) {
      const input = this.inputs[inputKey]
      //input.onFormUpdated()
      // const newValue = input.getValue()
      // const oldValue = input.value
      // if(newValue !== oldValue) {
      //     console.log('Updated', oldValue, '->', newValue)
      //     input.setValue(newValue)
      //     Q.push(input)
      // }
    }
    if (this.isLoaded) {
      this.eventSource.next({type:'change',target:input})
    }
  }

  getValue() {
    // const res: any = {}
    // for (const inputKey in this.inputs) {
    //   const input = this.inputs[inputKey]
    //   if (input.keyName === null) continue;
    //   res[input.keyName || input.key] = input.getValue()
    // }
    // return res
    const res = this.inputsInoder.map((input) => ({
      ...input.inputConfig,
      value: input.getValue(),
      //children: input.childrens.map(ch => ch.getValue()),
      children: input.childrens.map(ch => ({...ch.inputConfig,value:ch.getValue()}))
    }))

    return res
  }

  // to set individual input externally
  updateInput(inputKey: string, value: any) {
    const input = this.inputs[inputKey]
    if (input) {
      input.value = value
    }
  }

  private getInputFactory() {
    if(this.inputFactory) return this.inputFactory;
    const inputFactory = new InputFactory()
    inputFactory.register('group', Input)
    inputFactory.register('text', Input)
    inputFactory.register('text_area', Input)
    inputFactory.register('text_area', Input)
    inputFactory.register('password', Input)
    inputFactory.register('email', Input)
    inputFactory.register('phone', Input)
    inputFactory.register('integer', Input)
    inputFactory.register('float', Input)
    inputFactory.register('url', Input)
    inputFactory.register('select', Input)
    inputFactory.register('multi_select', Input)
    inputFactory.register('checkbox', Input)
    inputFactory.register('date', Input)
    inputFactory.register('date_time', Input)
    inputFactory.register('number',Input)
    inputFactory.register('sql',SqlInput)
    inputFactory.register('json',JsonInput)
    inputFactory.register('python',PythonInput)
    inputFactory.register('multi', ArrayNewlineInput)

    return inputFactory
  }
}

export class FormUpdateHandler {
  protected sub: any
  // async loadForm(formData:any){
  //   xyz = await this.nodeService.connectionRemap(this.pipelineId,this.nodeInstanceId,formData).toPromise();
  //   // will remove it later
  //     xyz = xyz.map(obj=>{
  //       if(obj.dataType==='timestamp' && obj.value!=null)
  //       {
  //         let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  //         let tsd = new Date(obj.value-tzoffset)
  //         let dateString=tsd.toISOString().slice(0,16);

  //         if(obj.formatType==='date'){
  //           return{
  //             ...obj,value:dateString.slice(0,10)
  //           }
  //         }

  //         return{
  //           ...obj,value:dateString
  //         }
  //       }
  //       return obj;
  //     })
  //   ////
  //   this.form.build(xyz)
  // }
  constructor(protected form: Form,private formRemap:FormRemap) {
  }

  public subscribe() {
    this.sub = this.form.onChange$.pipe(debounceTime(500)).subscribe(this.onChangeHandler.bind(this))
  }

  protected onChangeHandler({target}:{target:Input}) {
    const data = this.form.getValue()
    const newForm = data
    let obj:any = {
      inputFields:{}
    }
    newForm.forEach(d=>{
      obj.inputFields[d.name]={"value":d.value}
    })

    //call api
    //this.loadForm(obj);
    //console.log(this.form)
    //this.formRemap(this.form,obj)
    this.formRemap.loadForm(this.form,obj)
  }
}

export class FormUpdateHandlerOnBlur extends FormUpdateHandler{
  public subscribe() {
    this.sub = this.form.onBlur$.pipe(distinctUntilChanged((x,y)=>{return(x.target.value===y.target.value)})).subscribe(this.onChangeHandler.bind(this))
  }
}

export type InputType = {
  children?:Array<InputType>
  dataType: 'string'|'integer'|'float'|'date_time'|'date'|'timestamp' | 'boolean'
  formatType: 'text'|'text_area'| 'password'| 'email' |'phone'| 'integer' |'float'| 'url' |'select' |'multi_select' |'checkbox' |'date' |'date_time' | 'sql' | 'python' | 'json'
  name:string
  label:string
  description?:string
  required?:boolean
  options?:Array<any>
  default?:any
  value?:any
  disabled?:boolean
  visible?:boolean
  errors?:Array<string>
}





import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Groupv2Service } from 'src/app/services/api/v2/groupv2.service';
import { Group } from 'src/app/services/api/v2/interfacesv2';
import { DirectoriesStoreService } from 'src/app/services/store/directories-store.service';
import { filter, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from 'src/app/services/api/auth.service';
import { Accountv2Service } from 'src/app/services/api/v2/accountv2.service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements OnInit {
  hasAdminRole = false
  @ViewChild('createGroupModal') createGroupModal!: TemplateRef<HTMLDivElement>
  searchQuery:string = ''
  searchQueryChange: Subject<string> = new Subject<string>();
  subscriptions: Array<Subscription> = [];

  recentGroups:Array<Group> = []
  filteredRecentGroups:Array<Group> = []

  allGroups:Array<Group> = []
  
  allGroupCount = 0
  recentGroupCount = 0
  pageIndex=0
  itemsPerPage=10
  pageSizeOptions=[10,20,25,30]

  imageChangedEvent:any = ''
  imageUrl:any=''
  croppedImage = ''

  constructor(private groupService:Groupv2Service,private router:Router,private directoriesStoreService:DirectoriesStoreService,private dialog:MatDialog,private authService:AuthService,private accountsService:Accountv2Service) { }

  ngOnInit() {
    this.authService.accountId$.subscribe(accountId=>{
      if(accountId){
        this.accountsService.getUserInfoV2().subscribe((_data:any)=>{
          let role = _data.payload.accounts[accountId]?.role?.role || ""
          this.hasAdminRole = role === "rrole:admin"
        })
      }
      
    })
    this.groupService.getGroups({itemsPerPage:10}).subscribe((data:any)=>{
      this.recentGroups = data.payload.data
      this.filteredRecentGroups = this.recentGroups
      this.recentGroupCount = this.recentGroups.length
    })
    this.loadAllGroups(this.itemsPerPage,this.pageIndex);
    let subscription = this.searchQueryChange
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(filterQuery => {
        this.loadAllGroups(this.itemsPerPage,this.pageIndex,filterQuery);
        this.loadRecentGroups(filterQuery);
      });

    this.subscriptions.push(subscription)
  }

  loadAllGroups(itemsPerPage:number,pageIndex:number,search:string=''){
    this.groupService.getGroups({itemsPerPage,pageNum:pageIndex+1,search_label:search}).subscribe((data:any)=>{
      this.allGroupCount = data.payload.totalCount
      this.pageIndex = data.payload.currentPage-1
      this.allGroups = data.payload.data
    })
  }
  loadRecentGroups(search:string=''){
    this.filteredRecentGroups = this.recentGroups.filter((group) => {
      return group.label.toLowerCase().includes(search.toLowerCase())
    })
    this.recentGroupCount = this.filteredRecentGroups.length;
  }
  getGroupData(group:any){
    return JSON.stringify({
      id:group.id,
      name:group.label,
      badgeCount:group.directories.length,
      description:group.description,
      tags:['Simplify', 'VMS', 'Bulk Upload', 'Utility', 'Self Management'],
      updatedBy:`Updated ${this.convertTime(group.updatedTime)} ago`,
    })
  }
  convertTime(timestamp: any) {
    timestamp = timestamp * 1000;
    const date: any = new Date(timestamp);
    const currentTime: any = new Date();
    const timeDifference = currentTime - date;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursDifference = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    const minutesDifference = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    const secondsDifference = Math.floor((timeDifference % (1000 * 60)) / 1000);

    let updatedTime = '';
    if (daysDifference > 0) {
      updatedTime += `${daysDifference} day${daysDifference > 1 ? 's' : ''} `;
    }

    if (hoursDifference > 0) {

      updatedTime += `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} `;

    }

    // if (minutesDifference > 0) {

    //   updatedTime += `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''} `;

    // }

    // if (secondsDifference > 0) {

    //   updatedTime += `${secondsDifference} second${secondsDifference > 1 ? 's' : ''} `;

    // }

    if (!updatedTime) {

      updatedTime = 'less than a second';

    }
    return updatedTime;
  }
  onOpenGroup(groupId:number){
    this.router.navigate(['', { outlets: { view: ["groups",groupId],action:null } }])
  }

  onGroupEdit(id:number){
    //switch to group page and open edit sidebar
    this.router.navigate(['', { outlets: { view: ["groups",id],action:null }}],{ queryParams: { edit: true } })
  }

  //create group modal
  dialogRef:any
  openCreateGroupPopup(){
    this.dialogRef = this.dialog.open(this.createGroupModal, {
      panelClass: 'custom-dialog-container',
      height:'calc(100vh - 100px)',
      width:'500px',
      disableClose:true
    })
  }
  closeCreateGroupPopup(){
    this.dialogRef.close()
    this.imageChangedEvent = ''
    this.imageUrl = ''
    this.croppedImage = ''
  }
  createGroup(newGroupData:{name:string,label:string,description:string,icon:string,tags:Array<string>,attributes:Array<{key:string,value:string}>}){
    this.groupService.createGroup(newGroupData).subscribe((data)=>{
      this.groupService.getAllDirectories().subscribe((data:any)=>{
        this.directoriesStoreService.directoriesData.next(data.payload.data)
      });
      this.closeCreateGroupPopup();
    })
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.imageUrl='';
}
  customHeaderHandler(event:any){
    if(event.detail.type === "iconDetails" && event.detail.data){
      this.fileChangeEvent(event.detail.data);
    }
    else if(event.detail.type === "iconUrl" && event.detail.data){
      this.imageUrl=event.detail.data;
    }
    else if(event.detail.type === "advanceDetails" && event.detail.data){
       console.log(event.detail.data,this.croppedImage)
      this.createGroup({...event.detail.data,icon:this.croppedImage});
    }
  }

  onPaginationEvent(e:any) {
    this.loadAllGroups(e.detail.itemsPerPage,e.detail.index,this.searchQuery);
  }

  onGroupSearch(event: any) {
    let searchVal = event.detail.detail.value
    this.searchQuery = searchVal
    this.searchQueryChange.next(searchVal); 
    // this.searchQuery = searchVal
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}

<div [style.display]="isLoading?'flex':'none'" class="loader-wrapper">
    <span class="loader"></span>
    <span>Loading...</span>
</div>

<div class="container-fluid" style="background-color: white;">
    <simplify-details-header (smEvent)="eventLogger($event)" [name]="headerDetails.name" [folder]="headerDetails.folder"
        [status]="headerDetails.status" [showEdit]="hasAdminRole" [showMore]="hasAdminRole"></simplify-details-header>
    <simplify-tabs [activeTab]="activeTab" #detailstabs>
        <simplify-tab-header slot="header" name="tab1">Pipeline Details</simplify-tab-header>
        <simplify-tab-header slot="header" name="tab2">Version</simplify-tab-header>
        <simplify-tab-header slot="header" name="tab3">History</simplify-tab-header>
        <simplify-tab-content slot="content" name="tab1">
            <div class="row">
                <div class="col-lg-8">
                    <div class="details-content py-4">
                        <div class="details-single-content mt-7">
                            <simplify-basic-details [buildType]='pipelineDetails.buildType'
                                [trigger]='pipelineDetails.triggerName'></simplify-basic-details>
                        </div>
                        <div class="details-single-content mt-7">
                            <simplify-basic-details-description
                                [description]='pipelineDetails.description'></simplify-basic-details-description>
                        </div>
                        <!-- <div class="details-single-content mt-7">
                            <simplify-run-cycle-container
                                (customEvents)="customEventsHandler($event)"
                                [progress]="runDetails.inProgress"
                                [success]="runDetails.success"
                                [failed]="runDetails.failure">
                            </simplify-run-cycle-container>
                        </div> -->
                        <div class="details-single-content mt-7">
                            <simplify-tags-container>
                                <simplify-chip [showIcon]="false" *ngFor="let tag of tags" [name]="tag"></simplify-chip>
                            </simplify-tags-container>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="details-single-content mt-7">
                                    <simplify-details-attributes>
                                        <simplify-attribute-item *ngFor="let attribute of attributes"
                                            [keyy]="attribute.key" [value]="attribute.value"></simplify-attribute-item>
                                    </simplify-details-attributes>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="details-single-content mt-7">
                                    <simplify-details-connections>
                                        <simplify-connection-item
                                            icon="https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/2560px-Amazon_Web_Services_Logo.svg.png"
                                            name="My Test AWS Account" status="Connected"></simplify-connection-item>
                                        <simplify-connection-item
                                            icon="https://cdn-icons-png.flaticon.com/512/5968/5968534.png"
                                            name="Simplify Mail" status="Connected"></simplify-connection-item>
                                        <simplify-connection-item
                                            icon="../../../assets/images/Simplify.png"
                                            name="Simplify UAT API" status="Connected"></simplify-connection-item>
                                        <simplify-connection-item
                                            icon="../../../assets/images/trigger-img.png"
                                            name="Test Workday Tenant" status="Disconnected"></simplify-connection-item>
                                    </simplify-details-connections>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 border-start">
                    <!-- <simplify-timeline></simplify-timeline> -->
                    <simplify-run-cycle-container
                        (customEvents)="customEventsHandler($event)"
                        [progress]="runDetails.inProgress"
                        [success]="runDetails.success"
                        [failed]="runDetails.failed">
                    </simplify-run-cycle-container>
                </div>
            </div>
        </simplify-tab-content>

        <simplify-tab-content slot="content" name="tab2">
            <simplify-version-table [showedit]="hasAdminRole" [versionDetails]="pipelineVersionDetails">
                <simplify-pagination slot="pagination" 
                *ngIf="versionTotalCount>10"
                [totalCount]="versionTotalCount"
                [pageIndex]="versionPageIndex"
                [itemsPerPage]="versionItemsPerPage" 
                [pageSizeOptions]="versionPageSizeOptions" 
                (paginate)="onPaginationEvent($event)" class="position-sticky bottom-0 bg-white"></simplify-pagination>
            </simplify-version-table>
           
        </simplify-tab-content>
        <simplify-tab-content slot="content" name="tab3">
            <simplify-history-table (onProcessClick)="processRedirect($event)" [historyDetails]="pipelineHistoryDetails">
                <simplify-pagination slot="pagination" 
                *ngIf="historyTotalCount>10"
                [totalCount]="historyTotalCount"
                [pageIndex]="historyPageIndex"
                [itemsPerPage]="historyItemsPerPage" 
                [pageSizeOptions]="historyPageSizeOptions" 
                (paginate)="onPaginationEventHistory($event)" class="position-sticky bottom-0 bg-white"></simplify-pagination>
            </simplify-history-table>
        </simplify-tab-content>

    </simplify-tabs>
</div>

<ng-template #portalContent>
    <simplify-edit-details [initialDetails]="pipelineBasicDetails" (save)="updatePipelineDetails($event)"
        (close)="closeEditDetails()"></simplify-edit-details>
</ng-template>
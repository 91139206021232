import { TemplatePortal } from '@angular/cdk/portal';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/api/auth.service';
import { Accountv2Service } from 'src/app/services/api/v2/accountv2.service';
import { SideNavService } from 'src/app/services/core/sidenav.service';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit {
  totalCount=0
  pageIndex=0
  itemsPerPage=10
  pageSizeOptions=[10,20,25,30]
  tempIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="13" viewBox="0 0 24 13" fill="none">
  <path d="M10.1234 7.8017H9.47166V7.70143C9.47166 7.0647 9.47668 6.42798 9.46915 5.79125C9.46665 5.63583 9.43406 5.4779 9.38894 5.33C9.32877 5.14199 9.17586 5.04172 8.98534 5.01415C8.79733 4.98657 8.61183 5.00663 8.44388 5.09687C8.25336 5.19714 8.15309 5.35507 8.15309 5.57567C8.15309 6.2876 8.15309 7.00203 8.15309 7.71396V7.79668H7.50133V7.69641C7.50133 7.0647 7.50133 6.43299 7.50133 5.80379C7.50133 5.63082 7.48628 5.46036 7.41108 5.30243C7.33588 5.1445 7.21304 5.05175 7.04509 5.02167C6.83201 4.98407 6.62896 5.01164 6.44095 5.12194C6.26548 5.22472 6.18025 5.38014 6.18025 5.58319C6.18025 6.29261 6.18025 7.00454 6.18025 7.71396C6.18025 7.74153 6.17774 7.76911 6.17523 7.8017H5.54102V4.53034H6.18025V5.06428C6.18777 5.0693 6.19529 5.07431 6.20281 5.07682C6.38079 4.65818 6.67408 4.42255 7.14285 4.45012C7.59157 4.47519 7.90993 4.68325 8.07287 5.11693C8.20072 4.82614 8.38873 4.60303 8.68704 4.51279C9.07308 4.39748 9.4491 4.41252 9.76746 4.69579C9.96801 4.87377 10.0633 5.11191 10.0984 5.37262C10.1159 5.49796 10.1259 5.6258 10.1259 5.75114C10.1284 6.4004 10.1259 7.04715 10.1259 7.69641L10.1234 7.8017Z" fill="black"/>
  <path d="M11.7453 7.27485V9.05969H11.1035V4.52741H11.7453V5.05384C11.7528 5.05634 11.7603 5.05634 11.7678 5.05885C11.7803 5.03629 11.7929 5.01373 11.8029 4.98866C11.9383 4.69035 12.1739 4.51989 12.4923 4.46474C13.1064 4.35946 13.6178 4.64774 13.856 5.2243C14.0089 5.5953 14.039 5.98135 14.0139 6.37742C13.9989 6.62559 13.9537 6.86875 13.856 7.09938C13.6855 7.49796 13.4123 7.77621 12.9711 7.85392C12.6377 7.91158 12.3143 7.88651 12.0486 7.64837C11.9533 7.56063 11.8856 7.44281 11.8054 7.33752C11.7904 7.31747 11.7803 7.28989 11.7678 7.26733C11.7628 7.26984 11.7528 7.27235 11.7453 7.27485ZM13.3396 6.16685C13.3371 6.16685 13.3321 6.16685 13.3295 6.16685C13.3295 6.03399 13.3396 5.90113 13.327 5.77078C13.2995 5.45242 13.1641 5.19422 12.8533 5.06888C12.6076 4.96861 12.3544 4.98114 12.1112 5.08643C11.8581 5.19422 11.7302 5.38223 11.7377 5.668C11.7478 6.01143 11.7402 6.35486 11.7402 6.69829C11.7402 6.93644 11.8505 7.10439 12.0536 7.2197C12.1915 7.29741 12.3444 7.32248 12.4998 7.32499C12.9335 7.33 13.2393 7.08434 13.3095 6.65568C13.3371 6.49524 13.3321 6.32979 13.3396 6.16685Z" fill="black"/>
  <path d="M20.7101 8.53351C20.8681 8.53351 21.0235 8.531 21.1764 8.53602C21.2366 8.53853 21.2616 8.51596 21.2792 8.46332C21.3268 8.31291 21.3845 8.16501 21.4296 8.01461C21.4421 7.96948 21.4421 7.91433 21.4271 7.87172C21.0511 6.7938 20.6725 5.71588 20.2915 4.63796C20.279 4.60537 20.2689 4.57028 20.2539 4.52265H20.6776C20.828 4.52265 20.9257 4.59284 20.9734 4.73322C21.1689 5.30477 21.3669 5.87631 21.5574 6.45037C21.6276 6.66094 21.6778 6.87652 21.7404 7.09211C21.7505 7.1272 21.7605 7.15979 21.7931 7.19238C21.8031 7.17232 21.8131 7.14976 21.8206 7.12971C22.1039 6.28492 22.3872 5.43763 22.6679 4.59284C22.6855 4.5427 22.7055 4.52014 22.7632 4.52265C22.9412 4.52516 23.1167 4.52516 23.3072 4.52516C23.2997 4.55524 23.2946 4.5778 23.2871 4.60036C22.8259 5.9089 22.3646 7.21494 21.9009 8.52348C21.8758 8.59367 21.8382 8.66136 21.8031 8.72904C21.7028 8.92206 21.5424 9.03236 21.3268 9.04991C21.1238 9.06746 20.9182 9.06996 20.7051 9.07749L20.7101 8.53351Z" fill="black"/>
  <path d="M0.455078 7.2549C0.597965 7.12706 0.735839 7.00422 0.858671 6.89392C1.00908 7.00924 1.14194 7.13458 1.29736 7.22231C1.57311 7.37773 1.87141 7.39278 2.17223 7.28749C2.3477 7.22733 2.43544 7.08444 2.43544 6.8864C2.43544 6.71845 2.3477 6.57055 2.17975 6.51791C2.03185 6.47028 1.87141 6.46025 1.7185 6.42515C1.52046 6.38003 1.31992 6.34494 1.13191 6.27224C0.730825 6.11933 0.542816 5.79344 0.577911 5.36478C0.615513 4.92359 0.873712 4.61525 1.33245 4.50245C1.71098 4.4097 2.087 4.41972 2.45299 4.56512C2.66857 4.65035 2.83653 4.79323 2.97189 4.94364C2.84405 5.08402 2.72372 5.21438 2.60089 5.35225C2.44798 5.19432 2.28503 5.06647 2.07697 5.01383C1.8664 4.96119 1.65332 4.95116 1.44526 5.03389C1.28232 5.09906 1.20711 5.22691 1.2021 5.39486C1.19709 5.56031 1.26477 5.69568 1.42521 5.74581C1.61071 5.80347 1.80624 5.84107 1.99926 5.87867C2.21735 5.91878 2.43795 5.95388 2.63599 6.06418C2.8992 6.20957 3.03456 6.43769 3.05963 6.73349C3.10726 7.29752 2.7538 7.72618 2.15719 7.84149C1.83632 7.90416 1.51545 7.89413 1.20461 7.79135C0.89878 7.6961 0.663142 7.50307 0.455078 7.2549Z" fill="black"/>
  <path d="M19.0987 4.51967H19.7755V5.05613H19.0937V7.79605H18.4444V5.06365H17.9355V4.52469H18.4394C18.4394 4.33166 18.4344 4.15118 18.4419 3.96818C18.4469 3.85287 18.4595 3.73755 18.4895 3.62726C18.5648 3.35151 18.7904 3.16099 19.0761 3.14595C19.3068 3.13342 19.5374 3.14345 19.773 3.14345V3.66486H19.0962L19.0987 4.51967Z" fill="black"/>
  <path d="M15.4718 7.26999H15.9306V7.81396C15.7125 7.80394 15.4969 7.80895 15.2838 7.77887C15.0482 7.74628 14.9078 7.58835 14.8526 7.36024C14.8376 7.29757 14.8301 7.23239 14.8301 7.16721C14.8301 5.84363 14.8301 4.52004 14.8301 3.19646C14.8301 3.1764 14.8326 3.15886 14.8351 3.13379H15.4743L15.4718 7.26999Z" fill="black"/>
  <path d="M17.2534 4.52734V7.7962H16.6191V4.52734H17.2534Z" fill="black"/>
  <path d="M4.51062 7.79913H3.87891V4.53027H4.51062V7.79913Z" fill="black"/>
  <path d="M4.2174 4.00106C4.1422 4.00106 4.06699 3.97599 4.00683 3.92084C3.87397 3.80553 3.86144 3.60499 3.97926 3.47213C4.16476 3.25905 4.85914 2.56467 5.95962 2.51454C7.23056 2.45688 8.04276 3.30167 8.19317 3.47213C8.30848 3.60248 8.29595 3.80553 8.16559 3.92084C8.03524 4.03616 7.83219 4.02362 7.71688 3.89327C7.59906 3.76292 6.97236 3.10614 5.98719 3.15126C5.13739 3.18886 4.59843 3.72782 4.45555 3.89327C4.39288 3.96597 4.30514 4.00106 4.2174 4.00106Z" fill="url(#paint0_linear_7668_49057)"/>
  <path d="M4.21671 4.0467C4.42161 4.0467 4.58771 3.88059 4.58771 3.67569C4.58771 3.47079 4.42161 3.30469 4.21671 3.30469C4.01181 3.30469 3.8457 3.47079 3.8457 3.67569C3.8457 3.88059 4.01181 4.0467 4.21671 4.0467Z" fill="black"/>
  <path d="M7.95753 4.00886C8.06532 4.00886 8.1706 3.95371 8.23077 3.85594C9.15828 2.32179 10.8328 1.40179 12.6026 1.45444C15.2523 1.53215 16.626 3.76068 16.6837 3.85594C16.7739 4.00635 16.9694 4.05398 17.1198 3.96373C17.2703 3.87349 17.3179 3.67796 17.2276 3.52755C17.1625 3.41976 15.6133 0.905451 12.6202 0.817713C10.6222 0.760057 8.72962 1.79787 7.68429 3.52755C7.59404 3.67796 7.64167 3.87349 7.79208 3.96373C7.84472 3.99382 7.90238 4.00886 7.95753 4.00886Z" fill="url(#paint1_linear_7668_49057)"/>
  <path d="M16.9335 4.0467C17.1384 4.0467 17.3045 3.88059 17.3045 3.67569C17.3045 3.47079 17.1384 3.30469 16.9335 3.30469C16.7286 3.30469 16.5625 3.47079 16.5625 3.67569C16.5625 3.88059 16.7286 4.0467 16.9335 4.0467Z" fill="black"/>
  <path d="M7.53631 10.7071C7.70427 10.7071 7.84966 10.5642 7.84966 10.3511C7.84966 10.138 7.70427 9.99515 7.53631 9.99515C7.36836 9.99515 7.22297 10.138 7.22297 10.3511C7.22547 10.5667 7.37087 10.7071 7.53631 10.7071ZM7.08008 10.8525V9.45117H7.24803V9.97258C7.26809 9.94752 7.29566 9.92496 7.32324 9.9049C7.37337 9.87231 7.44858 9.83722 7.54885 9.83722C7.80705 9.83722 8.01762 10.0478 8.01762 10.3486C8.01762 10.6494 7.80705 10.86 7.54885 10.86C7.44858 10.86 7.37337 10.8324 7.32324 10.7998C7.29316 10.7823 7.26809 10.7622 7.24803 10.7372L7.20291 10.8475L7.08008 10.8525Z" fill="black"/>
  <path d="M8.97166 10.5844H9.14964C9.08196 10.7298 8.9491 10.8626 8.72349 10.8626C8.45526 10.8626 8.24219 10.652 8.24219 10.3512C8.24219 10.0504 8.4678 9.83984 8.71096 9.83984C8.95412 9.83984 9.1697 10.0379 9.1697 10.3387C9.1697 10.3688 9.16469 10.3888 9.16218 10.4039L9.15717 10.4289H8.42017C8.4327 10.5743 8.56306 10.7072 8.72349 10.7072C8.85635 10.7072 8.93907 10.632 8.97166 10.5844ZM8.42268 10.2861H9.00425C8.97918 10.1206 8.85886 9.99777 8.71346 9.99777C8.56807 9.99777 8.44774 10.1181 8.42268 10.2861Z" fill="black"/>
  <path d="M9.5078 11.2082V11.0753C9.60807 11.0753 9.65069 11.0302 9.68578 10.9424L9.7309 10.8321L9.33984 9.85449H9.51783L9.82115 10.6216L10.1245 9.85449H10.3025L9.86627 10.9424C9.78605 11.1455 9.70834 11.2207 9.57548 11.2207C9.55793 11.2207 9.54289 11.2182 9.52785 11.2157L9.5078 11.2082Z" fill="black"/>
  <path d="M10.9395 10.7072C11.0949 10.7072 11.2428 10.5643 11.2428 10.3512C11.2428 10.1382 11.0949 9.99526 10.9395 9.99526C10.7841 9.99526 10.6362 10.1382 10.6362 10.3512C10.6387 10.5668 10.7841 10.7072 10.9395 10.7072ZM10.9395 10.8626C10.6813 10.8626 10.4707 10.652 10.4707 10.3512C10.4707 10.0504 10.6813 9.83984 10.9395 9.83984C11.1977 9.83984 11.4082 10.0504 11.4082 10.3512C11.4107 10.652 11.2002 10.8626 10.9395 10.8626Z" fill="black"/>
  <path d="M11.7012 10.8526V9.85238H11.824L11.8691 9.96268C11.8892 9.94012 11.9142 9.92006 11.9418 9.90251C11.9945 9.86993 12.0621 9.83984 12.1599 9.83984C12.373 9.83984 12.551 10.0178 12.551 10.2735V10.8501H12.383V10.2735C12.383 10.1081 12.2677 9.99526 12.1374 9.99526C11.9794 9.99526 11.8691 10.1056 11.8691 10.2735V10.8501H11.7012V10.8526Z" fill="black"/>
  <path d="M13.3108 10.7069C13.4788 10.7069 13.6242 10.5641 13.6242 10.351C13.6242 10.1379 13.4788 9.99501 13.3108 9.99501C13.1429 9.99501 12.9975 10.1379 12.9975 10.351C12.9975 10.5666 13.1429 10.7069 13.3108 10.7069ZM13.6467 10.8523L13.6016 10.742C13.5791 10.7671 13.554 10.7872 13.5264 10.8047C13.4738 10.8373 13.4011 10.8649 13.3008 10.8649C13.0426 10.8649 12.832 10.6543 12.832 10.3535C12.832 10.0527 13.0426 9.8421 13.3008 9.8421C13.4011 9.8421 13.4763 9.87469 13.5264 9.90978C13.5565 9.92984 13.5791 9.9524 13.6016 9.97747V9.45605H13.7696V10.8548L13.6467 10.8523Z" fill="black"/>
  <path d="M14.7639 10.5281V10.0067H14.5859V9.85123H14.6636C14.7464 9.85123 14.7865 9.81113 14.7865 9.7284V9.58301H14.9319V9.85123H15.155V10.0067H14.9319V10.5281C14.9319 10.6409 14.9996 10.706 15.0998 10.706C15.1149 10.706 15.1299 10.7035 15.1399 10.701L15.155 10.6935V10.8389C15.1475 10.8439 15.1399 10.8489 15.1299 10.8514C15.1099 10.859 15.0848 10.864 15.0547 10.864C14.9018 10.8615 14.7639 10.7286 14.7639 10.5281Z" fill="black"/>
  <path d="M15.4805 10.8525V9.45117H15.6484V9.97258C15.6685 9.94752 15.6935 9.92496 15.7211 9.9049C15.7738 9.87231 15.8414 9.83722 15.9392 9.83722C16.1523 9.83722 16.3303 10.0152 16.3303 10.2709V10.8475H16.1623V10.2709C16.1623 10.1054 16.047 9.99264 15.9167 9.99264C15.7587 9.99264 15.6484 10.1029 15.6484 10.2709V10.8475H15.4805V10.8525Z" fill="black"/>
  <path d="M17.3349 10.5844H17.5129C17.4452 10.7298 17.3124 10.8626 17.0868 10.8626C16.8185 10.8626 16.6055 10.652 16.6055 10.3512C16.6055 10.0504 16.8311 9.83984 17.0742 9.83984C17.3174 9.83984 17.533 10.0379 17.533 10.3387C17.533 10.3688 17.528 10.3888 17.5255 10.4039L17.5204 10.4289H16.7835C16.796 10.5743 16.9263 10.7072 17.0868 10.7072C17.2221 10.7072 17.3024 10.632 17.3349 10.5844ZM16.786 10.2861H17.3675C17.3425 10.1206 17.2221 9.99777 17.0767 9.99777C16.9314 9.99777 16.811 10.1181 16.786 10.2861Z" fill="black"/>
  <path d="M18.6575 10.8518L18.2539 9.85156H18.4319L18.7352 10.6186L19.0385 9.85156H19.2165L18.8129 10.8518H18.6575Z" fill="black"/>
  <path d="M19.4512 10.8526V9.85238H19.574L19.6191 9.96268C19.6342 9.94012 19.6542 9.92006 19.6793 9.90251C19.7219 9.86993 19.7771 9.83984 19.8648 9.83984C19.9651 9.83984 20.0353 9.88497 20.0804 9.92758C20.108 9.95516 20.1305 9.98273 20.1456 10.0153C20.1656 9.98273 20.1882 9.95265 20.2157 9.92758C20.2684 9.88246 20.3386 9.83984 20.4464 9.83984C20.6243 9.83984 20.7823 9.99276 20.7823 10.251V10.8501H20.6143V10.251C20.6143 10.083 20.5266 9.99526 20.4138 9.99526C20.2985 9.99526 20.2007 10.093 20.2007 10.251V10.8501H20.0327V10.251C20.0327 10.083 19.945 9.99526 19.8322 9.99526C19.7169 9.99526 19.6191 10.1031 19.6191 10.251V10.8501L19.4512 10.8526Z" fill="black"/>
  <path d="M21.0605 10.5518H21.2285C21.241 10.6395 21.3037 10.7072 21.429 10.7072C21.5444 10.7072 21.607 10.6395 21.607 10.5618C21.607 10.3362 21.0806 10.5092 21.0806 10.1281C21.0806 9.97521 21.216 9.83984 21.4165 9.83984C21.6321 9.83984 21.7524 9.96268 21.7624 10.1407H21.5945C21.582 10.0529 21.5293 9.99526 21.4165 9.99526C21.3162 9.99526 21.2486 10.0604 21.2486 10.1281C21.2486 10.3537 21.775 10.1833 21.775 10.5618C21.775 10.7272 21.6396 10.8626 21.429 10.8626C21.2059 10.8626 21.0706 10.7298 21.0605 10.5518Z" fill="black"/>
  <defs>
    <linearGradient id="paint0_linear_7668_49057" x1="4.40688" y1="3.81928" x2="7.54603" y2="3.10797" gradientUnits="userSpaceOnUse">
      <stop stop-color="#A5D3FF"/>
      <stop offset="0.5781" stop-color="#0686FF"/>
    </linearGradient>
    <linearGradient id="paint1_linear_7668_49057" x1="16.1386" y1="5.11041" x2="8.83131" y2="1.41336" gradientUnits="userSpaceOnUse">
      <stop stop-color="#A5D3FF"/>
      <stop offset="0.2016" stop-color="#0686FF"/>
    </linearGradient>
  </defs>
</svg>`
  @ViewChild('portalContent', { static: true })
  portalContent: any;
  accountId

  accountType: 'platform' | 'master' | '' = ''
  masterAccounts: Array<{ id: number, icon: string, label: string, status: string, clients: string, description: string }> = []
  clientAccounts: Array<{ icon: string, label: string, status: string, description: string }> = []
  accountSideBar: {icon:string,label:string,pipeline: string, jobs: string, user: string, description: string, createdBy: string, createdOn: string}=
  {
    icon:'',
    label:'',
    pipeline:'',
    jobs:'',
    user:'',
    description:'',
    createdBy:'',
    createdOn:''
  }
 
  constructor(private viewContainerRef: ViewContainerRef,private route:ActivatedRoute, public portalBridge: SideNavService, private accountService: Accountv2Service, private authService: AuthService, private router:Router) { 
    this.accountId = this.route.snapshot.params.accountId;
  }
  
  ngOnInit() {
    //check account type
    let currentAccountId = this.authService.getAccountId()?.toString();
    this.accountService.getAccountDetails(currentAccountId || '').subscribe((data: any) => {
      this.accountType = data.payload.type
      if (this.accountType === 'platform') {
        //load all masters
        this.loadMasterAccounts(this.itemsPerPage,this.pageIndex)
      }
      else if (this.accountType === 'master') {
        this.loadClientAccounts(currentAccountId || '',this.itemsPerPage,this.pageIndex)
      }
    })
    //
    // debugger
    // console.log(this.accountType)

  }

  loadMasterAccounts(itemsPerPage:number,pageIndex:number) {
    this.accountService.getAccounts({ params: { type: "master",itemsPerPage,pageNum:pageIndex+1 } }).subscribe((accounts: any) => {
      this.totalCount = accounts.payload.totalCount;
      this.pageIndex = accounts.payload.currentPage - 1;
      this.masterAccounts = accounts.payload.data.map((acc: any) => {
        return {
          id: acc.id,
          // icon:this.tempIcon,
          label: acc.displayName,
          description: acc.description,
          status: acc.status,
          clients: '-'
        }
      })
    })
  }

  loadClientAccounts(currentAccountId:string,itemsPerPage:number,pageIndex:number){
    this.accountService.getAccounts({ params: { parentId: currentAccountId,itemsPerPage,pageNum:pageIndex+1 } }).subscribe((accounts: any) => {
      this.totalCount = accounts.payload.totalCount;
      this.pageIndex = accounts.payload.currentPage - 1;
      this.clientAccounts = accounts.payload.data.map((acc: any) => {
        return {
          id: acc.id,
          // icon: this.tempIcon,
          label: acc.displayName,
          description: acc.description,
          status: acc.status
        }
      })
    })
  }

  onPaginationEvent(e:any) {
    // console.log(e.detail)
    if(this.accountType === 'platform'){
      this.loadMasterAccounts(e.detail.itemsPerPage,e.detail.index)
    }
    else if(this.accountType === 'master'){
      this.loadClientAccounts(this.authService.getAccountId()?.toString() || '',e.detail.itemsPerPage,e.detail.index)
    }
  }

  openDetails(event:any) {
    this.accountService.getAccountDetails(event.detail).subscribe((data:any)=>{  
      this.accountSideBar.icon='https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg'
      this.accountSideBar.label=data.payload.displayName;
      this.accountSideBar.pipeline = '--';
      this.accountSideBar.jobs = '--';
      this.accountSideBar.user = '--';
      this.accountSideBar.description = data.payload.description;
      this.accountSideBar.createdBy='--',
      this.accountSideBar.createdOn=this.formatTimestamp(data.payload.createdTime);
      
      this.portalBridge.show = true;
      const portal = new TemplatePortal(this.portalContent, this.viewContainerRef)
      this.portalBridge.setRightNavTemplate(portal)
    })
  }
  formatTimestamp(timestamp:any) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(timestamp* 1000);
    const day = date.getDate();
    const month = date.toLocaleDateString(undefined, { month: 'long' });
    const year = date.getFullYear();
    let daySuffix = 'th';
    if (day >= 1 && day <= 3) {
      daySuffix = ['st', 'nd', 'rd'][day - 1];
    }
    return `${day}${daySuffix} ${month} ${year}`;
  }
  openClientDetails(event:any){
    console.log(event.detail)
    this.router.navigate(["accounts",event.detail])
  }
  switchAccount(event:any){
    this.authService.saveAccountId(event.detail)
    this.accountType === 'master' ? this.router.navigateByUrl('/pipelines(view:pipeline-list)') : window.location.href = window.location.href;
  }

  ngOnDestroy() {
    this.portalBridge.removeRightNavTemplate()
    this.portalBridge.show = false;
  }
}

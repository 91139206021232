<div style="display: flex;height:80vh">
    <section class="side-panel">
        <header><b>Files</b><nb-icon *ngIf="!readOnly" (click)="addFile(fileNamePopup)" nbTooltip="Add File" icon="plus-outline"></nb-icon></header>
        <div *ngFor="let file of files">
            <div style="width: 100%;cursor: pointer;" (click)="openFile(file)">{{file.name}}</div>
            <!-- <span *ngIf="file.modified" nbTooltip="Upload" style="color:red;font-weight: bold;margin-right: 0.25rem;">M</span> -->
            <!-- <nb-icon [nbSpinner]="loading" nbSpinnerSize="tiny" *ngIf="file.modified" (click)="uploadFile(file.name,file.content)" nbTooltip="Upload File" icon="cloud-upload-outline"></nb-icon> -->
            <span style="color:red;margin-left:0.25rem;margin-right:0.25rem;cursor:pointer" [nbSpinner]="loading" nbSpinnerSize="tiny" *ngIf="file.modified" (click)="saveFile(file.name,file.content)" nbTooltip="Save File">S</span>
            <nb-icon (click)="deleteFile(file)" nbTooltip="Delete File" icon="trash-outline"></nb-icon>
        </div>
    </section>
    <section class="editor-panel">
        <app-code-editor-options [readOnly]="readOnly" [outsideEvents]="editorEvents.asObservable()" (events)="incomingEvents($event)" ></app-code-editor-options>
    </section>
</div>

<ng-template #fileNamePopup let-ref="dialogRef">
    <nb-card>
        <nb-card-header>
            Enter the file name
        </nb-card-header>
        <nb-card-body>
            <input nbInput type="text" [(ngModel)]="newFileName">
        </nb-card-body>
        <nb-card-footer style="display: flex;justify-content: right;">
          <button style="margin-right:0.5rem" nbButton size="small" status="danger" (click)="ref.close()">CANCEL</button>
          <button nbButton size="small" status="success" (click)="ref.close(newFileName)">CREATE</button>
        </nb-card-footer>
      </nb-card>
</ng-template>
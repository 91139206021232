//@ts-ignore
import jsonixSource from '!!raw-loader!jsonix'

export const loadScript = (scriptSource: string, context: {[key: string]: unknown} = {}) => {
    const _module = {
        ...context,
        module: {
            exports: {}
        }
    }
    const factory = new Function(...Object.keys(_module), scriptSource)
    factory.call(_module, ...Object.values(_module))
    return _module
}

let jsonixInstance: any = null;

loadScript(jsonixSource, {require:() => {}, define: (requireLs: Array<string>, factory: Function) => {
    jsonixInstance = factory().Jsonix;
}})


type ContextType = {
    new (...args: any[]): ContextType
    createUnmarshaller: () => {
        unmarshalString: (str: string) => any
    }
    createMarshaller: () => {
        marshalDocument: (obj: any) => string
    }
}
export class Jsonix {
    get Context() {
        return jsonixInstance.Context as ContextType
    }
}


export const jsonix = new Jsonix
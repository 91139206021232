import { Component, ElementRef, Injector, Input, OnInit, ViewChild, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { createEditor, Schemes, NodeTypeMap } from "./editor";
import { DashboardStoreService } from '../services/store/dasboard-store.service';
import { NodeEditor, GetSchemes, ClassicPreset } from "rete";
import { Subscription } from 'rxjs';
import { NodeSettingsIconControl } from './custom-controller/settings-icon.component';
import { ACTION_CONTROLER_KEY, CustomNode, SETTINGS_CONTROLER_KEY } from './custom-nodes/default-node/custom-node.component';

// TODO: create rete as module

@Component({
  selector: 'app-rete-dag',
  templateUrl: './rete-dag.component.html',
  styleUrls: ['./rete-dag.component.css']
})
export class ReteDagComponent implements OnInit, OnChanges, OnDestroy {

  
  constructor(private injector: Injector, private dashboardStoreService: DashboardStoreService) {}

  @Input() graph: {nodes: {[key: string]: any}, edges: {[key: string]: any}} = {nodes:{}, edges:{}};
  @Output() openSettings: EventEmitter<CustomNode> = new EventEmitter()
  @Output() openActions: EventEmitter<CustomNode> = new EventEmitter()
  @ViewChild("rete") container!: ElementRef;

  nodeEditor?: {layout: (animate: boolean) => Promise<void>;destroy: () => void; editor: NodeEditor<Schemes>}
  private subscriptions: Array<Subscription> = []

  ngAfterViewInit(): void {
    const el = this.container.nativeElement;
    if(!el) return;
    createEditor(el, this.injector, this.dashboardStoreService).then(editor => {
      this.nodeEditor = editor
      this.buildGraph()
    })
  }

  ngOnInit(): void {
    this.buildGraph()
  }

  ngOnDestroy(): void {
    this.nodeEditor?.destroy()
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.buildGraph()
  }

  private async buildGraph() {
    if(!this.nodeEditor)return;
    const editor = this.nodeEditor.editor
    await editor.clear()

    const socket1 = new ClassicPreset.Socket("socket1");


    // const a = new ClassicPreset.Node("Source Node");
    // const b = new ClassicPreset.Node("Transform");
    // const c = new ClassicPreset.Node("Sync");


    // await editor.addNode(a);
    // await editor.addNode(b);
    // await editor.addNode(c);

    // a.addOutput("a", new ClassicPreset.Output(socket1, "Data", true));
    // b.addOutput("a", new ClassicPreset.Output(socket1, 'Data', true));
    // b.addInput("a", new ClassicPreset.Input(socket1, "Input", false));
    // b.addInput("b", new ClassicPreset.Input(socket1, "Data", false));
    // c.addInput("a", new ClassicPreset.Input(socket1, "Payload", false));


    // a.addControl("InputBox", new ClassicPreset.InputControl("text", {initial: "Hello"}))
    // a.addControl("Source", new ClassicPreset.InputControl<any>("text", {initial: "Hello"}))


    // b.addControl("InputBox", new ClassicPreset.InputControl("number", {initial: "Hello"}))
    // await editor.addConnection(new ClassicPreset.Connection(a, "a", b, "b"));
    // await editor.addConnection(new ClassicPreset.Connection(b, "a", c, "a"));
    // await editor.addConnection(new ClassicPreset.Connection(b, "a", c, "a"));


    const nodeMap: Map<string, any> = new Map()
    const nodesPromise: Array<Promise<any>> = []
    Object.entries(this.graph?.nodes || {}).forEach(([key, node]) => {

      const Constructor = NodeTypeMap.get(node.connector) || NodeTypeMap.get('')!
      const nodeObj = new Constructor(node.label, key, node)
      nodesPromise.push(nodeObj.init())

      nodeObj.addOutput("output", new ClassicPreset.Output(socket1, 'Data', true))
      nodeObj.addInput("input", new ClassicPreset.Input(socket1, 'Input', false))
      nodesPromise.push(editor.addNode(nodeObj))
      nodeMap.set(key, nodeObj)

      const settingControl = new NodeSettingsIconControl("Settings", ()=> {
        this.openSettings.emit(nodeObj)
      }, 'settings-outline');
      
      const actionControl = new NodeSettingsIconControl("Action", ()=> {
        this.openActions.emit(nodeObj)
      }, 'copy-outline');

      nodeObj.addControl(SETTINGS_CONTROLER_KEY, settingControl)
      nodeObj.addControl(ACTION_CONTROLER_KEY, actionControl)

      console.log(nodeObj)
    })

    await Promise.all(nodesPromise)

    const nodesConnectionPromise: Array<Promise<any>> = []
    Object.entries(this.graph?.edges || {}).forEach(([key, edge]) => {
      const srcNodeId = edge.src
      const destNodeId = edge.dest

      const srcNode = nodeMap.get(srcNodeId)
      const destNode = nodeMap.get(destNodeId)

      nodesConnectionPromise.push(editor.addConnection(new ClassicPreset.Connection(srcNode, 'output', destNode, 'input')))
    })

    await Promise.all(nodesConnectionPromise)

    await this.nodeEditor?.layout(false)

  }

}

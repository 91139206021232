import { HttpClient, HttpHeaders } from "@angular/common/http";
import { filter, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class FormService {
    url = ''
    constructor(private http: HttpClient) {
        this.url = "http://127.0.0.1:8000/pipeline/v2/remap_form";
    }

    getFormInputs(formData:any={
        "nodeName": "mysql",
        "serviceName": "select_query",
        "inputType":"connection",
        "form": {
        }
    }) {
        return this.http.post("http://127.0.0.1:8000/pipeline/v2/remap_form", formData).pipe(map((data: any) => {
                const inputArray:Array<InputType> = [];
                for (const key in data.payload.form) {
                    if (data.payload.form.hasOwnProperty(key)) {
                        inputArray.push({ ...data.payload.form[key] });
                    }
                }
                return inputArray;
            }))
    }

 formData: any = [];

  setFormData(data: any) {
    this.formData = data;
    console.log(this.formData)
  }

  getFormData() {
    return this.formData ;
  }


}





type InputType = {
    children?:Array<InputType>
    dataType: 'string'|'integer'|'float'|'date_time'|'date'|'timestamp' | 'boolean'
    formatType: 'text'|'text_area'| 'password'| 'email' |'phone'| 'integer' |'float'| 'url' |'select' |'multi_select' |'checkbox' |'date' |'date_time'
    name:string
    label:string
    description?:string
    required?:boolean
    options?:Array<any>
    default?:any
    value?:any
    disabled?:boolean
    visible?:boolean
    errors?:Array<string>
  }

 
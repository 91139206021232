import { Component, OnInit } from '@angular/core';
import {NbMenuItem} from "@nebular/theme";

@Component({
  selector: 'app-simplify-header',
  templateUrl: './simplify-header.component.html',
  styleUrls: ['./simplify-header.component.css']
})
export class SimplifyHeaderComponent implements OnInit {
  items: NbMenuItem[] = [
  	{
  		title: 'Logout',
  		icon: 'activity-outline',
  	},
  	{
  		title: 'View Pipe',
  		icon: 'layers-outline',
  	}
  ];
  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import {Chart} from 'chart.js/auto';
import { Pipelinev2Service } from 'src/app/services/api/v2/pipelinev2.service';
@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss']
})
export class MainDashboardComponent implements OnInit {
  dashboardTitle: string = "Hey there, Welcome to Simplify Bridge";
  cardsData = [{
    icon:"ri-flow-chart status-icon",
    title:"Pipeline Stats",
    firstLabel:"Active Pipelines",
    firstValue:80,
    secondLabel:"Inactive Pipelines",
    secondValue:20,
  },
  {
    icon:"ri-terminal-box-line status-icon",
    title:"APIs Stats",
    firstLabel:"Active APIs",
    firstValue:80,
    secondLabel:"Inactive APIs",
    secondValue:20,
  }
]
  tableData = {
    pipelineStats:[
      {
        name:"Invoice Generator",
        total:"15 min"
      },
      {
        name:"Generic Job Template",
        total:"1h 30 min"
      },
      {
        name:"Invoice Generator",
        total:"15 min"
      },
      {
        name:"Generic Job Template",
        total:"1h 30 min"
      },
    ],
    recentProcesses:[
      {
        name:"Invoice Generator",
        status:"Successful",
        startedBy:"Vikas Sharma - 2min ago"
      },
      {
        name:"Generic Job Template",
        status:"Failed",
        startedBy:"Ravi Kumar - 5min ago"
      },
      {
        name:"Invoice Generator",
        status:"Successful",
        startedBy:"Vikas Sharma - 2min ago"
      },
      {
        name:"Generic Job Template",
        status:"Failed",
        startedBy:"Ravi Kumar - 5min ago"
      },
      {
        name:"Invoice Generator",
        status:"Successful",
        startedBy:"Vikas Sharma - 2min ago"
      },
      {
        name:"Generic Job Template",
        status:"Failed",
        startedBy:"Ravi Kumar - 5min ago"
      },
      {
        name:"Invoice Generator",
        status:"Successful",
        startedBy:"Vikas Sharma - 2min ago"
      },
      {
        name:"Generic Job Template",
        status:"Failed",
        startedBy:"Ravi Kumar - 5min ago"
      },
      {
        name:"Invoice Generator",
        status:"Successful",
        startedBy:"Vikas Sharma - 2min ago"
      },
      {
        name:"Generic Job Template",
        status:"Failed",
        startedBy:"Ravi Kumar - 5min ago"
      },
      {
        name:"Invoice Generator",
        status:"Successful",
        startedBy:"Vikas Sharma - 2min ago"
      },
      {
        name:"Generic Job Template",
        status:"Failed",
        startedBy:"Ravi Kumar - 5min ago"
      },
      {
        name:"Invoice Generator",
        status:"Successful",
        startedBy:"Vikas Sharma - 2min ago"
      },
      {
        name:"Generic Job Template",
        status:"Failed",
        startedBy:"Ravi Kumar - 5min ago"
      },
      {
        name:"Invoice Generator",
        status:"Successful",
        startedBy:"Vikas Sharma - 2min ago"
      },
      {
        name:"Generic Job Template",
        status:"Failed",
        startedBy:"Ravi Kumar - 5min ago"
      }
    ]
  }
  pipelines = [
    {name:"Invoice Generator",total:31,success:22,fail:9,lastUpdated:"By Shubham 6 hour ago"},
    {name:"Invoice Generator",total:31,success:22,fail:9,lastUpdated:"By Shubham 6 hour ago"},
    {name:"Invoice Generator",total:31,success:22,fail:9,lastUpdated:"By Shubham 6 hour ago"},
    {name:"Invoice Generator",total:31,success:22,fail:9,lastUpdated:"By Shubham 6 hour ago"},
  ]
  public chart: any;
  createChart(data:any){
  
    this.chart = new Chart("MyChart", {
      type: 'bar', //this denotes tha type of chart
      
      data: data,
      options: {
        plugins: {
          legend:{
            position: 'bottom',
            labels:{
              borderRadius:5,
              useBorderRadius: true,
              boxWidth:10,
              boxHeight:10,
              padding:40
            }
          },
          tooltip:{
            backgroundColor: '#FFF',
            titleColor: '#000',
            bodyColor: '#000',
            caretSize: 0,
            boxWidth:10,
            boxHeight:10,
            borderColor:'#E3E9F0',
            borderWidth:1,
            titleFont:{
              size:22,
              weight:"bold"
            },
            titleSpacing:10,
            callbacks:{
              // afterTitle(tooltipItems) {
              //   //add font
              // },
            },
            boxPadding:0

          }
          // title: {
          //   display: true,
          //   text: 'Chart.js Bar Chart - Stacked'
          // },
          
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
            grid:{
              display:false
            }
          },
          y: {
            stacked: true,
            grid:{
              // tickBorderDash: [8, 4],
              // color:"red"
            }
          }
        },
        
         
      },
      
    });
  }
  constructor(private pipelineService:Pipelinev2Service) { }

  ngOnInit(): void {
    // this.createChart();
    // this.pipelineService.getPipelineStats().subscribe((res:any)=>{
    //   console.log(res)
    //   this.cardsData[0].firstValue = res.payload.activePipelines;
    //   this.cardsData[0].secondValue = res.payload.inactivePipelines;
    //   this.cardsData = [...this.cardsData];
    // });

    // this.pipelineService.getRecentProcesses().subscribe((res:any)=>{
    //   console.log(res)
    //   let mappedData = res.payload.data.map((item:any)=>{
    //     return {
    //       name:item.pipelineName,
    //       status:item.status,
    //       startedBy:item.startedBy
    //     }
    //   }
    //   this.tableData.recentProcesses = res.payload;
    //   this.tableData.recentProcesses = [...this.tableData.recentProcesses];
    // })
  }

  ngAfterViewInit(){
    // this.pipelineService.processesRunData({params:{}}).subscribe((res:any)=>{
    //   let obj = res.payload
    //   let labels = []
    //   let successArray = []
    //   let failedArray = []
    //   let pipelines = []

    //   for (const [key, value] of Object.entries(obj)) {
    //     labels.push(key)
    //     successArray.push(obj[key]['success'])
    //     failedArray.push(obj[key]['failed'])
    //     pipelines.push(obj[key]['pipeline_ids'].length)
    //   }

    //   this.createChart({// values on X-Axis
    //     labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug' ], 
    //     // labels: labels, 
	  //      datasets: [
    //       {
    //         label: "Successful Runs",
    //         data: ['467','576', '572', '79', '92',
		// 						 '574', '573', '576'],
    //         // data: successArray,
    //         backgroundColor: '#5A9FD7',
    //         borderRadius: 5
    //       },
    //       {
    //         label: "Failed Run",
    //         data: ['542', '542', '536', '327', '17',
		// 							 '0.00', '538', '541'],
    //         // data:failedArray,
    //         backgroundColor: '#F97777',
    //         borderRadius:5,
    //       }  
    //     ],
        
    //   });
    // })
    setTimeout(() => {
      this.createChart({// values on X-Axis
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug' ], 
	       datasets: [
          {
            label: "Successful Runs",
            data: ['467','576', '572', '79', '92',
								 '574', '573', '576'],
            backgroundColor: '#5A9FD7',
            borderRadius: 5
          },
          {
            label: "Failed Run",
            data: ['542', '542', '536', '327', '17',
									 '0.00', '538', '541'],
            backgroundColor: '#F97777',
            borderRadius:5,
          }  
        ],
        
      });
    },0)
  }

  changeDashboard(){
    this.chart.destroy();
    this.createChart({// values on X-Axis
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug' ], 
	       datasets: [
          {
            label: "Successful Runs",
            data: ['542', '542', '536', '327', '17',
            '0.00', '538', '541'],
            backgroundColor: '#5A9FD7',
            borderRadius: 5
          },
          {
            label: "Failed Run",
            data: ['467','576', '572', '79', '92',
            '574', '573', '576'],
            backgroundColor: '#F97777',
            borderRadius:5,
          }  
        ],
        
    });
  }

  filterApplied(e:any){
    console.log(e)
  }

}

import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {Subscription} from "rxjs/internal/Subscription";

// Export Events
export enum EventTypes {
  INIT,
  SET_DATA = 'SET_DATA'
}

export type Event = {
  type: EventTypes
  args: Array<any>
}

function syntaxHighlight(json: any) {
  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match: string) {
    let cls = 'number';
    if (/^"/.test(match)) {
      if (/:$/.test(match)) {
        cls = 'key';
      } else {
        cls = 'string';
      }
    } else if (/true|false/.test(match)) {
      cls = 'boolean';
    } else if (/null/.test(match)) {
      cls = 'null';
    }

    let style = ''
    switch (cls) {
    case 'string':
      style = 'color: olive'
      break
    case 'number':
      style = 'color: darkorange'
      break
    case 'boolean':
      style = 'color: blue'
      break
    case 'null':
      style = 'color: magenta'
      break
    case 'key':
      style = 'color: brown'
      break
    }

    return '<span class="' + cls + '" style="' + style + '">' + match + '</span>';
  });
}

@Component({
  selector: 'app-print-json',
  templateUrl: './print-json.component.html',
  styleUrls: ['./print-json.component.css']
})
export class PrintJsonComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @ViewChild('root') root: ElementRef | undefined
  @Input() data: any = {}
  @Input() ignore: any = {}
  @Input() events: Observable<Event> | undefined

  eventMapping: { [key: string]: Function } = {}
  subscriptions: Array<Subscription> = []

  constructor() {
  }

  ngOnInit(): void {
    if (this.data) {
      if(Array.isArray(this.data)) {
        this.data = JSON.parse(JSON.stringify(this.data))
      } else {
        this.data = JSON.parse(JSON.stringify({...this.data, ...this.ignore}))
      }
    }
    this.registerEvents()
  }

  ngOnChanges(changes: any) {
    if(changes.data) {

      if(Array.isArray(changes.data.currentValue)) {
        this.data = JSON.parse(JSON.stringify(changes.data.currentValue))
      } else {
        this.data = JSON.parse(JSON.stringify({...changes.data.currentValue, ...this.ignore}))
      }
      this.parseData()
    }
  }

  parseData() {
    if (this.root && this.root.nativeElement) {
      this.root.nativeElement.innerHTML = syntaxHighlight(JSON.stringify(this.data, null, 4))
    }
  }

  ngAfterViewInit(): void {
    this.parseData()
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  registerEvents() {
    if (!this.events) return
    let observer = this.events.subscribe(event => {
      switch (event.type) {
      case EventTypes.SET_DATA:
        this.data = event.args[0]
        break
      }
    })
    this.subscriptions.push(observer)
  }

}

<div [style.display]="isLoading?'flex':'none'" class="loader-wrapper">
    <span class="loader"></span>
    <span>Loading...</span>
</div>



<!--need to add trigger function-->

<pipeline-page (pageEvents)="customEventsHandler($event)" [totalPipelinesCount]="totalCount" [recentsPipelinesCount]="recentsCount" [recentPipelines]="filteredRecentPipelines" [allPipelines]="pipelines" [pipelineOptions]="pipelineOptions">
    <simplify-pipeline-list-header [showCreateNew]="hasAdminRole" slot="header" (searchQuery)="onSearchQueryChange($event)" (customEvents)="headerEventsHandler($event)" (createModalEvents)="createPipeline($event)"></simplify-pipeline-list-header>
    <simplify-pagination *ngIf="totalCount>10" slot="allPagination" [totalCount]="totalCount" [pageIndex]="pageIndex" [itemsPerPage]="itemsPerPage" [pageSizeOptions]="pageSizeOptions" (paginate)="onPaginationEvent($event)" class="position-sticky bottom-0 bg-white"></simplify-pagination>
    <!-- <simplify-pagination slot="allPagination" [totalCount]="totalPipelines" [pageIndex]="pageIndex"  (paginate)="onPaginationEvent($event)" class="position-sticky bottom-0 bg-white"></simplify-pagination> -->
</pipeline-page>

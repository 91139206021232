import ClickEvent = JQuery.ClickEvent;
import { FileService } from 'src/app/services/api/file.service';
import { DataService } from 'src/app/services/api/data.service';
//import { buildTableData } from '../dashboard/helper';
import { PipelineService } from 'src/app/services/api/pipeline.service';
import { DashboardStoreService } from 'src/app/services/store/dasboard-store.service';
import { BehaviorSubject, Subscription, fromEvent, interval,Observable } from 'rxjs';
import { debounce, switchMap, takeUntil, throttle } from 'rxjs/operators';
import { DownloadFormComponent } from '../download-form/download-form.component';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterType } from '../output-table/output-table.component';
import {getDataTypeIconName} from '../../components/data-mapper/data-mapper.component'

@Component({
  selector: 'app-output',
  templateUrl: './output.component.html',
  styleUrls: ['./output.component.css']
})
export class OutputComponent implements OnInit, AfterViewInit, OnDestroy {
  schemaParse=[
    {name:'root',indent:0,type:'array'},
    {name:'firstName',indent:1,type:'string'},
    {name:'lastName',indent:1,type:"string"},
    {name:'age',indent:1,type:"number"},
    {name:'primaryMobile',indent:1,type:"number"},
    {name:'costCenter',indent:1,type:"string"},
    {name:'orgUnit',indent:1,type:"string"},
    {name:'workLocation',indent:1,type:"array"},
    {name:'code',indent:2,type:"string"},
    {name:'name',indent:2,type:"string"},
    {name:'financialAuthority',indent:1,type:"number"},
  ]
  schemaQuery = ''
  filteredSchema:Array<{text:string,indent:number,type:string}> = []
  indentLine(num:number){
    let str = '';
    for(let i=0;i<num;i++){
      str+='- '
    }
    return str;
  }
  filterSchema(event:any,schemaArray:Array<{text:string,indent:number,type:string}>){
    this.schemaQuery = event.target.value;
    this.filteredSchema = schemaArray.filter(obj=>obj.text.match(new RegExp(this.schemaQuery,'i')))
  }
  getDataTypeIconName = getDataTypeIconName
  @Input()
  validation: any;
  @Input()
  api: any;
  @Input()
  taskId: any;
  @Input()
  Conditional_upload: boolean | null = null;
  @Input()
  status: any;
  @Input()
  name: string = '';
  @Input()
  events = new Observable<{type: 'TAB_CHANGE', args: string }>();
  @Input()
  field:string = '';

  _taskData: any;
  downloadLinks: { [key: string]: string } = {}
  fileNameMapping: { [key: string]: any } = {}
  currentTabId: string = ''
  loader = false
  outputType:'table'|'data' | 'schema' | '' = ''
  @Input()
  set taskData(value: any) {
    this._taskData = value

    // Map download links with tabs
    let downloadLinks: { [key: string]: string } = {}
    let fileNameMapping: { [key: string]: any } = {}
    for (let obj of value) {
      downloadLinks[obj.sheet_name] = obj.download_url
      fileNameMapping[obj.sheet_name] = obj
    }
    this.downloadLinks = downloadLinks
    this.fileNameMapping = fileNameMapping

    // select first sheet
    if (value.length > 0) {
      //this.currentTabId = Object.keys(downloadLinks)[0]
    }
  }
 
  get taskData(): any {
    return this._taskData
  }


  /* eslint  @angular-eslint/no-output-native: "off", curly: "error" */
  @Output()
  close = new EventEmitter();

  subscriptions: Array<Subscription> = []
  layoutContentRect: any = null
  gridHeaderRect: any = null

  constructor(private _fileService: FileService, private data_service: DataService, private pipelineService: PipelineService, private dashboardService: DashboardStoreService,
              public dialog: MatDialog,) {}

  // constructor(public dialog: MatDialog,
  //   private _fileService: FileService, private data_service: DataService, private pipelineService:PipelineService, private dashboardService:DashboardStoreService ) {
  // }

  contentHeight = new BehaviorSubject(400)
  xmlstring = `<?xml version="1.0" encoding="UTF-8"?>    
  <Employees>
    <Employee>    
       <id>1</id>    
       <name><fname>Vikas</fname><lname>Bhadoriya</lname></name>    
       <gender>Male</gender>    
       <mobile>514545</mobile>    
    </Employee>    
    <Employee>    
       <id>2</id>    
       <name><fname>Akash</fname><lname>Bhadoriya</lname></name>     
       <gender>Male</gender>    
       <mobile>5431643</mobile>    
    </Employee>    
    <Employee>    
       <id>3</id>    
       <name><fname>Shivam</fname><lname>Bhadoriya</lname></name>     
       <gender>Male</gender>    
       <mobile>43265436</mobile>    
    </Employee>    
    <Employee>    
       <id>4</id>    
       <name><fname>Ashish</fname><lname>Bhadoriya</lname></name>      
       <gender>Male</gender>    
       <mobile>5435431</mobile>    
    </Employee>    
    <Employee>    
       <id>5</id>    
       <name><fname>Krishna</fname><lname>Bhadoriya</lname></name>     
       <gender>Male</gender>    
       <mobile>432656</mobile>    
    </Employee>    
  </Employees>`

  ngOnInit(): void {

    const resizeEvent = fromEvent(window, 'resize')

    const stream = this.getDragStream(this.dragBar?.nativeElement)
    // stream = stream.pipe(debounce(event => interval(15)))

    this.subscriptions.push(stream.subscribe(event => this.dragEventHandler(event)))
    this.subscriptions.push(resizeEvent.subscribe(this.onWindowResize))

    this.currentTabId = this.field

    console.log(this.field)

    //this.taskData.forEach((x:any)=>{if(x.sheet_name===this.currentTabId)this.outputType=x.outputTypes[0]})
    this.outputType = 'data'
  }

  getDragStream(element: HTMLElement) {
    const mouseMove = fromEvent(document, 'mousemove')
    const mouseUp = fromEvent(document, 'mouseup')
    const mouseDown = fromEvent(element, 'mousedown')

    const dragStart = mouseDown
    const dragMove = dragStart.pipe(
      switchMap(start => 
        mouseMove.pipe(takeUntil(mouseUp))
      )
    )

    return dragMove
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  onWindowResize(event: any) {
    const layoutContent = document.querySelector('app-dashboard')
    console.log(layoutContent)
    if (layoutContent) {
      this.layoutContentRect = layoutContent.getBoundingClientRect()
    }
  }

  ngAfterViewInit() {
    if (this.dragContainer && this.dragContainer.nativeElement && this.tabsNevBar) {
      let tabsNevBarRect = this.tabsNevBar?.nativeElement.getBoundingClientRect()
      this.dragContainer.nativeElement.style.height = this.contentHeight.value + tabsNevBarRect.height + 'px'
    }

    const slider = document.querySelector(".scroll") as HTMLElement;
    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    slider.addEventListener("mousedown", (e: any) => {
      e.preventDefault();
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", (e) => {
      e.preventDefault();
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", e => {
      if (!isDown) {
        return;
      }
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = x - startX;
      slider.scrollLeft = scrollLeft - walk;
    });
  }


  async createUrl( uri:string ,Filename:string = "file"){
    console.log(uri)
    let url = await this.pipelineService.getPublicUrl(uri, Filename).toPromise()
    console.log(url)
    let a = document.createElement("a")
    a.download = Filename
    a.href = url.URL
    a.click()
      
  }

  DRAG_BORDER_PIXEL = 10


  dragEventHandler(event: any) {
    console.log('resizing...')

    let height = (window.innerHeight) - event.y

    if (!this.layoutContentRect) {
      const layoutContent = document.querySelector('app-dashboard') as any
      this.layoutContentRect = layoutContent.getBoundingClientRect()
    }

    if (!this.gridHeaderRect) {
      
      const gridHeader = document.querySelector('[ref=headerRoot]') as any
      if(gridHeader){
        this.gridHeaderRect = gridHeader.getBoundingClientRect()
      }
      else{
        this.gridHeaderRect = {
          height:0
        }
      }
    }

    let tabsNevBarRect = this.tabsNevBar?.nativeElement.getBoundingClientRect()

    // ignore last event, ignore event out of dashboard component
    if ((event.y === 0) || (height > this.layoutContentRect.height) || (height < tabsNevBarRect.height + this.gridHeaderRect.height)) {
      return
    }

    const MIN_GAP_FROM_TOP = 40

    // make full screen if gap is less then MIN_GAP_FROM_TOP
    if (this.layoutContentRect.height - height < MIN_GAP_FROM_TOP) {
      height = this.layoutContentRect.height - 2
    }


    const MIN_GAP_FROM_BOTTOM = 0
    const VISIBLE_ROW_HEIGHT = tabsNevBarRect.height * 3
    //minimize on drag down
    if (height < tabsNevBarRect.height + this.gridHeaderRect.height + MIN_GAP_FROM_BOTTOM + VISIBLE_ROW_HEIGHT) {
      height = tabsNevBarRect.height + this.gridHeaderRect.height + VISIBLE_ROW_HEIGHT
    }


    if (this.dragContainer && this.dragContainer.nativeElement) {
      this.dragContainer.nativeElement.style.height = height + 'px'
    }

    if (this.tabsContent && this.tabsContent.nativeElement) {
      // let dragBarRect = this.dragBar?.nativeElement.getBoundingClientRect()

      this.contentHeight.next(height /* - dragBarRect.height*/ - tabsNevBarRect.height)
    }
  }


  @ViewChild('tabsNevBar') tabsNevBar: ElementRef | undefined;
  @ViewChild('tabsContent') tabsContent: ElementRef | undefined;
  @ViewChild('dragBar', {static: true}) dragBar: ElementRef | undefined;
  @ViewChild('dragContainer', {static: true}) dragContainer: ElementRef | undefined;

  onTabChange(event: MouseEvent, file: any) {
    this.currentTabId = file.sheet_name
    this.outputType = file.outputTypes[0]
    this.filteredSchema = [];
    this.schemaQuery = '';
  }


  gridApi: any

  onGridReady(params: any) {
    this.gridApi = params.api;
  }


  async changeDataType(sheetName: any, event: any) {
    // console.log("change data type params:",sheetName, event);
    this.loader = true
    // console.log(this.dashboardService.getCurrentNode()?.id)
    const result = await this._fileService.changeDatatype({
      "pipelineId": this.dashboardService.getCurrentPipeline()?.id,
      "processId": this.dashboardService.getCurrentPipeline()?.process.id,
      "taskId": this.dashboardService.getCurrentOutput()?.id,
      "fileName": sheetName,
      "columnId": event.columnId,
      "targetDatatype": event.targetDataType

    }).toPromise()


    const outputResponse = await this.pipelineService.getoutput(this.dashboardService.getCurrentPipeline()?.id as number, this.dashboardService.getCurrentPipeline()?.process.id as number, this.dashboardService.getCurrentOutput()?.id as number).toPromise()
    //this._taskData = buildTableData(outputResponse)

    this.loader = false
  }

  downloadWithName(event: MouseEvent) {
    const container = (event.target as HTMLElement).closest('div.download-icon-container')!;
    const link = container.querySelector('a')
    if(!link) {return;}

    console.log(link.dataset)

    if(link.dataset.clicked) {
      link.dataset.clicked = ''

    } else {

      event.stopPropagation()
      event.preventDefault()
      link.dataset.clicked = 'true'

      console.log(event.target)
      console.log(link)

      let dialogRef = this.dialog.open(DownloadFormComponent, { hasBackdrop: true, panelClass: 'custom-dialog-container', minHeight:'20vh', minWidth: '40vw',} )

      dialogRef.componentInstance.fileConfigs = {
        name: this.currentTabId
      }

      dialogRef.componentInstance.onDownload.subscribe(fileName => {
        link.setAttribute('download', fileName+'.xlsx')
        link.click()
        dialogRef.close()
      })
    }
  }

  openWindow(uri:string) {
    let dialogRef = this.dialog.open(DownloadFormComponent, { hasBackdrop: true, panelClass: 'custom-dialog-container', minHeight:'20vh', minWidth: '30vw',} )

    dialogRef.componentInstance.fileConfigs = {
      name: this.dashboardService.getCurrentPipeline()?.name || 'pipeline_export'
    }

    dialogRef.componentInstance.onDownload.subscribe(fileName => {
      let pipeline = this.dashboardService.getCurrentPipeline()
      this.createUrl( uri ,fileName || pipeline?.name)
    })
  }

  @Output() filteredData = new EventEmitter<any>();
  @Input() defaultFilters:{[sheet_name:string]:{[input_field:string]:FilterType}}={};
 
  filteredDataEvent(event:any){
    this.filteredData.emit({event,sheet_name:this.currentTabId})
  }
  
  
  changeOutputType(event:any,outputType:any){
    this.outputType= outputType;
  }


}

import { Component, OnInit, EventEmitter, Input, Output, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigModel } from 'src/app/models/config.model';
import { Nodev2Service } from 'src/app/services/api/nodev2.service';
import { Connectorv2Service } from 'src/app/services/api/v2/connectorv2.service';
import { Connector, PipelineVersion } from 'src/app/services/api/v2/interfacesv2';
import { UIService } from 'src/app/services/config/ui.service';
import { DashboardStoreService } from 'src/app/services/store/dasboard-store.service';

const toSnakeCase = (str:any) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x:any) => x.toLowerCase())
    .join('_');
  
type NodeInstanceAPIResponse = {
  payload:{
    id: number,
    pipelineId: number,
    parentId: null,
    isReference: boolean,
    name: string,
    label: string,
    nodeName: string,
    connectionInstanceId: number,
    connection: any,
    serviceName: any,
    inputConfigs: any,
    status: string,
    createdTime: any,
    updatedTime: any
  },
    status: string,
    errorCode: string,
    errorMessage: string
  
}

@Component({
  selector: 'app-add-node',
  templateUrl: './add-node.component.html',
  styleUrls: ['./add-node.component.css']
})
export class AddNodeComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:{connectorSelected:Connector,pipelineId:number,pipelineVersion:number, runAfter: {[key: string]: boolean}}, private dialogRef: MatDialogRef<AddNodeComponent>, private nodeService: Connectorv2Service) { }

  nodeInstanceName: string = ''
  description:string = ''
  ngOnInit(): void {
    //console.log(this.data);
  }

  close() {
    //console.log({...this.data,nodeName:this.name})
    this.dialogRef.close();
  }

 async addNode() {
    if(this.nodeInstanceName){
    let pipelineId = this.data.pipelineId.toString()
    let pipelineVersion = this.data.pipelineVersion.toString()
    const nodeData = {
      connectorId:this.data.connectorSelected.id,
      name: toSnakeCase(this.nodeInstanceName),
      label: this.nodeInstanceName,
      description: this.description,
      // status: "draft"
      runAfter: this.data.runAfter || {}
    }
    //this.dashboardStoreService.createTaskk({...this.data,name:this.name})
    let response:{nodeId:string,pipelineVersion:PipelineVersion} = (await this.nodeService.createNodeInit(nodeData,pipelineId,pipelineVersion).toPromise()).payload;
    console.log(response)

    this.dialogRef.close(response);

    /*
      response.payload = {
        "id": 55,
        "pipelineId": 10,
        "parentId": null,
        "isReference": false,
        "name": "active_workers 666",
        "label": "Active Workers 666",
        "nodeName": "test_mysql",
        "connectionInstanceId": null,
        "connection": null,
        "serviceName": null,
        "inputConfigs": null,
        "status": "draft",
        "createdTime": "2023-02-07T05:25:36.496311Z",
        "updatedTime": "2023-02-07T05:25:36.496311Z"
    }
    */
  }
  else{
    console.log('No node name provided');
    this.dialogRef.close();
  }
}

  
}

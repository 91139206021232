<simplify-sidemenu (createNewGroup)="openCreateGroupPopup()" [addGroupShow]="hasAdminRole">
    <simplify-sidemenu-item routerLinkActive="active" [routerLink]="['', { outlets: { view: ['pipeline-list'],action:null } }]" (click)="resetDirectory()">
        <simplify-icon name="flow-chart" size="large" lib="remix"></simplify-icon>
        Pipelines
        <simplify-badge status="outline-sm" >{{totalPipelines}}</simplify-badge>
    </simplify-sidemenu-item>

    <!-- <simplify-sidemenu-item routerLinkActive="active" [routerLink]="['', { outlets: { view: ['pipeline-details'],action:['connection-form','5'] } }]">
        <simplify-icon name="folders-line" size="large" lib="remix"></simplify-icon>
        All Groups
        <simplify-badge status="secondary">{{totalGroups}}</simplify-badge>
    </simplify-sidemenu-item> -->

    <li class="side-group-heading d-flex mb-2">
        <div>Groups</div>
        <a *ngIf="directoriesData.length!==0" class="btn-link text-blue ms-auto text-decoration-none" [routerLink]="['', { outlets: { view: ['groups'],action:null } }]">View All</a>
        <a *ngIf="directoriesData.length===0" class="btn-link text-blue ms-auto text-decoration-none" style="color: #AFB9C5 !important;cursor: not-allowed;">View All</a>
      </li>

    <ng-container *ngIf="directoriesData.length > 0" >
      <simplify-sidemenu-item as="div" style="padding:0.5rem 0rem;margin-bottom:0.5rem;">
        <simplify-searchbar placeholder='Search Groups' (smKeyUp)="onGroupSearch($event)"></simplify-searchbar>
      </simplify-sidemenu-item>

      <!-- <simplify-directory-tree expand-to="9" [directoryData]="dirData" (itemSelected)="showId($event)"></simplify-directory-tree> -->
      <simplify-directory-tree #directoryTree [expandTo]="expandTo" [directoryData]="directoriesData" (itemSelected)="folderSelected($event)" (loadData)="loadFolders($event)"></simplify-directory-tree>
    </ng-container>
    <simplify-empty-state *ngIf="directoriesData.length===0"></simplify-empty-state>

    

    
    <!-- <simplify-file-explorer>
        <simplify-fe-folder (click)="groupSwitch($event,group.id)" *ngFor="let group of groups" [name]="group.label" [folders]="2">
            <ng-container
            *ngFor="let directory of group.directories"
            [ngTemplateOutlet]="treeNode"
            [ngTemplateOutletContext]="{ $implicit: directory }">
            </ng-container>
        </simplify-fe-folder>
    </simplify-file-explorer>

      
      <ng-template #treeNode let-data>
        <simplify-fe-folder (click)="groupSwitch($event,data.groupId,data.id)" [name]="data.label" directory>
        <ng-container *ngIf="data.childDirectories">
            <ng-container
              *ngFor="let child of data.childDirectories"
              [ngTemplateOutlet]="treeNode"
              [ngTemplateOutletContext]="{ $implicit: child }">
            </ng-container>
        </ng-container>
        </simplify-fe-folder>
      </ng-template> -->

</simplify-sidemenu>


<ng-template #createGroupModal let-data>
  <!-- <simplify-create-group-modal (save)="createGroup($event)" (close)="closeCreateGroupPopup()"></simplify-create-group-modal> -->
  <simplify-create-group-modal-2 (customEvents)="customHeaderHandler($event)" (close)="closeCreateGroupPopup()" [finalImage]="croppedImage">
    <app-image-cropper slot="icon" [imageChangedEvent]="imageChangedEvent"  [imageUrl]="imageUrl" (croppedImage)="croppedImage = $event"></app-image-cropper>
  </simplify-create-group-modal-2>

</ng-template>
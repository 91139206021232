<nb-card>
    <nb-card-header>
CONSTANTS
        <button nbButton  style="float: right;" (click)="close()"><nb-icon icon="close-outline" ></nb-icon></button>
    </nb-card-header>
    <nb-card-body>

        <form [formGroup]="user">
           <label> <b>key:</b><input formControlName="key" style="margin-left: 1rem;"/></label>
            <label style="margin-left: 1rem;"><b>Value:</b><input formControlName="value" style="margin-left: 1rem;"/></label>
            <button nbButton  nbButton hero status="success" size = "tiny" (click)="addPair(user)" style="margin-left: 1rem;">
              ADD
            </button>

            <ng-container formArrayName="pairs">
              <div *ngFor="let pair of pairs.controls; index as i">
                <ng-container [formGroupName]="i">
                  <input formControlName="key" style="margin-top: 1rem;" />
                  <input formControlName="value" style="margin-left: 1rem; margin-top: 1rem;"/>
                  <button nbButton hero status="danger"  size="tiny"  shape= "round" (click)="removePair(pair)" style="margin-left: 1rem;">
                    <nb-icon icon="minus-outline" style="color:white"></nb-icon>

                  </button>
                <button nbButton hero status="success"  size="tiny"  shape= "round" (click)="updatePair(pair)" style="margin-left: 1rem;">
                  <nb-icon icon="checkmark" style="color:white"></nb-icon>
                </button>

                </ng-container>
              </div>
            </ng-container>
            <br>

            <!-- <button nbButton (click)="addPair(user.controls)">
                <nb-icon icon="plus-outline" style="color: blue"></nb-icon>

              </button> -->
              <br>
<!--              {{user.value| json}}-->

          </form>
            <br>


    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-between">
        <!-- <button nbButton (click)="save();">Save</button> -->
        <!-- <button nbButton (click)="updateconstants()">Update Constant</button> -->

              </nb-card-footer>

  </nb-card>


import { Component, OnInit } from '@angular/core';
import { SideNavService } from 'src/app/services/core/sidenav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-templates',
  templateUrl: './sidebar-templates.component.html',
  styleUrls: ['./sidebar-templates.component.scss']
})
export class SidebarTemplatesComponent implements OnInit {

  constructor(private sidenavService: SideNavService, private router: Router) { }

  sidemenuDetails: Array<{
    type: string,
    count: number
  }> = [
      {
        type: "Workday",
        count: 52
      },
      {
        type: "SuccessFactors",
        count: 30
      },
      {
        type: "ServiceNow",
        count: 19
      },
      {
        type: "File Based",
        count: 24
      },
      {
        type: "Kronos",
        count: 18
      }
    ]
  sidemenuGlobal: Array<{
    type: string,
    count: number
  }> = [
      {
        type: "Workday",
        count: 52
      },
      {
        type: "SuccessFactors",
        count: 30
      },
      {
        type: "ServiceNow",
        count: 19
      },
      {
        type: "File Based",
        count: 24
      },
      {
        type: "Kronos",
        count: 18
      },
      {
        type: "Oracle",
        count: 21
      },
      {
        type: "SalesForce",
        count: 35
      },
      {
        type: "Analytics",
        count: 27
      },
      {
        type: "JIRA",
        count: 15
      },
      {
        type: "Hello Sign",
        count: 13
      },
      {
        type: "DocuSign",
        count: 19
      },
      {
        type: "Cloud",
        count: 31
      },
      {
        type: "Slack",
        count: 14
      },
      {
        type: "Databases",
        count: 17
      },
      {
        type: "File Storage",
        count: 23
      },
      {
        type: "PowerBI",
        count: 13
      },
      {
        type: "SAP",
        count: 20
      },
      {
        type: "Notification",
        count: 11
      },
      {
        type: "Calendar",
        count: 16
      }
    ]

  ngOnInit(): void {
    this.sidenavService.templateCategory.next("Workday")
  }


  onCategoryChange(event: any){
    console.log(event)
    this.sidenavService.templateCategory.next(event.detail)
    this.router.navigateByUrl('/templates(view:view-templates)')
  }

}
